/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import { Colors } from "../../../style";
import { columnCenterStyles } from "../../../style/flex";
import { styles } from "../styles";
import { ReactComponent as IconMainQuest } from "../../../assets/icons/icon_main_quest.svg";

export const LevelCategory = ({
    userLevel,
    category,
}: {
    userLevel: number;
    category: any;
}) => {
    return (
        <Box sx={styles.level}>
            <Box
                sx={
                    userLevel > category.start || category.start === 1
                        ? styles.levelLineCompleted
                        : styles.levelLine
                }
            />
            <Box sx={styles.categoryBox}>
                {userLevel < category.start ? (
                    <Box
                        sx={{
                            width: "32px",
                            height: "32px",
                            ...columnCenterStyles,
                        }}>
                        <IconMainQuest
                            width={25}
                            height={25}
                            fill={Colors.FOND_ITEM_POPUP}
                        />
                    </Box>
                ) : (
                    category.icon
                )}
                <Typography
                    sx={[
                        styles.categoryBoxText,
                        userLevel < category.start
                            ? {
                                  color: Colors.FOND_ITEM_POPUP,
                              }
                            : {
                                  color: category.color,
                              },
                    ]}>
                    {category.name}
                </Typography>
            </Box>
        </Box>
    );
};
