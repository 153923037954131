/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    ButtonBase,
    Grid,
    Modal,
    Typography,
    useMediaQuery,
} from "@mui/material";
import useAuth from "../../services/Auth/useAuth";
import { rowStartStyles } from "../../style/flex";
import { Level, LevelReward } from "../../models/Level";
import { NotificationContext } from "../../utils/ContextsHelper";
import LoadingView from "../../components/LoadingView";
import { getRouteManager } from "../../services/routeManager";
import { Player } from "@lottiefiles/react-lottie-player";
import BackgroundRow from "../../components/BackgroundRow";
import { progressBarJson } from "../../constants/progressBar";
import { strings } from "../../services/translation";
//ICONS
import Separator from "../../components/Separator";
import RewardBtn from "../../components/RewardBtn";
import { styles } from "./styles";
import { LevelCategory } from "./LevelCategory";
import { LevelBtn } from "./LevelBtn";
import { categories, storyRewards } from "./data";

const LevelScreen = () => {
    const auth = useAuth();
    const { getLevels } = useContext(NotificationContext);
    const matches = useMediaQuery(`(min-width:${1148}px)`);

    /**
     *
     * STATES
     *
     */

    const [activeLevel, setActiveLevel] = useState<Level | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [levels, setLevels] = useState<Array<Level>>([]);
    const [progressBar, setProgressBar] = useState<any>("0%");
    const [reward, setReward] = useState<LevelReward | any>();
    const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

    /**
     *
     * USE EFFECTS
     *
     */
    useEffect(() => {
        initData();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @initData
     * Get levels and update rewards
     */
    const initData = async () => {
        try {
            const level: Array<Level> | null = await getLevels();
            if (level) {
                updateLevels(level);
            }
            if (auth && auth?.user) {
                const val =
                    (auth.user.experience - auth.user.currentLevel.experience) /
                    (auth.user.nextLevel.experience -
                        auth.user.currentLevel.experience);

                setProgressBar(val > 0 ? val * 100 + "%" : "0%");
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * @updateLevels
     * add rewards to levels
     * @param lvl
     */
    const updateLevels = (lvl: any) => {
        lvl.map((l: Level) => {
            const hasStoryReward = storyRewards.find(
                (reward: any) => reward.level === l.number,
            );
            if (hasStoryReward) {
                const reward: LevelReward = {
                    id: 99999,
                    type: "story",
                    storyName: hasStoryReward.storyName,
                    levelId: l.id,
                };
                l.rewards = [...(l.rewards ? l.rewards : []), reward];
            }
        });
        setLevels(lvl);
        setIsLoaded(true);
        //scrollIndex();
    };

    /**
     * @checkAndGetReward
     * Fetch reward for a certain level
     * @param level
     */
    const checkAndGetReward = async (level: any) => {
        try {
            if (!level.rewardReclaimed && level.unlocked) {
                setIsLoaded(false);
                setActiveLevel(level);
                const token = getRouteManager().getUser().getLoginToken();
                if (token) {
                    const json =
                        await getRouteManager().fetchReclaimLevelReward(
                            level.id,
                        );
                    if (json.flashmessage === "error-already-claimed") {
                        setIsLoaded(true);
                    } else if (json.flashmessage !== "error") {
                        await getUserInfos();
                        await getLevels();
                        setReward(level.rewards);
                        setIsLoaded(true);
                        setShowPopUpCongrats(true);
                        updateLevels(json);
                    } else {
                        setIsLoaded(true);
                    }
                } else {
                    setIsLoaded(true);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * @getUserInfos
     * fetch user infos and update user
     */
    const getUserInfos = async () => {
        try {
            const json = await getRouteManager().fetchGetUserInfos(auth.user);
            if (json.flashmessage === "error") {
                console.log("no user found");
            } else {
                getRouteManager().setUser(json);
                auth.setUser(json);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * @closeModal
     * close reward modal
     */
    const closeModal = () => {
        setShowPopUpCongrats(false);
        setReward(null);
        setActiveLevel(null);
    };

    /**
     *
     * RENDER
     *
     */

    if (!isLoaded) {
        return <LoadingView />;
    }

    return (
        <BackgroundRow
            hasBackground={false}
            style={{ height: "100%" }}
            bodyStyle={styles.bodyStyle}
            matchesValue={1148}
            leftPartStyle={
                !matches
                    ? { width: "100%", maxWidth: "unset", alignItems: "center" }
                    : {}
            }
            leftPart={
                <Box sx={matches ? styles.container : styles.containerSmall}>
                    <Typography
                        sx={[
                            styles.topMenuTitle,
                            !matches && { textAlign: "center" },
                        ]}>
                        {strings.levels.title}
                    </Typography>
                    <Typography
                        sx={[
                            styles.subtitle,
                            !matches && {
                                justifyContent: "center",
                                marginBottom: "10px",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.levels.sub}
                    </Typography>
                    <Box
                        sx={[
                            {
                                ...rowStartStyles,
                                width: "100%",
                                flex: 1,
                                marginBottom: "10px",
                            },
                            !matches && { justifyContent: "center" },
                        ]}>
                        <Box style={styles.levelCircle}>
                            <Typography style={styles.levelText}>
                                {auth.user?.level ? auth.user.level : "?"}
                            </Typography>
                        </Box>
                        <Box sx={styles.progressBar}>
                            <Box
                                sx={[
                                    styles.progressBarActive,
                                    { width: progressBar },
                                ]}>
                                <Player
                                    src={progressBarJson}
                                    autoplay
                                    loop
                                    speed={2}
                                    style={{
                                        display: "flex",
                                        width: "240px",
                                        height: "15px",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
            rightPartStyle={{
                height: "90vh",
            }}
            rightPart={
                <Box
                    sx={[
                        styles.containerRight,
                        !matches && { alignSelf: "center", paddingTop: "2vh" },
                    ]}>
                    <Grid container sx={styles.mainContainer}>
                        {categories.map((category, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} key={`category-${index}`}>
                                    <LevelCategory
                                        userLevel={auth.user.level}
                                        category={category}
                                    />
                                </Grid>
                                {Array.from(
                                    {
                                        length:
                                            category.end - category.start + 1,
                                    },
                                    (_, i) => (
                                        <LevelBtn
                                            key={`levelbtn-  ${
                                                i + category.start
                                            }`}
                                            userLevel={auth.user.level}
                                            level={
                                                levels[i + category.start - 1]
                                            }
                                            index={i + category.start}
                                            checkAndGetReward={
                                                checkAndGetReward
                                            }
                                        />
                                    ),
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                    {activeLevel && showPopUpCongrats ? (
                        <Modal
                            open={showPopUpCongrats}
                            onClose={() => closeModal()}>
                            <Box sx={styles.modalView}>
                                <Typography sx={styles.congrats}>
                                    {strings.congrats}
                                </Typography>
                                <Box sx={styles.rewardsList}>
                                    {reward &&
                                        reward.length &&
                                        reward.map((r: any, index: number) => {
                                            return (
                                                <RewardBtn
                                                    reward={r}
                                                    isModal
                                                    level={activeLevel}
                                                    key={"reward" + index}
                                                />
                                            );
                                        })}
                                </Box>
                                <Typography sx={styles.congrats2}>
                                    {strings.addedToLibrary}
                                </Typography>

                                <Separator style={{ margin: "25px 0px" }} />

                                <ButtonBase
                                    sx={styles.yesButton}
                                    onClick={() => closeModal()}>
                                    <Typography sx={styles.yesText}>
                                        {strings.great}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        </Modal>
                    ) : null}
                </Box>
            }
        />
    );
};

export default LevelScreen;
