/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Fade, Typography, useMediaQuery } from "@mui/material";
import {
  columnStartStyles,
  rowCenterStyles,
  rowStartStyles,
} from "../../style/flex";
import { Colors } from "../../style";
import { Eczar, Gilroy } from "../../style/fonts";
import GoBackBtn from "../GoBackBtn";
import LoadingView from "../LoadingView";

interface Props {
  backgroundImage?: string | undefined;
  bodyStyle?: any;
  content?: any;
  contentStyle?: any;
  globalPart?: any;
  globalPartStyle?: any;
  goBack?: boolean | undefined;
  goBackAction?: any | undefined;
  goBackStyle?: any;
  hasBackground?: boolean | undefined;
  header?: any;
  headerStyle?: any;
  loading?: boolean | undefined;
  matchesValue?: number | undefined;
  style?: any;
  subtitle?: string | undefined;
  title?: string | undefined;
}

const BackgroundColumn = (props: Props) => {
  const {
    backgroundImage,
    bodyStyle,
    content,
    contentStyle,
    globalPart = null,
    globalPartStyle,
    goBack,
    goBackAction,
    goBackStyle,
    hasBackground = true,
    header,
    headerStyle,
    loading,
    matchesValue = 1032,
    style,
    subtitle,
    title,
  } = props;

  const matches = useMediaQuery(`(min-width:${matchesValue}px)`);

  return (
    <Fade in={true} appear timeout={800}>
      <Box
        sx={[
          styles.all,
          hasBackground
            ? {
                background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%),
              url(${
                backgroundImage
                  ? backgroundImage
                  : require("../../assets/images/background.jpg")
              }) 50%/cover`,
              }
            : {},
          style ? style : {},
        ]}
      >
        {loading ? (
          <LoadingView
            style={{
              position: "relative",
              ...columnStartStyles,
              width: "100%",
              height: "100vh",
              paddingTop: "10vh",
              overflow: "auto",
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          />
        ) : (
          <Box sx={[styles.body, bodyStyle ? bodyStyle : {}]}>
            {goBack ? (
              <Box
                sx={{
                  alignSelf: "flex-start",
                  ...rowStartStyles,
                  position: matches ? "fixed" : "relative",
                  top: 37,
                }}
              >
                {goBack && goBackAction ? (
                  <GoBackBtn
                    callback={goBackAction}
                    style={{}}
                    extraStyle={goBackStyle ? goBackStyle : null}
                  />
                ) : null}
              </Box>
            ) : null}
            <Box sx={[styles.header, headerStyle ? headerStyle : {}]}>
              {title ? (
                <Typography sx={styles.title}>{title}</Typography>
              ) : null}
              {subtitle ? (
                <Typography sx={styles.subtitle}>{subtitle}</Typography>
              ) : null}
              {header ? header : null}
            </Box>
            <Box
              sx={[
                styles.content,
                contentStyle ? contentStyle : {},
                matches
                  ? {
                      overflow: "auto",
                      scrollbarWidth: "none",
                      "::-webkit-scrollbar": {
                        display: "none",
                      },
                      scrollBehavior: "smooth",
                    }
                  : {},
              ]}
            >
              {content ? content : null}
            </Box>
            {globalPart ? (
              <Box
                sx={[styles.globalPart, globalPartStyle ? globalPartStyle : {}]}
              >
                {globalPart ? globalPart : null}
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </Fade>
  );
};

const styles = {
  all: {
    position: "relative",
    //...rowCenterStyles,
    ...columnStartStyles,
    width: "100%",
    height: "100vh",
    overflow: "auto",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  loading: {
    position: "relative",
    ...rowCenterStyles,
    width: "100%",
    height: "100vh",
    overflow: "auto",
    backgroundColor: Colors.PRIMARY,
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  body: {
    width: "90%",
    minHeight: "100vh",
    ...columnStartStyles,
    //rowGap: "15px",
  },
  header: {
    width: "90%",
    maxWidth: "1200px",
    minWidth: "200px",
    ...columnStartStyles,
    //rowGap: "10px",
    //paddingTop: "10vh",
  },
  content: {
    flex: 1,
    width: "100%",
    height: "90vh",
    maxWidth: "1200px",
    minWidth: "200px",
    ...columnStartStyles,
  },
  title: {
    color: Colors.WHITE,
    fontSize: "50px",
    lineHeight: "50px",
    marginBottom: "10px",
    fontFamily: Eczar.regular,
    textAlign: "center",
  },
  subtitle: {
    fontSize: "20px",
    lineHeight: "20px",
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
    marginBottom: "38px",
    textAlign: "center",
  },
  globalPart: {
    position: "relative",
    width: "90%",
  },
};

export default BackgroundColumn;
