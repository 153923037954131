/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { rowCenterStyles } from "../../style/flex";

const ModalButton = ({
    text,
    callback,
    style = null,
    textStyle = null,
    isCancelButton = false,
}: {
    text: any;
    callback: any;
    style?: any;
    textStyle?: any;
    isCancelButton?: boolean;
}) => {
    return (
        <ButtonBase
            sx={[
                styles.button,
                isCancelButton ? { backgroundColor: Colors.BTN_ACTIVE } : {},
                style ? style : {},
            ]}
            onClick={() => callback()}>
            <Typography
                sx={[
                    styles.buttonText,
                    isCancelButton ? { color: Colors.OLD_PRICE } : {},
                    textStyle ? textStyle : {},
                ]}>
                {text}
            </Typography>
        </ButtonBase>
    );
};

const styles = {
    button: {
        ...rowCenterStyles,
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    buttonText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
};

export default ModalButton;
