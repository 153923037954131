/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  ButtonBase,
  InputBase,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import BigModal from "../../../components/BigModal";
import { useCallback, useState } from "react";
import RewardPromoCode from "../../../components/RewardPromoCode";
import { getRouteManager } from "../../../services/routeManager";
import useAuth from "../../../services/Auth/useAuth";
import {
  columnCenterStyles,
  columnStartStyles,
  columnStyles,
  rowStyles,
} from "../../../style/flex";
import * as yup from "yup";
import { ReactComponent as IconKey } from "../../../assets/icons/icon_key_menu.svg";
import Loading from "../../../components/Loading";
import SoundManager from "../../../services/SoundManager";

interface Props {
  modalVisible: boolean;
  callback: any;
}

const PromoCode = (props: Props) => {
  const { modalVisible, callback } = props;
  const auth = useAuth();

  const [showEnterCode, setShowEnterCode] = useState(true);
  const [rewards, setRewards] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchCheckPromoCode = async (code: string) => {
    try {
      const api = getRouteManager().getPromoForCode();

      return await getRouteManager().fetchData(api, {
        token: auth.user.login_token,
        code: code,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkPromoCode = async (code: string): Promise<void> => {
    try {
      setLoading(true);
      const data = await fetchCheckPromoCode(code);
      if (data.flashmessage === "success") {
        if (data.rewards) {
          setRewards(data.rewards);
          SoundManager.getInstance().playUnlockTicketsSound();
          setShowEnterCode(false);
          setLoading(false);
        }
      } else {
        switch (data.flashmessage) {
          case "error":
            setError(strings.anErrorOccured);
            setLoading(false);
            break;
          case "error-already-use":
            setError(strings.promoErrors.promoAlreadyUsed);
            setLoading(false);
            break;
          case "error-sold-out":
            setError(strings.promoErrors.promoSoldOut);
            setLoading(false);
            break;
          case "error-no-rewards":
            setError(strings.promoErrors.promoNotExist);
            setLoading(false);
            break;
          case "error-not-active":
            setError(strings.promoErrors.promoNotActive);
            setLoading(false);
            break;
          case "error-no-promo":
            setError(strings.promoErrors.promoNotExist);
            setLoading(false);
            break;
          default:
            setError(strings.anErrorOccured);
            setLoading(false);
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderItemReward = useCallback(
    (item: any) => <RewardPromoCode reward={item} />,
    []
  );

  return (
    <BigModal
      modalVisible={modalVisible}
      hasSeparator={false}
      hasCloseIcon={false}
      callback={() => callback()}
      contentStyle={
        showEnterCode ? { flex: 1, padding: "0px 50px 0px 50px" } : { flex: 1 }
      }
      modalStyle={
        showEnterCode
          ? { height: "30vh", width: "400px", minHeight: "375px" }
          : {}
      }
      content={
        <Box style={styles.container}>
          {showEnterCode && (
            <Formik
              initialValues={{ code: "" }}
              onSubmit={(values) => {
                SoundManager.getInstance().playBtnSound();
                checkPromoCode(values.code);
              }}
              validationSchema={promoCodeValidationSchema}
            >
              {({
                values,
                handleChange,
                errors,
                setFieldTouched,
                touched,
                isValid,
                handleSubmit,
              }) => (
                <Box
                  style={{
                    ...columnCenterStyles,
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <IconKey width={60} height={60} fill={Colors.WHITE} />
                  <Typography sx={styles.modalTitle}>
                    {strings.promoCode}
                  </Typography>

                  {loading && (
                    <Box style={{ marginTop: "15px" }}>
                      <Loading />
                    </Box>
                  )}

                  {error && error !== "" && !loading && (
                    <Typography sx={styles.formError}>{error}</Typography>
                  )}

                  <Box
                    sx={[
                      styles.myInputView,
                      touched.code && errors.code
                        ? { borderColor: Colors.ERROR, borderWidth: 2 }
                        : {},
                    ]}
                  >
                    <IconKey width={35} height={35} fill={Colors.INPUT} />

                    <InputBase
                      value={values.code}
                      onChange={handleChange("code")}
                      onBlur={() => setFieldTouched("code")}
                      sx={styles.myInput}
                      placeholder={"code"}
                    />
                  </Box>

                  {touched.code && errors.code && (
                    <Typography sx={styles.formError}>{errors.code}</Typography>
                  )}

                  <Box style={styles.separatorReward} />

                  <Box sx={styles.popupButtons}>
                    <ButtonBase
                      sx={styles.noButton}
                      onClick={() => {
                        callback(false);
                        setError(null);
                        setShowEnterCode(true);
                        setLoading(false);
                      }}
                    >
                      <Typography sx={styles.noText}>{strings.back}</Typography>
                    </ButtonBase>

                    <ButtonBase
                      sx={styles.yesButton2}
                      disabled={!isValid && values.code === ""}
                      onClick={() => {
                        SoundManager.getInstance().playBtnSound();
                        isValid ? handleSubmit() : "";
                      }}
                    >
                      <Typography sx={styles.yesText}>
                        {strings.confirm}
                      </Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              )}
            </Formik>
          )}
          {!showEnterCode && !loading && rewards && (
            <Box
              style={{
                ...columnCenterStyles,
                alignItems: "center",
                flex: 1,
                width: "100%",
                paddingTop: "30px",
              }}
            >
              <Typography
                style={{
                  color: Colors.WHITE,
                  fontFamily: "Gilroy-Bold",
                  fontSize: normalize(20),
                  marginBottom: "10px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {strings.congrats}
              </Typography>

              <Typography
                style={{
                  color: Colors.OLD_PRICE,
                  fontFamily: "Gilroy-Bold",
                  fontSize: normalize(14),
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {strings.addedToLibrary}
              </Typography>
              <Box style={styles.separatorReward} />

              {rewards && rewards.length ? (
                <Box
                  sx={{
                    ...columnStyles,
                    justifyContent: "flex-start",
                    paddingTop: "15px",
                  }}
                >
                  {rewards.map((item: any) => renderItemReward(item))}
                  <Box sx={styles.popupButtons}>
                    <ButtonBase
                      style={styles.yesButton2}
                      onClick={() => {
                        SoundManager.getInstance().playBtnSound();
                        setRewards(null);
                        setShowEnterCode(true);
                        callback(false);
                        setError(null);
                      }}
                    >
                      <Typography sx={styles.yesText}>
                        {strings.great}
                      </Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      }
    />
  );
};

const promoCodeValidationSchema = yup.object().shape({
  code: yup.string().required(strings.promoErrors.promoNotValid),
});

const styles = {
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
  },
  modalTitle: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    marginBottom: "20px",
    textAlign: "center",
    marginTop: "10px",
  },
  formError: {
    color: Colors.ERROR,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
    marginBottom: "20px",
    textAlign: "center",
    marginTop: "10px",
  },
  myInputView: {
    width: "85%",
    height: "60px",
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
    padding: "0px 10px",
    ...rowStyles,
    alignItems: "center",
    marginTop: "30px",
  },
  myInput: {
    width: "70%",
    backgroundColor: Colors.BTN_ACTIVE,
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
    marginLeft: 0,
    height: "56px",
    marginVertical: 2,
  },
  separatorReward: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
    marginBottom: 0,
    marginTop: "20px",
  },
  popupButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "25px 0px",
  },
  yesButton: {
    backgroundColor: Colors.ERROR,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesButton2: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
};

export default PromoCode;
