import { Colors } from "../../style";
import { columnStyles, rowStyles, rowStartStyles } from "../../style/flex";
import normalize from "../../utils/fontSizer";

export const styles = {
  normalTextOld: {
    fontSize: normalize(14),
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
  },
  normalTextAccent: {
    fontSize: normalize(14),
    color: Colors.ACCENT,
    fontFamily: "Gilroy-Bold",
  },
  progressBar: {
    ...columnStyles,
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    marginBottom: "10px",
  },
  achievementNumber: {
    ...rowStyles,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  tag: {
    alignSelf: "flex-start",
    backgroundColor: Colors.BTN_ACTIVE,
    marginBottom: "5px",
  },
  rowCenter: {
    ...rowStartStyles,
  },
  separatorPrice: {
    width: "2px",
    height: "30px",
    backgroundColor: Colors.FOND_ITEM_POPUP,
    borderRadius: "3px",
    opacity: 0.7,
    margin: "0px 7px",
  },
  container: {
    alignSelf: "flex-start",
    display: "flex",
    width: "300px",
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
  },
  buyAll: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: Colors.PRIMARY,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "13px",
    overflow: "hidden",
  },
  leftPart: {
    width: "86px",
    height: "100%",
    ...rowStartStyles,
    paddingLeft: "12px",
  },
  rightPart: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    paddingLeft: "24px"
  },
  coverContainer: {
    height: "139.5px",
    width: "86px",
    minWidth: "86px",
    minHeight: "139.5px",
  },
  socialTitle: {
    fontSize: normalize(20),
    lineHeight: "22px",
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginBottom: "5px",
    textAlign: "start",
    paddingRight: "2px",
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  storyPriceBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  storyPrice: {
    fontSize: normalize(20),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "7px",
  },
  tokensOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "6px",
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokensAmountOwned: {
    fontSize: normalize(20),
    fontFamily: "Gilroy-Bold",
    color: Colors.TOKEN,
    marginLeft: "3px",
  },
  successModal: {
    position: "relative",
    width: "550px",
    height: "475px",
    outline: "none",
    backgroundColor: Colors.PRIMARY,
    border: "none",
    padding: "20px",
    borderRadius: "20px",
    zIndex: 50,
    overflow: "auto",
    webkitOverflowScrolling: "touch",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
      width: "0px",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  successTitle: {
    fontFamily: "Eczar-Regular",
    fontWeight: 600,
    fontSize: "calc(20px + 1vh)",
    marginLeft: "60px",
    /* or 23px */
    color: Colors.ACCENT,
  },
  successSub: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    marginBottom: "15px",
    marginLeft: "60px",
  },
};
