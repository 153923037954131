/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import ColorManager from "../../services/colorManager";
import { Box, Typography } from "@mui/material";
import { columnStyles } from "../../style/flex";

interface Props {
  text: string;
  icon?: any;
  type?: string;
}

const InfoBox = (props: Props) => {
  const { text, icon, type } = props;
  return (
    <Box
      sx={[
        styles.objectiveBox,
        {
          backgroundColor: ColorManager.getInstance().getColor("quinary"),
        },
      ]}
    >
      {icon ? <>{icon}</> : null}
      <Box sx={{...columnStyles, justifyContent: 'center', width: '100%',}}>
        {type ? (
          <Typography
            sx={[
              styles.infoTxt,
              {
                fontSize: normalize(12),
                color: ColorManager.getInstance().getColor("tertiary"),
                marginBottom: '3px',
              },
            ]}
          >
            {type}
          </Typography>
        ) : null}
        {text ? (
          <Typography
            sx={[
              styles.infoTxt,
              { color: ColorManager.getInstance().getColor("quaternary") },
            ]}
          >
            {text}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const styles = {
  objectiveBox: {
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    marginTop: '10px',
    borderRadius: '13px',
    padding: '12px 10px',
  },
  infoTxt: {
    width: 'calc(100% - 30px)',
    marginLeft: '15px',
    fontSize: normalize(14),
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
  },
};

export default InfoBox;
