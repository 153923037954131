/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, ButtonBase } from "@mui/material";
import React, { useRef, useState } from "react";
import './style.css'
import { ReactComponent as IconLock } from "../../assets/icons/icon_lock.svg";
import { Colors } from "../../style";

interface Props {
  updateAvatar: any;
  title: any;
  avatar: any;
  goToShop: any;
  setShowPopUpMission: any;
}

const styles = {
  itemBlock: {
    width: "12vh",
    height: "12vh",
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "1.4vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
    marginBottom: "15px",
    position: "relative",
  },
  itemBlockEquiped: {
    width: "12vh",
    height: "12vh",
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "1.4vh",
    border: '2px solid ' + Colors.ACCENT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
    marginBottom: "15px",
    position: "relative",
  },
  storyPriceBlock: {
    position: "absolute",
    top: '50%',
    bottom: '50%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

const AvatarItemEditUser = (props: Props) => {
  const { updateAvatar, title, avatar, goToShop, setShowPopUpMission } = props;
  title.equiped = title.equiped ? title.equiped : title.id === avatar[title.type]
  const animation = useRef(null);

  return (
    <Box sx={{}}>
      {title.id === null && title.type === "chapeau" && (
        <Box>
          {avatar && avatar?.chapeau != null && (
            <ButtonBase
              onClick={() => updateAvatar(title)}
              sx={styles.itemBlock}>
              <Box
                sx={{
                  width: "10vh",
                  height: "10vh",
                  backgroundColor: Colors.PRIMARY,
                  borderRadius: "10vh",
                }}
              />
            </ButtonBase>
          )}
          {avatar && !avatar?.chapeau && (
            <Box sx={styles.itemBlockEquiped}>
              <Box
                sx={{
                  width: "10vh",
                  height: "10vh",
                  backgroundColor: Colors.PRIMARY,
                  borderRadius: "10vh",
                }}
              />
            </Box>
          )}
        </Box>
      )}

      {title.id == null && title.type === "buste" && (
        <Box>
          {avatar && avatar?.buste && avatar?.buste != null && (
            <ButtonBase
              onClick={() => updateAvatar(title)}
              sx={styles.itemBlock}>
              <Box
                style={{
                  width: "10vh",
                  height: "10vh",
                  backgroundColor: Colors.PRIMARY,
                  borderRadius: "10vh",
                }}
              />
            </ButtonBase>
          )}
          {avatar && !avatar.buste && (
            <Box sx={styles.itemBlockEquiped}>
              <Box
                style={{
                  width: "10vh",
                  height: "10vh",
                  backgroundColor: Colors.PRIMARY,
                  borderRadius: "10vh",
                }}
              />
            </Box>
          )}
        </Box>
      )}

      {title.id == null && title.type == "masque" && (
        <Box>
          {avatar?.masque != null && (
            <ButtonBase
              onClick={() => updateAvatar(title)}
              sx={styles.itemBlock}>
              <Box
                style={{
                  width: "10vh",
                  height: "10vh",
                  backgroundColor: Colors.PRIMARY,
                  borderRadius: "10vh",
                }}
              />
            </ButtonBase>
          )}
          {avatar && !avatar.masque && (
            <Box sx={styles.itemBlockEquiped}>
              <Box
                style={{
                  width: "10vh",
                  height: "10vh",
                  backgroundColor: Colors.PRIMARY,
                  borderRadius: "10vh",
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {title.id != null && title.unlocked && !title.equiped && (
        <ButtonBase
          onClick={() => updateAvatar(title)}
          sx={styles.itemBlock}>
          <img
            src={title.image_backdrop_promo}
            style={{
              width: "10vh",
              height: "10vh",
              backgroundColor: Colors.LOCK,
              borderRadius: "10vh",
            }}
          />
        </ButtonBase>
      )}
      {title.id != null && title.unlocked && title.equiped && (
        <Box sx={styles.itemBlockEquiped}>
          <img
            src={title.image_backdrop_promo}
            style={{
              width: "10vh",
              height: "10vh",
              backgroundColor: Colors.LOCK,
              borderRadius: "10vh",
            }}
          />
        </Box>
      )}
      {title.id != null && !title.unlocked && (
        <ButtonBase
          onClick={() => title.shopVisible ? goToShop(title) : setShowPopUpMission(true)} sx={styles.itemBlock}>
          <img
            src={title.image_backdrop_promo}
            className={title.shopVisible ? 'grayscale' : ''}

            //tintColor={title.shopVisible ? 'none' : '#000000'}
            style={{
              width: "10vh",
              height: "10vh",
              backgroundColor: Colors.LOCK,
              borderRadius: "10vh",
              opacity: 0.3,
            }}
          />
          <Box sx={styles.storyPriceBlock}>
            <IconLock width={23.7} height={25.01} fill={Colors.WHITE} />

            {/*
            <IconTicket width={23.7} height={25.01} fill={Colors.TICKET} />
            <Text style={styles.storyPrice}>{title.price}</Text>
            */}
          </Box>
        </ButtonBase>
      )}
    </Box>
  );
};

export default React.memo(AvatarItemEditUser);
