/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import ColorManager from '../../services/colorManager';
import normalize from '../../utils/fontSizer';
import { Box, Fade, Typography } from "@mui/material";
import { strings } from '../../services/translation';
import styles from './styles';
import { ReactComponent as IconRandom } from "../../assets/icons/icon_random.svg";


// =======================================================

interface Props {
    colorAccent: any;
    textColor: any;
    notif: any;
    callback: any;
}

// =======================================================

const DiceFeedbackPopup = (props: Props) => {
    /**
     *
     */
    const { colorAccent, textColor, callback, notif } = props;

    notif.remove = true;

    const [shouldShow, setShouldShow] = useState(true);

    const [exist, setExist] = useState(true);

    const onAnimationEnd = () => {
        setTimeout(() => {
            setShouldShow(false);
            setTimeout(() => {
                setExist(false);
                callback(notif);
            }, 800);
        }, 3500);
    };

    if (exist) {
        return (
            <Fade
                in={shouldShow}
                appear
                addEndListener={() => {
                    onAnimationEnd();
                }}
                timeout={600}>

                <Box
                    sx={[
                        styles.popBg,
                        { backgroundColor: ColorManager.getInstance().getColor("quinary") },
                    ]}>
                    <IconRandom
                        width={48}
                        height={48}
                        fillSecondary={ColorManager.getInstance().getColor('primary')}
                        fill={ColorManager.getInstance().getColor('quaternary')}
                    />
                    <Box sx={styles.rightPart}>
                        <Typography sx={[styles.normalText, { color: colorAccent }]}>
                            {strings.labels.random}
                        </Typography>
                        <Typography
                            sx={[
                                styles.normalText,
                                {
                                    color: textColor,
                                    fontSize: normalize(14),
                                    justifyContent: "center",
                                    flexShrink: 1,
                                },
                            ]}>
                            {strings.messages.diceRolled}
                        </Typography>
                    </Box>
                </Box>
            </Fade>
        );
    } else {
        return null;
    }
};

// =======================================================

export default React.memo(DiceFeedbackPopup);
