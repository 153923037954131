import { Colors } from "../../style";
import {
    columnBetweenStyles,
    columnStartStyles,
    rowCenterStyles,
    columnCenterStyles,
    rowStyles,
} from "../../style/flex";
import { Eczar, Gilroy } from "../../style/fonts";
import normalize, { normalizeSize, normalizeVh } from "../../utils/fontSizer";

export const styles = {
    mainContainer: {
        position: "relative",
        width: "60vw",
        maxWidth: "650px",
        minWidth: "550px",
        height: "98vh",
        minHeight: "550px",
        maxHeight: "800px",
        borderRadius: "20px",
        backgroundColor: Colors.PRIMARY,
        overflow: "hidden",
    },
    imgContainer: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        backgroundImage: `url(${require("../../assets/images/bg-pass.png")})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    contentContainer: {
        width: "100%",
        height: "100%",
        padding: "2vh 0px 24px",
        ...columnBetweenStyles,
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    },
    container: {
        ...columnStartStyles,
        zIndex: 1,
    },
    lottieLogo: {
        width: "96px",
        height: "96px",
    },
    lottie: {
        zIndex: 3,
        width: "10vh",
        height: "10vh",
        minWidth: "96px",
        maxWidth: "120px",
        minHeight: "96px",
        maxHeight: "120px",
        marginBottom: "24px",
    },
    lottie2: {
        zIndex: 3,
        width: normalizeSize(60),
        height: normalizeSize(60),
        //marginBottom: normalizeSize(16),
    },
    item: {
        minWidth: "96px",
        maxWidth: "120px",
        minHeight: "96px",
        maxHeight: "120px",
        borderRadius: "10vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        marginBottom: "24px",
    },
    item2: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        //marginBottom: normalizeSize(16),
    },
    img: {
        width: "10vh",
        height: "10vh",
        minWidth: "96px",
        maxWidth: "120px",
        minHeight: "96px",
        maxHeight: "120px",
        borderRadius: "10vh",
        zIndex: 2,
    },
    img2: {
        width: normalizeSize(60),
        height: normalizeSize(60),
        borderRadius: normalizeSize(60),
        zIndex: 2,
    },
    gradientText: {
        fontFamily: Eczar.regular,
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "100%",
        textAlign: "center",
        marginTop: "24px",
    },
    gradientTextHeader: {
        color: Colors.WHITE,
        fontFamily: Eczar.semiBold,
        fontSize: normalizeSize(26),
        textAlign: "center",
        marginTop: normalizeVh(1),
        marginBottom: normalizeVh(1),
    },
    gradientTextBig: {
        fontFamily: Eczar.regular,
        fontWeight: 600,
        fontSize: "36px",
        lineHeight: "100%",
        textAlign: "center",
    },
    gradientTextNormal: {
        fontFamily: Eczar.regular,
        fontWeight: 600,
        fontSize: normalizeSize(22),
        lineHeight: "100%",
        textAlign: "center",
    },
    passTimer: {
        ...rowCenterStyles,
        gap: "4px",
        marginTop: "16px",
    },
    timeBox: {
        ...columnCenterStyles,
        rowGap: "0px",
        padding: "10px 10px",
        minWidth: "62px",
        minHeight: "68px",
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "7px",
    },
    time: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: "20px",
        textAlign: "center",
    },
    timeSub: {
        color: Colors.FOND_ITEM_POPUP,
        fontFamily: Gilroy,
        fontSize: "12px",
        textAlign: "center",
    },
    subtitle: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: "26px",
        lineHeight: "100%",
        textAlign: "center",
        color: Colors.OLD_PRICE,
        marginTop: normalizeVh(0.5),
        marginBottom: normalizeVh(0.5),
    },
    subtitle2: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: normalizeSize(14),
        lineHeight: "100%",
        textAlign: "left",
        color: Colors.OLD_PRICE,
        marginTop: normalizeSize(8),
    },
    subtitle2Pass: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: normalizeSize(16),
        lineHeight: "100%",
        textAlign: "left",
        color: Colors.OLD_PRICE,
    },
    priceOld: {
        fontFamily: Gilroy,
        color: Colors.OLD_PRICE,
        textDecoration: "line-through",
        fontSize: "16px",
    },
    gradientTextSmall: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        lineHeight: "100%",
        textAlign: "center",
    },
    choosePass: {
        color: Colors.WHITE,
        fontFamily: Eczar.semiBold,
        fontSize: normalizeSize(22),
        textAlign: "center",
    },
    unlockBtn: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: "16px",
        textAlign: "center",
    },
    unlockBtnDisabled: {
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        fontSize: "16px",
        textAlign: "center",
    },
    dotsContainer: {
        ...rowCenterStyles,
        alignSelf: "center",
        marginTop: "24px",
    },
    navBtn: {
        ...columnCenterStyles,
        margin: "0px 20px 0px",
        height: "100%",
    },
    carouselOuterContainer: { margin: "0 0" },
    carouselContainer: {
        width: "100%",
        overflow: "hidden",
        borderRadius: "13px",
    },
    carousel: {
        display: "flex",
        transition: "transform 0.5s ease-in-out",
        borderRadius: "13px",
    },
    overlay: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        background: Colors.BLACK,
        opacity: "0.7 !important",
        zIndex: 20,
    },
    passAmount: {
        ...rowCenterStyles,
        fontSize: normalize(20),
        fontFamily: Gilroy,
        marginLeft: "7px",
    },
    title: {
        fontFamily: Eczar.regular,
        fontWeight: 600,
        lineHeight: "100%",
        textAlign: "center",
        fontSize: "calc(24px + 2vh)",
        padding: "10px 30px 25px",
    },
    priceContainer: {
        ...rowCenterStyles,
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "7px 20px",
        borderRadius: "7px",
        marginLeft: 0,
    },
    popupButtons: {
        width: "100%",
        margin: "25px 0px",
        ...rowStyles,
        justifyContent: "center",
        columnGap: "25px",
        alignItems: "flex-end",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: "14px",
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "150px",
        height: "50px",
    },
    noText: {
        fontSize: "14px",
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    passOptions: {
        ...rowCenterStyles,
        gap: normalizeSize(12),
        padding: "0px 25px",
    },
    optionContainer: { ...columnCenterStyles, width: "100%" },
    optionContainerInside: {
        width: "100%",
        columnGap: normalizeSize(8),
    },
    playerAvatar: {
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
        width: normalizeSize(60),
        height: normalizeSize(60),
        borderRadius: normalizeSize(60),
        zIndex: 1,
    },
    passIncluded: {
        ...rowCenterStyles,
        marginTop: normalizeSize(20),
        marginBottom: normalizeSize(8),
    },
    passAdd: {
        width: "100%",
        marginTop: normalizeSize(10),
        marginBottom: normalizeSize(-10),
    },
    gradientBox: { ...columnCenterStyles, flex: 1, height: "100%" },
    gradientBoxBtn: {
        ...columnStartStyles,
        width: "100%",
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "30px 0px 10px",
        borderRadius: normalizeSize(13),
        rowGap: normalizeSize(8),
        position: "relative",
        height: "100%",
    },
    price: {
        fontFamily: Gilroy,
        fontWeight: 400,
        fontSize: "26px",
        lineHeight: "100%",
        textAlign: "center",
        marginTop: normalizeVh(0.5),
        marginBottom: normalizeVh(0.5),
        ...rowCenterStyles,
        color: Colors.WHITE,
    },
    separator: { marginBottom: normalizeSize(8) },
    cardsList: {
        ...columnCenterStyles,
        flex: 1,
        padding: "0px 10px",
        rowGap: normalizeSize(8),
    },
    valueTag: {
        backgroundColor: Colors.PRIMARY,
        marginBottom: normalizeSize(12),
        marginTop: normalizeSize(8),
    },
    tag: {
        backgroundColor: Colors.BTN_ACTIVE,
        marginBottom: normalizeSize(12),
    },
    paymentContainer: {
        position: "absolute",
        bottom: 0,
        alignSelf: "center",
        width: "100%",
        borderRadius: "20px 20px 0px 0px",
        backgroundColor: Colors.PRIMARY,
        zIndex: 50,
    },
    paymentLoading: {
        height: "100%",
        ...columnCenterStyles,
    },
    paymentContainerInside: {
        height: "100%",
        ...columnBetweenStyles,
    },
    paymentBox: {
        ...columnCenterStyles,
        paddingTop: "20px",
    },
};
