/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { Friend } from "../../../models/friend";
import { styles } from "../style";
import AvatarContainer from "../../AvatarContainer";
import { FRIENDS } from "../../../constants";
import useAuth from "../../../services/Auth/useAuth";
import { useState } from "react";
import { getRouteManager } from "../../../services/routeManager";
import ClassicModal from "../../ClassicModal";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import { isPositiveNumber } from "../../../utils/NumberHelper";

const FriendBox = ({
    friend,
    isSelected = null,
    callback,
    addFriend,
}: {
    friend: Friend;
    isSelected?: boolean | null;
    callback: any;
    addFriend?: any;
}) => {
    /**
     *
     * STATES
     *
     */

    const auth = useAuth();
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @onClickFriend
     * Dispatch click to open corresponding modal
     */
    const onClickFriend = () => {
        if (addFriend) {
            addFriend(friend);
        } else {
            if (
                friend &&
                isPositiveNumber(friend.status) &&
                friend.status === FRIENDS.STATUS.PENDING
            ) {
                setShowRequestModal(true);
            } else {
                setShowRemoveModal(true);
            }
        }
    };

    /**
     * @answerFriendRequest
     * answer request with declined or accepted status + callback to update list
     */
    const answerFriendRequest = async (requestId: number, status: number) => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                const api = getRouteManager().answerFriendRequest();
                const res = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    status: status,
                    requestId: requestId,
                });
                await callback();
                setShowRequestModal(false);
            } else {
                setShowRequestModal(false);
                window.location.href = "login";
                // go to login
            }
        } catch (err) {
            console.log(err);
            setShowRequestModal(false);
        }
    };

    /**
     * @removeFriend
     * Call remove friend api + callback to update list
     */
    const removeFriend = async () => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                const api = getRouteManager().removeFriend();
                const res = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    friendId: friend.friendId,
                });
                setShowRemoveModal(false);
                await callback();
            } else {
                setShowRemoveModal(false);
                window.location.href = "login";
                // go to login
            }
        } catch (err) {
            console.log(err);
            setShowRemoveModal(false);
        }
    };

    /**
     *
     * RENDER
     *
     */

    const renderRequestModalContent = () => (
        <Box style={styles.modalContent}>
            <AvatarContainer persoWidth={120} user={friend} borderWidth={0} />
            <Typography component="span" sx={styles.modalText}>
                <Typography
                    component="span"
                    sx={[styles.modalText, { color: Colors.WHITE }]}>
                    {friend.pseudo}
                </Typography>
                <Typography component="span" sx={styles.modalText}>
                    {strings.friends.invitedYou}
                </Typography>
                <Typography
                    component="span"
                    sx={[styles.modalText, { color: Colors.ACCENT }]}>
                    {strings.friends.friendsMin}
                </Typography>
            </Typography>
        </Box>
    );

    const renderRemoveFriendModalContent = () => (
        <Box style={styles.modalContent}>
            <AvatarContainer persoWidth={120} user={friend} borderWidth={0} />
            <Typography component="span" sx={styles.modalText}>
                <Typography component="span" sx={styles.modalText}>
                    {strings.friends.removeFriend}
                </Typography>
                <Typography
                    component="span"
                    sx={[styles.modalText, { color: Colors.WHITE }]}>
                    {friend.pseudo}
                </Typography>
                <Typography component="span" sx={styles.modalText}>
                    {strings.friends.fromYourList}
                </Typography>
            </Typography>
        </Box>
    );

    return (
        <Box>
            <ButtonBase
                onClick={() => onClickFriend()}
                disableRipple
                sx={[
                    styles.friendBox,
                    friend.status === FRIENDS.STATUS.PENDING
                        ? styles.friendBoxPending
                        : isSelected
                        ? styles.friendBoxSelected
                        : {},
                ]}>
                <Box sx={styles.friendBoxInside}>
                    <AvatarContainer
                        persoWidth={60}
                        user={friend}
                        borderWidth={0}
                        isConnected={friend?.isConnected}
                    />
                    <Box sx={styles.friendText}>
                        <Typography component={"span"} sx={styles.friendPseudo}>
                            {friend?.pseudo ? friend.pseudo : ""}
                        </Typography>
                        <Typography sx={styles.friendTitle} component={"span"}>
                            {friend?.title ? friend.title : ""}
                        </Typography>
                    </Box>
                </Box>
                <Box style={styles.levelCircle}>
                    <Typography style={styles.levelText}>
                        {friend.level ? friend.level : "?"}
                    </Typography>
                </Box>
            </ButtonBase>
            <ClassicModal
                modalVisible={showRequestModal}
                onCloseModal={() =>
                    answerFriendRequest(friend.id, FRIENDS.STATUS.ACCEPTED)
                }
                buttonText={strings.actions.accept}
                twoButtons
                buttonCancelText={strings.actions.decline}
                onCancel={() =>
                    answerFriendRequest(friend.id, FRIENDS.STATUS.DECLINED)
                }
                onBackdropPress={() => {
                    setShowRequestModal(false);
                }}
                content={renderRequestModalContent()}
            />
            <ClassicModal
                modalVisible={showRemoveModal}
                onCloseModal={() => removeFriend()}
                buttonText={strings.actions.remove}
                isErrorButton
                twoButtons
                onCancel={() => setShowRemoveModal(false)}
                onBackdropPress={() => {
                    setShowRemoveModal(false);
                }}
                content={renderRemoveFriendModalContent()}
            />
        </Box>
    );
};

export default FriendBox;
