/* eslint-disable @typescript-eslint/no-var-requires */
import { isPositiveNumber } from "../../utils/NumberHelper";
import { isNotEmptyString } from "../../utils/StringHelper";
import Sound from "./Sound";
import rand from "random-seed";

/**
 * Service permettant de gérer les sons
 * Utilisation du SoundManager (exemple)
 * const soundManager = SoundManager.getInstance();
 */
class SoundManager {
  public sounds: Sound[];
  public isSfxMuted: boolean;
  public isMusicMuted: boolean;
  public isThemeMuted: boolean;
  public isThemePlaying: boolean;
  public maxVolume: number;

  public static instance: SoundManager;

  constructor() {
    this.sounds = [];
    this.isSfxMuted = false;
    this.isMusicMuted = false;
    this.isThemeMuted = false;
    this.isThemePlaying = false;
    const vol = localStorage.getItem("volume");
    if (vol && isNotEmptyString(vol) && isPositiveNumber(parseInt(vol))) {
      this.maxVolume = parseFloat(vol);
    } else {
      this.maxVolume = 1;
    }
  }

  public static getInstance(): SoundManager {
    if (!SoundManager.instance) {
      SoundManager.instance = new SoundManager();
    }
    return SoundManager.instance;
  }

  public makeSoundID = (length: number): string => {
    try {
      let id = "";

      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      const seededRandom = rand.create(Date.now().toString());

      for (let i = 0; i < length; i++) {
        id += characters.charAt(Math.floor(seededRandom(characters.length)));
      }

      return id;
    } catch (err) {
      console.log("makeSoundID");
      return "";
    }
  };

  public addSound(
    name: string,
    url: string,
    category: string,
    volume: number,
    loop = false
  ): Sound {
    const audio: HTMLAudioElement = document.createElement("audio");
    audio.src = url;
    audio.loop = loop;
    //const id = this.sounds.length; // Génère l'ID via l'index
    const id = this.makeSoundID(15);
    const sound = new Sound(
      id,
      name,
      audio,
      category,
      volume < 1 ? volume : this.maxVolume,
      loop
    );
    //sound.id = this.sounds.length; // Génère l'id via l'index
    this.sounds.push(sound);

    sound.play(); // Joue le son directement après l'ajout

    return sound;
  }

  public getSound(id: string): Sound | undefined {
    return this.sounds.find((sound) => sound.id === id);
  }

  public getMaxVolume(): number {
    return this.maxVolume;
  }

  public setMaxVolume(volume: number) {
    this.maxVolume = volume;
    this.sounds.map((sound: Sound) => {
      if (sound && sound.volume && sound.volume !== volume) {
        sound.volume = volume;
        if (sound.audio && sound.audio.volume !== volume) {
          sound.audio.volume = volume;
        }
      }
    });
  }

  public getSoundsByCategory(category: string): Sound[] {
    return this.sounds.filter((sound) => sound.category === category);
  }

  public removeSound(id: string): void {
    const index = this.sounds.findIndex((sound) => sound.id === id);
    if (index !== -1) {
      this.sounds[index].stop();
      this.sounds.splice(index, 1);
    }
  }

  public removeSoundsByCategory(category: string): void {
    const soundsToRemove = this.sounds.filter(
      (sound) => sound.category === category
    );

    soundsToRemove.forEach((sound) => {
      sound.stop();
      const index = this.sounds.indexOf(sound);
      this.sounds.splice(index, 1);
    });
  }

  public removeAllSounds(): void {
    this.sounds.forEach((sound) => sound.stop());
    this.sounds = [];
  }

  public muteSfx(): void {
    this.isSfxMuted = true;
    this.sounds.forEach((sound) => {
      if (sound.category === "sfx") {
        sound.stop();
      }
    });
  }

  public muteMusic(): void {
    this.isMusicMuted = true;
    this.sounds.forEach((sound) => {
      if (sound.category === "music") {
        sound.stop();
      }
    });
  }

  public muteTheme(): void {
    this.isThemeMuted = true;
    this.sounds.forEach((sound) => {
      if (sound.category === "theme") {
        sound.stop();
      }
    });
  }

  public unmuteSfx(): void {
    this.isSfxMuted = false;
    this.sounds.forEach((sound) => {
      if (sound.category === "sfx") {
        sound.play();
      }
    });
  }

  public unmuteMusic(): void {
    this.isMusicMuted = false;
    this.sounds.forEach((sound) => {
      if (sound.category === "music") {
        sound.play();
      }
    });
  }

  public unmuteTheme(): void {
    this.isThemeMuted = false;
    this.sounds.forEach((sound) => {
      if (sound.category === "theme") {
        sound.play();
      }
    });
  }

  public playSfxByFilename(name: string, filename: string): void {
    if (this.isSfxMuted) {
      return;
    } else {
      const url = require(`./audio/${filename}`);
      this.addSound(name, url, "sfx", this.maxVolume);
    }
  }

  public playMusicByFilename(name: string, filename: string): void {
    if (this.isMusicMuted) {
      return;
    } else {
      const url = `./audio/${filename}`;
      this.addSound(name, url, "music", this.maxVolume, true);
    }
  }

  /* ************ GENERIC FUNCTIONS ************ */

  public playTheme(): void {
    if (this.isThemeMuted) {
      return;
    } else if (!this.isThemePlaying) {
      const url = require("./audio/talesup_music_menutheme.aac");
      this.addSound("theme", url, "theme", this.maxVolume, true);
      this.isThemePlaying = true;
    } else {
      return;
    }
  }

  public stopTheme(): void {
    this.removeSoundsByCategory("theme");
    this.isThemePlaying = false;
  }

  public playAppOpeningSound(): void {
    const filename = `talesup_ui_jingle_ouverture_app.aac`;
    this.playSfxByFilename("opening", filename);
  }

  public playBtnSound(): void {
    const filename = `talesup_ui_bouton_general.aac`;
    this.playSfxByFilename("button", filename);
  }

  public playOpenStorySound(): void {
    const filename = `talesup_ui_bouton_ouvrir_histoire_bibliotheque.aac`;
    this.playSfxByFilename("story-open", filename);
  }

  public playStoryStartSound(): void {
    const filename = `talesup_ui_ecran_debut_partie.aac`;
    this.playSfxByFilename("story-start", filename);
  }

  public playAvatarColorChangeSound(): void {
    const soundToPlay = Math.floor(Math.random() * 3) + 1;
    const filename = `talesup_ui_avatar_changer_apparence_couleur_0${soundToPlay}.aac`;
    this.playSfxByFilename("avatar-color", filename);
  }

  public playAvatarItemChangeSound(): void {
    const soundToPlay = Math.floor(Math.random() * 3) + 1;
    const filename = `talesup_ui_avatar_changer_apparence_vetements_0${soundToPlay}.aac`;
    this.playSfxByFilename("avatar-item", filename);
  }

  public playChoiceMadeSound(): void {
    const soundToPlay = Math.floor(Math.random() * 3) + 1;
    const filename = `talesup_ui_bouton_avancer_texte_0${soundToPlay}.aac`;
    this.playSfxByFilename("choice", filename);
  }

  public playUnlockItemSound(): void {
    const filename = `talesup_ui_jingle_debloquer_cosmetique.aac`;
    this.playSfxByFilename("unlock-item", filename);
  }

  public playUnlockTicketsSound(): void {
    const filename = `talesup_ui_jingle_debloquer_tickets.aac`;
    this.playSfxByFilename("unlock-tickets", filename);
  }

  public playUnlockStorySound(): void {
    const filename = `talesup_ui_jingle_debloquer_histoire.aac`;
    this.playSfxByFilename("unlock-story", filename);
  }

  public playLevelUpSound(): void {
    const filename = `talesup_ui_jingle_levelup.aac`;
    this.playSfxByFilename("level-up", filename);
  }

  public playOpenModalSound(): void {
    const filename = `talesup_ui_bouton_credits_ouvrir.aac`;
    this.playSfxByFilename("open-modal", filename);
  }

  public playCloseModalSound(): void {
    const filename = `talesup_ui_bouton_credits_fermer.aac`;
    this.playSfxByFilename("close-modal", filename);
  }

  public playTimerSound(): void {
    const filename = `talesup_ui_decompte.aac`;
    this.playSfxByFilename("timer", filename);
  }

  public playTopMenuSound(): void {
    const filename = `talesup_sfx_ui_menu_open_v.wav`;
    this.playSfxByFilename("menu-open", filename);
  }

  public playTopMenuCloseSound(): void {
    const filename = `talesup_sfx_ui_menu_close_v.wav`;
    this.playSfxByFilename("menu-close", filename);
  }

  public playMenuInteraction(): void {
    const filename = `talesup_sfx_ui_menu_click_v.wav`;
    this.playSfxByFilename("menu-click", filename);
  }

  public playCarouselSwipeSound(): void {
    const filename = `talesup_ui_swipe_histoires.aac`;
    this.playSfxByFilename("carousel-swipe", filename);
  }

  public playTabBarSound(): void {
    const filename = `talesup_ui_bouton_barre_laterale.aac`;
    this.playSfxByFilename("tab-swipe", filename);
  }
}

export default SoundManager;

/* this.menuMusic = new Sound(
      "talesupmusicmenutheme.wav",
      Sound.MAINBUNDLE
    );
    this.btnSound = new Sound(
      "talesupuiboutongeneral.aac",
      Sound.MAINBUNDLE
    );
     */
