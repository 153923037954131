/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnCenterStyles, columnStyles } from "../../../style/flex";

// =======================================================

const centerStyles: any = {
  ...columnCenterStyles,
};

export default {
  container: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: "32px",
    gap: "32px",
  },
  /* lottie: {
    flex: 3,
    width: "100%",
    ...columnStyles,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  lottiePlayer: {
    width: "38.2vw",
    maxWidth: "640px",
    minWidth: "590px",
  }, */
  buttonContainer: {
    /* position: "absolute",
    bottom: "32px", */
    position: 'relative',
    ...columnStyles,
    zIndex: 50,
    alignSelf: "center",
    alignItems: "center",
  },
  changeContainer: {
    ...centerStyles,
    marginBottom: "10px",
  },
  changing: {
    color: Colors.WHITE,
    fontSize: normalize(24),
    fontFamily: "Eczar-Regular",
    marginTop: "5px",
  },
  letter: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    borderWidth: "2px",
    ...centerStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
};

// =======================================================
