export const pirateJson = {
  v: '5.7.4',
  fr: 29.9700012207031,
  ip: 0,
  op: 45.0000018328876,
  w: 300,
  h: 60,
  nm: 'Composition 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Fill',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [241.928, 44.06, 0], ix: 2, l: 2 },
        a: { a: 0, k: [91.928, 14.06, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0.833], y: [1, 1] },
                    o: { x: [0.696, 0.167], y: [0, 0] },
                    t: 0,
                    s: [100, 100],
                  },
                  { t: 45.0000018328876, s: [725, 100] },
                ],
                ix: 2,
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 282, ix: 4 },
              nm: 'Tracé rectangulaire 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.215685990277, 0.168626987233, 0.341175991881, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Contour 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'gf',
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0,
                    0.475,
                    0.439,
                    1,
                    0.5,
                    0.639,
                    0.359,
                    0.978,
                    1,
                    0.804,
                    0.278,
                    0.957,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [-50.5, 0], ix: 5 },
              e: { a: 0, k: [363, 0], ix: 6 },
              t: 1,
              nm: 'Gradient Fill 1',
              mn: 'ADBE Vector Graphic - G-Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-250.5, 0.25], ix: 2 },
              a: { a: 0, k: [-50.5, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 623.00002537531,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'BG',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [149, 37, 0], ix: 2, l: 2 },
        a: { a: 0, k: [185, 94, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [6.623, 0],
                    [0, 0],
                    [0, -6.623],
                    [0, 0],
                    [-6.623, 0],
                    [0, 0],
                    [0, 6.623],
                  ],
                  o: [
                    [0, -6.623],
                    [0, 0],
                    [-6.623, 0],
                    [0, 0],
                    [0, 6.623],
                    [0, 0],
                    [6.623, 0],
                    [0, 0],
                  ],
                  v: [
                    [150, -18],
                    [138, -30],
                    [-138, -30],
                    [-150, -18],
                    [-150, 18],
                    [-138, 30],
                    [138, 30],
                    [150, 18],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Tracé 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.145098039216, 0.149019607843, 0.18431372549, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fond 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [186.047, 86.919], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Groupe 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 623.00002537531,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
