import { Colors } from "../../style";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowBetweenStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import normalize, { normalizeSize, normalizeVh } from "../../utils/fontSizer";

export const styles = {
    mainContainer: {
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        maxHeight: "calc(96vh - 20px)",
        flex: "1 1 auto",
    },
    mainContainerBig: {
        ...rowCenterStyles,
        alignItems: "flex-start",
    },
    mainContainerSmall: {
        ...columnStyles,
        alignItems: "center",
    },
    container: {
        width: "100%",
        alignItems: "center",
        ...columnStyles,
        justifyContent: "flex-start",
        maxWidth: "450px",
        minWidth: "450px",
        rowGap: "10px",
        paddingTop: "4vh",
        paddingBottom: "50px",
        flex: "0 0 auto",
    },
    containerSmall: {
        width: "100%",
        ...columnStartStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
        flex: "0 0 auto",
    },
    topMenuTitle: {
        width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "20px",
    },
    playerCode: {
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        fontSize: normalize(16),
        ...rowStyles,
        alignItems: "center",
    },
    right: {
        ...columnStyles,
        //flex: 1,
        justifyContent: "flex-start",
        maxWidth: normalizeSize(450),
        minWidth: normalizeSize(450),
        paddingTop: normalizeVh(4),
        paddingBottom: normalizeSize(50),
        rowGap: normalizeSize(16),
    },
    //Friend screen start
    list: {
        ...columnStyles,
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        columnGap: "10px",
        maxWidth: normalizeSize(1020),
        width: "100%",
        minWidth: normalizeSize(450),
    },
    listSmall: {
        ...columnStyles,
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: normalizeSize(8),
        maxWidth: normalizeSize(1020),
        width: normalizeSize(300),
    },
    friendsCategory: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(18),
        fontWeight: 600,
        color: Colors.WHITE,
    },
    friendBox: {
        width: normalizeSize(300),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(13),
        alignItems: "center",
        flex: 1,
        ...rowBetweenStyles,
        padding: normalizeSize(10),
        flexShrink: 0,
        alignSelf: "center",
    },
    friendBoxPending: {
        border: `2px solid ${Colors.ACCENT}`,
    },
    friendBoxSelected: {
        border: `2px solid ${Colors.HEART}`,
    },
    friendBoxInside: {
        ...rowStartStyles,
        gap: normalizeSize(12),
    },
    friendText: {
        ...columnStyles,
        alignItems: "flex-start",
    },
    friendPseudo: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(18),
        fontWeight: 600,
        color: Colors.WHITE,
        ...rowStartStyles,
        columnGap: normalizeSize(7),
    },
    friendTitle: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        fontWeight: 600,
        color: Colors.ACCENT,
    },
    levelCircle: {
        width: normalizeSize(40),
        height: normalizeSize(40),
        borderRadius: normalizeSize(40),
        border: `solid 2px ${Colors.ACCENT}`,
        ...columnCenterStyles,
        backgroundColor: Colors.BTN_ACTIVE,
        zIndex: 10,
    },
    levelText: {
        fontSize: normalizeSize(20),
        lineHeight: normalizeSize(20),
        fontFamily: Gilroy,
        color: Colors.ACCENT,
    },
    addBtn: {
        marginTop: normalizeSize(30),
    },
    //modal
    modalView: {
        position: "relative",
        width: normalizeSize(300),
        backgroundColor: Colors.PRIMARY,
        borderRadius: normalizeSize(20),
        paddingTop: normalizeSize(24),
        paddingBottom: normalizeSize(16),
        ...columnCenterStyles,
        outline: "none",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    modalCodeIconBackground: {
        width: normalizeSize(120),
        height: normalizeSize(120),
        borderRadius: normalizeSize(120),
        backgroundColor: Colors.BTN_ACTIVE,
        ...columnCenterStyles,
    },
    modalContent: {
        ...columnStyles,
        alignItems: "center",
        width: "100%",
    },
    modalText: {
        fontSize: normalize(14),
        fontFamily: Gilroy,
        color: Colors.OLD_PRICE,
        textAlign: "center",
        marginTop: normalizeSize(30),
    },
    myInputView: {
        width: "100%",
        height: normalizeSize(60),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(13),
        paddingLeft: normalizeSize(10),
        ...rowCenterStyles,
        alignItems: "center",
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: normalize(16),
    },
    inputError: {
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(12),
        color: Colors.ERROR,
        marginTop: normalizeSize(15),
        textAlign: "center",
    },
    whiteText: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: normalizeSize(16),
    },
    nextButton: {
        position: "relative",
        top: normalizeSize(36),
        width: normalizeSize(45),
        height: normalizeSize(45),
        borderRadius: normalizeSize(45),
        backgroundColor: Colors.PRIMARY,
    },
    connectedIndicator: {
        alignSelf: "center",
        width: normalizeSize(8),
        height: normalizeSize(8),
        borderRadius: normalizeSize(8),
    },
    // friend screen end
};
