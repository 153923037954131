/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { User } from "./user";
import appVersion from "../../utils/roads";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { SERVER_MAINTENANCE } from "../../constants/parameters";
import { isNotEmptyString } from "../../utils/StringHelper";
import { getLanguage } from "../translation";
import { Mission } from "../../models/mission";
import { isTrueBoolean } from "../../utils/TypeOfHelper";

class RouteManager {
    private _apiUrl: string;
    private _user: User | any;
    private cookiesAcceptedOn: any;
    private fbEnabled: boolean;
    private googleEnabled: boolean;
    private type: string;
    private auxiliaryUrl: string;

    constructor() {
        // CHANGE TO prod2 | test | preprod to change server type and urls everywhere

        //this.type = "prod";
        //this.type = "prod2";
        //this.type = 'preprod';
        this.type = "test";
        //this.type = 'local';

        this.fbEnabled = false;
        this.googleEnabled = false;

        switch (this.type) {
            case "prod":
                this._apiUrl = "https://talesup.io/tool/";
                break;
            case "prod2":
                this._apiUrl = "https://prod2.talesup.io/tool/";
                break;
            case "test":
                this._apiUrl = "https://test.talesup.io/tool/";
                break;
            case "preprod":
                this._apiUrl = "https://preprod.talesup.io/tool/";
                break;
            case "local":
                this._apiUrl = "http://10.22.16.191:8002/tool/";
                break;
            default:
                this._apiUrl = "https://prod2.talesup.io/tool/";
                break;
        }
        this.auxiliaryUrl = "https://auxiliary.talesup.io/tool/";
        //this._apiUrl = 'https://preprod.talesup.io/tool/';
        //this._apiUrl = 'https://test.talesup.io/tool/';
        //this._apiUrl = 'https://prod2.talesup.io/tool/';
        //this._apiUrl = 'https://talesup.io/tool/';
    }

    public getUser(): User {
        return this._user;
    }

    public setUser(user: any): any {
        this._user = new User(
            user.id,
            user.email,
            user.player_code,
            user.tickets,
            user.lang,
            user.login_token,
            user.pseudo,
            user.avatar,
            user.level,
            user.experience,
            user.nextLevel,
            user.currentLevel,
            user.title ? user.title : null,
            user.tokens,
        );
    }

    public getServerType(): string {
        return this.type;
    }

    public getCookiesAcceptedOn(): any {
        return this.cookiesAcceptedOn;
    }

    public getFbEnabled(): any {
        return this.fbEnabled;
    }

    public getGoogleEnabled(): any {
        return this.googleEnabled;
    }

    public setCookiesAcceptedOn(val: any): void {
        this.cookiesAcceptedOn = val;
    }

    public setFbEnabled(val: boolean): void {
        this.fbEnabled = val;
    }

    public setGoogleEnabled(val: boolean): void {
        this.googleEnabled = val;
    }

    public getApiUrl(): string {
        return this._apiUrl;
    }

    public getParamForType(param: string | any): any {
        if (isNotEmptyString(param)) {
            return this.type + "_" + param;
        } else {
            return param;
        }
    }

    /*---------------- UTILS  ----------------*/

    public async getData(
        url: string,
        cancelSignal: AbortSignal | undefined = undefined,
    ) {
        try {
            const res = await fetch(url, { signal: cancelSignal });
            return res.json();
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    public fetchData = async (
        url: string,
        body: any,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> => {
        try {
            const data = await fetch(`${url}`, {
                signal: cancelSignal,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });
            return data.json();
        } catch (err: any) {
            console.log(err);
        }
    };

    /*---------------- VERSION  ----------------*/

    public checkVersion(): string {
        return (
            this._apiUrl +
            "version/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/checkVersion"
        );
    }

    public getAvailableVersionsForInstance(): string {
        return (
            this.auxiliaryUrl +
            "version/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAvailableVersionsForInstance"
        );
    }

    public async postGetAvailableVersions(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        const url = this.getAvailableVersionsForInstance();
        const getVersions = await fetch(`${url}`, {
            signal: cancelSignal,
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                instance:
                    this.getServerType() === "local"
                        ? "test"
                        : this.getServerType(),
            }),
        });
        return getVersions.json();
    }

    public async isVersionOk(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<boolean> {
        let isOk = false;
        const versions = await this.postGetAvailableVersions(cancelSignal);
        if (isNotEmptyArray(versions)) {
            const exists = versions.find(
                (item: any) => item.version === appVersion,
            );
            if (exists) {
                isOk = true;
            }
        }
        return isOk;
    }

    public async isMaintenanceOk(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<boolean> {
        let isOk = true;
        const param = this.getParamForType(SERVER_MAINTENANCE);
        const isMaintenanceModeActivated = await this.postCheckParamValueNew(
            param,
            cancelSignal,
        );
        if (
            isMaintenanceModeActivated &&
            isTrueBoolean(isMaintenanceModeActivated.value)
        ) {
            isOk = false;
        }
        return isOk;
    }

    public async isVersionAndMaintenanceOk(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<boolean> {
        const versionOk = await this.isVersionOk(cancelSignal);
        const maintenanceOk = await this.isMaintenanceOk(cancelSignal);
        if (versionOk && maintenanceOk) {
            return true;
        } else {
            return false;
        }
    }

    public getNbStories(lang: string): string {
        return (
            this._apiUrl +
            "api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getNbstories/" +
            lang
        );
    }

    /*---------------- PARAMETERS  ----------------*/

    public getParamValue(): string {
        return (
            this._apiUrl +
            "parameters/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getParamValue"
        );
    }

    public getParamValueNew(): string {
        return (
            this.auxiliaryUrl +
            "parameters/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getParamValue"
        );
    }

    public async postCheckParamValueNew(
        paramName: string,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const apiUrl = this.getParamValueNew();
            const data = await fetch(`${apiUrl}`, {
                signal: cancelSignal,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: paramName,
                }),
            });
            return data.json();
        } catch (err) {
            console.log(err);
        }
    }

    /*---------------- CODE PROMO  ----------------*/

    public getPromoForCode(): string {
        return (
            this._apiUrl +
            "promoCode/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getPromoForCode"
        );
    }

    /*---------------- USER  ----------------*/

    public loginByToken(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/loginByToken"
        );
    }

    public login(): string {
        return this._apiUrl + "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/login";
    }

    public loginWithGoogle(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/loginWithSocials"
        );
    }

    public loginWithSocialsNew(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/loginWithSocialsNew"
        );
    }

    public loginWithFacebook(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/loginWithSocials"
        );
    }

    public loginWithApple(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/loginWithSocials"
        );
    }

    public updateCookiesAccepted(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updateCookiesAccepted"
        );
    }

    public getUserInfos(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserInfos"
        );
    }

    public async fetchGetUserInfos(
        user: any | undefined,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const url = getRouteManager().getUserInfos();
            const token = user.login_token
                ? user.login_token
                : this.getUser().getLoginToken()
                ? this.getUser().getLoginToken()
                : null;
            if (token) {
                const getUser = await fetch(`${url}`, {
                    signal: cancelSignal,
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        login_token: token,
                    }),
                });
                return getUser.json();
            } else return null;
        } catch (err) {
            console.log(err);
        }
    }

    public askForResetPassword(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/askForResetPassword"
        );
    }

    public register(): string {
        return (
            this._apiUrl + "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/register"
        );
    }

    public registerV2(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/registerV2"
        );
    }

    public registerTemporaryAccount(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/registerTemporaryAccount"
        );
    }

    public finalizeRegisterTemporary(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/finalizeRegisterTemporary"
        );
    }

    public finalizeRegisterTemporaryWithSocials(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/finalizeRegisterTemporaryWithSocials"
        );
    }

    public askForActivationEmail(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/askForActivationEmail"
        );
    }

    public updatePseudo(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updatePseudo"
        );
    }

    public changeUserLang(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updateUserLang"
        );
    }

    public async updateUserLang(
        lang: string,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const url = getRouteManager().changeUserLang();
            const token = this.getUser().getLoginToken();
            const getUser = await fetch(`${url}`, {
                signal: cancelSignal,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login_token: token,
                    lang: lang,
                }),
            });
            return getUser.json();
        } catch (err) {
            console.log(err);
        }
    }

    public changeTitle(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/changeTitle/"
        );
    }

    public unlockTitle(): string {
        return (
            this._apiUrl +
            "playerTitle/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/unlockTitle"
        );
    }

    public setFtueDone(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/setFtueDone"
        );
    }

    public getUserFromCode(code: string): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserFromCode/" +
            code
        );
    }

    public askForAccountDeletion(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/askForAccountDeletion"
        );
    }

    public async sendAskForAccountDeletion(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        const url = getRouteManager().askForAccountDeletion();
        const token = this.getUser().getLoginToken();
        if (isNotEmptyString(token)) {
            try {
                const res = await fetch(`${url}`, {
                    signal: cancelSignal,
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        login_token: token,
                    }),
                });
                return res.json();
            } catch (err) {
                console.log(err);
                return null;
            }
        } else {
            return null;
        }
    }

    public updateUserExperienceAndLevel(): string {
        return (
            this._apiUrl +
            "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updateUserExperienceAndLevel"
        );
    }

    public async fetchUpdateUserExperienceAndLevel(
        xp: number,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const url = getRouteManager().updateUserExperienceAndLevel();
            const token = this.getUser().getLoginToken();
            const level = this.getUser().getLevel();
            const experience = xp;
            const getUser = await fetch(`${url}`, {
                signal: cancelSignal,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login_token: token,
                    level: level,
                    experience: experience,
                }),
            });
            return getUser.json();
        } catch (err) {
            console.log(err);
        }
    }

    /*---------------- USER STORY ----------------*/

    public getUserStories(lang: string, id: number): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserStories/" +
            lang +
            "/" +
            id.toString()
        );
    }

    public getUserStoryForUserForStory(): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserStoryForUserForStory/"
        );
    }

    public getUserStoriesLibrary(lang: string, id: number): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserStoriesLibrary/" +
            lang +
            "/" +
            id.toString()
        );
    }

    public getStoriesToBuy(): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getStoriesToBuy"
        );
    }

    public async postGetStoriesToBuy(): Promise<any> {
        try {
            const url = this.getStoriesToBuy();
            const token = this.getUser().getLoginToken();
            const getStories = await fetch(`${url}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login_token: token,
                    lang: getLanguage(),
                }),
            });
            return getStories.json();
        } catch (err) {
            console.log(err);
        }
    }

    public purchaseStory(): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseStory"
        );
    }

    public purchaseStoryWithTokens(): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseStoryWithTokens"
        );
    }

    public purchaseSeries(): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseSeries"
        );
    }

    public setUserStoryPlayed(): string {
        return (
            this._apiUrl +
            "userStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/setUserStoryPlayed"
        );
    }

    public getOfferedStories(lang: string): string {
        return (
            this._apiUrl +
            "offeredStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getOfferedStories/" +
            lang
        );
    }

    /*---------------- SERIES  ----------------*/

    public getParentEpisodeForStory(storyId: number): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getParentEpisodeForStory/" +
            storyId.toString()
        );
    }

    public getEpisodeInfos(storyId: number): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getEpisodeInfosForStory/" +
            storyId.toString()
        );
    }

    public getUserSeries(userId: number, lang: string): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserSeries/" +
            lang +
            "/" +
            userId.toString()
        );
    }

    public getUserSeriesLibrary(userId: number, lang: string): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getUserSeriesLibrary/" +
            lang +
            "/" +
            userId.toString()
        );
    }

    public getEpisodesForSeries(
        seriesId: number,
        userToken: string,
        lang: string,
    ): string {
        return (
            this._apiUrl +
            "seriesEpisode/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getEpisodesForSeries/" +
            seriesId.toString() +
            "/" +
            lang +
            "/" +
            userToken
        );
    }

    public getSeriesToBuy(userId: number, lang: string): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSeriesToBuy/" +
            userId.toString() +
            "/" +
            lang
        );
    }

    public getSeriesToBuyNew(): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSeriesToBuyNew"
        );
    }

    public async postGetSeriesToBuy(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const url = this.getSeriesToBuyNew();
            const token = this.getUser().getLoginToken();
            const getStories = await fetch(`${url}`, {
                signal: cancelSignal,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login_token: token,
                    lang: getLanguage(),
                }),
            });
            return getStories.json();
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    public getSeries(id: number, userId: number, lang: string): string {
        return (
            this._apiUrl +
            "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSeries/" +
            id.toString() +
            "/" +
            userId.toString() +
            "/" +
            lang
        );
    }

    /*---------------- COVER  ----------------*/

    public getCoverForSeries(storyId: number): string {
        return storyId
            ? this._apiUrl +
                  "series/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCoverForSeries/" +
                  storyId.toString()
            : "";
    }

    /*---------------- SAVES  ----------------*/

    public createSaveForUser(): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/createSaveForUser"
        );
    }

    public replaceSaveForUser(): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/replaceSaveForUser"
        );
    }

    public getSavesForUser(id: number, lang: string, type: string): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSavesForUser/" +
            id.toString() +
            "/" +
            lang +
            "/" +
            type
        );
    }

    public getSavesForUserForStory(
        id: number,
        lang: string,
        storyId: number,
        type: string,
    ): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSavesForUserForStory/" +
            id.toString() +
            "/" +
            lang +
            "/" +
            storyId.toString() +
            "/" +
            type
        );
    }

    public getAllSavesForUserForStory(): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAllSavesForUserForStory"
        );
    }

    public getSave(id: number): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSave/" +
            id.toString()
        );
    }

    public deleteSaveForUser(): string {
        return (
            this._apiUrl +
            "saves/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/deleteSaveForUser/"
        );
    }

    /*---------------- SUCCESS  ----------------*/

    public createUserSuccess(): string {
        return (
            this._apiUrl +
            "userSuccess/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/createUserSuccess"
        );
    }

    public createUserSuccessForPlayerCode(): string {
        return (
            this._apiUrl +
            "userSuccess/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/createUserSuccessForPlayerCode"
        );
    }

    public getSuccessForUserForSeriesForLang(
        userId: number,
        seriesId: number,
        lang: string,
    ) {
        return (
            this._apiUrl +
            "success/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSuccessForUserForSeriesForLang/" +
            userId.toString() +
            "/" +
            seriesId.toString() +
            "/" +
            lang
        );
    }

    public getSuccessForUserForStoryForLang(
        userId: number,
        storyId: number,
        lang: string,
    ) {
        return (
            this._apiUrl +
            "success/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getSuccessForUserForStoryForLang/" +
            userId.toString() +
            "/" +
            storyId.toString() +
            "/" +
            lang
        );
    }

    public checkSucccessForUser(): string {
        return (
            this._apiUrl +
            "userSuccess/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/checkSucccessForUser"
        );
    }

    public getTitlesList(lang: string): string {
        return (
            this._apiUrl +
            "playerTitle/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/list/" +
            lang
        );
    }

    public getTitle(id: number, lang: string): string {
        return (
            this._apiUrl +
            "playerTitle/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getTitle/" +
            id.toString() +
            "/" +
            lang
        );
    }

    /*---------------- MISSIONS  ----------------*/

    public getAllMissionsForUser(): string {
        return (
            this._apiUrl +
            "mission/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAllMissionsForUser"
        );
    }

    fetchGetAllMissionsForUser = async (): Promise<Array<Mission> | null> => {
        return await this.fetchData(this.getAllMissionsForUser(), {
            login_token: this.getUser().getLoginToken(),
            lang: getLanguage(),
        });
    };

    public reclaimReward(): string {
        return (
            this._apiUrl +
            "mission/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/reclaimRewardNew"
        );
    }

    public unlockMissionForUser(): string {
        return (
            this._apiUrl +
            "mission/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/unlockMissionForUser"
        );
    }

    public getMissionUnclaimedNumberForUser(): string {
        return (
            this._apiUrl +
            "mission/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getMissionUnclaimedNumberForUser"
        );
    }

    /*---------------- PACKS  ----------------*/

    public getPacksForUser(): string {
        return (
            this._apiUrl +
            "pack/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getPacksForUser"
        );
    }

    public getPackImage(id: number): string | undefined {
        return (
            this._apiUrl +
            "packTrad/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/packImage/" +
            id.toString()
        );
    }

    public getPackImageEnd(id: number): string | undefined {
        return (
            this._apiUrl +
            "packTrad/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/packImageEnd/" +
            id.toString()
        );
    }

    public getPackImageHeader(id: number): string | undefined {
        return (
            this._apiUrl +
            "packTrad/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/packImageHeader/" +
            id.toString()
        );
    }

    public purchasePack(): string {
        return (
            this._apiUrl +
            "pack/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchasePack"
        );
    }

    public async postPurchasePack(
        id: number,
        user?: any,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const url = this.purchasePack();
            const token = this.getUser().getLoginToken();
            const purchase = await fetch(`${url}`, {
                signal: cancelSignal,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login_token: token,
                    lang: getLanguage(),
                    packId: id,
                }),
            });
            return purchase.json();
        } catch (err) {
            console.log(err);
        }
    }
    /*---------------- ITEMS  ----------------*/

    public getAllUserItemsForUser(): string {
        return (
            this._apiUrl +
            "item/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAllUserItemsForUser"
        );
    }

    public getAllUserItemsForUserCompanion(): string {
        return (
            this._apiUrl +
            "item/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAllUserItemsForUserCompanion"
        );
    }

    public createUserItem(): string {
        return (
            this._apiUrl +
            "item/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/createUserItem"
        );
    }

    public createUserItemWithTokens(): string {
        return (
            this._apiUrl +
            "item/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/createUserItemWithTokens"
        );
    }

    public getItemImage(id: number): string {
        return (
            this._apiUrl +
            "item/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/image/" +
            id.toString()
        );
    }

    public getItemImageForVersion(id: number, version: string): string {
        return (
            this._apiUrl +
            "item/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/image/" +
            id.toString() +
            "/" +
            version
        );
    }

    /*---------------- AVATAR  ----------------*/

    public updateAvatarForUser(): string {
        return (
            this._apiUrl +
            "avatar/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updateAvatarForUser"
        );
    }

    public getAvatarForUser(): string {
        return (
            this._apiUrl +
            "avatar/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAvatarForUser"
        );
    }

    public getAvatarById(): string {
        return (
            this._apiUrl +
            "avatar/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAvatarById"
        );
    }

    /*---------------- ARTIST  ----------------*/

    public getStoryArtistsForStory(storyId: number): string {
        return (
            this._apiUrl +
            "storyArtist/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getStoryArtistsForStory/" +
            storyId.toString()
        );
    }

    public getStoryArtistsForSeries(seriesId: number): string {
        return (
            this._apiUrl +
            "storyArtist/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getStoryArtistsForSeries/" +
            seriesId.toString()
        );
    }

    /*---------------- PURCHASES  ----------------*/

    public purchaseTickets(): string {
        return (
            this._apiUrl +
            "purchase/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseTickets/"
        );
    }

    public purchaseTicketsNew(): string {
        return (
            this._apiUrl +
            "purchase/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseTicketsNew/"
        );
    }

    public purchaseTicketsWeb(): string {
        return (
            this._apiUrl +
            "purchase/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseTicketsWeb/"
        );
    }

    public achievePurchaseWeb(): string {
        return (
            this._apiUrl +
            "purchase/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/achievePurchaseWeb/"
        );
    }

    public setPurchaseComplete(): string {
        return (
            this._apiUrl +
            "purchase/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/setPurchaseComplete/"
        );
    }

    /*---------------- CAROUSEL  ----------------*/

    public getCarouselForLang(): string {
        return (
            this._apiUrl +
            "carousel/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCarouselForLang"
        );
    }

    public getCarouselItemImage(tradId: number): string {
        return (
            this._apiUrl +
            "carouselItemTrad/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCarouselItemImage/" +
            tradId.toString()
        );
    }

    public getCarouselBackgroundImage(itemId: number): string {
        return (
            this._apiUrl +
            "carousel/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCarouselBackgroundImage/" +
            itemId.toString()
        );
    }

    /*---------------- PROMO  ----------------*/

    public getTickets(): string {
        return (
            this._apiUrl +
            "tickets/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getTicketsPackWeb"
        );
    }

    public async fetchGetTickets(
        user: any | undefined = undefined,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const apiTickets = getRouteManager().getTickets();
            const token =
                user && user.login_token
                    ? user.login_token
                    : this.getUser().getLoginToken()
                    ? this.getUser().getLoginToken()
                    : null;
            if (token) {
                const ticket = await fetch(`${apiTickets}`, {
                    method: "POST",
                    signal: cancelSignal,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        login_token: token,
                    }),
                });
                return ticket.json();
            } else {
                return null;
            }
        } catch (err) {
            console.log(err);
        }
    }

    public getPromo(): string {
        return (
            this._apiUrl + "promo/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getPromo"
        );
    }

    /*---------------- STORIES  ----------------*/

    public exportAll(lang: string): string {
        return (
            this._apiUrl +
            "story/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/" +
            lang +
            "/exportAll"
        );
    }

    public export(id: number, lang: string): string {
        return (
            this._apiUrl +
            "story/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/" +
            id.toString() +
            "/" +
            lang +
            "/export"
        );
    }

    public incrementStart(id: number): string {
        return (
            this._apiUrl +
            "story/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/" +
            id.toString() +
            "/incrementStart"
        );
    }

    public getNbStart(id: number): string {
        return (
            this._apiUrl +
            "story/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/" +
            id.toString() +
            "/getNbStart"
        );
    }

    /*---------------- TRADS  ----------------*/
    /*---------------- IMAGES  ----------------*/

    public cover(storyId: number): string {
        return storyId
            ? this._apiUrl +
                  "cover/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/cover/" +
                  storyId.toString()
            : "";
    }

    public getCover(coverId: number): string {
        return coverId
            ? this._apiUrl +
                  "cover/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCover/" +
                  coverId.toString()
            : "";
    }

    public sceneImage(id: number): string {
        return (
            this._apiUrl +
            "sceneImage/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/sceneImage/" +
            id.toString()
        );
    }

    public imagesForStory(storyId: number): string {
        return (
            this._apiUrl +
            "sceneImage/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/imagesForStory/" +
            storyId.toString()
        );
    }

    public imagesForStoryWeb(storyId: number): string {
        return (
            this._apiUrl +
            "sceneImage/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/imagesForStoryWeb/" +
            storyId.toString()
        );
    }

    /*---------------- SOUNDS  ----------------*/

    public sound(id: number): string {
        return (
            this._apiUrl +
            "sound/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/sound/" +
            id.toString()
        );
    }

    public soundsForStory(storyId: number): string {
        return (
            this._apiUrl +
            "sound/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/soundsForStory/" +
            storyId.toString()
        );
    }

    /*---------------- COLORS  ----------------*/

    public colorsForStory(storyId: number): string {
        return (
            this._apiUrl +
            "color/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/colorsForStory/" +
            storyId.toString()
        );
    }

    /*---------------- ANIMS  ----------------*/

    public animation(id: number): string {
        return (
            this._apiUrl +
            "anim/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/animation/" +
            id.toString()
        );
    }
    public animationsForStory(storyId: number): string {
        return (
            this._apiUrl +
            "anim/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/animationsForStory/" +
            storyId.toString()
        );
    }

    /*---------------- EVENTS  ----------------*/

    public eventsForStory(storyId: number): string {
        return (
            this._apiUrl +
            "storyEvent/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/eventsForStory/" +
            storyId.toString()
        );
    }

    public incrementTrigger(id: number): string {
        return (
            this._apiUrl +
            "storyEvent/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/event/" +
            id.toString() +
            "/incrementTrigger"
        );
    }

    /*---------------- ICONS  ----------------*/

    public icon(id: number): string {
        return (
            this._apiUrl +
            "icon/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/icon/" +
            id.toString()
        );
    }

    /*---------------- TITLES  ----------------*/

    public titleForStory(lang: string, id: number): string {
        return (
            this._apiUrl +
            "title/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/titleForStory/" +
            lang +
            "/" +
            id.toString()
        );
    }

    public titleForSeries(lang: string, id: number): string {
        return (
            this._apiUrl +
            "title/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/titleForSeries/" +
            lang +
            "/" +
            id.toString()
        );
    }

    /*---------------- THEMES  ----------------*/

    public themesForStory(lang: string, id: number): string {
        return (
            this._apiUrl +
            "themeStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/themesForStory/" +
            lang +
            "/" +
            id.toString()
        );
    }
    public getThemeIcon(id: number): string {
        return (
            this._apiUrl +
            "themeStory/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getThemeIcon/" +
            id.toString()
        );
    }

    public themesForSeries(lang: string, id: number): string {
        return (
            this._apiUrl +
            "themeSeries/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/themesForSeries/" +
            lang +
            "/" +
            id.toString()
        );
    }

    public getThemesList(lang: string): string {
        return (
            this._apiUrl +
            "theme/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getThemesList/" +
            lang
        );
    }

    /*---------------- NEWS  ----------------*/

    public likeNews(newsId: number): string {
        return (
            this._apiUrl +
            "news/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/news/like/" +
            newsId.toString()
        );
    }

    public unlikeNews(newsId: number): string {
        return (
            this._apiUrl +
            "news/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/news/unlike/" +
            newsId.toString()
        );
    }

    public newsForLang(lang: string): string {
        return (
            this._apiUrl +
            "news/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/newsForLang/" +
            lang
        );
    }

    public getNewsImage(id: number): string {
        return (
            this._apiUrl +
            "news/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/newsImage/" +
            id.toString()
        );
    }

    /*---------------- ENDGAME  ----------------*/

    public getEndGameForUser(): string {
        return (
            this._apiUrl +
            "endGame/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getEndGameForUser"
        );
    }

    public async fetchGetEndGameForUser(
        storyId: number | string,
        user: any | undefined,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const url = this.getEndGameForUser();
            return await this.fetchData(
                url,
                {
                    login_token:
                        user && user.login_token
                            ? user.login_token
                            : this.getUser().getLoginToken(),
                    lang: getLanguage(),
                    storyId: storyId,
                },
                cancelSignal,
            );
        } catch (err) {
            console.log(err);
        }
    }

    /*---------------- LEVELS  ----------------*/

    public getAllLevelForUser = (): string => {
        return (
            this._apiUrl +
            "level/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAllLevelForUser"
        );
    };

    public async fetchGetAllLevelForUser(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            return await this.fetchData(
                this.getAllLevelForUser(),
                {
                    login_token: this.getUser().getLoginToken(),
                    lang: getLanguage(),
                },
                cancelSignal,
            );
        } catch (err) {
            console.log(err);
        }
    }

    public unlockLevelForUser = (): string => {
        return (
            this._apiUrl +
            "level/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/unlockLevelForUser"
        );
    };

    public async fetchUnlockLevelForUser(
        levelId: number,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            return await this.fetchData(
                this.unlockLevelForUser(),
                {
                    login_token: this.getUser().getLoginToken(),
                    lang: getLanguage(),
                    levelId: levelId,
                },
                cancelSignal,
            );
        } catch (err) {
            console.log(err);
        }
    }

    public reclaimLevelReward = (): string => {
        return (
            this._apiUrl +
            "level/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/reclaimReward"
        );
    };

    public async fetchReclaimLevelReward(
        levelId: number,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            return await this.fetchData(
                this.reclaimLevelReward(),
                {
                    login_token: this.getUser().getLoginToken(),
                    lang: getLanguage(),
                    levelId: levelId,
                },
                cancelSignal,
            );
        } catch (err) {
            console.log(err);
        }
    }

    /*---------------- ADS  ----------------*/

    public getCheckAdsForUserUrl() {
        return (
            this._apiUrl +
            "ads/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/checkAdsForUser"
        );
    }
    public async checkAdsForUser(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        return await this.fetchData(
            this.getCheckAdsForUserUrl(),
            {
                login_token: this.getUser().getLoginToken(),
            },
            cancelSignal,
        );
    }

    public getAdsRewardUrl() {
        return (
            this._apiUrl +
            "ads/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getAdsReward"
        );
    }
    public async getAdsReward(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        return await this.fetchData(
            this.getAdsRewardUrl(),
            {
                login_token: this.getUser().getLoginToken(),
            },
            cancelSignal,
        );
    }

    /*---------------- FEED  ----------------*/

    public getOfferReward(): string {
        return (
            this._apiUrl +
            "offer/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/reclaimReward"
        );
    }

    public getUserOfferPackReward(): string {
        return (
            this._apiUrl +
            "userOfferPack/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/reclaimReward"
        );
    }

    public getOfferRewardImage(id: number): string {
        return (
            this._apiUrl +
            "offer/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getImage/" +
            id.toString()
        );
    }

    public async fetchGetOfferReward(
        offerId: number,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        return await this.fetchData(
            this.getUserOfferPackReward(),
            {
                login_token: this.getUser().getLoginToken(),
                lang: getLanguage(),
                offerId: offerId,
            },
            cancelSignal,
        );
    }

    public getFeaturedForLang(): string {
        return (
            this._apiUrl +
            "featured/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getFeaturedForLangNewWithPromo"
        );
    }

    public getFeaturedLibrary(): string {
        return (
            this._apiUrl +
            "featured/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getFeaturedLibrary"
        );
    }

    public async fetchGetFeaturedForLang(
        user: any | undefined = undefined,
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            const token = user?.login_token
                ? user.login_token
                : this.getUser().getLoginToken()
                ? this.getUser().getLoginToken()
                : null;
            if (token) {
                return await this.fetchData(
                    this.getFeaturedForLang(),
                    {
                        login_token: token,
                        lang: getLanguage(),
                    },
                    cancelSignal,
                );
            } else {
                return null;
            }
        } catch (err) {
            console.log(err);
        }
    }

    public getCardImage(id: number): string {
        return (
            this._apiUrl +
            "featuredCard/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCardImage/" +
            id.toString()
        );
    }

    public getCardVideo(id: number): string {
        return (
            this._apiUrl +
            "featuredCard/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCardVideo/" +
            id.toString()
        );
    }

    /*---------------- SESSIONS  ----------------*/

    public startSession(): string {
        return (
            this._apiUrl +
            "gameSession/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/createSession"
        );
    }

    public updateSession(): string {
        return (
            this._apiUrl +
            "gameSession/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updateSession"
        );
    }

    public async fetchStartSession(session: any, user: any): Promise<void> {
        if (session && session.nbPlayers && session.storyId && session.type) {
            return await this.fetchData(this.startSession(), {
                login_token: user.login_token,
                gameSession: session,
            });
        }
    }

    public async fetchUpdateSession(session: any, user: any): Promise<void> {
        return await this.fetchData(this.updateSession(), {
            login_token: user.login_token,
            gameSession: session,
        });
    }

    /*---------------- COMPANIONS  ----------------*/

    public getCompanionsForUser(): string {
        return (
            this._apiUrl +
            "companion/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getCompanionsForUser"
        );
    }

    public updateCompanionForUser(): string {
        return (
            this._apiUrl +
            "companion/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/updateCompanionForUser"
        );
    }

    public purchaseCompanionSlotForUser(): string {
        return (
            this._apiUrl +
            "companion/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/purchaseCompanionSlotForUser"
        );
    }

    /*---------------- FRIENDS  ----------------*/

    public getFriendsForUser(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getFriendsForUser"
        );
    }

    public answerFriendRequest(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/answerFriendRequest"
        );
    }

    public removeFriend(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/removeFriend"
        );
    }

    public addFriendForUser(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/addFriendForUser"
        );
    }

    public addFriendForUserById(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/addFriendForUserById"
        );
    }

    public getSocketUrl(): string {
        return this._apiUrl.replace("/tool/", "");
    }

    public inviteFriendToGame(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/inviteFriendToGame"
        );
    }

    public inviteFriendToGameById(): string {
        return (
            this._apiUrl +
            "friend/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/inviteFriendToGameById"
        );
    }

    public async fetchGetFriendsForUser(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            if (!this.getUser()?.getLoginToken()) {
                return [];
            }
            return await this.fetchData(
                this.getFriendsForUser(),
                {
                    login_token: this.getUser().getLoginToken(),
                    lang: getLanguage(),
                },
                cancelSignal,
            );
        } catch (err) {
            console.log(err);
        }
    }

    public getLadder(): string {
        return (
            this._apiUrl + "user/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getLadder"
        );
    }

    /*---------------- FTUE  ----------------*/

    public getFTUEStoriesWithRelations(): string {
        return (
            this._apiUrl +
            "story/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getFTUEStoriesWithRelations"
        );
    }

    /*---------------- REFERRAL  ----------------*/

    public getReferralsForUser(): string {
        return (
            this._apiUrl +
            "referral/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/getReferralsForUser"
        );
    }

    public addReferral(): string {
        return (
            this._apiUrl +
            "referral/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/addReferral"
        );
    }

    public unlockRewardForReferral(): string {
        return (
            this._apiUrl +
            "referral/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/unlockRewardForReferral"
        );
    }

    public async fetchGetReferralsForUser(
        cancelSignal: AbortSignal | undefined = undefined,
    ): Promise<any> {
        try {
            if (!this.getUser()?.getLoginToken()) {
                return [];
            }
            return await this.fetchData(
                this.getReferralsForUser(),
                {
                    login_token: this.getUser().getLoginToken(),
                    lang: getLanguage(),
                },
                cancelSignal,
            );
        } catch (err) {
            console.log(err);
        }
    }
}

const routeManager = new RouteManager();

export const getRouteManager = () => {
    return routeManager;
};
