/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Fade, Typography } from "@mui/material";
import { columnCenterStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import { Colors } from "../../style";
import SoundManager from "../../services/SoundManager";

interface Props {
  goNext: any;
  type: string;
  icon: any;
  title: string;
  subtitle: string;
}

const GameModeBtn = (props: Props) => {
  const { goNext, type, icon, title, subtitle } = props;

  return (
    <Fade in={true} appear timeout={800}>
      <ButtonBase style={styles.container} onClick={() => {
        SoundManager.getInstance().playBtnSound();
        goNext(type)}}>
        <Box>{icon}</Box>
        <Typography sx={styles.title}>{title}</Typography>
        <Typography sx={styles.subtitle}>{subtitle}</Typography>
      </ButtonBase>
    </Fade>
  );
};

const styles = {
  container: {
    ...columnCenterStyles,
    rowGap: "7px",
    width: "300px",
    height: "235px",
    borderRadius: "13px",
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "30px 50px",
  },
  title: {
    fontFamily: Gilroy,
    fontSize: "20px",
    color: Colors.WHITE,
  },
  subtitle: {
    fontFamily: Gilroy,
    fontSize: "14px",
    color: Colors.OLD_PRICE,
  },
};

export default GameModeBtn;
