import { useState } from "react";
import { Friend } from "../../models/friend";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { Box, ButtonBase, Typography } from "@mui/material";
import { columnCenterStyles, columnStyles } from "../../style/flex";
import { Colors } from "../../style";
import { normalizeSize } from "../../utils/fontSizer";
import { Gilroy } from "../../style/fonts";
import SoundManager from "../../services/SoundManager";
//ICONS
import { ReactComponent as IconCompanions } from "../../assets/icons/icon_companion.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { ReactComponent as IconCompleted } from "../../assets/icons/icon_completed2.svg";
import { strings } from "../../services/translation";
import Separator from "../Separator";
import FriendList from "../FriendList";

const SelectFriendModal = ({
    friends,
    createMultipleFriends,
    currentPlayerCount,
    nbPlayerMax,
}: {
    friends: Friend[] | null;
    createMultipleFriends: (friends: Friend[]) => void;
    currentPlayerCount: number;
    nbPlayerMax: number;
}) => {
    const [playerCount, setPlayerCount] = useState(currentPlayerCount);
    const [selectedFriends, setSelectedFriends] = useState<Friend[]>([]);

    const updateFriendList = (friend: Friend) => {
        const shouldSelect =
            !isNotEmptyArray(selectedFriends) ||
            !selectedFriends.includes(friend);
        if (shouldSelect) {
            if (playerCount + 1 <= nbPlayerMax) {
                selectFriend(friend);
            }
        } else {
            unselectFriend(friend);
        }
    };

    const selectFriend = (friend: Friend) => {
        setSelectedFriends((current: Friend[]) => [...current, friend]);
        setPlayerCount((current: number) => current + 1);
    };

    const unselectFriend = (friend: Friend) => {
        setSelectedFriends((prev: Friend[]) => [
            ...prev.filter((entry: any) => entry != friend),
        ]);
        setPlayerCount((current: number) => current - 1);
    };

    return (
        <Box sx={styles.friendModal}>
            <Box sx={styles.modalHeader}>
                <ButtonBase
                    onClick={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        createMultipleFriends(selectedFriends);
                    }}
                    sx={styles.closeModalBtn}>
                    {selectedFriends && isNotEmptyArray(selectedFriends) ? (
                        <IconCompleted
                            width={30}
                            height={30}
                            style={{ marginTop: "5px" }}
                        />
                    ) : (
                        <CloseIcon width={50} height={50} />
                    )}
                </ButtonBase>
                <IconCompanions width={40} height={40} fill={Colors.WHITE} />
                <Typography sx={styles.modalTitle}>
                    {strings.friends.friends}
                </Typography>
                {selectedFriends && isNotEmptyArray(selectedFriends) ? (
                    <Typography sx={styles.modalSubtitle}>
                        {`${selectedFriends.length} ${
                            strings.friends.selectedFriends
                        } ${playerCount >= nbPlayerMax ? "(MAX)" : ""}`}
                    </Typography>
                ) : null}
            </Box>
            <Separator />
            <Box sx={styles.friendModalBody}>
                <FriendList
                    friends={friends}
                    setActiveFriend={(friend: Friend) => {
                        updateFriendList(friend);
                    }}
                    selectedFriends={selectedFriends}
                />
            </Box>
        </Box>
    );
};

const styles = {
    friendModal: {
        ...columnCenterStyles,
        backgroundColor: Colors.PRIMARY,
        width: normalizeSize(400),
        borderRadius: normalizeSize(20),
        maxHeight: "max(475px, 50vh)",
        padding: "10px 0px",
    },
    modalHeader: {
        width: "100%",
        padding: normalizeSize(20),
        ...columnCenterStyles,
        rowGap: normalizeSize(10),
        position: "relative",
    },
    modalTitle: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: normalizeSize(18),
    },
    modalSubtitle: {
        color: Colors.HEART,
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
    },
    friendModalBody: {
        ...columnStyles,
        alingItems: "center",
        overflow: "auto",
        scrollbarWidth: "none",
        scrollBehaviour: "smooth",
        "::-webkit-scrollbar": {
            width: "5px",
        },
        "::-webkit-scrollbar-track": {
            background: Colors.BTN_ACTIVE,
            borderRadius: "5px",
        },

        "::-webkit-scrollbar-thumb": {
            background: Colors.OLD_PRICE,
            borderRadius: "5px",
        },

        "::-webkit-scrollbar-thumb:hover": {
            background: Colors.BTN_INACTIVE,
        },
    },
    closeModalBtn: {
        position: "absolute",
        width: normalizeSize(50),
        height: normalizeSize(50),
        top: normalizeSize(0),
        right: normalizeSize(10),
        borderRadius: normalizeSize(50),
        backgroundColor: Colors.BTN_ACTIVE,
        ...columnCenterStyles,
    },
};

export default SelectFriendModal;
