/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from "react-router";
import useAuth from "../../services/Auth/useAuth";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { columnStyles, rowBetweenStyles } from "../../style/flex";
import LoadingView from "../../components/LoadingView";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { Eczar } from "../../style/fonts";
import Carousel from "../../components/Carousel";
import { Link } from "react-router-dom";

const SuggestionScreen = () => {
  const { storyId } = useParams();
  const auth = useAuth();

  const [reco, setReco] = useState<Array<any> | null>(null);
  const [stories, setStories] = useState<Array<any> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRecos();
  }, []);

  const getRecos = async (): Promise<void> => {
    if (storyId && auth && auth.user) {
      setLoading(true);
      const recos = await getRouteManager().fetchGetEndGameForUser(
        storyId,
        auth.user
      );
      if (recos) {
        if (recos.reco && recos.reco?.length > 0) {
          recos.reco.map((s: any) => {
            if (s.item.id) {
              s.item.image_backdrop =
                s.type === "series"
                  ? getRouteManager().getCover(s.item.idCover)
                  : getRouteManager().cover(s.item.id);

              s.item.image_title =
                s.type === "series"
                  ? getRouteManager().titleForSeries(getLanguage(), s.item.id)
                  : getRouteManager().titleForStory(getLanguage(), s.item.id);
            }
          });
          setReco(recos.reco);
        }
        if (recos.stories && recos.stories.length > 0) {
          setStories(recos.stories);
          recos.stories.map((s: any) => {
            if (s.item.id) {
              s.item.image_backdrop =
                s.type === "series"
                  ? getRouteManager().getCover(s.item.idCover)
                  : getRouteManager().cover(s.item.id);

              s.item.image_title =
                s.type === "series"
                  ? getRouteManager().titleForSeries(getLanguage(), s.item.id)
                  : getRouteManager().titleForStory(getLanguage(), s.item.id);
            }
          });
        }
      }
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingView />;
  } else {
    return (
      <Box
        sx={[
          styles.all,
          {
            background: `url(${require("../../assets/images/story_outro.jpg")})`,
          },
        ]}
      >
        <Box sx={styles.body}>
          <Box sx={styles.header}>
            <Typography style={styles.titleReco}>
              {strings.endGame.title}
            </Typography>
            <Link to={"/"} style={styles.goNext}>
              {strings.actions.skip}
            </Link>
          </Box>

          {isNotEmptyArray(reco) ? (
            <Carousel
              cards={reco}
              cardWidth={250}
              cardHeight={400}
              numCardsPerView={4}
              marginBetweenCards={15}
            />
          ) : null}
          <Typography style={styles.recommandText}>
            {strings.endGame.recommand}
          </Typography>
          {isNotEmptyArray(stories) ? (
            <Carousel
              cards={stories}
              cardWidth={165}
              cardHeight={264}
              numCardsPerView={5}
              marginBetweenCards={10}
            />
          ) : null}
        </Box>
      </Box>
    );
  }
};

const styles = {
  all: {
    ...columnStyles,
    width: "100%",
  },
  body: {
    ...columnStyles,
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
  },
  container: {
    ...columnStyles,
  },
  header: {
    margin: "4vh 0px 2vh 0px",
    ...rowBetweenStyles,
    width: "100%",
  },
  titleReco: {
    fontSize: normalize(50),
    color: Colors.ACCENT,
    fontFamily: Eczar.semiBold,
    paddingLeft: "4vw",
  },
  goNext: {
    color: Colors.WHITE,
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    paddingRight: "4vw",
  },
  recommand: {
    ...columnStyles,
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: "402px",
  },
  stories: {
    ...columnStyles,
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: "314px",
    marginTop: "20px",
  },
  recommandText: {
    color: Colors.WHITE,
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    paddingLeft: "4vw",
    margin: "20px 0px",
  },
  cover: {
    flex: 1,
    width: "220px",
    height: "352px",
    marginRight: "15px",
  },
  coverStories: {
    flex: 1,
    width: "150px",
    height: "264px",
    marginRight: "15px",
  },
};

export default SuggestionScreen;
