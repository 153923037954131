/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import normalize from "../../utils/fontSizer";
import { ReactComponent as IconItem } from "../../assets/icons/icon_item_normalize.svg";
import { ReactComponent as IconTitle } from "../../assets/icons/icon_news.svg";
import { ReactComponent as IconTicket } from "../../assets/icons/icon_ticket.svg";
import { ReactComponent as IconXp } from "../../assets/icons/icon_xp.svg";
import { ReactComponent as IconXpInactive } from "../../assets/icons/icon_xp_inactive.svg";
import { Colors } from "../../style";
import { rewardItemJson } from "../../constants/rewardItem";
import { rewardTicketJson } from "../../constants/rewardTicket";
import { rewardTitleJson } from "../../constants/rewardTitle";
import { strings } from "../../services/translation";
import TicketAnimated from "../TicketAnimated";
import { tokensAnim } from "../../constants/tokens";
import TokenAnimated from "../TokenAnimated";
import { Box, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { columnCenterStyles } from "../../style/flex";
import { Level, LevelReward } from "../../models/Level";
import { getRouteManager } from "../../services/routeManager";
import { itemBackgroundJson } from "../../constants/itemBackground";
import { Mission, MissionReward } from "../../models/mission";

interface Props {
    level: Level | Mission;
    reward: LevelReward | MissionReward | undefined;
    isExperience?: boolean;
    isModal?: boolean;
}

const RewardBtn = (props: Props) => {
    const { level, reward, isExperience = false, isModal = false } = props;

    return (
        <Box sx={styles.rewardBlock}>
            {isExperience && level.experience ? (
                <Box sx={styles.rewardBlock}>
                    <IconXp
                        width={30}
                        height={30}
                        fill={
                            level.rewardReclaimed
                                ? Colors.FOND_ITEM_POPUP
                                : Colors.WHITE
                        }
                    />
                    <Typography
                        sx={[
                            level.rewardReclaimed
                                ? styles.rewardReclaimed
                                : styles.ticketsReward,
                            !level.rewardReclaimed
                                ? { color: Colors.WHITE }
                                : {},
                        ]}>
                        {level.experience}
                    </Typography>
                </Box>
            ) : reward && reward.type === "tickets" ? (
                <Box sx={styles.rewardBlock}>
                    {level.rewardReclaimed ? (
                        <Box sx={styles.rewardBlock}>
                            <IconTicket
                                width={30}
                                height={30}
                                fill={Colors.FOND_ITEM_POPUP}
                            />
                            <Typography style={styles.rewardReclaimed}>
                                {reward.tickets}
                            </Typography>
                        </Box>
                    ) : level.unlocked ? (
                        <Box sx={styles.rewardBlock}>
                            <Player
                                src={rewardTicketJson}
                                autoplay
                                loop
                                speed={1.5}
                                style={{
                                    width: isModal ? "100px" : "75%",
                                }}
                            />
                            <Typography sx={[styles.ticketsReward]}>
                                {reward.tickets}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={styles.rewardBlock}>
                            <TicketAnimated style={{ width: 30, height: 30 }} />
                            <Typography sx={[styles.ticketsReward]}>
                                {reward.tickets}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : reward && reward.type === "tokens" ? (
                <Box sx={styles.rewardBlock}>
                    {level.rewardReclaimed ? (
                        <Box sx={styles.rewardBlock}>
                            <img
                                src={require("../../assets/icons/icon_token_disabled.png")}
                                style={{ width: 30, height: 30 }}
                            />
                            <Typography style={styles.rewardReclaimed}>
                                {reward.tokens}
                            </Typography>
                        </Box>
                    ) : level.unlocked ? (
                        <Box sx={styles.rewardBlock}>
                            <Player
                                src={tokensAnim}
                                autoplay
                                loop
                                style={{
                                    width: isModal ? "100px" : "75%",
                                }}
                                speed={1.5}
                            />
                            <Typography
                                sx={[
                                    styles.ticketsReward,
                                    {
                                        color: Colors.TOKEN,
                                    },
                                ]}>
                                {reward.tokens}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={styles.rewardBlock}>
                            <TokenAnimated style={{ width: 30, height: 30 }} />
                            <Typography
                                sx={[
                                    styles.ticketsReward,
                                    { color: Colors.TOKEN },
                                ]}>
                                {reward.tokens}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : reward && reward.type === "title" ? (
                <Box sx={styles.rewardBlock}>
                    {level.rewardReclaimed ? (
                        <Box sx={styles.rewardBlock}>
                            <IconTitle
                                width={30}
                                height={30}
                                fill={Colors.FOND_ITEM_POPUP}
                            />
                            <Typography style={styles.rewardReclaimed}>
                                {strings.title}
                            </Typography>
                        </Box>
                    ) : level.unlocked ? (
                        <Box sx={styles.rewardBlock}>
                            <Player
                                src={rewardTitleJson}
                                autoplay
                                loop
                                speed={1.5}
                            />
                            <Typography sx={[styles.titleReward]}>
                                {strings.title}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={styles.rewardBlock}>
                            <IconTitle
                                width={30}
                                height={30}
                                fill={Colors.ACCENT}
                            />
                            <Typography style={styles.titleReward}>
                                {strings.title}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : reward && reward.type === "item" ? (
                <Box sx={styles.rewardBlock}>
                    {reward.item && isModal ? (
                        <Box sx={styles.rewardBlock}>
                            <Box sx={styles.skinBtnImg}>
                                <Player
                                    src={itemBackgroundJson}
                                    autoplay
                                    loop
                                    speed={1.5}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100px",
                                        height: "100px",
                                    }}
                                />
                                <img
                                    src={getRouteManager().getItemImageForVersion(
                                        reward.item.id,
                                        "promo",
                                    )}
                                    style={{
                                        position: "absolute",
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "100px",
                                    }}
                                />
                            </Box>
                            <Box>
                                {reward.item?.name && (
                                    <Box sx={styles.skinBtnTitleBlock}>
                                        <Typography sx={styles.skinBtnTitle}>
                                            {reward.item.name}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ) : level.rewardReclaimed ? (
                        <Box sx={styles.rewardBlock}>
                            <IconItem
                                width={30}
                                height={30}
                                fill={Colors.FOND_ITEM_POPUP}
                            />
                            <Typography style={styles.rewardReclaimed}>
                                {strings.item}
                            </Typography>
                        </Box>
                    ) : level.unlocked ? (
                        <Box sx={styles.rewardBlock}>
                            <Player
                                src={rewardItemJson}
                                autoplay
                                loop
                                speed={1.5}
                            />
                            <Typography sx={[styles.itemReward]}>
                                {strings.item}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={styles.rewardBlock}>
                            <IconItem
                                width={30}
                                height={30}
                                fill={Colors.HEART}
                            />
                            <Typography style={styles.itemReward}>
                                {strings.item}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

const styles = {
    rewardBlock: {
        ...columnCenterStyles,
        flex: 1,
    },
    ticketsReward: {
        color: Colors.TICKET,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        marginTop: "5px",
    },
    titleReward: {
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        marginTop: "5px",
    },
    itemReward: {
        color: Colors.HEART,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        marginTop: "5px",
    },
    rewardReclaimed: {
        color: Colors.FOND_ITEM_POPUP,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        marginTop: "5px",
    },
    skinBtnImg: {
        width: "100px",
        height: "100px",
        borderRadius: "100px",
        backgroundColor: Colors.FOND_ITEM_POPUP,
        ...columnCenterStyles,
        position: "relative",
    },
    skinBtnTitleBlock: {
        ...columnCenterStyles,
        justifyContent: "center",
        flex: 1,
    },
    skinBtnTitle: {
        textAlign: "center",
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
    },
};

export default RewardBtn;
