import { Box, Typography } from "@mui/material";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import { normalizeSize } from "../../../utils/fontSizer";
import Separator from "../../Separator";
import { styles } from "../style";
import { ReactComponent as IconOnline } from "../../../assets/icons/icon_online.svg";

export const FTUEModalOnline = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleColumnContainer}>
                <Box sx={styles.iconContainer}>
                    <IconOnline width={57} height={57} fill={Colors.HEART} />
                </Box>
                <Typography sx={[styles.title, { color: Colors.HEART }]}>
                    {strings.ftue.online.title}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box style={styles.contentContainer}>
                <Typography
                    component={"span"}
                    sx={[
                        styles.itemText,
                        { textAlign: "center", fontSize: normalizeSize(16) },
                    ]}>
                    {strings.ftue.online.intro}
                    <Typography
                        component={"span"}
                        sx={[
                            styles.itemText,
                            {
                                color: Colors.HEART,
                                fontSize: normalizeSize(16),
                            },
                        ]}>
                        {strings.ftue.online.title}
                    </Typography>
                    {strings.ftue.online.intro2}
                </Typography>
                <Typography sx={styles.itemText}>
                    {strings.ftue.online.code}
                </Typography>
                <Typography sx={styles.itemText}>
                    {strings.ftue.online.friends}
                </Typography>
                <Typography sx={styles.itemText}>
                    {strings.ftue.online.public}
                </Typography>
            </Box>
        </Box>
    );
};
