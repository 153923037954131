import { useState } from "react";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import { columnCenterStyles, columnStartStyles } from "../../style/flex";
import useAuth from "../../services/Auth/useAuth";
import appVersion from "../../utils/roads";
import CustomOptBtn from "../../components/customOptBtn";
import { useNavigate } from "react-router";

import { ReactComponent as IconLogout } from "../../assets/icons/icon_logout.svg";
import { ReactComponent as IconLogoutMenu } from "../../assets/icons/icon_logout_menu.svg";
import { ReactComponent as IconSave } from "../../assets/icons/icon_save.svg";
import { ReactComponent as IconParameters } from "../../assets/icons/icon_parameters.svg";
import { ReactComponent as IconSocials } from "../../assets/icons/icon_socials.svg";
import { ReactComponent as IconCredits } from "../../assets/icons/icons_credits.svg";
import { ReactComponent as IconAbout } from "../../assets/icons/icon_about.svg";
import { ReactComponent as IconPromoCode } from "../../assets/icons/icon_code_promo.svg";
import { ReactComponent as IconSupport } from "../../assets/icons/icon_support.svg";
import BackgroundRow from "../../components/BackgroundRow";
import BigModal from "../../components/BigModal";
import Socials from "./Socials";

import PromoCode from "./PromoCode";
import SoundManager from "../../services/SoundManager";
import { getRouteManager } from "../../services/routeManager";
import RegisterTemporaryAccountModal from "../../components/RegisterTemporaryAccountModal";

const Options = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [showConfirm, setShowConfirm] = useState(false);
    const [showSocials, setShowSocials] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const handleLogout = () => {
        auth.signout(() => {
            navigate("/login");
        });
    };

    return (
        <BackgroundRow
            title={"Menu"}
            animType="normal"
            bodyStyle={{ paddingTop: "14vh" }}
            rightPart={
                <Box sx={styles.container}>
                    <Box sx={styles.separatorBox}>
                        <CustomOptBtn
                            icon={IconSave}
                            text={strings.saves}
                            onPress={() => {
                                navigate("saves");
                            }}
                        />
                    </Box>
                    <Box sx={styles.separatorBox}>
                        <CustomOptBtn
                            icon={IconParameters}
                            text={strings.options}
                            onPress={() => {
                                navigate("settings");
                            }}
                        />
                        <CustomOptBtn
                            icon={IconSocials}
                            text={strings.ourSocials}
                            onPress={() => {
                                setShowSocials(true);
                            }}
                        />
                        <CustomOptBtn
                            icon={IconPromoCode}
                            text={strings.promoCode}
                            onPress={() => {
                                auth?.user?.isTemporaryAccount
                                    ? setShowRegisterModal(true)
                                    : setShowPopUp(true);
                            }}
                        />
                        <CustomOptBtn
                            icon={IconCredits}
                            text={strings.credits}
                            onPress={() => {
                                navigate("credits");
                            }}
                        />
                        <CustomOptBtn
                            icon={IconSupport}
                            text={strings.support}
                            onPress={() => {
                                navigate("support");
                            }}
                        />
                        <CustomOptBtn
                            icon={IconAbout}
                            text={strings.about}
                            onPress={() => {
                                navigate("about");
                            }}
                        />
                    </Box>
                    <Box sx={styles.separatorBox}>
                        <CustomOptBtn
                            icon={IconLogout}
                            text={strings.logout}
                            onPress={() => {
                                //handleLogout();
                                setShowConfirm(true);
                            }}
                        />
                    </Box>

                    {auth && auth.user ? (
                        <Box style={styles.loggedIn}>
                            <Typography sx={styles.versionText}>
                                {strings.logedInAs} {auth.user.email}
                            </Typography>
                        </Box>
                    ) : null}

                    <Box style={styles.version}>
                        <Typography sx={styles.versionText}>
                            Version {appVersion}{" "}
                            {getRouteManager().getServerType() === "test"
                                ? "- TEST"
                                : ""}
                        </Typography>
                    </Box>
                    <Modal
                        open={showConfirm}
                        onClose={() => {
                            SoundManager.getInstance().playCloseModalSound();
                            setShowConfirm(false);
                        }}
                        style={styles.centeredView}>
                        <Box style={styles.modalView}>
                            <IconLogoutMenu width={80} height={80} />
                            <Typography
                                style={{
                                    color: Colors.BTN_INACTIVE,
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: "12px",
                                    marginTop: 19,
                                    textAlign: "center",
                                }}>
                                {strings.logoutMsg}
                            </Typography>
                            <Box style={styles.separator} />
                            <Box sx={styles.popupButtons}>
                                <ButtonBase
                                    style={styles.noButton}
                                    onClick={() => {
                                        SoundManager.getInstance().playCloseModalSound();
                                        setShowConfirm(false);
                                    }}>
                                    <Typography sx={styles.noText}>
                                        {strings.back}
                                    </Typography>
                                </ButtonBase>
                                <ButtonBase
                                    style={styles.yesButton}
                                    onClick={() => {
                                        SoundManager.getInstance().playCloseModalSound();
                                        handleLogout();
                                    }}>
                                    <Typography sx={styles.yesText}>
                                        {strings.logMeOut}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        </Box>
                    </Modal>
                    <BigModal
                        modalVisible={showSocials}
                        title={strings.ourSocials}
                        content={<Socials />}
                        callback={() => setShowSocials(false)}
                    />
                    <PromoCode
                        modalVisible={showPopUp}
                        callback={() => setShowPopUp(false)}
                    />
                    <RegisterTemporaryAccountModal
                        isOpen={showRegisterModal}
                        callback={() => setShowRegisterModal(false)}
                        onCloseCallback={() => setShowRegisterModal(false)}
                    />
                </Box>
            }
        />
    );
};

const styles = {
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
    },
    separatorBox: {
        width: "370px",
        paddingBottom: "30px",
        borderBottomColor: Colors.BTN_ACTIVE,
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
    },
    loggedIn: {
        marginTop: "20px",
        width: "370px",
    },
    versionText: {
        fontSize: "14px",
        fontFamily: "Gilroy-Bold",
        textAlign: "center",
        color: Colors.WHITE,
    },
    version: {
        paddingBottom: "50px",
        marginTop: "20px",
        width: "370px",
    },
    centeredView: {
        flex: 1,
        ...columnCenterStyles,
    },
    modalView: {
        width: "300px",
        margin: "20px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        paddingTop: "45px",
        padding: "45px 45px 0px 45px",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: "2px",
        },
        ...columnCenterStyles,
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    separator: {
        width: "300px",
        height: 0,
        color: Colors.BTN_ACTIVE,
        borderColor: Colors.BTN_ACTIVE,
        borderWidth: "1px",
        borderStyle: "solid",
        marginTop: "25px",
    },
    popupButtons: {
        width: "300px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: "25px",
        marginBottom: "25px",
    },
    yesButton: {
        backgroundColor: Colors.ERROR,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: "12px",
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: "12px",
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
};
export default Options;
