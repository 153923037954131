/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router";
import BackgroundRow from "../../../components/BackgroundRow";
import {
  creditsTeam,
  creditsTeamEn,
  collaborationTeam,
  collaborationTeamEn,
  thanksTeam,
  thanksTeamEn,
} from "../../../constants";
import { getLanguage, strings } from "../../../services/translation";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import {
  columnStartStyles,
  columnStyles,
  rowStartStyles,
} from "../../../style/flex";
import { Box, Typography } from "@mui/material";

const CreditsScreen = () => {
  const navigate = useNavigate();
  const lang = getLanguage();
  const credTeam = lang === "fr" ? creditsTeam : creditsTeamEn;
  const collTeam = lang === "fr" ? collaborationTeam : collaborationTeamEn;
  const tyTeam = lang === "fr" ? thanksTeam : thanksTeamEn;

  return (
    <BackgroundRow
      title={strings.credits}
      goBack
      goBackAction={() => navigate(-1)}
      leftPartStyle={{ width: "20%" }}
      rightPartStyle={{ width: "80%" }}
      animType="normal"
      rightPart={
        <Box sx={styles.container}>
          <Typography sx={[styles.insideTitle, { marginTop: 0 }]}>
            {strings.team}
          </Typography>

          {credTeam &&
            credTeam.map((item: any) => {
              return (
                <Box sx={styles.coreTeamBlock} key={"cred-" + item.name}>
                  {item.chibi && (
                    <img src={item.chibi} style={{ width: 50, height: 50 }} />
                  )}
                  <Box sx={styles.coreTeamText}>
                    <Typography sx={styles.name}>{item.name}</Typography>
                    {item.role !== "" && (
                      <Typography sx={styles.role}>{item.role}</Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
          <Typography sx={styles.insideTitle}>{strings.collab}</Typography>
          {collTeam &&
            collTeam.map((item: any) => (
              <Box sx={{ marginTop: "20px" }} key={"collab-" + item.name}>
                <Typography sx={styles.name}>{item.name}</Typography>
                {item.role !== "" && (
                  <Typography sx={styles.role}>{item.role}</Typography>
                )}
              </Box>
            ))}
          <Typography sx={styles.insideTitle}>{strings.thankss}</Typography>
          {tyTeam &&
            tyTeam.map((item: any) => {
              return (
                <Box style={{ marginTop: "20px" }} key={"tyTeam-" + item.name}>
                  <Typography sx={styles.name}>{item.name}</Typography>
                  {item.role !== "" && (
                    <Typography sx={styles.role}>{item.role}</Typography>
                  )}
                </Box>
              );
            })}
          <Box sx={{ height: "50px" }} />
        </Box>
      }
    />
  );
};

const styles = {
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    width: "370px",
  },
  name: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(18),
    color: Colors.WHITE,
  },
  role: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    color: Colors.OLD_PRICE,
  },
  insideTitle: {
    width: "370px",
    color: Colors.ACCENT,
    fontFamily: "Eczar-Regular",
    fontSize: normalize(36),
    borderBottomColor: Colors.BTN_ACTIVE,
    marginTop: "30px",
    paddingBottom: "20px",
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    lineHeight: "36px",
  },
  coreTeamBlock: {
    ...rowStartStyles,
    marginTop: "25px",
  },
  coreTeamText: {
    ...columnStyles,
    justifyContent: "center",
    marginLeft: "10px",
  },
};

export default CreditsScreen;
