import { Box, Typography, useMediaQuery } from "@mui/material";
import { styles } from "../style";
import { strings } from "../../../../services/translation";
import GradientBtn from "../../../../components/GradientBtn";
import { normalizeSize } from "../../../../utils/fontSizer";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import { Colors } from "../../../../style";
import { successAnim } from "../../../../constants/success";
import { Player } from "@lottiefiles/react-lottie-player";

const ConfirmedBox = ({
    callback,
    isTemporaryModal = false,
}: {
    callback: any;
    isTemporaryModal?: boolean;
}) => {
    const matchesHeight = useMediaQuery("(min-height:800px)");
    const matchesWidth = useMediaQuery("(min-width:800px)");
    return (
        <Box
            sx={[
                styles.loginContainer,
                matchesWidth && !isTemporaryModal
                    ? { width: "50%" }
                    : { width: "100%" },
                isTemporaryModal ? { borderRadius: normalizeSize(20) } : {},
            ]}>
            <Box style={styles.container}>
                <Player
                    src={successAnim}
                    autoplay
                    style={{ width: "120px", height: "120px" }}
                    keepLastFrame
                />
                <Typography sx={styles.title2}>
                    {strings.user.everythingReady}
                </Typography>
                <Typography sx={[styles.subtitle, { marginBottom: "15px" }]}>
                    {strings.registerConfirmedSubtitleBis}
                </Typography>
                <Typography
                    sx={[
                        styles.subtitle,
                        {
                            marginBottom: matchesHeight
                                ? "calc(20px + 2.2vh)"
                                : "25px",
                        },
                    ]}>
                    {strings.registerConfirmedSubtitle2}
                </Typography>

                <GradientBtn onPress={() => callback()}>
                    {strings.continue}
                </GradientBtn>
            </Box>
        </Box>
    );
};

export default ConfirmedBox;
