import { Box, Typography } from "@mui/material";
import { itemBackgroundJson } from "../../../constants/itemBackground";
import { Item } from "../../../models/item";
import { getRouteManager } from "../../../services/routeManager";
import { Player } from "@lottiefiles/react-lottie-player";
import { columnCenterStyles } from "../../../style/flex";
import { Colors } from "../../../style";
import { normalizeSize } from "../../../utils/fontSizer";

const ItemReward = ({ item }: { item: Item }) => {
    if (item && item.id) {
        return (
            <Box sx={styles.rewardBlock}>
                <Box sx={styles.skinBtnImg}>
                    <Player
                        src={itemBackgroundJson}
                        autoplay
                        loop
                        speed={1.5}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100px",
                            height: "100px",
                        }}
                    />
                    <img
                        src={getRouteManager().getItemImageForVersion(
                            item.id,
                            "promo",
                        )}
                        style={{
                            position: "absolute",
                            width: "100px",
                            height: "100px",
                            borderRadius: "100px",
                        }}
                    />
                </Box>
                <Box>
                    {item?.name && (
                        <Box sx={styles.skinBtnTitleBlock}>
                            <Typography sx={styles.skinBtnTitle}>
                                {item.name}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }
    return null;
};

const styles = {
    rewardBlock: {
        ...columnCenterStyles,
        flex: 1,
    },
    skinBtnTitleBlock: {
        ...columnCenterStyles,
        justifyContent: "center",
        flex: 1,
        marginTop: "15px",
    },
    skinBtnTitle: {
        textAlign: "center",
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
    },
    skinBtnImg: {
        width: "100px",
        height: "100px",
        borderRadius: "100px",
        backgroundColor: Colors.FOND_ITEM_POPUP,
        ...columnCenterStyles,
        position: "relative",
    },
};

export default ItemReward;
