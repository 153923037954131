import { Box, Typography, useMediaQuery } from "@mui/material"
import { styles } from "../styles"
import TicketAnimated from "../../../components/TicketAnimated"
import { Colors } from "../../../style"
import { ReactComponent as IconSlot } from "../../../assets/icons/icon_avatar_slot.svg";
import { strings } from "../../../services/translation";
import { COMPANIONS } from "../../../constants";


export const CompanionEmpty = () => {

    const matchesValue = 1032;
    const matches = useMediaQuery(`(min-width:${matchesValue}px)`);

    return (
        <Box sx={matches ? styles.companionSingleColumn : styles.companionSingle}>
            <IconSlot width={matches ? 120 : 60} height={matches ? 120 : 60} />
            <Box sx={matches ? styles.companionTextBig : styles.companionText}>
                <Box sx={styles.priceBlock}>
                    <TicketAnimated style={{ width: 25, height: 25 }} />
                    <Typography sx={[styles.companionName, { color: Colors.TICKET }]}>{COMPANIONS.PRICE}</Typography>
                </Box>
                <Typography
                    sx={styles.subtitle}
                    component={"span"}>
                    {strings.companions.buySlot1}
                </Typography>
            </Box>
        </Box>
    )
}

