import { Colors } from "../../../style";
import { columnCenterStyles, columnStyles } from "../../../style/flex";
import normalize from "../../../utils/fontSizer";

// =======================================================

export default {
  container: {
    flex: 1,
    ...columnCenterStyles,
    width: "100%",
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    ...columnStyles,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "32px",
    zIndex: 50,
    ...columnStyles,
    alignSelf: "center",
    alignItems: "center",
  },
  text: {
    color: Colors.WHITE,
    fontSize: normalize(16),
    textAlign: "center",
    position: "absolute",
    /* top: "50%",
    bottom: "50%", */
    fontFamily: "Gilroy-Bold",
    padding: "0px 30px",
    zIndex: 5,
  },
};

// =======================================================
