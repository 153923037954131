/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
import React, {useState } from "react";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { ReactComponent as IconAccount } from "../../assets/icons/icon_credits.svg";
import { ReactComponent as IconPurchase } from "../../assets/icons/icon_ticket.svg";
import { ReactComponent as IconGameplay } from "../../assets/icons/icon_stories.svg";
import { ReactComponent as IconInfo } from "../../assets/icons/button_quest.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import SoundManager from '../../services/SoundManager';
import { strings } from "../../services/translation";
import { Box, ButtonBase, Collapse, Typography } from "@mui/material";
import "./style.css";
import { columnStyles, rowStartStyles } from "../../style/flex";

interface Props {
  faq: any;
  index: number;
}

const SupportBtn = (props: Props) => {
  const { faq, index } = props;
  const [expanded, setExpanded] = useState(false);

  const manageShowBlock = () => {
    if (expanded) {
      // First set up animation
      setExpanded(!expanded);
    } else {
      setExpanded(!expanded);
    }
  };

  const getIcon = (index: number) => {
    switch (index) {
      case 0:
        return (
          <IconAccount
            width={26}
            height={26}
            fill={Colors.BTN_ACTIVE}
            //fillSecondary={Colors.OLD_PRICE}
          />
        );
      case 1:
        return <IconPurchase width={26} height={26} fill={Colors.WHITE} />;
      case 2:
        return <IconGameplay width={26} height={26} fill={Colors.WHITE} />;
      case 3:
        return <IconInfo width={26} height={26} />;
    }
  };

  return (
    <Box sx={styles.btn}>
      <ButtonBase
        onClick={() => {
          SoundManager.getInstance().playBtnSound();
          manageShowBlock();
        }}
        sx={[
          styles.faqContainer,
          !expanded
            ? {
                borderBottomRightRadius: "13px",
                borderBottomLeftRadius: "13px",
              }
            : {},
        ]}
      >
        {getIcon(index)}
        <Typography style={styles.faqBlockTitle}>{faq.title}</Typography>
        <Box
          className={expanded ? "spinOut" : "spinIn"}
          sx={{
            marginLeft: "auto",
            width: "20px",
            height: "20px",
            alignSelf: "center",
          }}
        >
          <ArrowDown width={20} height={20} fill={Colors.OLD_PRICE} />
        </Box>
      </ButtonBase>
      <Box style={styles.container}>
        <Collapse in={expanded} timeout={600}>
          <Box sx={[styles.viewPort]}>
            <Box sx={styles.textBox}>
              {faq.articles.map((a: any, index: any) => (
                <Box key={"bloc-" + a.title + index}>
                  <Typography style={styles.faqArticleTitle}>
                    {a.title}
                  </Typography>
                  <Typography style={styles.faqArticleSub}>{a.sub}</Typography>
                  {a.remark !== "" && (
                    <Typography sx={styles.faqArticleSub}>
                      {strings.remark} : {a.remark}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Collapse>
      </Box>
      {(faq.title === "Dépannage" || faq.title === "Troubleshouting") && (
        <Box style={{ height: 100 }} />
      )}
    </Box>
  );
};

const styles = {
  btn: { maxWidth: "700px" },
  faqContainer: {
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "25px",
    borderTopRightRadius: "13px",
    borderTopLeftRadius: "13px",
    marginTop: "13px",
    width: "100%",
    ...rowStartStyles,
  },

  faqBlockTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(20),
    color: Colors.OLD_PRICE,
    marginLeft: "15px",
  },
  faqArticleTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    color: Colors.ACCENT,
    marginBottom: "10px",
  },
  faqArticleSub: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    color: Colors.BTN_INACTIVE,
    marginBottom: "20px",
  },
  container: {
    flex: 1,
    ...columnStyles,
  },
  viewPort: {
    backgroundColor: Colors.BTN_ACTIVE,
    borderBottomRightRadius: "13px",
    borderBottomLeftRadius: "13px",
    flex: 1,
    overflow: "hidden",
  },
  textBox: {
    flex: 1,
    padding: "15px 25px 0px 25px",
  },
};

export default SupportBtn;
