/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../services/Auth/useAuth";
import { GameContext } from "../../utils/ContextsHelper";
import SoundManager from "../../services/SoundManager";
import { ChoiceTypeEnum, IChoice, ScreenType } from "../../services/StoryReader/interfaces";
import { getRouteManager } from "../../services/routeManager";
import StoryReader from "../../services/StoryReader";
import { getCacheManager } from "../../services/CacheManager";
import { isNotEmptyString } from "../../utils/StringHelper";
import { isNumber, isObject, isString, isTrueBoolean } from "../../utils/TypeOfHelper";
import { propertyExists } from "../../utils/ObjectHelper";
import { getFinalChoice, getGamePlayers, getNbPlayers, goToLibrary, handleQuitGame, isScreenWithChoices, isScreenWithNextButton, isScreenWithTimer, isSingleNextChoice, isValidScene, isValidSnapshot, resetStorage } from "../../services/GameOnlineManager";
import { db } from "../../utils/FirebaseHelper";
import { FIREBASE_DATABASE } from "../../constants";
import _ from "lodash";
import ColorManager from "../../services/colorManager";
import { countDuplicates, isNotEmptyArray } from "../../utils/ArrayHelper";
import { isPositiveNumber } from "../../utils/NumberHelper";
import { onAddHasChangedScene, onAddSecretPlayerID, onAddSoloPlayerID, onDeleteGameChoices, onRemoveHasChangedScene, onRemoveHasPlayedFeedbackAnimation, onRemoveSecretPlayerID, onRemoveShouldPlayedFeedbackAnimation, onRemoveSoloPlayerID, onSaveVariablesAndPlayers, onUpdateIsTheEnd, onUpdateScene, onUpdateShouldPlayedFeedbackAnimation } from "../../services/FirebaseRealtimeDatabaseManager";
import { strings } from "../../services/translation";
import { columnCenterStyles, columnStartStyles } from "../../style/flex";
import {
  Box, Typography, Tooltip,
  TooltipProps,
  styled,
  ButtonBase,
  Fade,
  Slide,
  tooltipClasses,
} from "@mui/material";
import Choices from "../../components/Choices";
import StatePopUp from "../../components/StatePopUp";
import VarPopUp from "../../components/VarPopUp";
import HintPopup from "../../components/HintPopup";
import SuccessPopup from "../../components/SuccessPopup";
import { styles } from "./style";
import AvatarContainer from "../../components/AvatarContainer";
/* ICONS */
import { ReactComponent as SecretIcon } from "../../assets/icons/icon_secret.svg";
import { ReactComponent as OptionsBtn } from "../../assets/icons/icon_logbook_btn.svg";
import LoadingView from "../../components/LoadingView";
import TopMenu from "../Game/TopMenu";
import GameChat from "../../components/GameChat";
import StandardScreen from "../Game/standardScreen";
import ImageScreen from "../Game/imageScreen";
import PoolpScreen from "../Game/poolpScreen";
import FullScreenImageScreen from "../Game/fullscreenImageScreen";
import ChangeScreen from "../Game/changeScreen";
import SecretScreen from "../Game/secretScreen";
import TimerScreen from "../Game/timerScreen";
import TimerImageScreen from "../Game/timerImageScreen";
import ExitGameModal from "../../components/ClassicModal/ExitGameModal";
import ClassicModal from "../../components/ClassicModal";
import GameVolumeModal from "../../components/ClassicModal/GameVolumeModal";
import ReportScreen from "../../components/Report";
import { SCREEN_HEIGHT } from "../../utils/size";
import { Colors } from "../../style";
import DicePopup from '../../components/DicePopup';
import DiceFeedbackPopup from "../../components/DiceFeedbackPopup";

const END_GAME_CHOICE = "choice_end";
const END_GAME_NEXT = "next_end";
const NEXT_ANIMATION_NAME = "next_anim";

const ONE_USER_MODE = {
  SECRET: "secret",
  SOLO: "solo",
};

const RANDOM_IDENTIFIER = "{RANDOM}";

const GameOnline = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { setGame } = useContext(GameContext);

  const INITIAL_STATE = {
    activePlayerCode: null,
    arrowPercent: 0,
    askExit: true,
    availablePlayers: [],
    backgroundColor: null,
    choices: [],
    choiceTimerSelect: -1,
    countdown: 0,
    currPassageId: 0,
    disableLeaveGameButton: false,
    hasChangedScene: false,
    hasReachedWaitingPlayersSize: false,
    hasUpdatedState: false,
    hlText: "",
    isImageChanged: true,
    isReady: false,
    isTheEndOfTheGame: false,
    isVisibleVolumeModal: true,
    isWaitingNext: false,
    jsonVan: null,
    lastSelectedChoiceIndex: -1,
    passageNumber: 0,
    players: [],
    refreshPlayersList: false,
    screenType: ScreenType.STANDARD,
    secretSequence: false,
    selectedChoices: [],
    shouldPlayedFeedbackAnimation: false,
    showBugLoadingModal: false,
    showConfirmLeaveModal: false,
    showContinueButton: true,
    showOverlay: false,
    showPlayersList: true,
    showPopUp: false,
    showPopUpExit: false,
    showPopUpPlayersConcerned: false,
    showPopUpBackToGroup: false,
    showPopUpSuccess: false,
    showStatePopUp: false,
    showWaitingModal: false,
    stateCurrVar: "",
    switchValueFirst: !SoundManager.getInstance().isSfxMuted,
    switchValueSecond: !SoundManager.getInstance().isMusicMuted,
    text: "",
    toggleBottom: false,
    topMenuIsOpen: false,
    chatIsOpen: false,
    visitedScenes: [],
    wasHost: false,
    shouldFetchRandom: null,
    showPopUpGameOver: false,
    showVarPopUp: false,
    showInfo: null,
    notificationList: [],
    showBoardDicePopup: false,
    currentChoiceForDicePopup: null,
    randomForDicePopup: null,
    episode: state?.episode ? state.episode : null,
    storyID: state?.storyID
      ? state.storyID
      : state?.storyId
        ? state.storyId
        : null,
    title: state?.title ? state.title : "",
    gameID: state?.gameID ? state.gameID : "",
    playerID: state?.playerID ? state.playerID : "",
    screenName: state?.screenName ? state.screenName : null,
    colors: state?.colors ? state.colors : null,
    user: auth?.user ? auth.user : null,
    nbrPlayers: state?.nbrPlayers ? state.nbrPlayers : null,
    save: state?.save ? state.save : null,
    host: state?.host
      ? state.host
      : {
        name: null,
        id: null,
      },
    storyData: null,
    onReboot: state?.onReboot ? state.onReboot : false,
    background: state?.background ? state.background : null,
  };

  // ====== Local states ======
  // --
  const [activePlayerCode, _setActivePlayerCode] = useState<any>(
    INITIAL_STATE.activePlayerCode
  );
  const activePlayerCodeRef = useRef(activePlayerCode);
  const setActivePlayerCode = (data: any) => {
    activePlayerCodeRef.current = data;
    _setActivePlayerCode(data);
  };

  // --
  const [arrowPercent, _setArrowPercent] = useState<any>(
    INITIAL_STATE.arrowPercent
  );
  const arrowPercentRef = useRef(arrowPercent);
  const setArrowPercent = (data: any) => {
    arrowPercentRef.current = data;
    _setArrowPercent(data);
  };
  // --
  const [askExit, setAskExit] = useState<boolean>(INITIAL_STATE.askExit);
  const [background, setBackground] = useState<any>(INITIAL_STATE.background);
  const [choices, setChoices] = useState<Array<IChoice>>(INITIAL_STATE.choices);
  const [choiceTimerSelect, setChoiceTimerSelect] = useState(
    INITIAL_STATE.choiceTimerSelect
  );
  // --
  const [comesFromLocalStorage, _setComesFromLocalStorage] = useState<boolean>(
    //propertyExists(auth.user, 'id') ? true : false,
    INITIAL_STATE.onReboot
  );
  const comesFromLocalStorageRef = useRef(comesFromLocalStorage);
  const setComesFromLocalStorage = (data: boolean) => {
    comesFromLocalStorageRef.current = data;
    _setComesFromLocalStorage(data);
  };
  // --
  const [countdown, setCountdown] = useState(INITIAL_STATE.countdown);
  // --
  const [currPassageId, _setCurrPassageId] = useState<number>(
    INITIAL_STATE.currPassageId
  );
  const currPassageIdRef = useRef(currPassageId);
  const setCurrPassageId = (data: number) => {
    currPassageIdRef.current = data;
    _setCurrPassageId(data);
  };
  // --
  const [disableLeaveGameButton, setDisableLeaveGameButton] = useState(
    INITIAL_STATE.disableLeaveGameButton
  );
  // --
  const [hasChangedScene, _setHasChangedScene] = useState<boolean>(
    INITIAL_STATE.hasChangedScene
  );
  const hasChangedSceneRef = useRef(hasChangedScene);
  const setHasChangedScene = (data: boolean) => {
    hasChangedSceneRef.current = data;
    _setHasChangedScene(data);
  };
  // --
  const [hasUpdatedState, _setHasUpdatedState] = useState<boolean>(
    INITIAL_STATE.hasUpdatedState
  );
  const hasUpdatedStateRef = useRef(hasUpdatedState);
  const setHasUpdatedState = (data: boolean) => {
    hasUpdatedStateRef.current = data;
    _setHasUpdatedState(data);
  };

  const [hlText, setHlText] = useState<string>(INITIAL_STATE.hlText);
  // --
  const [host, _setHost] = useState<any>(INITIAL_STATE.host);
  const hostRef = useRef(host);
  const setHost = (data: any) => {
    hostRef.current = data;
    _setHost(data);
  };
  const [isImageChanged, setIsImageChanged] = useState(
    INITIAL_STATE.isImageChanged
  );
  // --
  const [isReady, _setIsReady] = useState<boolean>(INITIAL_STATE.isReady);
  const isReadyRef = useRef(isReady);
  const setIsReady = (data: boolean) => {
    isReadyRef.current = data;
    _setIsReady(data);
  };

  // --
  const [isTheEndOfTheGame, _setIsTheEndOfTheGame] = useState<boolean>(
    INITIAL_STATE.isTheEndOfTheGame
  );
  const isTheEndOfTheGameRef = useRef(isTheEndOfTheGame);
  const setIsTheEndOfTheGame = (data: boolean) => {
    isTheEndOfTheGameRef.current = data;
    _setIsTheEndOfTheGame(data);
  };
  // --
  const [isVisibleVolumeModal, setIsVisibleVolumeModal] = useState(
    INITIAL_STATE.isVisibleVolumeModal
  );
  const [isWaitingNext, setIsWaitingNext] = useState<boolean>(
    INITIAL_STATE.isWaitingNext
  );
  const [jsonVan, setJsonVan] = useState<any>(INITIAL_STATE.jsonVan);
  // --
  const [lastSelectedChoiceIndex, _setLastSelectedChoiceIndex] =
    useState<number>(INITIAL_STATE.lastSelectedChoiceIndex);
  const lastSelectedChoiceIndexRef = useRef(lastSelectedChoiceIndex);
  const setLastSelectedChoiceIndex = (data: number) => {
    lastSelectedChoiceIndexRef.current = data;
    _setLastSelectedChoiceIndex(data);
  };
  // --
  const [passageNumber, setPassageNumber] = useState(
    INITIAL_STATE.passageNumber
  );
  // --
  const [players, _setPlayers] = useState<any>(INITIAL_STATE.players);
  const playersRef = useRef(players);
  const setPlayers = (data: any) => {
    playersRef.current = data;
    _setPlayers(data);
  };
  // --
  const [refreshPlayersList, _setRefreshPlayersList] = useState<boolean>(
    INITIAL_STATE.refreshPlayersList
  );
  const refreshPlayersListRef = useRef(refreshPlayersList);
  const setRefreshPlayersList = (data: boolean) => {
    refreshPlayersListRef.current = data;
    _setRefreshPlayersList(data);
  };

  const [screenType, setScreenType] = useState<ScreenType>(
    INITIAL_STATE.screenType
  );

  // --
  const [secretSequence, _setSecretSequence] = useState<boolean>(
    INITIAL_STATE.secretSequence
  );
  const secretSequenceRef = useRef(secretSequence);
  const setSecretSequence = (data: boolean) => {
    secretSequenceRef.current = data;
    _setSecretSequence(data);
  };

  // --
  const [selectedChoices, _setSelectedChoices] = useState<any>(
    INITIAL_STATE.selectedChoices
  );
  const selectedChoicesRef = useRef(selectedChoices);
  const setSelectedChoices = (data: any) => {
    selectedChoicesRef.current = data;
    _setSelectedChoices(data);
  };

  // --
  const [shouldPlayedFeedbackAnimation, _setShouldPlayedFeedbackAnimation] =
    useState<boolean>(INITIAL_STATE.shouldPlayedFeedbackAnimation);
  const shouldPlayedFeedbackAnimationRef = useRef(
    shouldPlayedFeedbackAnimation
  );
  const setShouldPlayedFeedbackAnimation = (data: boolean) => {
    shouldPlayedFeedbackAnimationRef.current = data;
    _setShouldPlayedFeedbackAnimation(data);
  };
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(
    INITIAL_STATE.showConfirmLeaveModal
  );
  //TODO: Check if use again, maybe now should be canPlayerVote
  const [showContinueButton, setShowContinueButton] = useState<boolean>(
    INITIAL_STATE.showContinueButton
  );
  const [showOverlay, setShowOverlay] = useState<boolean>(
    INITIAL_STATE.showOverlay
  );
  const [showPlayersList, setShowPlayersList] = useState<boolean>(
    INITIAL_STATE.showPlayersList
  );
  const [showPopUpExit, setShowPopUpExit] = useState<boolean>(
    INITIAL_STATE.showPopUpExit
  );
  const [showPopUpBackToGroup, setShowPopUpBackToGroup] = useState<boolean>(
    INITIAL_STATE.showPopUpBackToGroup
  );
  const [showReportScreen, setShowReportScreen] = useState<boolean>(false);
  const [showWaitingModal, setShowWaitingModal] = useState<boolean>(
    INITIAL_STATE.showWaitingModal
  );
  const [switchValueFirst, setSwitchValueFirst] = useState(
    INITIAL_STATE.switchValueFirst
  );
  const [switchValueSecond, setSwitchValueSecond] = useState(
    INITIAL_STATE.switchValueSecond
  );
  const [text, setText] = useState<string>(INITIAL_STATE.text);
  const [toggleBottom, setToggleBottom] = useState<boolean>(
    INITIAL_STATE.toggleBottom
  );
  const [topMenuIsOpen, setTopMenuIsOpen] = useState<boolean>(
    INITIAL_STATE.topMenuIsOpen
  );
  const [chatIsOpen, setChatIsOpen] = useState<boolean>(
    INITIAL_STATE.chatIsOpen
  );
  const [visitedScenes, setVisitedScenes] = useState<Array<number>>(
    INITIAL_STATE.visitedScenes
  );
  const [showPopUpGameOver, setShowPopUpGameOver] = useState(
    INITIAL_STATE.showPopUpGameOver
  );
  const [showInfo, setShowInfo] = useState<any>(INITIAL_STATE.showInfo);
  const [notificationList, setNotificationList] = useState<Array<any>>(
    INITIAL_STATE.notificationList
  );
  // RANDOM DICE
  const [showBoardDicePopup, setShowBoardDicePopup] = useState<boolean>(
    INITIAL_STATE.showBoardDicePopup,
  );
  const [currentChoiceForDicePopup, setCurrentChoiceForDicePopup] =
    useState<IChoice | null>(INITIAL_STATE.currentChoiceForDicePopup);
  const [randomForDicePopup, setRandomForDicePopup] = useState<number | null>(
    INITIAL_STATE.randomForDicePopup,
  );
  //unread messages
  const [messagesLength, setMessagesLength] = useState(0);
  const [showNewMessages, setShowNewMessages] = useState(false);

  //from params
  const [episode, setEpisode] = useState(INITIAL_STATE.episode);
  const [storyID, setStoryID] = useState<any>(INITIAL_STATE.storyID);
  const [title, setTitle] = useState(INITIAL_STATE.title);
  const [gameID, setGameID] = useState(INITIAL_STATE.gameID);
  const [playerID, setPlayerID] = useState(INITIAL_STATE.playerID);
  const [screenName, setScreenName] = useState(INITIAL_STATE.screenName);
  const [colors, setColors] = useState(INITIAL_STATE.colors);
  const [user, setUser] = useState(INITIAL_STATE.user);
  const [nbrPlayers, setNbrPlayers] = useState(INITIAL_STATE.nbrPlayers);
  const [save, setSave] = useState(INITIAL_STATE.save);
  const [storyData, setStoryData] = useState<any>(INITIAL_STATE.storyData);

  // needed because the host gets reset twice if they leave game
  const [wasHost, _setWasHost] = useState<boolean>(INITIAL_STATE.wasHost);
  const wasHostRef = useRef(wasHost);
  const setWasHost = (data: boolean) => {
    wasHostRef.current = data;
    _setWasHost(data);
  };

  const cover = storyID ? getRouteManager().cover(storyID) : null;

  // ====== Local refs ======
  const countdownCall = useRef<any>(null);
  const recheckShouldPlayAnimTimeout = useRef<any>(null);
  const bugLoadingTimeout = useRef<any>(null);
  const lastSound = useRef();
  const soundManager = useRef<SoundManager>();
  const storyReader = useRef<StoryReader>();
  const timerRef = useRef<any>();
  const timerTextRef = useRef<any>();
  const containerRef = useRef<any>(null);


  /**
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * UTILS FUNCTIONS
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   *  */

  /**
   *
   */
  const onAskExit = useCallback((value: boolean): void => {
    setAskExit(value);
  }, []);

  /**
 * Shortcut to return to the library screen
 */
  const goLibrary = (): void => {
    // why? @see all updateStorage() calls on previous screens
    resetStorage();
    setGame("", "", false);
    if (!isTheEndOfTheGameRef.current) {
      SoundManager.getInstance().removeAllSounds();
      goToLibrary(navigate, playerID, hostRef.current);
    }
  };

  /**
  * Shortcut to get color
  */
  const getColor = useCallback((colorType: string): string => {
    return ColorManager.getInstance().getColor(colorType);
  }, []);

  /**
   * Get the anim for a type
   */
  const getAnimForType = (type: string, animations: any = null) => {
    if (animations && animations.length) {
      const anim = animations.find((item: any) => item.type === type);
      if (anim && anim.id) {
        return getRouteManager().animation(anim.id);
      } else {
        return null;
      }
    } else if (storyData) {
      if (storyData.animations && storyData.animations.length) {
        const anim = storyData.animations.find(
          (item: any) => item.type === type
        );
        if (anim && anim.id) {
          return getRouteManager().animation(anim.id);
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
    return null;
  };

  /**
 * Make sure to reset navigation route user param(s)
 */
  const resetNavigationParams = () => {
    setComesFromLocalStorage(false);
  };

  const updateBackgroundCallback = (newbg: any) => {
    if (newbg && newbg[0] && newbg[0].background === true && newbg[0].id) {
      setIsImageChanged(false);
      setTimeout(() => {
        setBackground(getRouteManager().sceneImage(newbg[0].id));
        setIsImageChanged(true);
      }, 400);
    }
  };

  /**
   * @handleLeaveGame
 * Triggered when `Leave game` button was clicked
 * [from TopMenu]
 */
  const handleLeaveGame = async (): Promise<void> => {
    setDisableLeaveGameButton(true);
    setAskExit(false);
    setShowConfirmLeaveModal(INITIAL_STATE.showConfirmLeaveModal);

    const currUser = auth.user;

    const result = await handleQuitGame(
      playerID,
      gameID,
      hostRef.current,
      propertyExists(currUser, "id") ? currUser.id : null
    );

    if (!result) {
      setDisableLeaveGameButton(INITIAL_STATE.disableLeaveGameButton);
    } else {
      goLibrary();
    }
  };

  /**
* @resetApp
* Redirects to LoginScreen to reset game and do the onReboot tasks
*/
  const resetApp = () => {
    navigate("/", { replace: true });
  };

  /**
   * @getStoryData
   * Load StoryData from cache
    */
  const getStoryData = async (id?: number): Promise<any> => {
    const target = id ? id : storyID ? storyID : null;
    if (target) {
      const data = await getCacheManager().loadStory(target);
      if (data) {
        setStoryData(data);
        return data;
      }
      return null;
    }
    return null;
  };

  /**
   * @createImgs
   * TODO: make this a callback, i think it causes the icons to rerender ? 
   * Or, create a list in state ? Or do this directly in top menu since it's only used here
   * Create all icons for global vars & returns list updated with name, value and component
    */
  const createImgs = () => {
    if (storyData) {
      const { iconStates } = storyData;
      if (iconStates) {
        const varsCopy = [];
        const { globalVars } = storyData;
        for (let i = 0; i < iconStates.length; i += 1) {
          const globalVar = globalVars.find(
            (entry: any) =>
              entry.name === iconStates[i].name && entry.type === "var"
          );

          if (globalVar && storyReader.current) {
            const findVar = storyReader.current
              ?.getVariables()
              .getVariableByName(globalVar.name);
            if (findVar && findVar.value) {
              const component = (
                <Box style={{ ...columnStartStyles }} key={i}>
                  <img
                    key={i}
                    src={getRouteManager().icon(iconStates[i].iconId)}
                    style={{
                      width: 48,
                      height: 48,
                      /* tintColor:
                        ColorManager.getInstance().getColor("quaternary"), */
                    }}
                  />
                </Box>
              );

              varsCopy.push({
                name: globalVar.defaultValue,
                varName: globalVar.name,
                component: component,
                value: findVar.value,
              });
            }
          }
        }
        if (varsCopy && varsCopy.length > 0) {
          return varsCopy;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  /**
   * @isTheEndCallback
* Firebase listener callback for `isTheEnd`
* [<=> the current game is over]
*/
  const isTheEndCallback = (snapshot: any): void => {
    setIsWaitingNext(INITIAL_STATE.isWaitingNext);

    if (
      isTrueBoolean(snapshot.val()) &&
      storyReader.current &&
      hasRequiredParamaters()
    ) {
      setIsTheEndOfTheGame(true);

      // kill the current game in realtime database firebase
      if (isHost()) {
        handleQuitGame(playerID, gameID, hostRef.current);
      }

      // make sure to clean local storage on the current device
      localStorage.removeItem("deleteOnlineGameData");
      resetStorage();
      // Show report screen #ReportScreen
      setShowReportScreen(true);
    }
  };

  /**
* Triggered when player taps on the `top menu` button
*/
  const openTopMenu = useCallback((): void => {
    setTopMenuIsOpen(true);
    SoundManager.getInstance().playTopMenuSound();
  }, []);

  /**
   * 
   * NOTIFICATIONS
   * 
   */

  /**
   * @addNotificationToList
   */
  const addNotificationToList = (notif: any) => {
    if (notif && notif.type && notif.item) {
      if (notificationList && notificationList.length > 0) {
        setTimeout(() => {
          setNotificationList((current) => [...current, notif]);
        }, 600);
      } else {
        setNotificationList((current) => [...current, notif]);
      }
    }
  };

  /**
   * @removeNotificationFromList
    */
  const removeNotificationFromList = useCallback(
    (notif: any) => {
      if (notif) {
        setShowInfo(notif);
      }
    },
    [notificationList]
  );

  /**
   * @showStateUpdate
    */
  const showStateUpdate = useCallback((currVar: any) => {
    if (storyReader && storyReader.current) {
      setShowInfo({
        type: "state",
        item: {
          currVar: currVar,
          player: storyReader.current
            .getPlayers()
            .getPlayerById(storyReader.current.getActivePlayerId()),
        },
      });
    }
  }, []);

  /**
   * @showInformationCallback
   */
  const showInformationCallback = (newVal: boolean) => {
    setShowInfo({ type: "hint", item: newVal });
  };

  /**
     * @showDiceGroupFeedback
     * Add notif to indicate a dice has been rolled
     */
  const showDiceGroupFeedback = (newVal: boolean) => {
    setShowInfo({ type: "dice", item: newVal });
  };

  /**
     * @showBoardDicePopupCallback
     * Shortcut to update dice modal
     */
  const showBoardDicePopupCallback = (
    value: boolean,
    choice: IChoice | null,
    random: number | null = null,
  ) => {
    setShowBoardDicePopup(value);
    setCurrentChoiceForDicePopup(choice);
    setRandomForDicePopup(random);
    if (value && !isEnabledSoloMode()) {
      showDiceGroupFeedback(value);
    }
    if (!value && isEnabledSoloMode()) {
      launchTimer();
    }
  };

  /**
   * @showVarupdate
   */
  const showVarupdate = (currVar: any) => {
    setShowInfo({ type: "var", item: currVar });
  };

  /**
 * @showGroupCallback
 * Activate / deactivate solo/secret mode
 * Render Popup
 */
  const showGroupCallback = (newVal: boolean) => {
    // True means that it's back to group
    if (isTrueBoolean(newVal)) {
      setActivePlayerCode(INITIAL_STATE.activePlayerCode);
      const old = showPlayersList;
      setShowPlayersList(newVal);

      // only the host can do these actions
      // to avoid doing it multiple times per player
      if (isHost()) {
        onRemoveSoloPlayerID(gameID);
        onRemoveSecretPlayerID(gameID);
      }
      if (newVal === true && !old) {
        setShowPopUpBackToGroup(true);
        setTimeout(() => {
          setShowPopUpBackToGroup(false);
        }, 2000);
      }
    }
    // false means that it's supposed to start solo mode, we enable it on
    // active player (-1 by default)
    else {
      enableSoloOrSecretMode(-1, ONE_USER_MODE.SOLO);
      setShowPlayersList(newVal);
    }
  };

  /**
  * @showSecretCallback
  */
  const showSecretCallback = useCallback((newVal: boolean) => {
    setSecretSequence(newVal);
  }, []);

  /**
  * @showSuccessUnlocked
  * Try unlock success and if OK, show notif
  */
  const showSuccessUnlocked = useCallback((currVar: any): void => {
    if (auth.user) {
      const api = getRouteManager().checkSucccessForUser();

      const check = fetch(api, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_token: auth.user.login_token,
          name: currVar,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.flashmessage === "success") {
            addNotificationToList({ type: "success", item: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  /**
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * UTILS FUNCTIONS PLAYERS 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * */

  /**
   * @isAvailableCurrentPlayerInGame
   * Shortcut to determine if the current player is AVAILABLE in game
   * playerCode is used only to make sure we have the good solo user if 
   * activePlayerCodeRef is not set yet
   */
  const isAvailableCurrentPlayerInGame = (playerCode: any = null): boolean => {
    let isAvailable = false;

    if (storyReader.current && isNotEmptyString(playerID)) {

      const avPlayers = storyReader.current.getAvailablePlayers();

      const currActiveCode = isNotEmptyString(playerCode)
        ? playerCode
        : activePlayerCodeRef.current;

      // MJ player will be ALWAYS an availabled player
      // [during solo/secret mode]
      if (isEnabledSoloMode() && currActiveCode === playerID) {
        isAvailable = true;
      }
      // normal case [no during solo/secret mode]
      else if (isNotEmptyArray(avPlayers)) {
        isAvailable = avPlayers.some((player) => {
          // allow to identify the current player
          const isCurrentUser =
            propertyExists(player, "playerCode") &&
            player.playerCode === playerID;

          return isCurrentUser ? true : false;
        });
      }
    }

    return isAvailable;
  };

  /**
   * @isExistsSoloOrSecretPlayerID
  * Shortcut to ensure solo or secret player ID
  * exists in realtime database firebase
  */
  const isExistsSoloOrSecretPlayerID = (
    game: any,
    property: string
  ): boolean => {
    if (isNotEmptyString(property)) {
      return (
        propertyExists(game, "story") &&
        propertyExists(game.story, property) &&
        isNumber(game.story[property])
      );
    }

    return false;
  };

  /**
   * @isHost
 * Shortcut to determine if the current player is the game host
 */
  const isHost = (): boolean => {
    if (propertyExists(hostRef.current, "id")) {
      return playerID === hostRef.current.id;
    }

    return false;
  };

  /**
   * @getCurrentPlayerCodeById
* Shortcut to retrieve the current player code by his index ID
* within the existing players list in game
* [current player code <=> player ID in realtime database firebase]
*/
  const getCurrentPlayerCodeById = (id: number): any => {
    let playerCode = null;

    if (isNumber(id) && storyReader.current) {
      const player = storyReader.current.getPlayers().getPlayerById(id);

      if (propertyExists(player, "playerCode")) {
        playerCode = player.playerCode;
      }
    }

    return playerCode;
  };


  /**
   * @getNbPlayersInGame
 * Shortcut to return the current number
 * of players in game according states
 * => Solo or Secret = 1 
 * => Else = All players even indispo
 */
  const getNbPlayersInGame = (): number => {
    const avPlayersLength = getNbPlayers(storyReader.current);
    const currPlayersLength = playersRef.current.length;

    let nbr = avPlayersLength; // default value
    //let nbr = currPlayersLength; // default value

    if (isEnabledSoloMode()) {
      nbr = 1;
    } else if (avPlayersLength < currPlayersLength) {
      nbr = currPlayersLength;
    }

    return nbr;
  };

  /**
   * @getNbPlayersAvailableInGame
   * Returns the number of available players 
   * Solo = 1
   * Else = All available players from StoryReader
    */
  const getNbPlayersAvailableInGame = (): number => {
    const avPlayersLength = getNbPlayers(storyReader.current);
    const currPlayersLength = playersRef.current.length;

    //let nbr = avPlayersLength; // default value
    let nbr = currPlayersLength; // default value

    if (isEnabledSoloMode()) {
      nbr = 1;
    } else if (avPlayersLength < currPlayersLength) {
      nbr = avPlayersLength;
    }

    return nbr;
  };

  /**
   * @userDoesNotComeFromReboot
* Shortcut to determine if the current user has restarted app
* to avoid re-render the `volume info` modal
* [after app killing for instance]
*/
  const userDoesNotComeFromReboot = useCallback((): boolean => {
    return (
      !propertyExists(state, "onReboot") || !isTrueBoolean(state.onReboot)
    );
  }, [state]);

  /**
* @addUserWaiting
* Add the current player ID to the player waiting list
* [within the realtime database firebase]
*
* Will allow to know this player will have to submit his vote
* during the current scene in game
*/
  const addUserWaiting = (): void => {
    if (hasRequiredParamaters()) {
      db.ref(
        `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting/${playerID}`
      )
        .set(screenName)
        .then(() => {
          setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
        })
        .catch(() => {
          setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
        });
    } else {
      setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
    }
  };

  /**
   * @removeUserWaiting
   * Remove the current player ID from the player waiting list
   * [within the realtime database firebase]
   *
   * Will allow to know this player was submitted his vote
   * (or he is unavailable in game)
   */
  const removeUserWaiting = (
    choice: any = null,
    comesFromTextBox = false,
    checkNextScene = true
  ): void => {
    if (storyReader.current && hasRequiredParamaters()) {
      const currSceneID = storyReader.current.getCurrPassageId();

      setShowContinueButton(false);

      if (isNumber(currSceneID)) {
        db.ref(
          `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting/${playerID}`
        )
          .remove()
          .then(() => {
            setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
            if (checkNextScene) {
              //setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
              // is the last player to submit his vote?
              checkIfCanNextScene(currSceneID, choice, comesFromTextBox);
            }
          })
          .catch(() => {
            setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
            setShowContinueButton(INITIAL_STATE.showContinueButton);
          });
      } else {
        setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
        setShowContinueButton(INITIAL_STATE.showContinueButton);
      }
    } else {
      setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
    }
  };


  /**
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   *  UTILS FUNCTIONS GAME
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   *  */

  /**
   * @hasRequiredParamatersadididu63
  * Shortcut to ensure required navigation parameters
  * are not empty
  */
  const hasRequiredParamaters = (): boolean => {
    return (
      isNumber(storyID) &&
      isNotEmptyString(gameID) &&
      isNotEmptyString(playerID) &&
      isNotEmptyString(screenName)
    );
  };

  /**
   * @initGame
   * Setup everything for the game (instantiate StoryReader, loadSave, players, vars, etc...)
   */
  const initGame = async (isActive: boolean) => {
    // are all players in game here at this step?
    const areReadyPlayers =
      isNumber(nbrPlayers) && playersRef.current.length === nbrPlayers;

    const newData = await getStoryData(state?.storyId ? state.storyId : null);
    // condition to avoid trigger multiple times the following code
    // [this code will be triggered one time onmount OR after an app reboot]
    if (
      !isReadyRef.current &&
      isActive &&
      areReadyPlayers &&
      hasRequiredParamaters() &&
      newData
    ) {
      const { colors } = newData;
      ColorManager.getInstance().Clear();
      ColorManager.getInstance().generateColors(colors);
      if (newData.animations && newData.animations.length > 0) {
        setJsonVan(getAnimForType("banner", newData.animations));
      }
      db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}`).once(
        "value",
        (snapshot) => {
          const game = snapshot.val();
          if (
            propertyExists(game, "scene") &&
            isValidScene(game.scene, comesFromLocalStorageRef.current)
          ) {
            // particulary usefull after an app reboot to update states
            // will be null if it's a normal case (onmount)
            const hasDataSaved = propertyExists(game, "storyReader")
              ? JSON.parse(game.storyReader)
              : null;

            const gamePlayers = propertyExists(hasDataSaved, "players")
              ? // special case after app killing/reboot
              getGamePlayers(hasDataSaved.players, playerID, false)
              : // specially at the beginning (normal case onmount)
              getGamePlayers(playersRef.current, playerID);

            const host: any = Object.entries(game.host)[0];

            const checkIsHost = host[0] === playerID;

            // it's OK : we can create our STORY READER instance
            if (isNotEmptyArray(gamePlayers)) {
              storyReader.current = new StoryReader(
                storyID,
                newData,
                gamePlayers,
                title,
                game.scene.number,
                true,
                onAskExit,
                false,
                checkIsHost,
                false,
                comesFromLocalStorageRef.current
              );

              // special case after app killing/reboot
              if (hasDataSaved && propertyExists(hasDataSaved, "variables")) {
                storyReader.current.loadSave({
                  players: gamePlayers,
                  variables: hasDataSaved.variables,
                  hints: propertyExists(hasDataSaved, "informations")
                    ? hasDataSaved.informations
                    : [],
                });
              }
              // specially at the beginning (normal case onmount)
              else {
                storyReader.current.setNbPlayers(gamePlayers.length);
                if (propertyExists(game, "save")) {
                  storyReader.current.loadSaveOnline(game.save);
                }
              }

              if (propertyExists(hasDataSaved, "currentObjective")) {
                storyReader.current.setCurrentObjective(
                  hasDataSaved.currentObjective
                );
              }
              if (propertyExists(hasDataSaved, "visitedScenes")) {
                storyReader.current.setVisitedScenes(
                  hasDataSaved.visitedScenes
                );
              }

              if (episode != null && episode != undefined) {
                storyReader.current.setEpisode(episode);
              }

              soundManager.current = storyReader.current.getSoundManager();
              storyReader.current.shouldPlaySound();

              checkIfIsEnabledSoloOrSecretModeOnMount(
                game,
                "soloPlayerID",
                ONE_USER_MODE.SOLO
              );
              checkIfIsEnabledSoloOrSecretModeOnMount(
                game,
                "secretPlayerID",
                ONE_USER_MODE.SECRET
              );
              setIsReady(true); // will allow you to enter to other functions later
            }
          }
        }
      );
    }
  };

  /**
 * SPECIALLY ONMOUNT
 * @checkIfIsEnabledSoloOrSecretModeOnMount
 * Shortcut to determine if we are in a solo or secret game mode
 * [usefull during an app reboot for example]
 */
  const checkIfIsEnabledSoloOrSecretModeOnMount = (
    game: any,
    property: string,
    type: string
  ): void => {
    // condition to make sure avoid to trigger multiples times
    if (
      isNotEmptyString(property) &&
      isExistsSoloOrSecretPlayerID(game, property) &&
      isNotEmptyString(type) &&
      storyReader.current
    ) {
      const activePlayerID = game.story[property];
      const avPlayers = storyReader.current.getPlayers().players;

      if (isNotEmptyArray(avPlayers)) {
        const activePlayer = avPlayers.find((obj: any) => {
          return obj.id === activePlayerID;
        });

        if (activePlayer) {
          enableSoloOrSecretMode(activePlayerID, type);
          //reCheckIfHasReachedWaitingPlayerSize();
        }
      }
    }
  };

  /**
   * @enableSoloOrSecretMode
 * Shortcut to enable solo or secret mode in game
 */
  const enableSoloOrSecretMode = (id: number, type: string): any => {
    let currActiveCode = null;

    if (storyReader.current && isNotEmptyString(type)) {
      const activePlayerID = isPositiveNumber(id)
        ? id // <=> the selected player id within the players choices
        : storyReader.current.getActivePlayerId(); // default value

      storyReader.current.setActivePlayerId(activePlayerID);

      // only the host can do this following action to avoid doing it multiple times per player
      // [saving player ID in firebase realtime database to inform all players by using listener]
      if (isHost() && !isTrueBoolean(comesFromLocalStorageRef.current)) {
        if (type === ONE_USER_MODE.SECRET) {
          onAddSecretPlayerID(activePlayerID, gameID);
        } else if (type === ONE_USER_MODE.SOLO) {
          onAddSoloPlayerID(activePlayerID, gameID);
        }
      }

      storyReader.current.setMasterPlayerId(activePlayerID);

      setShowPlayersList(false);

      if (type === ONE_USER_MODE.SECRET) {
        setSecretSequence(true);
      }

      currActiveCode = getCurrentPlayerCodeById(activePlayerID);
    }

    setActivePlayerCode(currActiveCode);

    return currActiveCode;
  };

  /**
   * @enableSoloOrSecretMode
 * Shortcut to determine if we are in a `solo` or `secret` workflow
 * [because : by default, state `activePlayerCodeRef` is null]
 */
  const isEnabledSoloMode = (): boolean => {
    return isNotEmptyString(activePlayerCodeRef.current);
  };

  /**
   * @isPlayerDeciging
   * Shortcot to determine if we are supposed to handle action 
   * If secret sequence & is secret player = YES
   * If not secret sequence & is host = YES
   * Else = NO
   */
  const isPlayerDeciding = (): boolean => {
    return ((!(secretSequenceRef.current && isEnabledSoloMode()) && isHost()) || isSecretPlayer())
  }

  /**
     * @isSoloPlayer
     * Shortcut to determine if we are solo / secret player
     * If secret sequence & is secret player = YES
     * If not secret sequence & solo &&  active player = YES
     * Else = NO
     */
  const isSoloPlayer = (): boolean => {
    return (
      isSecretPlayer() ||
      (!secretSequenceRef.current &&
        isEnabledSoloMode() &&
        activePlayerCodeRef.current === playerID)
    );
  };

  /**
* @resetValues
* Reset initial values
*/
  const resetValues = (): void => {
    //resetRecheckShouldPlayAnimTimeout();
    //resetBugLoadingTimeout();
    setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
    setArrowPercent(INITIAL_STATE.arrowPercent);
    setShowContinueButton(INITIAL_STATE.showContinueButton);
    setShouldPlayedFeedbackAnimation(
      INITIAL_STATE.shouldPlayedFeedbackAnimation
    );
    setIsWaitingNext(INITIAL_STATE.isWaitingNext);
    setHasChangedScene(INITIAL_STATE.hasChangedScene);
    setShowWaitingModal(INITIAL_STATE.showWaitingModal);
    //setShowBugLoadingModal(INITIAL_STATE.showBugLoadingModal);
    resetTimer();
  };

  /**
   * @resetTimer
 * Shortcut to reset the timer in game
 */
  const resetTimer = useCallback((): void => {
    clearInterval(countdownCall.current);
    countdownCall.current = null;
    //timerTextRef.current = null;
  }, [countdownCall]);

  /**
* @checkIfCanNextScene
* Checks if it's the last player which was submitted his vote
* [if true, we can continue the workflow
* in order to go to the next scene in game]
*/
  const checkIfCanNextScene = (
    currScene: number,
    choice: any,
    comesFromTextBox = false
  ): void => {
    if (isNotEmptyString(gameID) && isNumber(currScene)) {
      db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting`)
        .orderByKey()
        .once("value", (snapshot) => {
          if (snapshot.val() == null && storyReader.current) {
            // no animation in this case
            if (comesFromTextBox) {
              let playerToSend = -1;
              const textAnswer = choice.text ? choice.text : getTextForNextScene(comesFromTextBox);
              const currChoices = storyReader.current.getCurrentChoices();
              if (isNotEmptyArray(currChoices)) {
                const tmpChoice = currChoices[0];
                const target = storyReader.current.getSceneLinkFromChoice(tmpChoice, textAnswer, playerToSend);
                if (target) {
                  // On vérifie si prochain passage call random, 
                  if (target.text && target.text.includes(RANDOM_IDENTIFIER)) {
                    playerToSend = storyReader.current.getRandomPlayer();
                  }
                  // On update la scene avec la target pour trigger changement
                  // de scene pour tout le monde
                  onUpdateScene(
                    {
                      number: target.number,
                      enableCallback: true,
                      // Joueur à mettre actif (choix joueur ou random) ou -1
                      newActivePlayer: playerToSend,
                      randomInfos: null,
                    },
                    gameID
                  );
                }
              }

            }
            // standard case
            // we have to play a feedback animation when
            // all players have submitted their votes
            else if (
              isScreenWithChoices(storyReader.current) ||
              isScreenWithNextButton(storyReader.current)
            ) {
              handleShouldPlayedFeedbackAnimation(choice);
            }
          }
        });
    }
  };

  /**
   * @getSelectedChoice
   * Shortcut to retrieve the definitive selected choice to pass to the story reader
   * [in order to continue game - specially during a standard/timer scene]
   */
  const getSelectedChoice = (latestChoice: any): any => {
    // default choice (case when the current scene in game didn't have choices)
    let choice: any = latestChoice;

    // case when the current scene in game contains choices
    if (isNotEmptyArray(selectedChoicesRef.current)) {
      // to get `count` property [each count value means `a voté`]
      let finalChoices: any = null;

      // case when it's a players selection
      if (propertyExists(latestChoice, "playerIndex")) {
        const arr = [...selectedChoicesRef.current];
        finalChoices = countDuplicates(arr, "playerIndex");
      }
      // case when it's a standard selection
      else if (propertyExists(latestChoice, "text")) {
        const arr = [...selectedChoicesRef.current];
        finalChoices = countDuplicates(arr, "text");
      }

      if (isNotEmptyArray(finalChoices)) {
        choice = getFinalChoice(finalChoices);
      }
    } else {
      // Get here means no choices where selected (list empty)
      if (storyReader.current) {
        const choiceType = storyReader.current.getChoiceType();
        const isPlayerChoice = choiceType === ChoiceTypeEnum.PLAYER;
        // If player choice : take first player in list, else take latestChoice 
        // Wich is already index 0 choice
        if (isPlayerChoice) {
          const index = storyReader.current.getPlayerIndexFromIndexInList(0);
          choice = { count: 1, indexInList: 0, playerIndex: index };
        }
      }
    }

    return choice;
  };

  /**
 * @handleShouldPlayedFeedbackAnimation
 * This function allows to notify all players they CAN play
 * the feedback animation on their devices (about the definitive selected choice)
 */
  const handleShouldPlayedFeedbackAnimation = (choice: any): void => {
    if (
      storyReader.current &&
      !shouldPlayedFeedbackAnimationRef.current &&
      isNotEmptyString(gameID)
    ) {
      const selectedChoice = getSelectedChoice(choice);

      let selectedChoiceData = null;

      // case when it's a players selection
      if (
        propertyExists(selectedChoice, "playerIndex") &&
        propertyExists(selectedChoice, "indexInList")
      ) {
        selectedChoiceData = {
          playerIndex: selectedChoice.playerIndex,
          indexInList: selectedChoice.indexInList,
        };
      }
      // case when it's a standard selection
      else if (propertyExists(selectedChoice, "text")) {
        if (selectedChoice.text === "FIN_NEXT") {
          // case using next button
          selectedChoiceData = END_GAME_NEXT;
        } else if (selectedChoice.text === "FIN") {
          // case using standard choice
          selectedChoiceData = END_GAME_CHOICE;
        } else {
          const currChoices = storyReader.current.getCurrentChoices();

          if (isNotEmptyArray(currChoices)) {
            selectedChoiceData = currChoices.findIndex((obj: any) => {
              return obj.text === selectedChoice.text;
            });
          }
        }
      }
      // case when it's a next button click
      else if (isScreenWithNextButton(storyReader.current)) {
        selectedChoiceData = NEXT_ANIMATION_NAME;
      }

      // update the `selectedChoiceData` value in realtime database firebase
      // then we have to listen the `shouldPlayedFeedbackAnimation` value changes
      // to continue workflow on all players devices
      if (
        isNotEmptyString(selectedChoiceData) ||
        isObject(selectedChoiceData) ||
        isPositiveNumber(selectedChoiceData)
      ) {
        onUpdateShouldPlayedFeedbackAnimation(selectedChoiceData, gameID);
      }
    }
  };

  /**
   * @handleShowContineButtonState
     * Shortcut to enable/disable the state `showContinueButton`
     * (<=> the main submit button)
     */
  const handleShowContineButtonState = useCallback(
    (arr: any, disabled: boolean): void => {
      if (isNotEmptyArray(arr) && disabled) {
        setShowContinueButton(false);
      } else {
        setShowContinueButton(INITIAL_STATE.showContinueButton);
      }
    },
    []
  );

  /**
   * @hideOnSecretMode
 * Shortcut to determine if the current player is the secret player
 * [during a `secret` mode]
 */
  const hideOnSecretMode = (): boolean => {
    return (
      secretSequenceRef.current &&
      isEnabledSoloMode() &&
      activePlayerCodeRef.current !== playerID
    );
  };

  const isSecretPlayer = (): boolean => {
    return (
      secretSequenceRef.current &&
      isEnabledSoloMode() &&
      activePlayerCodeRef.current === playerID
    );
  };

  /**
   * @shouldPlayedFeedbackAnimationCallback
 * Firebase listener callback for `shouldPlayedFeedbackAnimation`
 * [should we play the ending animation
 * to confirm the final selected choice for all players devices?]
 */
  const shouldPlayedFeedbackAnimationCallback = (snapshot: any): void => {
    // a STRING type means it's a NEXT scene (arrow button) or it's the END game choice
    if (isNotEmptyString(snapshot.val())) {
      if (
        (snapshot.val() === NEXT_ANIMATION_NAME ||
          snapshot.val() === END_GAME_NEXT) && // is the END of the game?
        arrowPercentRef.current != 100
      ) {
        setIsWaitingNext(true);
        setLastSelectedChoiceIndex(INITIAL_STATE.lastSelectedChoiceIndex); // important

        // play the percentage gauge animation to 100% (around the circle button)
        setArrowPercent(100);

        // HERE: trigger go to next scene 
        if (isPlayerDeciding() && storyReader?.current) {
          const currChoices = storyReader.current.getCurrentChoices();
          if (currChoices?.length) {
            setTimeout(() => {
              handleSelectChoice(currChoices[0], -1, -1, true);
            }, 600);
          }
        }
      }
      // is the END of the game?
      else if (
        snapshot.val() === END_GAME_CHOICE &&
        !shouldPlayedFeedbackAnimationRef.current
      ) {
        // will allow to play the feedback animation
        // @see handleSelectChoice() call
        setShouldPlayedFeedbackAnimation(true);
        setChoiceTimerSelect(0);
        if (isPlayerDeciding() && storyReader.current) {
          const currChoices = storyReader.current.getCurrentChoices();
          if (currChoices?.length) {
            setTimeout(() => {
              handleSelectChoice(currChoices[0], -1, -1, true);
            }, 600);
          }
        }
      }
    }
    // CHOICES scene
    else if (!shouldPlayedFeedbackAnimationRef.current) {
      // a NUMBER type means it's a STANDARD choices scene
      if (isNumber(snapshot.val())) {
        setLastSelectedChoiceIndex(snapshot.val());
        if (!hideOnSecretMode()) {
          // will allow to play the feedback animation
          // @see handleSelectChoice() call
          setShouldPlayedFeedbackAnimation(true);
          const indexToChoose = snapshot.val() ? snapshot.val() : 0;
          setChoiceTimerSelect(indexToChoose);

          if (isPlayerDeciding() && storyReader.current) {
            const currChoices = storyReader.current.getCurrentChoices();
            if (currChoices?.length) {
              setTimeout(() => {
                handleSelectChoice(currChoices[indexToChoose], -1, -1, true);
              }, 600);
            }
          }
        }
      }
      // an OBJECT type means it's a PLAYERS choices scene
      else if (
        isObject(snapshot.val()) &&
        propertyExists(snapshot.val(), "playerIndex") &&
        propertyExists(snapshot.val(), "indexInList")
      ) {
        const playerIndex = snapshot.val().playerIndex;
        const indexInList = snapshot.val().indexInList;

        setLastSelectedChoiceIndex(playerIndex);
        if (!hideOnSecretMode()) {
          // will allow to play the feedback animation
          // @see handleSelectChoice() call
          setShouldPlayedFeedbackAnimation(true);
          const indexToChoose = indexInList ? indexInList : 0;
          setChoiceTimerSelect(indexToChoose);

          if (isPlayerDeciding() && storyReader.current) {
            const currChoices = storyReader.current.getCurrentChoices();
            if (currChoices?.length) {
              setTimeout(() => {
                handleSelectChoice(currChoices[0], playerIndex, indexInList, true);
              }, 600);
            }
          }
        }
      } else {
        setShouldPlayedFeedbackAnimation(
          INITIAL_STATE.shouldPlayedFeedbackAnimation
        );
      }
    } else {
      setShouldPlayedFeedbackAnimation(
        INITIAL_STATE.shouldPlayedFeedbackAnimation
      );
    }
  };

  /**
   * @isDisabled
   * TODO: Update this with new concerned players system 
  * Shortcut to determine if the action buttons are disabled or not
  */
  const isDisabled = () => {
    return !showContinueButton;
  };

  /**
   * @getDisabledText
 * Shortcut to get the disabled text
 * [when action buttons are disabled]
 */
  const getDisabledText = () => {
    let text = null;

    if (isDisabled() && storyReader.current) {
      const isAvailable = isAvailableCurrentPlayerInGame();

      if (!isAvailable || isEnabledSoloMode()) {
        text = strings.messages.notConcerned;
      } else {
        text = strings.messages.alreadyVoted;
      }
    }

    return text;
  };

  /**
   * @onSkipImage
   * Triggered when player taps on the `arrow` button
   * [specially during the `poolp` scene]
  */
  const onSkipImage = (choice: any = null): void => {
    if (hasChangedSceneRef.current) {
      if (storyReader.current && showContinueButton) {
        const currChoices = storyReader.current.getCurrentChoices();
        if (isNotEmptyArray(currChoices)) {
          let choiceObj: any = null;
          // case when is the END of the game
          if (propertyExists(choice, "text")) {
            choiceObj = { text: choice.text };
          }
          // remove from waiting list
          // to indicate the current player has already voted
          removeUserWaiting(choiceObj);
        }
      }
    } else {
      setShowWaitingModal(true);
      //reCheckIfHasReachedWaitingPlayerSize();
    }
  };

  /**
   * @onSubmitTextbox
 * Triggered when player entered a string in a textinput
 * [specially during a `Textbox` scene]
 */
  const onSubmitTextbox = (str: string): void => {
    // condition to avoid trigger multiple times the following code
    if (showContinueButton && hasRequiredParamaters() && isString(str)) {
      // custom choice based a IChoice existing properties
      const obj: any = { text: str };

      db.ref(`/${FIREBASE_DATABASE.REFERENCES.CHOICES}/${gameID}/${playerID}`)
        .set(obj)
        .then(() => {
          removeUserWaiting(obj, true);
        });
    }
  };

  /**
   * @handleSelectChoice 
   * Triggered when user long taps on a specific choice
   * [we add selected choices to realtime database firebase -
   * specially during a standard/players selection/timer scene]
   */
  const handleSelectChoice = async (
    choice: IChoice,
    index = -1,
    indexPlayer = -1, // specially during a players selection
    forceNext = false,
  ): Promise<void> => {
    // this condition because the end of the feedback animation
    // will redirect user here
    // @see <Choice /> component and the timerAnim() call function
    if (shouldPlayedFeedbackAnimationRef.current || forceNext) {
      // Only host gets here after anim, trigger update to next scene for everyone
      // If secret sequence, if secret player is not host then he won't see choices
      // and then handleSelectChoice will not trigger second time to validate
      // so add isSecretPlayer in condition => this way, all cases validate choice and 
      // there is no dupplicate enter in fn.
      if ((isHost() || isSecretPlayer()) && storyReader.current && hasRequiredParamaters()) {

        const currChoices = storyReader.current.getCurrentChoices();
        const currSceneID = storyReader.current.getCurrPassageId();
        const choiceType = storyReader.current.getChoiceType();

        if (
          isNotEmptyArray(currChoices) &&
          currChoices[0].text === "FIN" // is the END of the game?
        ) {
          onUpdateIsTheEnd(true, gameID);
        } else {
          // Si pas la fin, on calcule le choix
          const choice = getChoiceForNextScene(choiceType, currChoices);

          if (choice !== null && isObject(choice)) {
            const isPlayerChoice = choiceType === ChoiceTypeEnum.PLAYER;
            const isTextBox = choiceType === ChoiceTypeEnum.TEXTBOX;

            let isRandomDice = false;
            if (choice.isRandom && choice.diceNumber && choice.diceProbaType) {
              isRandomDice = true;
            }
            let playerToSend = isPlayerChoice && isPositiveNumber(lastSelectedChoiceIndexRef.current) ? lastSelectedChoiceIndexRef.current : -1;

            // On vérifie si choix joueur
            let target = null;
            let randomInfos = null;
            if (isRandomDice) {
              const randomNumber = storyReader.current.getRandomDiceNumber();
              target = storyReader.current._getScene(storyReader.current.handleRandomWithModal(
                choice,
                randomNumber,
              ));
              randomInfos = {
                randomNumber: randomNumber,
                choice: choice,
              };
            } else {
              target = storyReader.current.getSceneLinkFromChoice(
                choice,
                getTextForNextScene(isTextBox),
                playerToSend,
              );
            }

            if (target) {
              // On vérifie si prochain passage call random, 
              if (target.text && target.text.includes(RANDOM_IDENTIFIER)) {
                playerToSend = storyReader.current.getRandomPlayer();
              }
              // On update la scene avec la target pour trigger changement
              // de scene pour tout le monde
              onUpdateScene(
                {
                  number: target.number,
                  enableCallback: true,
                  // Joueur à mettre actif (choix joueur ou random) ou -1
                  newActivePlayer: playerToSend,
                  // Infos de random (nombre pick & choix pour affichage) ou null
                  randomInfos: randomInfos,
                },
                gameID
              );
            } else {
              alert("Error, no scene linked to this choice");
            }
          }
        }
      }
    } else if (
      // Means everyone has changed scene, enter this means player just made a choice
      hasChangedSceneRef.current
    ) {
      if (showContinueButton && storyReader.current) {
        let choiceObj: any = null;
        if (!isSingleNextChoice(storyReader.current)) {
          // case when it's a players selection
          if (index !== -1 && indexPlayer !== -1) {
            choiceObj = {
              playerIndex: index,
              indexInList: indexPlayer,
            };
          }
          // case when it's a standard selection
          else if (propertyExists(choice, "text")) {
            choiceObj = { text: choice.text };
          }

          if (isObject(choiceObj) && hasRequiredParamaters()) {
            db.ref(
              `/${FIREBASE_DATABASE.REFERENCES.CHOICES}/${gameID}/${playerID}`
            )
              .set(choiceObj)
              .then(() => {
                // remove from waiting list
                // to indicate the current player has already voted
                // Will also 
                removeUserWaiting(choiceObj, false);
              });
          }
        } else {
          removeUserWaiting(null, false);
        }
      }
    }
    // else means not everyone changed scene + no choice made 
    else if (choiceTimerSelect == INITIAL_STATE.choiceTimerSelect) {
      setShowWaitingModal(true);
      //TODO: Check next fn because maybe some usefull code even if waiting size removed
      //reCheckIfHasReachedWaitingPlayerSize();
    }
  };

  /**
   * @hasChangedSceneCallback
 * Firebase listener callback for `hasChangedScene`
 * [we ensure each player in game has changed the scene
 * in order to allow host player to delete game choices in database to
 * start again from beggining]
 */
  const hasChangedSceneCallback = (snapshot: any): void => {
    const playerKeys: any = _(snapshot.val()).values();
    const asArray = [...playerKeys];

    if (isNotEmptyArray(asArray)) {
      const playerKeysSize = asArray.length;

      // means all players have changed scene successfully
      const hasReachedNbPlayers = playerKeysSize === nbrPlayers;

      // only the host can do these actions
      // to avoid doing it multiple times per player
      if (isHost() && hasReachedNbPlayers && isNotEmptyString(gameID)) {
        onRemoveHasChangedScene(gameID, (response: boolean) => {
          if (isTrueBoolean(response)) {
            onDeleteGameChoices(gameID);
          }
        });
      }

      if (hasReachedNbPlayers) {
        setHasChangedScene(true);
      }
    }
  };

  /**
   * @playersWaitingChangeCallback
 * Firebase listener callback
 * for players in `waiting` state. Will manage continue button and update progress circle
 */
  const playersWaitingChangeCallback = async (snapshot: any): Promise<void> => {
    // condition to avoid trigger multiple times the following code
    if (
      isValidSnapshot(snapshot, 11) &&
      isNotEmptyString(playerID) &&
      storyReader.current
    ) {
      const waiting: any = _(snapshot.val()).values();
      const waitingSize = [...waiting].length;

      const keys: any = _(snapshot.val()).keys();
      const asArray: Array<string> = [...keys];

      handleShowContineButtonState(asArray, !asArray.includes(playerID));
      //checkIfHasReachedWaitingPlayersSize(waitingSize);

      // load percent in the progress circle (specially for next scene)
      // [around the circle button]
      if (
        isNumber(waitingSize) &&
        waitingSize > 0 &&
        isScreenWithNextButton(storyReader.current) &&
        hasChangedSceneRef.current &&
        !isEnabledSoloMode()
      ) {
        const formula = waitingSize * 100;
        setArrowPercent(100 - formula / getNbPlayersAvailableInGame());
      }
    }
  };

  /**
   * @checkIfShouldBeAddedToHasChangeScene
   * Sert à vérifier qu'un joueur n'a pas besoin d'être ajouté à la liste
   * HasChangedScene au reboot. S'il n'y est pas alors que les autres oui,
   * ça bloque la game car on reset pas les choix, et tout le monde a modale
   * pas pret
   */
  const checkIfShouldBeAddedToHasChangeScene = async () => {
    if (hasRequiredParamaters()) {
      const rest = await db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasChangedScene`)
        .once("value", async (snapshot) => {
          if (
            isValidSnapshot(snapshot, 11) &&
            isNotEmptyString(playerID) &&
            storyReader.current
          ) {

            const waiting: any = _(snapshot.val()).values();
            const waitingSize = [...waiting].length;

            const keys: any = _(snapshot.val()).keys();
            const asArray: Array<string> = [...keys];

            if (
              !asArray.includes(playerID) &&
              isTrueBoolean(comesFromLocalStorageRef.current)
              // Peut être ajouter une condition sur !hasChangedScene ? Cas ou la
              // liste aurait pas encore été supprimée. 
            ) {
              // Si la liste des hasChangedScene n'est pas vide, qu'on est pas dedans 
              // et qu'on est au reboot, alors on s'ajoute aux hasChangedScene, seulement
              // hasChangedScene est set a true dans sceneChangeCallback
              await onAddHasChangedScene(playerID, gameID);
            }
          }
        });
    }
  };

  /**
   * @checkIfShouldBeAddedToWaitingOnReboot
   * Called on reboot to check if need to add to waiting list :
   */
  const checkIfShouldBeAddedToWaitingOnReboot = async (): Promise<void> => {
    if (hasRequiredParamaters()) {
      const rest = await db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.CHOICES}/${gameID}`)
        .once("value", async (snapshot) => {
          if (
            isValidSnapshot(snapshot, 11) &&
            isNotEmptyString(playerID) &&
            storyReader.current
          ) {
            const keys: any = _(snapshot.val()).keys();
            const asArray: Array<string> = [...keys];

            if (
              !asArray.includes(playerID) &&
              isTrueBoolean(comesFromLocalStorageRef.current)
            ) {
              // Si il y a des choix et qu'on est pas dans ceux qui ont voté
              // Alors on vérifie si on doit être ajouté
              checkIfShouldBeAddedToWaiting();
            }
          } else {
            // Si il n'y a pas de choix dans la liste, alors on vérifie par défaut
            if (isNotEmptyString(playerID) && storyReader.current) {
              checkIfShouldBeAddedToWaiting();
            }
          }
        });
    }
  };

  /**
   * @checkIfShouldBeAddedToWaiting
 * Same check as in @updateState :
 * If is available and
 * - SoloMode & MJ > addUserWaiting
 * - Not soloMode & available > addUserWaiting
 */
  const checkIfShouldBeAddedToWaiting = (): void => {
    if (storyReader.current) {
      if (isEnabledSoloMode()) {
        // solo/scret mode (a player has been selected to become the active player)
        // so all players who are not the selected player will have to wait
        // [by removing them from queue within the realtime database firebase]
        if (
          activePlayerCodeRef.current &&
          activePlayerCodeRef.current === playerID
        ) {
          addUserWaiting();
        }
      } else if (isAvailableCurrentPlayerInGame()) {
        // May be broken: if solo not enabled yet, we may add a user that is not the solo
        // player and he would have to vote => will likely break next
        // solution: timeout ? to make sure solo not enabled ?
        // solution 2: Use hasChangedScene or something like that to make sure we have
        // changed scene ?
        addUserWaiting();
      }
    }
  };


  /**
  * @getChoiceForNextScene
  * We determine the definitive selected choice in game
  * Then, we can use it through the goToNextScene() function
  */
  const getChoiceForNextScene = (
    type: number,
    choices: IChoice[]
  ): IChoice | null => {
    let choice = null;
    if (isNumber(type) && isNotEmptyArray(choices)) {
      const isPlayerChoice = type === ChoiceTypeEnum.PLAYER;
      const isTextBox = type === ChoiceTypeEnum.TEXTBOX;

      if (isPlayerChoice || isTextBox) {
        // the selected choice in these cases
        choice = choices[0];
      } else if (isPositiveNumber(lastSelectedChoiceIndexRef.current)) {
        // dynamic choice
        choice = choices[lastSelectedChoiceIndexRef.current];
      } else {
        // default choice
        choice = choices[0];
      }
    }
    return choice;
  };

  /**
   * @getTextForNextScene
   * Get the textbox text for next scene (see @goToNextScene callbacks)
   * Then, we can use it through the goToNextScene() function
  */
  const getTextForNextScene = (isTextBox: boolean): string => {
    let textForScene = "";
    if (
      isTextBox &&
      isNotEmptyArray(selectedChoicesRef.current) &&
      propertyExists(selectedChoicesRef.current[0], "text")
    ) {
      textForScene = selectedChoicesRef.current[0].text;
      setLastSelectedChoiceIndex(INITIAL_STATE.lastSelectedChoiceIndex);
    }
    return textForScene;
  };


  const gameOverCallback = (newVal: boolean) => {
    setShowPopUpGameOver(newVal);
  };

  /**
   * @sceneChangeCallback
   * STEP 3 BIS - Firebase listener callback for `scene` change
   * Gets the new scene ID + possible new active player 
   */
  const sceneChangeCallback = async (snapshot: any): Promise<void> => {
    // condition to avoid trigger multiple times the following code
    if (
      isValidScene(snapshot.val(), comesFromLocalStorageRef.current) &&
      storyReader.current &&
      isReadyRef.current &&
      !hasUpdatedStateRef.current &&
      !isTheEndOfTheGameRef.current
    ) {
      setNotificationList([]);
      setShowInfo(null);
      if (!comesFromLocalStorageRef.current && isHost()) {
        onRemoveHasPlayedFeedbackAnimation(gameID);
      }
      // If not hideOnSecretMode && has random infos : show dice modal
      if (!hideOnSecretMode() && snapshot.val().randomInfos) {
        if (isEnabledSoloMode()) {
          showBoardDicePopupCallback(
            true,
            snapshot.val().randomInfos.choice,
            snapshot.val().randomInfos.randomNumber,
          );
        } else {
          showBoardDicePopupCallback(true, null, null);
        }
      } else {
        showBoardDicePopupCallback(false, null, null);
      }
      const currSceneID = storyReader.current.getCurrPassageId();
      const choiceType = storyReader.current.getChoiceType();
      const currChoices = storyReader.current.getCurrentChoices();
      // condition to avoid trigger multiple times the following code
      if (
        isNumber(currSceneID) &&
        isNumber(choiceType) &&
        isNotEmptyArray(currChoices)
      ) {
        const nextSceneNumber = snapshot.val().number;
        const onReboot = isTrueBoolean(comesFromLocalStorageRef.current);
        let wasRandomDiceChoice = false;
        if (snapshot.val().randomInfos && isEnabledSoloMode()) {
          wasRandomDiceChoice = true;
        }
        if (nextSceneNumber <= 1 || onReboot) {
          setHasChangedScene(true);
          updateState(onReboot, false, wasRandomDiceChoice);
        } else if (nextSceneNumber && nextSceneNumber !== currSceneID) {
          setLastSelectedChoiceIndex(INITIAL_STATE.lastSelectedChoiceIndex);
          resetValues();

          if (isPositiveNumber(snapshot.val().newActivePlayer)) {
            storyReader.current.setActivePlayerId(snapshot.val().newActivePlayer);
          }


          await storyReader.current.goToSpecificScene(
            nextSceneNumber,
            showStateUpdate,
            showSuccessUnlocked,
            showGroupCallback,
            showSecretCallback,
            gameOverCallback,
            () => null,
            showVarupdate,
            showInformationCallback,

          );

          // make sure to reset values
          setSelectedChoices(INITIAL_STATE.selectedChoices);
          setChoiceTimerSelect(INITIAL_STATE.choiceTimerSelect);

          updateState(onReboot, true, wasRandomDiceChoice);
          storyReader.current.shouldPlaySound();
        }
      }
    }
  }

  /**
    * @launchTimer
    * Launch timer shortcut
    */
  const launchTimer = () => {
    if (storyReader.current) {
      const type = storyReader.current.getScreenType();
      if (isScreenWithTimer(type) && !countdownCall.current) {
        // handle launch timer
        countdownCall.current = setInterval(() => {
          setCountdown((currentTime) => currentTime - 1);
        }, 1000);
      }
    }
  };

  /**
   * @updateState
   * Update UI state from Story Reader
   */
  const updateState = async (
    onReboot: boolean,
    shouldUpdateScene = true,
    wasRandomDiceChoice = false,
  ): Promise<void> => {
    if (
      storyReader.current &&
      !hasUpdatedStateRef.current &&
      isReadyRef.current &&
      hasRequiredParamaters()
    ) {
      const currSceneID = storyReader.current.getCurrPassageId();

      if (currSceneID != currPassageIdRef.current) {
        const type = storyReader.current.getScreenType(
          updateBackgroundCallback
        );

        setHasUpdatedState(true);
        resetTimer();
        setToggleBottom(false);
        setText(storyReader.current.getCurrentText());
        setChoices(storyReader.current.getCurrentChoices());
        setHlText(storyReader.current.getHlText().arrToReturn);
        setCountdown(storyReader.current.getTimerValue());
        setScreenType(type);

        if (isScreenWithTimer(type) && !countdownCall.current) {
          if (!wasRandomDiceChoice || (wasRandomDiceChoice && !isEnabledSoloMode())) {
            launchTimer();
          }
        }

        setCurrPassageId(currSceneID);

        // only the host can do this following action
        // to avoid doing it multiple times per player
        if (isHost()) {
          // will be used in case of app kill
          // @see `new StoryReader()` call on this file
          onSaveVariablesAndPlayers(
            gameID,
            storyReader.current.getVariables().variables,
            storyReader.current.getPlayers().players,
            storyReader.current.getInformations(),
            storyReader.current.getCurrentObjective(),
            storyReader.current.getVisitedScenes()
          );
          onRemoveShouldPlayedFeedbackAnimation(gameID);

        }

        setVisitedScenes(storyReader.current.getVisitedScenes());
        setPassageNumber((old) => old + 1);

        const isChangeScreen = type == ScreenType.CHANGE;
        const isSecretScreen = type == ScreenType.SECRET;

        let playerCode = null;

        if (isChangeScreen) {
          playerCode = enableSoloOrSecretMode(
            -1,
            ONE_USER_MODE.SOLO
          );
        } else if (isSecretScreen) {
          playerCode = enableSoloOrSecretMode(
            -1,
            ONE_USER_MODE.SECRET
          );
        }

        setLastSelectedChoiceIndex(INITIAL_STATE.lastSelectedChoiceIndex); // reset value
        // used in order to force re-render players list
        setRefreshPlayersList(!refreshPlayersListRef.current);

        //TODO: FINISH

        if (!onReboot) {
          if (shouldUpdateScene) {
            // we add the current player ID in an array in realtime database firebase
            // to indicate the current number of players which are changed of scene in game
            // successfully
            await onAddHasChangedScene(playerID, gameID);
          }

          if (isEnabledSoloMode()) {
            // solo/scret mode (a player has been selected to become the active player)
            // so all players who are not the selected player will have to wait
            // [by removing them from queue within the realtime database firebase]
            if (
              activePlayerCodeRef.current &&
              activePlayerCodeRef.current === playerID
            ) {
              addUserWaiting();
            } else {
              removeUserWaiting(null, false, false);
            }
          } else if (
            isAvailableCurrentPlayerInGame(playerCode)
          ) {
            addUserWaiting();
          } else {
            removeUserWaiting(null, false, false);
          }
        } else {
          // On reboot case 
          setHasUpdatedState(INITIAL_STATE.hasUpdatedState);
          setChoiceTimerSelect(INITIAL_STATE.choiceTimerSelect);
          // Au reboot, si on a quitté pendant le loading,
          // on peut ne pas être dans haschangedScene et bloquer la game donc on vérifie
          await checkIfShouldBeAddedToHasChangeScene();
          // Au reboot on vérifie si le joueur devrait être ajouté aux waitings
          await checkIfShouldBeAddedToWaitingOnReboot();
          resetNavigationParams();
        }

      }

    }
  }



  /**
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * USE EFFECTS
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * */




  /**
 * STEP 1 - Get players in game on mount
 * [from firebase realtime database]
 */
  useEffect(() => {
    let onPlayersJoiningChange: any = null;

    if (isNotEmptyString(gameID)) {
      // --
      onPlayersJoiningChange = db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`)
        .on("value", (snapshot: any) => {
          if (!isValidSnapshot(snapshot, 11)) {
            goLibrary();
            return;
          }

          const dbPlayers: any[] = _.toPairs(snapshot.val());
          const asArray = [...dbPlayers];

          // condition to avoid a useless update of local state
          if (!_.isEqual(playersRef.current, asArray)) {
            setPlayers(asArray);
          }
        });
    }

    return () => {
      // stop listening for updates when no longer required
      if (onPlayersJoiningChange != null && isNotEmptyString(gameID)) {
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`).off(
          "value",
          onPlayersJoiningChange
        );
      }

      resetValues();
    };
  }, []);

  /**
  * STEP 2 - Init story reader in game
  * [just after successfully getting players in game]
  */
  useEffect(() => {
    let isActive = true;
    if (isNumber(storyID)) {
      initGame(true);
    }
    return () => {
      isActive = false;
    };
  }, [playersRef.current, storyID]);

  /**
 * STEP 3 - Firebase event subscriptions
 */
  useEffect(() => {
    let isActive = true;

    let onHostChange: any = null;
    let onChoicesChange: any = null;
    let onShouldPlayedFeedbackAnimation: any = null;
    let onPlayersWaitingChange: any = null;
    let onSceneChange: any = null;
    //let onRandomPlayerID: any = null;
    let onIsTheEnd: any = null;
    let onHasChangedScene: any = null;

    // condition to avoid trigger multiple times the following code
    if (isActive && isReadyRef.current && hasRequiredParamaters()) {
      if (!jsonVan && storyData) {
        setJsonVan(getAnimForType("banner"));
      }

      // --
      onHostChange = db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/host`)
        .on("value", (snapshot: any) => {
          setAskExit(false);

          if (!isValidSnapshot(snapshot, 13)) {
            goLibrary();
            return;
          }

          if (!propertyExists(hostRef.current, "name")) {
            if (snapshot.val() === "") {
              if (!wasHostRef.current) {
                setAskExit(false);
                handleLeaveGame();
              }
            } else {
              const host: any = Object.entries(snapshot.val())[0];

              setHost({ name: host[1], id: host[0] });

              if (playerID === host[0]) {
                setWasHost(true);
              }
            }
          }
          setAskExit(true);
        });

      // listen for choices submitted (used for count voters)
      onChoicesChange = db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.CHOICES}/${gameID}`)
        .on("value", (snapshot: any) => {
          if (isValidSnapshot(snapshot, 11)) {
            const keys: any = _(snapshot.val()).keys();
            const choiceKeys: Array<string> = [...keys];
            //Used to tell player already voted
            handleShowContineButtonState(
              choiceKeys,
              choiceKeys.includes(playerID)
            );

            const result: any = _(snapshot.val()).values();
            const asArray = [...result];

            // condition to avoid a useless update of local state
            if (!_.isEqual(selectedChoicesRef.current, asArray)) {
              setSelectedChoices(asArray);
            }

            if (asArray.length === getNbPlayersAvailableInGame()) {
              // initie un timeout qui sera clear au changement
              // de passage et qui forcera le check can next scene.
              // Sert si on est pas passé au passage suivant normalement
              // Peut potentiellement tout péter
              /* recheckShouldPlayAnimTimeout.current = setTimeout(() => {
                  checkIfShouldNextScene();
              }, 5000); */
            }
          }
        });

      // listen for shouldPlayedFeedbackAnimation (play anim for selected choice)
      onShouldPlayedFeedbackAnimation = db
        .ref(
          `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/shouldPlayedFeedbackAnimation`
        )
        .on("value", shouldPlayedFeedbackAnimationCallback);

      if (isNotEmptyArray(players)) {
        // listen for waiting changes, see callback
        onPlayersWaitingChange = db
          .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting`)
          .on("value", playersWaitingChangeCallback);
      }
      // Listener on scene, will initiate sceneChange for everyone once host said to do so
      onSceneChange = db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/scene`)
        .on("value", sceneChangeCallback);

      // TODO: Move this part in the host go to next scene to get the random player
      // We do not use this callback anymore since random will be passed with scene to go to
      /* onRandomPlayerID = db
          .ref(
              `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/story/randomPlayerID`
          )
          .on("value", (snapshot: any) => {
              if (
                  (isNumber(snapshot.val()) || snapshot.val() === 0) &&
                  storyReader.current
              ) {
                  storyReader.current.setActivePlayerId(snapshot.val());

                  const type = storyReader.current.getScreenType();

                  if (type === ScreenType.CHANGE) {
                      enableSoloOrSecretMode(snapshot.val(), ONE_USER_MODE.SOLO);
                  } else if (type === ScreenType.SECRET) {
                      enableSoloOrSecretMode(snapshot.val(), ONE_USER_MODE.SECRET);
                  }

                  const randomPlayerText = storyReader.current.getRandomPlayerName(
                      snapshot.val()
                  );
                  setShouldFetchRandom(snapshot.val());
              }
          });
      */
      // Listener for end of the game to leave game / show report
      onIsTheEnd = db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/isTheEnd`)
        .on("value", isTheEndCallback);

      // Listener for hasChangedScene, will set hasChangedSceneRef to true
      // If everybody changed scene. Allow making choices
      onHasChangedScene = db
        .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasChangedScene`)
        .on("value", hasChangedSceneCallback);
    }

    // stop listening for updates when no longer required
    return () => {
      isActive = false;

      if (onHostChange != null && isNotEmptyString(gameID)) {
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/host`).off(
          "value",
          onHostChange
        );
      }

      if (onChoicesChange != null && isNotEmptyString(gameID)) {
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.CHOICES}/${gameID}`).off(
          "value",
          onChoicesChange
        );
      }

      if (onShouldPlayedFeedbackAnimation != null && isNotEmptyString(gameID)) {
        db.ref(
          `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/shouldPlayedFeedbackAnimation`
        ).off("value", onShouldPlayedFeedbackAnimation);
      }

      if (onPlayersWaitingChange != null && isNotEmptyString(gameID)) {
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting`).off(
          "value",
          onPlayersWaitingChange
        );
      }

      if (onSceneChange != null && isNotEmptyString(gameID)) {
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/scene`).off(
          "value",
          onSceneChange
        );
      }

      if (onIsTheEnd != null && isNotEmptyString(gameID)) {
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/isTheEnd`).off(
          "value",
          onIsTheEnd
        );
      }

      if (onHasChangedScene != null && isNotEmptyString(gameID)) {
        db.ref(
          `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasChangedScene`
        ).off("value", onHasChangedScene);
      }
    };
  }, [isReadyRef.current]);

  /**
 * Triggered each time the `countdown` value decrements
 * [specially on a screen with a timer]
 */
  useEffect(() => {
    // condition to avoid trigger multiple times the following code
    if (
      countdownCall.current &&
      isScreenWithTimer(screenType) &&
      storyReader.current &&
      //timerTextRef.current &&
      isReadyRef.current
    ) {
      const currChoices = storyReader.current.getCurrentChoices();
      if (countdown > 0) {
        SoundManager.getInstance().playTimerSound();
      } else if (countdown <= 0) {
        // the current timer is over
        resetTimer();
        // we determine the definitive choice when timer is over
        if (isNotEmptyArray(currChoices)) {
          const getTimerDefault = storyReader.current.getTimerSelect();
          const target = getTimerDefault ? getTimerDefault : 0;
          let defaultChoice = currChoices[target];
          if (isNotEmptyArray(selectedChoicesRef.current)) {
            const finalChoices = countDuplicates(
              selectedChoicesRef.current,
              "text"
            );
            if (isNotEmptyArray(finalChoices)) {
              defaultChoice = getFinalChoice(finalChoices);
            }
          }
          removeUserWaiting(defaultChoice);
        }
      }
    }
  }, [countdown]);

  /**
   * Update notification list when showInfo update
    */
  useEffect(() => {
    if (showInfo && !notificationList.includes(showInfo)) {
      if (!showInfo.remove) {
        addNotificationToList(showInfo);
      }
    } else if (showInfo && notificationList.includes(showInfo)) {
      setNotificationList((prev) => [
        ...prev.filter((entry: any) => entry != showInfo),
      ]);
    }
  }, [showInfo]);

  /**
  * Callback triggered when `switchValueFirst` has changed
  */
  useEffect(() => {
    if (soundManager.current) {
      if (switchValueFirst === false) {
        soundManager.current.muteSfx();
      } else {
        soundManager.current.unmuteSfx();
      }
    }
  }, [switchValueFirst]);

  /**
   * Callback triggered when `switchValueSecond` has changed
   */
  useEffect(() => {
    if (soundManager.current) {
      if (switchValueSecond === false) {
        soundManager.current.muteMusic();
      } else {
        soundManager.current.unmuteMusic();
      }
    }
  }, [switchValueSecond]);

  /**
   * Reset values when showReportScreen true
   */
  useEffect(() => {
    if (showReportScreen) {
      resetValues();
    }
  }, [showReportScreen]);

  /**
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * RENDER FUNCTIONS
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * 
   * */

  /**
  * @drawChoices
  * Returns the choices list (if not secret sequence or SS & not concerned)
   */
  const drawChoices = () => {
    // don't display choices during the secret mode
    // when the current player isn't the selected secret player
    if (hideOnSecretMode()) {
      return null;
    }

    if (
      isNumber(storyID) &&
      storyReader.current &&
      !isTheEndOfTheGameRef.current
    ) {
      const type = storyReader.current.getChoiceType();
      const isLoading =
        isWaitingNext || shouldPlayedFeedbackAnimationRef.current;

      return (
        <Choices
          choices={choices}
          players={storyReader.current.getAvailablePlayers()}
          goNext={handleSelectChoice}
          choicesType={type}
          onSubmitTextbox={(str: string) => onSubmitTextbox(str)}
          storyId={storyID}
          color={getColor("tertiary")}
          storyReader={storyReader.current}
          bgColor={getColor("primary")}
          textColor={getColor("quaternary")}
          choiceColor={getColor("secondary")}
          visitedScenes={visitedScenes}
          toggleBottom={(v: boolean) => setToggleBottom(v)}
          showStatePopUp={false}
          choiceTimerSelect={choiceTimerSelect}
          timerCallback={() =>
            setChoiceTimerSelect(INITIAL_STATE.choiceTimerSelect)
          }
          selectedChoices={selectedChoices}
          toggleOverlay={(v: boolean) => setShowOverlay(v)}
          disabled={isDisabled() || isLoading}
          disabledText={getDisabledText()}
          percent={
            type !== ChoiceTypeEnum.TEXTBOX ? arrowPercentRef.current : null
          }
          loading={isLoading}
          setAskExit={(value: boolean) => onAskExit(value)}
          onEndGame={(choice: IChoice) => handleSelectChoice(choice, -1, -1)}
          overlay={toggleBottom}
          animUrl={getAnimForType("choice")}
          setShowReport={setShowReportScreen}
        />
      );
    }

    return null;
  };

  /**
   * @renderNotificationItem
    */
  const renderNotificationItem = useCallback(
    (item: any, index: any) => (
      <Box key={"notif-" + item.type + "-" + index}>
        {item.type === "state" ? (
          <StatePopUp
            currVar={item.item.currVar}
            player={item.item.player}
            storyId={storyID}
            colorAccent={ColorManager.getInstance().getColor("tertiary")}
            textColor={ColorManager.getInstance().getColor("quaternary")}
            notif={item}
            callback={removeNotificationFromList}
            storyData={storyData}
          />
        ) : item.type === "var" ? (
          <VarPopUp
            currVar={item.item}
            storyId={storyID}
            colorAccent={ColorManager.getInstance().getColor("tertiary")}
            textColor={ColorManager.getInstance().getColor("quaternary")}
            notif={item}
            callback={removeNotificationFromList}
            storyData={storyData}
          />
        ) : item.type === "hint" ? (
          <HintPopup
            colorAccent={ColorManager.getInstance().getColor("tertiary")}
            textColor={ColorManager.getInstance().getColor("quaternary")}
            notif={item}
            callback={removeNotificationFromList}
          />
        ) : item.type === "dice" ? (
          <DiceFeedbackPopup
            colorAccent={ColorManager.getInstance().getColor("tertiary")}
            textColor={ColorManager.getInstance().getColor("quaternary")}
            notif={item}
            callback={removeNotificationFromList}
          />
        ) : item.type === "success" ? (
          <SuccessPopup notif={item} callback={removeNotificationFromList} />
        ) : null}
      </Box>
    ),
    [storyData]
  );

  /**
   * @drawNotificationList
    */
  const drawNotificationList = useCallback(() => {

    return (
      <Box sx={styles.notificationList}>
        {notificationList &&
          storyData &&
          !(showBoardDicePopup &&
            currentChoiceForDicePopup &&
            randomForDicePopup &&
            isPositiveNumber(randomForDicePopup) &&
            isEnabledSoloMode()) &&
          notificationList.map((item: any, index: any) =>
            renderNotificationItem(item, index)
          )}
      </Box>
    );
  }, [notificationList, showBoardDicePopup]);

  /**
     * @_renderWaitingModalContent
     */
  const _renderWaitingModalContent = () => (
    <Box style={styles.modalContent}>
      <Typography sx={styles.modalText}>{strings.messages.waiting}</Typography>
    </Box>
  );

  /**
   * @_renderConfirmLeaveModalContent
   */
  const _renderConfirmLeaveModalContent = () => (
    <Box style={styles.modalContent}>
      <Typography sx={styles.modalText}>
        {isHost()
          ? strings.messages.leavingGameForHost
          : strings.messages.leavingGameForPlayer}
      </Typography>
    </Box>
  );

  /**
   * @_renderBugLoadingModalContent
    */
  const _renderBugLoadingModalContent = () => (
    <Box style={styles.modalContent}>
      <Typography sx={styles.modalText}>
        {strings.messages.bugLoading}
      </Typography>
    </Box>
  );

  /**
   * @_renderGameOverModalContent
    */
  const _renderGameOverModalContent = () => {
    return (
      <Box style={styles.modalContent}>
        <Typography sx={styles.modalText}>
          {strings.messages.gameOver}
        </Typography>
      </Box>
    );
  };

  /**
   * @drawPlayerActive
   * Draws the list of available players' avatars
   */
  const drawPlayerActive = () => {
    if (storyReader.current) {
      const players = storyReader.current.getAvailablePlayers();
      return players.map((player, index) => {
        if (player.avatar) {
          return (
            <AvatarContainer
              key={"avatar" + index}
              user={player}
              persoWidth={40}
              borderWidth={2}
              borderColor={ColorManager.getInstance().getColor("secondary")}
              avatarStyle={
                index > 0 && index != 4
                  ? { marginLeft: "-7px", zIndex: index + 1 }
                  : { zIndex: index + 1 }
              }
            />
          );
        } else {
          return (
            <Box
              key={"letter" + index}
              sx={[
                styles.letter,
                {
                  backgroundColor: player.letterColor,
                  borderColor: ColorManager.getInstance().getColor("secondary"),
                },
                index > 0 && index !== 4
                  ? { marginLeft: "-7px", zIndex: index + 1 }
                  : { zIndex: index + 1 },
              ]}
            >
              <Typography style={styles.playerLetter}>
                {player.letter}
              </Typography>
            </Box>
          );
        }
      });
    }
  };

  /**
   * @drawMJActive
   * Draws the solo / secret player avatar
   */
  const drawMJActive = () => {
    if (storyReader.current) {
      const mj = storyReader.current.getMasterPlayerId();
      const player = storyReader.current.getPlayers().getPlayerById(mj);
      if (player.avatar) {
        return (
          <AvatarContainer
            user={player}
            persoWidth={40}
            borderWidth={2}
            borderColor={ColorManager.getInstance().getColor("secondary")}
          />
        );
      } else {
        return (
          <Box
            sx={[
              styles.letter,
              {
                backgroundColor: player.letterColor,
                borderColor: ColorManager.getInstance().getColor("secondary"),
              },
            ]}
          >
            <Typography style={styles.playerLetter}>{player.letter}</Typography>
          </Box>
        );
      }
    }
  };

  /**
   * @CustomTooltip
   * Draws the CustomTooltip for hover player list
    */
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: getColor("tertiary"),
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: getColor("tertiary"),
      borderRadius: "8px",
      padding: "8px 12px",
    },
  }));


  /**
   * @drawPlayersActiveList
   * Draws the list and call one of the draw fn for avatars
   */
  const drawPlayersActiveList = () => {
    if (
      !secretSequence &&
      (screenType === ScreenType.TIMER ||
        screenType === ScreenType.STANDARD ||
        screenType === ScreenType.NORMAL_IMAGE ||
        screenType === ScreenType.IMAGE_TIMER ||
        screenType === ScreenType.POOLP)
    ) {
      if (showPlayersList) {
        return (
          <CustomTooltip
            title={
              <Typography
                style={{
                  color: getColor("secondary"),
                  fontFamily: "Gilroy-Bold",
                }}
              >
                {showPlayersList
                  ? strings.playersConcerned
                  : strings.playerConcerned}
              </Typography>
            }
            arrow
            placement="bottom"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 400 }}
          >
            <Box
              style={{
                maxWidth: "320px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  maxWidth: "271px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {drawPlayerActive()}
              </Box>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  maxWidth: "325px",
                  height: "100%",
                  maxHeight: "65px",
                  zIndex: 10,
                  backgroundColor:
                    ColorManager.getInstance().getColor("primary"),
                  opacity: 0.3,
                }}
              />
            </Box>
          </CustomTooltip>
        );
      } else {
        return (
          <CustomTooltip
            title={
              <Typography
                style={{
                  color: getColor("secondary"),
                  fontFamily: "Gilroy-Bold",
                }}
              >
                {showPlayersList
                  ? strings.playersConcerned
                  : strings.playerConcerned}
              </Typography>
            }
            arrow
            placement="bottom"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 400 }}
          >
            <Box
              style={{
                maxWidth: "320px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  maxWidth: "320px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {drawMJActive()}
              </Box>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  maxWidth: "325px",
                  height: "100%",
                  maxHeight: "65px",
                  zIndex: 10,
                  backgroundColor:
                    ColorManager.getInstance().getColor("primary"),
                  opacity: 0.3,
                }}
              />
            </Box>
          </CustomTooltip>
        );
      }
    }
  };

  /**
   * @drawSecretIcon
   * Draws the secret icon next to secret player avatar
   */
  const drawSecretIcon = () => {
    if (
      secretSequence &&
      !showPlayersList &&
      (screenType === ScreenType.TIMER ||
        screenType === ScreenType.STANDARD ||
        screenType === ScreenType.NORMAL_IMAGE ||
        screenType === ScreenType.IMAGE_TIMER ||
        screenType === ScreenType.POOLP)
    ) {
      return (
        <CustomTooltip
          title={
            <Typography
              style={{
                color: getColor("secondary"),
                fontFamily: "Gilroy-Bold",
              }}
            >
              {strings.playerConcerned}
            </Typography>
          }
          arrow
          placement="bottom"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 400 }}
        >
          <Box
            style={{
              maxWidth: "320px",
              position: "relative",
            }}
          >
            <Box
              style={{
                maxWidth: "271px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              {drawMJActive()}
              <SecretIcon
                width={38}
                height={38}
                fill={ColorManager.getInstance().getColor("quaternary")}
              />
            </Box>
            <Box
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                maxWidth: "325px",
                height: "100%",
                maxHeight: "65px",
                zIndex: 10,
                backgroundColor: ColorManager.getInstance().getColor("primary"),
                opacity: 0.3,
              }}
            />
          </Box>
        </CustomTooltip>
      );
    }
  };

  /**
   * @drawBackToGroupPopup
   * Draws the back to group popup for tooltip
   */
  const drawBackToGroupPopup = () => {
    if (storyReader.current && showPopUpBackToGroup) {
      return (
        <Fade in={showPopUpBackToGroup}>
          <Box
            sx={[
              styles.popBg,
              {
                backgroundColor: getColor("tertiary"),
                alignItems: "center",
                zIndex: 300,
              },
            ]}
          >
            <Typography
              style={{
                color: getColor("secondary"),
                fontFamily: "Gilroy-Bold",
              }}
            >
              {strings.backToGroup}
            </Typography>
          </Box>
        </Fade>
      );
    }
  };

  /**
     * @_renderBoardDicePopup
     * Draws the dice popup
     */
  const _renderBoardDicePopup = () => {
    if (
      showBoardDicePopup &&
      currentChoiceForDicePopup &&
      randomForDicePopup &&
      isPositiveNumber(randomForDicePopup) &&
      !hideOnSecretMode()
    ) {
      if (!showPlayersList) {
        return (
          <DicePopup
            choice={currentChoiceForDicePopup}
            random={randomForDicePopup}
            callback={() => {
              showBoardDicePopupCallback(false, null, null);
            }}
            onReboot={comesFromLocalStorageRef.current}
            isSoloPlayer={isSoloPlayer()}
            storyReader={storyReader.current}
            gameID={gameID}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };


  /**
       * 
       * 
       * 
       * 
       * 
       * 
       * 
       * RENDER
       * 
       * 
       * 
       * 
       * 
       * 
       * 
       * 
       * */



  // --
  if (!isReady || !storyReader.current || !isNumber(storyID)) {
    return <LoadingView />;
  }

  return (
    <Box ref={containerRef} sx={styles.container}>
      <Fade in={isImageChanged} timeout={{ appear: 0, enter: 1000, exit: 400 }}>
        <Box
          sx={[
            { position: "absolute", width: "100%", height: "100%" },
            {
              backgroundColor: !showReportScreen
                ? getColor("quinary")
                : Colors.PRIMARY,
              background: `  linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), center / cover no-repeat url(${background
                ? background
                : (storyID && storyID === "92") || storyID === 92
                  ? require("../../assets/images/dragons.jpeg")
                  : (storyID && storyID === "41") || storyID === 41
                    ? require("../../assets/images/BG25.jpeg")
                    : (storyID && storyID === "44") || storyID === 44
                      ? require("../../assets/images/BGViking.jpeg")
                      : (storyID && storyID === "51") || storyID === 51
                        ? require("../../assets/images/BG5.jpg")
                        : (storyID && storyID === "59") || storyID === 59
                          ? require("../../assets/images/BG27.jpeg")
                          : (storyID && storyID === "67") || storyID === 67
                            ? require("../../assets/images/BG8.jpg")
                            : (storyID && storyID === "72") || storyID === 72
                              ? require("../../assets/images/BG22.jpg")
                              : (storyID && storyID === "83") || storyID === 83
                                ? require("../../assets/images/BG11.jpg")
                                : (storyID && storyID === "87") || storyID === 87
                                  ? require("../../assets/images/BGFantome.jpeg")
                                  : (storyID && storyID === "99") || storyID === 99
                                    ? require("../../assets/images/BG15.jpg")
                                    : (storyID && storyID === "102") || storyID === 102
                                      ? require("../../assets/images/BG26.jpeg")
                                      : (storyID && storyID === "56") || storyID === 56
                                        ? require("../../assets/images/BGPirate.jpeg")
                                        : (storyID && storyID === "57") || storyID === 57
                                          ? require("../../assets/images/BGPirate.jpeg")
                                          : (storyID && storyID === "66") || storyID === 66
                                            ? require("../../assets/images/BGPirate.jpeg")
                                            : (storyID && storyID === "61") || storyID === 61
                                              ? require("../../assets/images/BG6.jpg")
                                              : (storyID && storyID === "62") || storyID === 62
                                                ? require("../../assets/images/BG6.jpg")
                                                : (storyID && storyID === "63") || storyID === 63
                                                  ? require("../../assets/images/BG6.jpg")
                                                  : (storyID && storyID === "64") || storyID === 64
                                                    ? require("../../assets/images/BG6.jpg")
                                                    : (storyID && storyID === "68") || storyID === 68
                                                      ? require("../../assets/images/BG7.jpg")
                                                      : (storyID && storyID === "69") || storyID === 69
                                                        ? require("../../assets/images/BG7.jpg")
                                                        : (storyID && storyID === "70") || storyID === 70
                                                          ? require("../../assets/images/BG7.jpg")
                                                          : (storyID && storyID === "71") || storyID === 71
                                                            ? require("../../assets/images/BG7.jpg")
                                                            : (storyID && storyID === "75") || storyID === 75
                                                              ? require("../../assets/images/BG21.jpg")
                                                              : (storyID && storyID === "76") || storyID === 76
                                                                ? require("../../assets/images/BG21.jpg")
                                                                : (storyID && storyID === "77") || storyID === 77
                                                                  ? require("../../assets/images/BG21.jpg")
                                                                  : (storyID && storyID === "78") || storyID === 78
                                                                    ? require("../../assets/images/BG10.jpg")
                                                                    : (storyID && storyID === "79") || storyID === 79
                                                                      ? require("../../assets/images/BG10.jpg")
                                                                      : (storyID && storyID === "80") || storyID === 80
                                                                        ? require("../../assets/images/BG10.jpg")
                                                                        : (storyID && storyID === "81") || storyID === 81
                                                                          ? require("../../assets/images/BG10.jpg")
                                                                          : (storyID && storyID === "88") || storyID === 88
                                                                            ? require("../../assets/images/BG12.jpg")
                                                                            : (storyID && storyID === "89") || storyID === 89
                                                                              ? require("../../assets/images/BG12.jpg")
                                                                              : (storyID && storyID === "90") || storyID === 90
                                                                                ? require("../../assets/images/BG12.jpg")
                                                                                : (storyID && storyID === "91") || storyID === 91
                                                                                  ? require("../../assets/images/BG12.jpg")
                                                                                  : (storyID && storyID === "95") || storyID === 95
                                                                                    ? require("../../assets/images/BG13.jpg")
                                                                                    : (storyID && storyID === "96") || storyID === 96
                                                                                      ? require("../../assets/images/BG13.jpg")
                                                                                      : (storyID && storyID === "97") || storyID === 97
                                                                                        ? require("../../assets/images/BG13.jpg")
                                                                                        : (storyID && storyID === "98") || storyID === 98
                                                                                          ? require("../../assets/images/BG13.jpg")
                                                                                          : (storyID && storyID === "101") || storyID === 101
                                                                                            ? require("../../assets/images/BG101.jpeg")
                                                                                            : (storyID && storyID === "103") || storyID === 103
                                                                                              ? require("../../assets/images/BG1.jpg")
                                                                                              : (storyID && storyID === "104") || storyID === 104
                                                                                                ? require("../../assets/images/BG2.jpg")
                                                                                                : (storyID && storyID === "105") || storyID === 105
                                                                                                  ? require("../../assets/images/BG3.jpg")
                                                                                                  : (storyID && storyID === "106") || storyID === 106
                                                                                                    ? require("../../assets/images/BG19.jpg")
                                                                                                    : (storyID && storyID === "107") || storyID === 107
                                                                                                      ? require("../../assets/images/BG17.jpg")
                                                                                                      : cover
                                                                                                        ? cover
                                                                                                        : require("../../assets/images/BG7.jpeg")
                })`,
            },
          ]}
        />
      </Fade>
      {/* TOP MENU START */}
      {!showReportScreen ? (
        <ButtonBase
          onClick={() => openTopMenu()}
          style={{
            alignItems: "center",
            width: "48px",
            position: "absolute",
            top: "30px",
            left: "35px",
            borderRadius: "48px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex: 300,
          }}
        >
          <OptionsBtn width={48} height={48} fill={getColor("secondary")} />
        </ButtonBase>
      ) : null}
      {storyReader.current && !showReportScreen ? (
        <Slide
          direction="right"
          in={topMenuIsOpen}
          mountOnEnter
          unmountOnExit
          timeout={400}
          container={containerRef.current}
        >
          <Box
            sx={[
              {
                backgroundColor: ColorManager.getInstance().getColor("primary"),
                /* border: `solid 5px ${ColorManager.getInstance().hexToRgba(
                  ColorManager.getInstance().getColor("quaternary"),
                  "0.3"
                )}`, */
              },
              styles.topMenuContainer,
            ]}
          >
            <TopMenu
              onClose={() => setTopMenuIsOpen(INITIAL_STATE.topMenuIsOpen)}
              players={storyReader.current.getPlayers().players}
              soundManager={SoundManager.getInstance()}
              navigate={navigate}
              currentObjective={storyReader.current.getCurrentObjective()}
              switchValueFirst={switchValueFirst}
              switchValueSecond={switchValueSecond}
              toggleSound={(switchValue: boolean) =>
                setSwitchValueFirst(switchValue)
              }
              toggleMusic={(switchValue: boolean) =>
                setSwitchValueSecond(switchValue)
              }
              colorBg={getColor("primary")}
              textColor={getColor("quaternary")}
              accentColor={getColor("tertiary")}
              secondaryColor={getColor("secondary")}
              storyId={storyID}
              setShowPopupExit={setShowPopUpExit}
              storyReader={storyReader.current}
              storyData={storyData}
              informations={storyReader.current.getInformations()}
              varToShow={createImgs()}
              gameID={gameID}
              isOnlineMode={true}
              disabledLogout={disableLeaveGameButton}
              onLogoutOnlineGame={() => setShowPopUpExit(true)}
              jsonVan={jsonVan}
            />
          </Box>
        </Slide>
      ) : null}
      {/* TOP MENU END */}
      {/* CHAT */}
      {storyReader.current &&
        !showReportScreen &&
        gameID &&
        playerID &&
        storyReader.current.getPlayers().getPlayerByPlayerCode(playerID) ? (
        <GameChat
          gameID={gameID}
          player={storyReader.current
            .getPlayers()
            .getPlayerByPlayerCode(playerID)}
          backgroundUrl={getAnimForType("background")}
          containerRef={containerRef.current}
        />
      ) : null}
      {/* NOTIFS */}
      {storyReader.current && !showReportScreen ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            ...columnCenterStyles,
            zIndex: 900,
          }}
        >
          {notificationList && drawNotificationList()}
        </Box>
      ) : null}
      {/* GAME */}
      {storyReader.current && !showReportScreen ? (
        <Box
          sx={[
            styles.gameContainer,
            {
              backgroundColor: ColorManager.getInstance().getColor("primary"),
              //background: 'rgba( 0, 0, 0, 0.4 )',

              /* border: `solid 5px ${ColorManager.getInstance().hexToRgba(
                ColorManager.getInstance().getColor("quaternary"),
                "0.3"
              )}`, */
            },
            screenType === ScreenType.FULLSCREEN_IMAGE
              ? {
                width: "47vh",
                maxWidth: "650px",
                minWidth: "250px",
              }
              : {},
          ]}
        >
          {showBoardDicePopup && !showPlayersList ? (
            <>
              {_renderBoardDicePopup()}
            </>
          ) : (
            <>
              <Box sx={styles.playersList}>{drawPlayersActiveList()}</Box>
              <Box sx={styles.playersList}>{drawSecretIcon()}</Box>
              <Box sx={styles.playersList}>{drawBackToGroupPopup()}</Box>
              {screenType === ScreenType.STANDARD && (
                <StandardScreen
                  text={text}
                  drawChoices={drawChoices}
                  players={storyReader.current.getPlayers().players}
                  color={getColor("tertiary")}
                  hlText={hlText}
                  textColor={getColor("quaternary")}
                  overlay={toggleBottom}
                  showOverlay={showOverlay}
                  storyReader={storyReader.current}
                  choices={choices}
                  isVisibleText={!hideOnSecretMode()}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                />
              )}
              {screenType === ScreenType.NORMAL_IMAGE && (
                <ImageScreen
                  players={storyReader.current.getPlayers().players}
                  text={text}
                  drawChoices={drawChoices}
                  imageId={storyReader.current.GetImageId()}
                  color={getColor("tertiary")}
                  hlText={hlText}
                  storyId={storyID}
                  textColor={getColor("quaternary")}
                  passageNumber={passageNumber}
                  currPassageId={currPassageIdRef.current}
                  overlay={toggleBottom}
                  storyReader={storyReader.current}
                  showOverlay={showOverlay}
                  isVisibleText={!hideOnSecretMode()}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                />
              )}
              {screenType === ScreenType.POOLP && (
                <PoolpScreen
                  text={text}
                  onSkipImage={onSkipImage}
                  players={storyReader.current.getPlayers().players}
                  storyId={storyID}
                  color={getColor("tertiary")}
                  hlText={hlText}
                  textColor={getColor("quaternary")}
                  secondaryColor={getColor("secondary")}
                  showStatePopUp={false}
                  overlay={toggleBottom}
                  storyReader={storyReader.current}
                  disabled={isDisabled()}
                  disabledText={getDisabledText()}
                  percent={arrowPercentRef.current}
                  loading={isWaitingNext}
                  isVisibleText={!hideOnSecretMode()}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                  animUrl={getAnimForType("background")}
                />
              )}
              {screenType === ScreenType.FULLSCREEN_IMAGE && (
                <FullScreenImageScreen
                  onSkipImage={onSkipImage}
                  imageId={storyReader.current.GetImageId()}
                  choices={choices}
                  passageNumber={passageNumber}
                  storyId={storyID}
                  color={getColor("tertiary")}
                  storyReader={storyReader.current}
                  currPassageId={currPassageId}
                  secondaryColor={getColor("secondary")}
                  textColor={getColor("quaternary")}
                  showStatePopUp={false}
                  disabled={isDisabled()}
                  disabledText={getDisabledText()}
                  percent={arrowPercentRef.current}
                  loading={isWaitingNext}
                  onEndGame={(choice: any) => onSkipImage(choice)}
                  text={text}
                  setShowReport={setShowReportScreen}
                />
              )}
              {screenType === ScreenType.CHANGE && (
                <ChangeScreen
                  text={text}
                  onSkipImage={() => onSkipImage()}
                  players={storyReader.current.getPlayers().players}
                  storyId={storyID}
                  color={getColor("tertiary")}
                  hlText={hlText}
                  textColor={getColor("quaternary")}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                  showStatePopUp={false}
                  overlay={toggleBottom}
                  storyReader={storyReader.current}
                  disabled={isDisabled()}
                  disabledText={getDisabledText()}
                  percent={arrowPercentRef.current}
                  loading={isWaitingNext}
                  isVisibleText={!hideOnSecretMode()}
                />
              )}
              {screenType === ScreenType.SECRET && (
                <SecretScreen
                  text={text}
                  onSkipImage={() => onSkipImage()}
                  players={storyReader.current.getPlayers().players}
                  storyId={storyID}
                  color={getColor("tertiary")}
                  hlText={hlText}
                  secondaryColor={getColor("secondary")}
                  textColor={getColor("quaternary")}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                  showStatePopUp={false}
                  overlay={toggleBottom}
                  storyReader={storyReader.current}
                  disabled={!showContinueButton}
                  disabledText={getDisabledText()}
                  percent={arrowPercentRef.current}
                  loading={isWaitingNext}
                  isVisibleText={!hideOnSecretMode()}
                />
              )}
              {screenType === ScreenType.TIMER && (
                <TimerScreen
                  players={storyReader.current.getPlayers().players}
                  countdown={countdown}
                  timerRef={timerRef}
                  timerTextRef={timerTextRef}
                  text={text}
                  drawChoices={drawChoices}
                  color={getColor("tertiary")}
                  textColor={getColor("quaternary")}
                  hlText={hlText}
                  overlay={toggleBottom}
                  storyReader={storyReader.current}
                  choicesLength={choices.length}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                  max={storyReader.current.getTimerValue()}
                  isVisibleText={!hideOnSecretMode()}
                />
              )}
              {screenType === ScreenType.IMAGE_TIMER && (
                <TimerImageScreen
                  players={storyReader.current.getPlayers().players}
                  countdown={countdown}
                  timerRef={timerRef}
                  timerTextRef={timerTextRef}
                  text={text}
                  drawChoices={drawChoices}
                  color={getColor("tertiary")}
                  textColor={getColor("quaternary")}
                  hlText={hlText}
                  imageId={storyReader.current.GetImageId()}
                  storyId={storyID}
                  passageNumber={passageNumber}
                  currPassageId={currPassageIdRef.current}
                  overlay={toggleBottom}
                  storyReader={storyReader.current}
                  isVisibleText={!hideOnSecretMode()}
                  masterPlayer={storyReader.current.getMasterPlayerId()}
                  max={storyReader.current.getTimerValue()}
                />
              )}
            </>
          )}

        </Box>
      ) : null}
      {/* MODALS */}
      {!isVisibleVolumeModal || !userDoesNotComeFromReboot() ? (
        <>
          <ExitGameModal
            onClose={() => {
              setAskExit(true);
              setShowPopUpExit(INITIAL_STATE.showPopUpExit);
            }}
            onConfirm={() => handleLeaveGame()}
            visible={showPopUpExit}
          />
          <ClassicModal
            title={strings.actions.sure}
            modalVisible={showConfirmLeaveModal}
            twoButtons={true}
            buttonText={strings.actions.leave}
            onCancel={() =>
              setShowConfirmLeaveModal(INITIAL_STATE.showConfirmLeaveModal)
            }
            onCloseModal={() => handleLeaveGame()}
            minHeight={SCREEN_HEIGHT / 5}
            titleHeight={SCREEN_HEIGHT / 26}
            content={_renderConfirmLeaveModalContent()}
          />

          <ClassicModal
            title={strings.labels.gameOver}
            modalVisible={showPopUpGameOver}
            twoButtons={false}
            buttonText={strings.actions.leave}
            onCloseModal={() => handleLeaveGame()}
            minHeight={SCREEN_HEIGHT / 5}
            titleHeight={SCREEN_HEIGHT / 26}
            content={_renderGameOverModalContent()}
          />

          <ClassicModal
            title={strings.labels.waiting}
            modalVisible={showWaitingModal}
            buttonText={strings.actions.gotIt}
            onCloseModal={() => {
              //reCheckIfHasReachedWaitingPlayerSize();
              setShowWaitingModal(INITIAL_STATE.showWaitingModal);
            }}
            minHeight={SCREEN_HEIGHT / 5}
            content={_renderWaitingModalContent()}
          />
        </>
      ) : userDoesNotComeFromReboot() ? (
        <GameVolumeModal
          onClose={() => setIsVisibleVolumeModal(false)}
          visible={isVisibleVolumeModal}
          isOnline={true}
        />
      ) : null}
      {/* REPORT */}
      {showReportScreen && storyReader.current && storyData ? (
        <ReportScreen storyData={storyData} storyReader={storyReader.current} />
      ) : null}
    </Box>
  );


};



export default GameOnline;