/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Component, useCallback } from "react";

import ColorManager from "../../../services/colorManager";
import Highlighter from "react-highlight-words";
import OptionBtn from "./optionBtn";

import { ReactComponent as OptionsIcon } from "../../../assets/icons/icon_settings.svg";
import { ReactComponent as GoBackIcon } from "../../../assets/icons/icon_go_back.svg";
import { ReactComponent as LogbookIcon } from "../../../assets/icons/icon_logbook.svg";
import { ReactComponent as ObjectiveIcon } from "../../../assets/icons/icon_objective.svg";
import { ReactComponent as QuitIcon } from "../../../assets/icons/icon_exitstory.svg";
import { ReactComponent as QuitPopupIcon } from "../../../assets/icons/icon_quit_story.svg";
import { ReactComponent as HintIcon } from "../../../assets/icons/icon_hint.svg";

import OptionsScreen from "./optionsScreen";
import PlayerStates from "./playerStates";
import styles from "./styles";
import { isBoolean } from "lodash";

import { isFunction } from "../../../utils/TypeOfHelper";
import { isNotEmptyString } from "../../../utils/StringHelper";

import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import StoryReader from "../../../services/StoryReader";
import { Information } from "../../../services/StoryReader/information";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { columnCenterStyles, columnStartStyles } from "../../../style/flex";
import "./style.css";
import InfoBox from "../../../components/InfoBox";
import InfoBoxObject from "../../../components/InfoBoxObject";
import SoundManager from "../../../services/SoundManager";
import { getCacheManager } from "../../../services/CacheManager";
import Animation from "../../../components/Animation";

// =======================================================

interface Props {
    onClose: any;
    players: Array<any>;
    soundManager: SoundManager;
    navigate: any;
    storyData: any;
    currentObjective: any;
    switchValueFirst: boolean;
    switchValueSecond: boolean;
    toggleSound: any;
    toggleMusic: any;
    colorBg: any;
    textColor: any;
    accentColor: any;
    secondaryColor: any;
    storyId: any;
    isOnlineMode?: boolean;
    onLogoutOnlineGame?: any;
    disabledLogout?: boolean;
    setShowPopupExit?: any;
    gameID?: string;
    storyReader?: StoryReader;
    informations: Array<Information> | null;
    varToShow: Array<any> | null;
    jsonVan?: any;
    isSolo?: boolean;
    isFTUE?: boolean;
}

interface State {
    first: boolean;
    second: boolean;
    showNames: Array<any>;
    ready: boolean;
    adjustHeight: any;
    enabled: boolean;
    //scaleClose: any;
    switchValue: boolean;
    showPopup: boolean;
}

// =======================================================

export const fadeIn = {
    from: {
        opacity: 0,
    },
    to: {
        opacity: 0.8,
    },
};

// =======================================================

class TopMenu extends Component<Props, State> {
    private myComponent: any;

    private AnimationRef: any;

    public iconsData: any;

    /*  private AnimationRefBackground; */

    /**
     *
     */
    constructor(props: Props) {
        super(props);

        this.state = {
            first: true,
            second: false,
            showNames: [],
            ready: false,
            adjustHeight: 0,
            enabled: true,
            //scaleClose: new RNAnimated.Value(1),
            switchValue: false,
            showPopup: false,
        };

        this.myComponent = React.createRef();
    }

    /**
     *
     */
    componentDidMount = () => {
        for (let i = 0; i < this.props.players.length; i += 1) {
            this.setState((prevState) => ({
                showNames: [...prevState.showNames, false],
            }));
        }
        //this.createImgs();
        //this.getInformations();

        this.setState({ ready: true });

        /* setTimeout(() => {
      this.myComponent.current.measure((x, y, width, height, pagex, pagey) => {
        this.setState({ adjustHeight: pagey });
      });
    }, 0); */
    };

    /**
     *
     */
    /* handleScroll = (event: any) => {
    if (event.nativeEvent.contentOffset.x > width / 2) {
      this.setState({
        first: false,
        second: true,
      });
    } else {
      this.setState({
        first: true,
        second: false,
      });
    }
  }; */

    /**
     *
     */
    goToOptions = (value: boolean) => {
        this.setState({
            second: value,
        });
        SoundManager.getInstance().playMenuInteraction();
    };

    /**
     *
     */
    toggleSound = (switchValue: boolean) => {
        const { soundManager } = this.props;

        if (switchValue === true) {
            soundManager.muteSfx();
        } else {
            soundManager.unmuteSfx();
        }
    };

    /**
     *
     */
    toggleMusic = (switchValue: boolean) => {
        const { soundManager } = this.props;

        if (switchValue === true) {
            soundManager.muteMusic();
        } else {
            soundManager.unmuteMusic();
        }
    };

    /**
     *
     */
    onCloseMenu = () => {
        const { onClose } = this.props;

        onClose();
        SoundManager.getInstance().playTopMenuCloseSound();
    };

    /**
     *
     */
    getHlText = () => {
        const { currentObjective } = this.props;

        const hl = currentObjective.match(/\$\$(.*?)\$\$/g);

        const arrToReturn = [];

        if (hl !== null) {
            for (let i = 0; i < hl.length; i += 1) {
                hl[i] = hl[i].replace("$$", "");
                hl[i] = hl[i].replace("$$", "");

                arrToReturn.push(hl[i]);
            }

            return arrToReturn;
        }

        return [];
    };

    /**
     *
     */
    getClearedText = () => {
        const { currentObjective } = this.props;

        let txtCopy = currentObjective;

        const n = this.getHlText();

        for (let i = 0; i < n.length; i += 1) {
            txtCopy = txtCopy.replace("$$", "");
            txtCopy = txtCopy.replace("$$", "");
        }

        return txtCopy;
    };

    /**
     *
     */
    setShowName = (value: boolean, index: any) => {
        const copy = [...this.state.showNames];

        let goodOne = { ...copy[index] };

        goodOne = value;

        copy[index] = goodOne;

        this.setState({ showNames: copy });

        setTimeout(() => this.hideAll(), 3000);
    };

    /**
     *
     */
    hideAll = () => {
        const copy = [...this.state.showNames];
        const www = copy.map(() => false);
        this.setState({ showNames: www });
    };

    /**
     *
     */
    onPress = () => {
        /* this.AnimationRef.slideOutUp().then(() => {
      this.onCloseMenu();
    }); */
        this.onCloseMenu();
        /* this.AnimationRefBackground.animate({
      from: {
        opacity: 0.8,
      },
      to: {
        opacity: 0,
      },
    }); */
    };

    /**
     * Shortcut to print the game ID [only during an online mode]
     */
    _renderOnlineGameCode = (code?: any) => {
        if (isNotEmptyString(code)) {
            return (
                <Box sx={[styles.onlineGameCodeContainer]}>
                    <Typography
                        sx={[
                            styles.onlineGameCodeTextWrapper,
                            {
                                color: this.props.textColor,
                                borderColor: this.props.accentColor,
                            },
                        ]}>
                        {`${strings.labels.gameID} :`}

                        <Typography
                            component={"span"}
                            sx={[
                                styles.onlineGameCodeText,
                                { color: this.props.accentColor },
                            ]}>
                            {` ${code}`}
                        </Typography>
                    </Typography>
                </Box>
            );
        }

        return null;
    };

    /**
     *
     */
    checkIfIsOnlineMode = (): boolean => {
        const { isOnlineMode = false, onLogoutOnlineGame = null } = this.props;
        return (
            isBoolean(isOnlineMode) &&
            isOnlineMode &&
            isFunction(onLogoutOnlineGame)
        );
    };

    /**
     *
     */
    backToMenu = () => {
        const { navigate, onLogoutOnlineGame, setShowPopupExit } = this.props;

        this.setShowPopup(false);

        if (this.checkIfIsOnlineMode() && onLogoutOnlineGame != undefined) {
            SoundManager.getInstance().removeAllSounds();
            onLogoutOnlineGame();
        } else {
            if (!setShowPopupExit) {
                setShowPopupExit(true);
            } else {
                SoundManager.getInstance().removeAllSounds();
                /* navigate.replace('StoryCarousel', {
                      screen: 'Library',
                    }); */
                getCacheManager().deleteCurrentGame();
                navigate("/", { replace: true });
            }
        }

        SoundManager.getInstance().playMenuInteraction();
    };

    /**
     *
     */
    setShowPopup = (value: boolean) => {
        const { onLogoutOnlineGame, setShowPopupExit } = this.props;

        if (this.checkIfIsOnlineMode() && onLogoutOnlineGame != undefined) {
            onLogoutOnlineGame();
        } else {
            if (!setShowPopupExit) {
                setShowPopupExit(true);
            } else {
                this.setState({ showPopup: value });
            }
        }
    };

    renderPlayers = () => {
        const { players, accentColor } = this.props;
        if (players.length) {
            return players.map((el, index) => (
                <Box
                    key={"player-view" + index}
                    sx={[
                        styles.singleChild,
                        {
                            backgroundColor:
                                ColorManager.getInstance().getColor("quinary"),
                        },
                    ]}>
                    <PlayerStates
                        key={"player-state" + index}
                        player={el}
                        color={accentColor}
                        storyId={this.props.storyId}
                        storyData={this.props.storyData}
                        iconColor={ColorManager.getInstance().getColor(
                            "quaternary",
                        )}
                        pointColor={ColorManager.getInstance().getColor(
                            "secondary",
                        )}
                        setShowName={this.setShowName}
                        showName={this.state.showNames[index]}
                        idx={index}
                    />
                </Box>
            ));
        } else {
            return null;
        }
    };
    // --
    render() {
        const {
            players,
            switchValueFirst,
            switchValueSecond,
            toggleMusic,
            toggleSound,
            colorBg,
            accentColor,
            textColor,
            secondaryColor,
            isOnlineMode,
            onLogoutOnlineGame,
            disabledLogout,
            setShowPopupExit,
            gameID = null,
            informations,
            varToShow,
            jsonVan = null,
            isSolo,
            isFTUE,
            storyReader,
        } = this.props;

        const { second } = this.state;

        return (
            <>
                <Box
                    /* bgcolor={"red"} */
                    ref={(ref) => (this.AnimationRef = ref)}
                    sx={[styles.container]}>
                    <Box sx={[styles.menuBtns]}>
                        <OptionBtn
                            icon={
                                <GoBackIcon
                                    width={48}
                                    height={48}
                                    fill={ColorManager.getInstance().getColor(
                                        "quinary",
                                    )}
                                />
                            }
                            style={{ borderRadius: "25px" }}
                            onPress={() => this.onPress()}
                        />
                        <Box style={styles.logbook}>
                            <LogbookIcon
                                width={25}
                                height={25}
                                fill={ColorManager.getInstance().getColor(
                                    "tertiary",
                                )}
                            />
                            <Typography
                                sx={[
                                    styles.logbookText,
                                    {
                                        color: ColorManager.getInstance().getColor(
                                            "tertiary",
                                        ),
                                    },
                                ]}>
                                {strings.topMenu.logbook}
                            </Typography>
                        </Box>
                        <OptionBtn
                            icon={
                                <OptionsIcon
                                    width={52}
                                    height={52}
                                    fill={ColorManager.getInstance().getColor(
                                        "quinary",
                                    )}
                                />
                            }
                            style={{ borderRadius: "25px" }}
                            onPress={() => this.goToOptions(!second)}
                        />
                    </Box>

                    <Box
                        className={"mainScroll"}
                        sx={[
                            styles.mainScroll,
                            {
                                scrollbarWidth: "0px",
                                "::-webkit-scrollbar": {
                                    display: "none",
                                },
                            },
                        ]}>
                        <Box
                            sx={[
                                styles.separator,
                                {
                                    backgroundColor:
                                        ColorManager.getInstance().getColor(
                                            "quinary",
                                        ),
                                },
                            ]}
                        />
                        {jsonVan ? (
                            <Box
                                style={{
                                    width: "100%",
                                }}>
                                <Animation src={jsonVan} />
                            </Box>
                        ) : null}
                        {isNotEmptyString(this.getClearedText()) ? (
                            <Box sx={styles.obectiveContainer}>
                                <Box sx={styles.objective}>
                                    <Typography
                                        sx={[
                                            styles.acteTxt,
                                            { color: accentColor },
                                        ]}>
                                        {strings.objective}
                                    </Typography>

                                    <Box
                                        sx={[
                                            styles.objectiveBox,
                                            {
                                                backgroundColor:
                                                    ColorManager.getInstance().getColor(
                                                        "quinary",
                                                    ),
                                            },
                                        ]}>
                                        <ObjectiveIcon
                                            width={48}
                                            height={48}
                                            fill={ColorManager.getInstance().getColor(
                                                "tertiary",
                                            )}
                                            fillSecondary={ColorManager.getInstance().getColor(
                                                "primary",
                                            )}
                                        />
                                        <Typography
                                            sx={[
                                                styles.infoTxt,
                                                { color: textColor },
                                            ]}>
                                            <Highlighter
                                                highlightStyle={{
                                                    fontFamily: "Gilroy-Bold",
                                                    color: accentColor,
                                                    backgroundColor:
                                                        "transparent",
                                                }}
                                                searchWords={this.getHlText()}
                                                textToHighlight={this.getClearedText()}
                                            />
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={[
                                        styles.separator,
                                        {
                                            backgroundColor:
                                                ColorManager.getInstance().getColor(
                                                    "quinary",
                                                ),
                                        },
                                    ]}
                                />
                            </Box>
                        ) : null}
                        {!isSolo || isFTUE ? (
                            <Box sx={[styles.scrollContainer]}>
                                <Box
                                    sx={styles.insideScroll}
                                    ref={this.myComponent}>
                                    {this.state.ready && this.renderPlayers()}
                                </Box>
                            </Box>
                        ) : null}
                        {varToShow && varToShow.length > 0 ? (
                            <Box sx={styles.obectiveContainer}>
                                <Box sx={styles.objective}>
                                    <Typography
                                        sx={[
                                            styles.acteTxt,
                                            { color: accentColor },
                                        ]}>
                                        {isSolo && !isFTUE
                                            ? storyReader?.getPlayers()
                                                  .players[0]?.name
                                            : strings.topMenu.groupObject}
                                    </Typography>
                                    {varToShow.map(
                                        (object: any, index: any) => (
                                            <InfoBoxObject
                                                key={index}
                                                text={object.name}
                                                icon={object.component}
                                                number={
                                                    object.value
                                                        ? object.value
                                                        : null
                                                }
                                            />
                                        ),
                                    )}
                                </Box>
                            </Box>
                        ) : null}

                        {informations && informations.length > 0 ? (
                            <Box sx={styles.obectiveContainer}>
                                <Box sx={styles.objective}>
                                    <Typography
                                        sx={[
                                            styles.acteTxt,
                                            { color: accentColor },
                                        ]}>
                                        {strings.information}
                                    </Typography>

                                    {informations.map(
                                        (
                                            hint: any,
                                            index: number | undefined,
                                        ) => (
                                            <InfoBox
                                                key={index}
                                                text={hint.getText()}
                                                icon={
                                                    <HintIcon
                                                        width={48}
                                                        height={48}
                                                        fill={ColorManager.getInstance().getColor(
                                                            "primary",
                                                        )}
                                                        fillSecondary={ColorManager.getInstance().getColor(
                                                            "tertiary",
                                                        )}
                                                    />
                                                }
                                                type={hint.getType()}
                                            />
                                        ),
                                    )}
                                </Box>
                            </Box>
                        ) : null}
                        {this._renderOnlineGameCode(gameID)}
                        {/* <Box sx={{ height: "100px" }} /> */}
                    </Box>
                </Box>

                <Modal
                    open={second}
                    onClose={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        this.goToOptions(false);
                    }}>
                    <Box style={styles.centeredView}>
                        <Box
                            sx={[
                                styles.modalView,
                                {
                                    backgroundColor:
                                        ColorManager.getInstance().getColor(
                                            "primary",
                                        ),
                                },
                            ]}>
                            <OptionsScreen
                                //soundManager={soundManager}
                                switchCallback={toggleSound}
                                toggleMusic={toggleMusic}
                                switchValueFirst={switchValueFirst}
                                switchValueSecond={switchValueSecond}
                                navigate={this.props.navigate}
                                color={accentColor}
                                textColor={ColorManager.getInstance().getColor(
                                    "quaternary",
                                )}
                                backColor={colorBg}
                                btnColor={secondaryColor}
                                isOnlineMode={
                                    isBoolean(isOnlineMode)
                                        ? isOnlineMode
                                        : false
                                }
                                onLogoutOnlineGame={
                                    isFunction(onLogoutOnlineGame) &&
                                    onLogoutOnlineGame != undefined
                                        ? () => onLogoutOnlineGame()
                                        : () => null
                                }
                                disabledLogout={
                                    isBoolean(disabledLogout)
                                        ? disabledLogout
                                        : false
                                }
                                setShowPopupExit={setShowPopupExit}
                            />
                            <Box
                                sx={[
                                    styles.closeBtn,
                                    /* {
                    backgroundColor:
                      ColorManager.getInstance().getColor("primary"),
                  }, */
                                ]}>
                                <Box
                                    sx={[
                                        styles.separator,
                                        {
                                            backgroundColor:
                                                ColorManager.getInstance().getColor(
                                                    "quinary",
                                                ),
                                        },
                                    ]}
                                />
                                <ButtonBase
                                    style={{
                                        width: "100%",
                                        ...columnCenterStyles,
                                        padding: "10px 0px",
                                    }}
                                    onClick={() => {
                                        SoundManager.getInstance().playOpenModalSound();
                                        this.setShowPopup(true);
                                    }}
                                    disabled={
                                        isBoolean(disabledLogout) &&
                                        disabledLogout
                                    }>
                                    <Box sx={styles.quit}>
                                        <QuitIcon
                                            width={48}
                                            height={48}
                                            stroke={accentColor}
                                        />
                                        <Typography
                                            sx={[
                                                styles.quitText,
                                                { color: accentColor },
                                            ]}>
                                            {strings.quitStory}
                                        </Typography>
                                    </Box>
                                </ButtonBase>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={this.state.showPopup}
                    onClose={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        this.setShowPopup(false);
                    }}>
                    <Box style={styles.centeredView}>
                        <Box
                            sx={{
                                width: "300px",
                                backgroundColor: Colors.PRIMARY,
                                borderRadius: "20px",
                                padding: "35px 25px 25px 25px",
                                ...columnStartStyles,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                elevation: 5,
                            }}>
                            <QuitPopupIcon
                                width={90}
                                height={90}
                                fill={Colors.WHITE}
                            />

                            <Typography
                                style={{
                                    color: Colors.WHITE,
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: normalize(14),
                                    marginTop: "19px",
                                    textAlign: "center",
                                }}>
                                {strings.sureToExit}
                            </Typography>

                            <Typography
                                style={{
                                    color: Colors.ERROR,
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: normalize(12),
                                    marginTop: "19px",
                                    textAlign: "center",
                                }}>
                                {strings.looseAchievements}
                            </Typography>

                            <Box style={styles.separatorBuyEpisode} />

                            <Box sx={styles.popupButtons}>
                                <ButtonBase
                                    style={styles.noButton}
                                    onClick={() => {
                                        SoundManager.getInstance().playCloseModalSound();
                                        this.setShowPopup(false);
                                    }}>
                                    <Typography sx={styles.noText}>
                                        {strings.back}
                                    </Typography>
                                </ButtonBase>

                                <ButtonBase
                                    sx={{
                                        backgroundColor: Colors.SECONDARY,
                                        padding: "7px 11px",
                                        borderRadius: "10px",
                                        width: "100px",
                                    }}
                                    onClick={() => {
                                        SoundManager.getInstance().playCloseModalSound();
                                        this.backToMenu();
                                    }}>
                                    <Typography
                                        sx={[
                                            styles.yesText,
                                            { color: Colors.WHITE },
                                        ]}>
                                        {strings.exit}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        );
    }
}

// =======================================================

export default TopMenu;
