import { Box, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { successAnim } from "../../../constants/success";
import { styles } from "../styles";
import { strings } from "../../../services/translation";
import GradientBtn from "../../../components/GradientBtn";

const PurchaseSuccess = () => {
  return (
    <Box sx={styles.container}>
      <Player
        src={successAnim}
        autoplay
        style={{ width: "120px", height: "120px" }}
        keepLastFrame
      />
      <Typography sx={styles.title} variant="h2">
        {strings.purchase.validate}
      </Typography>
      <Typography sx={styles.subtitle}>
        {strings.purchase.validateSub}
      </Typography>
      <Box sx={styles.gradientBtn}>
        <GradientBtn onPress={() => (window.location.href = "/shop")}>
          {strings.purchase.backToStore}
        </GradientBtn>
      </Box>
    </Box>
  );
};

export default PurchaseSuccess;
