/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material";

interface Props {
  style?: any;
  color1: string;
  color2: string;
  borderWidth?: number | undefined;
  borderRadius?: number | undefined;
  backgroundColor?: string | undefined;
  deg?: number | undefined;
  children: any;
}

const GradientBorderBox = (props: Props) => {
  const {
    color1,
    color2,
    borderWidth = 2,
    borderRadius = 13,
    backgroundColor = "transparent",
    deg = 270,
    style,
    children,
  } = props;

  return (
    <Box
      sx={[
        {
          position: "relative",
          border: `${borderWidth}px solid transparent`,
          borderRadius: `${borderRadius}px`,
          backgroundImage: `linear-gradient(${backgroundColor}, ${backgroundColor}), linear-gradient(${deg}deg, ${color2} 0%, ${color1} 100%)`,
          backgroundOrigin: "border-box",
          backgroundClip: "padding-box, border-box",
        },
        style ? style : {},
      ]}
    >
      {children}
    </Box>
  );
};

export default GradientBorderBox;
