/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Colors } from "../../style";
import { Box, Typography } from "@mui/material";

interface Props {
  text: string | any;
  textColor?: string;
  textStyle?: any;
  style?: any;
  icon?: any;
}

const DynamicTag = (props: Props) => {
  const { text, textColor, style, icon, textStyle } = props;

  return (
    <Box sx={[styles.promoTimer, style]}>
      {icon ? (
        <>
          {icon()}
          <Box style={{ width: 5 }} />
        </>
      ) : null}
      <Typography
        sx={[
          styles.promoTagTextTimer,
          textColor ? { color: textColor } : { color: Colors.WHITE },
          textStyle ? textStyle : {},
        ]}
      >
        {text}
      </Typography>
    </Box>
  );
};

const styles = {
  promoTagTextTimer: {
    color: Colors.ACCENT,
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
  },
  promoTimer: {
    backgroundColor: Colors.PRIMARY,
    flexDirection: "row",
    padding: "7px 13px",
    borderRadius: "7px",
    alignItems: "center",
  },
};

export default React.memo(DynamicTag);
