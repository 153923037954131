import { Colors } from "../../style";
import {
  columnCenterStyles,
  columnEndStyles,
  columnStartStyles,
  columnStyles,
  rowCenterStyles,
  rowStartStyles,
  rowStyles,
} from "../../style/flex";
import { Eczar, Gilroy } from "../../style/fonts";

export const styles = {
  contentContainer: {
    ...columnCenterStyles,
    width: "100%",
    height: "100%",
  },
  container: {
    ...columnCenterStyles,
    backgroundColor: Colors.PRIMARY,
    padding: "40px 30px",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "700px",
    color: Colors.WHITE,
    filter: "drop-shadow(0px 12.0538px 12.0538px rgba(0, 0, 0, 0.25))",
  },
  title: {
    fontFamily: Gilroy,
    color: Colors.WHITE,
    marginBottom: "calc(10px + 1vh)",
    textAlign: "center",
  },
  subtitle: {
    fontFamily: Gilroy,
    color: Colors.WHITE,
    fontSize: "calc(16px + 0.2vh)",
    textAlign: "center",
  },
  helpBtn: {
    position: "absolute",
    top: 37,
    right: "5%",
    ...rowStartStyles,
    columnGap: "7px",
  },
  modalContainer: {
    outline: "none",
    position: "relative",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    width: "60vw",
    minWidth: "500px",
    maxWidth: "950px",
  },
  modalRewardContainer: {
    outline: "none",
    ...columnStartStyles,
    position: "relative",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    padding: "30px 0px",
    width: "400px",
  },
  modalRewardText: {
    textAlign: "center",
    padding: "25px",
    color: Colors.WHITE,
    fontFamily: Gilroy,
    fontSize: "18px",
  },
  modalRewardTickets: {
    color: Colors.TICKET,
    fontFamily: Gilroy,
    fontSize: "18px",
  },
  helpContainer: {
    ...columnStyles,
    padding: "20px 0px 150px",
    overflow: "auto",
    maxHeight: "90vh",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  helpTitle: {
    fontFamily: Eczar.semiBold,
    color: Colors.ACCENT,
    fontSize: "calc(24px + 2vh)",
    padding: "10px 30px",
  },
  helpSubtitle: {
    alignSelf: "flex-start",
    textAlign: "start",
    fontFamily: Gilroy,
    color: Colors.WHITE,
    padding: "10px 30px",
    fontSize: "20px",
  },
  helpText: {
    alignSelf: "flex-start",
    textAlign: "start",
    fontFamily: Gilroy,
    color: Colors.OLD_PRICE,
    padding: "0px 30px",
    fontSize: "16px",
  },
  closeBtn: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  gradientBox: {
    ...columnEndStyles,
    width: "100%",
    position: "absolute",
    borderRadius: "0px 0px 20px 20px",
    height: "150px",
    paddingBottom: "20px",
    bottom: "0px",
    background: `linear-gradient(rgba(23, 24, 28, 0) 0%, ${Colors.PRIMARY} 35%)`,
  },
  popupButtons: {
    width: "100%",
    marginTop: "25px",
    ...rowStyles,
    justifyContent: "space-evenly",
    alignItems: "flex-end",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  ticketsNumber: {
    ...rowCenterStyles,
    columnGap: "7px",
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "7px 15px",
    borderRadius: "7px",
    marginTop: "15px",
  },
  gradientBtn: {
    width: "100%",
    marginTop: "calc(20px + 4vh)",
    marginBottom: "20px",
    ...columnEndStyles,
  },
};
