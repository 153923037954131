import firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyRkixjfNKrvPIi6Anpu7hjMuQWpwovXY",
  authDomain: "tales-up-demo.firebaseapp.com",
  databaseURL: "https://tales-up-demo.firebaseio.com",
  projectId: "tales-up-demo",
  storageBucket: "tales-up-demo.appspot.com",
  messagingSenderId: "261248566427",
  appId: "1:261248566427:web:fee5b505cd16b1360d323e",
  measurementId: "G-8NGJ07V9P3",
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebase.database();

// Exportez la fonction d'initialisation de Firebase Analytics
export const initializeFirebaseAnalytics = () => {
  if (typeof window !== "undefined") {
    firebase.analytics();
  }
};

// Exportez l'instance de firebase.analytics()
export const analytics = firebase.analytics();
