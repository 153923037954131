import normalize from "../../../../utils/fontSizer";
import { Colors } from "../../../../style";
import { columnStyles } from "../../../../style/flex";

// =======================================================

export default {
  container: {
    ...columnStyles,
    alignItems: "center",
  },
  topOptions: {
    marginTop: "30px",
    marginBottom: "50px",
    ...columnStyles,
    justifyContent: "center",
  },
  separator: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.WHITE,
    alignSelf: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "26px 0px",
  },
  quit: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  quitText: {
    color: Colors.ACCENT_BRAQUAGE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(20),
    marginLeft: "10px",
  },
  sliderBox: {
    ...columnStyles,
    marginTop: "15px",
    marginLeft: "12px",
    width: "250px",
  },
  text: {
    color: Colors.WHITE,
    fontSize: 20,
    fontFamily: "Gilroy-Bold",
    marginBottom: "10px",
  },
};

// =======================================================
