/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { getRouteManager } from "../../services/routeManager";
import TicketAnimated from "../TicketAnimated";
import TicketPromoAnimated from "../TicketPromoAnimated";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import CoverTitleSmallFlexible from "../coverTitleSmallFlexible";
import SoundManager from "../../services/SoundManager";
import { columnCenterStyles } from "../../style/flex";
import BuyStoryModal from "../BuyStoryModal";
import { styles } from "./style";
import useAuth from "../../services/Auth/useAuth";
import PassAnimated from "../PassAnimated";
import GradientText from "../GradientText";

interface Props {
  number: string;
  time: string;
  episode: any;
  onPressContinue: any;
}

const EpisodeAnimated = (props: Props) => {
  const { number, time, episode, onPressContinue } = props;
  const auth = useAuth();
  const [cover] = useState(getRouteManager().cover(episode.storyId));
  const [showPopUpBuyStory, setShowPopUpBuyStory] = useState(false);
  const [priceState, setPriceState] = useState<any>(episode.story.price);

  episode.story.epNumber = number;

  useEffect(() => {
    setPriceState(episode.story.price);
  }, [episode]);

  const pressEp = async (): Promise<void> => {
    if (!showPopUpBuyStory) {
      if (priceState != 0) {
        SoundManager.getInstance().playOpenModalSound();
        setShowPopUpBuyStory(true);
        return;
      } else if (priceState == 0) {
        await onPressContinue(episode);
        return;
      }
    }
  };

  const closeModal = () => {
    setShowPopUpBuyStory(false);
    SoundManager.getInstance().playCloseModalSound();
  };

  return (
    <ButtonBase
      sx={[styles.container, { height: 159.75 }]}
      onClick={() => pressEp()}>
      <Box sx={styles.buyAll}>
        <Box style={styles.leftPart}>
          <Box sx={styles.coverContainer}>
            <CoverTitleSmallFlexible
              cover={cover}
              title={null}
              height={"139.5px"}
              width={"86px"}
              isAnimated={false}
              maxWidth={"115px"}
              maxHeight={"186px"}
              minWidth={"86px"}
              minHeight={"139.5px"}
            />
          </Box>
        </Box>
        <Box
          sx={[
            styles.rightPart,
            {
              background: `linear-gradient(90deg, #17181C 0%, rgba(23, 24, 28, 0.7) 100%), no-repeat center/100% url(${cover})`,
            },
          ]}>
          <Box sx={[styles.priceContainer]}>
            <Typography sx={styles.socialTitle}>
              {strings.episode + " " + number}
            </Typography>

            {priceState != 0 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                {!auth?.user?.pass?.isActive ? (
                  <>
                    <Box sx={styles.ticketsOwned}>
                      <PassAnimated
                        style={{
                          width: 20,
                          height: 20,
                        }}
                      />
                      <GradientText
                        color1={Colors.PASS1}
                        color2={Colors.PASS2}
                        style={styles.gradientTextSmall}>
                        {strings.pass.pass}
                      </GradientText>
                    </Box>
                    <Box
                      style={{
                        width: '2px',
                        height: "30px",
                        borderRadius: "2px",
                        margin: "0 10px",
                        backgroundColor: Colors.FOND_ITEM_POPUP,
                      }}
                    />
                  </>

                ) : null}
                {episode.story.promo ? (
                  <Box sx={styles.ticketsOwned}>
                    <TicketPromoAnimated style={{ width: 25, height: 25 }} />
                    <Typography
                      sx={[
                        styles.storyPrice,
                        {
                          color: Colors.OLD_PRICE,
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                          fontSize: normalize(12),
                        },
                      ]}>
                      {priceState}
                    </Typography>
                    <Typography
                      sx={[
                        styles.storyPrice,
                        { marginLeft: "7px", color: Colors.PROMO },
                      ]}>
                      {Math.ceil(
                        priceState *
                        ((100 - episode.story.promo.discount) / 100),
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={styles.ticketsOwned}>
                    <TicketAnimated style={{ width: 25, height: 25 }} />
                    <Typography style={styles.storyPrice}>
                      {priceState}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{
                    color: Colors.ACCENT,
                    fontSize: normalize(16),
                    fontFamily: "Gilroy-Bold",
                  }}>
                  {time ? time : null}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Modal open={showPopUpBuyStory} onClose={() => closeModal()}>
        <Box
          sx={{
            width: "300px",
            borderRadius: "20px",
            backgroundColor: Colors.PRIMARY,
            position: "relative",
            padding: "40px 0px 20px",
            ...columnCenterStyles,
            zIndex: 50,
          }}>
          <BuyStoryModal
            story={episode.story}
            seriesId={episode.seriesId}
            price={priceState}
            closeModal={() => closeModal()}
            setPriceState={setPriceState}
            hasCloseBtn={true}
            isFromModal
          />
        </Box>
      </Modal>
    </ButtonBase>
  );
};

export default EpisodeAnimated;
