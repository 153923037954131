/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component, ReactNode } from "react";
import { pirateJson } from "../../constants/pirateJSON";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { columnCenterStyles } from "../../style/flex";
import { Colors } from "../../style";

interface Props {
  goNext: any;
  text: string;
}

interface State {
  jsonChoice: any;
  isPlayingAnimation: boolean;
}

class ChoiceFTUE extends Component<Props, State> {
  private playerRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      jsonChoice: pirateJson,
      isPlayingAnimation: false,
    };
    this.playerRef = React.createRef(); // initialize your ref
  }

  playAnim = () => {
    const { isPlayingAnimation } = this.state;
    if (this.playerRef && this.playerRef.current && !isPlayingAnimation) {
      this.setState({ isPlayingAnimation: true }, () => {
        this.playerRef.current.play();
      });
    } else if (this.playerRef && this.playerRef.current && isPlayingAnimation) {
      this.playerRef.current.stop();
      this.setState({ isPlayingAnimation: false });
    }
  };

  render(): ReactNode {
    const { goNext, text } = this.props;
    return (
      <Box
        sx={[
          styles.button,
          {
            width: "22.9vh",
          },
        ]}
      >
        <ButtonBase onClick={() => this.playAnim()} sx={[styles.touchable]}>
          <Box sx={styles.animationView}>
            {this.state.jsonChoice ? (
              <Player
                ref={this.playerRef}
                src={this.state.jsonChoice}
                onEvent={(event: any) => {
                  if (event === "complete") {
                    goNext();
                  }
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  overflow: "hidden",
                  height: "100%",
                }}
              />
            ) : null}
          </Box>

          <Typography sx={[styles.text]}>{text}</Typography>
        </ButtonBase>
      </Box>
    );
  }
}

const styles = {
  button: {
    position: "relative",
    width: "22.9%",
    maxWidth: "330px",
    minWidth: "230px",
    borderRadius: "13px",
    marginBottom: "10px",
    height: "5vh",
    maxHeight: "66px",
    minHeight: "52px",
    ...columnCenterStyles,
    zIndex: 12,
    opacity: 1,
  },
  touchable: {
    ...columnCenterStyles,
    height: "100%",
    width: "100%",
    borderRadius: "13px",
    overflow: "hidden",
  },
  animationView: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
  },
  text: {
    fontSize: "calc(12px + 0.45vh)",
    color: Colors.WHITE,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    zIndex: 20,
  },
};

export default ChoiceFTUE;
