/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { getRouteManager } from "../../services/routeManager";
import Avatar from "../Avatar";
import { Box } from "@mui/material";
import { Colors } from "../../style";
import { normalizeSize } from "../../utils/fontSizer";
import { isStrictlyPositiveNumber } from "../../utils/NumberHelper";

interface Props {
    user: any | null;
    persoWidth: number | null;
    borderWidth?: any;
    borderColor?: any;
    avatarStyle?: any;
    isConnected?: boolean;
}

const AvatarContainer = (props: Props) => {
    const {
        user,
        persoWidth,
        borderWidth,
        borderColor,
        avatarStyle,
        isConnected = undefined,
    } = props;

    const styles = {
        connectedIndicator: {
            width: normalizeSize(15),
            height: normalizeSize(15),
            borderRadius: normalizeSize(15),
            position: "absolute",
            bottom:
                persoWidth && isStrictlyPositiveNumber(persoWidth)
                    ? persoWidth * 0.0333
                    : 2,
            right:
                persoWidth && isStrictlyPositiveNumber(persoWidth)
                    ? persoWidth * 0.0333
                    : 2,
            zIndex: 300,
            border: `3px solid ${Colors.BTN_ACTIVE}`,
        },
    };

    return (
        <Box sx={{ position: "relative" }}>
            <Avatar
                fond={
                    user.avatar.fond != null
                        ? getRouteManager().getItemImage(user.avatar.fond)
                        : null
                }
                bustePerso={
                    user.avatar.perso != null
                        ? getRouteManager().getItemImageForVersion(
                              user.avatar.perso,
                              "bust",
                          )
                        : null
                }
                buste={
                    user.avatar.buste != null
                        ? getRouteManager().getItemImage(user.avatar.buste)
                        : null
                }
                perso={
                    user.avatar.perso != null
                        ? user.avatar.buste != null
                            ? user.avatar.chapeau != null
                                ? getRouteManager().getItemImageForVersion(
                                      user.avatar.perso,
                                      "head",
                                  )
                                : getRouteManager().getItemImageForVersion(
                                      user.avatar.perso,
                                      "noBust",
                                  )
                            : user.avatar.chapeau != null
                            ? getRouteManager().getItemImageForVersion(
                                  user.avatar.perso,
                                  "noHat",
                              )
                            : getRouteManager().getItemImageForVersion(
                                  user.avatar.perso,
                                  "full",
                              )
                        : null
                }
                masque={
                    user.avatar.masque != null
                        ? getRouteManager().getItemImage(user.avatar.masque)
                        : null
                }
                chapeau={
                    user.avatar.chapeau != null
                        ? getRouteManager().getItemImage(user.avatar.chapeau)
                        : null
                }
                persoWidth={persoWidth ? persoWidth : 120}
                borderColor={borderColor ? borderColor : "transparent"}
                borderWidth={borderWidth ? borderWidth : 3}
                avatarStyle={avatarStyle ? avatarStyle : {}}
            />
            {isConnected === undefined ? null : (
                <Box
                    sx={[
                        styles.connectedIndicator,
                        isConnected
                            ? {
                                  backgroundColor:
                                      Colors.vertALEXTESGRANDSMORTS,
                              }
                            : {
                                  backgroundColor: Colors.OLD_PRICE,
                              },
                    ]}
                />
            )}
        </Box>
    );
};

export default AvatarContainer;
