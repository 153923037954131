/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint max-classes-per-file: 0 */
import _ from "lodash";
import { Variable } from "./variables";
import { IVariable } from "./interfaces";

export class Player {
  name: string;

  id: number;

  avatar: any;

  letter: string;

  letterColor: string;

  letterBorderColor: string;

  states: Array<Variable>;

  playerCode?: string | null;

  constructor(
    playerName: string,
    id: number,
    avatar: any,
    letter: string,
    letterColor: string,
    letterBorderColor: string,
    playerCode?: any
  ) {
    this.name = playerName;
    this.avatar = avatar ? avatar : null;
    this.letter = letter;
    this.letterColor = letterColor;
    this.letterBorderColor = letterBorderColor;
    this.id = id;
    this.playerCode = playerCode;
    this.states = [];
  }

  /**
   * Change or add a player state
   * @param {IVariable} newState - a state to evaluate
   */
  public handleState(newState: IVariable): void {
    const state = this.getStateByName(newState.firstOperand);
    if (state == null) {
      this.states.push(
        new Variable(newState.firstOperand, newState.thirdOperand)
      );
    } else {
      state.update(newState);
    }
  }

  public handleStateLoad(newState: any): void {
    const state = this.getStateByName(newState.firstOperand);
    if (state == null) {
      this.states.push(new Variable(newState.name, newState.value));
    } else {
      state.update(newState);
    }
  }

  /**
   * Get the state on player (if it exists)
   * @param {string} stateName - the name of state to get.
   * @return {Variable} the state found.
   */
  public getStateByName(stateName: string) {
    if (_.isEmpty(this.states)) {
      return null;
    }

    return this.states.find((el) => el.name === stateName);
  }

  public isAvailable(): boolean {
    for (let i = 0; i < this.states.length; i += 1) {
      if (this.states[i].name.startsWith("state_Indispo") === true) {
        if (this.states[i].value > 0) {
          return false;
        }
      }
    }
    return true;
  }

  public hasState(stateName: string): boolean {
    for (let i = 0; i < this.states.length; i += 1) {
      if (this.states[i].name === stateName && this.states[i].value > 0) {
        return true;
      }
    }
    return false;
  }

  public hasStateWithValue(stateName: string, value: any): boolean {
    for (let i = 0; i < this.states.length; i += 1) {
      if (this.states[i].name === stateName && this.states[i].value === value) {
        return true;
      }
    }
    return false;
  }

  public checkHasStateWithValueAndOperand(
    stateName: string,
    value: any,
    operand: any = false
  ): boolean {
    for (let i = 0; i < this.states.length; i += 1) {
      if (this.states[i].name === stateName) {
        if (operand) {
          if (operand === "=") {
            if (this.states[i].value === parseInt(value, 10)) {
              return true;
            }
          } else if (operand === ">") {
            if (this.states[i].value > parseInt(value, 10)) {
              return true;
            }
          } else if (operand === "<") {
            if (this.states[i].value < parseInt(value, 10)) {
              return true;
            }
          } else {
            if (this.states[i].value === value) {
              return true;
            }
          }
        } else {
          if (this.states[i].value === value) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public isAvailableAndHasStateWithValue(
    stateName: string,
    value: any
  ): boolean {
    if (this.isAvailable()) {
      for (let i = 0; i < this.states.length; i += 1) {
        if (
          this.states[i].name === stateName &&
          this.states[i].value === value
        ) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  public GetName(): string {
    return this.name;
  }

  public GetId(): number {
    return this.id;
  }

  public GetLetter(): string {
    return this.letter;
  }

  public GetAvatar(): any {
    return this.avatar;
  }

  public GetLetterColor(): string {
    return this.letterColor;
  }

  public GetLetterBorderColor(): string {
    return this.letterBorderColor;
  }

  public getPlayerCode(): string | any {
    return this.playerCode;
  }
}

export class Players {
  players: Array<Player>;

  constructor(playersNames: Array<any>) {
    this.players = [];
    this.initializePlayers(playersNames);
  }

  /**
   * Initialize the players
   * @param {Array<string>} names - player names from preGame
   */
  public initializePlayers(names: Array<any>): void {
    names.map((p, index) => {
      this.players.push(
        new Player(
          p.name,
          index,
          p.avatar,
          p.letter,
          p.letterColor,
          p.letterBorderColor,
          p.playerCode ? p.playerCode : null
        )
      );
    });
  }

  /**
   * Get a player by ID
   * @param {number} id - A player id
   * @return {Player} the player object
   */
  public getPlayerById(id: number): Player {
    return this.players[id];
  }

  /**
   * Get a player by ID
   * @param {string} playerCode - A player id
   * @return {Player} the player object
   */
  public getPlayerByPlayerCode(playerCode: string): Player | undefined {
    return this.players.find((p: Player) => p.playerCode === playerCode);
  }

  /**
   * Get a player name
   * @param {number} ID of the player
   * @return {string} the name of the player
   */
  public getPlayerName(id: number): string {
    return this.players[id].name;
  }
}
