/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPositiveNumber, isStrictlyPositiveNumber } from "../../utils/NumberHelper";
import { isFunction, isNumber } from "../../utils/TypeOfHelper";
import { Information } from "./information";
import { ChoiceTypeEnum, IChoice, IScene, ScreenType } from "./interfaces";
import { Player, Players } from "./players";
import { Variables } from "./variables";
import HandleVariables from "./variableHandler";
import { getRouteManager } from "../routeManager";
import _ from "lodash";
import { isNotEmptyString } from "../../utils/StringHelper";
import { getLanguage, strings } from "../translation";
import { propertyExists } from "../../utils/ObjectHelper";
import ConditionHandler from "./conditionHandler";
import Sound from "../SoundManager/Sound";
import SoundManager from "../SoundManager";

const PLAYER_IDENTIFIER = "{PLAYER}";
const RANDOM_IDENTIFIER = "{RANDOM}";

class StoryReader {
  public _storyData: any;

  public _currentScene: IScene;

  public _players: Players;

  public _variables: Variables;

  public _storyId: number;

  public _activePlayerId: number;

  public _masterPlayerId: number;

  public _soundManager: SoundManager;

  public _lastSfx: Sound | null;

  public _lastLoop: Sound | null;

  public _lastMusic: Sound | null;

  public _lastAmbiance: Sound | null;

  public _currentObjective: any;

  public _triggeredEvents: Array<any>;

  public _dir: any;

  public _visitedScenes: Array<number>;

  public _informations: Array<Information>;

  public _title: string;

  public _episode: any;

  public _series: any;

  public _currentNamesToHighlight: Array<string>;

  public _isOnlineMode: boolean;

  public setAskExit: any;

  public isSolo: boolean;

  public isBoard: boolean;

  public isHost: boolean;

  constructor(
    storyId: number,
    storyData: any,
    playersNames: Array<any>,
    title: string,
    scene?: number,
    isOnlineMode = false,
    setAskExit?: any,
    isSolo = false,
    isHost = false,
    isBoard = false,
    onReboot = false
  ) {
    this._storyData = storyData;
    this._players = new Players(playersNames);
    this._variables = new Variables();
    this._storyId = storyId;
    this.isSolo = isSolo;
    this.isBoard = isBoard;
    this.isHost = isHost;
    this._currentScene =
      scene !== null && scene !== undefined && isStrictlyPositiveNumber(scene)
        ? this._getScene(scene)
        : storyData.scenes[0];
    this._activePlayerId = 0;
    this._masterPlayerId = 0;
    if (!onReboot) {
      this._handleVariables(null, null, null, null, null, null);
    }
    this._soundManager = SoundManager.getInstance();

    this._currentObjective = "";
    this._triggeredEvents = [];

    this._visitedScenes = [];
    this._title = title;
    this._episode = null;
    this._currentNamesToHighlight = [];
    this._informations = [];
    this._isOnlineMode = isOnlineMode;
    this.setAskExit = setAskExit;
    this._lastSfx = null;
    this._lastLoop = null;
    this._lastAmbiance = null;
    this._lastMusic = null;
  }

  // #region Public getters

  /**
   * Get a scene from realm
   *
   * @param {number} id - a scene ID.
   * @return {scene} the scene with the corresponding ID.
   */
  public _getScene(id: number): IScene {
    const { scenes } = this._storyData;
    return scenes.find((s: IScene) => s.number === id);
  }

  /**
   *
   */
  public getTitle(): string {
    return this._title;
  }

  /**
   *
   */
  public getCurrentNamesToHighlight(): Array<string> {
    return this._currentNamesToHighlight;
  }

  /**
   *
   */
  public getSoundManager(): SoundManager {
    return this._soundManager;
  }

  /**
   *
   */
  public getCurrPassageId(): number {
    return this._currentScene.number;
  }

  public getCurrScene(): any {
    return this._currentScene;
  }

  public getType(): string {
    if (this.isSolo) {
      return "solo";
    } else if (this._isOnlineMode) {
      return "online";
    } else if (this.isBoard) {
      return "board";
    } else {
      return "normal";
    }
  }

  public getIsHost(): boolean {
    return this.isHost;
  }

  /**
   * Get a choice type
   *
   * @return {ChoiceTypeEnum}
   */
  public getChoiceType(): ChoiceTypeEnum {
    if (this._currentScene.choiceType === "normal") {
      return ChoiceTypeEnum.NORMAL;
    }

    if (this._currentScene.choiceType === "random") {
      return ChoiceTypeEnum.RANDOM;
    }

    if (this._currentScene.choiceType === "textbox") {
      return ChoiceTypeEnum.TEXTBOX;
    }

    return ChoiceTypeEnum.PLAYER;
  }

  /**
   *
   */
  public getScreenType(updateBackgroundCallback?: any): ScreenType {
    const images = this._storyData.images.filter(
      (item: any) => item.passageId === this._currentScene.number
    );
    let hasFullscreen = false;
    let filterBg = [];
    let hasImageNotBg = false;
    if (images.length) {
      hasFullscreen = images.find((img: any) => img.fullscreen === true);
      filterBg = images.filter((img: any) => img.background !== true);
      hasImageNotBg = filterBg && filterBg.length > 0 ? true : false;
      if (images.length > 1 || (images.length === 1 && !hasImageNotBg)) {
        const newBg = images.filter((img: any) => img.background === true);
        if (
          newBg &&
          updateBackgroundCallback &&
          isFunction(updateBackgroundCallback)
        ) {
          updateBackgroundCallback(newBg);
        }
      }
    }

    if (this._currentScene && this._currentScene.text) {
      if (
        this._currentScene.text.includes(
          "The following sequence is only for"
        ) ||
        this._currentScene.text.includes("Cette séquence ne concerne que") ||
        this._currentScene.text.includes("This sequence is only for") ||
        this._currentScene.text.includes('This sequence is for') ||
        this._currentScene.text.includes("Cette séquence ne s'adresse qu'à ")
      ) {
        return ScreenType.SECRET;
      }

      if (
        this._currentScene.text.includes("La main passe à") ||
        this._currentScene.text.includes("The spotlight is on") ||
        this._currentScene.text.includes("Hand the phone") ||
        this._currentScene.text.includes("Donnez le téléphone") ||
        this._currentScene.text.includes("Passez le téléphone")
      ) {
        return ScreenType.CHANGE;
      }

      if (hasImageNotBg && this._currentScene.timer !== null) {
        return ScreenType.IMAGE_TIMER;
      }

      if (this._currentScene.timer !== null) {
        return ScreenType.TIMER;
      }

      if (hasImageNotBg) {
        if (hasFullscreen) {
          return ScreenType.FULLSCREEN_IMAGE;
        } else {
          return ScreenType.NORMAL_IMAGE;
        }
      }

      if (this._currentScene.choices.length === 1) {
        if (
          this._currentScene.choices[0].text === "continuer" ||
          this._currentScene.choices[0].text === "Continuer" ||
          this._currentScene.choices[0].text === "continue" ||
          this._currentScene.choices[0].text === "Continue"
        ) {
          return ScreenType.POOLP;
        }
      }

      return ScreenType.STANDARD;
    } else if (hasImageNotBg) {
      if (hasFullscreen) {
        return ScreenType.FULLSCREEN_IMAGE;
      } else {
        return ScreenType.NORMAL_IMAGE;
      }
    }
    return ScreenType.STANDARD;
  }

  /**
   *
   */
  public getHlText(): any {
    const txt = this._currentScene.text;

    const hl = txt.match(/\$\$(.*?)\$\$/g);
    const arrToReturn = [];

    let minus = 0;

    if (hl !== null) {
      for (let i = 0; i < hl.length; i += 1) {
        hl[i] = hl[i].replace("$$", "");
        hl[i] = hl[i].replace("$$", "");

        arrToReturn.push(hl[i]);
      }
    }

    const drawVar = /.*({VAR_(.*)}).*/.exec(txt);

    if (
      drawVar != null &&
      drawVar[2] !== undefined &&
      _.isEmpty(this._variables)
    ) {
      const variable = this._variables.getVariableByName(
        `var_${_.capitalize(drawVar[2])}`
      );

      if (variable) {
        arrToReturn.push(this.numberWithSpaces(variable.value));
        minus += 1;
      }
    }

    return { arrToReturn, minus };
  }

  /**
   *
   */
  public numberWithSpaces(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  /**
   *
   */
  public GetImageId(): number | null {
    const image = this._storyData.images.find(
      (item: any) =>
        item.passageId === this._currentScene.number && item.background !== true
    );

    if (image) {
      return image.id;
    }

    return null;
  }

  /**
   *
   */
  public getPlayers(): Players {
    return this._players;
  }

  /**
   *
   */
  public getAvailablePlayers(): Array<any> {
    return this._players.players.filter(
      (player) => player.isAvailable() === true
    );
  }

  public getPlayerIndexFromIndexInList(index: number) {
    const avPlayers = this.getAvailablePlayers();
    const playersList = this.getPlayers();
    const targetP = avPlayers[0];
    return playersList.players.indexOf(targetP);
  }
  /**
   *
   */
  public loadSave(save: any): void {
    this._players.players.map((p, index) => {
      save.players[index].states.map((s: any) => {
        p.handleStateLoad(s);
      });
    });

    save.variables.map((v: any) => {
      this._variables.handleVariable({
        firstOperand: v.name,
        secondOperand: "=",
        thirdOperand: v.value,
      });
    });

    if (save.hints) {
      save.hints.map((hint: any) => {
        const { _type, _text } = hint;
        if (isNotEmptyString(_type) && isNotEmptyString(_text)) {
          const finalType =
            _type === "hint" || _type === "indice"
              ? strings.topMenu.hint
              : _type;
          this.addInformation(new Information(finalType, _text));
        }
      });
    }
  }

  public loadSaveOnline(save: any): void {
    save.players.map((p: any) => {
      const player = this._players.players.find(
        (item) => item.avatar.userId === p.avatar.userId
      );
      if (player && propertyExists(p, "states")) {
        if (p.states.length > 0) {
          p.states.map((s: any) => {
            player.handleStateLoad(s);
          });
        }
      }
    });

    save.variables.map((v: any) => {
      this._variables.handleVariable({
        firstOperand: v.name,
        secondOperand: "=",
        thirdOperand: v.value,
      });
    });

    if (save.hints) {
      save.hints.map((hint: any) => {
        const { _type, _text } = hint;
        if (isNotEmptyString(_type) && isNotEmptyString(_text)) {
          const finalType =
            _type === "hint" || _type === "indice"
              ? strings.topMenu.hint
              : _type;
          this.addInformation(new Information(finalType, _text));
        }
      });
    }
  }

  /**
   *
   */
  public setNbPlayers(nbPlayers: any): void {
    this._variables.handleVariable({
      firstOperand: "var_Numberplayer",
      secondOperand: "=",
      thirdOperand: nbPlayers,
    });
  }

  /**
   *
   */
  public setEpisode(episode: any): void {
    this._episode = episode;
  }

  /**
   *
   */
  public getEpisode(): any {
    return this._episode;
  }

  /**
   *
   */
  public getVariables(): Variables {
    return this._variables;
  }

  /**
   *
   */
  public getTimerValue(): number {
    return this._currentScene.timer;
  }

  /**
   *
   */
  public getTimerSelect(): number {
    return this._currentScene.timerSelect;
  }

  /**
   *
   */
  public getCurrentObjective(): any {
    return this._currentObjective;
  }

  /**
   *
   */
  public setCurrentObjective(objective: any): any {
    this._currentObjective = objective;
  }

  /**
   *
   */
  public getActivePlayerId() {
    return this._activePlayerId;
  }

  /**
   *
   */
  public getMasterPlayerId() {
    return this._masterPlayerId;
  }

  /**
   *
   */
  public isRandomIdentifier(): boolean {
    return this._currentScene.text.includes(RANDOM_IDENTIFIER);
  }

  /**
   *
   */
  public getRandomIndexPlayer(): number {
    return _.random(0, this.getPlayers().players.length - 1);
  }

  /**
   *
   */
  public getRandomIndexPlayerAvailable(): number {
    return _.random(0, this.getAvailablePlayers().length - 1);
  }

  /**
   * @getRandomDiceNumber
   * Get a random number from 1 to 20
   */
  public getRandomDiceNumber(): number {
    return _.random(1, 20);
  }

  /**
   * Specially for host player [online mode]
   * Returns a random available player index (index is from normal players list)
   */
  public getRandomPlayer(): number {
    let randomIndex = this.getRandomIndexPlayer();

    let activePlayer = this._players.getPlayerById(randomIndex);

    while (activePlayer.isAvailable() === false) {
      randomIndex = this.getRandomIndexPlayer();
      activePlayer = this._players.getPlayerById(randomIndex);
    }

    return randomIndex;
  }

  /**
   *
   */
  public getPlayerTextByIndex(
    text: string,
    index: number,
    identifier: string
  ): string {
    let txt = text;

    if (isNumber(index) && isNotEmptyString(identifier)) {
      txt = txt.replace(identifier, this.getPlayers().getPlayerName(index));
      this._currentNamesToHighlight.push(
        this.getPlayers().getPlayerName(index)
      );
    }

    return txt;
  }

  /**
   *
   */
  public getHighLightText(txt: string): string {
    let text = txt;

    const hlText = this.getHlText().arrToReturn;
    const minus = this.getHlText().minus;

    for (let i = 0; i < hlText.length - minus; i += 1) {
      text = text.replace("$$", "");
      text = text.replace("$$", "");
    }

    return text;
  }

  /**
   * Specially for all players (no host) in game [online mode]
   */
  public getRandomPlayerName(playerIndex: number): string {
    let txt = this._currentScene.text;

    if (isNumber(playerIndex)) {
      this.setActivePlayerId(playerIndex);
      txt = this.getPlayerTextByIndex(txt, playerIndex, RANDOM_IDENTIFIER);
    }

    return this.getHighLightText(txt);
  }

  /**
   *
   */
  public getCurrentText(): string {
    let txt = this._currentScene.text;

    this._currentNamesToHighlight = [];

    const needReplace = txt.includes(PLAYER_IDENTIFIER);
    const needRandom = txt.includes(RANDOM_IDENTIFIER);

    if (needReplace) {
      txt = this.getPlayerTextByIndex(
        txt,
        this._activePlayerId,
        PLAYER_IDENTIFIER
      );
    }
    //Uniquement si pas mode en ligne, on set le joueur actif avant dans le mode en ligne
    //Si random, l'host le défini au changement de scene, donc se baser sur joueur actif 
    if (needRandom) {
      if (!this._isOnlineMode) {
        this.setActivePlayerId(this.getRandomIndexPlayer());

        let activePlayer = this._players.getPlayerById(this._activePlayerId);

        while (activePlayer.isAvailable() === false) {
          this.setActivePlayerId(this.getRandomIndexPlayer());
          activePlayer = this._players.getPlayerById(this._activePlayerId);
        }
      }

      txt = this.getPlayerTextByIndex(
        txt,
        this._activePlayerId,
        RANDOM_IDENTIFIER
      );
    }

    const drawVar = /.*({VAR_(.*)}).*/.exec(txt);
    if (
      drawVar != null &&
      drawVar[1] !== undefined &&
      drawVar[2] !== undefined &&
      !_.isEmpty(this._variables)
    ) {
      const variable = this._variables.getVariableByName(
        `var_${_.capitalize(drawVar[2])}`
      );

      if (variable) {
        txt = txt.replace(drawVar[1], this.numberWithSpaces(variable.value));
      }
    }

    const drawPlayerState = /.*({STATE_(.*)}).*/.exec(txt);

    if (drawPlayerState != null) {
      const playerWithState = this._players.players.filter((player: Player) =>
        player.hasState(`state_${_.capitalize(drawPlayerState[2])}`)
      );

      const playerName = playerWithState[0].GetName();
      const statePlayerId = playerWithState[0].GetId();

      this.setActivePlayerId(statePlayerId);

      txt = txt.replace(drawPlayerState[1], playerName);

      this._currentNamesToHighlight.push(playerName);
    }

    return this.getHighLightText(txt);
  }

  /**
   *
   */
  public getCurrentChoices(): Array<IChoice> {
    const { choices } = this._currentScene;

    const choicesToDraw: Array<IChoice> = [];
    if (choices && choices.length) {
      for (let i = 0; i < choices.length; i += 1) {
        if (choices[i].drawConditions.length !== 0) {
          const { firstOperand, secondOperand, thirdOperand } =
            choices[i].drawConditions[0];

          if (firstOperand.startsWith("state_")) {
            // check if it's a state
            const activePlayer = this.getPlayers().getPlayerById(
              this._activePlayerId
            );

            /* if (activePlayer.hasState(firstOperand)) {
                  if (
                    activePlayer.getStateByName(firstOperand)!.value ===
                    parseInt(thirdOperand, 10)
                  ) {
                    choicesToDraw.push(choices[i]);
                  }
                } */
            /* if (
                  activePlayer.hasStateWithValue(
                    firstOperand,
                    parseInt(thirdOperand, 10),
                  )
                ) {
                  choicesToDraw.push(choices[i]);
                } */
            if (
              activePlayer.checkHasStateWithValueAndOperand(
                firstOperand,
                parseInt(thirdOperand, 10),
                secondOperand
              )
            ) {
              choicesToDraw.push(choices[i]);
            }
          } else if (firstOperand.startsWith("var_")) {
            // check if it's a variable
            const varToCheck = this._variables.variables.find(
              (myVar) => myVar.name === firstOperand
            );

            if (varToCheck !== undefined) {
              if (secondOperand === "=") {
                if (varToCheck.value === parseInt(thirdOperand, 10)) {
                  choicesToDraw.push(choices[i]);
                }
              } else if (secondOperand === ">") {
                if (varToCheck.value > parseInt(thirdOperand, 10)) {
                  choicesToDraw.push(choices[i]);
                }
              } else if (secondOperand === "<") {
                if (varToCheck.value < parseInt(thirdOperand, 10)) {
                  choicesToDraw.push(choices[i]);
                }
              }
            }
          }
        } else {
          choicesToDraw.push(choices[i]);
        }
      }
    }
    return choicesToDraw;
  }

  /**
   *
   */
  public getVisitedScenes(): Array<number> {
    return this._visitedScenes;
  }

  /**
   *
   */
  public setVisitedScenes(scenes: Array<any>): void {
    this._visitedScenes = scenes;
  }
  // #endregion

  // #region Class methods

  /**
   *
   */
  public getStoryId(): number {
    return this._storyId;
  }

  public getInformations(): Array<Information> {
    return this._informations;
  }

  public addInformation(info: Information) {
    this._informations.push(info);
  }

  public setInformations(value: Array<Information>) {
    this._informations = value;
  }

  /**
   *
   */
  public setActivePlayerId(id: number): void {
    this._activePlayerId = id;
  }

  /**
   *
   */
  public setMasterPlayerId(id: number): void {
    this._masterPlayerId = id;
  }

  /**
   * Handle variables or player state change
   */
  public _handleVariables(
    newStateCallback: any,
    newSuccessCallback: any,
    newShowGroupCallback: any,
    newShowSecretCallback: any,
    newGameOverCallback: any,
    newVarCallback: any
  ): void {
    HandleVariables(
      this._storyData,
      this._currentScene.variables,
      this._variables,
      this._players,
      this._storyId,
      this._activePlayerId,
      newStateCallback,
      newSuccessCallback,
      newShowGroupCallback,
      newShowSecretCallback,
      newGameOverCallback,
      newVarCallback
    );
  }

  // eslint-disable-next-line class-methods-use-this
  async getData(url: any) {
    const response = await fetch(url);
    return response.json();
  }

  public getSceneLinkFromChoice(choice: IChoice, answer = "", playerPicked: number) {
    let sceneLink = null;
    const hasConditions = propertyExists(choice, "conditions");

    if (!hasConditions || (hasConditions && _.isEmpty(choice.conditions))) {
      // No condition: just go to the link.
      if (choice.isRandom) {
        if (!_.isEmpty(choice.randomScenes)) {
          sceneLink = this._getScene(this.handleRandom(choice));
        }
      } else {
        sceneLink = this._getScene(choice.sceneLink);
      }
    } else if (hasConditions) {
      const conditionHandler = new ConditionHandler(
        choice.conditions[0],
        this._variables,
        this._players.getPlayerById(isPositiveNumber(playerPicked) ? playerPicked : this._activePlayerId)
      );

      if (answer !== "") {
        sceneLink = this._getScene(
          conditionHandler.handleTextbox(answer)
        );
      } else {
        sceneLink = this._getScene(conditionHandler.handleCondition());
      }
    }
    return sceneLink;
  }

  /**
   *
   */
  public async goToNextScene(
    choice: IChoice,
    answer = "",
    newStateCallback = (_currVar: any) => {
      null;
    },
    newSuccessCallback = (_currVar: any) => {
      null;
    },
    newShowGroupCallback = (newVal: boolean) => {
      null;
    },
    newShowSecretCallback = (newVal: boolean) => {
      null;
    },
    newGameOverCallback = (newVal: boolean) => {
      null;
    },
    newEssentialCallback = (_showPopup: boolean, _essential: Array<any>) => {
      null;
    },
    newVarCallback = (_variable: any) => {
      null;
    },
    newInfoCallback = (_showPopup: boolean) => {
      null;
    },
    showBoardDicePopupCallback = (
      _showPopup: boolean,
      _choice: IChoice | null,
      _random: number | null,
    ) => { null },
  ): Promise<void> {
    if (this._lastSfx) {
      this._soundManager.removeSound(this._lastSfx.id);
      this._lastSfx = null;
    }

    if (this._lastLoop) {
      this._soundManager.removeSound(this._lastLoop.id);
      this._lastLoop = null;
    }

    const hasConditions = propertyExists(choice, "conditions");

    if (!hasConditions || (hasConditions && _.isEmpty(choice.conditions))) {
      // No condition: just go to the link.
      if (choice.isRandom && !this._isOnlineMode) {
        if (!_.isEmpty(choice.randomScenes)) {
          if (
            this.isBoard &&
            choice.diceNumber &&
            choice.diceProbaType
          ) {
            showBoardDicePopupCallback(true, choice, null);
          } else if (
            !this.isBoard &&
            choice.diceNumber &&
            choice.diceProbaType
          ) {
            const randNumber = this.getRandomDiceNumber();
            showBoardDicePopupCallback(true, choice, randNumber);
            this._currentScene = this._getScene(
              this.handleRandomWithModal(choice, randNumber),
            );
          } else {
            this._currentScene = this._getScene(
              this.handleRandom(choice),
            );
          }
        }
      } else {
        this._currentScene = this._getScene(choice.sceneLink);
      }
    } else if (hasConditions) {
      const conditionHandler = new ConditionHandler(
        choice.conditions[0],
        this._variables,
        this._players.getPlayerById(this._activePlayerId)
      );

      if (answer !== "") {
        this._currentScene = this._getScene(
          conditionHandler.handleTextbox(answer)
        );
      } else {
        this._currentScene = this._getScene(conditionHandler.handleCondition());
      }
    }

    if (this._currentScene.objective !== null) {
      this._currentObjective = this._currentScene.objective;
    }

    if (
      this._currentScene.information !== null &&
      this._currentScene.information !== undefined
    ) {
      const { type, text } = this._currentScene.information;
      if (isNotEmptyString(type) && isNotEmptyString(text)) {
        const finalType =
          type === "hint" || type === "indice" ? strings.topMenu.hint : type;
        this.addInformation(new Information(finalType, text));
        newInfoCallback(true);
      }
    }

    this._handleVariables(
      newStateCallback,
      newSuccessCallback,
      newShowGroupCallback,
      newShowSecretCallback,
      newGameOverCallback,
      newVarCallback
    );

    if (this._currentScene.essentials !== null) {
      newEssentialCallback(true, this._currentScene.essentials);
    }

    this._visitedScenes.push(this._currentScene.number);
  }
  // #endregion

  /**
   *
   */
  public async goToSpecificScene(
    sceneId: number,
    newStateCallback = (_currVar: any) => {
      null;
    },
    newSuccessCallback = (_currVar: any) => {
      null;
    },
    newShowGroupCallback = (newVal: boolean) => {
      null;
    },
    newShowSecretCallback = (newVal: boolean) => {
      null;
    },
    newGameOverCallback = (newVal: boolean) => {
      null;
    },
    newEssentialCallback = (_showPopup: boolean, _essential: Array<any>) => {
      null;
    },
    newVarCallback = (_variable: any) => {
      null;
    },
    newInfoCallback = (_showPopup: boolean) => {
      null;
    }
  ): Promise<void> {
    if (this._lastSfx) {
      this._soundManager.removeSound(this._lastSfx.id);
      this._lastSfx = null;
    }

    if (this._lastLoop) {
      this._soundManager.removeSound(this._lastLoop.id);
      this._lastLoop = null;
    }

    this._currentScene = this._getScene(sceneId);

    if (this._currentScene.objective) {
      this._currentObjective = this._currentScene.objective;
    }

    if (this._currentScene.information) {
      const { type = undefined, text = undefined } = this._currentScene.information;
      if (isNotEmptyString(type) && isNotEmptyString(text)) {
        const finalType =
          type === "hint" || type === "indice" ? strings.topMenu.hint : type;
        this.addInformation(new Information(finalType, text));
        newInfoCallback(true);
      }
    }

    this._handleVariables(
      newStateCallback,
      newSuccessCallback,
      newShowGroupCallback,
      newShowSecretCallback,
      newGameOverCallback,
      newVarCallback
    );

    if (this._currentScene.essentials !== null) {
      newEssentialCallback(true, this._currentScene.essentials);
    }

    this._visitedScenes.push(this._currentScene.number);
  }

  public async shouldPlaySound(): Promise<any> {
    if (this._storyData) {
      const sounds = this._storyData.sounds.filter(
        (item: any) => item.passageId === this._currentScene.number
      );

      if (this._currentScene.ambianceStop === true) {
        if (this._lastAmbiance) {
          this._soundManager.removeSound(this._lastAmbiance.id);
          this._lastAmbiance = null;
        }
      }

      if (this._currentScene.musicStop === true) {
        if (this._lastMusic) {
          this._soundManager.removeSound(this._lastMusic.id);
          this._lastMusic = null;
        }
      }

      await Promise.all(
        sounds.map(async (sound: any) => {
          const url = getRouteManager().sound(sound.id);

          const soundName = sound.name;

          if (sound.type === "sfx" && !this._soundManager.isSfxMuted) {
            this._lastSfx = this._soundManager.addSound(
              soundName,
              url,
              "sfx",
              SoundManager.getInstance().getMaxVolume(),
            );
          } else if (sound.type === "loop" && !this._soundManager.isSfxMuted) {
            this._lastLoop = this._soundManager.addSound(
              soundName,
              url,
              "sfx",
              SoundManager.getInstance().getMaxVolume(),
              true
            );
          } else if (
            sound.type === "music" &&
            !this._soundManager.isMusicMuted
          ) {
            if (this._lastMusic) {
              this._soundManager.removeSound(this._lastMusic.id);
              this._soundManager.removeSoundsByCategory("music");
              this._lastMusic = null;
            }

            this._lastMusic = this._soundManager.addSound(
              soundName,
              url,
              "music",
              SoundManager.getInstance().getMaxVolume(),
              true
            );
          } else if (
            sound.type === "ambiance" &&
            !this._soundManager.isMusicMuted
          ) {
            if (this._lastAmbiance) {
              this._soundManager.removeSound(this._lastAmbiance.id);
              this._soundManager.removeSoundsByCategory("ambiance");
              this._lastAmbiance = null;
            }
            this._lastAmbiance = this._soundManager.addSound(
              soundName,
              url,
              "ambiance",
              SoundManager.getInstance().getMaxVolume(),
              true
            );
          }
        })
      );
    }
    return { shouldPlay: false };
  }

  /**
   *
   */
  public async postCreateUserSuccess(data: any): Promise<any> {
    const token = getRouteManager().getUser().getLoginToken();
    const api = getRouteManager().createUserSuccess();

    if (token != null) {
      const res = await fetch(`${api}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_token: token,
          userSuccess: {
            name: data.name,
          },
          lang: getLanguage(),
        }),
      });

      return res.json();
    }
  }

  /**
   *
   */
  public async postCreateUserSuccessForPlayerCode(
    data: any,
    code: any
  ): Promise<any> {
    const token = getRouteManager().getUser().getLoginToken();

    const apiCode = getRouteManager().createUserSuccessForPlayerCode();

    if (token != null) {
      const res = await fetch(`${apiCode}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_token: token,
          userSuccess: {
            name: data.name,
          },
          lang: getLanguage(),
          playerCode: code,
        }),
      });

      return res.json();
    }
  }

  // success trigger

  /**
   *
   */
  public async triggerSuccess(): Promise<any> {
    let cpt = 0;

    const successList: Array<any> = [];
    const type = this.getType();
    await Promise.all(
      this._players.players.map(async (player) => {
        if (type === "online") {
          if (player.avatar) {
            if (player.avatar.userId === getRouteManager().getUser().GetId()) {
              await Promise.all(
                this._variables.variables.map(async (v) => {
                  if (v.name.startsWith("var_Success")) {
                    if (v.value === 1) {
                      const json = await this.postCreateUserSuccess(v);

                      if (
                        json.flashmessage !== "error-user-success" &&
                        json.flashmessage !== "error"
                      ) {
                        //getEventManager().successUnlock(json.id);
                        successList.push(json);
                        cpt++;
                      }
                    }
                  }
                })
              );
            }
          } else {
            return;
          }
        } else {
          if (
            player.getPlayerCode() ===
            getRouteManager().getUser().getPlayerCode()
          ) {
            // Trigger success for the owner of the account
            await Promise.all(
              this._variables.variables.map(async (v) => {
                if (v.name.startsWith("var_Success")) {
                  if (v.value === 1) {
                    const json = await this.postCreateUserSuccess(v);

                    if (
                      json.flashmessage !== "error-user-success" &&
                      json.flashmessage !== "error"
                    ) {
                      //getEventManager().successUnlock(json.id);
                      successList.push(json);
                      cpt++;
                    }
                  }
                }
              })
            );
          } else {
            const code = player.getPlayerCode();

            if (code && code !== getRouteManager().getUser().getPlayerCode()) {
              await Promise.all(
                this._variables.variables.map(async (v) => {
                  if (v.name.startsWith("var_Success")) {
                    if (v.value === 1) {
                      //LA
                      const json =
                        await this.postCreateUserSuccessForPlayerCode(v, code);

                      if (
                        json.flashmessage !== "error-user-success" &&
                        json.flashmessage !== "error"
                      ) {
                        console.log(
                          "success unlocked via player code for other player"
                        );
                      }
                    }
                  }
                })
              );
            }
          }
        }
      })
    );

    return successList;
  }

  /**
   *
   */
  public async setUserStoryPlayed(): Promise<any> {
    const api = getRouteManager().setUserStoryPlayed();

    const token = getRouteManager().getUser().getLoginToken();

    if (token != null) {
      const res = await fetch(`${api}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_token: token,
          storyId: this.getStoryId(),
        }),
      });

      return res;
    }
  }

  /**
   * @handleRandom
   * Get scene to go from random choice
   */
  public handleRandom(choice: IChoice): any {
    // exemple random choix : 1 - 65, 2- 20, 3 - 15
    const list = [...choice.randomScenes];
    const random = Math.floor(Math.random() * 100) + 1;
    const defaultChoice = list[0].sceneLink;
    let percentToCheck = 0;
    let idToPick = null;
    for (let i = 0; i < list.length; i++) {
      percentToCheck += list[i].proba; // 1 - 65
      if (random <= percentToCheck) {
        // exemple : 1 - 92 <= 65 : false — 2- 92 <= 85 : false — 3- 92 <= 100 : true
        idToPick = list[i].sceneLink; // 3- liste[2].id
        break;
      }
    }
    if (idToPick != null) {
      return idToPick;
    } else {
      return defaultChoice;
    }
  }

  public handleRandomWithModal(choice: IChoice, random: number): any {
    // exemple random choix : 1 - 65, 2- 20, 3 - 15
    const list = [...choice.randomScenes];
    const isSuccess = choice.diceNumber && random > choice.diceNumber;
    const isFirst = choice.diceProbaType === 'first';
    let idToPick = null;
    idToPick = isSuccess
      ? isFirst
        ? list[0].sceneLink
        : list[1].sceneLink
      : isFirst
        ? list[1].sceneLink
        : list[0].sceneLink;

    const defaultChoice = list[0].sceneLink;
    if (idToPick != null) {
      return idToPick;
    } else {
      return defaultChoice;
    }
  }

  // #region Report

  /**
   *
   */
  public async calculateReport(): Promise<any> {
    // Increment story trigger
    //this.setAskExit(false);

    await fetch(`${getRouteManager().incrementStart(this._storyId)}`);

    const events: Array<any> = await this.getData(
      `${getRouteManager().eventsForStory(this._storyId)}`
    );

    const varsToReturn = [];

    for (let i = 0; i < events.length; i += 1) {
      const currEvent = events[i];
      const currIssues = currEvent.issues;

      for (let j = 0; j < currIssues.length; j += 1) {
        const currIssue = currIssues[j];
        const issueName = currIssue.name;
        const issueVar = this._variables.getVariableByName(issueName);

        if (issueVar !== null && issueVar !== undefined) {
          if (issueVar.value === 1) {
            varsToReturn.push(issueName);
            // eslint-disable-next-line no-await-in-loop
            await fetch(`${getRouteManager().incrementTrigger(currIssue.id)}`);
          }
        }
      }
    }

    return varsToReturn;
  }
}

export default StoryReader;
