import { ReactComponent as Discord } from "../../../assets/icons/icon_discord.svg";
import { ReactComponent as Instagram } from "../../../assets/icons/icon_insta.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/icon_twitter.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/icon_fb.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/icon_youtube.svg";
import { Box, ButtonBase, Typography } from "@mui/material";
import { strings } from "../../../services/translation";
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnStartStyles, rowStartStyles } from "../../../style/flex";
import { getRouteManager } from "../../../services/routeManager";
import useAuth from "../../../services/Auth/useAuth";

const Socials = () => {
  const auth = useAuth();

  const unlockMissionForUser = async () => {
    try {
      const api = getRouteManager().unlockMissionForUser();
      return await fetch(api, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_token: auth.user.login_token,
          missionId: 40,
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clickBtn = async (type: string) => {
    await unlockMissionForUser();
    switch (type) {
      case "discord":
        window.location.href = strings.socials.discord;
        break;
      case "insta":
        window.location.href = strings.socials.insta;
        break;
      case "twitter":
        window.location.href = strings.socials.twitter;
        break;
      case "facebook":
        window.location.href = strings.socials.facebook;
        break;
      case "youtube":
        window.location.href = strings.socials.youtube;
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={styles.container}>
      <ButtonBase onClick={() => clickBtn("discord")} style={styles.link}>
        <Discord width={45} height={45} />
        <Box sx={styles.text}>
          <Typography style={styles.socialTitle}>Discord</Typography>
          <Typography style={styles.socialSub}>{strings.discordSub}</Typography>
        </Box>
      </ButtonBase>
      <ButtonBase onClick={() => clickBtn("insta")} style={styles.link}>
        <Instagram width={45} height={45} />
        <Box sx={styles.text}>
          <Typography style={styles.socialTitle}>Instagram</Typography>
          <Typography style={styles.socialSub}>
            {strings.instagramSub}
          </Typography>
        </Box>
      </ButtonBase>
      <ButtonBase onClick={() => clickBtn("twitter")} style={styles.link}>
        <Twitter width={45} height={45} />
        <Box sx={styles.text}>
          <Typography style={styles.socialTitle}>Twitter</Typography>
          <Typography style={styles.socialSub}>{strings.twitterSub}</Typography>
        </Box>
      </ButtonBase>
      <ButtonBase onClick={() => clickBtn("facebook")} style={styles.link}>
        <Facebook width={45} height={45} />
        <Box sx={styles.text}>
          <Typography style={styles.socialTitle}>Facebook</Typography>
          <Typography style={styles.socialSub}>{strings.fbSub}</Typography>
        </Box>
      </ButtonBase>
      <ButtonBase onClick={() => clickBtn("youtube")} style={styles.link}>
        <Youtube width={45} height={45} />
        <Box sx={styles.text}>
          <Typography style={styles.socialTitle}>Youtube</Typography>
          <Typography style={styles.socialSub}>{strings.ytSub}</Typography>
        </Box>
      </ButtonBase>
    </Box>
  );
};

const styles = {
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    flex: 1,
  },
  link: {
    ...rowStartStyles,
    marginTop: "30px",
  },
  text: {
    marginLeft: "18px",
    ...columnStartStyles,
    alignItems: "flex-start",
  },
  socialTitle: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginBottom: "5px",
  },
  socialSub: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.GRAY_TEXT,
  },
};

export default Socials;
