/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import TokenAnimated from "../TokenAnimated";
import TicketPromoAnimated from "../TicketPromoAnimated";
import TimerDate from "../TimerDate";
import { Box, Typography } from "@mui/material";
import DynamicTag from "../DynamicTag";

interface Props {
  title: any;
}

const PromoStoryShop = (props: Props) => {
  const { title } = props;

  return (
    <>
      <Box sx={styles.priceContainer}>
        <Box style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <DynamicTag
            text={strings.promo}
            style={{
              alignSelf: "flex-start",
              backgroundColor: Colors.BTN_ACTIVE,
              color: Colors.PROMO,
              marginBottom: "10px",
              marginRight: '5px',
            }}
            textColor={Colors.PROMO}
          />
          {title.isSaga ? (
            <DynamicTag
              text={strings.saga}
              style={{
                alignSelf: "flex-start",
                backgroundColor: Colors.BTN_ACTIVE,
                color: Colors.vertALEXTESGRANDSMORTS,
                marginBottom: "10px",
              }}
              textColor={Colors.vertALEXTESGRANDSMORTS}
            />
          ) : title.nbEpisodes ? (
            <DynamicTag
              text={strings.series}
              style={{
                alignSelf: "flex-start",
                backgroundColor: Colors.BTN_ACTIVE,
                marginBottom: "10px",
              }}
            />
          ) : null}
        </Box>

        <Typography style={styles.socialTitle}>{title.title}</Typography>
        {!title.owned && (
          <Box sx={styles.storyPriceBlock}>
            {title.buyWithTokens ? (
              <>
                <Box sx={styles.tokensOwned}>
                  <TokenAnimated style={{ width: 25, height: 25 }} />
                  <Typography style={styles.tokensAmountOwned}>
                    {title.priceTokens}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "2px",
                    height: "30px",
                    backgroundColor: Colors.FOND_ITEM_POPUP,
                    borderRadius: "3px",
                    opacity: 0.7,
                    margin: "0px 15px",
                  }}
                />
              </>
            ) : null}

            <TicketPromoAnimated style={{ width: 25, height: 25 }} />
            <Typography
              sx={[
                styles.storyPrice,
                {
                  color: Colors.OLD_PRICE,
                  textDecorationLine: "line-through",
                  textDecorationStyle: "solid",
                  fontSize: normalize(16),
                },
              ]}
            >
              {title.price}
            </Typography>
            <Typography sx={styles.storyPrice}>
              {Math.ceil(title.price * ((100 - title.promo.discount) / 100))}
            </Typography>
          </Box>
        )}
      </Box>

      {title.promo.endDate ? (
        <TimerDate
          toDate={title.promo.endDate}
          textColor={Colors.WHITE}
          textGradient={false}
          style={styles.promoTimer}
          toHour={23}
          toMinute={59}
          toSecond={59}
        />
      ) : null}
    </>
  );
};

const styles = {
  socialTitle: {
    fontSize: normalize(22),
    lineheight: '26px',
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginBottom: "5px",
  },
  promoTag: {
    display: "flex",
    flexDirection: "row",
    padding: "7px 13px",
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "7px",
    alignSelf: "flex-start",
    marginBottom: "5px",
  },
  promoTagText: {
    color: Colors.PROMO,
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
  },
  promoTagTextTimer: {
    color: Colors.WHITE,
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  storyPriceBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  storyPrice: {
    fontSize: normalize(21),
    fontFamily: "Gilroy-Bold",
    color: Colors.PROMO,
    marginLeft: "7px",
  },
  tokensOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "6px",
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokensAmountOwned: {
    fontSize: normalize(21),
    fontFamily: "Gilroy-Bold",
    color: Colors.TOKEN,
    marginLeft: "3px",
  },
  promoTimer: {
    backgroundColor: Colors.BTN_ACTIVE,
    position: "absolute",
    flexDirection: "row",
    padding: "7px 13px",
    borderRadius: "7px",
    top: 10,
    right: 10,
  },
};

export default PromoStoryShop;
