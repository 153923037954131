import { Box, ButtonBase, Typography } from "@mui/material";
import { Story } from "../../../models/story";
import { styles } from "../styles";
import GradientText from "../../../components/GradientText";
import { Colors } from "../../../style";
import { MAIN_QUEST_LEVELS } from "../../../constants";
import { getLanguage, strings } from "../../../services/translation";
import { getRouteManager } from "../../../services/routeManager";
import StoryInfoModal from "../../../components/StoryInfoModal";
import { useState } from "react";
import SoundManager from "../../../services/SoundManager";
import { useNavigate } from "react-router";
import { capitalize } from "lodash";
import { ReactComponent as IconLock } from "../../../assets/icons/icon_lock.svg";
import useAuth from "../../../services/Auth/useAuth";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const MainStoryBox = ({
    story,
    unlocked,
    index,
}: {
    story: Story;
    unlocked: boolean;
    index: number;
}) => {
    /**
     *
     * STATES
     */

    if (story?.storyTrads?.length) {
        story.title = story.storyTrads[0].title;
        story.resume = story.storyTrads[0].resume;
        story.intro = story.storyTrads[0].intro;
        story.storyTrad = story.storyTrads[0];
    }
    story.image_backdrop = getRouteManager().cover(story.id);
    story.image_title = getRouteManager().titleForStory(
        getLanguage(),
        story.id,
    );
    const auth = useAuth();
    const navigate = useNavigate();
    const [showStoryInfo, setShowStoryInfo] = useState(false);

    /**
     *
     * FUNCTIONS
     *
     */
    const closeModal = () => {
        setShowStoryInfo(false);
        SoundManager.getInstance().playCloseModalSound();
    };

    const goNext = () => {
        setShowStoryInfo(false);
        if (unlocked) {
            launchEpisode();
        }
    };

    const getUserAsPlayer = () => {
        if (auth?.user?.avatar) {
            return {
                idx: 0,
                key: 0,
                name: capitalize(auth.user.pseudo.substring(0, 12).trim()),
                avatar: auth.user.avatar,
                letter: capitalize(auth.user.pseudo.substring(0, 12).trim())[0],
                letterColor: Colors.PRIMARY,
                playerCode: auth.user.player_code,
                letterBorderColor: Colors.WHITE,
                title: auth.user && auth.user.title ? auth.user.title : null,
            };
        } else {
            return null;
        }
    };

    const launchEpisode = async () => {
        //navigate(navigatePathname, { replace: true });
        const player = getUserAsPlayer();
        if (player) {
            SoundManager.getInstance().removeAllSounds();
            navigate("/game", {
                state: {
                    players: [player],
                    storyId: story.id,
                    title: story.title,
                    save: null,
                    episode: null,
                    solo: true,
                    isFTUE: story?.isFTUE,
                    isBoard: false,
                },
            });
        }
    };

    const goToLevels = () => {
        navigate("/rewards");
    };

    return (
        <Box>
            <ButtonBase
                sx={[styles.storyContainer]}
                //disabled={!unlocked}
                onClick={() =>
                    unlocked ? setShowStoryInfo(true) : goToLevels()
                }>
                <Box
                    sx={[
                        styles.imageBackgroundContainer,
                        {
                            background: `linear-gradient(90deg, #25262F 0%, rgba(0, 0, 0, 0.00) 100%), no-repeat 100% 55%/100% url(${story.image_backdrop}), lightgray 50% / cover no-repeat`,
                        },
                    ]}
                />
                {unlocked ? (
                    <Box
                        sx={[
                            styles.imageBackgroundLeftContainer,
                            {
                                background: `url(${require("../../../assets/images/FTUE_BG_unlocked.png")})`,
                            },
                        ]}
                    />
                ) : (
                    <Box
                        sx={[
                            styles.imageBackgroundLeftContainer,
                            {
                                background: `url(${require("../../../assets/images/FTUE_BG_locked.png")})`,
                            },
                        ]}></Box>
                )}
                {unlocked ? (
                    <Box sx={[styles.titleContainer]}>
                        {story?.title ? (
                            <GradientText
                                color1={Colors.WHITE}
                                color2={Colors.FTUETEXT}
                                style={styles.gradientTitle}>
                                {story.title}
                            </GradientText>
                        ) : null}
                        <Typography sx={styles.subtitleUnlocked}>
                            {strings.mainQuest.unlocked}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={[styles.titleContainerLocked]}>
                        <IconLock width={40} height={40} fill={Colors.ACCENT} />
                        <Typography
                            sx={
                                styles.subtitleLocked
                            }>{`${strings.mainQuest.unlockAtLevel} ${MAIN_QUEST_LEVELS[index]}`}</Typography>
                    </Box>
                )}
            </ButtonBase>
            <StoryInfoModal
                active={story}
                showStoryInfo={showStoryInfo}
                onCloseStoryInfo={closeModal}
                callback={() => {
                    setShowStoryInfo(false);
                    goNext();
                }}
            />
        </Box>
    );
};
