/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { Referral } from "../../../../models/referral";
import { styles } from "../styles";
import { Player } from "@lottiefiles/react-lottie-player";
import { levelRewardWaiting } from "../../../../constants/levelRewardWaiting";
import ColorManager from "../../../../services/colorManager";
import { Colors } from "../../../../style";
import Separator from "../../../../components/Separator";
import AvatarContainer from "../../../../components/AvatarContainer";
import { rowStartStyles } from "../../../../style/flex";
import { ReferralBoxEmpty } from "../ReferralBoxEmpty";
import { isNotEmptyString } from "../../../../utils/StringHelper";
import { ReactComponent as IconXp } from "../../../../assets/icons/icon_xp.svg";
import { ReactComponent as CompleteIcon } from "../../../../assets/icons/icon_complete.svg";

export const ReferralBox = ({
    referral,
    callback,
    disabled,
}: {
    referral: Referral;
    callback: any;
    disabled: boolean;
}) => {
    const getAnimWithColors = () => {
        let anim = levelRewardWaiting;
        anim = ColorManager.getInstance().changeAnimColors(
            anim,
            [Colors.BTN_ACTIVE],
            [Colors.PRIMARY],
        );
        return anim;
    };

    const { user } = referral;

    if (user) {
        return (
            <ButtonBase
                sx={styles.referralBox}
                disabled={disabled}
                onClick={() => !disabled && callback(referral)}>
                <Box
                    sx={[
                        styles.rewardBox,
                        referral?.status === 1 || !referral.rewardId
                            ? { backgroundColor: "transparent" }
                            : {},
                    ]}>
                    {referral?.status === 0 && referral?.rewardId ? (
                        <Player
                            src={getAnimWithColors()}
                            autoplay
                            loop
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    ) : referral?.status === 1 ? (
                        <CompleteIcon width={25} height={25} />
                    ) : null}
                </Box>
                {referral?.rewardId ? (
                    <Separator style={styles.separator} />
                ) : null}
                <Box sx={styles.userBoxInside}>
                    <AvatarContainer
                        persoWidth={60}
                        user={user}
                        borderWidth={0}
                    />
                    <Box sx={styles.userText}>
                        <Typography component={"span"} sx={styles.userPseudo}>
                            {user?.pseudo ? user.pseudo : ""}
                        </Typography>
                        <Typography sx={styles.userTitle} component={"span"}>
                            {user?.title
                                ? isNotEmptyString(user?.title)
                                    ? user.title
                                    : user.title?.name
                                : ""}
                        </Typography>
                        <Box sx={styles.levelPart}>
                            <Box style={styles.levelCircle}>
                                <Typography style={styles.levelText}>
                                    {user.level ? user.level : "?"}
                                </Typography>
                            </Box>
                            <Box sx={{ ...rowStartStyles }}>
                                <IconXp width={25} height={25} />
                                <Typography sx={styles.xp}>
                                    {user.experience}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ButtonBase>
        );
    }
    return <ReferralBoxEmpty />;
};
