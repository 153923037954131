/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { db } from "../../utils/FirebaseHelper";
import { FIREBASE_DATABASE } from "../../constants";
import { Player } from "../../services/StoryReader/players";
import { Box, ButtonBase, InputBase, Slide, Typography } from "@mui/material";
import { columnCenterStyles, columnStyles } from "../../style/flex";
import OptionBtn from "../../pages/Game/TopMenu/optionBtn";
import ColorManager from "../../services/colorManager";
import SoundManager from "../../services/SoundManager";
import { centerStyles } from "../Choices/styles";
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import { strings } from "../../services/translation";
import Message from "./message";
import Animation from "../Animation";

/* ********** ICONS ********** */
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/icon_next.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/icon_chat.svg";

// Structure de données du message
interface ChatMessage {
    id: string;
    gameID: string;
    playerID: string;
    avatar: string;
    name: string;
    message: string;
    timestamp: number;
}

const chatColors = [
    {
        value: Colors.PRIMARY,
        type: "primary",
    },
    {
        value: Colors.BTN_ACTIVE,
        type: "secondary",
    },
    {
        value: Colors.ACCENT,
        type: "tertiary",
    },
    {
        value: Colors.OLD_PRICE,
        type: "quaternary",
    },
    {
        value: Colors.BTN_ACTIVE,
        type: "quinary",
    },
];

const GameChat: React.FC<{
    gameID: string;
    player: Player | undefined;
    backgroundUrl?: string | null;
    containerRef?: any;
    dateColor?: string;
}> = ({
    gameID,
    player,
    backgroundUrl,
    containerRef,
    dateColor = undefined,
}) => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [newMessage, setNewMessage] = useState("");
    const messagesRef = useRef<HTMLDivElement>(null);
    const [hasScroll, setHasScroll] = useState(false);

    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    const [isChatOpen, _setIsChatOpen] = useState<boolean>(false);
    const isChatOpenRef = useRef<boolean>(isChatOpen);
    const setIsChatOpen = (data: boolean) => {
        isChatOpenRef.current = data;
        _setIsChatOpen(data);
    };
    const scrollCheckTimer = useRef<any>(null);

    // Vérifie si l'utilisateur est en haut ou en bas de la liste
    const checkScrollPosition = (scrolling?: boolean) => {
        if (messagesRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                messagesRef.current;
            //setIsAtBottom(scrollTop + clientHeight >= scrollHeight);
            setShowScrollButton(
                (!isScrolling || !scrolling) &&
                    scrollTop + clientHeight < scrollHeight,
            );
        }
    };

    useLayoutEffect(() => {
        // Faire défiler vers le bas après le rendu initial des messages
        if (
            messagesRef.current &&
            !hasScroll &&
            messages.length &&
            isChatOpenRef.current
        ) {
            scrollToBottom();
            setHasScroll(true);
        }
    }, [messages, hasScroll, isChatOpen]);

    // Fait défiler vers le bas
    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        // Référence à la liste des messages spécifique au jeu
        const chatRef = db.ref(
            `/${FIREBASE_DATABASE.REFERENCES.GAME_CHATS}/${gameID}`,
        );
        chatRef.on("value", (snapshot) => {
            if (snapshot.exists()) {
                const messageData = snapshot.val();
                const messageList: ChatMessage[] = [];
                for (const key in messageData) {
                    if (messageData[key]) {
                        messageList.push(messageData[key]);
                    }
                }
                // Tri des messages par ordre chronologique
                messageList.sort((a, b) => a.timestamp - b.timestamp);
                setMessages(messageList);
                if (!isChatOpenRef.current) {
                    setHasUnreadMessages(true);
                } else {
                    checkScrollPosition();
                }
                // Vérifie si l'utilisateur est en bas de la liste après l'arrivée de nouveaux messages
            } else {
                setMessages([]);
            }
        });

        // Nettoyage de l'écouteur lors du démontage du composant
        return () => {
            chatRef.off("value");
        };
    }, [gameID]);

    useLayoutEffect(() => {
        // Faire défiler vers le bas après le rendu des messages
        if (messagesRef.current) {
            const tmpMsgs = [...messages];
            const { scrollHeight, clientHeight } = messagesRef.current;
            const scrollOffset = scrollHeight - clientHeight;
            const isScrolledToBottom =
                messagesRef.current.scrollTop >= scrollOffset - 140;
            if (
                isScrolledToBottom ||
                tmpMsgs.pop()?.playerID === player?.playerCode
            ) {
                messagesRef.current.scrollTop = scrollOffset;
            }
        }
    }, [messages]);

    // Envoi d'un nouveau message
    const sendMessage = () => {
        if (player && player.playerCode) {
            const message: ChatMessage = {
                id: Date.now().toString(),
                gameID: gameID,
                playerID: player.playerCode ? player.playerCode : "",
                avatar: player.avatar, // Ajoutez le chemin vers l'avatar du joueur ici
                name: player.name, // Ajoutez le nom du joueur ici
                message: newMessage,
                timestamp: Date.now(),
            };
            // Enregistrement du message dans la base de données
            db.ref(
                `/${FIREBASE_DATABASE.REFERENCES.GAME_CHATS}/${gameID}/${message.id}`,
            ).set(message);
            setNewMessage("");
        }
    };

    // Fait défiler vers le bas lors du clic sur le bouton "Revenir en bas"
    const handleScrollToBottom = () => {
        setIsScrolling(true);
        scrollToBottom();
    };

    // Gère l'événement de fin de défilement
    const handleScrollEnd = () => {
        setIsScrolling(true);
        if (scrollCheckTimer.current) {
            clearTimeout(scrollCheckTimer.current);
        }

        // Utilise un temporisateur pour vérifier la position de défilement après un court délai
        scrollCheckTimer.current = setTimeout(() => {
            setIsScrolling(false);
            checkScrollPosition(false);
        }, 200); // Définissez le délai en millisecondes selon vos besoins
    };

    /**
     * Triggered when player taps on the `top menu` button
     */
    const toggleChat = (newVal: boolean): void => {
        setIsChatOpen(newVal);
        if (!newVal) {
            setHasUnreadMessages(false);
            setHasScroll(false);
            SoundManager.getInstance().playTopMenuCloseSound();
        } else {
            SoundManager.getInstance().playTopMenuSound();
        }
    };

    return (
        <>
            <ButtonBase
                onClick={() => toggleChat(!isChatOpenRef.current)}
                style={{
                    alignItems: "center",
                    width: "48px",
                    position: "absolute",
                    top: "30px",
                    right: "35px",
                    borderRadius: "48px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    zIndex: 300,
                }}>
                {hasUnreadMessages ? (
                    <Box
                        sx={[
                            styles.unreadMessages,
                            {
                                backgroundColor:
                                    ColorManager.getInstance()?.getColor(
                                        "tertiary",
                                    )
                                        ? ColorManager.getInstance().getColor(
                                              "tertiary",
                                          )
                                        : chatColors[2],
                            },
                        ]}
                    />
                ) : null}
                <ChatIcon
                    width={48}
                    height={48}
                    fill={
                        ColorManager.getInstance()?.getColor("secondary")
                            ? ColorManager.getInstance().getColor("secondary")
                            : chatColors[1]
                    }
                />
            </ButtonBase>
            <Slide
                style={{ position: "absolute", right: 0 }}
                direction="left"
                in={isChatOpenRef.current}
                mountOnEnter
                unmountOnExit
                container={containerRef}
                timeout={400}>
                <Box
                    sx={[
                        {
                            backgroundColor:
                                ColorManager.getInstance()?.getColor("primary")
                                    ? ColorManager.getInstance().getColor(
                                          "primary",
                                      )
                                    : chatColors[0],
                        },
                        styles.chatContainer,
                    ]}>
                    <Box sx={styles.chatContainerInside}>
                        {backgroundUrl ? (
                            <Box sx={styles.lottieContainer}>
                                <Animation
                                    src={backgroundUrl}
                                    style={styles.lottie}
                                />
                            </Box>
                        ) : null}

                        <Box
                            sx={[
                                styles.menuBtns,
                                {
                                    backgroundColor:
                                        ColorManager.getInstance()?.getColor(
                                            "primary",
                                        )
                                            ? ColorManager.getInstance().getColor(
                                                  "primary",
                                              )
                                            : chatColors[0],
                                },
                            ]}>
                            <Box width={"48px"} />
                            <Box style={styles.logbook}>
                                <Typography
                                    sx={[
                                        styles.logbookText,
                                        {
                                            color: ColorManager.getInstance()?.getColor(
                                                "tertiary",
                                            )
                                                ? ColorManager.getInstance().getColor(
                                                      "tertiary",
                                                  )
                                                : chatColors[2],
                                        },
                                    ]}>
                                    {strings.topMenu.chat}
                                </Typography>
                            </Box>
                            <OptionBtn
                                icon={
                                    <CloseIcon
                                        width={48}
                                        height={48}
                                        fill={
                                            ColorManager.getInstance()?.getColor(
                                                "quinary",
                                            )
                                                ? ColorManager.getInstance().getColor(
                                                      "quinary",
                                                  )
                                                : chatColors[4]
                                        }
                                    />
                                }
                                style={{ borderRadius: "25px" }}
                                onPress={() =>
                                    toggleChat(!isChatOpenRef.current)
                                }
                            />
                        </Box>
                        <Box
                            sx={[
                                styles.separator,
                                {
                                    backgroundColor:
                                        ColorManager.getInstance()?.getColor(
                                            "quinary",
                                        )
                                            ? ColorManager.getInstance().getColor(
                                                  "quinary",
                                              )
                                            : chatColors[4],
                                },
                            ]}
                        />
                        <Box
                            ref={messagesRef}
                            onScroll={handleScrollEnd}
                            sx={[
                                styles.chatMessages,
                                hasScroll
                                    ? {
                                          scrollBehavior: "smooth",
                                      }
                                    : {},
                                {
                                    "::-webkit-scrollbar": {
                                        width: "5px",
                                    },
                                    "::-webkit-scrollbar-track": {
                                        background:
                                            ColorManager.getInstance()?.getColor(
                                                "quinary",
                                            )
                                                ? ColorManager.getInstance().getColor(
                                                      "quinary",
                                                  )
                                                : chatColors[4],
                                        borderRadius: "5px",
                                    },

                                    "::-webkit-scrollbar-thumb": {
                                        background:
                                            ColorManager.getInstance()?.getColor(
                                                "secondary",
                                            )
                                                ? ColorManager.getInstance().getColor(
                                                      "secondary",
                                                  )
                                                : chatColors[1],
                                        borderRadius: "5px",
                                    },

                                    "::-webkit-scrollbar-thumb:hover": {
                                        background:
                                            ColorManager.getInstance()?.getColor(
                                                "quaternary",
                                            )
                                                ? ColorManager.getInstance().getColor(
                                                      "quaternary",
                                                  )
                                                : chatColors[3],
                                    },
                                },
                            ]}>
                            {messages.map((message, index: number) => (
                                <Message
                                    message={message}
                                    player={player}
                                    key={message.id}
                                    lastMessage={
                                        index !== 0
                                            ? messages[index - 1]
                                            : undefined
                                    }
                                    dateColor={
                                        dateColor ? dateColor : undefined
                                    }
                                />
                            ))}
                        </Box>
                        {showScrollButton ? (
                            <ButtonBase
                                sx={[styles.scrollBtn]}
                                onClick={handleScrollToBottom}>
                                <NextIcon
                                    width={45}
                                    height={45}
                                    fill={
                                        ColorManager.getInstance()?.getColor(
                                            "quinary",
                                        )
                                            ? ColorManager.getInstance().getColor(
                                                  "quinary",
                                              )
                                            : chatColors[4]
                                    }
                                />
                            </ButtonBase>
                        ) : null}
                        <Box
                            sx={[
                                styles.chatInputContainer,
                                {
                                    backgroundColor:
                                        ColorManager.getInstance()?.getColor(
                                            "secondary",
                                        )
                                            ? ColorManager.getInstance().getColor(
                                                  "secondary",
                                              )
                                            : chatColors[1],
                                },
                            ]}>
                            <InputBase
                                sx={[
                                    styles.chatInput,
                                    {
                                        color: Colors.WHITE,
                                        "::placeholder": {
                                            color: Colors.WHITE,
                                        },
                                    },
                                ]}
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyUp={(event: any) =>
                                    event.key && event.key === "Enter"
                                        ? sendMessage()
                                        : null
                                }
                                placeholder={strings.actions.enterMessage}
                            />
                            <ButtonBase
                                sx={[styles.chatInputBtn]}
                                onClick={sendMessage}>
                                <NextIcon
                                    width={45}
                                    height={45}
                                    fill={
                                        ColorManager.getInstance()?.getColor(
                                            "quinary",
                                        )
                                            ? ColorManager.getInstance().getColor(
                                                  "quinary",
                                              )
                                            : chatColors[4]
                                    }
                                />
                            </ButtonBase>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </>
    );
};

const styles = {
    chatContainer: {
        position: "absolute",
        right: 0,
        width: "27.77vw",
        minWidth: "400px",
        maxWidth: "500px",
        height: "calc(100% - 40px)",
        margin: "20px 0px",
        zIndex: 901,
        borderRightWidth: "0px",
        borderRadius: "20px 0px 0px 20px",
        boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.25)",
        overflow: "hidden",
    },
    unreadMessages: {
        position: "absolute",
        top: 0,
        right: 0,
        backgroundColor: Colors.ERROR,
        width: "10px",
        height: "10px",
        borderRadius: "10px",
        padding: "0px 3px",
    },
    unreadMessagesNumber: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
    },
    chatContainerInside: {
        ...columnStyles,
        height: "100%",
        width: "100%",
        flex: 1,
        position: "relative",
        top: 0,
    },
    lottieContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
        ...columnCenterStyles,
        overflow: "hidden",
        zIndex: 1,
        opacity: 0.5,
    },
    lottie: {
        height: `${window.innerHeight - 40}px`,
        overflow: "hidden",
    },
    menuBtns: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 15px",
        paddingTop: "20px",
        zIndex: 2,
    },
    logbook: {
        display: "flex",
        flexDirection: "row",
        ...centerStyles,
    },
    logbookText: {
        fontSize: normalize(16),
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        marginLeft: "10px",
    },
    separator: {
        width: "100%",
        height: "2px",
        backgroundColor: Colors.WHITE,
        alignSelf: "center",
    },
    chatMessages: {
        ...columnStyles,
        flex: 1,
        overflowY: "auto",
        padding: "10px",
        zIndex: 2,
    },
    chatInputContainer: {
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        marginBottom: "15px",
        width: "90%",
        padding: "10px",
        borderRadius: "100px",
        backgroundColor: "#F5F5F5",
        zIndex: 2,
    },
    chatInput: {
        flex: 1,
        padding: "5px 5px 5px 10px",
        border: "none",
        borderRadius: "5px",
        fontFamily: "Gilroy-Bold",
    },
    chatInputBtn: {
        width: "45px",
        height: "45px",
        marginLeft: "10px",
        border: "none",
        borderRadius: "45px",
        cursor: "pointer",
    },
    scrollBtn: {
        transform: "rotate(90deg)",
        position: "fixed",
        bottom: "115px",
        alignSelf: "center",
        border: "none",
        borderRadius: "50px",
        cursor: "pointer",
        fontFamily: "Gilroy-Bold",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        zIndex: 2,
    },
};

export default GameChat;
