/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from "react";
import { Box, ButtonBase, Modal, Switch, Typography } from "@mui/material";
import {
  columnStyles,
  rowBetweenStyles,
  rowCenterStyles,
  rowStartStyles,
  rowStyles,
} from "../../style/flex";
import { Eczar, Gilroy } from "../../style/fonts";
import { Colors } from "../../style";
import { strings } from "../../services/translation";
import Cookies from "js-cookie";
import { CookiesContext } from "../../utils/ContextsHelper";

const ConsentBanner = ({
  isOpen = false,
  callback,
}: {
  isOpen?: boolean;
  callback: any | undefined;
}) => {
  const { cookieAudience, cookieUsage, updateCookies } =
    useContext(CookiesContext);

  const [chooseAll, setChooseAll] = useState(false);
  const [audienceAdvertising, setAudienceAdvertising] =
    useState(cookieAudience);
  const [usage, setUsage] = useState(cookieUsage);

  const handleAccept = () => {
    // Enregistrer le choix de l'utilisateur et activer Firebase Analytics si besoin
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 6);
    Cookies.set("cookiesSet", "given", { expires: expirationDate });
    Cookies.set("usage", JSON.stringify(usage), { expires: expirationDate });
    Cookies.set("audience", JSON.stringify(audienceAdvertising), {
      expires: expirationDate,
    });
    updateCookies(true, usage, audienceAdvertising);
    callback();
  };

  const handleAcceptAll = () => {
    // Enregistrer le choix de l'utilisateur et activer Firebase Analytics
    setAudienceAdvertising(true);
    setUsage(true);
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 6);
    Cookies.set("cookiesSet", "given", { expires: expirationDate });
    Cookies.set("usage", JSON.stringify(true), { expires: expirationDate });
    Cookies.set("audience", JSON.stringify(true), {
      expires: expirationDate,
    });
    updateCookies(true, true, true);
    callback();
  };

  const handleChoose = () => {
    // Afficher les options de choix pour chaque ligne
    setChooseAll(true);
  };

  return (
    <Modal open={isOpen}>
      <Box sx={[styles.container, { height: chooseAll ? "520px" : "420px" }]}>
        {/* <GradientText
            color1={Colors.ACCENT}
            color2={Colors.HEART}
            style={styles.title}
          >
            Préférences sur les cookies
          </GradientText> */}

        <Box sx={styles.textContainer}>
          <Typography sx={styles.title}>
            {strings.cookiesBanner.title}
          </Typography>
        </Box>
        {chooseAll ? (
          <Box sx={styles.chooseContainer}>
            <Box sx={styles.infoBox}>
              <Typography sx={styles.infoTitle}>
                {strings.cookiesBanner.audience}
              </Typography>
              <Typography sx={styles.infoSubtitle}>
                {strings.cookiesBanner.audienceSub}
              </Typography>
              <Box sx={styles.switchBox}>
                <Typography sx={styles.infoSubtitle2}>
                  {strings.cookiesBanner.firebase}
                </Typography>

                <Box sx={styles.switchRow}>
                  <Typography sx={styles.infoSubtitle2}>
                    {strings.cookiesBanner.decline}
                  </Typography>
                  <Switch
                    sx={{
                      color: audienceAdvertising ? Colors.ACCENT : "#767577",
                      "& .MuiSwitch-thumb": {
                        backgroundColor: audienceAdvertising
                          ? "#f4f3f4"
                          : "#f4f3f4",
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: audienceAdvertising
                          ? Colors.ACCENT + "!important"
                          : "#767577" + "!important",
                        opacity: audienceAdvertising ? 0.9 + "!important" : 0.5,
                      },
                    }}
                    onChange={() =>
                      setAudienceAdvertising(!audienceAdvertising)
                    }
                    checked={audienceAdvertising}
                  />
                  <Typography
                    sx={[
                      styles.infoSubtitle2,
                      audienceAdvertising && { color: Colors.ACCENT },
                    ]}
                  >
                    {strings.cookiesBanner.accept}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.infoBox}>
              <Typography sx={styles.infoTitle}>
                {strings.cookiesBanner.usage}
              </Typography>
              <Typography sx={styles.infoSubtitle}>
                {strings.cookiesBanner.usageSub}
              </Typography>
              <Box sx={styles.switchBox}>
                <Typography sx={styles.infoSubtitle2}>
                  {strings.cookiesBanner.all}
                </Typography>
                <Box sx={styles.switchRow}>
                  <Typography sx={styles.infoSubtitle2}>
                    {strings.cookiesBanner.interest}
                  </Typography>
                  <Switch
                    sx={{
                      color: usage ? Colors.ACCENT : "#767577",
                      "& .MuiSwitch-thumb": {
                        backgroundColor: usage ? "#f4f3f4" : "#f4f3f4",
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: usage
                          ? Colors.ACCENT + "!important"
                          : "#767577" + "!important",
                        opacity: usage ? 0.9 + "!important" : 0.5,
                      },
                    }}
                    onChange={() => setUsage(!usage)}
                    checked={usage}
                  />
                  <Typography
                    sx={[
                      styles.infoSubtitle2,
                      usage && { color: Colors.ACCENT },
                    ]}
                  >
                    {strings.cookiesBanner.accept}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <ButtonBase
              onClick={() => {
                window.open(strings.confiUrl, "_blank")?.focus();
              }}
            >
              <Typography
                sx={[
                  styles.subtitle,
                  {
                    color: Colors.ACCENT,
                    textDecoration: "underline",
                    margin: "10px 0px",
                  },
                ]}
              >
                {strings.cookiesBanner.policy}
              </Typography>
            </ButtonBase>
          </Box>
        ) : (
          <Box sx={styles.chooseContainer}>
            <Typography sx={styles.subtitle}>
              {strings.cookiesBanner.subtitle}
            </Typography>
            <Typography sx={styles.subtitle}>
              {strings.cookiesBanner.subtitle2}
            </Typography>
            <ButtonBase
              onClick={() => {
                window.open(strings.confiUrl, "_blank")?.focus();
              }}
            >
              <Typography
                sx={[
                  styles.subtitle,
                  {
                    color: Colors.ACCENT,
                    textDecoration: "underline",
                    margin: "10px 0px",
                  },
                ]}
              >
                {strings.cookiesBanner.policy}
              </Typography>
            </ButtonBase>
          </Box>
        )}
        <Box sx={styles.buttons}>
          {!chooseAll ? (
            <ButtonBase onClick={handleChoose} sx={styles.button}>
              <Typography sx={styles.btnText}>
                {strings.cookiesBanner.choose}
              </Typography>
            </ButtonBase>
          ) : null}
          <ButtonBase onClick={handleAccept} sx={styles.button}>
            <Typography sx={styles.btnText}>
              {chooseAll
                ? strings.cookiesBanner.validate
                : strings.cookiesBanner.interestOnly}
            </Typography>
          </ButtonBase>
          <ButtonBase onClick={handleAcceptAll} sx={styles.buttonAccept}>
            <Typography sx={[styles.btnText, { color: Colors.WHITE }]}>
              {strings.cookiesBanner.acceptAll}
            </Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  container: {
    ...columnStyles,
    padding: "20px 0px 0px",
    width: "60%",
    minWidth: "500px",
    maxWidth: "650px",
    borderRadius: "20px",
    position: "relative",
    height: "auto",
    backgroundColor: Colors.PRIMARY,
    backdropFilter: "blur(15px)",
    webkitBackdropFilter: "blur(15px)",
    overflow: "hidden",
    outline: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 2.8px 2.2px, rgba(0, 0, 0, 0.027) 0px 6.7px 5.3px, rgba(0, 0, 0, 0.035) 0px 12.5px 10px, rgba(0, 0, 0, 0.043) 0px 22.3px 17.9px, rgba(0, 0, 0, 0.05) 0px 41.8px 33.4px, rgba(0, 0, 0, 0.07) 0px 100px 80px",
  },
  textContainer: {
    ...columnStyles,
    gap: "10px",
    position: "relative",
    padding: "24px",
  },
  chooseContainer: {
    ...columnStyles,
    gap: "20px",
    position: "relative",
    padding: "0px 24px 30px",
  },
  title: {
    fontFamily: Eczar.semiBold,
    color: Colors.WHITE,
    fontSize: "2.2em",
  },
  subtitle: {
    fontFamily: Gilroy,
    //fontFamily: '"Source Sans Pro", sans-serif',
    color: Colors.OLD_PRICE,
    fontSize: "1em",
  },
  infoBox: {
    ...columnStyles,
  },
  infoTitle: {
    fontFamily: Gilroy,
    color: Colors.WHITE,
    fontSize: "1.1em",
  },
  infoSubtitle: {
    fontFamily: Gilroy,
    color: Colors.OLD_PRICE,
    fontSize: "1em",
  },
  infoSubtitle2: {
    fontFamily: Gilroy,
    //color: Colors.OLD_PRICE,
    color: Colors.BTN_INACTIVE,
    //color: Colors.FOND_ITEM_POPUP,
    fontSize: "0.9em",
  },
  switchBox: {
    ...rowBetweenStyles,
  },
  switchRow: {
    ...rowStartStyles,
  },
  buttons: {
    ...rowStyles,
    width: "100%",
  },
  button: {
    ...rowCenterStyles,
    flex: 1,
    padding: "10px 10px",
    backgroundColor: Colors.PRIMARY,
    borderTop: `solid 2px ${Colors.BTN_ACTIVE}`,
    borderRight: `solid 2px ${Colors.BTN_ACTIVE}`,
  },
  buttonAccept: {
    background: "linear-gradient(270deg, #cd47f4 0%, #7970ff 100%)",
    //backgroundColor: Colors.ACCENT,
    flex: 1,
    padding: "20px 10px",
  },
  btnText: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    fontSize: "16px",
  },
};

export default ConsentBanner;
