/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { itemBackgroundJson } from "../../constants/itemBackground";
import { getRouteManager } from "../../services/routeManager";
import { Box, ButtonBase, Typography } from "@mui/material";
import { strings } from "../../services/translation";
import { styles } from "./styles";

const SkinButton = ({ skin, callback }: { skin: any; callback: any }) => {
  return (
    <Box sx={styles.skinBtnModal}>
      <Box sx={styles.skinBtnImgModal}>
        <Player
          src={itemBackgroundJson}
          autoplay
          loop
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden",
            width: "70px",
            height: "70px",
            borderRadius: "70px",
            zIndex: 1,
          }}
          speed={1}
        />
        <img
          src={getRouteManager().getItemImageForVersion(skin.skin.id, "promo")}
          style={styles.image}
        />
      </Box>
      {skin.skin?.name ? (
        <Box sx={styles.skinBtnTitleBlockModal}>
          <Typography sx={styles.skinBtnTitleModal}>
            {skin.skin.name}
          </Typography>
          {skin.skin.shopVisible ? (
            <ButtonBase
              sx={styles.btn}
              onClick={() => callback(skin, skin.skin.type)}
            >
              <Typography sx={styles.btnText}>{strings.goToShop}</Typography>
            </ButtonBase>
          ) : (
            <Typography sx={styles.missionText}>
              {strings.missionText}
            </Typography>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default SkinButton;
