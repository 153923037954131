import { Box, ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../style";
import { columnEndStyles, rowStyles, rowCenterStyles } from "../../style/flex";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Item {
  title: any;
  icon?: any;
  callback: any;
  isActive: boolean;
  activeColor: string;
  style?: any;
}

const SwitchMenu = ({ items, style }: { items: Item[]; style?: any }) => {
  return (
    <Box sx={[styles.topMenu, style ? style : {}]}>
      <Box sx={styles.topMenuItems}>
        {items &&
          items.length &&
          items.map((item: Item, index: number) => {
            if (item && item.title && item.callback && item.activeColor) {
              return (
                <ButtonBase
                  key={index}
                  onClick={item.callback}
                  sx={
                    item.isActive
                      ? {
                          ...styles.topMenuItem,
                          ...styles.topMenuItemActive,
                        }
                      : {
                          ...styles.topMenuItem,
                          ...styles.topMenuItemInactive,
                        }
                  }
                >
                  {item.icon ? item.icon : null}
                  <Typography
                    sx={
                      item.isActive
                        ? [
                            styles.topMenuItemActiveTitle,
                            { color: item.activeColor },
                          ]
                        : styles.topMenuItemInactiveTitle
                    }
                  >
                    {item.title}
                  </Typography>
                </ButtonBase>
              );
            } else {
              return null;
            }
          })}
      </Box>
    </Box>
  );
};

const styles = {
  topMenu: {
    ...columnEndStyles,
    margin: 0,
  },
  topMenuItems: {
    with: "100%",
    height: "40px",
    ...rowStyles,
    backgroundColor: Colors.PRIMARY,
    borderRadius: "100px",
  },
  topMenuItem: {
    ...rowCenterStyles,
    padding: "8px 30px",
    borderRadius: "100px",
  },
  topMenuItemActive: {
    backgroundColor: Colors.BTN_ACTIVE,
  },
  topMenuItemInactive: {},
  topMenuItemActiveTitle: {
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginLeft: "3px",
  },
  topMenuItemInactiveTitle: {
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
    color: Colors.FOND_ITEM_POPUP,
    marginLeft: "3px",
  },
};

export default SwitchMenu;
