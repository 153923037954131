/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from "react";
import ColorManager from "../../services/colorManager";
import normalize from "../../utils/fontSizer";
import { getRouteManager } from "../../services/routeManager";
import { isNotEmptyString } from "../../utils/StringHelper";
import { propertyExists } from "../../utils/ObjectHelper";
import styles from "./styles";
import AvatarContainer from "../AvatarContainer";
import { Box, Fade, Typography } from "@mui/material";
import IconTint from "react-icon-tint";
import { rowStartStyles } from "../../style/flex";

// =======================================================

interface Props {
  currVar: any;
  player: any;
  storyId: any;
  colorAccent: any;
  textColor: any;
  notif: any;
  callback: any;
  storyData: any;
}

// =======================================================

const StatePopUp = (props: Props) => {
  /**
   *
   */
  const {
    currVar,
    player,
    storyId,
    colorAccent,
    textColor,
    notif,
    callback,
    storyData,
  } = props;

  notif.remove = true;

  const stateName = currVar.firstOperand ? currVar.firstOperand : null;
  const [shouldShow, setShouldShow] = useState(true);

  const { iconStates } = storyData;

  const iconObject = iconStates.find((item: any) => item.name === stateName);

  const [exist, setExist] = useState(true);

  const onAnimationEnd = () => {
    setTimeout(() => {
      setShouldShow(false);
      setTimeout(() => {
        setExist(false);
        callback(notif);
      }, 800);
    }, 3500);
  };

  /**
   *
   */
  const drawPlayer = () => {
    if (player.avatar) {
      return (
        <AvatarContainer
          user={player}
          persoWidth={72}
          borderColor={ColorManager.getInstance().getColor("secondary")}
          borderWidth={3}
        />
      );
    } else {
      return (
        <Box
          sx={[
            styles.letter,
            {
              backgroundColor: player.letterColor,
              borderColor: ColorManager.getInstance().getColor("secondary"),
            },
          ]}>
          <Typography style={styles.playerLetter}>{player.letter}</Typography>
        </Box>
      );
    }
  };

  /**
   *
   */
  const icon =
    iconObject && iconObject.iconId ? (
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box maxWidth={"45px"} maxHeight={"45px"} sx={{ alignSelf: "center" }}>
          <IconTint
            color={textColor}
            src={getRouteManager().icon(iconObject.iconId)}
            maxWidth={45}
            maxHeight={45}
          />
        </Box>

        {currVar?.secondOperand &&
          currVar?.secondOperand === "=" &&
          currVar?.thirdOperand &&
          currVar?.thirdOperand > 1 ? (
          <Box
            style={{
              position: "relative",
              left: "-15px",
              alignSelf: "flex-end",
            }}>
            <Typography
              style={{
                fontSize: normalize(14),
                color: colorAccent,
                fontFamily: "Gilroy-Bold",
              }}>
              {currVar.thirdOperand}
            </Typography>
          </Box>
        ) : null}
      </Box>
    ) : null;

  let nameToShow = "";

  if (storyId) {
    const { globalVars } = storyData;
    // globalVars est un objet et pas un array => si check array alors toujours false
    if (globalVars && typeof globalVars !== "undefined") {
      const obj = globalVars.find((entry: any) => entry.name === stateName);
      if (obj && propertyExists(obj, "defaultValue")) {
        nameToShow = obj.defaultValue;
      }
    }
  }

  if (exist) {
    return (
      <Fade
        in={shouldShow}
        appear
        addEndListener={() => {
          onAnimationEnd();
        }}
        timeout={600}>
        <Box
          sx={[
            styles.popBg,
            { backgroundColor: ColorManager.getInstance().getColor("quinary") },
          ]}>
          <Box sx={[styles.imageBackground, { opacity: 0.1 }]}>
            <IconTint
              color={textColor}
              maxWidth={137}
              maxHeight={137}
              src={getRouteManager().icon(iconObject.iconId)}
            />
          </Box>
          {drawPlayer()}

          <Box style={styles.rightPart}>
            <Typography sx={[styles.normalText, { color: colorAccent }]}>
              {player.name}
            </Typography>
            <Box sx={styles.iconAndName}>
              {icon}
              {isNotEmptyString(nameToShow) ? (
                <Typography
                  style={{
                    fontSize: normalize(14),
                    color: textColor,
                    paddingLeft: "5px",
                    fontFamily: "Gilroy-Bold",
                    ...rowStartStyles,
                  }}>
                  {currVar?.secondOperand &&
                    currVar?.secondOperand === "+" &&
                    currVar?.thirdOperand ? (
                    <Typography
                      style={{
                        fontSize: normalize(14),
                        color: colorAccent,
                        fontFamily: "Gilroy-Bold",
                        marginRight: "5px",
                      }}>
                      {"+"}
                      {currVar.thirdOperand}{" "}
                    </Typography>
                  ) : null}
                  {nameToShow}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Fade>
    );
  }else {
    return null;
  }
};

// =======================================================

export default React.memo(StatePopUp);
