import { Colors } from "../../style";
import {
  rowCenterEndStyles,
  columnCenterStyles,
  columnEndStyles,
  rowBetweenStyles,
  rowCenterStyles,
  rowStyles,
} from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import normalize from "../../utils/fontSizer";

export const styles = {
  selectedModal: {
    width: "700px",
    maxWidth: "700px",
    minWidth: "700px",
    minHeight: "420px",
    position: "relative",
    borderRadius: "20px",
    backgroundColor: Colors.BTN_ACTIVE,
    height: "auto",
    overflow: "hidden",
  },
  selectedBg: {
    position: "absolute",
    height: "100%",
    width: "100%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    top: 0,
  },
  selectedBgImage: {
    width: "100%",
    height: "180px",
    opacity: 0.7,
  },
  selectedStoryContainer: {
    ...rowCenterEndStyles,
    //flexWrap: 'wrap',
    columnGap: "32px",
    padding: "24px",
    position: "relative",
  },
  selectedStoryInfo: {
    ...columnCenterStyles,
    alignItems: "flex-start",
  },
  cover: {
    ...columnEndStyles,
    alignSelf: "flex-end",
  },
  selectedStoryInfos: {
    display: "flex",
    marginTop: "1vh",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  selectedStoryTitle: {
    color: Colors.WHITE,
    fontSize: 40,
    lineHeight: "40px",
    fontWeight: 500,
    fontFamily: "Eczar-Regular",
    paddingTop: "calc(30px + 5vh)",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "20px",
  },
  iconInfo: {
    color: Colors.TOKEN,
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    marginLeft: "7px",
  },
  themes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "15px",
  },
  storyTheme: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 12px",
    borderRadius: "100px",
    border: "solid 2px",
    marginRight: "5px",
  },
  storyDescription: {
    color: Colors.OLD_PRICE,
    fontSize: "16px",
    marginTop: "15px",
    marginBottom: "25px",
    fontFamily: "Gilroy-Bold",
  },
  storyButtons: {
    ...rowBetweenStyles,
    width: "100%",
  },
  successBtn: {
    height: "60px",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "13px",
    ...rowCenterStyles,
    width: "49%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  successBtnText: {
    fontFamily: Gilroy,
    fontSize: "20px",
    ...columnCenterStyles,
    lineHeight: "24px",
    textAlign: "center",
    color: Colors.ACCENT,
    marginLeft: "10px",
  },
  lesgoBtn: {
    width: "49%",
    ...columnCenterStyles,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  successModal: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    backgroundColor: Colors.PRIMARY,
    border: "none",
    padding: "20px",
    borderRadius: "20px",
    zIndex: 50,
    overflow: "auto",
    webkitOverflowScrolling: "touch",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
      width: "0px",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  successTitle: {
    fontFamily: "Eczar-Regular",
    fontWeight: 600,
    fontSize: "calc(20px + 1vh)",
    marginLeft: "60px",
    /* or 23px */
    color: Colors.ACCENT,
  },
  successSub: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    marginBottom: "15px",
    marginLeft: "60px",
  },
  separatorPrice: {
    width: "2px",
    height: "30px",
    backgroundColor: Colors.FOND_ITEM_POPUP,
    borderRadius: "3px",
    opacity: 0.7,
    margin: "0px 7px",
  },
  storyPrice: {
    fontSize: normalize(21),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "7px",
  },
  tokensOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "6px",
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokensAmountOwned: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.TOKEN,
    marginLeft: "3px",
  },
  popupButtons: {
    width: "100%",
    minWidth: "220px",
    ...rowStyles,
    justifyContent: "space-evenly",
    alignItems: "flex-end",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    ...rowCenterStyles,
    padding: "13px 11px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
  container: {
    ...columnCenterStyles,
    width: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    background: Colors.BLACK,
    opacity: "0.7 !important",
    zIndex: 20,
  },
  pregameContainer: {
    backgroundColor: Colors.PRIMARY,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  tag: {
    alignSelf: 'flex-start',
    backgroundColor: Colors.PRIMARY,
    color: Colors.WHITE,
    marginBottom: '5px',
  },
  gradientTextSmall: {
    fontSize: normalize(20),
    fontFamily: "Gilroy-Bold",
    marginLeft: '7px',
  }
};
