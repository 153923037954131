/* eslint-disable @typescript-eslint/no-explicit-any */
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnEndStyles, columnStartStyles } from "../../../style/flex";

// =======================================================

const centerStyles: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

// =======================================================

export default {
  container: {
    flex: 1,
    ...columnStartStyles,
    width: '100%',
    paddingTop: "32px",
    gap: "32px",
  },
  changing: {
    color: Colors.WHITE,
    fontSize: normalize(24),
    fontFamily: "Eczar-Regular",
    marginTop: "5px",
  },
  changeContainer: {
    flex: 1,

    ...columnEndStyles,
    marginBottom: "10px",
  },
  letter: {
    width: "120px",
    height: "120px",
    borderRadius: "120px",
    borderWidth: "5px",
    borderStyle: 'solid',
    ...centerStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
  submitButtonWrapper: {
    flex: 1,
    zIndex: 50,
    display: "flex",
    position: 'relative',
    flexDirection: "column",
    justifyContent: "flex-end",
    alignSelf: "center",
    alignItems: "center",
  },
};

// =======================================================
