export const ticketPromoAnim = {
  v: '5.9.6',
  fr: 29.9700012207031,
  ip: 0,
  op: 81.0000032991976,
  w: 25,
  h: 25,
  nm: 'Ticket Promo',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'ticket mask promo',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [12.5, 12.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.098, 0.633],
                    [-0.298, -0.298],
                    [-0.856, 0.494],
                    [0.188, 0.917],
                    [-0.342, 0.197],
                    [0, 0],
                    [-0.459, -0.123],
                    [-0.237, -0.411],
                    [-1.947, -3.371],
                    [0.123, -0.458],
                    [0.411, -0.238],
                    [1.098, -0.633],
                    [0.298, 0.298],
                    [0.856, -0.494],
                    [-0.188, -0.917],
                    [0.342, -0.197],
                    [0, 0],
                    [0.459, 0.123],
                    [0.237, 0.411],
                    [1.947, 3.371],
                    [-0.123, 0.458],
                    [-0.411, 0.238],
                  ],
                  o: [
                    [0.365, -0.21],
                    [0.659, 0.663],
                    [0.855, -0.493],
                    [-0.102, -0.382],
                    [1.068, -0.686],
                    [0.411, -0.238],
                    [0.458, 0.123],
                    [1.948, 3.371],
                    [0.238, 0.411],
                    [-0.123, 0.459],
                    [0, 0],
                    [-0.365, 0.21],
                    [-0.659, -0.663],
                    [-0.855, 0.493],
                    [0.102, 0.382],
                    [-1.068, 0.686],
                    [-0.411, 0.238],
                    [-0.458, -0.123],
                    [-1.948, -3.371],
                    [-0.238, -0.411],
                    [0.123, -0.459],
                    [0, 0],
                  ],
                  v: [
                    [-7.713, -7.424],
                    [-6.589, -7.276],
                    [-4.026, -6.971],
                    [-2.953, -9.356],
                    [-2.546, -10.337],
                    [0.667, -12.26],
                    [2.025, -12.439],
                    [3.111, -11.605],
                    [11.611, 3.109],
                    [11.79, 4.467],
                    [10.956, 5.553],
                    [7.713, 7.424],
                    [6.589, 7.276],
                    [4.026, 6.971],
                    [2.953, 9.356],
                    [2.546, 10.337],
                    [-0.667, 12.26],
                    [-2.025, 12.439],
                    [-3.111, 11.605],
                    [-11.611, -3.109],
                    [-11.79, -4.467],
                    [-10.956, -5.553],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.293, 0.169],
                    [-0.286, -0.18],
                    [0, 0],
                    [0, 0],
                    [-0.251, -0.226],
                    [0.083, -0.328],
                    [0, 0],
                    [0, 0],
                    [0.137, -0.308],
                    [0.337, -0.023],
                    [0, 0],
                    [0, 0],
                    [0.336, 0.035],
                    [0.125, 0.314],
                    [0, 0],
                    [0, 0],
                    [0.07, 0.33],
                    [-0.26, 0.216],
                    [0, 0],
                  ],
                  o: [
                    [-0.013, -0.337],
                    [0.293, -0.169],
                    [0, 0],
                    [0, 0],
                    [0.317, -0.117],
                    [0.251, 0.226],
                    [0, 0],
                    [0, 0],
                    [0.209, 0.265],
                    [-0.137, 0.309],
                    [0, 0],
                    [0, 0],
                    [-0.188, 0.279],
                    [-0.336, -0.036],
                    [0, 0],
                    [0, 0],
                    [-0.325, -0.092],
                    [-0.07, -0.331],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.724, -3.105],
                    [-2.269, -3.928],
                    [-1.328, -3.91],
                    [0.516, -2.751],
                    [2.561, -3.501],
                    [3.484, -3.323],
                    [3.758, -2.424],
                    [3.224, -0.313],
                    [4.571, 1.399],
                    [4.687, 2.332],
                    [3.916, 2.871],
                    [1.742, 3.015],
                    [0.529, 4.825],
                    [-0.323, 5.223],
                    [-1.073, 4.656],
                    [-1.883, 2.635],
                    [-3.979, 2.041],
                    [-4.622, 1.354],
                    [-4.314, 0.466],
                    [-2.641, -0.929],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.921568627451, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 2,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [12.851, 12.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'ticket',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600.000024438501,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'shiny promo',
      parent: 1,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [12.5, -70, 0],
              to: [0, 23.333, 0],
              ti: [0, -23.333, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 20,
              s: [12.5, 70, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80.0000032584668, s: [12.5, 70, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [34.306, -97.771],
                    [28.511, -99.324],
                    [-25.229, 101.234],
                    [-19.433, 102.787],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 30, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [12.5, 12.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'shiny',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600.000024438501,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'ticket promo',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [12.5, 12.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.098, 0.633],
                    [-0.298, -0.298],
                    [-0.856, 0.494],
                    [0.188, 0.917],
                    [-0.342, 0.197],
                    [0, 0],
                    [-0.459, -0.123],
                    [-0.237, -0.411],
                    [-1.947, -3.371],
                    [0.123, -0.458],
                    [0.411, -0.238],
                    [1.098, -0.633],
                    [0.298, 0.298],
                    [0.856, -0.494],
                    [-0.188, -0.917],
                    [0.342, -0.197],
                    [0, 0],
                    [0.459, 0.123],
                    [0.237, 0.411],
                    [1.947, 3.371],
                    [-0.123, 0.458],
                    [-0.411, 0.238],
                  ],
                  o: [
                    [0.365, -0.21],
                    [0.659, 0.663],
                    [0.855, -0.493],
                    [-0.102, -0.382],
                    [1.068, -0.686],
                    [0.411, -0.238],
                    [0.458, 0.123],
                    [1.948, 3.371],
                    [0.238, 0.411],
                    [-0.123, 0.459],
                    [0, 0],
                    [-0.365, 0.21],
                    [-0.659, -0.663],
                    [-0.855, 0.493],
                    [0.102, 0.382],
                    [-1.068, 0.686],
                    [-0.411, 0.238],
                    [-0.458, -0.123],
                    [-1.948, -3.371],
                    [-0.238, -0.411],
                    [0.123, -0.459],
                    [0, 0],
                  ],
                  v: [
                    [-7.713, -7.424],
                    [-6.589, -7.276],
                    [-4.026, -6.971],
                    [-2.953, -9.356],
                    [-2.546, -10.337],
                    [0.667, -12.26],
                    [2.025, -12.439],
                    [3.111, -11.605],
                    [11.611, 3.109],
                    [11.79, 4.467],
                    [10.956, 5.553],
                    [7.713, 7.424],
                    [6.589, 7.276],
                    [4.026, 6.971],
                    [2.953, 9.356],
                    [2.546, 10.337],
                    [-0.667, 12.26],
                    [-2.025, 12.439],
                    [-3.111, 11.605],
                    [-11.611, -3.109],
                    [-11.79, -4.467],
                    [-10.956, -5.553],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.293, 0.169],
                    [-0.286, -0.18],
                    [0, 0],
                    [0, 0],
                    [-0.251, -0.226],
                    [0.083, -0.328],
                    [0, 0],
                    [0, 0],
                    [0.137, -0.308],
                    [0.337, -0.023],
                    [0, 0],
                    [0, 0],
                    [0.336, 0.035],
                    [0.125, 0.314],
                    [0, 0],
                    [0, 0],
                    [0.07, 0.33],
                    [-0.26, 0.216],
                    [0, 0],
                  ],
                  o: [
                    [-0.013, -0.337],
                    [0.293, -0.169],
                    [0, 0],
                    [0, 0],
                    [0.317, -0.117],
                    [0.251, 0.226],
                    [0, 0],
                    [0, 0],
                    [0.209, 0.265],
                    [-0.137, 0.309],
                    [0, 0],
                    [0, 0],
                    [-0.188, 0.279],
                    [-0.336, -0.036],
                    [0, 0],
                    [0, 0],
                    [-0.325, -0.092],
                    [-0.07, -0.331],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.724, -3.105],
                    [-2.269, -3.928],
                    [-1.328, -3.91],
                    [0.516, -2.751],
                    [2.561, -3.501],
                    [3.484, -3.323],
                    [3.758, -2.424],
                    [3.224, -0.313],
                    [4.571, 1.399],
                    [4.687, 2.332],
                    [3.916, 2.871],
                    [1.742, 3.015],
                    [0.529, 4.825],
                    [-0.323, 5.223],
                    [-1.073, 4.656],
                    [-1.883, 2.635],
                    [-3.979, 2.041],
                    [-4.622, 1.354],
                    [-4.314, 0.466],
                    [-2.641, -0.929],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.913725490196, 0.313725490196, 0.313725490196, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 2,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [12.851, 12.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'ticket',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600.000024438501,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
