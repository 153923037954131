/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase, Box, Typography, Modal } from "@mui/material";
import { Colors } from "../../style";
import {
    columnCenterStyles,
    columnStyles,
    rowStartStyles,
} from "../../style/flex";
import GradientBorderBox from "../GradientBorderBox";
import { Player } from "@lottiefiles/react-lottie-player";
import { logoPass } from "../../constants/logoPass";
import DynamicTag from "../DynamicTag";
import GradientText from "../GradientText";
import { strings } from "../../services/translation";
import { Eczar, Gilroy } from "../../style/fonts";
import { useState } from "react";
import Pass from "../Pass";

const PassBtnBig = ({
    callback = null,
    currency = "EUR",
    style,
}: {
    callback?: any;
    currency: string | null;
    style?: any;
}) => {
    const [showPassModal, setShowPassModal] = useState(false);

    return (
        <>
            <ButtonBase
                onClick={callback ? callback : () => setShowPassModal(true)}
                sx={[styles.btn, style ? style : {}]}>
                <GradientBorderBox
                    color1={Colors.PASS1}
                    color2={Colors.PASS2}
                    backgroundColor={Colors.BTN_ACTIVE}
                    borderWidth={3}
                    borderRadius={13}
                    style={{
                        borderRadius: "13px",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        ...rowStartStyles,
                    }}>
                    <Box>
                        <Box sx={styles.playerContainer}>
                            <Player
                                src={logoPass}
                                autoplay
                                loop
                                style={{
                                    height: "167.9px",
                                }}
                            />
                        </Box>
                        <Box sx={styles.container}>
                            <DynamicTag
                                text=""
                                icon={() => (
                                    <GradientText
                                        color1={Colors.PASS1}
                                        color2={Colors.PASS2}
                                        style={styles.fullAccess}>
                                        {strings.pass.fullAccess}
                                    </GradientText>
                                )}
                            />
                            <GradientText
                                color1={Colors.PASS1}
                                color2={Colors.PASS2}
                                style={styles.gradientText}>
                                {strings.pass.adventurerPass}
                            </GradientText>
                            <Box
                                sx={{
                                    ...rowStartStyles,
                                    alignItems: "flex-end",
                                }}>
                                {/*  <Typography
                  component={"span"}
                  sx={[styles.price, styles.priceOld, { marginRight: "5px" }]}>
                  {currency === "EUR" ? "29" : "$29"}
                  <Typography
                    component={"span"}
                    sx={[styles.price, styles.priceOld]}>
                    {`.99${currency === "EUR" ? "€" : ""}`}
                  </Typography>
                </Typography> */}
                                <Typography
                                    component={"span"}
                                    sx={[styles.price, { marginRight: "5px" }]}>
                                    {strings.pass.from}
                                </Typography>
                                <Typography
                                    component={"span"}
                                    sx={[styles.price, { marginRight: "5px" }]}>
                                    {currency === "EUR" ? "19" : "$19"}
                                    <Typography
                                        component={"span"}
                                        sx={[
                                            styles.price,
                                            { fontSize: "14px" },
                                        ]}>
                                        {`.99${currency === "EUR" ? "€" : ""}`}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </GradientBorderBox>
            </ButtonBase>
            <Modal
                open={showPassModal}
                onClose={() => setShowPassModal(false)}
                sx={{ ...columnCenterStyles }}>
                <Box sx={{ outline: "none" }}>
                    <Pass />
                </Box>
            </Modal>
        </>
    );
};

const styles = {
    btn: {
        ...columnStyles,
        justifyContent: "center",
        alignItems: "flex-start",
        width: "350px",
        borderRadius: "13px",
        height: "157.8px",
        position: "relative",
        overflow: "hidden",
        backgroundColor: Colors.BTN_ACTIVE,
    },
    playerContainer: {
        width: "167.9px",
        height: "157.8px",
        position: "absolute",
        top: -5,
        right: 0,
    },
    container: {
        ...columnStyles,
        justifyContent: "center",
        alignItems: "flex-start",
        width: "185px",
        borderRadius: "13px",
        height: "157.8px",
        position: "relative",
        left: 24,
        overflow: "hidden",
    },
    fullAccess: {
        fontFamily: Gilroy,
        fontSize: "14px",
        lineHeight: "100%",
        textAlign: "center",
    },
    gradientText: {
        fontFamily: Eczar.regular,
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "100%",
        textAlign: "start",
        margin: "12px 0px 6px",
    },
    price: {
        fontFamily: Gilroy,
        fontSize: "16px",
        lineHeight: "100%",
        textAlign: "start",
        color: Colors.WHITE,
    },
    priceOld: {
        color: Colors.OLD_PRICE,
        textDecoration: "line-through",
        fontSize: "14px",
    },
};

export default PassBtnBig;
