/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
import { Box } from '@mui/material';
import { tokenAnim } from '../../constants/token';

interface Props {
  style?: any;
}

const TokenAnimated = (props: Props) => {
  const { style } = props;

  return (
    <Box style={style ? style : {}}>
    <Player
      src={tokenAnim}
      autoplay
      loop
      style={{backgroundSize: 'cover'}}
    />
  </Box>
  );
};

export default React.memo(TokenAnimated);
