import { Colors } from "../../style";
import { columnStyles, rowCenterStyles, rowStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import normalize, { normalizeSize } from "../../utils/fontSizer";

export const styles = {
    //GLOBAL START
    containerGlobal: {
        ...columnStyles,
        alignItems: "center",
        //flex: 1,
        width: "100%",
        height: "100%",
        borderBottom: `2px solid ${Colors.BTN_ACTIVE}`,
        background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%), url(${require("../../assets/images/background.jpg")}) 50%/cover`,
    },
    container: {
        ...columnStyles,
        alignItems: "center",
        //flex: 1,
        flex: "1 1 auto",
    },
    //GLOBAL END

    //MENU START
    socialMenu: {
        ...rowCenterStyles,
        //width: "100%",
        //borderBottom: `2px solid ${Colors.BTN_ACTIVE}`,
        marginTop: normalizeSize(20),
        flex: "0 0 auto",
    },
    menuContainer: {
        with: "100%",
        height: normalizeSize(40),
        ...rowStyles,
        backgroundColor: Colors.PRIMARY,
        borderRadius: normalizeSize(40),
        //maxWidth: "700px",
    },
    menuBtn: {
        position: "relative",
        //marginBottom: normalizeSize(-2),
        //width: "33%",
        //flex: 1,
        padding: "8px 30px",
        ...rowCenterStyles,
        borderRadius: normalizeSize(100),
    },
    menuBtnActive: {
        //paddingBottom: "1.5vh",
        //borderBottom: `2px solid ${Colors.WHITE}`,
        backgroundColor: Colors.BTN_ACTIVE,
    },

    topMenuItemActive: {
        backgroundColor: Colors.BTN_ACTIVE,
    },
    menuBtnText: {
        fontFamily: Gilroy,
        fontSize: normalize(14),
        color: Colors.OLD_PRICE,
    },
    notifIcon: {
        position: "absolute",
        top: normalizeSize(10),
        right: normalizeSize(10),
        width: normalizeSize(8),
        height: normalizeSize(8),
        borderRadius: normalizeSize(8),
        backgroundColor: Colors.PROMO,
    },
    discord: {
        position: "absolute",
        bottom: normalizeSize(20),
        right: normalizeSize(20),
    },
    //MENU END
};
