/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    ButtonBase,
    Input,
    InputAdornment,
    Modal,
    Snackbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../../../services/Auth/useAuth";
import SoundManager from "../../../../services/SoundManager";
import { getRouteManager } from "../../../../services/routeManager";
import {
    changeLanguage,
    getLanguage,
    strings,
} from "../../../../services/translation";
import { styles } from "../style";
import BgLeft from "../bgLeft";
import ChoiceFTUE from "../../../../components/ChoiceFTUE";
import { Colors } from "../../../../style";
import { Eczar } from "../../../../style/fonts";
import { Formik } from "formik";
import * as yup from "yup";
import normalize, { normalizeSize } from "../../../../utils/fontSizer";
import { columnCenterStyles, rowCenterStyles } from "../../../../style/flex";
import GradientBtn from "../../../../components/GradientBtn";
import { isNotEmptyString } from "../../../../utils/StringHelper";
import Loading from "../../../../components/Loading";
import { Player } from "@lottiefiles/react-lottie-player";
import GradientBtnCustom from "../../../../components/GradientBtnCustom";
import { capitalize } from "lodash";
import { rewardTitleJson } from "../../../../constants/rewardTitle";
import { ReactComponent as IconPseudo } from "../../../../assets/icons/icon_pseudo.svg";
import { ReactComponent as IconTitle } from "../../../../assets/icons/icon_news.svg";
import { getEventManager } from "../../../../services/EventManager";

const FtueBox = ({
    callback,
    screen = 0,
}: {
    callback: any;
    screen?: number;
}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const navigatePathname = useMemo(() => {
        const state = location.state as { from: Location };
        if (state && state.from) {
            return state.from;
        }
        return "/";
    }, [location]);

    const matchesWidth = useMediaQuery("(min-width:800px)");

    const [activeIndex, setActiveIndex] = useState(screen);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [pseudo, setPseudo] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [title, setTitle] = useState<any>(null);
    const [way, setWay] = useState<string | null>(null);

    const screens = [
        {
            title: strings.ftue.screen1Title,
            subtitle: strings.ftue.screen1Subtitle,
        },
        {
            title: strings.ftue.screen2Title,
            subtitle: strings.ftue.screen2Subtitle,
        },
        {
            title: strings.ftue.screen3Title,
            subtitle: strings.ftue.screen3Subtitle,
        },
        {
            title: `${strings.ftue.screen4Title} ${pseudo ? pseudo : ""}?`,
            subtitle: strings.ftue.screen4Subtitle,
        },
    ];

    /**
     *
     * USE EFFECTS
     *
     */
    useEffect(() => {
        if (screen && screen !== 0 && activeIndex !== screen) {
            if (!pseudo && auth?.user.pseudo) {
                setPseudo(auth.user.pseudo);
            }
            setActiveIndex(screen);
        }
    }, []);

    const goToScreen = (targetScreen: string) => {
        callback(targetScreen);
    };

    const handleError = (error: string, type: string) => {
        if (error.endsWith("is a required field") && type === "pseudo") {
            return strings.pseudoRequired;
        }
        if (error.endsWith("must be a valid pseudo") && type === "pseudo") {
            return strings.pseudoInvalid;
        }
        return error;
    };

    const testRegister = async (values: any) => {
        try {
            setLoading(true);
            setPseudo(values.pseudo);
            const api = getRouteManager().registerTemporaryAccount();
            const res = await getRouteManager().fetchData(`${api}`, {
                user: {
                    pseudo: values.pseudo,
                    lang: values.lang,
                },
            });
            if (res) {
                if (res.flashmessage && res.flashmessage !== "success") {
                    setError(res.errInfo ? res.errInfo : res.flashmessage);
                    setLoading(false);
                } else if (res && res.login_token) {
                    // SUCCESS, todo: setup user in useAuth + login_token etc..
                    if (res && res?.lang && res?.lang !== getLanguage()) {
                        changeLanguage(res.lang);
                    }
                    auth.signin(res, () => {
                        setActiveIndex(2);
                    });
                    setLoading(false);
                }
            } else {
                setError(strings.anErrorOccured);
                setLoading(false);
            }
        } catch (err) {
            setError(strings.anErrorOccured);
            setLoading(false);
            console.log(err);
        }
    };

    const chooseWay = async (way: string) => {
        try {
            const value = auth.user?.login_token;
            if (value) {
                setLoading(true);
                setWay(way);
                const apiTitle = getRouteManager().unlockTitle();
                const title = await getRouteManager().fetchData(apiTitle, {
                    login_token: value,
                    id: way === "good" ? 4 : 5,
                    lang: getLanguage(),
                    changeTitle: true,
                });
                if (
                    title.flashmessage === "success" ||
                    title.flashmessage === "already_unlocked"
                ) {
                    setTitle(title);
                    setShowPopup(true);
                    const newUser = auth.user;
                    newUser.title = title;
                    auth.setUser(newUser);
                    SoundManager.getInstance().playUnlockItemSound();
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getUserAsPlayer = () => {
        if (auth?.user?.avatar) {
            return {
                idx: 0,
                key: 0,
                name: capitalize(auth.user.pseudo.substring(0, 12).trim()),
                avatar: auth.user.avatar,
                letter: capitalize(auth.user.pseudo.substring(0, 12).trim())[0],
                letterColor: Colors.PRIMARY,
                playerCode: auth.user.player_code,
                letterBorderColor: Colors.WHITE,
                title: auth.user && auth.user.title ? auth.user.title : null,
            };
        } else {
            return null;
        }
    };

    const setFtueDone = async () => {
        if (auth?.user?.login_token) {
            const apiUrl = getRouteManager().setFtueDone();
            await getRouteManager().fetchData(apiUrl, {
                login_token: auth.user.login_token,
            });
            getEventManager().logFtueCompleted();
        }
    };

    const launchEpisode = async () => {
        //navigate(navigatePathname, { replace: true });
        setLoading(true);
        await setFtueDone();
        const player = getUserAsPlayer();
        if (player) {
            SoundManager.getInstance().removeAllSounds();
            navigate("/game", {
                state: {
                    players: [player],
                    storyId: 113,
                    title: strings.ftue.storyTitle,
                    save: null,
                    episode: null,
                    solo: true,
                    isFTUE: true,
                    isBoard: false,
                },
            });
        }
    };

    const launchGame = async () => {
        await setFtueDone();
        navigate(navigatePathname, { replace: true });
    };

    const renderPseudoForm = () => {
        return (
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    pseudo: pseudo ? pseudo : "",
                    lang: getLanguage(),
                    terms: false,
                    newsletter: false,
                    privacy: false,
                }}
                onSubmit={(values) => testRegister(values)}
                validationSchema={yup.object().shape({
                    pseudo: yup.string().max(20).required(),
                })}>
                {({
                    values,
                    handleChange,
                    errors,
                    setFieldTouched,
                    touched,
                    setFieldValue,
                    isValid,
                    handleSubmit,
                }) => (
                    <Box sx={styles.container}>
                        <Box sx={styles.inputContainer}>
                            <Typography sx={styles.label}>
                                {strings.pseudo}
                            </Typography>
                            <Input
                                sx={styles.input}
                                value={values.pseudo}
                                disableUnderline
                                autoFocus
                                type="pseudo"
                                name="pseudo"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("pseudo")}
                                placeholder={"pseudo"}
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        sx={{ paddingLeft: "5px" }}>
                                        <IconPseudo
                                            width={20}
                                            height={20}
                                            fill={Colors.WHITE}
                                        />
                                    </InputAdornment>
                                }
                            />
                            {touched.pseudo && errors.pseudo && (
                                <Typography
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        fontSize: normalize(12),
                                        color: Colors.ERROR,
                                        marginTop: "15px",
                                        textAlign: "center",
                                    }}>
                                    {handleError(errors.pseudo, "pseudo")}
                                </Typography>
                            )}
                            {error && isNotEmptyString(error) ? (
                                <Typography
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        fontSize: normalize(12),
                                        color: Colors.ERROR,
                                        marginTop: "15px",
                                        textAlign: "center",
                                    }}>
                                    {error}
                                </Typography>
                            ) : null}
                            {isValid &&
                            values.pseudo &&
                            isNotEmptyString(values.pseudo) ? (
                                <Box
                                    style={{
                                        width: "100%",
                                        ...rowCenterStyles,
                                        marginTop: "calc(20px + 0.8vh)",
                                    }}>
                                    <GradientBtn
                                        disabled={
                                            errors.pseudo || values.pseudo == ""
                                        }
                                        width={"100%"}
                                        onPress={handleSubmit}>
                                        {strings.continue}
                                    </GradientBtn>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                )}
            </Formik>
        );
    };

    return (
        <Box sx={styles.insideContainer}>
            <BgLeft
                imageInfos={"De l'histoire principale de Tales Up"}
                showInfos
            />
            <Box
                sx={[
                    styles.loginContainer,
                    matchesWidth ? { width: "50%" } : { width: "100%" },
                ]}>
                <Snackbar
                    open={isNotEmptyString(error)}
                    autoHideDuration={3000}
                    onClose={() => setError(null)}>
                    <Box sx={styles.snackBar}>
                        <Typography sx={styles.snackBarText}>
                            {error}
                        </Typography>
                    </Box>
                </Snackbar>
                <Box sx={[styles.container, { width: "80%" }]}>
                    <Typography
                        sx={[
                            styles.title,
                            {
                                fontFamily: Eczar.semiBold,
                                fontSize: "calc(27px + 0.6vh)",
                                lineHeight: "calc(27px + 0.6vh)",
                                padding: "calc(15px + 0.8vh) calc(10px)",
                                color: Colors.ACCENT,
                            },
                        ]}>
                        {screens[activeIndex].title}
                    </Typography>
                    <Typography
                        sx={[
                            styles.subtitle,
                            activeIndex === 1
                                ? {
                                      marginBottom: normalizeSize(25),
                                  }
                                : {},
                        ]}>
                        {screens[activeIndex].subtitle}
                    </Typography>
                    {activeIndex === 0 ? (
                        <Box sx={styles.choices}>
                            <ChoiceFTUE
                                goNext={() => setActiveIndex(1)}
                                text={strings.ftue.startAdventure}
                            />
                            <ChoiceFTUE
                                goNext={() => goToScreen("login")}
                                text={strings.ftue.alreadyHaveAccount}
                            />
                        </Box>
                    ) : activeIndex === 1 ? (
                        loading ? (
                            <Loading />
                        ) : (
                            <Box sx={styles.container}>
                                {renderPseudoForm()}
                                <ButtonBase
                                    onClick={() => goToScreen("register")}
                                    sx={styles.registerNowBtn}>
                                    <Typography sx={styles.registerNowText}>
                                        {strings.ftue.registerNow}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        )
                    ) : activeIndex === 2 ? (
                        loading ? (
                            <Loading />
                        ) : (
                            <Box sx={styles.choices}>
                                <ChoiceFTUE
                                    goNext={() => chooseWay("good")}
                                    text={strings.iGetItMaster}
                                />
                                <ChoiceFTUE
                                    goNext={() => chooseWay("bad")}
                                    text={strings.letMePlayNow}
                                />
                            </Box>
                        )
                    ) : activeIndex === 3 ? (
                        loading ? (
                            <Loading />
                        ) : (
                            <Box sx={styles.container}>
                                <GradientBtnCustom
                                    height={60}
                                    width={"70%"}
                                    customStyle={{
                                        alignItems: "center",
                                        borderRadius: "10px",
                                        marginTop: "20px",
                                    }}
                                    onPress={() => {
                                        //launchEpisode();
                                        launchGame();
                                    }}>
                                    <Typography
                                        sx={{
                                            color: Colors.WHITE,
                                            fontFamily: "Gilroy-Bold",
                                            fontSize: normalize(16),
                                            margin: "0px 20px",
                                        }}>
                                        {strings.lesgo}
                                    </Typography>
                                </GradientBtnCustom>
                                {/* <ButtonBase
                                    onClick={() => launchGame()}
                                    sx={styles.registerNowBtn}>
                                    <Typography sx={styles.registerNowText}>
                                        {strings.ftue.doItLater}
                                    </Typography>
                                </ButtonBase> */}
                            </Box>
                        )
                    ) : null}
                </Box>
            </Box>
            <Modal
                open={showPopup}
                onClose={() => {
                    SoundManager.getInstance().playCloseModalSound();
                    setActiveIndex(3);
                    setShowPopup(false);
                    setLoading(false);
                }}>
                <Box sx={{ ...columnCenterStyles }}>
                    <Box sx={styles.modalView}>
                        <Player
                            src={rewardTitleJson}
                            autoplay
                            loop
                            speed={1.5}
                            style={{ width: 130, height: 130 }}
                        />
                        <Typography
                            sx={{
                                color: Colors.SECONDARY_TEXT,
                                fontFamily: "Gilroy-Bold",
                                fontSize: normalize(14),
                                marginTop: "19px",
                            }}>
                            {strings.titleReceived}
                        </Typography>

                        <Box sx={styles.storyPriceBlockPopup}>
                            <IconTitle
                                width={23.7}
                                height={25.01}
                                fill={Colors.ACCENT}
                            />
                            <Typography sx={styles.rewardTitleName}>
                                {title && title?.name ? title.name : ""}
                            </Typography>
                        </Box>

                        <Box sx={styles.separator} />
                        <Box sx={styles.popupButtons}>
                            <ButtonBase
                                sx={styles.yeahButton}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    setActiveIndex(3);
                                    setShowPopup(false);
                                    setLoading(false);
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.great}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default FtueBox;
