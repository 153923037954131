/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import useAuth, { AuthProvider } from "./services/Auth/useAuth";
import { Routes, Route } from "react-router-dom";
import Library from "./pages/Library";
import Error from "./pages/Error";
import Shop from "./pages/Shop";
import RequireAuth from "./services/Auth/RequireAuth";
import Login from "./pages/User/Login";
import Home from "./pages/Home";
import { CssBaseline } from "@mui/material";
import EditUser from "./pages/User/Edit";
import { getRouteManager } from "./services/routeManager";
import { changeLanguage, getLanguage, strings } from "./services/translation";
import { isPositiveNumber } from "./utils/NumberHelper";
import {
    CookiesContext,
    GameContext,
    NotificationContext,
    SidebarContext,
} from "./utils/ContextsHelper";
import News from "./pages/News";
import Game from "./pages/Game";
import LevelScreen from "./pages/Level";
import { Level } from "./models/Level";
import MissionScreen from "./pages/Mission";
import Options from "./pages/Options";
import SaveScreen from "./pages/Saves";
import SettingsScreen from "./pages/Options/Settings";
import CreditsScreen from "./pages/Options/Credits";
import SupportScreen from "./pages/Options/Support";
import AboutScreen from "./pages/Options/About";
import CookieScreen from "./pages/Options/About/Cookies";
import SuggestionScreen from "./pages/Suggestion";
import { isTrueBoolean } from "./utils/TypeOfHelper";
import SoundManager from "./services/SoundManager";
import Lobby from "./pages/Lobby";
import GameOnline from "./pages/GameOnline";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from "react-facebook";
import RewardsScreen from "./pages/Rewards";
import Cookies from "js-cookie";
import { getEventManager } from "./services/EventManager";
import { initializeFirebaseAnalytics } from "./utils/FirebaseHelper";
import Purchase from "./pages/Purchase";
import TestScreen from "./pages/TestScreen";
import CompanionScreen from "./pages/CompanionScreen";
import SocialScreen from "./pages/Social";
import { FRIENDS, REFERRAL, SOCKET_EVENTS } from "./constants";
import { Friend } from "./models/friend";
import { isNotEmptyArray } from "./utils/ArrayHelper";
import { socket } from "./socket";
import { GameRequest } from "./models/gameRequest";
import MainQuestScreen from "./pages/MainQuestScreen";
import { Referral } from "./models/referral";

function App() {
    const auth = useAuth();

    const [cookiesSet, setCookiesSet] = useState(true);
    const [cookieUsage, setCookieUsage] = useState(false);
    const [cookieAudience, setCookieAudience] = useState(false);
    const [gameID, setGameID] = useState<string>("");
    const [hasNewLevel, setHasNewLevel] = useState(-1);
    const [hasNewMissions, setHasNewMissions] = useState(-1);
    const [hasNews, setHasNews] = useState(-1);
    const [hasReward, setHasReward] = useState(-1);
    const [hasReferralReward, setHasReferralReward] = useState(-1);
    const [hasFriendRequest, setHasFriendRequest] = useState(-1);
    const [playerID, setPlayerID] = useState<string>("");
    const [gameRequest, setGameRequest] = useState<GameRequest | null>(null);
    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
    const [wasHost, setWasHost] = useState<boolean>(false);

    const checkSounds = () => {
        checkSfx();
        checkMusic();
    };

    useEffect(() => {
        // no-op if the socket is already connected
        if (!socket.connected) {
            socket.connect();
        }

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        checkCookies();
    }, []);

    useEffect(() => {
        if (isTrueBoolean(cookieAudience)) {
            initializeFirebaseAnalytics();
        }
    }, [cookieAudience]);

    const checkCookies = () => {
        const consentCookie = Cookies.get("cookiesSet");
        if (consentCookie) {
            setCookiesSet(true);
            const usageValue = Cookies.get("usage");
            const audienceValue = Cookies.get("audience");
            if (usageValue) {
                setCookieUsage(JSON.parse(usageValue));
            }
            if (audienceValue) {
                setCookieAudience(JSON.parse(audienceValue));
                getEventManager().setCookieAudience(JSON.parse(audienceValue));
            }
        } else {
            setCookiesSet(false);
        }
    };

    const updateCookies = (
        consent: boolean,
        usage: boolean,
        audience: boolean,
    ) => {
        setCookiesSet(consent);
        setCookieUsage(usage);
        setCookieAudience(audience);
        getEventManager().setCookieAudience(audience);
    };

    const checkSfx = () => {
        const val = localStorage.getItem("isSfxMuted");
        if (val) {
            const isSfxMuted = JSON.parse(val);
            if (isTrueBoolean(isSfxMuted)) {
                SoundManager.getInstance().muteSfx();
            }
        }
    };

    const checkMusic = () => {
        const val = localStorage.getItem("isMusicMuted");
        if (val) {
            const isMusicMuted = JSON.parse(val);
            if (isTrueBoolean(isMusicMuted)) {
                SoundManager.getInstance().muteMusic();
                SoundManager.getInstance().muteTheme();
            }
        }
    };

    const checkLanguage = async () => {
        const value = localStorage.getItem("language");
        if ((value === "fr" || value === "en") && value !== getLanguage()) {
            changeLanguage(value);
        } else if (!value) {
            const userLang =
                strings.getInterfaceLanguage() || navigator.language;
            if (userLang) {
                if (
                    userLang.includes("fr") ||
                    (userLang.includes("FR") && getLanguage() !== "fr")
                ) {
                    changeLanguage("fr");
                } else if (getLanguage() !== "en") {
                    changeLanguage("en");
                }
            }
        }
    };

    useEffect(() => {
        checkSounds();
        checkLanguage();
    }, []);

    const getLevels = async (): Promise<Array<Level> | null> => {
        const lvl = await getRouteManager().fetchGetAllLevelForUser();
        if (lvl) {
            const index = lvl.findIndex(
                (l: any) => l.unlocked && !l.rewardReclaimed,
            );
            if (isPositiveNumber(index) && hasNewLevel !== index) {
                setHasNewLevel(index);
            } else if (!isPositiveNumber(index) && hasNewLevel !== -1) {
                setHasNewLevel(-1);
            }
            return lvl;
        } else {
            return null;
        }
    };

    /**
     *
     */
    const getMissions = async (): Promise<any> => {
        try {
            const json = await getRouteManager().fetchGetAllMissionsForUser();
            if (json) {
                const index = json.findIndex(
                    (l: any) =>
                        l.unlocked &&
                        !l.rewardReclaimed &&
                        l.toShow &&
                        ((l.successForSeries && !l.notComplete) ||
                            !l.successForSeries),
                );
                if (isPositiveNumber(index) && hasNewMissions !== index) {
                    setHasNewMissions(index);
                } else if (!isPositiveNumber(index) && hasNewMissions !== -1) {
                    setHasNewMissions(-1);
                }
                return json;
            } else {
                return null;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getNews = async () => {
        try {
            const articles = await getRouteManager().getData(
                `${getRouteManager().newsForLang(getLanguage())}`,
            );
            const fetched = localStorage.getItem("newsFetched");
            if (fetched) {
                const date = new Date(JSON.parse(fetched));
                if (
                    articles[0] &&
                    articles[0]?.date &&
                    date < new Date(articles[0]?.date)
                ) {
                    if (hasNews !== 1) {
                        setHasNews(1);
                    }
                } else if (hasNews !== -1) {
                    setHasNews(-1);
                }
            } else if (hasNews !== 1) {
                setHasNews(1);
            }
            return articles ? articles : null;
        } catch (err) {
            console.log(err);
        }
    };

    const getFriends = async () => {
        try {
            const api = getRouteManager().getFriendsForUser();
            const data = await getRouteManager().fetchGetFriendsForUser();
            if (isNotEmptyArray(data)) {
                const tmpFriendsList = data.find((friend: Friend) => {
                    return (
                        isPositiveNumber(friend.status) &&
                        friend.status === FRIENDS.STATUS.PENDING
                    );
                });
                if (tmpFriendsList) {
                    setHasFriendRequest(1);
                } else {
                    setHasFriendRequest(-1);
                }
            } else {
                setHasFriendRequest(-1);
            }

            return data ? data : null;
        } catch (err) {
            console.log(err);
        }
    };

    const getReferrals = async () => {
        try {
            const data = await getRouteManager().fetchGetReferralsForUser();
            if (data?.invitee?.status === 0) {
                setHasReferralReward(1);
            } else if (data && isNotEmptyArray(data.referrals)) {
                const tmpReferralList = data.referrals.find(
                    (referral: Referral) => {
                        return (
                            isPositiveNumber(referral.status) &&
                            referral.status === REFERRAL.STATUS.PENDING &&
                            !!referral.rewardId
                        );
                    },
                );
                if (tmpReferralList) {
                    setHasReferralReward(1);
                } else {
                    setHasReferralReward(-1);
                }
            } else {
                setHasReferralReward(-1);
            }

            return data ? data : null;
        } catch (err) {
            console.log(err);
        }
    };

    const getFeatured = async () => {
        try {
            const tmpFeatured: any =
                await getRouteManager().fetchGetFeaturedForLang(
                    auth?.user ? auth.user : undefined,
                );
            if (tmpFeatured && tmpFeatured?.length > 0) {
                tmpFeatured.map((f: any) => {
                    if (f?.layout === 2 && f?.cards[0]?.type === "weekend") {
                        if (
                            f?.cards[0]?.offer &&
                            !f?.cards[0]?.offer?.claimed &&
                            f?.cards[0]?.offer?.isOpen
                        ) {
                            if (hasReward !== 1) {
                                setHasReward(1);
                            }
                        } else if (
                            f?.cards[0]?.offer &&
                            f?.cards[0]?.offer?.claimed
                        ) {
                            if (hasReward !== -1) {
                                setHasReward(-1);
                            }
                        }
                    }
                });
                return tmpFeatured;
            } else {
                return null;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleReceiveGameRequest = (gameRequest: GameRequest) => {
        setGameRequest(gameRequest);
    };

    useEffect(() => {
        socket.on(SOCKET_EVENTS.GAME_REQUEST, handleReceiveGameRequest);

        return () => {
            socket.off(SOCKET_EVENTS.GAME_REQUEST, handleReceiveGameRequest);
        };
    }, []);

    const valueNotification = useMemo(
        () => ({
            hasNewLevel: hasNewLevel,
            getLevels: getLevels,
            hasNewMissions: hasNewMissions,
            getMissions: getMissions,
            hasNews: hasNews,
            hasReferralReward: hasReferralReward,
            getNews: getNews,
            getFeatured: getFeatured,
            hasReward: hasReward,
            hasFriendRequest: hasFriendRequest,
            getFriends: getFriends,
            getReferrals: getReferrals,
        }),
        [
            hasNewLevel,
            hasNewMissions,
            hasNews,
            hasReward,
            hasFriendRequest,
            hasReferralReward,
        ],
    );

    const setGame = (
        gameIDParam: string,
        playerIDParam: string,
        wasHostParam = false,
    ) => {
        setGameID(gameIDParam);
        setPlayerID(playerIDParam);
        setWasHost(wasHostParam);
    };

    const updateSidebarCollapsed = (val: boolean) => {
        setSidebarCollapsed(val);
    };

    const removeRequest = () => {
        setGameRequest(null);
    };

    const valueGame = useMemo(
        () => ({
            gameID: gameID,
            gameRequest: gameRequest,
            playerID: playerID,
            wasHost: wasHost,
            setGame: setGame,
            removeRequest: removeRequest,
        }),
        [gameID, playerID, wasHost, gameRequest],
    );

    const valueCookies = useMemo(
        () => ({
            cookiesSet: cookiesSet,
            cookieAudience: cookieAudience,
            cookieUsage: cookieUsage,
            checkCookies: checkCookies,
            updateCookies: updateCookies,
        }),
        [cookiesSet, cookieAudience, cookieUsage],
    );

    const valuesSidebar = useMemo(
        () => ({
            sidebarCollapsed: sidebarCollapsed,
            setSidebarCollapsed: updateSidebarCollapsed,
        }),
        [sidebarCollapsed],
    );

    return (
        <GoogleOAuthProvider clientId="261248566427-s2aqrdnbbngb3vs4eqj0u35hj6rkoijv.apps.googleusercontent.com">
            <AuthProvider>
                <CssBaseline />
                <NotificationContext.Provider value={valueNotification}>
                    <GameContext.Provider value={valueGame}>
                        <CookiesContext.Provider value={valueCookies}>
                            <SidebarContext.Provider value={valuesSidebar}>
                                <FacebookProvider appId="960485081768892" lazy>
                                    <Routes>
                                        <Route path="/" element={<Home />}>
                                            <Route
                                                index
                                                element={
                                                    <RequireAuth>
                                                        <Library />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path=":targetId"
                                                element={
                                                    <RequireAuth>
                                                        <Library />
                                                    </RequireAuth>
                                                }
                                            />
                                            {/*  <Route
                        path="library/old"
                        element={
                          <RequireAuth>
                            <LibraryOld />
                          </RequireAuth>
                        }
                      /> */}
                                            <Route
                                                path="test/adrien"
                                                element={
                                                    <RequireAuth>
                                                        <TestScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="social"
                                                element={
                                                    <RequireAuth>
                                                        <SocialScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="shop"
                                                element={
                                                    <RequireAuth>
                                                        <Shop />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="companions"
                                                element={
                                                    <RequireAuth>
                                                        <CompanionScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="purchase"
                                                element={
                                                    <RequireAuth>
                                                        <Purchase />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="editUser"
                                                element={
                                                    <RequireAuth>
                                                        <EditUser />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="news"
                                                element={
                                                    <RequireAuth>
                                                        <News />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="levels"
                                                element={
                                                    <RequireAuth>
                                                        <LevelScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="rewards"
                                                element={
                                                    <RequireAuth>
                                                        <RewardsScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="quests"
                                                element={
                                                    <RequireAuth>
                                                        <MissionScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="mainQuest"
                                                element={
                                                    <RequireAuth>
                                                        <MainQuestScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/"
                                                element={
                                                    <RequireAuth>
                                                        <Options />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/saves"
                                                element={
                                                    <RequireAuth>
                                                        <SaveScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/settings"
                                                element={
                                                    <RequireAuth>
                                                        <SettingsScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/credits"
                                                element={
                                                    <RequireAuth>
                                                        <CreditsScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/support"
                                                element={
                                                    <RequireAuth>
                                                        <SupportScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/about"
                                                element={
                                                    <RequireAuth>
                                                        <AboutScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="menu/about/cookies"
                                                element={
                                                    <RequireAuth>
                                                        <CookieScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="suggestions/:storyId"
                                                element={
                                                    <RequireAuth>
                                                        <SuggestionScreen />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="*"
                                                element={<Error />}
                                            />
                                        </Route>
                                        <Route
                                            path="/game"
                                            element={
                                                <RequireAuth>
                                                    <Game />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path="/gameOnline/:gameIDParam"
                                            element={
                                                <RequireAuth>
                                                    <GameOnline />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path="/lobby"
                                            element={
                                                <RequireAuth>
                                                    <Lobby />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path="/lobby/:gameIDParam"
                                            element={
                                                <RequireAuth>
                                                    <Lobby />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                    </Routes>
                                </FacebookProvider>
                            </SidebarContext.Provider>
                        </CookiesContext.Provider>
                    </GameContext.Provider>
                </NotificationContext.Provider>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

const styles = {
    modalView: {},
};

export default App;
