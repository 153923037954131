/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    ButtonBase,
    Modal,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { columnCenterStyles } from "../../../style/flex";
import { Colors } from "../../../style";
import {
    changeLanguage,
    getLanguage,
    strings,
} from "../../../services/translation";
import { useNavigate, useLocation } from "react-router";
import useAuth from "../../../services/Auth/useAuth";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    APPLE_LOGIN_WEB,
    FACEBOOK_LOGIN_WEB,
    GOOGLE_LOGIN_WEB,
    REGISTER_AVAILABLE_WEB,
} from "../../../constants/parameters";
import { FIREBASE_DATABASE } from "../../../constants";
import {
    resetStorage,
    isValidSnapshot,
    goToOnlineGame,
} from "../../../services/GameOnlineManager";
import { db } from "../../../utils/FirebaseHelper";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { propertyExists } from "../../../utils/ObjectHelper";
import { isTrueBoolean } from "../../../utils/TypeOfHelper";
import ClassicModal from "../../../components/ClassicModal";
import normalize from "../../../utils/fontSizer";
import { getRouteManager } from "../../../services/routeManager";
import { SCREEN_HEIGHT } from "../../../utils/size";
import LoginBox from "./LoginBox";
import SplashScreen from "./SplashScreen";
import BgLeft from "./bgLeft";
import RegisterBox from "./RegisterBox";
import { useGoogleLogin } from "@react-oauth/google";
import CgvBox from "./CgvBox";
import MainBox from "./MainBox";
import FtueBox from "./FtueBox";
import { styles } from "./style";
import { useLogin } from "react-facebook";
import BetaBox from "./BetaBox";
import Separator from "../../../components/Separator";
import ConsentBanner from "../../../components/ConsentBanner";
import { CookiesContext } from "../../../utils/ContextsHelper";
import { getEventManager } from "../../../services/EventManager";
import { isPositiveNumber } from "../../../utils/NumberHelper";

const Login = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();

    const navigatePathname = useMemo(() => {
        const state = location.state as { from: Location };
        if (state && state.from) {
            return state.from;
        }
        return "/";
    }, [location]);

    const matchesWidth = useMediaQuery("(min-width:800px)");
    const matchesHeight2 = useMediaQuery("(min-height:1000px)");

    const loginGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async ({ code }: any) => {
            signInSocialCallback(code, "google");
        },
        onError: (error) => handleError(error, "first error"),
    });
    const { login } = useLogin();
    const { cookiesSet, checkCookies } = useContext(CookiesContext);

    /* ----------- STATE ----------- */

    const [error, setError] = useState<any>(null);
    const [isBeta, setIsBeta] = useState(
        getRouteManager().getServerType() !== "prod",
    );
    const [loginAppleEnabled, setLoginAppleEnabled] = useState(false);
    const [loginGoogleEnabled, setLoginGoogleEnabled] = useState(false);
    const [loginFbENabled, setLoginFbENabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerEnabled, setRegisterEnabled] = useState(false);
    const [shouldFetchParams, setShouldFetchParams] = useState(false);
    const [showCgv, setShowCgv] = useState(false);
    const [showFtue, setShowFtue] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showResumeGame, setShowResumeGame] = useState(false);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [ftueScreenToShow, setFtueScreenToShow] = useState(-1);
    const [user, setUser] = useState<any>(null);

    /* ----------- USE EFFECTS ----------- */

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent,
            )
        ) {
            try {
                const timeout = setTimeout(function () {
                    // Si l'ouverture de l'application prend trop de temps, redirigez vers le magasin d'applications
                    alert(strings.messages.appNotInstalled);
                }, 1000);
                window.location.href = strings.urls.app;
            } catch (err) {
                alert(strings.messages.appNotInstalled);
            }
        }
    }, []);

    /**
     *
     */
    useEffect(() => {
        onInitCheckSocialLoginEnabled();
    }, []);

    /**
     *
     */
    useEffect(() => {
        checkLogin();
    }, []);

    useEffect(() => {
        if (isPositiveNumber(ftueScreenToShow)) {
            setShowFtue(true);
        }
    }, [ftueScreenToShow]);

    /* ----------- FUNCTIONS ----------- */

    /**
     *
     */
    const checkLoginEnabled = async (type: string): Promise<any> => {
        const paramRegister = getRouteManager().getParamForType(
            REGISTER_AVAILABLE_WEB,
        );
        const registerAvailable =
            await getRouteManager().postCheckParamValueNew(paramRegister);
        if (registerAvailable && isTrueBoolean(registerAvailable.value)) {
            setRegisterEnabled(true);
            if (type === "facebook") {
                const param =
                    getRouteManager().getParamForType(FACEBOOK_LOGIN_WEB);
                return await getRouteManager().postCheckParamValueNew(param);
            } else if (type === "google") {
                const param =
                    getRouteManager().getParamForType(GOOGLE_LOGIN_WEB);
                return await getRouteManager().postCheckParamValueNew(param);
            } else if (type === "apple") {
                const param =
                    getRouteManager().getParamForType(APPLE_LOGIN_WEB);
                return await getRouteManager().postCheckParamValueNew(param);
            }
        } else {
            setShowLogin(true);
            setRegisterEnabled(false);
        }
    };

    /**
     *
     */
    const onInitCheckSocialLoginEnabled = async () => {
        const loginFb = await checkLoginEnabled("facebook");
        const loginGoogle = await checkLoginEnabled("google");
        const loginApple = await checkLoginEnabled("apple");
        if (loginFb && isTrueBoolean(loginFb.value)) {
            setLoginFbENabled(loginFb.value);
            //setLoginFbENabled(false);
        }
        if (loginGoogle && isTrueBoolean(loginGoogle.value)) {
            setLoginGoogleEnabled(loginGoogle.value);
        }
        if (loginApple && isTrueBoolean(loginApple.value)) {
            setLoginAppleEnabled(loginApple.value);
        }
    };

    useEffect(() => {
        if (shouldFetchParams) {
            onInitCheckSocialLoginEnabled();
        }
    }, [shouldFetchParams]);

    /**
     *
     */
    const fetchLoginByToken = async (login_token: string) => {
        const apiUrl = getRouteManager().loginByToken();

        return await getRouteManager().fetchData(`${apiUrl}`, {
            login_token: login_token,
        });
    };

    /**
     *
     */
    const goToFtue = () => {
        //TODO: FTUE
        setFtueScreenToShow(2);
        //setShowFtue(true);
        //navigate(navigatePathname, { replace: true });
    };

    const goToFtueCallback = async (values: any) => {
        // TODO : addReferal with values.referral
        if (auth?.user?.login_token) {
            if (
                values &&
                values?.referral &&
                isNotEmptyString(values.referral)
            ) {
                const api = getRouteManager().addReferral();
                const data = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    referralId: values?.referral,
                });
                if (data?.flashmessage === "error") {
                    alert(
                        `${strings.referral.errorAddingReferral}: ${
                            data?.errInfo ? data.errInfo : ""
                        }`,
                    );
                }
            }
        }
        setFtueScreenToShow(2);
    };

    /**
     * Dispatch user navigation onmount
     */
    const onDispatchUser = useCallback(
        (response: any, callback?: any) => {
            if (response) {
                if (
                    response &&
                    response?.lang &&
                    response?.lang !== getLanguage()
                ) {
                    changeLanguage(response.lang);
                }
                auth.signin(response, () => {
                    getEventManager().logLogin();
                    if (!response.ftueDone) {
                        setShowSplashScreen(false);
                        goToFtue();
                    } else {
                        if (callback) {
                            callback();
                        } else {
                            navigate(navigatePathname, { replace: true });
                        }
                    }
                });
            }
        },
        [goToFtue],
    );

    /**
     *
     */
    const commonNavigationDispatch = useCallback(
        (currUser: any) => {
            onDispatchUser(currUser);
            resetStorage();
        },
        [onDispatchUser],
    );

    /**
     * Auto redirect user to the current online game if exists
     */
    const goToOnlineMode = useCallback(
        (
            currentUser: any,
            gameID?: string,
            playerID?: string,
            screenName?: string,
            nbrPlayers?: number,
            background?: string | undefined,
        ) => {
            if (isNotEmptyString(gameID)) {
                db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}`)
                    .orderByKey()
                    .once("value", (snapshot: any) => {
                        if (!isValidSnapshot(snapshot, 12)) {
                            commonNavigationDispatch(currentUser);
                        }
                        getEventManager().logLogin();
                        goToOnlineGame(
                            navigate,
                            snapshot.val(),
                            gameID,
                            playerID,
                            screenName,
                            currentUser,
                            () => commonNavigationDispatch(currentUser), // onError
                            nbrPlayers,
                            true, // onReboot,
                            background,
                        );
                    });
            } else {
                commonNavigationDispatch(currentUser);
            }
        },
        [commonNavigationDispatch],
    );

    const tryGoToOnlineMode = async () => {
        if (auth && auth.user) {
            const value = localStorage.getItem("alreadyLaunchedOnlineGame");
            if (value == null) {
                // common navigation dispatch case no game launched
            } else {
                const currGame = JSON.parse(value);
                if (
                    propertyExists(currGame, "status") &&
                    isTrueBoolean(currGame.status)
                ) {
                    goToOnlineMode(
                        auth.user, // <=> curr user
                        propertyExists(currGame, "gameID")
                            ? currGame.gameID
                            : "",
                        propertyExists(currGame, "playerID")
                            ? currGame.playerID
                            : "",
                        propertyExists(currGame, "screenName")
                            ? currGame.screenName
                            : "",
                        propertyExists(currGame, "nbrPlayers")
                            ? currGame.nbrPlayers
                            : "",
                    );
                } else {
                    // no valid data in storage, we continue by using common navigation dispatch
                    commonNavigationDispatch(auth.user);
                }
            }
        } else {
            // case no user
            setUser(null);
            localStorage.setItem("login_token", "");
            setTimeout(() => {
                // LAa
                setShowLogin(false);
                /* setIsLoaded(false);*/
                setShowSplashScreen(false);
                setShouldFetchParams(true);
            }, 2000);
        }
    };

    /**
     *
     */
    const checkLogin = async (forceLogin = false) => {
        //const apiUrl = getRouteManager().checkVersion();
        //setIsLoaded(true);
        const isMaintenanceOk = await getRouteManager().isMaintenanceOk();
        /*  const isMaintenanceModeActivated = await getRouteManager().postCheckParamValueNew(
      getParamForType(SERVER_MAINTENANCE),
    ); */
        if (!isMaintenanceOk) {
            setShowSplashScreen(false);
            setShowPopUp(true);
            setShouldFetchParams(false);
        } else {
            //const checkVersion = await getData(apiUrl);
            const checkVersionNew = await getRouteManager().isVersionOk();
            if (checkVersionNew) {
                //setVersionOk(true);
                const value = localStorage.getItem("login_token");
                if (value != null && value != "") {
                    const json = await fetchLoginByToken(value);
                    if (json.flashmessage === "error") {
                        setUser(null);

                        localStorage.setItem("login_token", "");
                        setTimeout(() => {
                            // LAa
                            setShowLogin(false);
                            //setIsLoaded(false);
                            setShowSplashScreen(false);
                            setShouldFetchParams(true);
                        }, 2000);
                    } else {
                        setUser(json);

                        getRouteManager().setUser(json);
                        if (forceLogin) {
                            onDispatchUser(json);
                        } else {
                            //await getEventManager().login('talesup');
                            // if the app was killed (for example), we redirect the user to the current
                            // game on reboot  if exists (@see all updateStorage() calls in screens/lobbyScreen/index.tsx)
                            onDispatchUser(json, () => {
                                const value = localStorage.getItem(
                                    "alreadyLaunchedOnlineGame",
                                );
                                if (value == null) {
                                    // common navigation dispatch
                                    onDispatchUser(json);
                                } else {
                                    const currGame = JSON.parse(value);
                                    if (
                                        propertyExists(currGame, "status") &&
                                        isTrueBoolean(currGame.status)
                                    ) {
                                        setShowSplashScreen(false);
                                        //onDispatchUser(json);
                                        setShowResumeGame(true);
                                    } else {
                                        // no valid data in storage, we continue by using common navigation dispatch
                                        onDispatchUser(json);
                                    }
                                }
                            });
                        }
                    }
                } else {
                    setTimeout(() => {
                        const valueFtue = localStorage.getItem(
                            "hasAlreadyLaunchGame",
                        );
                        if (
                            !valueFtue ||
                            valueFtue === null ||
                            valueFtue !== "true"
                        ) {
                            setShowFtue(true);
                        }
                        setUser(null);
                        //setIsLoaded(false);
                        setShowSplashScreen(false);
                        setShouldFetchParams(true);
                    }, 2000);
                }
            } else {
                setTimeout(() => {
                    //setVersionOk(false);
                    //setIsLoaded(false);
                    setShowSplashScreen(false);
                    setShouldFetchParams(true);
                }, 2000);
            }
        }
    };

    const signInWithGoogle = async () => {
        try {
            setLoading(true);
            loginGoogle();
        } catch (error: any) {
            console.log(error);
        }
    };

    const loginWithFacebook = async () => {
        try {
            const response = await login({
                scope: "public_profile,email",
            });
            setLoading(true);
            if (
                response &&
                response.status &&
                response.status === "connected" &&
                response.authResponse &&
                response.authResponse.accessToken
            ) {
                await signInSocialCallback(
                    response.authResponse.accessToken,
                    "facebook",
                );
            } else {
                handleError(null, strings.anErrorOccured);
            }
            setLoading(false);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleError = (error: any, errorInfo?: any) => {
        if (errorInfo) {
            setError(errorInfo);
        } else {
            switch (error) {
                case "error":
                    setError(error);
                    break;
                case "ERROR_NO_EMAIL_FOUND":
                    setError(strings.errorGoogle);
                    break;
                case "CANCELED":
                    break;
                default:
                    setError(error ? error : strings.errorGoogle);
                    break;
            }
        }
        setLoading(false);
    };

    const signInSocialCallback = async (code: any, type: string) => {
        try {
            if (code && isNotEmptyString(type)) {
                setLoading(true);
                const api = getRouteManager().loginWithSocialsNew();
                const res = await getRouteManager().fetchData(api, {
                    // http://localhost:3001/auth/google backend that will exchange the code
                    code: code,
                    lang: getLanguage(),
                    type: type,
                });
                if (res.flashmessage !== "error") {
                    getRouteManager().setUser(res);
                    localStorage.setItem("login_token", res.login_token);
                    if (res && res?.lang && res?.lang !== getLanguage()) {
                        changeLanguage(res.lang);
                    }
                    if (res && res.ftueDone) {
                        res.flashmessage = "OK_FTUE_DONE";
                        auth.signin(res, () => {
                            getEventManager().logLogin();
                            navigate(navigatePathname, { replace: true });
                        });
                        //navigation.replace('SplashScreen');
                        return res;
                    } else if (res && !res.ftueDone) {
                        res.flashmessage = "OK_FTUE_NOT_DONE";
                        auth.signin(res, () => {
                            getEventManager().logLogin();
                            setLoading(false);
                            setShowCgv(true);
                            //navigate(navigatePathname, { replace: true });
                        });
                        return res;
                    }
                } else {
                    handleError(res.flashmessage, res.errorInfo);
                }
            } else {
                handleError("ERROR_NO_EMAIL_FOUND");
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     *
     */
    const loginWithGoogle = async () => {
        await signInWithGoogle();
    };

    const handleBetaCode = (val: boolean) => {
        setIsBeta(val);
    };

    const handleFTUECallback = (screen?: string) => {
        /* if (screen && screen === "login") {
            setShowLogin(true);
        } */
        setShowFtue(false);
    };

    /**
     *
     */
    const _renderResumeGameModalContent = () => (
        <Box sx={styles.modalContent}>
            <Typography sx={styles.modalText}>
                {strings.messages.resumeGame}
            </Typography>
        </Box>
    );

    const mainContainerStyle = [
        styles.mainContainer,
        !matchesWidth && !matchesHeight2
            ? {
                  padding: "5vh 5vh",
                  background: `url(${require("../../../assets/images/FTUE.jpg")}) no-repeat 50%/cover`,
              }
            : !matchesWidth
            ? {
                  background: `url(${require("../../../assets/images/FTUE.jpg")}) no-repeat 50%/cover`,
                  padding: "10vh 10vh",
              }
            : !matchesHeight2
            ? {
                  padding: "5vh 5vh",
              }
            : !matchesWidth && !matchesHeight2
            ? {
                  padding: "5vh 5vh",
              }
            : { padding: "10vh 5vh" },
    ];

    /* ----------- RETURN ----------- */

    if (showSplashScreen) {
        return <SplashScreen />;
    } else {
        return (
            <Box sx={mainContainerStyle}>
                {!showFtue && !isBeta ? (
                    <Box sx={styles.insideContainer}>
                        <BgLeft
                            imageInfos={"De l'histoire principale de Tales Up"}
                            showInfos
                        />
                        {user == null && showLogin ? (
                            <LoginBox
                                callback={() => {
                                    if (registerEnabled) {
                                        setShowLogin(false);
                                    }
                                }}
                                ftueCallback={() => goToFtue()}
                            />
                        ) : user == null && showRegister ? (
                            <RegisterBox
                                callback={() => {
                                    setShowRegister(false);
                                    setShowLogin(true);
                                }}
                            />
                        ) : user == null && !showLogin && !showCgv ? (
                            <MainBox
                                loginAppleEnabled={loginAppleEnabled}
                                loginFbENabled={loginFbENabled}
                                loginGoogleEnabled={loginGoogleEnabled}
                                loginWithGoogle={loginWithGoogle}
                                loginWithFacebook={loginWithFacebook}
                                setShowLogin={setShowLogin}
                                setShowRegister={setShowRegister}
                                signInSocialCallback={signInSocialCallback}
                                isBeta={isBeta}
                            />
                        ) : user == null && !showLogin && showCgv ? (
                            <CgvBox
                                callback={
                                    /* () => {
                                    //go to ftue
                                    goToFtue();
                                } */ goToFtueCallback
                                }
                            />
                        ) : null}
                        <ClassicModal
                            onCloseModal={() => {
                                setShowResumeGame(false);
                                //setIsLoaded(true);
                                tryGoToOnlineMode();
                            }}
                            twoButtons={true}
                            onCancel={() => {
                                setShowResumeGame(false);
                                //setIsLoaded(true);
                                checkLogin(true);
                            }}
                            buttonText={strings.actions.resume}
                            modalVisible={showResumeGame}
                            title={strings.labels.resume}
                            minHeight={SCREEN_HEIGHT / 2}
                            titleHeight={SCREEN_HEIGHT / 26}
                            content={_renderResumeGameModalContent()}
                        />
                        <Modal open={showPopUp}>
                            <Box
                                style={{
                                    ...columnCenterStyles,
                                    outline: "none",
                                }}>
                                <Box style={styles.modalViewError}>
                                    <Typography
                                        style={{
                                            color: Colors.WHITE,
                                            fontFamily: "Eczar-Regular",
                                            fontSize: normalize(36),
                                            marginBottom: "18px",
                                            textAlign: "center",
                                            lineHeight: "40px",
                                            paddingTop: "20px",
                                        }}>
                                        {strings.serverUnderMaintenance}
                                    </Typography>

                                    <Typography sx={styles.textModal}>
                                        {strings.maintenancePartOne} 😉
                                    </Typography>

                                    <Separator />

                                    <a
                                        href={strings.socials.twitter}
                                        style={{ textDecoration: "none" }}>
                                        <Typography
                                            sx={[
                                                styles.deleteStoryText,
                                                { marginTop: "15px" },
                                            ]}>
                                            {strings.checkTwitter} 🔥
                                        </Typography>
                                    </a>
                                </Box>
                            </Box>
                        </Modal>
                        <Modal
                            open={!!error}
                            onClose={() => setError(null)}
                            style={{ outline: "none" }}>
                            <Box
                                style={{
                                    ...columnCenterStyles,
                                    outline: "none",
                                }}>
                                <Box style={styles.modalViewError}>
                                    <Typography
                                        style={{
                                            color: Colors.WHITE,
                                            fontFamily: "Eczar-Regular",
                                            fontSize: normalize(36),
                                            marginBottom: "18px",
                                            textAlign: "center",
                                        }}>
                                        {strings.error}
                                    </Typography>

                                    <Typography sx={styles.textModalError}>
                                        {error}
                                    </Typography>

                                    <Box style={styles.separatorError} />

                                    <ButtonBase onClick={() => setError(null)}>
                                        <Typography
                                            style={styles.deleteStoryText}>
                                            {strings.understood}
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                ) : showFtue && !isBeta ? (
                    <FtueBox
                        callback={handleFTUECallback}
                        screen={
                            isPositiveNumber(ftueScreenToShow)
                                ? ftueScreenToShow
                                : 0
                        }
                    />
                ) : user == null && isBeta ? (
                    <Box sx={styles.insideContainer}>
                        <BgLeft
                            imageInfos={"De l'histoire principale de Tales Up"}
                            showInfos
                        />
                        <BetaBox callback={handleBetaCode} />
                    </Box>
                ) : null}
                <ConsentBanner isOpen={!cookiesSet} callback={() => null} />
            </Box>
        );
    }
};

export default Login;
