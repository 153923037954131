/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Box, Typography } from "@mui/material";
import { Level } from "../../../models/Level";
import { Colors } from "../../../style";
import { styles } from "../styles";
import { rowCenterStyles } from "../../../style/flex";
import { LevelRewardBtn } from "./LevelRewardBtn";
import { normalizeSize } from "../../../utils/fontSizer";
import { useNavigate } from "react-router";

export const LevelBtn = ({
    index,
    userLevel,
    level,
    checkAndGetReward,
}: {
    index: number;
    userLevel: number;
    level: Level;
    checkAndGetReward: any;
}) => {
    const navigate = useNavigate();

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @goToMainQuest
     * Navigate to /mainQuest
     */
    const goToMainQuest = () => {
        navigate("/mainQuest");
    };

    /**
     *
     * RENDER
     *
     */
    return (
        <Grid item xs={12} key={`level-${index}`}>
            <Box sx={styles.level}>
                {index < 20 ? (
                    <Box
                        sx={
                            index < userLevel
                                ? styles.levelLineCompleted
                                : styles.levelLine
                        }
                    />
                ) : null}
                <Box
                    sx={{
                        ...rowCenterStyles,
                        alignItems: "flex-start",
                        columnGap: normalizeSize(10),
                    }}>
                    {level?.rewards?.length && level?.rewards[0] ? (
                        <LevelRewardBtn
                            reward={level?.rewards[0]}
                            unlocked={level.unlocked}
                            rewardReclaimed={level.rewardReclaimed}
                            callback={() => checkAndGetReward(level)}
                        />
                    ) : (
                        <Box sx={styles.emptyReward} />
                    )}
                    <Typography
                        variant="h6"
                        sx={styles.levelNumber}
                        style={{
                            borderColor:
                                index <= userLevel
                                    ? Colors.ACCENT
                                    : Colors.FOND_ITEM_POPUP,
                            color:
                                index <= userLevel
                                    ? Colors.ACCENT
                                    : Colors.FOND_ITEM_POPUP,
                        }}>
                        {index}
                    </Typography>
                    {level?.rewards?.length && level?.rewards[1] ? (
                        <LevelRewardBtn
                            reward={level?.rewards[1]}
                            unlocked={level.unlocked}
                            rewardReclaimed={false}
                            callback={() => goToMainQuest()}
                        />
                    ) : (
                        <Box sx={styles.emptyReward} />
                    )}
                </Box>
            </Box>
        </Grid>
    );
};
