/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router";
import useAuth from "../../services/Auth/useAuth";
import StoryReader from "../../services/StoryReader";
import { useEffect, useState } from "react";
import { Box, ButtonBase, Fade, Modal, Typography } from "@mui/material";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import BackgroundRow from "../BackgroundRow";
import {
    columnCenterStyles,
    columnEndStyles,
    columnStartStyles,
    columnStyles,
    rowEvenlyStyles,
    rowStartStyles,
} from "../../style/flex";
import Loading from "../Loading";
import ReportBtn from "../ReportBtn";
import GradientBtn from "../GradientBtn";
import { isFunction } from "../../utils/TypeOfHelper";
import { Colors } from "../../style";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon_achievement.svg";
import { ReactComponent as IconSave } from "../../assets/icons/icon_save.svg";
import normalize from "../../utils/fontSizer";
import CreateSaveScreen from "../../pages/Saves/createSave";
import SoundManager from "../../services/SoundManager";
import { isPositiveNumber } from "../../utils/NumberHelper";

interface Props {
    storyReader: StoryReader;
    storyData: any;
    setAskExit?: any;
}

interface Issue {
    name: string | undefined;
    nbTrigger: number;
}

interface Event {
    nbTriggerTotal: number;
    issues: Array<Issue>;
}

const ReportScreen = (props: Props) => {
    const { storyReader, storyData, setAskExit = null } = props;
    const auth = useAuth();
    const navigate = useNavigate();

    const storyId = storyReader.getStoryId();
    const players = storyReader.getPlayers();
    const variables = storyReader.getVariables();
    const hints = storyReader.getInformations();
    const type = storyReader.getType();
    const title = storyReader.getTitle();
    const ep = storyReader.getEpisode();

    const nbSavesUser =
        auth && auth?.user && auth?.user?.nbSaves ? auth.user.nbSaves : 20;

    const [eventsTriggered, setEventsTriggered] = useState([]);
    const [receivedEvent, setReceivedEvent] = useState(false);
    const [receivedSuccess, setReceivedSuccess] = useState(false);
    const [data, setData] = useState(null);
    const [dataSuccess, setDataSuccess] = useState<any>(null);
    const [showPopupSave, setShowPopupSave] = useState(false);
    const [showPopupSaveDone, setShowPopupSaveDone] = useState(false);
    const [series, setSeries] = useState<any>(null);
    const [saveCreated, setSaveCreated] = useState(false);
    const [isHost, setIsHost] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showCreateSave, setShowCreateSave] = useState(false);

    const [save, setSave] = useState({
        userId: auth.user.id,
        storyId: storyId,
        players: players.players,
        variables: variables.variables,
        hints: hints,
        type: type,
    });
    const [saves, setSaves] = useState(null);

    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        setLoading(true);
        SoundManager.getInstance().removeAllSounds();
        if (setAskExit && isFunction(setAskExit)) setAskExit(false);
        await storyReader.setUserStoryPlayed();
        if ((storyId && storyId === 113) || (storyData && storyData.isFTUE)) {
            if (storyId && storyId === 113) {
                await unlockMissionForUser(39);
            }
            navigate("/", { replace: true });
        } else {
            const _eventsTriggered = await storyReader.calculateReport();
            if (_eventsTriggered) {
                setEventsTriggered(_eventsTriggered);
            }
            const srIsHost = storyReader.getIsHost();
            setIsHost(srIsHost);

            const nbSuccessUnlock = await storyReader.triggerSuccess();
            if (
                nbSuccessUnlock?.flashmessage !== "error" &&
                nbSuccessUnlock?.flashmessage !== "error-user-success" &&
                nbSuccessUnlock.length > 0
            ) {
                setReceivedSuccess(true);
                setDataSuccess(nbSuccessUnlock);
            }
            await checkMissionsForUser(type);

            if ((type === "online" && srIsHost) || type !== "online") {
                const session = {
                    endDate: new Date(),
                    storyId: storyId,
                };
                await getRouteManager().fetchUpdateSession(session, auth.user);
            }

            if (ep && ep.seriesId) {
                const seriesAPI = getRouteManager().getSeries(
                    ep.seriesId,
                    auth.user.id,
                    getLanguage(),
                );
                const seriesTmp = await getRouteManager().getData(seriesAPI);
                if (seriesTmp) {
                    setSeries(seriesTmp);
                }
            }
            const events = await getRouteManager().getData(
                `${getRouteManager().eventsForStory(storyId)}`,
            );
            if (events) {
                setReceivedEvent(true);
                setData(events);
            }
            setLoading(false);
        }
    };

    const unlockMissionForUser = async (missionId: number): Promise<any> => {
        const api = getRouteManager().unlockMissionForUser();
        return await getRouteManager().fetchData(api, {
            login_token: getRouteManager().getUser().getLoginToken(),
            missionId: missionId,
        });
    };

    const checkMissionsForUser = async (typeM: string): Promise<void> => {
        switch (typeM) {
            case "online":
                await unlockMissionForUser(60);
                break;
            case "normal":
                await unlockMissionForUser(59);
                break;
            case "solo":
                await unlockMissionForUser(92);
                break;
            default:
                break;
        }
        if (
            (ep && ep.seriesId && ep.isLast) ||
            (ep && !ep.seriesId && !ep.isLast) ||
            !ep
        ) {
            await unlockMissionForUser(64);
        }
    };

    const checkAndCreateSave = async () => {
        let saveNew = save;
        if (!save) {
            saveNew = {
                userId: auth.user.id,
                storyId: storyId,
                players: players.players,
                variables: variables.variables,
                hints: hints,
                type: type,
            };
            setSave(saveNew);
        }

        const api = getRouteManager().getSavesForUser(
            auth.user.id,
            getLanguage(),
            "all",
        );
        const getSaves = await getRouteManager().getData(api);
        if (getSaves.flashmessage === "error") {
            console.log("no saves found");
        } else if (getSaves && isPositiveNumber(getSaves.length)) {
            const nbSaves = getSaves.length;
            setSaves(getSaves);
            if (type === "online") {
                if (isHost) {
                    if (!saveCreated) {
                        if (nbSaves < nbSavesUser) {
                            await autoCreateSave();
                        } else {
                            setShowPopupSave(true);
                        }
                    } else {
                        setShowPopupSaveDone(true);
                    }
                } else {
                    navigate("/", { replace: true });
                    /* params: {
                  series: series,
                  user: getRouteManager().getUser(),
                  shouldGoLibrary: true,
                }, */
                }
            } else if (!saveCreated) {
                if (nbSaves < nbSavesUser) {
                    await autoCreateSave();
                    //this.setState({ showPopupSave: true });
                } else {
                    setShowPopupSave(true);
                }
            } else {
                setShowPopupSaveDone(true);
            }
        }
    };

    const autoCreateSave = async () => {
        let saveNew = save;
        if (!save) {
            saveNew = {
                userId: auth.user.id,
                storyId: storyId,
                players: players.players,
                variables: variables.variables,
                hints: hints,
                type: type,
            };
            setSave(saveNew);
        }
        if (saveNew) {
            const apiUrl = getRouteManager().createSaveForUser();

            const json = await getRouteManager().fetchData(apiUrl, {
                save: saveNew,
            });

            if (json && json.flashmessage === "success") {
                setSaveCreated(true);
                setShowPopupSaveDone(true);
            } else {
                setShowCreateSave(true);
            }
        } else {
            setShowCreateSave(true);
        }
    };

    const goNext = () => {
        if (ep != null && ep != undefined && ep.seriesId) {
            if (!ep.isLast) {
                if (type === "online" && isHost) {
                    checkAndCreateSave();
                } else if (type !== "online") {
                    checkAndCreateSave();
                } else {
                    navigate("/", { replace: true });
                }
            } else {
                if (auth?.user?.pass?.isActive) {
                    navigate(`/`, {
                        replace: true,
                    });
                } else {
                    navigate(`/`, {
                        replace: true,
                    });
                }
            }
        } else {
            if (auth?.user?.pass?.isActive) {
                navigate(`/`, {
                    replace: true,
                });
            } else {
                navigate(`/`, {
                    replace: true,
                });
            }
        }
    };

    const drawEvents = (data: any) => {
        const { globalVars } = storyData;
        let cpt = 0;
        let cpt2 = 0;
        return data.map((event: Event, index: number) => {
            cpt2++;
            const nbTriggerTotal = event.nbTriggerTotal;
            let totalPercent = 100;
            return (
                <Fade
                    key={index}
                    in={true}
                    timeout={{
                        appear: cpt2 * event.issues.length * 400,
                        enter: 800,
                        exit: 800,
                    }}>
                    <Box
                        sx={[
                            styles.block,
                            data.length === index + 1
                                ? { marginBottom: "150px" }
                                : {},
                        ]}>
                        {event.issues.map((issue: Issue, index2: number) => {
                            cpt++;
                            const percent = Math.floor(
                                (100 / nbTriggerTotal) * issue.nbTrigger,
                            );

                            if (index2 == event.issues.length - 1) {
                                return (
                                    <ReportBtn
                                        key={index2}
                                        globalVars={globalVars}
                                        _eventsTriggered={eventsTriggered}
                                        issue={issue}
                                        cpt={cpt}
                                        percent={totalPercent}
                                    />
                                );
                            } else {
                                totalPercent -= percent;
                                return (
                                    <ReportBtn
                                        key={index2}
                                        globalVars={globalVars}
                                        _eventsTriggered={eventsTriggered}
                                        issue={issue}
                                        cpt={cpt}
                                        percent={percent}
                                    />
                                );
                            }
                        })}
                        {data.length !== index + 1 ? (
                            <Box sx={styles.blockSep} />
                        ) : null}
                    </Box>
                </Fade>
            );
        });
    };

    const drawSuccess = () => {
        if (receivedSuccess && dataSuccess) {
            return dataSuccess.map((item: any, index: number) => (
                <Box
                    key={index}
                    sx={[
                        styles.successBlock,
                        index === 0 ? { marginTop: "20px" } : {},
                    ]}>
                    <IconSuccess width={31} height={31} fill={Colors.ACCENT} />
                    <Box sx={styles.successText}>
                        <Typography sx={styles.successTitle}>
                            {item?.success?.trad?.successName
                                ? item.success.trad.successName
                                : ""}
                        </Typography>
                        <Typography sx={styles.successSubtitle}>
                            {item?.success?.trad?.description
                                ? item.success.trad.description
                                : ""}
                        </Typography>
                    </Box>
                </Box>
            ));
        } else {
            return null;
        }
    };

    const drawSuccessModal = () => (
        <Modal open={receivedSuccess} onClose={() => setReceivedSuccess(false)}>
            <Box sx={styles.centeredView}>
                {receivedSuccess && dataSuccess ? (
                    <Box sx={styles.modalView}>
                        <IconSuccess
                            width={40}
                            height={40}
                            fill={Colors.WHITE}
                        />
                        <Typography sx={styles.modalTitle}>
                            {strings.achievementsCompletedMaj}
                        </Typography>
                        <Box sx={styles.separator} />
                        {receivedSuccess && dataSuccess && drawSuccess()}
                        <Box sx={styles.popupButtons}>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    setReceivedSuccess(false);
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.great}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : (
                    <Loading />
                )}
            </Box>
        </Modal>
    );

    const drawSaveDoneModal = () => (
        <Modal
            open={showPopupSaveDone}
            onClose={() => setShowPopupSaveDone(false)}>
            <Box sx={styles.centeredView}>
                {ep != null && ep != undefined && series ? (
                    <Box sx={styles.modalView}>
                        <IconSave width={90} height={90} fill={Colors.WHITE} />
                        <Typography sx={styles.modalTitle2}>
                            {strings.popupSaveDone}
                        </Typography>
                        <Box sx={styles.separator} />
                        <Box sx={styles.popupButtons}>
                            <ButtonBase
                                sx={styles.noButton}
                                onClick={() => {
                                    setShowPopupSaveDone(false);
                                    navigate("/", { replace: true });
                                }}>
                                <Typography sx={styles.noText}>
                                    {strings.back}
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    setShowPopupSaveDone(false);
                                    navigate("/", {
                                        replace: true,
                                        state: {
                                            seriesParam: series,
                                            shouldShowEpisodes: true,
                                        },
                                    });
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.continue}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </Modal>
    );

    const drawSaveModal = () => (
        <Modal open={showPopupSave} onClose={() => setShowPopupSave(false)}>
            <Box sx={styles.centeredView}>
                {ep != null && ep != undefined && series ? (
                    <Box sx={styles.modalView}>
                        <IconSave width={90} height={90} fill={Colors.WHITE} />
                        <Typography sx={styles.modalTitle2}>
                            {strings.popupSave}
                        </Typography>
                        <Box sx={styles.separator} />
                        <Box sx={styles.popupButtons}>
                            <ButtonBase
                                sx={styles.noButton}
                                onClick={() => {
                                    setShowPopupSave(false);
                                    navigate("/", { replace: true });
                                }}>
                                <Typography sx={styles.noText}>
                                    {strings.dontSave}
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    setShowPopupSave(false);
                                    setShowCreateSave(true);
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.createSave}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </Modal>
    );

    if (showCreateSave) {
        return (
            <CreateSaveScreen
                hints={hints}
                type={type}
                save={save}
                savesLoad={saves}
                storyId={storyId}
                series={series}
                variables={variables.variables}
                players={players.players}
            />
        );
    } else {
        return (
            <BackgroundRow
                title={title}
                loading={loading}
                subtitle={strings.decisive}
                style={{ zIndex: 910 }}
                backgroundImage={
                    series && series?.id
                        ? getRouteManager().getCoverForSeries(series.id)
                        : storyId
                        ? getRouteManager().cover(storyId)
                        : undefined
                }
                rightPart={
                    <Box sx={styles.container}>
                        {receivedEvent ? drawEvents(data) : null}
                        <Box sx={styles.touchable}>
                            <GradientBtn
                                width={"100%"}
                                onPress={() => goNext()}>
                                {strings.continue}
                            </GradientBtn>
                        </Box>
                        {drawSuccessModal()}
                        {drawSaveDoneModal()}
                        {drawSaveModal()}
                    </Box>
                }
            />
        );
    }
};

const styles = {
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
        width: "370px",
    },
    touchable: {
        ...columnEndStyles,
        width: "370px",
        position: "absolute",
        height: "150px",
        paddingBottom: "20px",
        bottom: "0px",
        background: `linear-gradient(rgba(23, 24, 28, 0) 0%, ${Colors.PRIMARY} 35%)`,
    },
    block: {
        ...columnStyles,
        width: "100%",
        rowGap: "7px",
    },
    blockSep: {
        height: "2px",
        width: "100%",
        margin: "10px 0px 17px 0px",
        backgroundColor: Colors.GRAY_TEXT,
    },
    centeredView: {
        ...columnCenterStyles,
        flex: 1,
    },
    modalView: {
        ...columnCenterStyles,
        width: "330px",
        maxHeight: "450px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        padding: "30px 0px 0px 0px",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    modalTitle: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
        textAlign: "center",
    },
    modalTitle2: {
        color: Colors.LOCK,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        marginTop: "22px",
        textAlign: "center",
    },
    separator: {
        width: "100%",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        marginTop: "20px",
    },
    popupButtons: {
        width: "100%",
        ...rowEvenlyStyles,
        marginTop: "20px",
        marginBottom: "24px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(12),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalize(12),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    successBlock: {
        ...rowStartStyles,
        width: "290px",
        minHeight: "80px",
        marginTop: "10px",
        padding: "10px 15px",
        borderRadius: "10px",
        backgroundColor: Colors.BTN_ACTIVE,
    },
    successText: {
        ...columnStyles,
        justifyContent: "center",
        marginLeft: "20px",
    },
    successTitle: {
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
    },
    successSubtitle: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
    },
};

export default ReportScreen;
