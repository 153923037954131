/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import useAuth from "../../services/Auth/useAuth";
import { UserLadder } from "../../models/userLadder";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import LoadingView from "../LoadingView";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styles } from "./style";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { SidebarContext } from "../../utils/ContextsHelper";
import UserLadderBox from "./UserLadderBox";
import { columnStartStyles, rowStyles } from "../../style/flex";
import { isPositiveNumber } from "../../utils/NumberHelper";
import { isNotEmptyString } from "../../utils/StringHelper";
import Separator from "../Separator";
import { normalizeSize, normalizeVh } from "../../utils/fontSizer";

const LadderScreen = () => {
    /**
     *
     * STATES
     *
     */
    const auth = useAuth();
    const { sidebarCollapsed } = useContext(SidebarContext);
    const matches = useMediaQuery(
        `(min-width:${sidebarCollapsed ? 1143 : 1195}px)`,
    );
    const [ladder, setLadder] = useState<UserLadder[]>([]);
    const [loading, setLoading] = useState(true);
    const [you, setYou] = useState<UserLadder | null>(null);

    /**
     *
     * USE EFFECTS
     *
     */

    useEffect(() => {
        initData();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    const initData = async () => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                if (!loading) setLoading(true);
                const api = getRouteManager().getLadder();
                const data = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                    number: 50,
                });
                if (isNotEmptyArray(data.ladder)) {
                    setLadder(data?.ladder);
                }
                if (
                    isNotEmptyString(data?.userPosition) &&
                    isPositiveNumber(parseInt(data?.userPosition))
                ) {
                    const tmp: UserLadder = {
                        id: auth.user.id,
                        avatar: auth.user.avatar,
                        experience: auth.user.experience,
                        lang: auth.user.lang,
                        level: auth.user.level,
                        player_code: auth.user.player_code,
                        pseudo: auth.user.pseudo,
                        title: auth.user.title?.name
                            ? auth.user.title.name
                            : "",
                        titleId: auth.user.titleId,
                        position: parseInt(data?.userPosition),
                    };
                    setYou(tmp);
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /**
     *
     * RENDER
     *
     */

    if (loading) {
        return <LoadingView />;
    } else {
        return (
            <Box
                sx={[
                    matches
                        ? styles.mainContainerBig
                        : styles.mainContainerSmall,
                    styles.mainContainer,
                ]}>
                <Box sx={matches ? styles.container : styles.containerSmall}>
                    <Typography
                        sx={[
                            styles.topMenuTitle,
                            !matches && {
                                justifyContent: "center",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.ladder.ladder}
                    </Typography>
                    <Typography
                        sx={[
                            styles.subtitle,
                            !matches && {
                                justifyContent: "center",
                                marginBottom: "10px",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.ladder.subtitle}
                    </Typography>
                    <Box
                        sx={[
                            {
                                ...columnStartStyles,
                                width: "100%",
                                columnGap: "7px",
                            },
                            matches
                                ? { alignItems: "flex-start" }
                                : { justifyContent: "center" },
                        ]}>
                        <Typography
                            sx={[
                                styles.playerCode,
                                !matches && {
                                    justifyContent: "center",
                                    textAlign: "center",
                                },
                            ]}>
                            {strings.ladder.yourPosition}
                        </Typography>
                        <Box sx={styles.listSmall}>
                            {you ? <UserLadderBox user={you} isYou /> : null}
                        </Box>
                        {!matches ? (
                            <Separator style={styles.separatorLeft} />
                        ) : null}
                    </Box>
                </Box>
                <Box
                    sx={[
                        styles.right,
                        !matches && {
                            paddingTop: normalizeSize(20),
                            justifyContent: "center",
                            textAlign: "center",
                        },
                    ]}>
                    {isNotEmptyArray(ladder) ? (
                        <Box
                            sx={[
                                styles.listSmall,
                                !matches
                                    ? { alignSelf: "center" }
                                    : { marginLeft: normalizeSize(20) },
                            ]}>
                            {ladder.map((user: UserLadder, index: number) => (
                                <UserLadderBox key={index} user={user} />
                            ))}
                        </Box>
                    ) : null}
                </Box>
            </Box>
        );
    }
};

export default LadderScreen;
