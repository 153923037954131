/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { Tickets } from "../../models/tickets";
import { Colors } from "../../style";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  columnStartStyles,
  columnStyles,
  rowStartStyles,
  rowStyles,
} from "../../style/flex";
import DynamicTag from "../DynamicTag";
import { strings } from "../../services/translation";
import TicketAnimated from "../TicketAnimated";
import normalize from "../../utils/fontSizer";
import { Eczar, Gilroy } from "../../style/fonts";
import Separator from "../Separator";
import { useState } from "react";

const TicketButton = ({
  purchaseTickets,
  item,
}: {
  purchaseTickets: any;
  item: Tickets;
}) => {
  const [showContinue, setShowContinue] = useState(false);

  return (
    <>
      <ButtonBase
        onClick={() => {
          setShowContinue(true);
        }}
        sx={styles.mainContainer}>
        <Box sx={styles.playerContainer}>
          <Player
            src={item.json}
            autoplay
            loop
            style={{
              position: "absolute",
              top: 0,
              height: "100%",
            }}
            speed={item.key ? (item.key === 1 ? 2.5 : item.key) : 2}
          />
        </Box>
        <Box sx={styles.container}>
          {item.bonus ? (
            <DynamicTag
              text={"+" + item.bonus + "% " + strings.value}
              textColor={Colors.TICKET}
              style={{
                marginLeft: "28px",
                marginBottom: "5px",
              }}
            />
          ) : null}
          <Box style={styles.ticketIconPrice}>
            <TicketAnimated style={{ width: 25, height: 25 }} />
            <Typography style={styles.ticketAmount}>{item.number}</Typography>
          </Box>
          <Typography style={styles.tikcetPrice}>{item.price}</Typography>
        </Box>
      </ButtonBase>
      <Modal open={showContinue} onClose={() => setShowContinue(false)}>
        <Box sx={styles.modalRewardContainer}>
          <Typography sx={styles.title}>{strings.purchase.title}</Typography>

          <Box
            sx={[
              styles.ticketIconPrice,
              {
                backgroundColor: Colors.BTN_ACTIVE,
                padding: "7px 20px",
                borderRadius: "7px",
                marginLeft: 0,
              },
            ]}>
            <TicketAnimated style={{ width: 25, height: 25 }} />
            <Typography style={styles.ticketAmount}>{item.number}</Typography>
            <Typography
              sx={[
                styles.ticketAmount,
                { color: Colors.WHITE, fontSize: "18px" },
              ]}>
              {" - "}
              {item.price}
            </Typography>
          </Box>
          <Typography sx={styles.modalRewardText}>
            {strings.messages.redirectToPayment}
          </Typography>
          <Separator />
          <Box sx={styles.popupButtons}>
            <ButtonBase
              sx={styles.noButton}
              //onMouseDown={(event) => event.stopPropagation()}
              onClick={() => {
                setShowContinue(false);
              }}>
              <Typography sx={styles.noText}>{strings.back}</Typography>
            </ButtonBase>
            <ButtonBase
              sx={styles.yesButton}
              onClick={() => purchaseTickets(item)}>
              <Typography sx={styles.yesText}>{strings.continue}</Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const styles = {
  mainContainer: {
    ...columnStyles,
    justifyContent: "center",
    alignItems: "flex-start",
    width: "350px",
    borderRadius: "13px",
    height: "140px",
    position: "relative",
    overflow: "hidden",
    backgroundColor: Colors.BTN_ACTIVE,
  },
  container: {
    ...columnStyles,
    justifyContent: "center",
    alignItems: "flex-start",
    width: "350px",
    borderRadius: "13px",
    height: "140px",
    position: "relative",
    overflow: "hidden",
  },
  playerContainer: {
    width: "350px",
    height: "140px",
    position: "absolute",
    top: 0,
    left: 0,
  },
  tikcetPrice: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginBottom: "5px",
    marginLeft: "28px",
  },
  ticketIconPrice: {
    marginLeft: "28px",
    ...rowStartStyles,
    padding: "2px 0px",
    resizeMode: "contain",
  },
  ticketAmount: {
    fontSize: normalize(26),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "7px",
  },
  modalRewardContainer: {
    outline: "none",
    ...columnStartStyles,
    position: "relative",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    padding: "30px 0px",
    width: "400px",
  },
  title: {
    fontFamily: Eczar.semiBold,
    color: Colors.ACCENT,
    fontSize: "calc(24px + 2vh)",
    lineHeight: "calc(24px + 2vh)",
    padding: "10px 30px 25px",
  },
  modalRewardText: {
    textAlign: "center",
    padding: "25px",
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
    fontSize: "18px",
  },
  modalRewardTickets: {
    color: Colors.TICKET,
    fontFamily: Gilroy,
    fontSize: "18px",
  },
  popupButtons: {
    width: "100%",
    marginTop: "25px",
    ...rowStyles,
    justifyContent: "space-evenly",
    alignItems: "flex-end",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
};

export default TicketButton;
