/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import "./style.css";
import { Colors } from "../../style";
import { Player } from "@lottiefiles/react-lottie-player";
import { itemBackgroundJson } from "../../constants/itemBackground";
import TicketAnimated from "../TicketAnimated";
import normalize from "../../utils/fontSizer";
import { columnCenterStyles } from "../../style/flex";
import BuyItemModal from "../BuyItemModal";
import ClassicModal from "../ClassicModal";
import { strings } from "../../services/translation";

interface Props {
  buyItemCallback?: any;
  title: any;
  avatar?: any;
}

const avatarItemShop = (props: Props) => {
  const { buyItemCallback, title, avatar = null } = props;

  /* STATES */

  const [showPopUpBuyItem, setShowPopUpBuyItem] = useState(false);
  const [showPopUpCantEquip, setShowPopUpCantEquip] = useState(false);


  const onClickItem = () => {
    if (avatar) {
      if ((avatar.perso !== 74 && avatar.perso !== 75 && avatar.perso !== 76) || title.type === "perso") {
        setShowPopUpBuyItem(true);
      } else {
        setShowPopUpCantEquip(true);
      }
    } else {
      setShowPopUpBuyItem(true);
    }
  }

  //buyItem(title)
  return (
    <Box sx={styles.container}>
      <Box onClick={() => onClickItem()} sx={styles.btn}>
        <ButtonBase sx={styles.itemBlock}>
          <Box sx={styles.item}>
            <Player
              src={itemBackgroundJson}
              autoplay={true}
              loop={true}
              speed={1.5}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                overflow: "hidden",
                width: "10vh",
                height: "10vh",
                borderRadius: "10vh",
                zIndex: 1,
              }}
            />

            <img src={title.image_backdrop_promo} style={styles.img} />
          </Box>
        </ButtonBase>
      </Box>
      {title.price ? (
        <Box sx={styles.storyPriceBlockAvatar}>
          <TicketAnimated style={{ width: 25, height: 25 }} />
          <Typography style={styles.storyPriceAvatar}>{title.price}</Typography>
        </Box>
      ) : null}
      <Modal open={showPopUpBuyItem} onClose={() => setShowPopUpBuyItem(false)}>
        <Box
          sx={{
            width: "300px",
            borderRadius: "20px",
            backgroundColor: Colors.PRIMARY,
            position: "relative",
            padding: "40px 0px 20px",
            ...columnCenterStyles,
            zIndex: 50,
          }}>
          <BuyItemModal
            item={title}
            buyItemCallback={buyItemCallback}
            closeModal={() => setShowPopUpBuyItem(false)}
            avatarToShow={avatar}
          />
        </Box>
      </Modal>
      <ClassicModal
        onCloseModal={() => setShowPopUpCantEquip(false)}
        modalVisible={showPopUpCantEquip}
        title={strings.error}
        buttonText={strings.actions.cancel}
        content={(
          <Typography sx={[styles.modalSubtitle, { marginTop: 0 }]}>{strings.companions.cantEquip}</Typography>
        )}
      />
    </Box>
  );
};

const styles = {
  container: {
    ...columnCenterStyles,
    alignSelf: "center",
  },
  btn: {
    width: "12vh",
    height: "12vh",
    borderRadius: "1.4vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginRight: "15px",
  },
  itemBlock: {
    width: "12vh",
    height: "12vh",
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "1.4vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  item: {
    width: "10vh",
    height: "10vh",
    borderRadius: "10vh",
    backgroundColor: Colors.FOND_ITEM_POPUP,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  img: {
    width: "10vh",
    height: "10vh",
    borderRadius: "10vh",
    zIndex: 2,
  },
  storyPriceBlockAvatar: {
    /* position: "absolute",
    bottom: 0, */
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    zIndex: 30,
    marginTop: "5px",
    marginBottom: "15px",
    paddingRight: "15px",
  },
  storyPriceAvatar: {
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "6px",
  },
  modalSubtitle: {
    color: Colors.SECONDARY_TEXT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    width: "250px",
    marginTop: "19px",
    textAlign: "center",
  },
};

export default React.memo(avatarItemShop);
