import { Box, Typography, useMediaQuery } from "@mui/material"
import AvatarContainer from "../../../components/AvatarContainer"
import { styles } from "../styles"
import { Companion } from "../../../models/companion"

export const CompanionSingle = (
    {
        companion,
        forceRowList = false,
        selected = false,
    }: {
        companion: Companion,
        forceRowList: boolean,
        selected?: boolean | undefined,
    }) => {

    const matchesValue = 1032;
    const matches = forceRowList ? !forceRowList : useMediaQuery(`(min-width:${matchesValue}px)`);

    return (
        <Box sx={[
            matches ? styles.companionSingleColumn : styles.companionSingle,
            selected ? styles.companionSingleSelected : {},
        ]}>
            <AvatarContainer persoWidth={matches ? 120 : 60} user={companion} borderWidth={0} />
            <Box sx={matches ? styles.companionTextBig : styles.companionText}>
                <Typography
                    component={"span"}
                    sx={styles.companionName}>
                    {companion?.name ? companion.name : "Companion"}
                </Typography>
                <Typography
                    sx={styles.companionTitle}
                    component={"span"}>
                    {companion?.title &&
                        companion?.title?.name
                        ? companion.title.name
                        : ""}
                </Typography>
            </Box>
        </Box>
    )
}



