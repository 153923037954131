/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box } from "@mui/material";
import { Colors } from "../../style";
import "./style.css";

interface Props {
  fond: string | null;
  buste: string | null;
  bustePerso: string | null;
  perso: string | null;
  masque: string | null;
  chapeau: string | null;
  persoWidth: number;
  borderColor: string | null;
  borderWidth: number | null;
  avatarStyle?: any;
}

const Avatar = (props: Props) => {
  const {
    fond,
    bustePerso,
    perso,
    buste,
    masque,
    chapeau,
    persoWidth,
    borderColor,
    avatarStyle,
    borderWidth,
  } = props;
  const finalPersoWidth = persoWidth - 2 * (borderWidth ? borderWidth : 3);

  return (
    <Box
      className="supperposition"
      sx={[
        {
          width: persoWidth,
          height: persoWidth,
          backgroundColor: borderColor ? borderColor : Colors.WHITE,
          borderStyle: "solid",
          borderWidth: borderWidth ? borderWidth : 3,
          borderColor: borderColor ? borderColor : "transparent",
          borderRadius: persoWidth,
        },
        avatarStyle,
      ]}
    >
      {fond && (
        <img
          className="fond"
          src={fond}
          alt="fond"
          style={{
            width: finalPersoWidth,
            height: finalPersoWidth,
          }}
        />
      )}
      {bustePerso && (
        <img
          className="bustePerso"
          src={bustePerso}
          alt="bustePerso"
          style={{ width: finalPersoWidth, height: finalPersoWidth }}
        />
      )}
      {buste && (
        <img
          className="buste"
          src={buste}
          alt="buste"
          style={{ width: finalPersoWidth, height: finalPersoWidth }}
        />
      )}
      {perso && (
        <img
          className="perso"
          src={perso}
          alt="perso"
          style={{ width: finalPersoWidth, height: finalPersoWidth }}
        />
      )}
      {chapeau && (
        <img
          className="chapeau"
          src={chapeau}
          alt="chapeau"
          style={{ width: finalPersoWidth, height: finalPersoWidth }}
        />
      )}
      {masque && (
        <img
          className="masque"
          src={masque}
          alt="masque"
          style={{ width: finalPersoWidth, height: finalPersoWidth }}
        />
      )}
    </Box>
  );
};

export default Avatar;
