/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from "react";
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import { strings } from "../../services/translation";
import { ReactComponent as ButtonMission } from "../../assets/icons/button_mission.svg";
import { ReactComponent as IconError } from "../../assets/icons/icon_error.svg";
import { ReactComponent as IconOk } from "../../assets/icons/icon_success.svg";
import { ReactComponent as IconTitle } from "../../assets/icons/icon_news.svg";
import TimerDate from "../TimerDate";
import { Box, ButtonBase, Typography } from "@mui/material";
import RewardBtn from "../RewardBtn";
import {
  rowBetweenStyles,
} from "../../style/flex";

interface Props {
  mission: any;
  missions: any;
  checkAndGetReward: any;
  index: number;
  //goToSocial: any;
  //library: any;
  goNext: any;
  goToShopStory: any;
}

const MissionBtn = (props: Props) => {
  const {
    mission,
    checkAndGetReward,
    //goToSocial,
    //library,
    goToShopStory,
  } = props;

  const [rewards, setRewards] = useState<any>();

  useEffect(() => {
    const tmp = [];
    if (mission.reward) {
      tmp.push(mission.reward);
    }
    setRewards(tmp);
  }, []);

  /* const unlockMissionStoreForUser = async (): Promise<void> => {
    const api = getRouteManager().unlockMissionForUser();
    await fetch(api, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_token: getRouteManager().getUser().getLoginToken(),
        missionId: 41,
      }),
    });
  }; */

  const pressBtn = async () => {
    if (!mission.rewardReclaimed && mission.unlocked && !mission.notComplete) {
      checkAndGetReward(mission);
    } else if (mission.notComplete) {
      if (mission.successForSeries) {
        goToShopStory(mission);
      }
    } else if (!mission.unlocked) {
      if (mission.type === "follow") {
        //goToSocial();
      } else if (mission.type === "store") {
        //await unlockMissionStoreForUser();
      } else if (mission.type === "half_success_series") {
        if (mission.successForSeries) {
          /* const target = library.find(
            (item: any) => item.seriesId === mission.successForSeries
          );
          if (target) {
            goNext(target);
          } */
        }
      } else if (mission.type === "half_success_story") {
        if (mission.successForStory) {
          /* const target = library.find(
            (item: any) => item.story.id === mission.successForStory
          );
          if (target) {
            goNext(target);
          } */
        }
      } else if (mission.type === "all_success_story") {
        if (mission.successForStory) {
          /* const target = library.find(
            (item: any) => item.story.id === mission.successForStory
          );
          if (target) {
            goNext(target);
          } */
        } else if (mission.successForSeries) {
          /* const target = library.find(
            (item: any) => item.seriesId === mission.successForSeries
          );
          if (target) {
            goNext(target);
          } */
        }
      } else if (mission.type === "all_success_series") {
        if (mission.successForSeries) {
          /* const target = library.find(
            (item: any) => item.seriesId === mission.successForSeries
          );
          if (target) {
            goNext(target);
          } */
        }
      } else if (mission.type === "first_success_story") {
        if (mission.successForStory) {
          /* const target = library.find(
            (item: any) => item.story.id === mission.successForStory
          );
          if (target) {
            goNext(target);
          } */
        }
        if (mission.successForSeries) {
          /* const target = library.find(
            (item: any) => item.seriesId === mission.successForSeries
          );
          if (target) {
            goNext(target);
          } */
        }
      } else if (
        mission.type === "all_success_saga" ||
        mission.type === "finish_saga"
      ) {
        if (mission.successForSeries) {
          /* const target = library.find(
            (item: any) => item.seriesId === mission.successForSeries
          );
          if (target) {
            goNext(target);
          } */
        }
      }
    }
  };

  return (
    <ButtonBase
      sx={[
        styles.single,
        mission.unlocked && !mission.rewardReclaimed
          ? { border: `solid 2px ${Colors.vertALEXTESGRANDSMORTS}` }
          : {},
      ]}
      onClick={() => {
        pressBtn();
      }}
    >
      <Box sx={styles.rowBetween}>
        <Box
          sx={[
            styles.levelLeft,
            mission.type === "weekly" && mission.rewardReclaimed
              ? { borderRightWidth: 0 }
              : {},
          ]}
        >
          {mission.unlocked ? (
            <Box sx={styles.circle}>
              {mission.rewardReclaimed ? (
                <>
                  <IconOk
                    width={20}
                    height={20}
                    fill={Colors.vertALEXTESGRANDSMORTS}
                  />
                  <Typography sx={styles.issueNotDoneText}>
                    {mission.nbDone}/{mission.number}
                  </Typography>
                </>
              ) : mission.notComplete ? (
                <>
                  <IconError width={20} height={20} />
                  <Typography sx={styles.issueDoneText}>
                    {mission.nbDone}/{mission.number}
                  </Typography>
                </>
              ) : (
                <>
                  <IconOk
                    width={20}
                    height={20}
                    fill={Colors.vertALEXTESGRANDSMORTS}
                  />
                  <Typography sx={styles.issueDoneText}>
                    {mission.nbDone}/{mission.number}
                  </Typography>
                </>
              )}
            </Box>
          ) : mission?.type === "weekly" ? (
            <Box sx={styles.circle}>
              <IconTitle width={16} height={16} fill={Colors.TOKEN} />
              <Typography sx={styles.issueDoneText}>
                {mission.nbDone}/{mission.number}
              </Typography>
            </Box>
          ) : (
            <Box sx={styles.circle}>
              <ButtonMission width={20} height={20} />
              <Typography sx={styles.issueDoneText}>
                {mission.nbDone}/{mission.number}
              </Typography>
            </Box>
          )}

          <Typography
            sx={[
              mission.unlocked
                ? styles.issueDonePercent
                : styles.issueDonePercent2,
            ]}
          >
            {mission?.description ? mission.description : strings.notAvailable}
          </Typography>
        </Box>

        {((mission.type === "weekly" && !mission.rewardReclaimed) ||
          mission.type !== "weekly") && ((rewards && rewards?.length > 0) || mission.experience) ? (
          <Box sx={styles.rewardList}>
             <RewardBtn
                level={mission}
                isExperience
                reward={undefined}
                key={"reward-" + mission.type + "-" + mission.id + "-" }
              />
            {rewards && rewards?.length > 0 && rewards.map((r: any) => (
              <RewardBtn
                level={mission}
                reward={r}
                key={"reward-" + mission.type + "-" + mission.id + "-" + r.id}
              />
            ))}
          </Box>
        ) : null}
        {mission.type === "weekly" &&
          mission.rewardReclaimed &&
          mission.endDate ? (
          <TimerDate
            toDate={mission.endDate}
            toHour={6}
            textColor={Colors.WHITE}
            textGradient={false}
            style={{ position: "absolute", top: "-5px", right: 0 }}
          />
        ) : null}
      </Box>
    </ButtonBase>
  );
};

const styles = {
  rowBetween: {
    ...rowBetweenStyles,
    position: "relative",
    flex: 1,
  },
  single: {
    width: "370px",
    marginBottom: "10px",
    minHeight: "100px",
    padding: "15px 10px",
    borderRadius: "13px",
    backgroundColor: Colors.BTN_ACTIVE,
  },
  //<Left part>
  levelLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "70%",
    marginLeft: "5px",
    borderRightColor: Colors.FOND_ITEM_POPUP,
    borderRightWidth: "2px",
    borderRightStyle: "solid",
  },
  levelName: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
  },
  issueDoneText: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(19),
    alignSelf: "flex-start",
    marginLeft: "5px",
  },
  issueDonePercent: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    alignSelf: "flex-start",
    textAlign: 'start',
    marginTop: "7px",
  },
  issueDonePercent2: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    alignSelf: "flex-start",
    textAlign: 'start',
    marginTop: "7px",
  },
  issueNotDoneText: {
    color: Colors.BTN_INACTIVE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(19),
    alignSelf: "flex-start",
    marginLeft: "5px",
  },
  circle: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "2px",
  },

  //rewards:
  rewardList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
};

export default MissionBtn;
