import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import MissionScreen from "../Mission";
import { NotificationContext } from "../../utils/ContextsHelper";
import { isPositiveNumber } from "../../utils/NumberHelper";
import RegisterTemporaryAccountModal from "../../components/RegisterTemporaryAccountModal";
import useAuth from "../../services/Auth/useAuth";
import FTUEModals from "../../components/FTUEModals";
import { FTUE_MODALS } from "../../constants";
import LevelScreen from "../Level";
import SoundManager from "../../services/SoundManager";
import RewardMenu from "./RewardMenu";
import { ReferralScreen } from "./ReferralScreen";
import { styles } from "./styles";

const RewardsScreen = () => {
    const { hasNewLevel, hasNewMissions, hasReferralReward } =
        useContext(NotificationContext);
    const auth = useAuth();

    const [menuActiveIndex, setMenuActiveIndex] = useState(0);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    useEffect(() => {
        if (isPositiveNumber(hasNewLevel)) {
            changeMenuActive("level");
        } else if (isPositiveNumber(hasNewMissions)) {
            changeMenuActive("mission");
        } else if (isPositiveNumber(hasReferralReward)) {
            changeMenuActive("referral");
        }
    }, []);

    useEffect(() => {
        if (!auth || auth?.user?.isTemporaryAccount) {
            setShowRegisterModal(true);
        }
    }, [auth]);

    /**
     * @changeMenuActive
     * Change active item menu
     */
    const changeMenuActive = (type: string) => {
        SoundManager.getInstance().playTabBarSound();
        const menuTabs = ["level", "mission", "referral"];
        const index = menuTabs.indexOf(type);
        if (index !== -1) {
            setMenuActiveIndex(index);
        }
    };

    /**
     * @renderSocialPart
     * Renders the good component for menu tab selected
     */
    const renderRewardPart = () => {
        switch (menuActiveIndex) {
            case 0:
                return <LevelScreen />;
            case 1:
                return <MissionScreen />;
            case 2:
                return <ReferralScreen />;
            default:
                return null;
        }
    };

    return (
        <Box sx={styles.all}>
            <RewardMenu
                activeIndex={menuActiveIndex}
                changeMenuActive={changeMenuActive}
            />
            <Box sx={styles.body}>{renderRewardPart()}</Box>
            <RegisterTemporaryAccountModal
                isOpen={showRegisterModal}
                callback={() => setShowRegisterModal(false)}
            />
            {!showRegisterModal ? (
                <FTUEModals modal={FTUE_MODALS.REWARDS} />
            ) : null}
        </Box>
    );
};

export default RewardsScreen;
