import { Colors } from "../../style";
import { rowStartStyles, columnCenterStyles, columnStyles, rowCenterStyles } from "../../style/flex";
import normalize from "../../utils/fontSizer";

export const styles = {
  skinBtnModal: {
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
    width: "80%",
    height: "100px",
    marginBottom: "10px",
    ...rowStartStyles,
  },
  skinBtnImgModal: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    backgroundColor: Colors.FOND_ITEM_POPUP,
    ...columnCenterStyles,
    position: "relative",
    marginLeft: "10px",
  },
  skinBtnTitleBlockModal: {
    ...columnStyles,
    justifyContent: "center",
    marginLeft: "15px",
    flex: 1,
  },
  skinBtnTitleModal: {
    color: Colors.ACCENT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
  },
  image: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    zIndex: 2,
  },
  btn: {
    padding: "12px 20px",
    backgroundColor: Colors.ACCENT,
    ...rowCenterStyles,
    borderRadius: "10px",
    marginTop: "7px",
    alignSelf: "flex-start",
  },
  btnText: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
  },
  missionText: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
  },
};
