/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import normalize from "../../utils/fontSizer";
import { ReactComponent as QuitPopupIcon } from "../../assets/icons/icon_quit_story.svg";
import { Colors } from "../../style";
import { strings } from "../../services/translation";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { columnCenterStyles, columnStyles } from "../../style/flex";

// =======================================================

interface Props {
  onClose: any;
  onConfirm: any;
  visible: boolean;
}

// =======================================================

const ExitGameModal = (props: Props) => {
  const {onClose, onConfirm, visible} = props;

  return (
    <Modal
      open={visible}
      sx={styles.centeredView}
      onClose={() => onClose()}
    >
      <Box sx={styles.container}>
        <QuitPopupIcon width={90} height={90} fill={Colors.WHITE} />

        <Typography
          sx={[
            styles.actionText,
            {
              color: Colors.WHITE,
              fontSize: normalize(14),
            },
          ]}
        >
          {strings.sureToExit}
        </Typography>

        <Typography
          sx={[
            styles.actionText,
            {
              color: Colors.ERROR,
              fontSize: normalize(12),
            },
          ]}
        >
          {strings.looseAchievements}
        </Typography>

        <Box sx={styles.separatorBuyEpisode} />

        <Box sx={styles.buttons}>
          <ButtonBase sx={[styles.button, { backgroundColor: Colors.BTN_ACTIVE }]} onClick={() => onClose()}>
            <Typography sx={[styles.text, { color: Colors.OLD_PRICE}]}>{strings.back}</Typography>
          </ButtonBase>

          <ButtonBase
            sx={[styles.button, { backgroundColor: Colors.ACCENT }]}
            onClick={() => onConfirm()}
          >
            <Typography sx={styles.text}>{strings.exit}</Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Modal>
  );
};

// =======================================================

const styles = {
  centeredView: {
    flex: 1,
    ...columnCenterStyles,
  },
  container: {
    width: "300px",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    padding: "35px 25px 25px 25px",
    ...columnStyles,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  actionText: {
    fontFamily: "Gilroy-Bold",
    marginTop: "19px",
    textAlign: "center",
  },
  separatorBuyEpisode: {
    width: "300px",
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
    marginTop: "25px",
  },
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginTop: "25px",
  },
  button: {
    padding: "7px 11px",
    borderRadius: "10px",
    width: "100px",
  },
  text: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    textAlign: "center",
    color: Colors.WHITE,
  },
};

// =======================================================

ExitGameModal.defaultProps = {
  visible: false,
};

// =======================================================

export default ExitGameModal;
