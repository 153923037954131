/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { Referral } from "../../../../models/referral";
import { styles } from "../styles";
import { Player } from "@lottiefiles/react-lottie-player";
import ColorManager from "../../../../services/colorManager";
import { Colors } from "../../../../style";
import { levelRewardWaiting } from "../../../../constants/levelRewardWaiting";
import { strings } from "../../../../services/translation";

export const RewardInviteeBtn = ({
    invitee,
    callback,
    disabled,
}: {
    invitee: Referral;
    callback: any;
    disabled: boolean;
}) => {
    const getAnimWithColors = () => {
        let anim = levelRewardWaiting;
        anim = ColorManager.getInstance().changeAnimColors(
            anim,
            [Colors.BTN_ACTIVE],
            [Colors.PRIMARY],
        );
        return anim;
    };

    return (
        <ButtonBase
            sx={styles.referralBoxInvitee}
            disabled={disabled}
            onClick={() => !disabled && callback(invitee)}>
            <Box sx={styles.rewardBox}>
                <Player
                    src={getAnimWithColors()}
                    autoplay
                    loop
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
            </Box>
            <Typography sx={styles.referralBoxInviteeText}>
                {strings.actions.collect}
            </Typography>
        </ButtonBase>
    );
};
