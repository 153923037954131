export const rewardStoryJson = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 300.00001221925,
  w: 50,
  h: 50,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'UI_reward_story',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [25, 25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.279, -0.184],
                        [0, 0],
                        [1.388, -0.751],
                        [0.33, -4.232],
                        [-1.425, -2.334],
                        [-0.696, 0.266],
                        [0.134, 0.725],
                        [-0.967, 1.346],
                        [-0.261, 0.208],
                        [0, 0],
                        [-0.286, -1.552],
                        [-3.923, -1.621],
                        [-2.659, 0.634],
                        [0.038, 0.744],
                        [0.731, 0.096],
                        [0.981, 1.335],
                        [0.118, 0.313],
                        [0, 0],
                        [-1.565, -0.207],
                        [-2.754, 3.23],
                        [-0.219, 2.726],
                        [0.72, 0.194],
                        [0.317, -0.665],
                        [1.573, -0.52],
                        [0.334, -0.015],
                        [0, 0],
                        [-0.681, 1.423],
                        [2.221, 3.618],
                        [2.525, 1.05],
                        [0.407, -0.625],
                        [-0.535, -0.506],
                        [-0.008, -1.657],
                        [0.088, -0.322],
                        [0, 0],
                        [1.143, 1.087],
                        [4.127, -0.994],
                        [1.78, -2.076],
                        [-0.468, -0.58],
                        [-0.647, 0.352],
                        [-1.578, -0.504],
                      ],
                      o: [
                        [0, 0],
                        [-1.443, 0.161],
                        [-3.273, 1.773],
                        [-0.162, 2.071],
                        [0.392, 0.638],
                        [0.697, -0.267],
                        [-0.411, -2.217],
                        [0.207, -0.288],
                        [0, 0],
                        [-0.292, 1.423],
                        [0.674, 3.661],
                        [1.919, 0.793],
                        [0.729, -0.175],
                        [-0.039, -0.745],
                        [-2.236, -0.294],
                        [-0.21, -0.286],
                        [0, 0],
                        [1.263, 0.718],
                        [3.689, 0.49],
                        [1.348, -1.58],
                        [0.058, -0.747],
                        [-0.719, -0.193],
                        [-0.971, 2.036],
                        [-0.337, 0.112],
                        [0, 0],
                        [1.073, -0.98],
                        [1.606, -3.358],
                        [-1.086, -1.77],
                        [-0.693, -0.286],
                        [-0.406, 0.624],
                        [1.636, 1.553],
                        [0.002, 0.355],
                        [0, 0],
                        [-0.599, -1.323],
                        [-2.697, -2.565],
                        [-2.019, 0.487],
                        [-0.486, 0.57],
                        [0.469, 0.579],
                        [1.982, -1.076],
                        [0.338, 0.108],
                      ],
                      v: [
                        [-4.417, -8.924],
                        [-4.433, -8.922],
                        [-8.75, -7.852],
                        [-15.507, 1.11],
                        [-14.045, 7.74],
                        [-12.301, 8.518],
                        [-11.522, 6.774],
                        [-10.314, 1.533],
                        [-9.607, 0.79],
                        [-9.611, 0.805],
                        [-9.927, 5.243],
                        [-3.492, 14.438],
                        [3.265, 15.096],
                        [4.545, 13.678],
                        [3.126, 12.399],
                        [-1.484, 9.631],
                        [-1.973, 8.728],
                        [-1.959, 8.736],
                        [2.163, 10.408],
                        [12.897, 7.13],
                        [15.611, 0.906],
                        [14.657, -0.748],
                        [13.002, 0.205],
                        [8.945, 3.735],
                        [7.936, 3.92],
                        [7.948, 3.911],
                        [10.812, 0.507],
                        [11.011, -10.715],
                        [5.931, -15.22],
                        [4.062, -14.824],
                        [4.458, -12.956],
                        [6.56, -8.006],
                        [6.426, -6.989],
                        [6.419, -7.003],
                        [4.068, -10.778],
                        [-6.544, -14.437],
                        [-12.398, -10.997],
                        [-12.599, -9.097],
                        [-10.699, -8.896],
                        [-5.342, -9.365],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.313725490196, 0.913725550034, 0.552941176471, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25.327, 25.494], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-8.831, 0],
                        [0, -8.831],
                        [8.831, 0],
                        [0, 8.831],
                      ],
                      o: [
                        [8.831, 0],
                        [0, 8.831],
                        [-8.831, 0],
                        [0, -8.831],
                      ],
                      v: [
                        [0, -16],
                        [16, 0],
                        [0, 16],
                        [-16, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 0, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25, 25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [25, 25], ix: 2 },
              a: { a: 0, k: [25, 25], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 300.00001221925, s: [360] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.031, -0.522],
                        [0, 0],
                        [2.101, 1.291],
                        [5.985, -2.86],
                        [2.045, -3.752],
                        [-0.905, -0.734],
                        [-0.874, 0.748],
                        [-2.576, -0.257],
                        [-0.486, -0.19],
                        [0, 0],
                        [1.876, -1.599],
                        [-0.871, -6.576],
                        [-2.936, -3.104],
                        [-0.977, 0.633],
                        [0.441, 1.063],
                        [-1.04, 2.371],
                        [-0.332, 0.404],
                        [0, 0],
                        [-0.941, -2.279],
                        [-6.523, -1.204],
                        [-3.86, 1.833],
                        [0.3, 1.125],
                        [1.148, -0.091],
                        [1.933, 1.722],
                        [0.281, 0.44],
                        [0, 0],
                        [-2.458, 0.191],
                        [-3.16, 5.832],
                        [0.552, 4.237],
                        [1.163, 0.062],
                        [0.268, -1.12],
                        [2.235, -1.306],
                        [0.506, -0.132],
                        [0, 0],
                        [-0.579, 2.397],
                        [4.57, 4.808],
                        [4.2, 0.785],
                        [0.418, -1.087],
                        [-0.982, -0.601],
                        [-0.551, -2.529],
                      ],
                      o: [
                        [0, 0],
                        [-1.346, -1.828],
                        [-4.956, -3.044],
                        [-2.927, 1.399],
                        [-0.558, 1.029],
                        [0.904, 0.734],
                        [2.68, -2.289],
                        [0.552, 0.055],
                        [0, 0],
                        [-2.155, 0.716],
                        [-4.427, 3.773],
                        [0.426, 3.216],
                        [0.807, 0.849],
                        [0.977, -0.634],
                        [-1.349, -3.256],
                        [0.223, -0.508],
                        [0, 0],
                        [0.015, 2.27],
                        [2.22, 5.375],
                        [3.191, 0.588],
                        [1.056, -0.505],
                        [-0.301, -1.125],
                        [-3.513, 0.276],
                        [-0.415, -0.369],
                        [0, 0],
                        [2.164, 0.688],
                        [5.798, -0.45],
                        [1.547, -2.852],
                        [-0.153, -1.161],
                        [-1.163, -0.061],
                        [-0.823, 3.427],
                        [-0.48, 0.28],
                        [0, 0],
                        [1.322, -1.846],
                        [1.363, -5.654],
                        [-2.235, -2.352],
                        [-1.152, -0.213],
                        [-0.418, 1.087],
                        [3.005, 1.842],
                        [0.119, 0.542],
                      ],
                      v: [
                        [7.487, -11.991],
                        [7.471, -12.011],
                        [2.651, -17.018],
                        [-14.756, -19.16],
                        [-22.585, -12.001],
                        [-22.275, -9.033],
                        [-19.306, -9.342],
                        [-11.272, -11.801],
                        [-9.714, -11.426],
                        [-9.736, -11.418],
                        [-15.988, -8.381],
                        [-23.405, 7.512],
                        [-19.015, 17.17],
                        [-16.096, 17.793],
                        [-15.473, 14.874],
                        [-15.329, 6.472],
                        [-14.491, 5.106],
                        [-14.491, 5.131],
                        [-13.534, 12.015],
                        [-0.711, 23.98],
                        [9.832, 22.79],
                        [11.326, 20.206],
                        [8.741, 18.712],
                        [0.797, 15.978],
                        [-0.243, 14.759],
                        [-0.221, 14.766],
                        [6.622, 15.983],
                        [21.964, 7.485],
                        [24.09, -2.908],
                        [22.095, -5.128],
                        [19.875, -3.132],
                        [14.82, 3.58],
                        [13.339, 4.192],
                        [13.353, 4.172],
                        [16.625, -1.96],
                        [13.284, -19.176],
                        [4.057, -24.41],
                        [1.328, -23.198],
                        [2.54, -20.471],
                        [7.361, -13.589],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.313725490196, 0.913725550034, 0.552941176471, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25.724, 24.872], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-13.798, 0],
                        [0, -13.798],
                        [13.798, 0],
                        [0, 13.798],
                      ],
                      o: [
                        [13.798, 0],
                        [0, 13.798],
                        [-13.798, 0],
                        [0, -13.798],
                      ],
                      v: [
                        [0, -25],
                        [25, 0],
                        [0, 25],
                        [-25, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 0, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25, 25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [25, 25], ix: 2 },
              a: { a: 0, k: [25, 25], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 300.00001221925, s: [144] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 20, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 305.000012422905,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
