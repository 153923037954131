import { Player } from "@lottiefiles/react-lottie-player";
import { Box } from "@mui/material";
import { splashScreenUI } from "../../../constants/splashScreenUI";
import { Colors } from "../../../style";
import { columnCenterStyles } from "../../../style/flex";

const SplashScreen = () => {
  return (
    <Box sx={[styles.container, { overflow: "hidden" }]}>
      <Box
        sx={[
          styles.img,
          {
            overflow: "hidden",
            backgroundColor: Colors.PRIMARY,
          },
        ]}
      >
        <Player
          src={splashScreenUI}
          autoplay
          speed={1}
          loop
          style={{
            overflow: "hidden",
            backgroundPosition: "center",
            height: "100vh",
          }}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    ...columnCenterStyles,
  },
  img: {
    width: "100%",
    height: "100%",
    ...columnCenterStyles,
  },
};

export default SplashScreen;
