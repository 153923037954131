/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Fade, Typography } from "@mui/material";
import { useState } from "react";
import { Colors } from "../../style";
import {
  columnCenterStyles,
  columnStyles,
  rowBetweenStyles,
  rowCenterStyles,
  rowStartStyles,
} from "../../style/flex";
import { GlobalVar } from "../../models/globalVar";
import { strings } from "../../services/translation";
import { ReactComponent as SecretIcon } from "../../assets/icons/icon_secret.svg";
import normalize from "../../utils/fontSizer";

interface Props {
  globalVars: any;
  _eventsTriggered: any;
  issue: any;
  percent?: number;
  cpt: number;
}

const ReportBtn = (props: Props) => {
  const { globalVars, _eventsTriggered, issue, percent } = props;
  const [refShown, setRefShown] = useState<boolean>(false);
  const [refHidden, setRefHidden] = useState<boolean>(true);

  const isEventTriggered =
    globalVars.find((item: any) => item.name === issue.name).name ===
    _eventsTriggered.find((item: any) => item === issue.name);

  const gVar: string | null = globalVars
    ? globalVars.find((item: GlobalVar) => item.name === issue.name)
        .defaultValue
    : null;

  const manageShowEvent = () => {
    if (refShown) {
      setRefShown(false);
      setTimeout(() => {
        setRefHidden(true);
      }, 100);
    } else {
      setRefHidden(false);
      setTimeout(() => {
        setRefShown(true);
      }, 100);
    }
  };

  const renderIssueText = () => {
    return isEventTriggered ? (
      <Box sx={styles.textContainer}>
        <Typography sx={styles.issueDoneText}>{gVar ? gVar : ""}</Typography>
        <Typography sx={styles.issueDonePercent}>
          {percent ? percent : "0"}% {strings.playerDidThis}
        </Typography>
      </Box>
    ) : (
      <Box sx={styles.textContainer}>
        <Typography sx={styles.issueNotDoneText}>{gVar ? gVar : ""}</Typography>
        <Typography sx={styles.issueNotDonePercent}>
          {percent ? percent : "0"}% {strings.playerDidThis}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={[
        styles.container,
        !!gVar && !refShown
          ? {
              ...rowCenterStyles,
            }
          : {},
      ]}
    >
      {isEventTriggered ? (
        <Box sx={styles.issueVisible}>
          <Box sx={styles.circleContainer}>
            <Box sx={styles.circle} />
          </Box>
          {renderIssueText()}
        </Box>
      ) : gVar && refShown ? (
        <Fade in={refShown} timeout={{ enter: 500, exit: 400 }}>
          <ButtonBase
            sx={styles.issueVisible}
            onClick={() => manageShowEvent()}
          >
            <Box sx={styles.circleContainer}>
              <Box sx={styles.circleGray} />
            </Box>
            {renderIssueText()}
          </ButtonBase>
        </Fade>
      ) : !!gVar && refHidden ? (
        <Fade in={refHidden} timeout={{ enter: 500, exit: 400 }}>
          <ButtonBase sx={styles.issueHidden} onClick={() => manageShowEvent()}>
            <SecretIcon width={30} height={30} fill={Colors.LOCK} />
            <Typography style={styles.issueHiddenText}>
              {strings.show}
            </Typography>
          </ButtonBase>
        </Fade>
      ) : null}
    </Box>
  );
};

const issueText = {
  fontFamily: "Gilroy-Bold",
  fontSize: normalize(14),
  color: Colors.WHITE,
};

const styles = {
  container: {
    padding: "10px 15px",
    borderRadius: "10px",
    backgroundColor: Colors.BTN_ACTIVE,
    width: "100%",
    minHeight: "75px",
    ...rowStartStyles,
  },
  issueVisible: {
    ...rowBetweenStyles,
    width: "100%",
  },
  issueHidden: {
    ...columnCenterStyles,
    width: "100%",
  },
  issueContainer: { width: "100%" },
  circleContainer: {
    width: "15%",
    minWidth: "20px",
    ...rowCenterStyles,
  },
  circle: {
    height: "10px",
    width: "10px",
    borderRadius: "10px",
    backgroundColor: Colors.ACCENT,
    //marginLeft: -15,
    //alignSelf: "center",
  },
  circleGray: {
    height: "10px",
    width: "10px",
    borderRadius: "10px",
    backgroundColor: Colors.LOCK,
    //marginLeft: -15,
    //alignSelf: "center",
  },
  textContainer: {
    ...columnStyles,
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "85%",
  },
  issueDoneText: {
    ...issueText,
  },
  issueDonePercent: {
    color: "#9897A3",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
    marginTop: "7px",
  },
  issueNotDoneText: {
    ...issueText,
    color: Colors.LOCK,
    textAlign: 'start',
  },
  issueNotDonePercent: {
    fontSize: normalize(12),
    color: Colors.LOCK,
    fontFamily: "Gilroy-Bold",
    marginTop: "7px",
  },
  issueHiddenText: {
    fontSize: normalize(12),
    color: Colors.LOCK,
    fontFamily: "Gilroy-Bold",
    marginTop: "7px",
  },
};

export default ReportBtn;
