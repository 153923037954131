/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import useAuth from "../../services/Auth/useAuth";
import { Friend } from "../../models/friend";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { FRIENDS } from "../../constants";
import {
    NotificationContext,
    SidebarContext,
} from "../../utils/ContextsHelper";
import { rowStartStyles, rowStyles } from "../../style/flex";
import { styles } from "./style";
import FriendBox from "./FriendBox";
import LoadingView from "../LoadingView";
import PlayerCodeBtn from "./PlayerCodeBtn";
import { isPositiveNumber } from "../../utils/NumberHelper";
import GradientBtn from "../GradientBtn";
import { Colors } from "../../style";
import { normalizeSize, normalizeVh } from "../../utils/fontSizer";
import AddFriendModal from "./AddFriendModal";
import { isNotEmptyString } from "../../utils/StringHelper";

import { ReactComponent as IconCompanions } from "../../assets/icons/icon_companion.svg";

const FriendScreen = () => {
    /**
     *
     * STATES
     *
     */

    const auth = useAuth();
    const { getFriends } = useContext(NotificationContext);
    const { sidebarCollapsed } = useContext(SidebarContext);
    const matches = useMediaQuery(
        `(min-width:${sidebarCollapsed ? 1143 : 1195}px)`,
    );
    const [friendsList, setFriendsList] = useState<Friend[]>([]);
    const [loading, setLoading] = useState(true);
    const [requestList, setRequestList] = useState<Friend[]>([]);
    const [inviteList, setInviteList] = useState<Friend[]>([]);
    const [showAddFriendModal, setShowAddFriendModal] = useState(false);

    /**
     *
     * USE EFFETCS
     *
     */

    useEffect(() => {
        initData();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @initData
     * Initialize screen and get friendlist
     */
    const initData = async () => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                if (!loading) setLoading(true);
                const data = await getFriends();
                let tmpFriendsList: any = [];
                let tmpRequestList: any = [];
                let tmpInviteList: any = [];
                if (isNotEmptyArray(data)) {
                    tmpFriendsList = data.filter((friend: Friend) => {
                        return (
                            isPositiveNumber(friend.status) &&
                            friend.status === FRIENDS.STATUS.ACCEPTED
                        );
                    });
                    tmpRequestList = data.filter(
                        (friend: Friend) =>
                            isPositiveNumber(friend.status) &&
                            friend.status === FRIENDS.STATUS.PENDING,
                    );
                    tmpInviteList = data.filter(
                        (friend: Friend) =>
                            isPositiveNumber(friend.status) &&
                            friend.status === FRIENDS.STATUS.INVITED,
                    );
                }
                setFriendsList(tmpFriendsList);
                setRequestList(tmpRequestList);
                setInviteList(tmpInviteList);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const addFriend = async (playerCode: string) => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                if (isNotEmptyString(playerCode)) {
                    const api = getRouteManager().addFriendForUser();
                    const data = await getRouteManager().fetchData(api, {
                        login_token: auth.user.login_token,
                        playerCode: playerCode,
                    });
                    if (data && data.flashmessage === "success") {
                        //return { error: null };
                        setShowAddFriendModal(false);
                        initData();
                        return { error: null };
                    } else {
                        if (
                            data.errInfo &&
                            data.errInfo === "error-no-add-yourself"
                        ) {
                            return { error: data.errInfo };
                        }
                        return { error: strings.friends.invalidCode };
                    }
                } else {
                    return { error: strings.friends.invalidCode };
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    if (loading) {
        return <LoadingView />;
    } else {
        return (
            <Box
                sx={[
                    matches
                        ? styles.mainContainerBig
                        : styles.mainContainerSmall,
                    styles.mainContainer,
                ]}>
                <Box sx={matches ? styles.container : styles.containerSmall}>
                    <Typography
                        sx={[
                            styles.topMenuTitle,
                            !matches && {
                                justifyContent: "center",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.friends.friends}
                    </Typography>
                    <Typography
                        sx={[
                            styles.subtitle,
                            !matches && {
                                justifyContent: "center",
                                marginBottom: "10px",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.friends.subtitle}
                    </Typography>
                    <Box
                        sx={[
                            {
                                ...rowStyles,
                                alignItems: "center",
                                width: "100%",
                                columnGap: "7px",
                            },
                            matches
                                ? { justifyContent: "flex-start" }
                                : { justifyContent: "center" },
                        ]}>
                        <Typography
                            sx={[
                                styles.playerCode,
                                !matches && {
                                    justifyContent: "center",
                                    textAlign: "center",
                                },
                            ]}>
                            {strings.labels.yourPlayerCode}
                        </Typography>
                        <PlayerCodeBtn />
                    </Box>
                    {matches ? (
                        <Box
                            sx={{
                                ...rowStartStyles,
                                width: "100%",
                                marginTop: normalizeVh(2),
                            }}>
                            <GradientBtn
                                width={300}
                                onPress={() => setShowAddFriendModal(true)}
                                icon={
                                    <IconCompanions
                                        width={25}
                                        height={25}
                                        fill={Colors.WHITE}
                                    />
                                }
                                style={
                                    !matches
                                        ? {
                                              alignSelf: "center",
                                          }
                                        : {}
                                }>
                                {strings.friends.addFriend}
                            </GradientBtn>
                        </Box>
                    ) : null}
                </Box>
                <Box
                    sx={[
                        styles.right,
                        !matches && {
                            justifyContent: "center",
                            textAlign: "center",
                        },
                    ]}>
                    {!matches ? (
                        <GradientBtn
                            width={300}
                            onPress={() => setShowAddFriendModal(true)}
                            icon={
                                <IconCompanions
                                    width={25}
                                    height={25}
                                    fill={Colors.WHITE}
                                />
                            }
                            style={
                                !matches
                                    ? {
                                          alignSelf: "center",
                                      }
                                    : {}
                            }>
                            {strings.friends.addFriend}
                        </GradientBtn>
                    ) : null}
                    {isNotEmptyArray(requestList) ? (
                        <Box
                            sx={[
                                styles.listSmall,
                                !matches
                                    ? { alignSelf: "center" }
                                    : { marginLeft: normalizeSize(20) },
                            ]}>
                            <Typography sx={styles.friendsCategory}>
                                {strings.friends.requests}
                            </Typography>
                            {requestList.map(
                                (friend: Friend, index: number) => (
                                    <FriendBox
                                        key={index}
                                        friend={friend}
                                        callback={initData}
                                    />
                                ),
                            )}
                        </Box>
                    ) : null}
                    {isNotEmptyArray(friendsList) ? (
                        <Box
                            sx={[
                                styles.listSmall,
                                !matches
                                    ? { alignSelf: "center" }
                                    : { marginLeft: normalizeSize(20) },
                            ]}>
                            <Typography sx={styles.friendsCategory}>
                                {strings.friends.friends}
                            </Typography>
                            {friendsList.map(
                                (friend: Friend, index: number) => (
                                    <FriendBox
                                        key={index}
                                        friend={friend}
                                        callback={initData}
                                    />
                                ),
                            )}
                        </Box>
                    ) : null}
                    {isNotEmptyArray(inviteList) ? (
                        <Box
                            sx={[
                                styles.listSmall,
                                !matches
                                    ? { alignSelf: "center" }
                                    : { marginLeft: normalizeSize(20) },
                            ]}>
                            <Typography sx={styles.friendsCategory}>
                                {strings.friends.invites}
                            </Typography>
                            {inviteList.map((friend: Friend, index: number) => (
                                <FriendBox
                                    key={index}
                                    friend={friend}
                                    callback={initData}
                                />
                            ))}
                        </Box>
                    ) : null}
                </Box>
                <Modal
                    open={showAddFriendModal}
                    sx={{ outline: "none" }}
                    onClose={() => {
                        setShowAddFriendModal(false);
                    }}>
                    <Box>
                        <AddFriendModal addFriend={addFriend} />
                    </Box>
                </Modal>
            </Box>
        );
    }
};

export default FriendScreen;
