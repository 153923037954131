export const ticketPack2Json = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 361.000014703831,
  w: 300,
  h: 120,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'ticket Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -30, ix: 10 },
        p: { a: 0, k: [176, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [45, 54, 0], ix: 1, l: 2 },
        s: { a: 0, k: [60, 60, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [160, -140], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 11',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.004],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.211],
                        [34.898, -53.466],
                        [41.516, -50.72],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.375],
                        [-41.803, -50.956],
                        [-35.199, -53.667],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45, -205], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 10',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [160, -10], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 7',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45, -75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 5',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.086],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.677],
                        [-16.966, 18.159],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.375],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.922],
                        [-10.947, 49.859],
                        [-15.341, 53.211],
                        [-34.898, 53.466],
                        [-41.516, 50.72],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [160, 250], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 4',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [160, 120], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 3',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45, 185], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket 2',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.767, -0.006],
                        [-0.828, -1.558],
                        [0, 0],
                        [0, 0],
                        [-0.55, -1.673],
                        [1.229, -1.259],
                        [0, 0],
                        [0, 0],
                        [1.422, -1.028],
                        [1.586, 0.778],
                        [0, 0],
                        [0, 0],
                        [1.431, 1.037],
                        [-0.25, 1.74],
                        [0, 0],
                        [0, 0],
                        [-0.54, 1.669],
                        [-1.74, 0.292],
                        [0, 0],
                      ],
                      o: [
                        [0.817, -1.551],
                        [1.767, 0.005],
                        [0, 0],
                        [0, 0],
                        [1.741, 0.302],
                        [0.55, 1.674],
                        [0, 0],
                        [0, 0],
                        [0.258, 1.739],
                        [-1.423, 1.032],
                        [0, 0],
                        [0, 0],
                        [-1.58, 0.768],
                        [-1.429, -1.041],
                        [0, 0],
                        [0, 0],
                        [-1.233, -1.264],
                        [0.543, -1.673],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.267, -21.11],
                        [-0.067, -23.625],
                        [4.15, -21.082],
                        [9.49, -11.037],
                        [20.706, -9.068],
                        [24.426, -5.854],
                        [23.328, -1.085],
                        [15.42, 7.024],
                        [17.072, 18.258],
                        [15.172, 22.761],
                        [10.279, 23.171],
                        [0.052, 18.137],
                        [-10.147, 23.111],
                        [-15.044, 22.678],
                        [-16.966, 18.16],
                        [-15.381, 6.939],
                        [-23.334, -1.218],
                        [-24.462, -5.992],
                        [-20.756, -9.187],
                        [-9.551, -11.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.145098045468, 0.149019613862, 0.184313729405, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 2',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.622, -0.018],
                        [-0.576, -2.121],
                        [-5.162, -0.015],
                        [-1.534, 4.624],
                        [-2.061, -0.008],
                        [0, 0],
                        [-1.76, -1.754],
                        [-0.006, -2.469],
                        [-0.056, -20.275],
                        [1.748, -1.74],
                        [2.48, 0.004],
                        [6.622, 0.018],
                        [0.576, 2.121],
                        [5.162, 0.016],
                        [1.533, -4.623],
                        [2.062, 0.007],
                        [0, 0],
                        [1.761, 1.754],
                        [0.006, 2.47],
                        [0.056, 20.275],
                        [-1.747, 1.74],
                        [-2.481, -0.004],
                      ],
                      o: [
                        [2.2, 0.01],
                        [1.26, 4.712],
                        [5.158, 0.013],
                        [0.53, -1.986],
                        [6.621, -0.294],
                        [2.48, 0.004],
                        [1.755, 1.751],
                        [0.06, 20.278],
                        [0.01, 2.473],
                        [-1.75, 1.744],
                        [0, 0],
                        [-2.2, -0.01],
                        [-1.26, -4.713],
                        [-5.157, -0.013],
                        [-0.531, 1.987],
                        [-6.621, 0.293],
                        [-2.481, -0.003],
                        [-1.755, -1.752],
                        [-0.06, -20.277],
                        [-0.01, -2.473],
                        [1.75, -1.745],
                        [0, 0],
                      ],
                      v: [
                        [-15.642, -53.611],
                        [-10.934, -50.003],
                        [-0.117, -41.923],
                        [10.948, -49.859],
                        [15.341, -53.21],
                        [34.898, -53.466],
                        [41.516, -50.719],
                        [44.269, -44.126],
                        [44.521, 44.374],
                        [41.803, 50.956],
                        [35.2, 53.667],
                        [15.642, 53.609],
                        [10.934, 50.004],
                        [0.117, 41.921],
                        [-10.947, 49.859],
                        [-15.341, 53.21],
                        [-34.898, 53.465],
                        [-41.516, 50.719],
                        [-44.269, 44.125],
                        [-44.521, -44.374],
                        [-41.803, -50.956],
                        [-35.199, -53.666],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45, 55], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'ticket',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [100.5, 24.5],
                    to: [0, 21.583],
                    ti: [0, -21.583],
                  },
                  { t: 360.000014663101, s: [100.5, 154] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [102.5, 22.5], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 8,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 623.00002537531,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
