/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import {
  useMediaQuery,
  Box,
  Typography,
  Input,
  InputAdornment,
  ButtonBase,
  Modal,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { getRouteManager } from "../../../../services/routeManager";
import { getLanguage, strings } from "../../../../services/translation";
import { styles } from "../style";
import { Colors } from "../../../../style";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import { ReactComponent as IconComplete } from "../../../../assets/icons/icon_completed2.svg";
import { ReactComponent as IconEmail } from "../../../../assets/icons/icon_email.svg";
import GoBackBtn from "../../../../components/GoBackBtn";
import GradientBtn from "../../../../components/GradientBtn";
import LoadingView from "../../../../components/LoadingView";
import { columnCenterStyles } from "../../../../style/flex";
import normalize from "../../../../utils/fontSizer";

interface Props {
  callback: any;
  email?: string | undefined;
}

const ForgotPasswordBox = (props: Props) => {
  const { callback, email } = props;

  const matchesWidth = useMediaQuery("(min-width:800px)");
  const matchesHeight = useMediaQuery("(min-height:800px)");

  const [loading, setLoading] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);

  const [errorType, setErrorType] = useState<any>();

  const resetPassword = async (values: any) => {
    try {
      setLoading(true);
      const api = getRouteManager().askForResetPassword();
      const res = await getRouteManager().fetchData(`${api}`, {
        email: values.email,
        lang: getLanguage(),
      });
      if (res.flashmessage && res.flashmessage === "error-google") {
        setErrorType("google");
        setShowPopupError(true);
        setLoading(false);
      } else if (res.flashmessage && res.flashmessage === "error-facebook") {
        setErrorType("fb");
        setShowPopupError(true);
        setLoading(false);
      } else if (res.flashmessage && res.flashmessage === "error-apple") {
        setErrorType("apple");
        setShowPopupError(true);
        setLoading(false);
      } else {
        setShowConfirmed(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleError = (error: string, type: string) => {
    if (error.endsWith("is a required field") && type === "email") {
      return strings.emailRequired;
    }
    if (error.endsWith("must be a valid email") && type === "email") {
      return strings.emailInvalid;
    }
  };

  return (
    <Box
      sx={[
        styles.loginContainer,
        matchesWidth ? { width: "50%" } : { width: "100%", minWidth: "400px" },
      ]}
    >
      <GoBackBtn
        callback={() => {
          callback();
        }}
        extraStyle={{
          borderRadius: "50px",
          position: "absolute",
          top: 37,
          left: 37,
        }}
      />
      <Formik
        initialValues={{
          email: email,
        }}
        onSubmit={(values) => resetPassword(values)}
        validationSchema={yup.object().shape({
          email: yup.string().email().required(),
        })}
      >
        {({
          values,
          handleChange,
          errors,
          setFieldTouched,
          touched,
          isValid,
          handleSubmit,
        }) => (
          <Box>
            {loading ? (
              <LoadingView />
            ) : !showConfirmed ? (
              <Box sx={styles.container}>
                <Box
                  sx={
                    matchesHeight
                      ? { width: "50px", height: "50px" }
                      : { display: "none" }
                  }
                >
                  <IconTalesup width={50} height={50} fill={Colors.WHITE} />
                </Box>
                <Typography sx={styles.title}>
                  {strings.passwordLost}
                </Typography>
                <Typography sx={[styles.subtitle, { marginBottom: "15px" }]}>
                  {strings.resetPasswordIntro1}
                </Typography>
                <Typography sx={styles.subtitle}>
                  {strings.resetPasswordIntro2}
                </Typography>
                <Box sx={[styles.inputContainer]}>
                  <Typography sx={styles.label}>Email</Typography>
                  <Input
                    sx={styles.input}
                    value={values.email}
                    disableUnderline
                    autoFocus
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("email")}
                    placeholder={
                      getLanguage() == "fr"
                        ? "email@email.com"
                        : "myemail@email.com"
                    }
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{ paddingLeft: "5px" }}
                      >
                        <IconEmail width={20} height={20} fill={Colors.WHITE} />
                      </InputAdornment>
                    }
                  />
                  {touched.email && errors.email && (
                    <Typography sx={styles.error}>
                      {handleError(errors.email, "email")}
                    </Typography>
                  )}
                </Box>
                <Box sx={styles.submitBtn}>
                  <GradientBtn
                    disabled={!isValid || errors.email || values.email == ""}
                    onPress={handleSubmit}
                  >
                    {strings.rescueMe}
                  </GradientBtn>
                </Box>
              </Box>
            ) : (
              <Box sx={styles.container}>
                <Box
                  sx={
                    matchesHeight
                      ? { width: "50px", height: "50px" }
                      : { display: "none" }
                  }
                >
                  <IconComplete
                    width={50}
                    height={50}
                    fill={Colors.vertALEXTESGRANDSMORTS}
                  />
                </Box>
                <Typography sx={styles.title}>
                  {strings.confirmedForgot}
                </Typography>
                <Typography sx={styles.subtitle}>
                  {strings.resetEmailSent}
                </Typography>
                <ButtonBase
                  sx={{ width: "50%" }}
                  onClick={() => {
                    if (values) {
                      resetPassword(values);
                    }
                  }}
                >
                  <Box sx={styles.notReceivedBtn}>
                    <Typography sx={styles.notReceivedBtnText}>
                      {strings.stillNotReceived}
                    </Typography>
                  </Box>
                </ButtonBase>
                <GradientBtn onPress={() => callback()}>
                  {strings.great}
                </GradientBtn>
              </Box>
            )}
          </Box>
        )}
      </Formik>
      <Modal open={showPopupError} onClose={() => setShowPopupError(false)}>
        <Box style={{ ...columnCenterStyles }}>
          <Box style={styles.modalViewError}>
            <Typography
              style={{
                color: Colors.WHITE,
                fontFamily: "Eczar-Regular",
                fontSize: normalize(36),
                marginBottom: "18px",
                textAlign: "center",
              }}
            >
              {strings.error}
            </Typography>
            <Typography sx={styles.textModalError}>
              {errorType === "google"
                ? strings.errorGooglePwd
                : errorType === "fb"
                ? strings.errorFbPwd
                : errorType === "apple"
                ? strings.errorGooglePwd
                : null}
            </Typography>
            <Box style={styles.separatorError} />
            <ButtonBase onClick={() => setShowPopupError(false)}>
              <Typography style={styles.deleteStoryText}>
                {strings.understood}
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ForgotPasswordBox;
