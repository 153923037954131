/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import React, { useState, useRef, useCallback } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";
import { Colors } from "../../style";
import Card from "./card";
import Separator from "../Separator";
import SkinButton from "../SkinButton";
import { useNavigate } from "react-router";
import Pass from "../Pass";

interface Props {
  cards: any;
  findStory: any;
  cardWidth?: number | undefined; // Largeur d'une carte de référence en pixels
  cardHeight?: number | undefined; // hauteur d'une carte de référence en pixels
  cardSpacing?: number | undefined; // marge entre les cartes en pixels
  screenWidth?: number | undefined; // marge entre les cartes en pixels
  style?: any;
}

const Carousel = (props: Props) => {
  const {
    cards,
    cardWidth = 404,
    cardHeight = 204,
    cardSpacing = 16,
    screenWidth = 0,
    findStory,
    style,
  } = props;

  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showSkinsModal, setShowSkinsModal] = useState<boolean>(false);
  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const [skinActive, setSkinActive] = useState<any>(null);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const handlePrev = () => {
    const newIndex = activeIndex === 0 ? cards.length - 1 : activeIndex - 1;
    handleScrollToIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = activeIndex === cards.length - 1 ? 0 : activeIndex + 1;
    handleScrollToIndex(newIndex);
  };

  const handleScrollToIndex = (index: number) => {
    setActiveIndex(index);
    const scrollLeft = index * (cardWidth + cardSpacing);
    carouselRef.current?.scrollTo({ left: scrollLeft, behavior: "smooth" });
  };

  const showSkinsModalCallback = (item: any) => {
    if (item) {
      setSkinActive(item);
      setShowSkinsModal(true);
    }
  };

  const closeSkinModal = (item: any, itemType?: any) => {
    setShowSkinsModal(false);
    navigate(`/editUser?itemCategory=${itemType}`);
  };

  /* RENDER FUNCTIONS */

  const renderItemSkin = useCallback((skin: any, index: number) => {
    return (
      <SkinButton skin={skin} callback={closeSkinModal} key={"skin-" + index} />
    );
  }, []);

  return (
    <Box
      sx={[
        { ...columnCenterStyles, width: "100%", position: "relative" },
        style ? style : {},
      ]}>
      {cards.length > 1 ? (
        <ButtonBase
          onClick={handlePrev}
          disableRipple
          sx={[
            styles.prev,
            screenWidth && cardWidth === 1432
              ? { left: `${(screenWidth - 1432) / 2 - screenWidth * 0.05}px` }
              : {},
          ]}>
          <NavigateBeforeIcon sx={{ fontSize: "2.3rem" }} />
        </ButtonBase>
      ) : null}

      <Box sx={{ ...rowCenterStyles, width: "100%", height: "100%" }}>
        <Box sx={{ width: "5%" }} />
        <Box
          position="relative"
          width={cardWidth}
          sx={styles.carouselOuterContainer}>
          <Box sx={styles.carouselContainer}>
            <Box
              ref={carouselRef}
              sx={[
                styles.carousel,
                {
                  transform: `translateX(-${
                    activeIndex * (cardWidth + cardSpacing)
                  }px)`,
                },
              ]}>
              {cards.map((card: any, index: number) => {
                return (
                  <Card
                    key={index}
                    card={card}
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    cardSpacing={cardSpacing}
                    active={activeIndex === index}
                    findStory={findStory}
                    showSkinsModalCallback={showSkinsModalCallback}
                    showPassModal={setShowPassModal}
                  />
                );
              })}
            </Box>
          </Box>
          {cards.length && cards.length > 1 ? (
            <Box
              sx={{
                position: "absolute",
                bottom: 10,
                zIndex: 3,
                width: "100%",
                marginTop: "16px",
                ...rowCenterStyles,
              }}>
              {cards.map((card: any, index: number) => (
                <Box
                  key={"dot-" + index}
                  sx={{
                    ...columnCenterStyles,
                    width: activeIndex === index ? "10px" : "8px",
                    height: activeIndex === index ? "10px" : "8px",
                    borderRadius: "50%",
                    backgroundColor:
                      activeIndex === index ? Colors.WHITE : Colors.OLD_PRICE,
                    marginX: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleScrollToIndex(index)}
                />
              ))}
            </Box>
          ) : null}
        </Box>
        <Box sx={{ width: "5%" }} />
      </Box>
      {cards.length > 1 ? (
        <ButtonBase
          onClick={handleNext}
          disableRipple
          sx={[
            styles.next,
            screenWidth && cardWidth === 1432
              ? { right: `${(screenWidth - 1432) / 2 - screenWidth * 0.05}px` }
              : {},
            {
              "&:hover nextIconCarousel": {
                fontSize: "2.5rem",
                color: "red",
              },
            },
          ]}>
          <NavigateNextIcon
            className="nextIconCarousel"
            sx={[{ fontSize: "2.3rem" }]}
          />
        </ButtonBase>
      ) : null}
      {showSkinsModal && skinActive && skinActive?.length ? (
        <Modal open={showSkinsModal} onClose={() => setShowSkinsModal(false)}>
          <Box
            sx={[
              styles.modalView,
              { width: "400px", padding: "28px 0px 5px" },
            ]}>
            {activeIndex &&
            cards &&
            cards[activeIndex] &&
            cards[activeIndex].subtitle ? (
              <Typography
                style={{
                  color: Colors.WHITE,
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  marginBottom: "20px",
                  textAlign: "center",
                  marginTop: "10px",
                }}>
                {cards[activeIndex].subtitle}
              </Typography>
            ) : null}
            <Separator style={{ marginBottom: "20px" }} />
            {skinActive &&
              skinActive.length &&
              skinActive.map((skin: any, index: number) => {
                return renderItemSkin(skin, index);
              })}
          </Box>
        </Modal>
      ) : null}
      {showPassModal ? (
        <Modal
          open={showPassModal}
          onClose={() => setShowPassModal(false)}
          sx={{ ...columnCenterStyles }}>
          <Box sx={{ outline: "none" }}>
            <Pass />
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};

const styles = {
  carouselOuterContainer: { margin: "0 0" },
  carouselContainer: {
    width: "100%",
    overflow: "hidden",
    borderRadius: "13px",
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
    borderRadius: "13px",
  },
  prev: {
    width: "5%",
    position: "absolute",
    left: 0,
    height: "100%",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: 2,
    color: "#fff",
  },
  next: {
    width: "5%",
    position: "absolute",
    right: 0,
    height: "100%",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: 2,
    color: "#fff",
  },
  modalView: {
    width: "300px",
    borderRadius: "20px",
    backgroundColor: Colors.PRIMARY,
    position: "relative",
    ...columnCenterStyles,
    zIndex: 50,
  },
};

export default Carousel;
