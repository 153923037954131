/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
} from "@mui/material";
import EpisodeAnimated from "../../components/episodeAnimated";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import PreGame from "../Pregame";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import PregameMode from "../PregameMode";
import LoadSaveScreen from "../../pages/Saves/loadSave";
import { isNotEmptyString } from "../../utils/StringHelper";
import SoundManager from "../../services/SoundManager";
import { getRouteManager } from "../../services/routeManager";
import { useNavigate } from "react-router";
import { onCreateGame } from "../../services/GameOnlineManager";
import useAuth from "../../services/Auth/useAuth";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import EpisodeAnimatedSaga from "../EpisodeAnimatedSaga";
import BackgroundRow from "../BackgroundRow";
import { columnCenterStyles, columnStartStyles } from "../../style/flex";
import LoadingView from "../LoadingView";
import { propertyExists } from "../../utils/ObjectHelper";
import { getLanguage } from "../../services/translation";
import BuySeriesModal from "../BuySeriesModal";
import BuyAllEpisodesBtn from "../BuyAllEpisodesBtn";

interface Props {
  active: any;
  callback: any;
  isSeries?: boolean;
  showEpisodesModal: boolean;
}

const PregameModal = (props: Props) => {
  const { active, callback, showEpisodesModal, isSeries } = props;
  const navigate = useNavigate();
  const auth = useAuth();
  const { user } = auth;
  const [selectedEpisode, setSelectedEpisode] = useState<any>(
    isSeries && isNotEmptyArray(active.seriesEpisodes)
      ? null
      : { story: active },
  );
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [episodes, setEpisodes] = useState<any>([]);
  const [priceCount, setPriceCount] = useState(0);
  const [priceNormalCount, setPriceNormalCount] = useState(0);
  const [remainingEpisodes, setRemainingEpisodes] = useState(0);
  const [hasPromo, setHasPromo] = useState(false);
  const [showPopUpBuyAllEpisodes, setShowPopUpBuyAllEpisodes] = useState(false);

  useEffect(() => {
    if (active && isSeries) {
      getEpisodes(active);
    } else {
      setLoading(false);
    }
  }, [active]);

  useEffect(() => {
    if (
      active &&
      selectedEpisode &&
      (!isSeries || (selectedEpisode.number && selectedEpisode.number === 1)) &&
      isNotEmptyString(selectedMode) &&
      selectedMode === "online"
    ) {
      setLoading(true);
      createOnlineGame();
    }
  }, [selectedMode, selectedEpisode]);

  const createOnlineGame = async () => {
    const storyId = isSeries ? selectedEpisode.story.id : active.id;
    const api = getRouteManager().colorsForStory(storyId);
    const story = isSeries ? selectedEpisode.story : active;
    const colors = await getRouteManager().getData(api);
    await onCreateGame(
      storyId,
      colors,
      story,
      isTrueBoolean(isSeries),
      story.nbPlayerMin,
      story.nbPlayerMax,
      isTrueBoolean(isSeries) ? story.title : story.storyTrad?.title,
      story.updated,
      navigate,
      user,
      isSeries && selectedEpisode ? selectedEpisode : null,
    );
  };

  /**
   *
   */
  const getEpisodes = async (series: any) => {
    const token = propertyExists(user, "login_token")
      ? user.login_token
      : propertyExists(user, "loginToken")
        ? user.loginToken
        : null;

    if (isNotEmptyString(token)) {
      const api = getRouteManager().getEpisodesForSeries(
        series.id,
        token,
        getLanguage(),
      );

      const ep = await getRouteManager().getData(`${api}`);

      let price = 0;
      let priceInitial = 0;
      let epRemain = 0;
      let promoActive = false;

      await Promise.all(
        ep.map(async (e: any, index: number) => {
          e.key = index;

          if (e.story.price != null && e.story.price != 0 && !(getLanguage() === 'fr' && e.releaseDate) && !(getLanguage() !== 'fr' && e.releaseDateEn)) {
            priceInitial += e.story.price;
            if (series.promo) {
              const promo = series.promo;
              setHasPromo(true);
              promoActive = true;
              e.story.promo = series.promo;
              price += Math.ceil(
                e.story.price * ((100 - promo.discount) / 100),
              );
            } else {
              price += e.story.price;
            }

            if (e.story.price !== 0) {
              epRemain++;
            }
          }

          const colorApi = getRouteManager().colorsForStory(e.story.id);
          const colors = await getRouteManager().getData(`${colorApi}`);
          const accentColor = colors.filter(
            (entry: any) => entry.type === "tertiary",
          );

          e.story.accentColor = accentColor[0].value;
        }),
      );

      if (!promoActive && series.hasLast && !series.isSaga) {
        if (epRemain > 1 && epRemain <= 4) {
          const percent = 1 - ((epRemain - 1) * 0.05 + 0.05);
          price = Math.ceil(percent * price);
        } else if (epRemain > 4) {
          price = Math.ceil(0.8 * price);
        }
      }

      setPriceCount(price);
      setPriceNormalCount(priceInitial);
      setRemainingEpisodes(epRemain);
      setEpisodes([...ep]);
      setLoading(false);
    }
  };

  const goToShop = () => {
    callback(false);
    navigate("/shop");
  };

  if (loading) {
    return <LoadingView style={{ width: "100vw", height: "100vh" }} />;
  } else if (active && showEpisodesModal) {
    return (
      <Box
        className="ReactModal__Content"
        sx={[
          styles.episodeModal,
          {
            background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%),
        url(${active.image_backdrop}) 50%/cover`,
          },
        ]}>
        {active && active.seriesEpisodes?.length && !selectedEpisode ? (
          <BackgroundRow
            goBack
            goBackAction={() => {
              callback(false);
            }}
            matchesValue={762}
            hasCurrencies
            currenciesCallback={() => goToShop()}
            leftPart={
              <Box sx={styles.container}>
                <Typography
                  sx={{
                    color: Colors.WHITE,
                    fontSize: "50px",
                    fontWeight: 500,
                    fontFamily: "Eczar-Regular",
                  }}>
                  {active.storyTrad?.title}
                </Typography>
                <Typography sx={styles.storyDescription}>
                  {active.isSeries
                    ? active.storyTrad.description
                    : active.storyTrad.resume}
                </Typography>
              </Box>
            }
            hasBackground
            backgroundImage={active.image_backdrop}
            rightPartStyle={{
              minWidth: "300px",
              paddingLeft: 0,
            }}
            rightPart={
              <Box sx={[styles.episodesList]}>
                {priceCount != 0 &&
                  episodes != null &&
                  remainingEpisodes > 1 &&
                  (!isTrueBoolean(active.isSaga) ||
                    (isTrueBoolean(active.isSaga) &&
                      isTrueBoolean(active.hasLast))) ? (
                  <BuyAllEpisodesBtn
                    callback={() => setShowPopUpBuyAllEpisodes(true)}
                    episodes={episodes}
                    priceCount={priceCount}
                    priceNormalCount={priceNormalCount}
                    remainingEpisodes={remainingEpisodes}
                    hasPromo={hasPromo}
                    series={active}
                  />
                ) : null}
                {episodes &&
                  episodes.length &&
                  episodes.map((ep: any) => {
                    if (active.isSaga) {
                      return (
                        <EpisodeAnimatedSaga
                          key={"ep" + ep.id}
                          number={ep.number}
                          goNext={() => {
                            SoundManager.getInstance().playBtnSound();
                            setSelectedEpisode(ep);
                          }}
                          episode={ep}
                        />
                      );
                    } else {
                      return (
                        <EpisodeAnimated
                          key={"ep" + ep.id}
                          number={ep.number}
                          time={ep.story.averageTime}
                          episode={ep}
                          /* series={active} */
                          onPressContinue={() => {
                            SoundManager.getInstance().playBtnSound();
                            setSelectedEpisode(ep);
                          }}
                        />
                      );
                    }
                  })}
                <Box height={"80px"} />
                <Modal
                  open={showPopUpBuyAllEpisodes}
                  onClose={() => setShowPopUpBuyAllEpisodes(false)}>
                  <Box
                    sx={{
                      width: "300px",
                      borderRadius: "20px",
                      backgroundColor: Colors.PRIMARY,
                      position: "relative",
                      padding: "40px 0px 20px",
                      ...columnCenterStyles,
                      zIndex: 50,
                    }}>
                    <BuySeriesModal
                      series={active}
                      episodes={episodes}
                      closeModal={() => setShowPopUpBuyAllEpisodes(false)}
                      priceCount={priceCount}
                      priceNormalCount={priceNormalCount}
                      refreshEpisodes={getEpisodes}
                      remainingEpisodes={remainingEpisodes}
                      hasCloseBtn
                      isFromModal
                    />
                  </Box>
                </Modal>
              </Box>
            }
          />
        ) : active && selectedEpisode && !isNotEmptyString(selectedMode) && (!isSeries ||
          (selectedEpisode.number && selectedEpisode.number === 1))? (
          <PregameMode
            goNext={setSelectedMode}
            storyId={selectedEpisode.story.id}
            active={active}
            episode={selectedEpisode}
            callback={() =>
              isSeries ? setSelectedEpisode(null) : callback(false)
            }
          />
        ) : active &&
          selectedEpisode &&
          selectedEpisode?.story &&
          (!isSeries ||
            (selectedEpisode.number && selectedEpisode.number === 1)) &&
          isNotEmptyString(selectedMode) &&
          selectedMode !== "online" ? (
          <PreGame
            callback={() => setSelectedMode("")}
            storyId={selectedEpisode.story?.id}
            active={active}
            title={
              selectedEpisode.story.title
                ? selectedEpisode.story.title
                : selectedEpisode.story?.storyTrad?.title
            }
            colors={[]}
            nbPlayerMin={selectedEpisode.story.nbPlayerMin}
            nbPlayerMax={selectedEpisode.story.nbPlayerMax}
            intro={
              selectedEpisode.story.intro
                ? selectedEpisode.story.intro
                : selectedEpisode.story.storyTrad?.intro
            }
            episode={selectedEpisode}
            solo={selectedMode === "solo"}
            isBoard={selectedMode === "board"}
          />
        ) : active &&
          selectedEpisode &&
          isSeries &&
          selectedEpisode.number &&
          selectedEpisode.number !== 1
          /* && isNotEmptyString(selectedMode) */ ? (
          <LoadSaveScreen
            callback={() => isSeries ? setSelectedEpisode(null) : callback(false)}
            episode={selectedEpisode}
            storyId={selectedEpisode.story.id}
            //type={selectedMode}
          />
        ) : null}
      </Box>
    );
  } else return null;
};

const styles = {
  episodeModal: {
    width: "100%",
    minHeight: "100vh",
    p: 0,
    backgroundColor: Colors.PRIMARY,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    /* overflow: "auto",
    "-webkit-overflow-scrolling": "touch",
    outline: "none",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    borderRadius: "13px",
    border: "none",
    padding: "20px", */
  },
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    marginRight: "5%",
  },
  modalBody: {
    display: "flex",
    width: "90%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBodyLeft: {
    display: "flex",
    width: "30%",
    maxWidth: "500px",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "10px",
    alignItems: "flex-start",
  },
  modalBodyRight: {
    display: "flex",
    width: "70%",
    minHeight: "100vh",
    paddingLeft: "5%",
    flexDirection: "column",
    justifyContent: "center",
  },
  episodesList: {
    display: "flex",
    width: "300px",
    flexDirection: "column",
    rowGap: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedStoryInfos: {
    display: "flex",
    width: "70%",
    backgroundColor: "red",
    marginTop: "1vh",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "20px",
  },
  iconInfo: {
    color: Colors.TOKEN,
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    marginLeft: "7px",
  },
  themes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
  },
  storyTheme: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "2.89286px 11.5714px",
    borderRadius: "100px",
    border: "solid 3px",
    marginRight: "5px",
  },
  storyDescription: {
    color: Colors.WHITE,
    fontSize: "18px",
    marginTop: "15px",
    marginBottom: "25px",
    fontFamily: "Gilroy-Bold",
  },
};

export default PregameModal;
