/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Colors } from "../../style";
import { Box, FormControlLabel, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { rowStartStyles } from "../../style/flex";

const CustomCheckboxRegister = ({
  children,
  value,
  handleChange,
  style,
}: {
  children?: any;
  value?: any;
  handleChange?: any;
  style?: any;
}) => {
  const [toggleCheckBox, setToggleCheckBox] = useState<any>(value);
  const handleChangeMui = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleCheckBox(event.target.checked);
    handleChange(event.target.checked);
  };
  return (
    <Box sx={[styles.container, style ? style : {}]}>
      <FormControlLabel
        label={
          <Typography component={"span"} sx={styles.newsletterText}>
            {children}
          </Typography>
        }
        control={
          <Checkbox
            checked={toggleCheckBox}
            onChange={handleChangeMui}
            sx={{
              color: toggleCheckBox ? Colors.ACCENT : Colors.LOCK,
              "&.Mui-checked": {
                color: Colors.ACCENT,
              },
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
      />
    </Box>
  );
};

const styles = {
  container: {
    ...rowStartStyles,
    width: "85%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "20px",
  },
  newsletterText: {
    fontSize: "calc(12px + 0.3vh)",
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    paddingLeft: "20px",
  },
};

export default CustomCheckboxRegister;
