import { Colors } from "../../../style";
import { columnCenterStyles } from "../../../style/flex";
import normalize from "../../../utils/fontSizer";

export const styles = {
    all: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: Colors.PRIMARY,
      overflow: "auto",
      scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
    },
    topMenu: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: "5%",
      paddingBottom: "1.5vh",
      margin: 0,
      borderBottom: "2px solid #25262F",
    },
    userName: {
      fontSize: "2.2vh",
      fontFamily: "Gilroy-Bold",
      color: Colors.WHITE,
    },
    editIcon: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    userTitle: {
      fontSize: "2.2vh",
      fontFamily: "Gilroy-Bold",
      color: Colors.ACCENT,
    },
    userId: {
      fontSize: "1.5vh",
      fontFamily: "Gilroy-Bold",
      color: Colors.BTN_INACTIVE,
      maxWidth: "100%",
      marginLeft: 5,
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
    modalViewBuyEpisode: {
      width: "30%",
      backgroundColor: Colors.PRIMARY,
      borderRadius: "20px",
      paddingTop: "50px",
      paddingBottom: "50px",
      ...columnCenterStyles,
      outline: "none",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    separatorBuyEpisode: {
      width: 300,
      height: 0,
      color: Colors.BTN_ACTIVE,
      borderColor: Colors.BTN_ACTIVE,
      borderWidth: 1,
      marginTop: 25,
    },
    popupButtons: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      marginTop: "15px",
    },
    yesButton: {
      backgroundColor: Colors.ACCENT,
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "11px",
      paddingBottom: "11px",
      borderRadius: "10px",
      minWidth: "100px",
    },
    yeahButton: {
      backgroundColor: Colors.ACCENT,
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "11px",
      paddingBottom: "11px",
      borderRadius: "10px",
      minWidth: "100px",
    },
    yesText: {
      fontSize: normalize(14),
      fontFamily: "Gilroy-Bold",
      color: Colors.WHITE,
      textAlign: "center",
    },
    noButton: {
      backgroundColor: Colors.BTN_ACTIVE,
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "11px",
      paddingBottom: "11px",
      borderRadius: "10px",
      minWidth: "100px",
    },
    noText: {
      fontSize: normalize(14),
      fontFamily: "Gilroy-Bold",
      color: Colors.OLD_PRICE,
      textAlign: "center",
    },
    myInputView: {
      width: "100%",
      height: 60,
      backgroundColor: Colors.BTN_ACTIVE,
      borderRadius: 13,
      paddingLeft: "10px",
      flexDirection: "row",
      alignItems: "center",
      color: Colors.WHITE,
      fontFamily: "Gilroy-Bold",
      fontSize: normalize(16),
    },
    myInput: {
      width: "70%",
      backgroundColor: Colors.BTN_ACTIVE,
      color: Colors.WHITE,
      fontFamily: "Gilroy-Bold",
      fontSize: normalize(12),
      marginLeft: 20,
      height: 60,
      marginVertical: 2,
    },
    itemImage: {
      width: 90,
    },
    itemBlock: {
      width: "23%",
      height: "23%",
      backgroundColor: Colors.BTN_ACTIVE,
      borderRadius: 13,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 15,
      marginBottom: 15,
    },
    itemBlockEquiped: {
      width: "23%",
      height: "23%",
      backgroundColor: Colors.BTN_ACTIVE,
      borderRadius: 13,
      borderWidth: 2,
      borderColor: Colors.ACCENT,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 15,
      marginBottom: 15,
    },
    storyPriceBlock: {
      position: "absolute",
      top: 33,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    storyPrice: {
      fontSize: normalize(16),
      fontFamily: "Gilroy-Bold",
      color: Colors.TICKET,
      marginLeft: 7,
    },
    iconsMenu: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      borderBottomColor: Colors.BTN_ACTIVE,
      borderBottomWidth: 2,
    },
    iconActive: {
      borderBottomColor: Colors.WHITE,
      borderBottomWidth: 2,
      width: "20%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    iconInactive: {
      width: "20%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    item: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "center",
      alignContent: "center",
      alignSelf: "center",
      justifySelf: "center",
      width: "80%",
    },
  };
  