/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";

import { ReactComponent as HeartFilled } from "../../assets/icons/heart_filled.svg";
import { ReactComponent as HeartOutline } from "../../assets/icons/heart_outline.svg";
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import { getRouteManager } from "../../services/routeManager";
import { Box, ButtonBase, Typography } from "@mui/material";

interface Props {
    defaultLiked: boolean;
    newsId: number;
    likeNumber: any;
}

const styles = {
    likedText: {
        fontFamily: "Gilroy-Bold",
        color: Colors.FOND_ITEM_POPUP,
        fontSize: normalize(12),
        marginTop: "3px",
    },
    likeBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.PRIMARY,
        width: "40px",
        height: "40px",
        borderRadius: "50px",
    },
};

const Heart = (props: Props) => {
    const { defaultLiked, newsId, likeNumber } = props;
    const [liked, setLiked] = useState<any>(null);
    const [likedNumber, setLikeNumber] = useState(likeNumber);
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        const value = localStorage.getItem(`${newsId}`);
        if (value == null) {
            setLiked(false);
        } else {
            setLiked(true);
        }
    }, [newsId]);

    const like = async () => {
        setLiked(true);
        setLikeNumber(likedNumber + 1);
        const url = getRouteManager().likeNews(newsId);
        await fetch(`${url}`);
        localStorage.setItem(`${newsId}`, "1");
    };

    const unLike = async () => {
        setLiked(false);
        setLikeNumber(likedNumber - 1);
        const url = getRouteManager().unlikeNews(newsId);
        await fetch(`${url}`);
        localStorage.removeItem(`${newsId}`);
    };

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <Box>
                {liked === true ? (
                    <ButtonBase onClick={() => unLike()}>
                        <Box style={styles.likeBtn}>
                            <HeartFilled
                                width={20}
                                height={20}
                                fill={Colors.HEART}
                            />
                        </Box>
                    </ButtonBase>
                ) : (
                    <ButtonBase onClick={() => like()}>
                        <Box style={styles.likeBtn}>
                            <HeartOutline width={20} height={20} />
                        </Box>
                    </ButtonBase>
                )}
            </Box>
            <Typography
                sx={[styles.likedText, liked ? { color: Colors.HEART } : {}]}>
                {likedNumber}
            </Typography>
        </Box>
    );
};

export default Heart;
