import { popupSoundJson } from "../../constants/popupSound";
import { Box } from "@mui/material";
import AnimationColorRenderer from "../../components/AnimationColorRenderer";
import DicePopup from "../../components/DicePopup";
import ColorManager from "../../services/colorManager";


const TestScreen = () => {

    ColorManager.getInstance().generateColors([{ "id": 28, "value": "#131A1B", "type": "primary", "storyId": 41 }, { "id": 29, "value": "#263437", "type": "secondary", "storyId": 41 }, { "id": 31, "value": "#FEC85E", "type": "tertiary", "storyId": 41 }, { "id": 32, "value": "#FFFFFF", "type": "quaternary", "storyId": 41 }, { "id": 48, "value": "#1D2729", "type": "quinary", "storyId": 41 }])
    return (
        <Box sx={styles.container} className="testclass">
            {/* <AnimationColorRenderer
                anim={popupSoundJson}
                colorsToReplace={['#7970FF', [60, 58, 100, 1]]}
                targetColors={['#ffffff', '#f00000']} /> */}
            <DicePopup
                callback={() => null}
                choice={JSON.parse(`{ "text": "Se rappeler le plan", "sceneLink": null, "answer": null, "ornament": null, "conditions": [], "drawConditions": [], "playerStateForbidden": "", "isRandom": true, "randomScenes": [{ "sceneLink": 2, "proba": 60 }, { "sceneLink": 3, "proba": 40 }], "diceNumber": 8, "diceProbaType": "first" }`)}
                random={12} />
        </Box>);
}

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: "flex",
    },
}

export default TestScreen;