/* eslint-disable @typescript-eslint/no-explicit-any */
import { Colors } from "../../style";
import {
  columnCenterStyles,
  rowCenterStyles,
  rowEndStyles,
} from "../../style/flex";

// =======================================================

const circleStyles: any = {
  width: "16px",
  height: "16px",
  backgroundColor: "red",
  borderRadius: "8px",
};

// =======================================================

export default {
  button: {
    position: 'relative',
    width: "22.9%",
    maxWidth: "330px",
    minWidth: "230px",
    borderRadius: "13px",
    marginBottom: "10px",
    height: "5vh",
    maxHeight: "66px",
    minHeight: "52px",
    ...columnCenterStyles,
    zIndex: 12,
    opacity: 1,
  },
  text: {
    fontSize: "calc(12px + 0.45vh)",
    color: Colors.WHITE,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    zIndex: 20,
  },
  animationView: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
  },
  touchable: {
    ...columnCenterStyles,
    height: "100%",
    width: "100%",
    borderRadius: "13px",
    overflow: "hidden",
  },
  playerIconContainer: {
    ...rowCenterStyles,
  },
  circle: {
    ...circleStyles,
    position: "absolute",
    //marginLeft: "15px",
  },
  voterWrapper: {
    position: "absolute",
    top: "-5px",
    right: 0,
    zIndex: 1,
    ...rowEndStyles,
  },
  voterShape: {
    ...circleStyles,
    borderStyle: "solid",
    marginHorizontal: "1px",
    borderWidth: "2px",
  },
};

// =======================================================
