/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import moment from "moment";
import GradientText from "../GradientText";
import { Box, Typography } from "@mui/material";

interface Props {
  toDate: any;
  textColor: string;
  textGradient: boolean;
  gradientColor1?: string;
  gradientColor2?: string;
  style?: any;
  icon?: any;
  toHour?: number | undefined;
  toMinute?: number | undefined;
  toSecond?: number | undefined;
}

const TimerDate = (props: Props) => {
  const {
    toDate,
    textColor,
    textGradient,
    gradientColor1,
    gradientColor2,
    style,
    icon,
    toHour,
    toMinute,
    toSecond,
  } = props;

  const [endDate, setEndDate] = useState<any>();

  const setRemainingTime = () => {
    const date = getRemainingTime();
    if (date != endDate) {
      setEndDate(date);
    }
  };

  const getRemainingTime = () => {
    const date = moment
      .utc(toDate)
      .set("hour", toHour != null ? toHour : 8)
      .set("minute", toMinute != null ? toMinute : 0)
      .set("seconds", toSecond != null ? toSecond : 0);
    const dur = moment.duration(moment.utc(date).diff(moment()));
    const daysRemain = dur.days();
    const hoursRemain =
      daysRemain < 1 ? dur.hours() + daysRemain * 24 : dur.hours();
    const minutesRemain = dur.minutes();
    const secondsRemain = dur.seconds();
    if (daysRemain >= 1) {
      return (
        daysRemain +
        strings.time.d +
        hoursRemain +
        strings.time.h +
        (minutesRemain < 10 ? "0" + minutesRemain : minutesRemain)
      );
    } else if (hoursRemain < 10) {
      if (hoursRemain < 1) {
        if (hoursRemain <= 0 && minutesRemain <= 0 && secondsRemain <= 0) {
          return "00" + strings.time.min + "00" + strings.time.s;
        } else {
          return (
            (minutesRemain < 10 ? "0" + minutesRemain : minutesRemain) +
            strings.time.min +
            (secondsRemain < 10 ? "0" + secondsRemain : secondsRemain) +
            strings.time.s
          );
        }
      }
      return (
        hoursRemain +
        strings.time.h +
        (minutesRemain < 10 ? "0" + minutesRemain : minutesRemain) +
        strings.time.m +
        (secondsRemain < 10 ? "0" + secondsRemain : secondsRemain) +
        strings.time.s
      );
    }
    const minutes = minutesRemain < 10 ? "0" + minutesRemain : minutesRemain;
    //const secondes = secondsRemain < 10 ? '0' + secondsRemain : secondsRemain;
    return hoursRemain + "h : " + minutes;
  };

  useEffect(() => {
    const inter = setInterval(setRemainingTime, 1000);
    return () => clearInterval(inter);
  }, []);

  return (
    <>
      {endDate && (
        <Box sx={(styles.promoTimer, style)}>
          {icon ? (
            <>
              {icon()}
              <Box style={{ width: 5 }} />
            </>
          ) : null}
          {textGradient && gradientColor1 && gradientColor2 ? (
            <GradientText
              color1={gradientColor1}
              color2={gradientColor2}
              style={styles.promoTagTextTimer}
            >
              {endDate}
            </GradientText>
          ) : (
            <Typography
              sx={{
                ...styles.promoTagTextTimer,
                ...(textColor ? { color: textColor } : { color: Colors.WHITE }),
              }}
            >
              {endDate}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

const styles = {
  promoTagTextTimer: {
    color: Colors.ACCENT,
    fontSize: '16px',
    fontFamily: "Gilroy-Bold",
  },
  promoTimer: {
    backgroundColor: Colors.PRIMARY,
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingVertical: 7,
    borderRadius: 7,
    alignItems: "center",
  },
};

export default React.memo(TimerDate);
