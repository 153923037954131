/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { getRouteManager } from "../../services/routeManager";
import { strings } from "../../services/translation";
import TicketPromoAnimated from "../TicketPromoAnimated";
import TicketAnimated from "../TicketAnimated";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { rowStartStyles } from "../../style/flex";
import useAuth from "../../services/Auth/useAuth";
import PassAnimated from "../PassAnimated";
import GradientText from "../GradientText";

interface Props {
  episodes: any;
  remainingEpisodes: number;
  series: any;
  callback: any;
  hasPromo?: boolean | undefined;
  priceCount: any;
  priceNormalCount: any;
}

const BuyAllEpisodesBtn = (props: Props) => {
  const {
    episodes,
    remainingEpisodes,
    series,
    callback,
    hasPromo,
    priceCount,
    priceNormalCount,
  } = props;

  const auth = useAuth();

  return (
    <ButtonBase sx={styles.container} onClick={() => callback()}>
      <Box sx={styles.buyAll}>
        <Box
          sx={[
            styles.leftPart,
            {
              background: `linear-gradient(270deg, #17181C 0%, rgba(23, 24, 28, 0) 100%), no-repeat center/100% url(${getRouteManager().getCover(
                series.idCover
              )})`,
            },
          ]}
        />
        {episodes != null && remainingEpisodes > 1 && (
          <Box sx={styles.rightPart}>
            <Typography sx={styles.getAll}>{strings.getAllEpisodes}</Typography>

            <Typography sx={styles.nbEpisodes}>
              {remainingEpisodes} {strings.episodes}
            </Typography>

            <Box style={styles.storyPriceBlock}>
              {!auth?.user?.pass?.isActive ? (
                <>
                  <Box sx={styles.ticketsOwned}>
                    <PassAnimated
                      style={{
                        width: 16,
                        height: 16,
                      }}
                    />
                    <GradientText
                      color1={Colors.PASS1}
                      color2={Colors.PASS2}
                      style={styles.gradientTextSmall}>
                      {strings.pass.pass}
                    </GradientText>
                  </Box>
                  <Box
                    style={{
                      width: '2px',
                      height: "30px",
                      borderRadius: "2px",
                      margin: "0 10px",
                      backgroundColor: Colors.FOND_ITEM_POPUP,
                    }}
                  />
                </>

              ) : null}
              {hasPromo ? (
                <TicketPromoAnimated style={{ width: 20, height: 20 }} />
              ) : (
                <TicketAnimated style={{ width: 20, height: 20 }} />
              )}

              <Typography component={"span"} sx={[styles.storyPrice]}>
                {priceNormalCount && priceNormalCount !== priceCount ? (
                  <Typography
                    component={"span"}
                    sx={{
                      color: Colors.OLD_PRICE,
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                      fontSize: normalize(12),
                    }}
                  >
                    {priceNormalCount}
                  </Typography>
                ) : null}

                <Typography
                  sx={[
                    styles.storyPrice,
                    hasPromo ? { color: Colors.PROMO } : {},
                  ]}
                >
                  {" "}
                  {priceCount}
                </Typography>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </ButtonBase>
  );
};

const styles = {
  container: {
    alignSelf: "flex-start",
    display: "flex",
    width: "300px",
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
  },
  buyAll: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: Colors.PRIMARY,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "13px",
    overflow: "hidden",
  },
  leftPart: {
    flex: 1,
    height: "100%",
  },
  rightPart: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: 20,
    padding: "10px 0px",
    height: "100%",
  },
  getAll: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "start",
  },
  nbEpisodes: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "start",
  },
  storyPriceBlock: {
    ...rowStartStyles,
  },
  storyPrice: {
    ...rowStartStyles,
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "3px",
  },
  gradientTextSmall: {
    fontSize: normalize(14),
    fontFamily: 'Gilroy-Bold',
    marginLeft: '5px',
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

export default BuyAllEpisodesBtn;
