/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import BackgroundRow from "../../components/BackgroundRow";
import { getLanguage, strings } from "../../services/translation";
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
} from "../../style/flex";
import { Save } from "../../models/save";
import { useNavigate } from "react-router";
import moment from "moment";
import { getRouteManager } from "../../services/routeManager";
import useAuth from "../../services/Auth/useAuth";
import Loading from "../../components/Loading";
import SaveBtn from "../../components/SaveBtn";
import SoundManager from "../../services/SoundManager";

const SaveScreen = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [saves, updateSaves] = useState<Array<Save> | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [activeSave, setActiveSave] = useState<Save | null>(null);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const abortController = new AbortController();
    const cancelSignal = abortController.signal;

    useEffect(() => {
        getSaves();
        return () => {
            abortController.abort();
        };
    }, []);

    const getSaves = async () => {
        try {
            if (auth && auth.user) {
                const api = getRouteManager().getSavesForUser(
                    auth.user.id,
                    getLanguage(),
                    "all",
                );

                const data: any = await getRouteManager().getData(
                    api,
                    cancelSignal,
                );
                if (data.flashmessage === "error") {
                    console.log("no saves found");
                    setLoading(false);
                } else if (data && data.length) {
                    data.map((s: Save, index: number) => {
                        s.image_backdrop = s.storyId
                            ? getRouteManager().getCoverForSeries(s.storyId)
                            : null;
                        s.image_title = s.storyId
                            ? getRouteManager().titleForStory(
                                  getLanguage(),
                                  s.storyId,
                              )
                            : null;

                        s.date = s.created
                            ? getLanguage() === "fr"
                                ? moment(s.created).format("DD/MM/YYYY HH:mm")
                                : moment(s.created).format("MM/DD/YYYY hh:mm")
                            : undefined;
                    });
                    updateSaves(data);
                    setLoading(false);
                }
                if (data.flashmessage === "error") {
                    console.log("no saves found");
                    setLoading(false);
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const deleteSaveForUser = async () => {
        try {
            if (auth && auth.user) {
                setShowConfirm(false);
                setShowPopUp(false);
                setLoading(true);
                const value = auth.user.login_token;
                if (value) {
                    const apiUrl = getRouteManager().deleteSaveForUser();
                    if (activeSave && activeSave?.id) {
                        const deletion = await getRouteManager().fetchData(
                            apiUrl,
                            {
                                saveId: activeSave.id,
                                token: value,
                            },
                        );
                        if (deletion.flashmessage !== "success") {
                            alert(deletion.flashmessage);
                            setLoading(false);
                        } else {
                            const savesCopy = saves ? [...saves] : [];
                            const index = savesCopy.indexOf(activeSave);
                            if (index) {
                                savesCopy.splice(index, 1);
                            }
                            setActiveSave(null);
                            updateSaves(savesCopy);
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    const confirmDelete = () => {
        setShowConfirm(true);
    };

    return (
        <BackgroundRow
            title={strings.saves}
            subtitle={strings.allSaves}
            loading={loading}
            goBack
            goBackAction={() => navigate(-1)}
            animType="normal"
            leftPart={
                <Typography sx={styles.nbSaves}>
                    {saves && saves.length ? saves.length : 0}/
                    {auth && auth?.user && auth?.user?.nbSaves
                        ? auth.user.nbSaves
                        : 20}{" "}
                    {strings.savesUsed}
                </Typography>
            }
            rightPart={
                <Box sx={styles.container}>
                    {saves && saves.length ? (
                        <Box sx={styles.containerGap}>
                            {saves.map((save: Save, index: number) => (
                                <SaveBtn
                                    save={save}
                                    isLast={index === saves.length - 1}
                                    hasDelete
                                    onDelete={() => {
                                        SoundManager.getInstance().playBtnSound();
                                        setActiveSave(save);
                                        setShowPopUp(true);
                                    }}
                                    key={"save-" + save.type + "-" + index}
                                />
                            ))}
                        </Box>
                    ) : null}

                    <Modal
                        open={showPopUp}
                        onClose={() => {
                            SoundManager.getInstance().playCloseModalSound();
                            setShowConfirm(false);
                            setShowPopUp(false);
                        }}>
                        <Box sx={styles.centeredView}>
                            {activeSave && !showConfirm ? (
                                <Box sx={styles.modalView}>
                                    <Typography sx={styles.storyTitle}>
                                        {activeSave.story.title}
                                    </Typography>
                                    <Typography sx={styles.playersModal}>
                                        {activeSave.players}
                                    </Typography>
                                    <Typography sx={styles.dateModal}>
                                        {activeSave.date}
                                    </Typography>
                                    <Box sx={styles.separator} />
                                    <ButtonBase
                                        sx={{
                                            width: "100%",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            SoundManager.getInstance().playOpenModalSound();
                                            confirmDelete();
                                        }}>
                                        <Typography sx={styles.deleteStoryText}>
                                            {strings.deleteSave}
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                            ) : activeSave && showConfirm ? (
                                <Box sx={styles.modalView}>
                                    <Typography sx={styles.deleteSaveTitle}>
                                        {strings.deleteSaveTitle}
                                    </Typography>
                                    <Typography sx={styles.deleteSaveMsg}>
                                        {strings.deleteSaveMsg}
                                    </Typography>
                                    <Box sx={styles.separator} />
                                    <Box sx={styles.popupButtons}>
                                        <ButtonBase
                                            sx={styles.noButton}
                                            onClick={() => {
                                                SoundManager.getInstance().playCloseModalSound();
                                                setShowConfirm(false);
                                                setShowPopUp(false);
                                            }}>
                                            <Typography sx={styles.noText}>
                                                {strings.keepIt}
                                            </Typography>
                                        </ButtonBase>
                                        <ButtonBase
                                            sx={styles.yesButton}
                                            onClick={() => {
                                                SoundManager.getInstance().playCloseModalSound();
                                                deleteSaveForUser();
                                            }}>
                                            <Typography sx={styles.yesText}>
                                                {strings.delete}
                                            </Typography>
                                        </ButtonBase>
                                    </Box>
                                </Box>
                            ) : (
                                <Loading />
                            )}
                        </Box>
                    </Modal>
                </Box>
            }
        />
    );
};

const styles = {
    nbSaves: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
    },
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
    },
    containerGap: {
        ...columnStartStyles,
        alignItems: "flex-start",
        rowGap: "15px",
    },
    centeredView: {
        flex: 1,
        ...columnStyles,
        justifyContent: "center",
        alignItems: "center",
    },
    modalView: {
        width: "300px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        paddingTop: "45px",
        ...columnCenterStyles,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    storyTitle: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(18),
        textAlign: "center",
        padding: "0px 45px",
    },
    deleteSaveTitle: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(18),
        marginTop: "19px",
    },
    deleteSaveMsg: {
        color: Colors.BTN_INACTIVE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        marginTop: "19px",
        textAlign: "center",
    },
    playersModal: {
        marginTop: "15px",
        color: Colors.BTN_INACTIVE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        textAlign: "center",
        padding: "0px 45px",
    },
    dateModal: {
        marginTop: "15px",
        color: Colors.INPUT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        textAlign: "center",
        padding: "0px 45px",
    },
    separator: {
        width: "100%",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        marginTop: "25px",
    },
    deleteStoryText: {
        marginBottom: "30px",
        marginTop: "30px",
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        color: "#ff4d4a",
    },
    popupButtons: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        marginTop: "25px",
        marginBottom: "25px",
    },
    yesButton: {
        backgroundColor: Colors.ERROR,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
};

export default SaveScreen;
