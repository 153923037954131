/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from "react";

import normalize from "../../utils/fontSizer";
import { ReactComponent as RemovePlayerIcon } from "../../assets/icons/icon_removeplayer.svg";
import { Colors } from "../../style";
import { Box, ButtonBase, Slide, Typography } from "@mui/material";
import AvatarContainer from "../AvatarContainer";
import SoundManager from "../../services/SoundManager";

interface Props {
  idx: any;
  onFocusInput: any;
  onChangeTextInput: any;
  currentPlayerCount: number;
  onSubmitInput: any;
  removePlayer: any;
  hideOverlay: any;
  accentColor: any;
  player: any;
  solo: boolean;
  containerRef?: any;
}

interface State {
  showAddBtn: boolean;
  showRemoveBtn: boolean;
  text: string;
  inputStyle: any;
  letter: any;
  letterColor: any;
  letterBorderColor: any;
}

const inputHeight = 80;

class PlayerTextInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inputStyle: styles.normalInput,
      showAddBtn: true,
      showRemoveBtn: false,
      text: "",
      letter: "",
      letterColor: "",
      letterBorderColor: "",
    };
  }

  onFocus = () => {
    const { onFocusInput, player } = this.props;
    onFocusInput(player);
  };

  showRemoveButton = () => {
    const { idx, solo } = this.props;
    if (!solo) {
      return (
        <Box style={styles.removeButton}>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              this.removePlayer(idx);
            }}>
            <RemovePlayerIcon width={50} height={50} />
          </ButtonBase>
          {/* <Button title="X" onPress={() => this.removePlayer(idx)} /> */}
        </Box>
      );
    } else {
      return <Box sx={[styles.removeButton, { width: 50 }]} />;
    }
  };

  removePlayer = (idx: number) => {
    const { removePlayer } = this.props;
    removePlayer(idx);
  };

  renderTextInput = () => {
    const { inputStyle } = this.state;
    const { accentColor, player, solo } = this.props;
    return (
      <ButtonBase
        sx={[inputStyle, { color: accentColor }]}
        onClick={
          solo
            ? () => null
            : () => {
                SoundManager.getInstance().playBtnSound();
                this.onFocus();
              }
        }>
        {player.avatar ? (
          <AvatarContainer
            user={player}
            persoWidth={60}
            borderColor={Colors.WHITE}
            borderWidth={3}
          />
        ) : (
          <Box
            sx={[
              styles.letter,
              {
                backgroundColor: player.letterColor,
                borderColor: Colors.WHITE,
              },
            ]}>
            <Typography sx={styles.playerLetter}>{player.letter}</Typography>
          </Box>
        )}
        {player.title?.name ? (
          <Box sx={styles.nameTitleContainer}>
            <Typography sx={[styles.playerName, { color: Colors.WHITE }]}>
              {player.name}
            </Typography>
            <Typography sx={[styles.titleName, { color: Colors.ACCENT }]}>
              {player.title.name}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography sx={[styles.playerName, { color: Colors.WHITE }]}>
              {player.name}
            </Typography>
          </Box>
        )}
      </ButtonBase>
    );
  };

  render() {
    return (
      <Slide
        direction="up"
        in={true}
        appear
        container={this.props.containerRef ? this.props.containerRef : null}>
        <Box sx={styles.container}>
          {this.renderTextInput()}
          {this.showRemoveButton()}
        </Box>
      </Slide>
    );
  }
}

const styles = {
  normalInput: {
    width: "300px",
    borderRadius: "13px",
    height: inputHeight,
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  enteredInput: {
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
    textAlign: "center",
    height: inputHeight,
    color: Colors.ACCENT_BRAQUAGE,
    width: "100%",
  },
  removeButton: {
    marginLeft: "15px",
  },
  whiteText: {
    color: Colors.WHITE,
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
  },
  container: {
    alignItems: "center",
    marginBottom: "15px",
    paddingLeft: "65px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  enterNext: {
    position: "absolute",
    backgroundColor: Colors.BTN_ACTIVE,
    borderColor: Colors.BTN_ACTIVE,
    width: "100%",
    height: inputHeight,
    borderWidth: "3px",
    borderRadius: "10px",
    fontFamily: "Gilroy-Bold",
  },
  playerName: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(18),
    marginLeft: "12px",
  },
  titleName: {
    fontFamily: "Gilroy-Bold",
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    textAlign: "center",
    fontSize: normalize(14),
    marginLeft: "12px",
  },
  letter: {
    width: "60px",
    height: "60px",
    borderRadius: "60px",
    borderStyle: "solid",
    borderWidth: "3px",
    borderColor: Colors.WHITE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: 23,
    lineHeight: "23px",
    color: Colors.WHITE,
  },
  nameTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
};

export default PlayerTextInput;
