/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { Box } from "@mui/material";
import React from "react";
import { storyShinyAnim } from "../../constants/storyShiny";
import { Colors } from "../../style";
import { isNotEmptyString } from "../../utils/StringHelper";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import DynamicTag from "../DynamicTag";

interface Props {
  cover: string | null | undefined;
  title: string | null | undefined;
  width: any;
  height: any;
  tag?: any;
  tagColor?: any;
  showTag?: any;
  isAnimated?: boolean;
  hasBorder?: boolean;
  borderColor?: string;
  borderWidth?: any;
  maxWidth?: any;
  minWidth?: any;
  maxHeight?: any;
  minHeight?: any;
  absolutelyPositioned?: boolean;
}

const CoverTitleSmallFlexible = (props: Props) => {
  const {
    cover,
    title,
    width,
    height,
    tag,
    tagColor,
    showTag,
    isAnimated,
    hasBorder,
    borderColor,
    borderWidth,
    maxHeight,
    maxWidth,
    minWidth,
    minHeight,
    absolutelyPositioned = true,
  } = props;

  const finalWidth = width;
  const finalHeight = height;

  const styles = {
    superposition: {
      position: "relative",
      width: finalWidth,
      minWidth: minWidth ? minWidth : width,
      maxWidth: maxWidth ? maxWidth : width,
      height: finalHeight,
      minHeight: minHeight ? minHeight : height,
      maxHeight: maxHeight ? maxHeight : height,
    },
    cover: {
      position: absolutelyPositioned ? "absolute" : "relative",
      top: 0,
      left: 0,
      zIndex: 13,
      width: finalWidth,
      minWidth: minWidth ? minWidth : width,
      maxWidth: maxWidth ? maxWidth : width,
      height: finalHeight,
      minHeight: minHeight ? minHeight : height,
      maxHeight: maxHeight ? maxHeight : height,
      borderRadius: "7px",
      backgroundSize: "cover",
      borderWidth: hasBorder ? borderWidth : 0,
      borderColor: hasBorder ? borderColor : "#000",
    },
    anim: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 20,
      borderRadius: "7px",
      overflow: "hidden",
      backgroundSize: "cover",
    },
    title: {
      position: "absolute",
      zIndex: 15,
      backgroundSize: "contain",
      width: hasBorder ? finalWidth - borderWidth * 2 : finalWidth,
      minWidth: minWidth ? minWidth : width,
      maxWidth: maxWidth ? maxWidth : width,
      height: finalHeight,
      minHeight: minHeight ? minHeight : height,
      maxHeight: maxHeight ? maxHeight : height,
      left: hasBorder ? borderWidth : 0,
      top: hasBorder
        ? ((finalWidth - borderWidth * 2) * 3) / 6
        : (finalWidth * 3) / 6,
    },
    tag: {
      position: "absolute",
      top: 10,
      right: 10,
      zIndex: 25,
    },
  };

  const customStyle = {
    ...styles.anim,
    ...{
      width: finalWidth,
      minWidth: minWidth ? minWidth : width,
      maxWidth: maxWidth ? maxWidth : width,
      height: finalHeight,
      minHeight: minHeight ? minHeight : height,
      maxHeight: maxHeight ? maxHeight : height,
    },
  };

  const shouldShowTag = isTrueBoolean(showTag) && isNotEmptyString(tag);

  return (
    <Box sx={{ position: "relative" }}>
      {cover ? (
        <img
          src={cover}
          style={{
            position: absolutelyPositioned ? "absolute" : "relative",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 13,
            width: finalWidth,
            minWidth: minWidth ? minWidth : width,
            maxWidth: maxWidth ? maxWidth : width,
            height: finalHeight,
            minHeight: minHeight ? minHeight : height,
            maxHeight: maxHeight ? maxHeight : height,
            borderRadius: "7px",
            backgroundSize: "cover",
            borderWidth: hasBorder ? borderWidth : 0,
            borderColor: hasBorder ? borderColor : "#000",
            objectFit: "cover",
          }}
        />
      ) : (
        <img
          src={require("../../assets/images/cover-unknown.jpeg")}
          style={{
            position: absolutelyPositioned ? "absolute" : "relative",
            top: 0,
            left: 0,
            zIndex: 13,
            width: finalWidth,
            minWidth: minWidth ? minWidth : width,
            maxWidth: maxWidth ? maxWidth : width,
            height: finalHeight,
            minHeight: minHeight ? minHeight : height,
            maxHeight: maxHeight ? maxHeight : height,
            borderRadius: "7px",
            backgroundSize: "cover",
            borderWidth: hasBorder ? borderWidth : 0,
            borderColor: hasBorder ? borderColor : "#000",
          }}
        />
      )}

      <img
        src={title ? title : undefined}
        style={{
          position: "absolute",
          zIndex: 15,
          width: finalWidth,
          minWidth: minWidth ? minWidth : width,
          maxWidth: maxWidth ? maxWidth : width,
          left: hasBorder ? borderWidth : 0,
          top: finalWidth,
        }}
      />
      {isAnimated ? (
        <Box sx={customStyle}>
          <Player
            src={storyShinyAnim}
            autoplay
            loop
            speed={1}
            style={{
              backgroundSize: "cover",
              zIndex: 20,
            }}
          />
        </Box>
      ) : null}

      {shouldShowTag ? (
        <DynamicTag
          text={tag}
          textColor={tagColor ? tagColor : Colors.WHITE}
          style={styles.tag}
        />
      ) : null}
    </Box>
  );
};

export default CoverTitleSmallFlexible;
