/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useRef, useState } from "react";
import GradientBtn from "../GradientBtn";
import normalize from "../../utils/fontSizer";
import PlayerTextInput from "../../components/playerTextInput";
import {
    Box,
    ButtonBase,
    Fade,
    InputBase,
    Modal,
    Typography,
} from "@mui/material";
import GoBackBtn from "../GoBackBtn";
import { capitalize } from "lodash";
import { Colors } from "../../style";
import { getLanguage, strings } from "../../services/translation";
import { getRouteManager } from "../../services/routeManager";
import Highlighter from "react-highlight-words";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/icon_next_image.svg";
import { ReactComponent as QuitPopupIcon } from "../../assets/icons/icon_quit_story.svg";
import { ReactComponent as IconPlayer } from "../../assets/icons/icon_more.svg";
import { ReactComponent as IconQuestion } from "../../assets/icons/icon_question.svg";
import DynamicTag from "../DynamicTag";
import { Gilroy } from "../../style/fonts";
import Loading from "../Loading";
import "./style.css";
import { useNavigate } from "react-router-dom";
import useAuth from "../../services/Auth/useAuth";
import SoundManager from "../../services/SoundManager";
import { getEventManager } from "../../services/EventManager";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
} from "../../style/flex";
import { Companion } from "../../models/companion";
import SelectCompanionModal from "../SelectCompanionModal";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import Separator from "../Separator";
import FTUEModals from "../FTUEModals";
import { FTUE_MODALS } from "../../constants";

interface Props {
    colors: any;
    storyId: any;
    active: any;
    episode: any;
    nbPlayerMin: any;
    nbPlayerMax: any;
    title: any;
    intro: any;
    solo: any;
    isBoard?: any;
    callback: any;
}

const PreGame = (props: Props) => {
    const {
        storyId,
        active,
        episode,
        nbPlayerMin,
        nbPlayerMax,
        title,
        intro,
        solo,
        isBoard = false,
        callback,
    } = props;

    const navigate = useNavigate();
    const auth = useAuth();

    //states
    const [players, setPlayers] = useState<Array<any>>([]);
    const [showOverlay, setShowOverlay] = useState(false);
    const [currPlayerName, setCurrPlayerName] = useState<any>();
    const [currPlayer, setCurrPlayer] = useState(null);
    const [currentPlayerCount, setCurrentPlayerCount] = useState(0);
    const [shouldGo, setShouldGo] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [idCpt, setIdCpt] = useState(0);
    const [letterColors, setLetterColors] = useState([...Colors.LETTERS]);
    const [showPopUpPlayerNotFound, setShowPopUpPlayerNotFound] =
        useState(false);
    const [showPopUpPlayerAlreadyExist, setShowPopUpPlayerAlreadyExist] =
        useState(false);
    let indexOverlay = 0;
    /*   const colorsObject = JSON.parse(colors);
  ColorManager.getInstance().Clear();
  ColorManager.getInstance().generateColors(colorsObject); */
    const [modalVisible, setModalVisible] = useState(false);
    const [modalBoardVisible, setModalBoardVisible] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
    const [showCompanionsList, setShowCompanionsList] = useState(false);
    const [companions, setCompanions] = useState<Companion[] | null>(null);

    const containerRef = useRef(null);

    useEffect(() => {
        initializePlayers();
    }, []);

    const initializePlayers = () => {
        if (solo) {
            initializePlayersSolo();
        } else {
            const playersCopy = [];
            setLetterColors([...Colors.LETTERS]);
            //get player avatar and pseudo
            const user = auth.user;
            if (user && user.avatar) {
                const p = {
                    idx: playersCopy.length,
                    key: playersCopy.length,
                    name: capitalize(user.pseudo.substring(0, 12).trim()),
                    avatar: user.avatar,
                    letter: capitalize(user.pseudo.substring(0, 12).trim())[0],
                    letterColor: Colors.PRIMARY,
                    playerCode: user.player_code,
                    letterBorderColor:
                        /* ColorManager.getInstance().getColor("tertiary") */ Colors.WHITE,
                    title: user && user.title ? user.title : null,
                };
                playersCopy.push(p);
            }
            setPlayers(playersCopy);
            setCurrentPlayerCount(playersCopy.length);
            setIdCpt(idCpt + 1);
            setIsReady(true);
        }
    };

    useEffect(() => {
        if (auth && auth.user) {
            initializeData();
        }
    }, []);

    /**
     * @initializeData
     * initialize data for companions & items
     */
    const initializeData = async () => {
        try {
            const token = auth.user.login_token;
            if (token) {
                const apiUrl = getRouteManager().getCompanionsForUser();
                const data = await getRouteManager().fetchData(apiUrl, {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                });
                if (data && data?.length) {
                    data.map((comp: any) => {
                        if (comp.title && comp.title?.titleTrads?.length) {
                            comp.title.name = comp.title.titleTrads[0].name;
                        }
                        comp.isEmpty = false;
                    });
                    /* const cpt = (COMPANIONS.NBMAX - data.length)
          for (let i = 0; i < cpt; i++) {
            data.push({ isEmpty: true })
          } */
                    setCompanions(data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const initializePlayersSolo = () => {
        const playersCopy = [];
        setLetterColors([...Colors.LETTERS]);
        //get player avatar and pseudo
        const user = auth.user;
        if (user && user.avatar) {
            const p = {
                idx: playersCopy.length,
                key: playersCopy.length,
                name: capitalize(user.pseudo.substring(0, 12).trim()),
                avatar: user.avatar,
                letter: capitalize(user.pseudo.substring(0, 12).trim())[0],
                letterColor: Colors.PRIMARY,
                playerCode: user.player_code,
                letterBorderColor:
                    /* ColorManager.getInstance().getColor("tertiary") */ Colors.WHITE,
                title: user && user.title ? user.title : null,
            };
            playersCopy.push(p);
        }

        const p2 = createSoloPlayer(playersCopy, 1);
        const p3 = createSoloPlayer(playersCopy, 2);
        const p4 = createSoloPlayer(playersCopy, 3);
        playersCopy.push(p2, p3, p4);

        setPlayers(playersCopy);
        setCurrentPlayerCount(playersCopy.length);
        setCurrPlayerName("");
        indexOverlay = 0;
        setIdCpt(3);
        scrollToRow(playersCopy.length - 1);
        setIsReady(true);
    };

    const onFocusInput = (player: any) => {
        if (player) {
            setCurrPlayer(player);
            setCurrPlayerName(player.name);
        } else {
            setCurrPlayer(null);
            setCurrPlayerName("");
        }
        setShowOverlay(true);
    };

    const getData = async (url: string) => {
        const response = await fetch(url);
        return await response.json();
    };
    //1 - 6, 15 - 12, 49 - 26

    const createSoloPlayer = (list: any, index: number) => {
        let name = "";
        let avatar = {};
        switch (index) {
            case 1:
                name = "Loline";
                avatar = {
                    id: null,
                    userId: null,
                    fond: null,
                    perso: 74,
                    buste: null,
                    masque: null,
                    chapeau: null,
                };
                break;
            case 2:
                name = "Alphonse";
                avatar = {
                    id: null,
                    userId: null,
                    fond: null,
                    perso: 75,
                    buste: null,
                    masque: null,
                    chapeau: null,
                };
                break;
            case 3:
                name = "Eugénie";
                avatar = {
                    id: null,
                    userId: null,
                    fond: null,
                    perso: 76,
                    buste: null,
                    masque: null,
                    chapeau: null,
                };
                break;
            default:
                name = "Adrien";
                avatar = {
                    id: null,
                    userId: null,
                    fond: null,
                    perso: 74,
                    buste: null,
                    masque: null,
                    chapeau: null,
                };
                break;
        }
        const p = {
            name: name,
            avatar: avatar,
            idx: index,
            key: index,
            letter: name[0],
            letterColor: letterColors[index],
            letterBorderColor: Colors.WHITE,
        };
        const copy = [...letterColors];
        copy.splice(0, 1);
        setLetterColors(copy);
        return p;
    };

    const createPlayer = (e: string) => {
        const newPlayers = players;
        const p = {
            name: capitalize(e.trim()),
            idx: idCpt + 1,
            key: idCpt + 1,
            letter: capitalize(e.trim())[0],
            letterColor: letterColors[0],
            letterBorderColor: Colors.WHITE,
        };
        const copy = [...letterColors];
        copy.splice(0, 1);
        setLetterColors(copy);
        p.letterBorderColor = Colors.WHITE;

        newPlayers.push(p);
        setCurrentPlayerCount(currentPlayerCount + 1);
        setCurrPlayerName("");
        indexOverlay = 0;
        setIdCpt(idCpt + 1);
        setPlayers(newPlayers);
        scrollToRow(newPlayers.length - 1);
    };

    const createMultipleCompanions = (companionList: Companion[]) => {
        let newIndex: number = idCpt;
        let newPlayers: any[] = [...players];
        if (isNotEmptyArray(companionList)) {
            companionList.forEach((comp: Companion, index: number) => {
                const { newPlayers: updatedPlayers, newIndex: updatedIndex } =
                    createCompanion(newPlayers, comp, newIndex + index);
                newPlayers = updatedPlayers;
                newIndex = updatedIndex;
            });
            setCurrentPlayerCount(newPlayers.length);
            setCurrPlayerName("");
            indexOverlay = 0;
            setIdCpt(newIndex);
            setPlayers(newPlayers);
            scrollToRow(newPlayers.length - 1);
            setShowAddPlayerModal(false);
        }
        setShowCompanionsList(false);
    };

    const getAvailableCompanions = (): Companion[] | null => {
        const list = companions?.filter((comp: Companion) =>
            players.filter((p: any) => p.userId === comp.id)?.length
                ? false
                : true,
        );
        if (list && list.length) {
            return list;
        }
        return null;
    };

    const createCompanion = (
        playersList: any[],
        companion: Companion,
        index = 1,
    ): { newPlayers: any[]; newIndex: number } => {
        const newPlayers = playersList;
        const newIndex = index + 1;
        const p = {
            name: capitalize(
                companion?.name
                    ? companion.name.substring(0, 12)
                    : strings.companions.companion,
            ),
            avatar: companion.avatar ? companion.avatar : null,
            userId: companion.id,
            idx: newIndex,
            key: newIndex,
            letter: capitalize(
                companion?.name
                    ? companion.name.substring(0, 12)
                    : strings.companions.companion,
            )[0],
            letterColor: letterColors[0],
            letterBorderColor: Colors.WHITE,
            title: companion.title ? companion.title : null,
        };
        const copy = [...letterColors];
        copy.splice(0, 1);
        setLetterColors(copy);
        newPlayers.push(p);
        return { newPlayers, newIndex };
    };

    const createPlayerFromUser = async (e: string) => {
        setIsLoaded(true);
        const newPlayers = players;
        const name = e.trim().substring(1);
        const checkExist = newPlayers.filter((np) => np.playerCode === name);
        if (checkExist.length > 0) {
            setShowPopUpPlayerAlreadyExist(true);
            setIsLoaded(false);
            return;
        } else {
            const apiUser = getRouteManager().getUserFromCode(name);
            const resUser = await getData(apiUser);
            if (resUser) {
                if (resUser.flashmessage === "error") {
                    setIsLoaded(false);
                    setShowPopUpPlayerNotFound(true);
                } else {
                    setIsLoaded(false);
                    const p = {
                        name: capitalize(resUser.pseudo.substring(0, 12)),
                        avatar: resUser.avatar ? resUser.avatar : null,
                        userId: resUser.id,
                        playerCode: resUser.playerCode,
                        idx: idCpt + 1,
                        key: idCpt + 1,
                        letter: capitalize(resUser.pseudo.substring(0, 12))[0],
                        letterColor: letterColors[0],
                        letterBorderColor: Colors.WHITE,
                        title: resUser.title ? resUser.title : null,
                    };
                    const copy = [...letterColors];
                    copy.splice(0, 1);
                    setLetterColors(copy);
                    newPlayers.push(p);
                    setCurrentPlayerCount(currentPlayerCount + 1);
                    setCurrPlayerName("");
                    indexOverlay = 0;
                    setIdCpt(idCpt + 1);
                    setPlayers(newPlayers);
                    scrollToRow(newPlayers.length - 1);
                }
            } else {
                setIsLoaded(false);
                setShowPopUpPlayerNotFound(true);
                return;
            }
        }
    };

    const editPlayer = (e: string) => {
        if (currPlayer) {
            const newPlayers = players;
            indexOverlay = 0;
            if (e && e !== "") {
                const p: any = currPlayer;
                p.name = capitalize(e.trim());
                p.letter = p.name[0];
                newPlayers.map((item) => {
                    if (item.idx === p.idx) {
                        item = p;
                    }
                });
                setPlayers(newPlayers);
                setCurrPlayerName("");
                scrollToRow(currentPlayerCount - 1);
            }
        }
    };

    const editPlayerFromUser = async (e: string) => {
        setIsLoaded(true);
        if (currPlayer) {
            const newPlayers = players;
            indexOverlay = 0;
            if (e && e !== "") {
                const p: any = currPlayer;
                const name = e.substring(1).trim();
                const checkExist = newPlayers.filter(
                    (np) => np.playerCode === name,
                );
                if (checkExist.length > 0) {
                    setShowPopUpPlayerAlreadyExist(true);
                    setIsLoaded(false);
                    return;
                } else {
                    const apiUser = getRouteManager().getUserFromCode(name);
                    const resUser = await getData(apiUser);
                    if (resUser) {
                        if (resUser.flashmessage === "error") {
                            setIsLoaded(false);
                            setShowPopUpPlayerNotFound(true);
                        } else {
                            setIsLoaded(false);
                            p.name = capitalize(
                                resUser.pseudo.substring(0, 12),
                            );
                            p.avatar = resUser.avatar;
                            p.userId = resUser.id;
                            p.playerCode = resUser.playerCode;
                            p.letter = p.name[0];
                            if (resUser.title) {
                                p.title = resUser.title;
                            }
                            newPlayers.map((item) => {
                                if (item.idx === p.idx) {
                                    item = p;
                                }
                            });
                            setPlayers(newPlayers);
                            setCurrPlayerName("");
                            scrollToRow(currentPlayerCount - 1);
                        }
                    } else {
                        setIsLoaded(false);
                        setShowPopUpPlayerNotFound(true);
                        return;
                    }
                }
            }
        }
    };

    const onSubmitInput = async (e: any) => {
        //todo: get player avatar if code, else create letter with color
        setShowOverlay(false);
        if (e && e.match(/^[a-zA-ZéÉèÈàÀêÊÇçùÙûÛ#]{1}[\S]{1,}/)) {
            if (currPlayer) {
                if (e.startsWith("#")) {
                    await editPlayerFromUser(e);
                } else {
                    editPlayer(e);
                }
            } else {
                if (e && e !== "" && !showError) {
                    if (e.startsWith("#")) {
                        await createPlayerFromUser(e);
                    } else {
                        createPlayer(e);
                    }
                }
            }
        }
    };

    const onChangeTextInput = (e: any) => {
        if (e && !e.match(/^[a-zA-ZéÉèÈàÀêÊÇçùÙûÛ#]{1}[\S]{1,}/)) {
            setShowError(true);
        } else {
            setShowError(false);
        }
        setCurrPlayerName(e);
    };

    const hideOverlay = () => {
        setShowOverlay(false);
    };

    const onRemovePlayer = (idx: number) => {
        const playersCopy = players;
        const p = playersCopy.filter((item) => idx === item.idx)[0];
        const color = p.letterColor;
        const copy = [...letterColors];
        if (color != Colors.PRIMARY) {
            copy.unshift(color);
        }
        setLetterColors(copy);
        playersCopy.map((item, index) => {
            if (idx === item.idx) {
                playersCopy.splice(index, 1);
            }
        });

        setCurrentPlayerCount(currentPlayerCount - 1);
        setCurrPlayerName("");
        setPlayers(playersCopy);
        scrollToRow(currentPlayerCount - 2);
    };

    const onPressLetsGo = () => {
        setShouldGo(true);
    };

    const goToGame = async () => {
        //const colorsObject = JSON.parse(colors);
        const session = {
            startDate: new Date(),
            userId: auth?.user?.id,
            storyId: storyId,
            type: solo ? "solo" : isBoard ? "board" : "normal",
            lang: getLanguage(),
            nbPlayers: solo ? 1 : players.length,
        };
        if (solo) {
            getEventManager().logLaunchStorySolo(storyId);
        } else {
            getEventManager().logLaunchStoryBoard(storyId);
        }

        setShouldGo(false);
        await getRouteManager().fetchStartSession(session, auth.user);

        navigate("/game", {
            state: {
                players: players,
                storyId: storyId,
                //colors: colorsObject,
                title: title,
                save: null,
                episode: episode,
                solo: solo,
                isFTUE: active?.isFTUE,
                isBoard: isBoard,
            },
        });

        SoundManager.getInstance().removeAllSounds();
        //SoundManager.getInstance().playStoryStartSound();
    };

    useEffect(() => {
        checkFtue();
    }, []);

    const checkFtue = async () => {
        if (solo) {
            const val = await localStorage.getItem("ftue_solo");
            if (!val) {
                setModalVisible(true);
            }
        } else {
            const val = await localStorage.getItem("ftue_board");
            if (!val) {
                setModalBoardVisible(true);
            }
        }
    };

    const handleFtueModalClose = (type: string) => {
        if (type === "solo") {
            localStorage.setItem("ftue_solo", "done");
            setModalVisible(false);
        } else if (type === "board") {
            localStorage.setItem("ftue_board", "done");
            setModalBoardVisible(false);
        }
    };

    const scrollToRow = (itemIndex: any) => {
        /* if (scrollView) {
      scrollView.scrollTo({
        y: itemIndex * 65,
      });
    } */
    };

    const showAddButton = () => {
        if (isLoaded) {
            return <Loading />;
        } else {
            if (
                currentPlayerCount &&
                nbPlayerMax &&
                currentPlayerCount < nbPlayerMax
            ) {
                return (
                    <ButtonBase
                        sx={[styles.addButton]}
                        onClick={() => {
                            SoundManager.getInstance().playBtnSound();
                            setShowAddPlayerModal(true);
                        }}>
                        <IconPlayer
                            width={50}
                            height={50}
                            fill={Colors.vertALEXTESGRANDSMORTS}
                        />
                        <Typography
                            sx={[
                                styles.whiteText,
                                { color: Colors.vertALEXTESGRANDSMORTS },
                            ]}>
                            {strings.add}
                        </Typography>
                    </ButtonBase>
                );
            } else {
                return null;
            }
        }
    };

    const drawAddModal = () => {
        return (
            <Modal
                open={showAddPlayerModal}
                onClose={() => {
                    setShowAddPlayerModal(false);
                }}>
                {showCompanionsList ? (
                    <SelectCompanionModal
                        companions={getAvailableCompanions()}
                        createMultipleCompanions={createMultipleCompanions}
                        currentPlayerCount={currentPlayerCount}
                        nbPlayerMax={nbPlayerMax}
                    />
                ) : (
                    <Box
                        sx={{
                            ...columnCenterStyles,
                            backgroundColor: Colors.PRIMARY,
                            borderRadius: "20px",
                            paddingTop: "20px",
                        }}>
                        <Typography
                            sx={{
                                color: Colors.OLD_PRICE,
                                textAlign: "center",
                                fontFamily: Gilroy,
                                fontSize: "18px",
                            }}>
                            {strings.companions.whatToAdd}
                        </Typography>
                        <Separator style={{ marginTop: "20px" }} />
                        <Box
                            sx={{
                                ...columnCenterStyles,
                                padding: "20px 20px",
                                gap: "10px",
                            }}>
                            <ButtonBase
                                sx={[
                                    styles.addButton,
                                    {
                                        width: "350px",
                                        justifyContent: "flex-start",
                                        padding: "16px",
                                        columnGap: "10px",
                                    },
                                ]}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    setShowAddPlayerModal(false);
                                    onFocusInput(null);
                                }}>
                                <IconPlayer
                                    width={50}
                                    height={50}
                                    fill={Colors.WHITE}
                                />
                                <Box
                                    sx={{
                                        ...columnStyles,
                                        alignItems: "flex-start",
                                    }}>
                                    <Typography
                                        sx={[
                                            styles.whiteText,
                                            { color: Colors.WHITE },
                                        ]}>
                                        {strings.actions.addPlayer}
                                    </Typography>
                                    <Typography
                                        sx={[
                                            styles.whiteText,
                                            {
                                                color: Colors.OLD_PRICE,
                                                fontSize: "12px",
                                                textAlign: "start",
                                            },
                                        ]}>
                                        {strings.labels.addPlayerSubtitle}
                                    </Typography>
                                </Box>
                            </ButtonBase>
                            <ButtonBase
                                sx={[
                                    styles.addButton,
                                    {
                                        width: "350px",
                                        justifyContent: "flex-start",
                                        padding: "16px",
                                        columnGap: "10px",
                                    },
                                ]}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    //setShowAddPlayerModal(false);
                                    setShowCompanionsList(true);
                                }}>
                                <IconPlayer
                                    width={50}
                                    height={50}
                                    fill={Colors.WHITE}
                                />
                                <Box
                                    sx={{
                                        ...columnStyles,
                                        alignItems: "flex-start",
                                    }}>
                                    <Typography
                                        sx={[
                                            styles.whiteText,
                                            { color: Colors.WHITE },
                                        ]}>
                                        {strings.companions.addCompanion}
                                    </Typography>
                                    <Typography
                                        sx={[
                                            styles.whiteText,
                                            {
                                                color: Colors.OLD_PRICE,
                                                fontSize: "12px",
                                                textAlign: "start",
                                            },
                                        ]}>
                                        {
                                            strings.companions
                                                .addCompanionSubtitle
                                        }
                                    </Typography>
                                </Box>
                            </ButtonBase>
                        </Box>
                    </Box>
                )}
            </Modal>
        );
    };

    const drawButton = () => {
        return (
            <Box
                style={{
                    width: "100%",
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 999,
                }}>
                <GradientBtn
                    onPress={() => {
                        SoundManager.getInstance().playStoryStartSound();
                        onPressLetsGo();
                    }}
                    disabled={!(solo || currentPlayerCount >= nbPlayerMin)}>
                    {strings.lesgo}
                </GradientBtn>
            </Box>
        );
    };

    const inputFields = players.map((item) => (
        <PlayerTextInput
            key={item.key}
            idx={item.idx}
            player={item}
            currentPlayerCount={currentPlayerCount}
            onFocusInput={onFocusInput}
            onSubmitInput={onSubmitInput}
            onChangeTextInput={onChangeTextInput}
            removePlayer={onRemovePlayer}
            hideOverlay={hideOverlay}
            accentColor={Colors.WHITE}
            solo={solo}
            containerRef={containerRef.current}
        />
    ));

    return (
        <Box sx={styles.all}>
            <Box sx={styles.modalBody}>
                <Box sx={[styles.modalBodyLeft, { alignSelf: "flex-start" }]}>
                    <GoBackBtn
                        callback={() => {
                            callback(false);
                        }}
                        style={{ position: "absolute", top: 37 }}
                    />
                    <Fade appear in={isReady} timeout={600}>
                        <Typography
                            sx={{
                                color: Colors.WHITE,
                                fontSize: "50px",
                                fontWeight: 500,
                                fontFamily: "Eczar-Regular",
                            }}>
                            {title}
                        </Typography>
                    </Fade>

                    {episode && episode.number ? (
                        <Fade in={isReady} appear>
                            <Box>
                                <DynamicTag
                                    text={
                                        strings.episode + " " + episode.number
                                    }
                                    textColor={Colors.WHITE}
                                    style={{
                                        backgroundColor: Colors.PRIMARY,
                                        padding: "7px 12px",
                                        fontSize: "20px",
                                    }}
                                />
                            </Box>
                        </Fade>
                    ) : null}
                    <Fade appear in={isReady} timeout={600}>
                        <Typography sx={styles.storyDescription}>
                            {intro ? intro : null}
                        </Typography>
                    </Fade>
                    {!solo ? (
                        <Fade appear in={isReady} timeout={600}>
                            <Typography
                                sx={[
                                    normalText,
                                    {
                                        fontSize: "18px",
                                        color:
                                            currentPlayerCount &&
                                            currentPlayerCount < nbPlayerMin
                                                ? Colors.FOND_ITEM_POPUP
                                                : Colors.HEART,
                                    },
                                ]}>
                                {nbPlayerMin} - {nbPlayerMax} {strings.players}
                            </Typography>
                        </Fade>
                    ) : null}
                </Box>
                <Fade appear in={isReady} timeout={600}>
                    <Box sx={[styles.modalBodyRight]}>
                        <Box sx={styles.container}>
                            {!showOverlay && !shouldGo && (
                                <Box
                                    style={{
                                        position: "absolute",
                                        top: 37,
                                        right: 0,
                                        zIndex: 105,
                                    }}>
                                    <ButtonBase
                                        onClick={() => {
                                            if (solo) {
                                                SoundManager.getInstance().playOpenModalSound();
                                                setModalVisible(true);
                                            } else {
                                                SoundManager.getInstance().playOpenModalSound();
                                                setModalBoardVisible(true);
                                            }
                                        }}>
                                        <IconQuestion width={50} height={50} />
                                    </ButtonBase>
                                </Box>
                            )}

                            <Box sx={styles.containerTouch}>
                                <Box sx={styles.inputContainer}>
                                    <Box
                                        sx={styles.inputContainer}
                                        ref={containerRef}>
                                        {inputFields}
                                    </Box>
                                    {!solo ? showAddButton() : null}
                                </Box>
                                {drawButton()}
                                {drawAddModal()}
                            </Box>

                            {/**
                             *
                             *
                             *
                             *
                             *
                             *
                             *
                             *
                             *
                             */}

                            <Modal
                                open={showPopUpPlayerNotFound}
                                onClose={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    setShowPopUpPlayerNotFound(false);
                                }}>
                                <Box style={styles.centeredView}>
                                    <Box sx={styles.modalViewBuyEpisode}>
                                        <QuitPopupIcon
                                            width={90}
                                            height={90}
                                            fill={Colors.WHITE}
                                        />
                                        <Typography
                                            sx={{
                                                color: Colors.LOCK,
                                                fontFamily: "Gilroy-Bold",
                                                fontSize: normalize(16),
                                                margin: "35px 0px 10px 0px",
                                                textAlign: "center",
                                            }}>
                                            {strings.playerNotFound}
                                        </Typography>
                                        <Box
                                            style={styles.separatorBuyEpisode}
                                        />

                                        <Box sx={styles.popupButtons}>
                                            <ButtonBase
                                                style={styles.yesButton}
                                                onClick={() => {
                                                    SoundManager.getInstance().playCloseModalSound();
                                                    setShowPopUpPlayerNotFound(
                                                        false,
                                                    );
                                                }}>
                                                <Typography sx={styles.yesText}>
                                                    {strings.ok}
                                                </Typography>
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                </Box>
                            </Modal>

                            <Modal
                                open={showPopUpPlayerAlreadyExist}
                                onClose={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    setShowPopUpPlayerAlreadyExist(false);
                                }}>
                                <Box style={styles.centeredView}>
                                    <Box sx={styles.modalViewBuyEpisode}>
                                        <QuitPopupIcon
                                            width={90}
                                            height={90}
                                            fill={Colors.WHITE}
                                        />
                                        <Typography
                                            style={{
                                                color: Colors.LOCK,
                                                fontFamily: "Gilroy-Bold",
                                                fontSize: normalize(16),
                                                margin: "35px 0px 10px 0px",
                                                textAlign: "center",
                                            }}>
                                            {strings.playerAlreadyExists}
                                        </Typography>
                                        <Box
                                            style={styles.separatorBuyEpisode}
                                        />

                                        <Box sx={styles.popupButtons}>
                                            <ButtonBase
                                                style={styles.yesButton}
                                                onClick={() => {
                                                    SoundManager.getInstance().playCloseModalSound();
                                                    setShowPopUpPlayerAlreadyExist(
                                                        false,
                                                    );
                                                }}>
                                                <Typography sx={styles.yesText}>
                                                    {strings.ok}
                                                </Typography>
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                </Box>
                            </Modal>
                            {/* <FtueModalSolo
        modalVisible={modalVisible}
        callback={() => {
          handleFtueModalClose("solo");
        }}
      />
      <FtueModalBoard
        modalVisible={modalBoardVisible}
        callback={() => {
          handleFtueModalClose("board");
        }}
      /> */}
                        </Box>
                    </Box>
                </Fade>
            </Box>
            {showOverlay && (
                <Box sx={styles.overlay}>
                    <Box
                        style={{
                            position: "absolute",
                            top: 37,
                            right: 37,
                            zIndex: 1000,
                        }}>
                        <ButtonBase
                            onClick={() => {
                                SoundManager.getInstance().playBtnSound();
                                hideOverlay();
                            }}>
                            <CloseIcon width={50} height={50} />
                        </ButtonBase>
                    </Box>
                    <Typography
                        sx={[
                            styles.overlayEnterName,
                            !showError ? { marginBottom: "50px" } : {},
                        ]}>
                        <Highlighter
                            highlightStyle={styles.overlayEnterNameAccent}
                            searchWords={["#Player code", "#Code joueur"]}
                            textToHighlight={strings.enterPlayerName}
                        />
                    </Typography>
                    {showError && (
                        <Typography
                            style={{
                                color: Colors.ERROR,
                                fontSize: normalize(12),
                                marginBottom: "50px",
                                fontFamily: "Gilroy-Bold",
                            }}>
                            {strings.pseudoInvalid}
                        </Typography>
                    )}

                    <InputBase
                        autoFocus
                        defaultValue={currPlayerName}
                        /*  autoCorrect={false} */
                        spellCheck={false}
                        onChange={(event: any) =>
                            onChangeTextInput(event.target.value)
                        }
                        onSubmit={(event: any) =>
                            onSubmitInput(event.target.value)
                        }
                        onKeyUp={(event: any) => {
                            if (event.key && event.key === "Enter") {
                                onSubmitInput(event.target.value);
                            } else if (event.key && event.key === "Escape") {
                                hideOverlay();
                            }
                        }}
                        sx={styles.overlayPlayerName}
                    />
                    <ButtonBase
                        sx={{
                            marginTop: "20px",
                            zIndex: 100,
                        }}
                        onClick={() => {
                            SoundManager.getInstance().playBtnSound();
                            currPlayerName && onSubmitInput(currPlayerName);
                        }}
                        disabled={
                            !currPlayerName ||
                            showError ||
                            !currPlayerName.match(
                                /^[a-zA-ZéÉèÈàÀêÊÇçùÙûÛ#]{1}[\S]{1,}/,
                            )
                        }>
                        <NextIcon
                            width={50}
                            height={50}
                            fill={Colors.BTN_ACTIVE}
                        />
                    </ButtonBase>
                </Box>
            )}
            {shouldGo && (
                <Box sx={[styles.showNext]} className={"fade-in-image"}>
                    {active.image_backdrop ? (
                        <img
                            src={active.image_backdrop}
                            style={{ position: "absolute", height: "100vh" }}
                        />
                    ) : null}
                    {active.image_title ? (
                        <img
                            src={active.image_title}
                            style={{
                                zIndex: 50,
                                width: "46vh",
                                position: "absolute",
                                bottom: "70px",
                            }}
                        />
                    ) : null}
                    <Box
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            zIndex: 50,
                            alignSelf: "center",
                        }}>
                        <ButtonBase
                            sx={[{ borderRadius: "50px" }]}
                            onClick={() => {
                                SoundManager.getInstance().playBtnSound();
                                goToGame();
                            }}>
                            <NextIcon width={50} height={50} fill="black" />
                        </ButtonBase>
                    </Box>
                </Box>
            )}
            <FTUEModals modal={FTUE_MODALS.GAME} />
        </Box>
    );
};

const normalText = {
    fontSize: "12px",
    fontFamily: Gilroy,
    color: Colors.WHITE,
};

const styles = {
    all: {
        position: "relative",
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBody: {
        position: "relative",
        display: "flex",
        width: "90%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    modalBodyLeft: {
        display: "flex",
        width: "30%",
        maxWidth: "500px",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: "10px",
        alignItems: "flex-start",
    },
    modalBodyRight: {
        display: "flex",
        width: "70%",
        minHeight: "100vh",
        paddingLeft: "5%",
        flexDirection: "column",
        justifyContent: "center",
    },
    storyDescription: {
        color: Colors.WHITE,
        fontSize: "18px",
        marginTop: "15px",
        marginBottom: "25px",
        fontFamily: "Gilroy-Bold",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        flex: 1,
    },
    containerTouch: {
        display: "flex",
        width: "25w",
        maxWidth: "500px",
        minWidth: "300px",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    header: {
        fontSize: normalize(18),
        marginBottom: "10px",
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        textAlign: "center",
    },
    headerContainer: {
        alignItems: "center",
        marginHorizontal: "30px",
    },
    subHeader: {
        color: Colors.ACCENT_BRAQUAGE,
        fontWeight: "bold",
        fontSize: normalize(18),
        fontFamily: "Gilroy-Bold",
    },
    overlay: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
        backgroundColor: "rgba(23, 24, 28, 0.9)",
    },
    showNext: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.PRIMARY,
        zIndex: 999,
    },
    backBtn: {
        position: "absolute",
        top: 37,
        left: 37,
        zIndex: 10,
    },
    overlayEnterName: {
        color: Colors.WHITE,
        fontSize: normalize(18),
        textAlign: "center",
        marginBottom: "15px",
        fontFamily: "Gilroy-Bold",
    },
    overlayPlayerName: {
        color: Colors.WHITE,
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "7px",
        padding: "7px 12px",
        height: "80px",
        fontSize: 24,
        opacity: 1,
        fontFamily: "Gilroy-Bold",
    },
    overlayEnterNameAccent: {
        color: Colors.HEART,
        backgroundColor: "transparent",
        fontSize: 18,
        fontFamily: "Gilroy-Bold",
    },
    addButton: {
        display: "flex",
        alignSelf: "center",
        zIndex: 2,
        width: "300px",
        height: "80px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "13px",
    },
    whiteText: {
        color: Colors.WHITE,
        fontSize: normalize(18),
        fontFamily: "Gilroy-Bold",
        marginLeft: "3px",
    },
    centeredView: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "22px",
    },
    modalViewBuyEpisode: {
        width: "400px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        padding: "35px 25px 25px 25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    separatorBuyEpisode: {
        width: "400px",
        height: "2px",
        color: Colors.BTN_ACTIVE,
        backgroundColor: Colors.BTN_ACTIVE,
        marginTop: "25px",
    },
    popupButtons: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        marginTop: "25px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "15px 11px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
};

export default PreGame;
