/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../utils/ContextsHelper";
import { OnlineGame } from "../../models/onlineGame";
import useAuth from "../../services/Auth/useAuth";
import LoadingView from "../LoadingView";
import { getLanguage, strings } from "../../services/translation";
import { columnStartStyles, rowStyles } from "../../style/flex";
import { styles } from "./style";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { normalizeSize, normalizeVh } from "../../utils/fontSizer";
import OnlineGameBox from "./OnlineGameBox";
import { db } from "../../utils/FirebaseHelper";
import { FIREBASE_DATABASE, SORT } from "../../constants";
import _ from "lodash";
import Routes from "../../utils/OnlineRoutesHelper";
import { isObject, isTrueBoolean } from "../../utils/TypeOfHelper";
import { Colors } from "../../style";
import Loading from "../Loading";
import { useNavigate } from "react-router";
import ClassicModal from "../ClassicModal";
import { isNotEmptyString } from "../../utils/StringHelper";
import { goToOnlineGame } from "../../services/GameOnlineManager";
import { propertyExists } from "../../utils/ObjectHelper";
import { getRouteManager } from "../../services/routeManager";
import SoundManager from "../../services/SoundManager";

import { ReactComponent as IconUpgrade } from "../../assets/icons/icon_upgrade.svg";
import { ReactComponent as IconSort } from "../../assets/icons/icon_sort.svg";
import Separator from "../Separator";
import AvatarContainer from "../AvatarContainer";

const INITIAL_STATE = {
    disableJoinButton: false,
    error: "",
    isJoining: false,
    showHasLeftOnlineGameModal: false,
    showPopUpJoinGame: null,
};

const OnlineListScreen = () => {
    /**
     *
     * STATES
     *
     */
    const auth = useAuth();
    const { sidebarCollapsed } = useContext(SidebarContext);
    const matches = useMediaQuery(
        `(min-width:${sidebarCollapsed ? 1143 : 1195}px)`,
    );
    const [gamesList, setGamesList] = useState<OnlineGame[]>([]);
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState(SORT.TIME_DESC);
    const [sortedGames, setSortedGames] = useState<OnlineGame[]>([]);

    const navigate = useNavigate();
    const [disableJoinButton, setDisableJoinButton] = useState(
        INITIAL_STATE.disableJoinButton,
    );
    const [error, setError] = useState(INITIAL_STATE.error);
    const [isJoining, setIsJoining] = useState(INITIAL_STATE.isJoining);
    const [showPopUpJoinGame, setShowPopUpJoinGame] =
        useState<OnlineGame | null>(INITIAL_STATE.showPopUpJoinGame);

    /**
     *
     * USE EFFECTS
     *
     */

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        const sortedGamesResult = sortGames(sortOrder);
        setSortedGames(sortedGamesResult);
    }, [gamesList, sortOrder]);

    /**
     *
     * FUNCTIONS
     *
     */

    const initData = async () => {
        try {
            if (!loading) setLoading(true);
            resetValuesForJoiningOnlineGame();
            const validGames: OnlineGame[] = [];
            const gamesSnapshot = await db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}`)
                .once("value");
            for (const gameId in gamesSnapshot.val()) {
                const gameData = gamesSnapshot.val()[gameId];
                if (gameData && isValidSnapshot(gameData)) {
                    gameData.gameId = gameId;
                    gameData.players = await getPlayersArray(gameId);
                    gameData.hostPlayer = findHostPlayer(gameData);
                    validGames.push(gameData);
                }
            }
            setGamesList(validGames);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const sortGames = (order: string) => {
        const sortedGamesCopy = [...gamesList];

        switch (order) {
            case SORT.TIME_ASC:
                sortedGamesCopy.sort((a, b) => a.timestamp - b.timestamp);
                break;
            case SORT.TIME_DESC:
                sortedGamesCopy.sort((a, b) => b.timestamp - a.timestamp);
                break;
            case SORT.STORY_ASC:
                sortedGamesCopy.sort((a, b) => a.story.id - b.story.id);
                break;
            case SORT.STORY_DESC:
                sortedGamesCopy.sort((a, b) => b.story.id - a.story.id);
                break;
            default:
                break;
        }
        return sortedGamesCopy;
    };

    const handleSortChange = () => {
        const nextOrder =
            sortOrder === SORT.TIME_DESC
                ? SORT.TIME_ASC
                : sortOrder === SORT.TIME_ASC
                ? SORT.STORY_ASC
                : sortOrder === SORT.STORY_ASC
                ? SORT.STORY_DESC
                : SORT.TIME_DESC;

        setSortOrder(nextOrder);
    };

    /**
     *
     * @isValidSnapshot
     * Checks if is valid
     */
    const isValidSnapshot = (snapshot: OnlineGame): boolean => {
        return (
            snapshot &&
            snapshot.status &&
            snapshot.created &&
            snapshot.lang &&
            snapshot.lang === getLanguage() &&
            snapshot.status.currentScreen === Routes.LOBBY_SCREEN.name &&
            snapshot.isPublic &&
            !isTrueBoolean(snapshot.hasReachedPlayerMaxLimit)
        );
    };

    /**
     * @getPlayersArray
     * Returns players in a certain game from gameid
     * @param gameId
     */
    const getPlayersArray = async (gameId: string) => {
        const playersSnapshot = await db
            .ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameId}`)
            .once("value");
        const dbPlayers: any[] = _.toPairs(playersSnapshot.val());
        return [...dbPlayers];
    };

    /**
     * @findHostPlayer
     * Returns the player data for the host
     * @param game
     */
    const findHostPlayer = (game: OnlineGame) => {
        const hostId: any = Object.entries(game.host)[0][0];
        if (hostId && isNotEmptyArray(game.players)) {
            const exists = game.players.find(
                (player: any) => player[0] === hostId,
            );
            return exists ? exists[1] : null;
        }
    };

    /**
     * @getGameReference
     * Shortcut to get game data
     */
    const getGameReference = useCallback(async (id: string): Promise<any> => {
        let reference = null;

        if (isNotEmptyString(id)) {
            reference = await db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${id}`)
                .orderByKey()
                .once("value");
        }

        return reference;
    }, []);

    /**
     * @isPresentInSave
     * Check if the user is present in the save and returns true if yes
     * false otherwise
     */
    const isPresentInSave = (save: any, userID: any) => {
        let res = false;
        if (propertyExists(save, "players")) {
            save.players.map((item: any) => {
                if (item.avatar.userId === userID) {
                    res = true;
                }
            });
        }
        return res;
    };

    /**
     * @resetValuesForJoiningOnlineGame
     * Shortcut to make sure to reset these following values
     */
    const resetValuesForJoiningOnlineGame = useCallback((): void => {
        setDisableJoinButton(INITIAL_STATE.disableJoinButton);
        setIsJoining(INITIAL_STATE.isJoining);
        setShowPopUpJoinGame(INITIAL_STATE.showPopUpJoinGame);
    }, []);

    /**
     * @getPlayersLogoutReference
     * Shortcut to get game players logout
     * [this is the list of players who left the game (by disconnection)]
     * Then can return to the game at any time
     */
    const getPlayersLogoutReference = useCallback(
        async (id: string): Promise<any> => {
            let reference = null;

            if (isNotEmptyString(id)) {
                reference = await db
                    .ref(
                        `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_LOGOUT}/${id}`,
                    )
                    .orderByKey()
                    .once("value");
            }

            return reference;
        },
        [],
    );

    /**
     * @handleHidePopUpJoinGameModal
     * Shortcut to close the join game modal
     */
    const handleHidePopUpJoinGameModal = useCallback(() => {
        SoundManager.getInstance().playCloseModalSound();
        setShowPopUpJoinGame(INITIAL_STATE.showPopUpJoinGame);
    }, []);

    /**
     * @isAuthorizedToJoinGame
     * Triggered when the user can join the online game
     */
    const isAuthorizedToJoinGame = useCallback(
        (data: any) => {
            // hide active modal
            //handleHidePopUpJoinGameModal();

            // will be used to redirect user when game will be stopped
            localStorage.setItem(
                "deleteOnlineGameData",
                JSON.stringify({
                    story: data.story.isSeries
                        ? data.story.episode
                        : data.story.data,
                    isSeries: data.story.isSeries ? "true" : "false",
                }),
            );
        },
        [handleHidePopUpJoinGameModal],
    );

    /**
     * @canUserJoinGame
     * Checking if game is valid
     */
    const canUserJoinGame = useCallback(
        async (gameID: string): Promise<any> => {
            const defaultResult: any = {
                ok: false,
                screen: Routes.LOBBY_SCREEN,
                player: null,
            };

            if (isNotEmptyString(gameID)) {
                try {
                    const snapshot = await getGameReference(gameID);

                    if (snapshot !== null) {
                        if (snapshot.val() == null) {
                            resetValuesForJoiningOnlineGame();
                            setError(strings.errors.noGameExists);
                            return defaultResult;
                        } else if (
                            propertyExists(snapshot.val(), "status") &&
                            propertyExists(
                                snapshot.val().status,
                                "currentScreen",
                            ) &&
                            snapshot.val().status.currentScreen !==
                                Routes.LOBBY_SCREEN.name
                        ) {
                            const snapshotPlayersLogout =
                                await getPlayersLogoutReference(gameID);

                            if (
                                snapshotPlayersLogout !== null &&
                                snapshotPlayersLogout.val() !== null &&
                                propertyExists(auth.user, "id")
                            ) {
                                const playersLogout: any = _(
                                    snapshotPlayersLogout.val(),
                                ).values();
                                const playersLogoutAsArray = [...playersLogout];

                                if (isNotEmptyArray(playersLogoutAsArray)) {
                                    const playerFound = _.find(
                                        playersLogoutAsArray,
                                        {
                                            userID: auth.user.id,
                                        },
                                    );

                                    // case when a player who has disconnected from an online game in progress
                                    // and is trying to return it
                                    if (
                                        propertyExists(playerFound, "playerID")
                                    ) {
                                        const playerKey = _.findKey(
                                            snapshotPlayersLogout.val(),
                                            (player: any) =>
                                                _.isEqual(player, playerFound),
                                        );

                                        if (isNotEmptyString(playerKey)) {
                                            isAuthorizedToJoinGame(
                                                snapshot.val(),
                                            );

                                            return {
                                                ok: true,
                                                player: {
                                                    ...playerFound,
                                                    key: playerKey,
                                                },
                                            };
                                        }
                                    }
                                }
                            }

                            resetValuesForJoiningOnlineGame();
                            setError(strings.errors.gameAlreadyStarted);
                            return defaultResult;
                        } else if (
                            propertyExists(
                                snapshot.val(),
                                "hasReachedPlayerMaxLimit",
                            ) &&
                            isTrueBoolean(
                                snapshot.val().hasReachedPlayerMaxLimit,
                            )
                        ) {
                            resetValuesForJoiningOnlineGame();
                            setError(strings.errors.hasReachedPlayerMaxLimit);
                            return defaultResult;
                        } else if (propertyExists(snapshot.val(), "save")) {
                            const canEnter = isPresentInSave(
                                snapshot.val().save,
                                auth.user.id,
                            );
                            if (!canEnter) {
                                setError(strings.errors.playerNotInSave);
                                return defaultResult;
                            }
                        } else if (propertyExists(snapshot.val(), "lang")) {
                            const check = snapshot.val().lang === getLanguage();
                            if (!check) {
                                setError(strings.errors.notSameLanguage);
                                return defaultResult;
                            }
                        }

                        if (propertyExists(snapshot.val(), "story")) {
                            isAuthorizedToJoinGame(snapshot.val());
                            return { ...defaultResult, ok: true };
                        }
                    }

                    return defaultResult;
                } catch (error) {
                    resetValuesForJoiningOnlineGame();
                    return defaultResult;
                }
            } else {
                resetValuesForJoiningOnlineGame();
                return defaultResult;
            }
        },
        [
            getGameReference,
            handleHidePopUpJoinGameModal,
            isAuthorizedToJoinGame,
            resetValuesForJoiningOnlineGame,
            auth.user,
        ],
    );

    /**
     * @getTertiaryColor
     * Shortcut to get tertiary color value for the given story
     */
    const getTertiaryColor = useCallback(
        async (storyID: number): Promise<Array<any>> => {
            let color = [];

            const colorApi = getRouteManager().colorsForStory(storyID);
            const colors = await getRouteManager().getData(`${colorApi}`);

            if (isNotEmptyArray(colors)) {
                color = colors.filter(
                    (entry: any) => entry.type === "tertiary",
                );
            }

            return color;
        },
        [],
    );

    /**
     * @goToLobby
     * Shortcut to redirect user to the next screen (lobby)
     */
    const goToLobby = useCallback(
        async (gameID?: string, username?: string): Promise<void> => {
            if (
                gameID &&
                isNotEmptyString(gameID) &&
                isNotEmptyString(username) &&
                propertyExists(auth.user, "id")
            ) {
                const game = await getGameReference(gameID);

                const hasStory = propertyExists(game.val(), "story");
                let letterBorderColor: any = null;
                if (hasStory && propertyExists(game.val().story, "id")) {
                    // will be used for border player avatar
                    if (propertyExists(game.val().story, "colors")) {
                        const colors = JSON.parse(game.val().story.colors);
                        letterBorderColor = colors.filter(
                            (entry: any) => entry.type === "tertiary",
                        );
                    } else {
                        letterBorderColor = await getTertiaryColor(
                            game.val().story.id,
                        );
                    }
                    if (isNotEmptyArray(letterBorderColor) && username) {
                        handleHidePopUpJoinGameModal();
                        navigate(`${Routes.LOBBY_SCREEN.url}/${gameID}`, {
                            replace: true,
                            state: {
                                playerID: "", // important to keep as empty value
                                gameID,
                                screenName: username.trim(),
                                user: auth.user,
                                letterBorderColor: letterBorderColor[0].value,
                                episode:
                                    hasStory &&
                                    propertyExists(game.val().story, "episode")
                                        ? game.val().story.episode
                                        : null,
                                title:
                                    hasStory &&
                                    propertyExists(game.val().story, "title")
                                        ? game.val().story.title
                                        : "",
                                story: hasStory ? game.val().story : "",
                            },
                        });
                    } else {
                        setError(strings.errors.errorOccured + "#1");
                        setIsJoining(INITIAL_STATE.isJoining);
                    }
                } else {
                    setError(strings.errors.errorOccured + "#2");
                    setIsJoining(INITIAL_STATE.isJoining);
                }
            } else {
                setError(strings.errors.errorOccured + "#3");
                setIsJoining(INITIAL_STATE.isJoining);
            }
        },
        [getGameReference, getTertiaryColor, auth.user],
    );

    /**
     * @handleJoinGame
     * Triggered when user has submitted the join game form
     */
    const handleJoinGame = useCallback(async () => {
        setIsJoining(true);
        //setShowPopUpJoinGame(values);
        setError(""); // erase existing error if exists

        if (showPopUpJoinGame) {
            const values = showPopUpJoinGame.gameId;
            if (
                propertyExists(auth.user, "id") &&
                propertyExists(auth.user, "pseudo")
            ) {
                const currPlayerName = auth.user.pseudo.substring(0, 12);

                const gameID = values.toUpperCase();

                setDisableJoinButton(true);

                const isAuthorized = await canUserJoinGame(gameID);

                if (
                    propertyExists(isAuthorized, "ok") &&
                    isTrueBoolean(isAuthorized.ok)
                ) {
                    setIsJoining(INITIAL_STATE.isJoining);

                    if (propertyExists(isAuthorized, "screen")) {
                        switch (isAuthorized.screen.name) {
                            // redirect user to the lobby screen
                            case Routes.LOBBY_SCREEN.name:
                                goToLobby(gameID, currPlayerName);
                                break;
                            // redirect user to the online game
                            case Routes.GAME_ONLINE_SCREEN.name:
                                if (
                                    propertyExists(isAuthorized, "player") &&
                                    isObject(isAuthorized.player) &&
                                    propertyExists(isAuthorized.player, "key")
                                ) {
                                    const { key, playerID } =
                                        isAuthorized.player;

                                    const game = await getGameReference(gameID);

                                    if (game !== null) {
                                        db.ref(
                                            `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_LOGOUT}/${gameID}/${key}`,
                                        )
                                            .remove()
                                            .then(() => {
                                                handleHidePopUpJoinGameModal();
                                                goToOnlineGame(
                                                    navigate,
                                                    game.val(),
                                                    gameID,
                                                    playerID,
                                                    currPlayerName,
                                                    auth.user,
                                                );
                                            })
                                            .catch((error: any) => {
                                                console.log(
                                                    `Remove player logout ${playerID} failed: ${error.message}`,
                                                );
                                            });
                                    }
                                }
                                break;
                            default:
                                setError(strings.errors.errorOccured + "#4");
                                setIsJoining(false);
                                break;
                        }
                    } else {
                        setIsJoining(false);
                    }
                } else {
                    setIsJoining(false);
                }
            } else {
                setIsJoining(false);
            }
        }
    }, [canUserJoinGame, goToLobby, auth.user, showPopUpJoinGame]);

    /**
     *
     * RENDER
     *
     */

    const _renderModalJoiningContent = () => (
        <Box sx={styles.modalContent}>
            {isNotEmptyString(error) ? (
                <Typography sx={styles.joinGameErrorText}>{error}</Typography>
            ) : showPopUpJoinGame && !isJoining ? (
                <Box sx={{ ...columnStartStyles }}>
                    {showPopUpJoinGame.hostPlayer ? (
                        <AvatarContainer
                            user={showPopUpJoinGame.hostPlayer}
                            persoWidth={120}
                        />
                    ) : (
                        <Box sx={styles.circlePlaceholder} />
                    )}
                    <Typography sx={styles.playerName} component={"span"}>
                        {strings.online.wannaJoin}
                        {showPopUpJoinGame.hostPlayer ? (
                            <Typography
                                component={"span"}
                                sx={[
                                    styles.playerName,
                                    { color: Colors.WHITE },
                                ]}>
                                {showPopUpJoinGame.hostPlayer.username}
                            </Typography>
                        ) : (
                            ""
                        )}
                        {strings.online.wannaJoin2}
                        <Typography
                            component={"span"}
                            sx={[styles.playerName, { color: Colors.WHITE }]}>
                            {showPopUpJoinGame.story?.title
                                ? showPopUpJoinGame.story?.title
                                : ""}
                        </Typography>
                        {` ?`}
                    </Typography>
                </Box>
            ) : (
                <Loading />
            )}
        </Box>
    );

    const getSortOrderText = () => {
        switch (sortOrder) {
            case SORT.TIME_ASC:
                return strings.online.lessRecent;
            case SORT.STORY_ASC:
                return strings.online.byStory + " ⬆️";
            case SORT.STORY_DESC:
                return strings.online.byStory + " ⬇️";
            default:
                return strings.online.mostRecent;
        }
    };

    return (
        <Box
            sx={[
                matches ? styles.mainContainerBig : styles.mainContainerSmall,
                styles.mainContainer,
            ]}>
            <Box sx={matches ? styles.container : styles.containerSmall}>
                <Typography
                    sx={[
                        styles.topMenuTitle,
                        !matches && {
                            justifyContent: "center",
                            textAlign: "center",
                        },
                    ]}>
                    {strings.online.online}
                </Typography>
                <Typography
                    sx={[
                        styles.subtitle,
                        !matches && {
                            justifyContent: "center",
                            marginBottom: "10px",
                            textAlign: "center",
                        },
                    ]}>
                    {strings.online.subtitle}
                </Typography>
                <Box
                    sx={[
                        {
                            ...rowStyles,
                            alignItems: "center",
                            width: "100%",
                            columnGap: "7px",
                        },
                        matches
                            ? { justifyContent: "flex-start" }
                            : { justifyContent: "center" },
                    ]}>
                    <ButtonBase
                        sx={styles.refreshBtn}
                        disabled={loading}
                        onClick={() => initData()}>
                        <IconUpgrade
                            width={20}
                            height={20}
                            fill={Colors.WHITE}
                        />

                        <Typography sx={styles.refreshBtnText}>
                            {strings.online.refresh}
                        </Typography>
                    </ButtonBase>
                </Box>
            </Box>
            <Box
                sx={[
                    styles.right,
                    !matches && {
                        paddingTop: normalizeVh(4),
                        justifyContent: "center",
                        textAlign: "center",
                    },
                ]}>
                <Box
                    sx={[
                        styles.listSmall,
                        !matches
                            ? { alignSelf: "center" }
                            : { marginLeft: normalizeSize(20) },
                    ]}>
                    <Box sx={styles.listHeader}>
                        <Typography sx={styles.gameCategory}>
                            {strings.online.publicGames}
                        </Typography>
                        <ButtonBase
                            disabled={loading}
                            sx={styles.refreshBtn}
                            onClick={() => handleSortChange()}>
                            <IconSort
                                width={16}
                                height={16}
                                fill={Colors.OLD_PRICE}
                            />
                            <Typography
                                sx={[
                                    styles.refreshBtnText,
                                    {
                                        color: Colors.OLD_PRICE,
                                        fontSize: normalizeSize(11),
                                    },
                                ]}>
                                {getSortOrderText()}
                            </Typography>
                        </ButtonBase>
                    </Box>
                    {loading ? (
                        <Loading />
                    ) : isNotEmptyArray(sortedGames) ? (
                        sortedGames.map((game: OnlineGame, index: number) => (
                            <OnlineGameBox
                                key={index}
                                game={game}
                                onClick={setShowPopUpJoinGame}
                                disabled={isJoining || disableJoinButton}
                            />
                        ))
                    ) : (
                        <Typography sx={styles.subtitle}>
                            {strings.online.noGames}
                        </Typography>
                    )}
                </Box>
            </Box>
            <ClassicModal
                buttonText={
                    showPopUpJoinGame ? strings.join : strings.actions.cancel
                }
                twoButtons={!!showPopUpJoinGame}
                onCloseModal={() =>
                    showPopUpJoinGame
                        ? handleJoinGame()
                        : handleHidePopUpJoinGameModal()
                }
                onCancel={() => handleHidePopUpJoinGameModal()}
                modalVisible={!!showPopUpJoinGame}
                title={strings.joinGame}
                onBackdropPress={() => handleHidePopUpJoinGameModal()}
                content={_renderModalJoiningContent()}
            />
        </Box>
    );
};

export default OnlineListScreen;
