export const tokensAnim = {
  v: '5.9.6',
  fr: 29.9700012207031,
  ip: 0,
  op: 80.0000032584668,
  w: 50,
  h: 50,
  nm: 'Reward Token',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Token',
      fr: 29.9700012207031,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'token mask',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [12.5, 12.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.795, 0],
                        [0, -5.795],
                        [5.795, 0],
                        [0, 5.795],
                      ],
                      o: [
                        [5.795, 0],
                        [0, 5.795],
                        [-5.795, 0],
                        [0, -5.795],
                      ],
                      v: [
                        [0, -10.5],
                        [10.5, 0],
                        [0, 10.5],
                        [-10.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.717647058824, 0.800000059838, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [11.5, 12.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600.000024438501,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'shiny',
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [12.5, -25, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 30,
                  s: [12.5, -25, 0],
                  to: [0, 10, 0],
                  ti: [0, -10, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 50,
                  s: [12.5, 35, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 80.0000032584668, s: [12.5, 35, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.203, -32.163],
                        [21.007, -35.163],
                        [-20.007, 35.876],
                        [-14.811, 38.876],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 15, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [12.5, 12.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'shiny',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600.000024438501,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'token',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [12.5, 12.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -4.142],
                        [4.142, 0],
                        [0, 4.142],
                        [-4.142, 0],
                      ],
                      o: [
                        [0, 4.142],
                        [-4.142, 0],
                        [0, -4.142],
                        [4.142, 0],
                      ],
                      v: [
                        [7.5, 0],
                        [0, 7.5],
                        [-7.5, 0],
                        [0, -7.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.038, 0],
                        [0, 3.038],
                        [3.038, 0],
                        [0, -3.038],
                      ],
                      o: [
                        [3.038, 0],
                        [0, -3.038],
                        [-3.038, 0],
                        [0, 3.038],
                      ],
                      v: [
                        [0, 5.5],
                        [5.5, 0],
                        [0, -5.5],
                        [-5.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.466666696586, 0.521568627451, 0.650980392157, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [11.5, 12.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.795, 0],
                        [0, -5.795],
                        [5.795, 0],
                        [0, 5.795],
                      ],
                      o: [
                        [5.795, 0],
                        [0, 5.795],
                        [-5.795, 0],
                        [0, -5.795],
                      ],
                      v: [
                        [0, -10.5],
                        [10.5, 0],
                        [0, 10.5],
                        [-10.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.717647058824, 0.800000059838, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [11.5, 12.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 5.795],
                        [5.795, 0],
                        [0, 0],
                        [0, -5.795],
                        [-5.795, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -5.795],
                        [0, 0],
                        [-5.795, 0],
                        [0, 5.795],
                        [0, 0],
                        [5.795, 0],
                      ],
                      v: [
                        [11.5, 0],
                        [1, -10.5],
                        [-1, -10.5],
                        [-11.5, 0],
                        [-1, 10.5],
                        [1, 10.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.466666696586, 0.521568627451, 0.650980392157, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [12.5, 12.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600.000024438501,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Token Big',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 0,
              s: [130, 130, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 3,
              s: [90, 90, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 70,
              s: [110, 110, 100],
            },
            { t: 80.0000032584668, s: [130, 130, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 25,
      h: 25,
      ip: 0,
      op: 600.000024438501,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'Token2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 1,
              s: [30],
            },
            { t: 78.0000031770051, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 1,
              s: [25, 25, 0],
              to: [-7.615, 0.083, 0],
              ti: [1.396, -1.177, 0],
            },
            { t: 13.0000005295009, s: [11.188, 30, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [85, 85, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 25,
      h: 25,
      ip: 0,
      op: 600.000024438501,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'Token3',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 1,
              s: [30],
            },
            { t: 78.0000031770051, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 1,
              s: [25, 25, 0],
              to: [7.208, -0.042, 0],
              ti: [-1.865, -1.302, 0],
            },
            { t: 13.0000005295009, s: [39, 30, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [85, 85, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 25,
      h: 25,
      ip: 0,
      op: 600.000024438501,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
