/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { replaceColor, getColors } from "lottie-colorify";

export default class ColorManager {
    public static myInstance: any;

    private _colors: Array<any>;
    private _isSetup: boolean;

    constructor() {
        this._colors = [];
        this._isSetup = false;
    }

    static getInstance(): ColorManager {
        if (ColorManager.myInstance == null) {
            ColorManager.myInstance = new ColorManager();
        }
        return this.myInstance;
    }

    public generateColors(colors: any) {
        for (let i = 0; i < colors.length; i += 1) {
            this._colors.push({
                type: colors[i].type,
                value: colors[i].value,
            });
        }
        this._isSetup = true;
    }

    public getColor(colorType: string) {
        if (isNotEmptyArray(this._colors)) {
            const color = this._colors.filter(
                (color) => color.type === colorType,
            );
            if (color && color[0] && color[0].value) {
                return color[0].value;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public getColors(): Array<any> {
        if (isNotEmptyArray(this._colors)) {
            return this._colors;
        } else {
            return [];
        }
    }

    public getIsSetup(): boolean {
        return this._isSetup;
    }

    // eslint-disable-next-line class-methods-use-this
    public hexToRgba(hex: any, opacity: any) {
        let h = hex.replace("#", "");
        h = h.match(new RegExp(`(.{${h.length / 3}})`, "g"));

        for (let i = 0; i < h.length; i++)
            h[i] = parseInt(h[i].length == 1 ? h[i] + h[i] : h[i], 16);

        if (typeof opacity !== "undefined") h.push(opacity);
        return `rgba(${h.join(", ")})`;
    }

    public getAnimColors = (anim: any) => {
        return getColors(anim);
    };

    public changeAnimColors = (
        anim: any,
        colorsToReplace: any[],
        targetColors: any[],
    ) => {
        let animCopy = anim;
        for (let i = 0; i < colorsToReplace.length; i++) {
            animCopy = replaceColor(
                colorsToReplace[i],
                targetColors[i],
                animCopy,
            );
        }
        return animCopy;
    };

    public Clear() {
        if (this._colors.length > 0) {
            this._colors = [];
            this._isSetup = false;
        }
    }
}
