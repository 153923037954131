export class Information {
  private _type: string;

  private _text: string;

  constructor(type: string, text: string) {
    this._type = type;
    this._text = text;
  }

  public getType(): string {
    return this._type;
  }
  public setType(value: string) {
    this._type = value;
  }

  public getText(): string {
    return this._text;
  }
  public setText(value: string) {
    this._text = value;
  }
}
