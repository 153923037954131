/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
    Box,
    ButtonBase,
    InputBase,
    InputAdornment,
    Typography,
} from "@mui/material";
import { useNavigate, NavLink } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./style.css";
import { Colors } from "../../style";
import useAuth from "../../services/Auth/useAuth";
import { getRouteManager } from "../../services/routeManager";
import AvatarContainer from "../AvatarContainer";
import { ReactComponent as IconMenuTicket } from "../../assets/icons/icon_ticket.svg";
import { ReactComponent as IconBottom } from "../../assets/icons/icon_bottomMenu.svg";
//import { ReactComponent as IconNoNews } from "../../assets/icons/icon_no_news.svg";
import { ReactComponent as IconNoNews } from "../../assets/icons/heart_filled.svg";

import { ReactComponent as IconLevel } from "../../assets/icons/icon_present.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/icon_next_2.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/icon_back.svg";
import { ReactComponent as IconMenuStory } from "../../assets/icons/icon_story.svg";
import { ReactComponent as IconCompanions } from "../../assets/icons/icon_companion.svg";
import { ReactComponent as IconMainQuest } from "../../assets/icons/icon_main_quest.svg";

import JoinBtn from "../JoinBtn";
import { getLanguage, strings } from "../../services/translation";
import UserCurrencies from "../UserCurrencies";
import _ from "lodash";
import {
    CookiesContext,
    GameContext,
    NotificationContext,
    SidebarContext,
} from "../../utils/ContextsHelper";
import { Player } from "@lottiefiles/react-lottie-player";
import BigModal from "../BigModal";
import {
    columnBetweenStyles,
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../style/flex";
import { Formik } from "formik";
import { propertyExists } from "../../utils/ObjectHelper";
import { isObject, isTrueBoolean } from "../../utils/TypeOfHelper";
import Routes from "../../utils/OnlineRoutesHelper";
import { db } from "../../utils/FirebaseHelper";
import { goToOnlineGame } from "../../services/GameOnlineManager";
import { isNotEmptyString } from "../../utils/StringHelper";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { ONLINE_AVAILABLE } from "../../constants/parameters";
import { FIREBASE_DATABASE, GAME_ONLINE, SOCKET_EVENTS } from "../../constants";
import SoundManager from "../../services/SoundManager";
import * as yup from "yup";
import Loading from "../Loading";
import normalize, { normalizeSize } from "../../utils/fontSizer";
import { ReactComponent as IconKey } from "../../assets/icons/icon_key_menu.svg";
import { Eczar, Gilroy } from "../../style/fonts";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { isPositiveNumber } from "../../utils/NumberHelper";
import ConsentBanner from "../ConsentBanner";
import { rewardStoryJson } from "../../constants/rewardStory";
import TicketAnimated from "../TicketAnimated";
import PassBtn from "../PassBtn";
import PassAnimated from "../PassAnimated";
import GradientText from "../GradientText";
import ClassicModal from "../ClassicModal";
import { GameRequest } from "../../models/gameRequest";
import { socket } from "../../socket";
import GradientBorderBox from "../GradientBorderBox";

const INITIAL_STATE = {
    disableJoinButton: false,
    error: "",
    isJoining: false,
    showHasLeftOnlineGameModal: false,
    showPopUpJoinGame: false,
};

const joinGameValidationSchema = yup.object().shape({
    code: yup
        .string()
        .max(GAME_ONLINE.GAME_ID_LENGTH)
        .min(GAME_ONLINE.GAME_ID_LENGTH)
        .required(),
});

const Sidebar = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const {
        hasNews,
        hasNewMissions,
        hasNewLevel,
        hasFriendRequest,
        hasReferralReward,
        getMissions,
        getLevels,
        getNews,
        getFriends,
        getReferrals,
    } = useContext(NotificationContext);

    const { cookiesSet } = useContext(CookiesContext);
    const { gameID, playerID, gameRequest, removeRequest } =
        useContext(GameContext);
    const { sidebarCollapsed, setSidebarCollapsed } =
        useContext(SidebarContext);

    //online states
    const [onlineAvailable, setOnlineAvailable] = useState(false);
    const [disableJoinButton, setDisableJoinButton] = useState(
        INITIAL_STATE.disableJoinButton,
    );
    const [error, setError] = useState(INITIAL_STATE.error);
    const [isJoining, setIsJoining] = useState(INITIAL_STATE.isJoining);
    /*  const [showHasLeftOnlineGameModal, setShowHasLeftOnlineGameModal] = useState(
     INITIAL_STATE.showHasLeftOnlineGameModal,
   ); */
    const [showPopUpJoinGame, setShowPopUpJoinGame] = useState(
        INITIAL_STATE.showPopUpJoinGame,
    );

    const checkOnlineAvailable = async (): Promise<any> => {
        try {
            const param = getRouteManager().getParamForType(ONLINE_AVAILABLE);
            const res = await getRouteManager().postCheckParamValueNew(param);
            if (res) {
                setOnlineAvailable(res.value);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        checkOnlineAvailable();
    }, []);

    const acceptInvite = async (gameReq: GameRequest | null) => {
        if (gameReq && gameReq?.gameId) {
            removeRequest();
            handleJoinGame(gameReq.gameId);
        }
    };

    useEffect(() => {
        if (auth && auth.user && auth.user.login_token) {
            checkNews();
        }
    }, [auth]);

    const checkNews = async () => {
        await getLevels();
        await getMissions();
        //await getFeatured();
        await getNews();
        await getFriends();
        await getReferrals();
    };

    /**
     * Shortcut to get game data
     */
    const getGameReference = useCallback(async (id: string): Promise<any> => {
        let reference = null;

        if (isNotEmptyString(id)) {
            reference = await db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${id}`)
                .orderByKey()
                .once("value");
        }

        return reference;
    }, []);

    /**
     * Shortcut to get game players logout
     * [this is the list of players who left the game (by disconnection)]
     * Then can return to the game at any time
     */
    const getPlayersLogoutReference = useCallback(
        async (id: string): Promise<any> => {
            let reference = null;

            if (isNotEmptyString(id)) {
                reference = await db
                    .ref(
                        `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_LOGOUT}/${id}`,
                    )
                    .orderByKey()
                    .once("value");
            }

            return reference;
        },
        [],
    );

    /**
     * Shortcut to close the join game modal
     */
    const handleHidePopUpJoinGameModal = useCallback(() => {
        SoundManager.getInstance().playCloseModalSound();
        setShowPopUpJoinGame(INITIAL_STATE.showPopUpJoinGame);
    }, []);

    /**
     * Shortcut to make sure to reset these following values
     */
    const resetValuesForJoiningOnlineGame = useCallback((): void => {
        setDisableJoinButton(INITIAL_STATE.disableJoinButton);
        setIsJoining(INITIAL_STATE.isJoining);
    }, []);

    /**
     * Triggered when the user can join the online game
     */
    const isAuthorizedToJoinGame = useCallback(
        (data: any) => {
            // hide active modal
            //handleHidePopUpJoinGameModal();

            // will be used to redirect user when game will be stopped
            localStorage.setItem(
                "deleteOnlineGameData",
                JSON.stringify({
                    story: data.story.isSeries
                        ? data.story.episode
                        : data.story.data,
                    isSeries: data.story.isSeries ? "true" : "false",
                }),
            );
        },
        [handleHidePopUpJoinGameModal],
    );

    /**
     * Shortcut to get tertiary color value for the given story
     */
    const getTertiaryColor = useCallback(
        async (storyID: number): Promise<Array<any>> => {
            let color = [];

            const colorApi = getRouteManager().colorsForStory(storyID);
            const colors = await getRouteManager().getData(`${colorApi}`);

            if (isNotEmptyArray(colors)) {
                color = colors.filter(
                    (entry: any) => entry.type === "tertiary",
                );
            }

            return color;
        },
        [],
    );

    const isPresentInSave = (save: any, userID: any) => {
        let res = false;
        if (propertyExists(save, "players")) {
            save.players.map((item: any) => {
                if (item.avatar.userId === userID) {
                    res = true;
                }
            });
        }
        return res;
    };

    /**
     * Checking if game is valid
     */
    const canUserJoinGame = useCallback(
        async (gameID: string): Promise<any> => {
            const defaultResult: any = {
                ok: false,
                screen: Routes.LOBBY_SCREEN,
                player: null,
            };

            if (isNotEmptyString(gameID)) {
                try {
                    const snapshot = await getGameReference(gameID);

                    if (snapshot !== null) {
                        if (snapshot.val() == null) {
                            resetValuesForJoiningOnlineGame();
                            setError(strings.errors.noGameExists);
                            return defaultResult;
                        } else if (
                            propertyExists(snapshot.val(), "status") &&
                            propertyExists(
                                snapshot.val().status,
                                "currentScreen",
                            ) &&
                            snapshot.val().status.currentScreen !==
                                Routes.LOBBY_SCREEN.name
                        ) {
                            const snapshotPlayersLogout =
                                await getPlayersLogoutReference(gameID);

                            if (
                                snapshotPlayersLogout !== null &&
                                snapshotPlayersLogout.val() !== null &&
                                propertyExists(auth.user, "id")
                            ) {
                                const playersLogout: any = _(
                                    snapshotPlayersLogout.val(),
                                ).values();
                                const playersLogoutAsArray = [...playersLogout];

                                if (isNotEmptyArray(playersLogoutAsArray)) {
                                    const playerFound = _.find(
                                        playersLogoutAsArray,
                                        {
                                            userID: auth.user.id,
                                        },
                                    );

                                    // case when a player who has disconnected from an online game in progress
                                    // and is trying to return it
                                    if (
                                        propertyExists(playerFound, "playerID")
                                    ) {
                                        const playerKey = _.findKey(
                                            snapshotPlayersLogout.val(),
                                            (player: any) =>
                                                _.isEqual(player, playerFound),
                                        );

                                        if (isNotEmptyString(playerKey)) {
                                            isAuthorizedToJoinGame(
                                                snapshot.val(),
                                            );

                                            return {
                                                ok: true,
                                                player: {
                                                    ...playerFound,
                                                    key: playerKey,
                                                },
                                            };
                                        }
                                    }
                                }
                            }

                            resetValuesForJoiningOnlineGame();
                            setError(strings.errors.gameAlreadyStarted);
                            return defaultResult;
                        } else if (
                            propertyExists(
                                snapshot.val(),
                                "hasReachedPlayerMaxLimit",
                            ) &&
                            isTrueBoolean(
                                snapshot.val().hasReachedPlayerMaxLimit,
                            )
                        ) {
                            resetValuesForJoiningOnlineGame();
                            setError(strings.errors.hasReachedPlayerMaxLimit);
                            return defaultResult;
                        } else if (propertyExists(snapshot.val(), "save")) {
                            const canEnter = isPresentInSave(
                                snapshot.val().save,
                                auth.user.id,
                            );
                            if (!canEnter) {
                                setError(strings.errors.playerNotInSave);
                                return defaultResult;
                            }
                        } else if (propertyExists(snapshot.val(), "lang")) {
                            const check = snapshot.val().lang === getLanguage();
                            if (!check) {
                                setError(strings.errors.notSameLanguage);
                                return defaultResult;
                            }
                        }

                        if (propertyExists(snapshot.val(), "story")) {
                            isAuthorizedToJoinGame(snapshot.val());
                            return { ...defaultResult, ok: true };
                        }
                    }

                    return defaultResult;
                } catch (error) {
                    resetValuesForJoiningOnlineGame();
                    return defaultResult;
                }
            } else {
                resetValuesForJoiningOnlineGame();
                return defaultResult;
            }
        },
        [
            getGameReference,
            handleHidePopUpJoinGameModal,
            isAuthorizedToJoinGame,
            resetValuesForJoiningOnlineGame,
            auth.user,
        ],
    );

    /**
     * Shortcut to redirect user to the next screen (lobby)
     */
    const goToLobby = useCallback(
        async (gameID?: string, username?: string): Promise<void> => {
            if (
                gameID &&
                isNotEmptyString(gameID) &&
                isNotEmptyString(username) &&
                propertyExists(auth.user, "id")
            ) {
                const game = await getGameReference(gameID);

                const hasStory = propertyExists(game.val(), "story");
                let letterBorderColor: any = null;
                if (hasStory && propertyExists(game.val().story, "id")) {
                    // will be used for border player avatar
                    if (propertyExists(game.val().story, "colors")) {
                        const colors = JSON.parse(game.val().story.colors);
                        letterBorderColor = colors.filter(
                            (entry: any) => entry.type === "tertiary",
                        );
                    } else {
                        letterBorderColor = await getTertiaryColor(
                            game.val().story.id,
                        );
                    }
                    if (isNotEmptyArray(letterBorderColor) && username) {
                        handleHidePopUpJoinGameModal();
                        navigate(`${Routes.LOBBY_SCREEN.url}/${gameID}`, {
                            replace: true,
                            state: {
                                playerID: playerID ? playerID : "", // important to keep as empty value
                                gameID,
                                screenName: username.trim(),
                                user: auth.user,
                                letterBorderColor: letterBorderColor[0].value,
                                episode:
                                    hasStory &&
                                    propertyExists(game.val().story, "episode")
                                        ? game.val().story.episode
                                        : null,
                                title:
                                    hasStory &&
                                    propertyExists(game.val().story, "title")
                                        ? game.val().story.title
                                        : "",
                                story: hasStory ? game.val().story : "",
                            },
                        });
                    } else {
                        setError(strings.errors.errorOccured + "#1");
                        setIsJoining(INITIAL_STATE.isJoining);
                    }
                } else {
                    setError(strings.errors.errorOccured + "#2");
                    setIsJoining(INITIAL_STATE.isJoining);
                }
            } else {
                setError(strings.errors.errorOccured + "#3");
                setIsJoining(INITIAL_STATE.isJoining);
            }
        },
        [getGameReference, getTertiaryColor, auth.user],
    );

    /**
     * Triggered when user has submitted the join game form
     */
    const handleJoinGame = useCallback(
        async (values: any) => {
            setIsJoining(true);
            setError(""); // erase existing error if exists

            if (
                propertyExists(auth.user, "id") &&
                propertyExists(auth.user, "pseudo")
            ) {
                const currPlayerName = auth.user.pseudo.substring(0, 12);

                const gameID = values.toUpperCase();

                setDisableJoinButton(true);

                const isAuthorized = await canUserJoinGame(gameID);

                if (
                    propertyExists(isAuthorized, "ok") &&
                    isTrueBoolean(isAuthorized.ok)
                ) {
                    setIsJoining(INITIAL_STATE.isJoining);

                    if (propertyExists(isAuthorized, "screen")) {
                        switch (isAuthorized.screen.name) {
                            // redirect user to the lobby screen
                            case Routes.LOBBY_SCREEN.name:
                                goToLobby(gameID, currPlayerName);
                                break;
                            // redirect user to the online game
                            case Routes.GAME_ONLINE_SCREEN.name:
                                if (
                                    propertyExists(isAuthorized, "player") &&
                                    isObject(isAuthorized.player) &&
                                    propertyExists(isAuthorized.player, "key")
                                ) {
                                    const { key, playerID } =
                                        isAuthorized.player;

                                    const game = await getGameReference(gameID);

                                    if (game !== null) {
                                        db.ref(
                                            `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_LOGOUT}/${gameID}/${key}`,
                                        )
                                            .remove()
                                            .then(() => {
                                                handleHidePopUpJoinGameModal();
                                                goToOnlineGame(
                                                    navigate,
                                                    game.val(),
                                                    gameID,
                                                    playerID,
                                                    currPlayerName,
                                                    auth.user,
                                                );
                                            })
                                            .catch((error: any) => {
                                                console.log(
                                                    `Remove player logout ${playerID} failed: ${error.message}`,
                                                );
                                            });
                                    }
                                }
                                break;
                            default:
                                setError(strings.errors.errorOccured + "#4");
                                setIsJoining(false);
                                break;
                        }
                    } else {
                        setIsJoining(false);
                    }
                } else {
                    setIsJoining(false);
                }
            } else {
                setIsJoining(false);
            }
        },
        [canUserJoinGame, goToLobby, auth.user],
    );

    /**
     *
     * Shortcut to close the `hasLeftOnlineGame' modal
     */
    /* const onCloseHasLeftOnlineGameModal = () => {
    setShowHasLeftOnlineGameModal(INITIAL_STATE.showHasLeftOnlineGameModal);
  }; */

    /**
     *
     */
    const handleError = (error: string, type: string) => {
        if (error.endsWith("is a required field") && type === "code") {
            return strings.codeRequired;
        } else if (error.endsWith("characters") && type === "code") {
            return `Le code doit faire ${GAME_ONLINE.GAME_ID_LENGTH} caractères`;
        } else {
            return error;
        }
    };

    const declineInvitation = () => {
        socket.emit(SOCKET_EVENTS.DECLINE_GAME_REQUEST, {
            playerCode: auth.user.player_code,
            gameRequest: gameRequest,
        });
        removeRequest();
    };

    const renderInviteModal = () => {
        if (
            gameRequest &&
            gameRequest.gameId &&
            gameRequest.friend &&
            gameRequest.friend.avatar &&
            gameRequest.friend.pseudo
        ) {
            return (
                <Box style={styles.modalContent}>
                    <AvatarContainer
                        persoWidth={120}
                        user={gameRequest.friend}
                        borderWidth={0}
                    />
                    <Typography component="span" sx={styles.modalText}>
                        <Typography
                            component="span"
                            sx={[styles.modalText, { color: Colors.WHITE }]}>
                            {gameRequest.friend.pseudo}
                        </Typography>
                        <Typography component="span" sx={styles.modalText}>
                            {strings.online.invitedYou}
                        </Typography>
                        {/* <Typography
                            component="span"
                            sx={[styles.modalText, { color: Colors.ACCENT }]}>
                            {strings.friends.friendsMin}
                        </Typography> */}
                    </Typography>
                </Box>
            );
        }
    };

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${Colors.PRIMARY} !important`,
                    borderRight: "2px solid #25262F",
                    height: "100%",
                },
                "& .pro-sidebar-layout": {
                    height: "100%",
                },
                "& .pro-sidebar-layout ul": {
                    height: "100%",
                },
                "& .pro-menu": {
                    height: "100%",
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: Colors.ACCENT + "!important",
                },
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                    display: "none",
                },
                ".pro-sidebar-inner::-webkit-scrollbar": {
                    display: "none",
                },
                ".pro-sidebar-layout::-webkit-scrollbar": {
                    display: "none",
                },
                "& .top-item": {
                    margin: "10px 0 20px 0",
                    padding: "0px",
                },
                "& .top-item .pro-inner-item": {
                    padding: "0px !important",
                },
                "& .top-item .pro-inner-item .pro-item-content": {
                    padding: "0px",
                    width: "100%",
                },
                ".pro-sidebar .pro-menu .pro-menu-item .pro-inner-item .pro-item-content":
                    {
                        overflow: "visible",
                    },
            }}>
            <ProSidebar collapsed={sidebarCollapsed}>
                <Menu iconShape="square">
                    <Box
                        sx={{
                            ...columnStyles,
                            justifyContent: "space-between",
                            height: "100%",
                        }}>
                        <Box sx={{ ...columnStyles }}>
                            {/* Currency */}
                            <Box
                                display="flex"
                                flexDirection={
                                    sidebarCollapsed ? "column" : "row"
                                }
                                justifyContent={
                                    sidebarCollapsed ? "center" : "space-evenly"
                                }
                                alignItems="center"
                                zIndex={1000}>
                                {!sidebarCollapsed && <Box width={40} />}
                                {auth && auth.user && !sidebarCollapsed ? (
                                    <UserCurrencies
                                        user={auth.user}
                                        goToShop={() => navigate("/shop")}
                                    />
                                ) : null}
                                <ButtonBase
                                    onClick={() =>
                                        setSidebarCollapsed(!sidebarCollapsed)
                                    }
                                    sx={{
                                        borderRadius: "40px",
                                        position: "relative",
                                        left: sidebarCollapsed
                                            ? 0
                                            : auth?.user?.pass?.isActive ||
                                              auth?.user?.isTemporaryAccount
                                            ? 0
                                            : "20px",
                                    }}>
                                    {sidebarCollapsed ? (
                                        <NextIcon width={40} height={40} />
                                    ) : (
                                        <BackIcon width={40} height={40} />
                                    )}
                                </ButtonBase>
                                {sidebarCollapsed && auth && auth.user && (
                                    <UserCurrencies
                                        user={auth.user}
                                        goToShop={() =>
                                            (window.location.href = "/shop")
                                        }
                                        isCollapsed
                                        style={{ marginTop: "24px" }}
                                    />
                                )}
                            </Box>
                            {/* User */}
                            {!sidebarCollapsed ? (
                                <Box>
                                    <MenuItem
                                        style={{
                                            margin: "10px 0 0px 0",
                                            color: Colors.FOND_ITEM_POPUP,
                                        }}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            ml="15px">
                                            {auth?.user && (
                                                <NavLink
                                                    to="/editUser"
                                                    className="avatar">
                                                    <AvatarContainer
                                                        user={auth.user}
                                                        persoWidth={120}
                                                    />
                                                    <Box className="nameBlock">
                                                        <Typography
                                                            component={"span"}
                                                            className="text pseudo"
                                                            sx={{
                                                                fontFamily:
                                                                    Eczar.regular,
                                                                fontSize:
                                                                    "30px",
                                                                fontWeight: 600,
                                                                marginTop:
                                                                    "24px",
                                                            }}>
                                                            {auth.user.pseudo}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    Gilroy,
                                                                fontSize:
                                                                    "16px",
                                                                fontWeight: 400,
                                                            }}
                                                            component={"span"}
                                                            className="text title">
                                                            {auth.user &&
                                                            auth.user?.title &&
                                                            auth.user?.title
                                                                ?.name
                                                                ? auth.user
                                                                      .title
                                                                      .name
                                                                : ""}
                                                        </Typography>
                                                    </Box>
                                                </NavLink>
                                            )}
                                        </Box>
                                    </MenuItem>
                                    <Typography
                                        sx={{
                                            fontFamily: Gilroy,
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: Colors.FOND_ITEM_POPUP,
                                            marginTop: "10px",
                                            alignSelf: "center",
                                            textAlign: "center",
                                        }}
                                        component={"span"}
                                        className="text title">
                                        {auth.user && auth.user?.player_code
                                            ? "#" + auth.user.player_code
                                            : ""}
                                    </Typography>
                                </Box>
                            ) : (
                                <MenuItem
                                    style={{
                                        margin: "10px 0 20px 0",
                                        color: Colors.FOND_ITEM_POPUP,
                                    }}
                                    icon={
                                        sidebarCollapsed ? (
                                            <Box mb="0px">
                                                {auth?.user && (
                                                    <>
                                                        <NavLink
                                                            to="/editUser"
                                                            className="avatar">
                                                            <AvatarContainer
                                                                user={auth.user}
                                                                persoWidth={50}
                                                            />
                                                        </NavLink>
                                                    </>
                                                )}
                                            </Box>
                                        ) : undefined
                                    }
                                />
                            )}
                            <Box className="separator" />
                            {/* Menu */}
                            <Box paddingLeft={sidebarCollapsed ? "0%" : "15%"}>
                                {/* Adventures */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/"
                                                className="menu-link">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIconCollapsed">
                                                            {isActive ? (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <Player
                                                                        src={
                                                                            rewardStoryJson
                                                                        }
                                                                        loop
                                                                        autoplay
                                                                        speed={
                                                                            1.5
                                                                        }
                                                                        style={{
                                                                            width: "30px",
                                                                            height: "30px",
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconMenuStory
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.vertALEXTESGRANDSMORTS
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    <NavLink to="/" className="menu-link">
                                        {({ isActive }) => {
                                            if (!sidebarCollapsed) {
                                                return (
                                                    <Box
                                                        className="menu-link-content"
                                                        style={{
                                                            color: isActive
                                                                ? Colors.vertALEXTESGRANDSMORTS
                                                                : "",
                                                        }}>
                                                        <Box className="menuIcon">
                                                            {isActive ? (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <Player
                                                                        src={
                                                                            rewardStoryJson
                                                                        }
                                                                        loop
                                                                        autoplay
                                                                        speed={
                                                                            1.5
                                                                        }
                                                                        style={{
                                                                            width: "24px",
                                                                            height: "24px",
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconMenuStory
                                                                        width={
                                                                            24
                                                                        }
                                                                        height={
                                                                            24
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.vertALEXTESGRANDSMORTS
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            sx={styles.gilroy}
                                                            component={"span"}>
                                                            {strings.library}
                                                        </Typography>
                                                    </Box>
                                                );
                                            } else {
                                                return null;
                                            }
                                        }}
                                    </NavLink>
                                </MenuItem>
                                <Box className="separatorEmpty" />
                                {/* Gallery */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/mainQuest"
                                                className="menu-link">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIcon">
                                                            {isActive ? (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconMainQuest
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.ACCENT
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconMainQuest
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.ACCENT
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                    {/* {isPositiveNumber(hasReward) ? (
                                <Box sx={styles.hasNew} />
                              ) : null} */}
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    <NavLink
                                        to="/mainQuest"
                                        className="menu-link">
                                        {({ isActive }) => {
                                            return (
                                                <>
                                                    {!sidebarCollapsed ? (
                                                        <Box
                                                            className="menu-link-content"
                                                            style={{
                                                                color: isActive
                                                                    ? Colors.ACCENT
                                                                    : "",
                                                            }}>
                                                            <Box className="menuIcon">
                                                                {isActive ? (
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...columnCenterStyles,
                                                                            },
                                                                        ]}>
                                                                        <IconMainQuest
                                                                            width={
                                                                                25
                                                                            }
                                                                            height={
                                                                                25
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.ACCENT
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {/* {isPositiveNumber(hasReward) ? (
                                  <Box sx={styles.hasNew} />
                                ) : null} */}
                                                                    </Box>
                                                                ) : (
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...columnCenterStyles,
                                                                            },
                                                                        ]}>
                                                                        <IconMainQuest
                                                                            width={
                                                                                25
                                                                            }
                                                                            height={
                                                                                25
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.ACCENT
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>

                                                            <Typography
                                                                sx={
                                                                    styles.gilroy
                                                                }
                                                                component={
                                                                    "span"
                                                                }>
                                                                {
                                                                    strings
                                                                        .mainQuest
                                                                        .title
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </NavLink>
                                </MenuItem>
                                <Box className="separatorEmpty" />
                                {/* Social */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/social"
                                                className="menu-link link-test">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIconCollapsed">
                                                            {!isPositiveNumber(
                                                                hasNews,
                                                            ) ||
                                                            !isPositiveNumber(
                                                                hasFriendRequest,
                                                            ) ? (
                                                                <IconNoNews
                                                                    width={24}
                                                                    height={24}
                                                                    fill={
                                                                        isActive
                                                                            ? Colors.PACK
                                                                            : Colors.FOND_ITEM_POPUP
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    {/* <Player
                                src={newsIconAnimated}
                                loop
                                autoplay
                                speed={1.5}
                                style={{ width: "24px", height: "24px" }}
                              /> */}
                                                                    <Box
                                                                        sx={
                                                                            styles.iconBox
                                                                        }>
                                                                        <IconNoNews
                                                                            width={
                                                                                24
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.PACK
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {isPositiveNumber(
                                                                            hasNews,
                                                                        ) ||
                                                                        isPositiveNumber(
                                                                            hasFriendRequest,
                                                                        ) ? (
                                                                            <Box
                                                                                sx={
                                                                                    styles.hasNew
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    {!sidebarCollapsed ? (
                                        <NavLink
                                            to="/social"
                                            className="menu-link">
                                            {({ isActive }) => {
                                                return (
                                                    <Box
                                                        className="menu-link-content"
                                                        style={{
                                                            color: isActive
                                                                ? Colors.PACK
                                                                : "",
                                                        }}>
                                                        <Box className="menuIcon">
                                                            {!isPositiveNumber(
                                                                hasNews,
                                                            ) &&
                                                            !isPositiveNumber(
                                                                hasFriendRequest,
                                                            ) ? (
                                                                <IconNoNews
                                                                    width={24}
                                                                    height={24}
                                                                    fill={
                                                                        isActive
                                                                            ? Colors.PACK
                                                                            : Colors.FOND_ITEM_POPUP
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    {/* <Player
                              src={newsIconAnimated}
                              loop
                              autoplay
                              speed={1.5}
                              style={{ width: "24px", height: "24px" }}
                            /> */}
                                                                    <Box
                                                                        sx={
                                                                            styles.iconBox
                                                                        }>
                                                                        <IconNoNews
                                                                            width={
                                                                                24
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.PACK
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {isPositiveNumber(
                                                                            hasNews,
                                                                        ) ||
                                                                        isPositiveNumber(
                                                                            hasFriendRequest,
                                                                        ) ? (
                                                                            <Box
                                                                                sx={
                                                                                    styles.hasNew
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            sx={styles.gilroy}
                                                            component={"span"}>
                                                            {
                                                                strings.titles
                                                                    .social
                                                            }
                                                        </Typography>
                                                    </Box>
                                                );
                                            }}
                                        </NavLink>
                                    ) : null}
                                </MenuItem>
                                <Box className="separatorEmpty" />
                                {/* companions */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/companions"
                                                className="menu-link">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIcon">
                                                            {isActive ? (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconCompanions
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.WHITE
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconCompanions
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.WHITE
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                    {/* {isPositiveNumber(hasReward) ? (
                                <Box sx={styles.hasNew} />
                              ) : null} */}
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    <NavLink
                                        to="/companions"
                                        className="menu-link">
                                        {({ isActive }) => {
                                            return (
                                                <>
                                                    {!sidebarCollapsed ? (
                                                        <Box
                                                            className="menu-link-content"
                                                            style={{
                                                                color: isActive
                                                                    ? Colors.WHITE
                                                                    : "",
                                                            }}>
                                                            <Box className="menuIcon">
                                                                {isActive ? (
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...columnCenterStyles,
                                                                            },
                                                                        ]}>
                                                                        <IconCompanions
                                                                            width={
                                                                                25
                                                                            }
                                                                            height={
                                                                                25
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.WHITE
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {/* {isPositiveNumber(hasReward) ? (
                                  <Box sx={styles.hasNew} />
                                ) : null} */}
                                                                    </Box>
                                                                ) : (
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...columnCenterStyles,
                                                                            },
                                                                        ]}>
                                                                        <IconCompanions
                                                                            width={
                                                                                25
                                                                            }
                                                                            height={
                                                                                25
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.WHITE
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {/* {isPositiveNumber(hasReward) ? (
                                  <Box sx={styles.hasNew} />
                                ) : null} */}
                                                                    </Box>
                                                                )}
                                                            </Box>

                                                            <Typography
                                                                sx={
                                                                    styles.gilroy
                                                                }
                                                                component={
                                                                    "span"
                                                                }>
                                                                {
                                                                    strings
                                                                        .companions
                                                                        .companions
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </NavLink>
                                </MenuItem>
                                <Box className="separatorEmpty" />
                                {/* Rewards */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/rewards"
                                                className="menu-link">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIconCollapsed">
                                                            {!isPositiveNumber(
                                                                hasNewMissions,
                                                            ) &&
                                                            !isPositiveNumber(
                                                                hasNewLevel,
                                                            ) &&
                                                            !isPositiveNumber(
                                                                hasReferralReward,
                                                            ) ? (
                                                                <IconLevel
                                                                    width={24}
                                                                    height={24}
                                                                    fill={
                                                                        isActive
                                                                            ? Colors.ACCENT
                                                                            : Colors.FOND_ITEM_POPUP
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    {/*  <Player
                              src={levelRewardWaiting}
                              loop
                              autoplay
                              speed={1.5}
                              style={{ width: "24px", height: "24px" }}
                            /> */}
                                                                    <Box
                                                                        sx={
                                                                            styles.iconBox
                                                                        }>
                                                                        <IconLevel
                                                                            width={
                                                                                24
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.ACCENT
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {isPositiveNumber(
                                                                            hasNewMissions,
                                                                        ) ||
                                                                        isPositiveNumber(
                                                                            hasNewLevel,
                                                                        ) ||
                                                                        isPositiveNumber(
                                                                            hasReferralReward,
                                                                        ) ? (
                                                                            <Box
                                                                                sx={
                                                                                    styles.hasNew
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    {!sidebarCollapsed ? (
                                        <NavLink
                                            to="/rewards"
                                            className="menu-link">
                                            {({ isActive }) => {
                                                return (
                                                    <Box
                                                        className="menu-link-content"
                                                        style={{
                                                            color: isActive
                                                                ? Colors.ACCENT
                                                                : "",
                                                        }}>
                                                        <Box className="menuIcon">
                                                            {!isPositiveNumber(
                                                                hasNewMissions,
                                                            ) &&
                                                            !isPositiveNumber(
                                                                hasNewLevel,
                                                            ) &&
                                                            !isPositiveNumber(
                                                                hasReferralReward,
                                                            ) ? (
                                                                <IconLevel
                                                                    width={24}
                                                                    height={24}
                                                                    fill={
                                                                        isActive
                                                                            ? Colors.ACCENT
                                                                            : Colors.FOND_ITEM_POPUP
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    {/*  <Player
                              src={levelRewardWaiting}
                              loop
                              autoplay
                              speed={1.5}
                              style={{ width: "24px", height: "24px" }}
                            /> */}
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...rowStartStyles,
                                                                            },
                                                                        ]}>
                                                                        <IconLevel
                                                                            width={
                                                                                24
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.ACCENT
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {isPositiveNumber(
                                                                            hasNewMissions,
                                                                        ) ||
                                                                        isPositiveNumber(
                                                                            hasNewLevel,
                                                                        ) ||
                                                                        isPositiveNumber(
                                                                            hasReferralReward,
                                                                        ) ? (
                                                                            <Box
                                                                                sx={
                                                                                    styles.hasNew
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            sx={styles.gilroy}
                                                            component={"span"}>
                                                            {
                                                                strings.labels
                                                                    .rewards
                                                            }
                                                        </Typography>
                                                    </Box>
                                                );
                                            }}
                                        </NavLink>
                                    ) : null}
                                </MenuItem>
                                <Box className="separatorEmpty" />
                                {/* Shop */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/shop"
                                                className="menu-link">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIcon">
                                                            {isActive ? (
                                                                <TicketAnimated
                                                                    style={{
                                                                        width: 25,
                                                                        height: 25,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    sx={[
                                                                        styles.iconBox,
                                                                        {
                                                                            ...columnCenterStyles,
                                                                        },
                                                                    ]}>
                                                                    <IconMenuTicket
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            isActive
                                                                                ? Colors.TICKET
                                                                                : Colors.FOND_ITEM_POPUP
                                                                        }
                                                                    />
                                                                    {/* {isPositiveNumber(hasReward) ? (
                                <Box sx={styles.hasNew} />
                              ) : null} */}
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    <NavLink to="/shop" className="menu-link">
                                        {({ isActive }) => {
                                            return (
                                                <>
                                                    {!sidebarCollapsed ? (
                                                        <Box
                                                            className="menu-link-content"
                                                            style={{
                                                                color: isActive
                                                                    ? Colors.TICKET
                                                                    : "",
                                                            }}>
                                                            <Box className="menuIcon">
                                                                {isActive ? (
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...columnCenterStyles,
                                                                            },
                                                                        ]}>
                                                                        <TicketAnimated
                                                                            style={{
                                                                                width: 24,
                                                                                height: 24,
                                                                            }}
                                                                        />
                                                                        {/* {isPositiveNumber(hasReward) ? (
                                  <Box sx={styles.hasNew} />
                                ) : null} */}
                                                                    </Box>
                                                                ) : (
                                                                    <Box
                                                                        sx={[
                                                                            styles.iconBox,
                                                                            {
                                                                                ...columnCenterStyles,
                                                                            },
                                                                        ]}>
                                                                        <IconMenuTicket
                                                                            width={
                                                                                24
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            fill={
                                                                                isActive
                                                                                    ? Colors.TICKET
                                                                                    : Colors.FOND_ITEM_POPUP
                                                                            }
                                                                        />
                                                                        {/* {isPositiveNumber(hasReward) ? (
                                  <Box sx={styles.hasNew} />
                                ) : null} */}
                                                                    </Box>
                                                                )}
                                                            </Box>

                                                            <Typography
                                                                sx={
                                                                    styles.gilroy
                                                                }
                                                                component={
                                                                    "span"
                                                                }>
                                                                {strings.shop}
                                                            </Typography>
                                                        </Box>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </NavLink>
                                </MenuItem>
                                <Box className="separatorEmpty" />
                                {/* Menu */}
                                <MenuItem
                                    icon={
                                        sidebarCollapsed ? (
                                            <NavLink
                                                to="/menu"
                                                className="menu-link">
                                                {({ isActive }) => {
                                                    return (
                                                        <Box className="menuIconCollapsed">
                                                            <IconBottom
                                                                width={24}
                                                                height={24}
                                                                fill={
                                                                    isActive
                                                                        ? Colors.HEART
                                                                        : Colors.FOND_ITEM_POPUP
                                                                }
                                                            />
                                                        </Box>
                                                    );
                                                }}
                                            </NavLink>
                                        ) : undefined
                                    }>
                                    {!sidebarCollapsed ? (
                                        <NavLink
                                            to="/menu"
                                            className="menu-link">
                                            {({ isActive }) => {
                                                return (
                                                    <Box
                                                        className="menu-link-content"
                                                        style={{
                                                            color: isActive
                                                                ? Colors.HEART
                                                                : "",
                                                        }}>
                                                        <Box className="menuIcon">
                                                            <IconBottom
                                                                width={24}
                                                                height={24}
                                                                fill={
                                                                    isActive
                                                                        ? Colors.HEART
                                                                        : Colors.FOND_ITEM_POPUP
                                                                }
                                                            />
                                                        </Box>
                                                        <Typography
                                                            sx={styles.gilroy}
                                                            component={"span"}>
                                                            Menu
                                                        </Typography>
                                                    </Box>
                                                );
                                            }}
                                        </NavLink>
                                    ) : null}
                                </MenuItem>
                            </Box>
                            <Box className="separator" />
                            <Box className="separatorEmpty" />
                        </Box>
                        {/* BTNS */}
                        <Box
                            sx={{
                                ...columnCenterStyles,
                                marginBottom: "24px",
                            }}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                {auth &&
                                auth.user &&
                                auth.user.pass &&
                                auth.user.pass.isActive ? null : (
                                    <PassBtn
                                        borderRadius={
                                            sidebarCollapsed ? 11 : 13
                                        }
                                        style={{
                                            width: sidebarCollapsed
                                                ? "50px"
                                                : "228.5px",
                                            height: "50px",
                                            opacity: 0.9,
                                        }}>
                                        {sidebarCollapsed ? (
                                            <Box sx={{ ...rowCenterStyles }}>
                                                <PassAnimated
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    ...rowStartStyles,
                                                    gap: "8px",
                                                }}>
                                                <PassAnimated
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                    }}
                                                />
                                                <GradientText
                                                    color1={Colors.PASS1}
                                                    color2={Colors.PASS2}
                                                    style={styles.passText}>
                                                    {auth &&
                                                    auth.user &&
                                                    auth.user.pass &&
                                                    auth.user.pass.isActive
                                                        ? strings.pass
                                                              .passActive
                                                        : strings.pass
                                                              .adventurerPass}
                                                </GradientText>
                                            </Box>
                                        )}
                                    </PassBtn>
                                )}
                            </Box>
                            <Box className="separatorEmpty" />
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <JoinBtn
                                    collapsed={sidebarCollapsed}
                                    callback={() => setShowPopUpJoinGame(true)}
                                />
                            </Box>
                            {isNotEmptyString(gameID) &&
                            isNotEmptyString(playerID) ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "15px",
                                    }}>
                                    <ButtonBase
                                        className="currentGameBtn"
                                        sx={styles.currentGameBtn}
                                        onClick={() => handleJoinGame(gameID)}>
                                        <PlayCircleOutlineIcon
                                            className="currentGameText"
                                            sx={styles.currentGameIcon}
                                        />
                                        <Typography
                                            className="currentGameText"
                                            sx={[
                                                styles.currentGameText,
                                                {
                                                    marginLeft: "5px",
                                                },
                                            ]}>
                                            {gameID}
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Menu>
            </ProSidebar>

            <ClassicModal
                modalVisible={gameRequest !== null}
                buttonText={strings.join}
                buttonCancelText={strings.actions.decline}
                onCloseModal={() => acceptInvite(gameRequest)}
                content={renderInviteModal()}
                title={strings.online.invites}
                twoButtons
                onBackdropPress={() => declineInvitation()}
                onCancel={() => declineInvitation()}
            />
            <BigModal
                callback={() => setShowPopUpJoinGame(false)}
                modalVisible={showPopUpJoinGame}
                hasCloseIcon
                title={strings.joinGame}
                contentStyle={{ padding: "0px 0px", ...columnBetweenStyles }}
                headerStyle={{
                    padding: "50px 50px 20px 50px",
                    ...columnStartStyles,
                }}
                modalStyle={{ height: "unset", minHeight: "unset" }}
                content={
                    <Box sx={styles.container}>
                        <Typography sx={styles.joinGameFormSubtitle}>
                            {strings.messages.enterGameID}
                        </Typography>
                        {isJoining ? (
                            <Box style={{ marginTop: "15px" }}>
                                <Loading />
                            </Box>
                        ) : (
                            <>
                                {error ? (
                                    <Typography
                                        sx={styles.joinGameFormErrorText}>
                                        {error}
                                    </Typography>
                                ) : null}
                                <Formik
                                    initialValues={{ code: "" }}
                                    onSubmit={(values) =>
                                        handleJoinGame(values.code)
                                    }
                                    validationSchema={joinGameValidationSchema}>
                                    {({
                                        values,
                                        handleChange,
                                        errors,
                                        setFieldTouched,
                                        touched,
                                        isValid,
                                        handleSubmit,
                                    }) => (
                                        <Box sx={styles.formContainer}>
                                            <Box sx={styles.container}>
                                                <InputBase
                                                    value={values.code}
                                                    onChange={handleChange(
                                                        "code",
                                                    )}
                                                    onBlur={() =>
                                                        setFieldTouched("code")
                                                    }
                                                    placeholder={
                                                        GAME_ONLINE.GAME_ID_PLACEHOLDER
                                                    }
                                                    autoCapitalize="characters"
                                                    autoFocus={
                                                        showPopUpJoinGame
                                                    }
                                                    autoComplete="off"
                                                    sx={[
                                                        styles.myInputView,
                                                        touched.code &&
                                                        errors.code
                                                            ? {
                                                                  borderColor:
                                                                      Colors.ERROR,
                                                                  borderWidth: 2,
                                                                  borderStyle:
                                                                      "solid",
                                                              }
                                                            : {},
                                                    ]}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <IconKey
                                                                width={35}
                                                                height={35}
                                                                fill={
                                                                    Colors.WHITE
                                                                }
                                                            />
                                                            <Typography
                                                                style={
                                                                    styles.joinGameFormInputPlaceholder
                                                                }>
                                                                {"#"}
                                                            </Typography>
                                                        </InputAdornment>
                                                    }
                                                />

                                                {touched.code &&
                                                    errors.code && (
                                                        <Typography
                                                            sx={
                                                                styles.joinGameFormErrorText
                                                            }>
                                                            {handleError(
                                                                errors.code,
                                                                "code",
                                                            )}
                                                        </Typography>
                                                    )}
                                            </Box>
                                            <Box sx={styles.container}>
                                                <Box style={styles.separator} />

                                                <Box
                                                    style={styles.popupButtons}>
                                                    <ButtonBase
                                                        sx={styles.noButton}
                                                        onClick={() =>
                                                            handleHidePopUpJoinGameModal()
                                                        }>
                                                        <Typography
                                                            sx={styles.noText}>
                                                            {strings.back}
                                                        </Typography>
                                                    </ButtonBase>

                                                    <ButtonBase
                                                        sx={styles.yesButton}
                                                        disabled={
                                                            (!isValid &&
                                                                values.code ===
                                                                    "") ||
                                                            disableJoinButton
                                                        }
                                                        onClick={() => {
                                                            SoundManager.getInstance().playBtnSound();
                                                            isValid
                                                                ? handleSubmit()
                                                                : "";
                                                        }}>
                                                        <Typography
                                                            sx={styles.yesText}>
                                                            {strings.join}
                                                        </Typography>
                                                    </ButtonBase>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Formik>
                            </>
                        )}
                    </Box>
                }
            />
            <ConsentBanner isOpen={!cookiesSet} callback={() => null} />
        </Box>
    );
};

const styles = {
    container: {
        ...columnStartStyles,
        width: "100%",
    },
    joinGameFormSubtitle: {
        color: Colors.MENU_TEXT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        textAlign: "center",
        marginTop: "15px",
    },
    formContainer: {
        flex: 1,
        width: "100%",
        height: "100%",
        ...columnBetweenStyles,
    },
    joinGameFormErrorText: {
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
        color: Colors.ERROR,
        marginTop: "15px",
        textAlign: "center",
    },
    myInputView: {
        width: "50%",
        height: 60,
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: 13,
        paddingLeft: "10px",
        flexDirection: "row",
        alignItems: "center",
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
        marginTop: "30px",
    },
    myInput: {
        width: "70%",
        backgroundColor: Colors.BTN_ACTIVE,
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
        marginLeft: 0,
        height: "56px",
        margin: "2px",
    },
    joinGameFormInputPlaceholder: {
        fontFamily: Gilroy,
        color: Colors.WHITE,
        marginLeft: "15px",
    },
    separator: {
        width: "100%",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        marginTop: "25px",
    },
    popupButtons: {
        width: "70%",
        alignSelf: "center",
        ...rowStyles,
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        margin: "25px 0px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    currentGameBtn: {
        ...rowCenterStyles,
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: Colors.FOND_ITEM_POPUP,
        borderRadius: "13px",
        padding: "7px 15px",
    },
    currentGameIcon: {
        fontSize: normalize(22),
        fontFamily: "Gilroy-Bold",
        color: Colors.FOND_ITEM_POPUP,
    },
    currentGameText: {
        fontSize: normalize(15),
        fontFamily: "Gilroy-Bold",
        color: Colors.FOND_ITEM_POPUP,
    },
    gilroy: {
        fontFamily: "Gilroy-Bold",
    },
    iconBox: {
        width: "24px",
        height: "30.5px",
        position: "relative",
    },
    hasNew: {
        position: "absolute",
        top: -5,
        right: -10,
        width: "10px",
        height: "10px",
        borderRadius: "10px",
        backgroundColor: Colors.PROMO,
    },
    passText: {
        fontFamily: Gilroy,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
    },
    modalContent: {
        ...columnStyles,
        alignItems: "center",
        width: "100%",
    },
    modalText: {
        fontSize: normalize(14),
        fontFamily: Gilroy,
        color: Colors.OLD_PRICE,
        textAlign: "center",
        marginTop: normalizeSize(30),
    },
};

export default Sidebar;
