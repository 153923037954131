/* eslint-disable @typescript-eslint/no-explicit-any */
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnCenterStyles, rowCenterStyles, rowStartStyles } from "../../../style/flex";

// =======================================================

const centerStyles: any = {
  alignItems: "center",
  justifyContent: "center",
};

const gameCodeTextStyles: any = {
  fontSize: normalize(16),
  fontFamily: "Gilroy-Bold",
  textAlign: "center",
};

// =======================================================

export default {
  container: {
    flex: 1,
    position: "relative",
    top: 0,
    width: "100%",
    height: "100%",    
  },
  menuBtns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
    paddingTop: '20px',

  },
  scrollContainer: {
    ...rowStartStyles,
    marginTop: "10px",
  },
  insideScroll: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  singleChild: {
    width: "40%",
    padding: "0px 15px",
    margin: "7px 0px",
    borderRadius: "13px",
    ...columnCenterStyles,
  },
  closeBtn: {
   /*  position: "absolute",
    bottom: 0, */
    width: "100%",
    zIndex: 102,
  },
  btn: {
    zIndex: 102,
  },
  separator: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.WHITE,
    alignSelf: "center",
  },
  logbook: {
    display: "flex",
    flexDirection: "row",
    ...centerStyles,
  },
  logbookText: {
    fontSize: normalize(16),
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    marginLeft: "10px",
  },
  obectiveContainer: {
    ...columnCenterStyles,
  },
  objective: {
    justifyContent: "center",
    display: "flex",
    flexDirection: 'column',
    alignItems: "flex-start",
    width: "85%",
    alignSelf: "center",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  objectiveBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
    borderRadius: "13px",
    padding: "12px 10px",
  },
  acteTxt: {
    fontSize: normalize(16),
    color: Colors.ACCENT_BRAQUAGE,
    fontFamily: "Gilroy-Bold",
  },
  infoTxt: {
    width: "calc(80% -90px)",
    marginLeft: "15px",
    fontSize: normalize(14),
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
  },
  mainScroll: {
    width: "100%",
    height: '100%',
    overflowY: 'scroll',
    paddingBottom: '100px',
  },
  overlayBg: {
    backgroundColor: Colors.BLACK,
    opacity: 0.5,
    position: "absolute",
    bottom: 0,
    top: 0,
    width: "100%",
    zIndex: 50,
  },
  onlineGameCodeContainer: {
    ...rowCenterStyles,
    padding: "5px 0px",
  },
  onlineGameCodeTextWrapper: {
    ...gameCodeTextStyles,
    ...rowCenterStyles,
    padding: "15px",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "7px",
  },
  onlineGameCodeText: {
    ...gameCodeTextStyles,
  },
  centeredView: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    backgroundColor: Colors.PRIMARY,
    width: "400px",
    //maxWidth: "80%",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: Colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: "20px",
    overflow: 'hidden',
  },
  popupButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginTop: "25px",
    zIndex: 25,
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "7px 11px",
    borderRadius: "10px",
    width: "100px",
  },
  yeahButton: {
    backgroundColor: Colors.ACCENT,
    padding: "7px 11px",
    borderRadius: "10px",
    width: "120px",
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    padding: "7px 11px",
    borderRadius: "10px",
    width: "100px",
  },
  noText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  separatorBuyEpisode: {
    width: "300px",
    height: 0,
    color: Colors.BTN_ACTIVE,
    borderColor: Colors.BTN_ACTIVE,
    borderWidth: "1px",
    marginTop: "25px",
  },
  quit: {
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
  },
  quitText: {
    color: Colors.ACCENT_BRAQUAGE,
    fontFamily: "Gilroy-Bold",
    fontSize: 20,
  },
};

// =======================================================
