import { Box, ButtonBase, Typography } from "@mui/material";
import { PassCard } from "../../models/passCard";
import { strings } from "../../services/translation";
import GradientBorderBox from "../GradientBorderBox";
import { Colors } from "../../style";
import { rowCenterStyles, rowStartStyles } from "../../style/flex";
import { normalizeSize } from "../../utils/fontSizer";
import GradientText from "../GradientText";
import { styles } from "./styles";
import Separator from "../Separator";
import DynamicTag from "../DynamicTag";
import { Player } from "@lottiefiles/react-lottie-player";
import { itemBackgroundJson } from "../../constants/itemBackground";
import PassAnimated from "../PassAnimated";

//ICONS
import { ReactComponent as IconAdd } from "../../assets/icons/icon_add.svg";
import { PassInfo } from "../../models/passInfo";

export const PassOption = ({
    pass,
    isSelected,
    onClickPass,
}: {
    pass: PassInfo;
    isSelected: boolean;
    onClickPass: (value: PassInfo) => void;
}) => {
    /**
     *
     *
     * RENDER
     *
     *
     */

    /**
     * @renderCards
     * Renders the cards list
     * @param item
     * @param index
     * @returns
     */
    const renderCards = (item: PassCard, indexCard: number) => {
        return (
            <Box sx={styles.optionContainer} key={indexCard}>
                <Box
                    sx={[
                        styles.optionContainerInside,
                        item.text === strings.pass.adventurerPass
                            ? {
                                  ...rowCenterStyles,
                              }
                            : {
                                  ...rowStartStyles,
                              },
                    ]}
                    key={pass.index}>
                    {item.type === "anim" && item.anim ? (
                        <Player
                            src={item.anim}
                            style={styles.lottie2}
                            autoplay={item.text === strings.pass.adventurerPass}
                            loop
                        />
                    ) : item.type === "image" &&
                      item.image &&
                      item.text === strings.pass.avatar ? (
                        <Box sx={styles.item2}>
                            <Player
                                src={itemBackgroundJson}
                                autoplay={true}
                                loop={true}
                                speed={1.5}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    overflow: "hidden",
                                    width: normalizeSize(60),
                                    height: normalizeSize(60),
                                    borderRadius: normalizeSize(60),
                                    zIndex: 1,
                                }}
                            />

                            <img src={item.image} style={styles.img2} />
                        </Box>
                    ) : item.type === "image" && item.image ? (
                        <img
                            src={item.image}
                            style={styles.lottie2}
                            alt="avatar image"
                        />
                    ) : null}
                    {item.text === strings.pass.adventurerPass ? (
                        <Box sx={styles.passIncluded}>
                            <PassAnimated
                                style={{
                                    width: 15,
                                    height: 15,
                                    marginRight: "7px",
                                }}
                            />
                            <GradientText
                                color1={Colors.PASS1}
                                color2={Colors.PASS2}
                                style={styles.subtitle2Pass}>
                                {item.text}
                            </GradientText>
                        </Box>
                    ) : (
                        <Typography sx={styles.subtitle2}>
                            {item.text}
                        </Typography>
                    )}
                </Box>
                {item.text === strings.pass.adventurerPass ? (
                    <Box sx={styles.passAdd}>
                        <IconAdd width={30} height={30} fill={Colors.PRIMARY} />
                    </Box>
                ) : null}
            </Box>
        );
    };

    return (
        <GradientBorderBox
            color1={isSelected ? Colors.PASS1 : "transparent"}
            color2={isSelected ? Colors.PASS2 : "transparent"}
            borderRadius={13}
            style={styles.gradientBox}
            //backgroundColor={Colors.PRIMARY}
            borderWidth={2}>
            <ButtonBase
                onClick={() => onClickPass(pass)}
                sx={styles.gradientBoxBtn}>
                <GradientText
                    color1={Colors.PASS1}
                    color2={Colors.PASS2}
                    style={styles.gradientTextNormal}>
                    {pass.title}
                </GradientText>
                <Typography sx={styles.price}>{pass.price}</Typography>
                <Separator color={Colors.PRIMARY} style={styles.separator} />
                <Box
                    sx={[
                        styles.cardsList,
                        pass.index === 1
                            ? { marginTop: normalizeSize(15) }
                            : {},
                    ]}>
                    {pass.cards &&
                        pass.cards.map((item: PassCard, index: number) =>
                            renderCards(item, index),
                        )}
                </Box>
                <DynamicTag
                    text=""
                    style={styles.valueTag}
                    icon={() => (
                        <GradientText
                            color1={Colors.PASS1}
                            color2={Colors.PASS2}
                            style={styles.gradientTextSmall}>
                            {pass.value}
                        </GradientText>
                    )}
                />
            </ButtonBase>
        </GradientBorderBox>
    );
};
