/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import { isNotEmptyArray } from "../../../utils/ArrayHelper";
import { normalizeSize } from "../../../utils/fontSizer";
import Separator from "../../Separator";
import { FTUEItem } from "../FTUEItem";
import { styles } from "../style";
//import { items } from "./dataRewards";
// ICONS
import { ReactComponent as IconRewards } from "../../../assets/icons/icon_present.svg";
import { rewardItemJson } from "../../../constants/rewardItem";
import { rewardTitleJson } from "../../../constants/rewardTitle";

export const FTUEModalRewards = () => {
    const items = [
        {
            type: "image",
            text: strings.ftue.rewards.level,
            image: require("../../../assets/images/FTUE_LEVEL.png"),
        },
        {
            type: "image",
            text: strings.ftue.rewards.mainQuest,
            image: require("../../../assets/images/FTUE_MAIN_QUEST.png"),
        },
        {
            type: "anim",
            text: strings.ftue.rewards.avatar,
            anim: rewardItemJson,
        },
        {
            type: "anim",
            text: strings.ftue.rewards.titles,
            anim: rewardTitleJson,
        },
        {
            type: "image",
            text: strings.ftue.rewards.parrain,
            image: require("../../../assets/images/FTUE_PARRAIN.png"),
        },
    ];

    const renderItems = () => {
        if (isNotEmptyArray(items)) {
            return items.map((item: any, index: number) => (
                <FTUEItem item={item} key={index} />
            ));
        }
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <IconRewards width={30} height={30} fill={Colors.ACCENT} />
                <Typography sx={[styles.title, { color: Colors.ACCENT }]}>
                    {strings.ftue.rewards.title}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box sx={styles.introContainer}>
                <img
                    src={require("../../../assets/images/icon_peintre.png")}
                    alt="painter"
                    width={normalizeSize(70)}
                    height={normalizeSize(70)}
                />
                <Typography sx={styles.introText}>
                    {strings.ftue.rewards.intro}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box style={styles.contentContainer}>{renderItems()}</Box>
        </Box>
    );
};
