import { Colors } from "../../style";
import { rowStartStyles } from "../../style/flex";
import normalize from "../../utils/fontSizer";

export const styles = {
  container: {
    alignSelf: "flex-start",
    display: "flex",
    width: "300px",
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
  },
  buyAll: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: Colors.PRIMARY,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "13px",
    overflow: "hidden",
  },
  leftPart: {
    width: "86px",
    height: "100%",
    ...rowStartStyles,
    paddingLeft: "12px",
  },
  rightPart: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    paddingLeft: "24px"
  },
  coverContainer: {
    height: "139.5px",
    width: "86px",
    minWidth: "86px",
    minHeight: "139.5px",
  },
  socialTitle: {
    fontSize: normalize(20),
    lineHeight: "22px",
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginBottom: "5px",
    textAlign: "start",
    paddingRight: "2px",
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  storyPriceBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  storyPrice: {
    fontSize: normalize(20),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "7px",
  },
  tokensOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "6px",
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokensAmountOwned: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.TOKEN,
    marginLeft: "3px",
  },
  gradientTextSmall: {
      fontSize: normalize(20),
      fontFamily: 'Gilroy-Bold',
      marginLeft: '5px',
  },
};
