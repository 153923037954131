/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import useAuth from "../../services/Auth/useAuth";
import { useGoogleLogin } from "@react-oauth/google";
import { useLogin } from "react-facebook";
import {
    APPLE_LOGIN_WEB,
    FACEBOOK_LOGIN_WEB,
    GOOGLE_LOGIN_WEB,
    REGISTER_AVAILABLE_WEB,
} from "../../constants/parameters";
import { getEventManager } from "../../services/EventManager";
import { getRouteManager } from "../../services/routeManager";
import {
    strings,
    getLanguage,
    changeLanguage,
} from "../../services/translation";
import { isNotEmptyString } from "../../utils/StringHelper";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import { Box, Modal, useMediaQuery } from "@mui/material";
import LoginBox from "../../pages/User/Login/LoginBox";
import RegisterBox from "../../pages/User/Login/RegisterBox";
import MainBox from "../../pages/User/Login/MainBox";
import CgvBox from "../../pages/User/Login/CgvBox";
import { columnCenterStyles } from "../../style/flex";
import { useNavigate } from "react-router";
import ConfirmedBox from "../../pages/User/Login/ConfirmedBox";
import Loading from "../Loading";
import ErrorModal from "../ErrorModal";
import { normalizeSize } from "../../utils/fontSizer";

const RegisterTemporaryAccountModal = ({
    isOpen,
    callback,
    onCloseCallback = null,
}: {
    isOpen: boolean;
    callback?: any;
    onCloseCallback?: any;
}) => {
    const auth = useAuth();

    const navigate = useNavigate();

    const loginGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async ({ code }: any) => {
            signInSocialCallback(code, "google");
        },
        onError: (error) => handleError(error, "first error"),
    });
    const { login } = useLogin();
    const matchesWidth = useMediaQuery("(min-width:800px)");

    const [error, setError] = useState<any>(null);
    const [loginAppleEnabled, setLoginAppleEnabled] = useState(false);
    const [loginGoogleEnabled, setLoginGoogleEnabled] = useState(false);
    const [loginFbENabled, setLoginFbENabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerEnabled, setRegisterEnabled] = useState(false);
    const [showCgv, setShowCgv] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showConfirmed, setShowConfirmed] = useState(false);

    /**
     *
     * USE EFFECTS
     *
     */
    useEffect(() => {
        onInitCheckSocialLoginEnabled();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     *
     */
    const loginWithGoogle = async () => {
        await signInWithGoogle();
    };

    /**
     * @onInitCheckSocialLoginEnabled
     * Check if social login is enabled
     */
    const onInitCheckSocialLoginEnabled = async () => {
        const loginFb = await checkLoginEnabled("facebook");
        const loginGoogle = await checkLoginEnabled("google");
        const loginApple = await checkLoginEnabled("apple");
        if (loginFb && isTrueBoolean(loginFb.value)) {
            setLoginFbENabled(loginFb.value);
        }
        if (loginGoogle && isTrueBoolean(loginGoogle.value)) {
            setLoginGoogleEnabled(loginGoogle.value);
        }
        if (loginApple && isTrueBoolean(loginApple.value)) {
            setLoginAppleEnabled(loginApple.value);
        }
    };

    const checkLoginEnabled = async (type: string): Promise<any> => {
        const paramRegister = getRouteManager().getParamForType(
            REGISTER_AVAILABLE_WEB,
        );
        const registerAvailable =
            await getRouteManager().postCheckParamValueNew(paramRegister);
        if (registerAvailable && isTrueBoolean(registerAvailable.value)) {
            setRegisterEnabled(true);
            if (type === "facebook") {
                const param =
                    getRouteManager().getParamForType(FACEBOOK_LOGIN_WEB);
                return await getRouteManager().postCheckParamValueNew(param);
            } else if (type === "google") {
                const param =
                    getRouteManager().getParamForType(GOOGLE_LOGIN_WEB);
                return await getRouteManager().postCheckParamValueNew(param);
            } else if (type === "apple") {
                const param =
                    getRouteManager().getParamForType(APPLE_LOGIN_WEB);
                return await getRouteManager().postCheckParamValueNew(param);
            }
        } else {
            setShowLogin(true);
            setRegisterEnabled(false);
        }
    };

    const signInWithGoogle = async () => {
        try {
            setLoading(true);
            loginGoogle();
        } catch (error: any) {
            console.log(error);
        }
    };

    const loginWithFacebook = async () => {
        try {
            const response = await login({
                scope: "public_profile,email",
            });
            setLoading(true);
            if (
                response &&
                response.status &&
                response.status === "connected" &&
                response.authResponse &&
                response.authResponse.accessToken
            ) {
                await signInSocialCallback(
                    response.authResponse.accessToken,
                    "facebook",
                );
            } else {
                handleError(null, strings.anErrorOccured);
            }
            setLoading(false);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleError = (error: any, errorInfo?: any) => {
        if (errorInfo) {
            setError(errorInfo);
        } else {
            switch (error) {
                case "error":
                    setError(error);
                    break;
                case "ERROR_NO_EMAIL_FOUND":
                    setError(strings.errorGoogle);
                    break;
                case "ERROR_NO_GUEST_FOUND":
                    setError(strings.user.errorNoGuestFound);
                    break;
                case "CANCELED":
                    break;
                default:
                    setError(error ? error : strings.errorGoogle);
                    break;
            }
        }
        setLoading(false);
    };

    const signInSocialCallback = async (code: any, type: string) => {
        try {
            if (code && isNotEmptyString(type)) {
                setLoading(true);
                let res = null;
                if (auth && auth.user && auth.user.login_token) {
                    const api =
                        getRouteManager().finalizeRegisterTemporaryWithSocials();
                    res = await getRouteManager().fetchData(api, {
                        code: code,
                        lang: getLanguage(),
                        type: type,
                        login_token: auth.user.login_token,
                    });
                }
                if (res) {
                    if (res.flashmessage !== "error") {
                        getRouteManager().setUser(res);
                        if (res && res?.lang && res?.lang !== getLanguage()) {
                            changeLanguage(res.lang);
                        }
                        auth.signin(res, () => {
                            getEventManager().logLogin();
                            setLoading(false);
                            setShowCgv(true);
                        });
                        return res;
                    } else {
                        handleError(res.flashmessage, res.errInfo);
                    }
                } else {
                    handleError("ERROR_NO_GUEST_FOUND");
                }
            } else {
                handleError("ERROR_NO_EMAIL_FOUND");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const redirectToLibrary = () => {
        navigate("/", { replace: true });
    };

    const cgvCallback = async (values: any) => {
        // TODO: Add referal with values.referral
        if (auth?.user?.login_token) {
            if (
                values &&
                values?.referral &&
                isNotEmptyString(values.referral)
            ) {
                const api = getRouteManager().addReferral();
                const data = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    referralId: values?.referral,
                });
                if (data?.flashmessage === "error") {
                    alert(
                        `${strings.referral.errorAddingReferral}: ${
                            data?.errInfo ? data.errInfo : ""
                        }`,
                    );
                }
            }
        }
        setShowCgv(false);
        setShowConfirmed(true);
    };

    /**
     *
     * RENDER
     *
     */

    return (
        <Box>
            <Modal
                open={isOpen}
                sx={{ outline: "none" }}
                onClose={() =>
                    onCloseCallback ? onCloseCallback() : redirectToLibrary()
                }>
                <Box
                    sx={[
                        styles.container,
                        matchesWidth ? { width: "50%" } : { width: "100%" },
                    ]}>
                    {loading ? (
                        <Loading />
                    ) : showLogin ? (
                        <LoginBox
                            callback={() => {
                                if (registerEnabled) {
                                    setShowLogin(false);
                                }
                            }}
                            ftueCallback={() => {
                                setShowConfirmed(true);
                            }}
                            isTemporaryModal={true}
                        />
                    ) : showRegister ? (
                        <RegisterBox
                            callback={() => {
                                setShowRegister(false);
                            }}
                            finishCallback={() => {
                                setShowRegister(false);
                                setShowConfirmed(true);
                            }}
                            isTemporaryModal={true}
                        />
                    ) : !showLogin && !showCgv && !showConfirmed ? (
                        <MainBox
                            loginAppleEnabled={loginAppleEnabled}
                            loginFbENabled={loginFbENabled}
                            loginGoogleEnabled={loginGoogleEnabled}
                            loginWithGoogle={loginWithGoogle}
                            loginWithFacebook={loginWithFacebook}
                            setShowLogin={setShowLogin}
                            setShowRegister={setShowRegister}
                            signInSocialCallback={signInSocialCallback}
                            isBeta={false}
                            isTemporaryModal={true}
                        />
                    ) : !showLogin && showCgv ? (
                        <CgvBox
                            callback={cgvCallback}
                            isTemporaryModal={true}
                        />
                    ) : showConfirmed ? (
                        <ConfirmedBox
                            callback={() => {
                                callback();
                            }}
                            isTemporaryModal={true}
                        />
                    ) : null}
                </Box>
            </Modal>
            <ErrorModal error={error} callback={() => setError(null)} />
        </Box>
    );
};

const styles = {
    container: {
        ...columnCenterStyles,
        maxWidth: normalizeSize(675),
    },
};

export default RegisterTemporaryAccountModal;
