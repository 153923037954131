/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  ButtonBase,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../utils/ContextsHelper";
import { useNavigate } from "react-router-dom";
import useAuth from "../../services/Auth/useAuth";
import {
  columnCenterStyles,
  rowStartStyles,
  columnStartStyles,
  rowEvenlyStyles,
  rowStyles,
  rowCenterStyles,
} from "../../style/flex";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";
import Loading from "../../components/Loading";
import { getLanguage, strings } from "../../services/translation";
import { progressBarJson } from "../../constants/progressBar";
import { Player } from "@lottiefiles/react-lottie-player";
import { Mission, MissionReward } from "../../models/mission";
import MissionBtn from "../../components/MissionBtn";
import { getRouteManager } from "../../services/routeManager";
import BackgroundRow from "../../components/BackgroundRow";
import RewardBtn from "../../components/RewardBtn";
import Separator from "../../components/Separator";
import normalize from "../../utils/fontSizer";
import SoundManager from "../../services/SoundManager";
import { ReactComponent as IconXp } from "../../assets/icons/icon_xp.svg";

const MissionScreen = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { getMissions } = useContext(NotificationContext);

  const [progressBar, setProgressBar] = useState<any>("0%");
  const [isLoaded, setIsLoaded] = useState(false);
  const [missions, setMissions] = useState<Array<Mission>>([]);
  const [reward, setReward] = useState<MissionReward | null>(null);
  const [progress, setProgress] = useState<Array<Mission>>();
  const [success, setSuccess] = useState<Array<Mission>>();
  const [social, setSocial] = useState<Array<Mission>>();
  const [done, setDone] = useState<Array<Mission>>();
  const [weekly, setWeekly] = useState<Array<Mission>>();
  const [sortDone, setSortDone] = useState(false);
  const [activeMission, setActiveMission] = useState<Mission | null>();
  const [showAlreadyClaimed, setShowAlreadyClaimed] = useState(false);
  const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

  const matches = useMediaQuery(`(min-width:${1148}px)`);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (auth?.user) {
      const val =
        (auth.user.experience - auth.user.currentLevel.experience) /
        (auth.user.nextLevel.experience - auth.user.currentLevel.experience);

      setProgressBar(val > 0 ? val * 100 + "%" : "0%");
    }
  }, [auth, auth.user.experience, auth.user.level]);

  const initData = async (missionsList: Array<Mission> | null = null) => {
    try {
      let mission: Array<Mission> | null = null;
      if (missionsList && missionsList.length) {
        mission = [...missionsList];
      } else {
        mission = await getMissions();
      }
      if (mission) {
        updateMissions(mission);
      }
      if (auth && auth?.user) {
        const val =
          (auth.user.experience - auth.user.currentLevel.experience) /
          (auth.user.nextLevel.experience - auth.user.currentLevel.experience);

        setProgressBar(val > 0 ? val * 100 + "%" : "0%");
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  const byValue = (a: any, b: any) => a.displayOrder - b.displayOrder;

  const updateMissions = (mission: Array<Mission> | null = null) => {
    const pro: Array<Mission> = [];
    const suc: Array<Mission> = [];
    const soc: Array<Mission> = [];
    const don: Array<Mission> = [];
    const week: Array<Mission> = [];
    const list =
      mission && mission.length
        ? mission
        : missions && missions.length
          ? missions
          : [];
    list.map((m) => {
      if (m.type !== "weekly") {
        if (
          m.rewardReclaimed &&
          m.unlocked &&
          m.displayOrder &&
          m.displayOrder < 1000
        ) {
          m.displayOrder += 1000;
          don.push(m);
        } else if (m.rewardReclaimed && m.unlocked) {
          don.push(m);
        } else if (!m.rewardReclaimed && m.unlocked && m.displayOrder) {
          m.displayOrder -= 1000;
        }
        switch (m.category) {
          case "progress":
            if (!don.includes(m)) {
              pro.push(m);
            }
            break;
          case "success":
            if (!don.includes(m)) {
              suc.push(m);
            }
            break;
          case "social":
            if (!don.includes(m)) {
              soc.push(m);
            }
            break;
          default:
            if (!don.includes(m)) {
              pro.push(m);
            }
            break;
        }
      } else {
        week.push(m);
      }
    });
    missions.sort(byValue);
    pro.sort(byValue);
    suc.sort(byValue);
    soc.sort(byValue);
    don.sort(byValue);
    week.sort(byValue);
    setProgress(pro);
    setSuccess(suc);
    setSocial(soc);
    setDone(don);
    setWeekly(week);
    setSortDone(true);
    setMissions(list);
  };

  const checkAndGetReward = async (mission: any) => {
    try {
      setIsLoaded(false);
      setActiveMission(mission);
      if (
        !mission.rewardReclaimed &&
        (mission.unlocked || mission.nbDone === mission.number)
      ) {
        const token = getRouteManager().getUser().getLoginToken();
        if (token) {
          const apiMissions = getRouteManager().reclaimReward();
          const json = await getRouteManager().fetchData(`${apiMissions}`, {
            login_token: token,
            missionId: mission.id,
            lang: getLanguage(),
          });
          if (json.flashmessage === "error-already-claimed") {
            setIsLoaded(true);
            setShowAlreadyClaimed(true);
          } else if (json.flashmessage !== "error") {
            json.map((m: any) => {
              if (m.rewardReclaimed && m.unlocked) {
                m.displayOrder += 1000;
              }
              if (!m.rewardReclaimed && m.unlocked) {
                m.displayOrder -= 1000;
              }
            });
            if (mission.reward) {
              setReward(mission.reward);
            }

            const updatedList = json.filter((m: any) => m.toShow === true);
            updatedList.sort(byValue);
            await getUserInfos();
            await initData(updatedList);
            //await getMissions();
            updateMissions(updatedList);
            setIsLoaded(true);
            if (mission.reward) {
              if (mission.reward.type === "item") {
                SoundManager.getInstance().playUnlockItemSound();
              } else if (mission.reward.type === "tickets") {
                SoundManager.getInstance().playUnlockTicketsSound();
              } else if (mission.reward.type === "tokens") {
                SoundManager.getInstance().playUnlockTicketsSound();
              } else {
                SoundManager.getInstance().playUnlockStorySound();
              }
            }

            setShowPopUpCongrats(true);
          }
        }
      } else {
        setActiveMission(null);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const getUserInfos = async () => {
    try {
      const json = await getRouteManager().fetchGetUserInfos(auth.user);
      if (json.flashmessage === "error") {
        console.log("no user found");
        return null;
      } else {
        auth.setUser(json);
        getRouteManager().setUser(json);
        return json;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const goNext = (storyId: any) => {
    navigate(storyId ? `/${storyId}` : "/");
  };

  const closeModal = () => {
    setShowPopUpCongrats(false);
    setReward(null);
    setActiveMission(null);
  };

  if (!isLoaded) {
    return (
      <Box sx={{ width: "100%", height: "100%", ...columnCenterStyles }}>
        <Loading />
      </Box>
    );
  }

  return (
    <BackgroundRow
      hasBackground={false}
      style={{ height: "100%" }}
      bodyStyle={{
        paddingTop: "0px",
        height: "fit-content",
        minHeight: "unset",
        justifyContent: "center",
      }}
      matchesValue={1148}
      leftPartStyle={
        !matches
          ? { width: "100%", maxWidth: "unset", alignItems: "center" }
          : {}
      }
      //animType="normal"
      leftPart={
        <Box sx={matches ? styles.container : styles.containerSmall}>
          <Typography
            sx={[styles.topMenuTitle, !matches && { textAlign: "center" }]}
          >
            {strings.quests.quests}
          </Typography>
          <Typography
            sx={[
              styles.subtitle,
              !matches && {
                justifyContent: "center",
                marginBottom: "10px",
                textAlign: "center",
              },
            ]}
          >
            {strings.quests.questsSubtitle}
          </Typography>
          <Box
            sx={[
              {
                ...rowStartStyles,
                width: "100%",
                flex: 1,
                marginBottom: "10px",
              },
              !matches && { justifyContent: "center" },
            ]}
          >
            <Box style={styles.levelCircle}>
              <Typography style={styles.levelText}>
                {auth.user?.level ? auth.user.level : "?"}
              </Typography>
            </Box>
            <Box sx={styles.progressBar}>
              <Box sx={[styles.progressBarActive, { width: progressBar }]}>
                <Player
                  src={progressBarJson}
                  autoplay
                  loop
                  speed={2}
                  style={{ display: "flex", width: "240px", height: "15px" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      }
      rightPartStyle={{
        height: "90vh",
        maxWidth: "406px",
      }}
      rightPart={
        <Box
          sx={[
            styles.container,
            !matches && { alignSelf: "center", paddingTop: "2vh" },
          ]}
        >
          {(progress && progress.length) ||
            (success && success.length) ||
            (social && social.length) ||
            (weekly && weekly.length) ||
            (done && done.length) ? (
            <Box sx={styles.missionsContainer}>
              {weekly && weekly.length > 0 && (
                <Box sx={styles.missionsContainer}>
                  <Typography style={styles.missionTitle}>
                    {strings.quests.weekly}
                  </Typography>

                  {weekly &&
                    weekly.map((item, index) => (
                      <MissionBtn
                        mission={item}
                        index={index}
                        missions={missions}
                        checkAndGetReward={checkAndGetReward}
                        //goToSocial={goToSocial}
                        //library={library}
                        goNext={goNext}
                        goToShopStory={() => navigate("/")}
                        key={"item-" + item.type + "-" + index}
                      />
                    ))}
                </Box>
              )}
              {progress && progress.length > 0 && (
                <Box sx={styles.missionsContainer}>
                  <Typography style={styles.missionTitle}>
                    {strings.progress}
                  </Typography>

                  {progress &&
                    progress.map((item, index) => (
                      <MissionBtn
                        mission={item}
                        index={index}
                        missions={missions}
                        checkAndGetReward={checkAndGetReward}
                        //goToSocial={goToSocial}
                        //library={library}
                        goNext={goNext}
                        goToShopStory={() => navigate("/")}
                        key={"item-" + item.type + "-" + index}
                      />
                    ))}
                </Box>
              )}
              {success && success.length > 0 && (
                <Box sx={styles.missionsContainer}>
                  <Typography style={styles.missionTitle}>
                    {strings.success}
                  </Typography>

                  {success &&
                    success.map((item, index) => (
                      <MissionBtn
                        mission={item}
                        index={index}
                        missions={missions}
                        checkAndGetReward={checkAndGetReward}
                        //goToSocial={goToSocial}
                        //library={library}
                        goNext={goNext}
                        goToShopStory={() => navigate("/")}
                        key={"item-" + item.type + "-" + index}
                      />
                    ))}
                </Box>
              )}
              {social && social.length > 0 && (
                <Box sx={styles.missionsContainer}>
                  <Typography style={styles.missionTitle}>
                    {strings.social}
                  </Typography>

                  {social &&
                    social.map((item, index) => (
                      <MissionBtn
                        mission={item}
                        index={index}
                        missions={missions}
                        checkAndGetReward={checkAndGetReward}
                        //goToSocial={goToSocial}
                        //library={library}
                        goNext={goNext}
                        goToShopStory={() => navigate("/")}
                        key={"item-" + item.type + "-" + index}
                      />
                    ))}
                </Box>
              )}
              {done && done.length > 0 && (
                <Box sx={styles.missionsContainer}>
                  <Typography style={styles.missionTitle}>
                    {strings.missionDone}
                  </Typography>

                  {done &&
                    done.map((item, index) => (
                      <MissionBtn
                        mission={item}
                        index={index}
                        missions={missions}
                        checkAndGetReward={checkAndGetReward}
                        //goToSocial={goToSocial}
                        //library={library}
                        goNext={goNext}
                        goToShopStory={() => navigate("/")}
                        key={"item-" + item.type + "-" + index}
                      />
                    ))}
                </Box>
              )}
              <Box sx={{ height: "100px" }} />
            </Box>
          ) : (
            <Loading />
          )}
          {activeMission && (reward || activeMission?.experience) && showPopUpCongrats ? (
            <Modal open={showPopUpCongrats} onClose={() => closeModal()}>
              <Box sx={styles.modalView}>
                <Typography sx={styles.congrats}>{strings.congrats}</Typography>
                {reward ? (
                  <Box sx={styles.rewardsList}>
                    <RewardBtn reward={reward} isModal level={activeMission} />
                  </Box>
                ) : null}

                {activeMission?.experience ? (
                  <Box
                    sx={{
                      ...rowCenterStyles,
                      marginTop: '19px',
                    }}>
                    <IconXp width={50} height={50} />

                    <Typography sx={[styles.congrats3]}>
                      {`+${activeMission?.experience}`}
                    </Typography>
                  </Box>
                ) : null}
                <Typography sx={styles.congrats2}>
                  {strings.rewardReceived}
                </Typography>

                <Separator style={{ margin: "25px 0px" }} />

                <ButtonBase sx={styles.yesButton} onClick={() => closeModal()}>
                  <Typography sx={styles.yesText}>{strings.great}</Typography>
                </ButtonBase>
              </Box>
            </Modal>
          ) : null}
        </Box>
      }
    />
  );
};

const styles = {
  currencies: {
    position: "fixed",
    top: 45,
  },
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    paddingTop: "4vh",
    paddingBottom: "50px",
  },
  container2: {
    ...columnStartStyles,
    gap: "5px",
    paddingTop: "4vh",
  },
  containerSmall: {
    ...columnStartStyles,
    alignItems: "center",
    gap: "5px",
    paddingTop: "4vh",
  },
  topMenuTitle: {
    width: "100%",
    fontSize: "50px",
    fontFamily: "Eczar-Regular",
    fontWeight: 400,
    color: Colors.WHITE,
    //marginBottom: "2vh",
  },
  subtitle: {
    gap: "10px",
    ...rowStyles,
    alignItems: "center",
    width: "100%",
    fontSize: "20px",
    lineHeight: "20px",
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
    marginBottom: "50px",
  },
  levelPart: {
    ...rowStartStyles,
    width: "100%",
    flex: 1,
    marginBottom: "10px",
  },
  levelCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "40px",
    border: `solid 2px ${Colors.ACCENT}`,
    ...columnCenterStyles,
    backgroundColor: Colors.BTN_ACTIVE,
    zIndex: 10,
  },
  levelText: {
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "Gilroy-Bold",
    color: Colors.ACCENT,
  },
  progressBar: {
    ...rowStartStyles,
    width: "240px",
    height: "15px",
    borderRadius: "15px",
    backgroundColor: "rgba(121, 112, 255, 0.3)",
    marginLeft: "-5px",
    zIndex: 5,
  },
  progressBarActive: {
    ...rowStartStyles,
    width: "0%",
    height: "100%",
    borderRadius: "15px",
    background: `linear-gradient(270deg, ${Colors.HEART} 0%, ${Colors.ACCENT} 100%)`,
    overflow: "hidden",
    zIndex: 6,
  },
  missionsContainer: {
    ...columnStartStyles,
    alignItems: "flex-start",
  },
  missionTitle: {
    fontSize: "20px",
    lineHeight: "20px",
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    marginBottom: "10px",
  },
  modalView: {
    width: "400px",
    borderRadius: "20px",
    backgroundColor: Colors.PRIMARY,
    position: "relative",
    padding: "25px 0px",
    ...columnCenterStyles,
    zIndex: 50,
  },
  congrats: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(20),
    marginBottom: "20px",
    textAlign: "center",
    marginTop: "10px",
    padding: "0px 25px",
  },
  congrats2: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    textAlign: "center",
    marginTop: "10px",
  },
  congrats3: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    textAlign: "center",
  },
  rewardsList: {
    ...rowEvenlyStyles,
    gap: "20px",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
};
export default MissionScreen;
