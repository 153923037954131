import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./assets/fonts/Gilroy-Bold.ttf";
import "./assets/fonts/Eczar-Regular.ttf";
import "./assets/fonts/Eczar-SemiBold.ttf";
import "./index.css";

//const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <App />
  </Router>
);
//render(rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
