import { Box, ButtonBase } from "@mui/material";
import { Friend } from "../../models/friend";
import FriendBox from "../FriendScreen/FriendBox";
import { rowWrapStyles } from "../../style/flex";

const FriendList = ({
    friends,
    setActiveFriend,
    setActiveFriendIndex = null,
    selectedFriends = null,
}: {
    friends: any;
    setActiveFriend: any;
    setActiveFriendIndex?: any;
    selectedFriends?: Friend[] | null;
}) => {
    return (
        <Box sx={styles.friendsList}>
            {friends &&
                friends.length &&
                friends.map((friend: Friend, index: number) => (
                    <Box key={"friend-" + index}>
                        <FriendBox
                            friend={friend}
                            callback={() => null}
                            isSelected={
                                selectedFriends &&
                                selectedFriends.includes(friend)
                            }
                            addFriend={() => {
                                setActiveFriend(friend);
                                setActiveFriendIndex &&
                                    setActiveFriendIndex(index);
                            }}
                        />
                    </Box>
                ))}
        </Box>
    );
};

const styles = {
    friendsList: {
        //...columnStyles,
        ...rowWrapStyles,
        width: "90%",
        maxWidth: "1070px",
        alignSelf: "center",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
    },
};

export default FriendList;
