import { Colors } from "../../../style";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
} from "../../../style/flex";
import { Gilroy } from "../../../style/fonts";
import normalize, { normalizeSize } from "../../../utils/fontSizer";

export const styles = {
    container: {
        width: "100%",
        ...columnCenterStyles,
    },
    mainContainer: {
        ...rowCenterStyles,
        flex: 1,
        width: "100%",
        height: "100vh",
        transition: "all 0.3s ease-in-out",
        backgroundColor: Colors.PRIMARY,
        backgroundImage: `linear-gradient(225deg, ${Colors.PROGRESSFOND2} 0%, ${Colors.PRIMARY} 100%)`,
    },
    insideContainer: {
        width: "100%",
        height: "100%",
        maxWidth: "1200px",
        minWidth: "400px",
        ...rowCenterStyles,
        boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        borderRadius: "20px",
        overflow: "hidden",
    },
    loginContainer: {
        position: "relative",
        ...columnCenterStyles,
        height: "100%",
        width: "50%",
        minWidth: "400px",
        backgroundColor: Colors.PROGRESSFONDDARK,
        backgroundImage: `linear-gradient(225deg, ${Colors.PRIMARY} 0%, ${Colors.PROGRESSFONDDARK} 100%)`,
        transition: "all 0.3s ease-in-out",
        padding: "calc(35px + 1vh) 20px calc(35px + 1vh) 20px",
        backdropFilter: "blur(15px)",
        webkitBackdropFilter: "blur(15px)",
    },
    title: {
        fontFamily: Gilroy,
        fontSize: "calc(20px + 0.5vh)",
        padding: "calc(15px + 0.8vh) calc(10px + 4vh)",
        color: Colors.WHITE,
        textAlign: "center",
    },
    title2: {
        fontFamily: Gilroy,
        fontSize: "calc(20px + 0.4vh)",
        padding: "calc(15px + 0.8vh) calc(10px + 4vh)",
        color: Colors.WHITE,
        textAlign: "center",
    },
    subtitle: {
        fontFamily: Gilroy,
        color: Colors.OLD_PRICE,
        fontSize: "calc(14px + 0.3vh)",
        marginBottom: "calc(25px + 2vh)",
        padding: "0px calc(10px + 1vh)",
        textAlign: "center",
    },
    separatorLoginContainer: {
        width: "100%",
        ...rowCenterStyles,
        margin: "20px 0px",
    },
    separatorLogin: {
        width: "7%",
        height: "2px",
        backgroundColor: Colors.FOND_ITEM_POPUP,
        marginTop: "0x",
    },
    separatorLoginText: {
        fontFamily: "Gilroy-Bold",
        color: Colors.FOND_ITEM_POPUP,
        textAlign: "center",
        fontSize: normalize(16),
        padding: "0px 4px",
    },
    socials: {
        ...rowCenterStyles,
        columnGap: "20px",
    },
    loginSocials: {
        backgroundColor: "#fff",
        borderRadius: "10px",
        width: "55px",
        height: "55px",
        overflow: "hidden",
        ...rowCenterStyles,
    },
    switchAccount: {
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        fontSize: normalize(14),
        margin: "calc(20px + 2.2vh) 0px 0px 0px",
    },
    inputContainer: { width: "70%", ...columnCenterStyles },
    input: {
        width: "100%",
        height: "70px",
        backgroundColor: Colors.PROGRESS,
        alignSelf: "center",
        borderRadius: "13px",
        paddingLeft: "10px",
        flexDirection: "row",
        alignItems: "center",
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
    },
    label: {
        marginLeft: "2px",
        marginBottom: "5px",
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
        alignSelf: "flex-start",
    },
    forgotButton: {
        marginTop: "15px",
        marginBottom: "15px",
        alignSelf: "center",
    },
    forgotText: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
    },
    modalContent: {
        ...columnStyles,
        alignItems: "center",
        width: "100%",
    },
    modalText: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
        marginTop: "10px",
    },
    modalViewError: {
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        padding: "37px 0px 20px 0px",
        width: "400px",
        ...columnStartStyles,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    separatorError: {
        width: "100%",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        marginBottom: "20px",
        marginTop: "10px",
    },
    textModalError: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        width: "80%",
        textAlign: "center",
        marginBottom: "15px",
    },
    deleteStoryText: {
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        color: Colors.ACCENT,
    },
    textModal: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        width: "80%",
        textAlign: "center",
        marginBottom: "15px",
    },
    popupButtons: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    commonText: {
        fontFamily: "Gilroy-Bold",
        fontSize: "calc(12px + 0.3vh)",
    },
    notReceivedBtn: {
        width: "100%",
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "13px",
        height: "60px",
        ...columnStyles,
        justifyContent: "center",
        padding: "0px 15px",
        marginBottom: "15px",
    },
    notReceivedBtnText: {
        textAlign: "center",
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: "12px",
    },
    cgvText: {
        fontSize: "calc(12px + 0.3vh)",
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
    },
    error: {
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(12),
        color: Colors.ERROR,
        marginTop: "15px",
        textAlign: "center",
    },
    submitBtn: {
        width: "100%",
        ...rowCenterStyles,
        marginTop: "calc(25px + 2vh)",
    },
    nextBtn: {
        marginTop: "20px",
        borderRadius: "48px",
    },
    choices: {
        marginTop: "20px",
        width: "100%",
        ...columnStyles,
        alignItems: "center",
    },
    modalView: {
        width: "300px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        paddingTop: "35px",
        paddingBottom: "25px",
        ...columnStyles,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    separator: {
        width: "300px",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        margin: "25px 0px",
    },
    rewardTitleName: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.ACCENT,
        marginLeft: "7px",
    },
    storyPriceBlockPopup: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "8px",
    },
    yeahButton: {
        backgroundColor: Colors.ACCENT,
        ...columnCenterStyles,
        borderRadius: "10px",
        width: "120px",
        height: "40px",
        textAlign: "center",
    },
    progress: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(20),
        marginTop: "10px",
    },
    registerNowBtn: {
        marginTop: "calc(15px + 1.5vh)",
    },
    registerNowText: {
        fontFamily: Gilroy,
        color: Colors.WHITE,
        fontSize: normalizeSize(16),
        textDecoration: "underline",
    },
    snackBar: {
        borderRadius: "10px",
        backgroundColor: Colors.BTN_ACTIVE,
        width: "280px",
        padding: "15px 0px",
        ...columnCenterStyles,
    },
    snackBarText: {
        color: Colors.ERROR,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
    },
    noCodeText: {
        fontFamily: Gilroy,
        color: Colors.WHITE,
        fontSize: normalize(16),
        textDecoration: "underline",
    },
};
