/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Fade, Typography, useMediaQuery } from "@mui/material";
import {
    columnStartStyles,
    rowCenterStyles,
    rowStartStyles,
} from "../../style/flex";
import { Colors } from "../../style";
import { Eczar, Gilroy } from "../../style/fonts";
import GoBackBtn from "../GoBackBtn";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import LoadingView from "../LoadingView";
import { Player } from "@lottiefiles/react-lottie-player";
import { testJson } from "../../constants/test";
import UserCurrencies from "../UserCurrencies";
import useAuth from "../../services/Auth/useAuth";

interface Props {
    animType?: string | undefined;
    backgroundImage?: string | undefined;
    globalPart?: any;
    globalPartStyle?: any;
    bodyStyle?: any;
    goBack?: boolean | undefined;
    goBackAction?: any | undefined;
    goBackStyle?: any;
    hasBackground?: boolean | undefined;
    leftPart?: any;
    leftPartStyle?: any;
    loading?: boolean | undefined;
    rightPart?: any;
    rightPartStyle?: any;
    style?: any;
    subtitle?: string | undefined;
    title?: string | undefined;
    matchesValue?: number | undefined;
    hasCurrencies?: boolean | undefined;
    currenciesCallback?: any;
}

const BackgroundRow = (props: Props) => {
    const {
        animType,
        backgroundImage,
        globalPart = null,
        globalPartStyle,
        bodyStyle,
        goBack,
        goBackAction,
        goBackStyle,
        hasBackground = true,
        hasCurrencies = false,
        currenciesCallback,
        leftPart,
        leftPartStyle,
        loading,
        rightPart,
        rightPartStyle,
        style,
        subtitle,
        title,
        matchesValue = 1032,
    } = props;

    const matches = useMediaQuery(`(min-width:${matchesValue}px)`);

    const auth = useAuth();

    if (isTrueBoolean(loading)) {
        return (
            <LoadingView
                style={{
                    position: "relative",
                    ...rowCenterStyles,
                    width: "100%",
                    height: "100vh",
                    overflow: "auto",
                    backgroundColor: Colors.PRIMARY,
                    scrollbarWidth: "none",
                    "::-webkit-scrollbar": {
                        display: "none",
                    },

                    background: hasBackground
                        ? `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%), url(${
                              backgroundImage
                                  ? backgroundImage
                                  : require("../../assets/images/background.jpg")
                          }) 50%/cover`
                        : "",
                }}
            />
        );
    } else {
        return (
            <Fade in={!loading} appear timeout={800}>
                <Box
                    sx={[
                        styles.all,
                        hasBackground
                            ? {
                                  background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%),
              url(${
                  backgroundImage
                      ? backgroundImage
                      : require("../../assets/images/background.jpg")
              }) 50%/cover`,
                              }
                            : {},
                        style ? style : {},
                    ]}>
                    {animType ? (
                        <Box sx={styles.animBox}>
                            {animType === "big" ? (
                                <Player
                                    src={testJson}
                                    autoplay={true}
                                    loop={true}
                                    speed={1}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        width: "100%",
                                    }}
                                />
                            ) : (
                                <Player
                                    src={testJson}
                                    autoplay={true}
                                    loop={true}
                                    speed={1}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            )}
                        </Box>
                    ) : null}

                    <Box sx={[styles.body, bodyStyle ? bodyStyle : {}]}>
                        <Box
                            sx={[
                                styles.bodyLeft,
                                leftPartStyle ? leftPartStyle : {},
                            ]}>
                            {goBack || hasCurrencies ? (
                                <Box
                                    sx={{
                                        ...rowStartStyles,
                                        position: matches
                                            ? "fixed"
                                            : "relative",
                                        top: matches ? 37 : 0,
                                    }}>
                                    {goBack && goBackAction ? (
                                        <GoBackBtn
                                            callback={goBackAction}
                                            style={{}}
                                            extraStyle={
                                                goBackStyle ? goBackStyle : null
                                            }
                                        />
                                    ) : null}
                                    {hasCurrencies ? (
                                        <UserCurrencies
                                            user={
                                                auth?.user
                                                    ? auth.user
                                                    : undefined
                                            }
                                            goToShop={
                                                currenciesCallback
                                                    ? currenciesCallback
                                                    : null
                                            }
                                            style={{
                                                position: "relative",
                                                left: 15,
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            ) : null}

                            {title ? (
                                <Typography style={styles.title}>
                                    {title}
                                </Typography>
                            ) : null}
                            {subtitle ? (
                                <Typography style={styles.subtitle}>
                                    {subtitle}
                                </Typography>
                            ) : null}
                            {leftPart ? leftPart : null}
                        </Box>
                        <Box
                            sx={[
                                styles.bodyRight,
                                rightPartStyle ? rightPartStyle : {},
                                matches
                                    ? {
                                          overflow: "auto",
                                          scrollbarWidth: "none",
                                          "::-webkit-scrollbar": {
                                              display: "none",
                                          },
                                          scrollBehavior: "smooth",
                                      }
                                    : {},
                            ]}>
                            {rightPart ? rightPart : null}
                        </Box>
                    </Box>
                    {globalPart ? (
                        <Box
                            sx={[
                                styles.globalPart,
                                globalPartStyle ? globalPartStyle : {},
                            ]}>
                            {globalPart ? globalPart : null}
                        </Box>
                    ) : null}
                </Box>
            </Fade>
        );
    }
};

const styles = {
    all: {
        position: "relative",
        //...rowCenterStyles,
        ...columnStartStyles,
        width: "100%",
        height: "100vh",
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    },
    loading: {
        position: "relative",
        ...rowCenterStyles,
        width: "100%",
        height: "100vh",
        overflow: "auto",
        backgroundColor: Colors.PRIMARY,
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    },
    body: {
        position: "relative",
        display: "flex",
        width: "90%",
        minHeight: "100vh",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        columnGap: "15px",
        paddingTop: "20.79vh",
    },
    bodyLeft: {
        display: "flex",
        width: "40%",
        maxWidth: "500px",
        minWidth: "370px",
        flexDirection: "column",
        justifyContent: "flex-start",
        rowGap: "10px",
        alignItems: "flex-start",
    },
    bodyRight: {
        display: "flex",
        flex: 1,
        height: "79.21vh",
        minWidth: "370px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    title: {
        color: Colors.WHITE,
        fontSize: "50px",
        lineHeight: "50px",
        marginBottom: "10px",
        fontFamily: Eczar.regular,
    },
    subtitle: {
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "38px",
    },
    animBox: {
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
    },
    globalPart: {
        position: "relative",
        width: "90%",
    },
};

export default BackgroundRow;
