/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material";
import "./style.css";
import { isNotEmptyString } from "../../utils/StringHelper";

interface Props {
    story: any;
    width?: any;
    height?: any;
    borderRadius?: any;
}

const StoryCard = (props: Props) => {
    const { story, width, height, borderRadius = 13 } = props;
    return (
        <Box
            sx={[
                styles.card,
                width ? { width: width } : {},
                height ? { height: height } : {},
                borderRadius ? { borderRadius: borderRadius } : {},
            ]}
            id={"story-" + story.id ? story.id : story.story.id}>
            {story.image_backdrop && isNotEmptyString(story.image_backdrop) ? (
                <img
                    style={{ borderRadius: borderRadius }}
                    src={story.image_backdrop}
                    className="story-image-backdrop"
                />
            ) : story.story?.image_backdrop &&
              isNotEmptyString(story.story.image_backdrop) ? (
                <img
                    style={{ borderRadius: borderRadius }}
                    src={story.story.image_backdrop}
                    className="story-image-backdrop"
                />
            ) : (
                <img
                    style={{ borderRadius: borderRadius }}
                    src={require("../../assets/images/cover-unknown.jpeg")}
                    className="story-image-backdrop"
                />
            )}
            {story.image_title && isNotEmptyString(story.image_title) ? (
                <img src={story.image_title} className="story-image-title" />
            ) : story.story?.image_title &&
              isNotEmptyString(story.story.image_title) ? (
                <img
                    src={story.story.image_title}
                    className="story-image-title"
                />
            ) : null}
        </Box>
    );
};

const styles = {
    card: {
        width: "165px",
        height: "264px",
        alignSelf: "center",
        position: "relative",
        transition: "all 0.2s ease-in-out",
    },
};

export default StoryCard;
