/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from "@emotion/styled";
import {
    TooltipProps,
    Tooltip,
    tooltipClasses,
    Box,
    Typography,
    Fade,
    useMediaQuery,
} from "@mui/material";
import { ReactComponent as IconQuestion } from "../../../assets/icons/icon_question.svg";
import { Colors } from "../../../style";
import { columnCenterStyles } from "../../../style/flex";
import { strings } from "../../../services/translation";
import { Gilroy } from "../../../style/fonts";

interface Props {
    bgImage?: any;
    showInfos?: boolean | undefined;
    imageInfos?: string | undefined;
}
const BgLeft = (props: Props) => {
    const { bgImage, showInfos, imageInfos } = props;

    const matchesWidth = useMediaQuery("(min-width:800px)");
    const matchesHeight = useMediaQuery("(min-height:800px)");

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: Colors.BTN_ACTIVE,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: Colors.PRIMARY,
            borderRadius: "8px",
            padding: "8px 12px",
        },
    }));

    const bgLeft = [
        styles.bgLeft,
        {
            background: `url(${
                bgImage ? bgImage : require("../../../assets/images/FTUE.jpg")
            }) no-repeat 50%/cover`,
        },
        !matchesHeight && { width: "50%" },
        !matchesWidth && {
            display: "none",
        },
    ];

    return (
        <Box sx={bgLeft}>
            {imageInfos && showInfos ? (
                <CustomTooltip
                    title={
                        <Box
                            sx={{ ...columnCenterStyles, padding: "7px 15px" }}>
                            <Typography style={styles.tooltipTitle}>
                                {strings.messages.whereFrom}
                            </Typography>
                            <Typography style={styles.tooltipSubtitle}>
                                {imageInfos}
                            </Typography>
                        </Box>
                    }
                    placement="right"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 400 }}>
                    <Box sx={styles.questionIcon}>
                        <IconQuestion width={30} height={30} />
                    </Box>
                </CustomTooltip>
            ) : null}
        </Box>
    );
};

const styles = {
    bgLeft: {
        width: "60%",
        maxWidth: "calc(1100px * 0.6)",
        height: "100%",
        position: "relative",
    },
    tooltipTitle: {
        color: Colors.ACCENT,
        fontFamily: Gilroy,
        fontSize: "12px",
    },
    tooltipSubtitle: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: "12px",
    },
    questionIcon: {
        width: "30px",
        height: "30px",
        borderRadius: "30px",
        position: "absolute",
        bottom: "30px",
        left: "30px",
    },
};

export default BgLeft;
