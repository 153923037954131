/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router";
import BackgroundRow from "../../../../components/BackgroundRow";
import { strings } from "../../../../services/translation";
import { columnStartStyles } from "../../../../style/flex";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../../../style";
import normalize from "../../../../utils/fontSizer";

const CookieScreen = () => {
  const navigate = useNavigate();

  return (
    <BackgroundRow
      title={strings.cookies}
      goBack
      goBackAction={() => navigate(-1)}
      leftPartStyle={{ width: "20%" }}
      rightPartStyle={{ width: "80%" }}
      rightPart={
        <Box sx={styles.container}>
          {strings.cookiesInfo.map((item: any, index: number) => (
            <Box
              key={"item" + item.name + index + 1}
              style={{ marginTop: "20px" }}
            >
              <Typography style={styles.name}>{item.name}</Typography>
              {item.text !== "" && (
                <Typography style={styles.role}>{item.text}</Typography>
              )}
            </Box>
          ))}
          <Box height={"80px"} />
        </Box>
      }
    />
  );
};

const styles = {
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    maxWidth: "700px",
  },
  name: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(18),
    color: Colors.WHITE,
    marginBottom: "10px",
  },
  role: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    color: "#9897A3",
  },
};

export default CookieScreen;
