import { Box, ButtonBase, Snackbar, Typography } from "@mui/material"
import useAuth from "../../../services/Auth/useAuth";
import { useState } from "react";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { strings } from "../../../services/translation";
import { columnCenterStyles, rowStyles } from "../../../style/flex";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import { ReactComponent as IconCompanions } from "../../../assets/icons/icon_companion.svg";


const PlayerCodeBtn = () => {

    /**
     * 
     * STATES
     * 
     */

    const auth = useAuth();
    const [showCopied, setShowCopied] = useState(false);

    /**
     * 
     * FUNCTIONS
     * 
     */

    /**
     * @handlePressCode
     * Copie player code
     */
    const handlePressCode = () => {
        if (auth && auth.user && isNotEmptyString(auth.user.player_code)) {
            navigator.clipboard.writeText(auth.user.player_code);
            setShowCopied(true);
        }
    };

    if (auth && auth.user && auth.user.player_code) {
        return (
            <Box>
                <Snackbar
                    open={showCopied}
                    autoHideDuration={3000}
                    onClose={() => setShowCopied(false)}>
                    <Box sx={styles.snackBar}>
                        <Typography sx={styles.snackBarText}>{strings.copied}</Typography>
                    </Box>
                </Snackbar>
                <ButtonBase onClick={() => handlePressCode()}>
                    <Box sx={styles.playerCode}>
                        <IconCompanions width={20} height={20} fill={Colors.WHITE} />
                        <Typography sx={styles.snackBarText}>#{auth.user.player_code}</Typography>
                    </Box>
                </ButtonBase>
            </Box>

        );
    } else return null;

}

const styles = {
    snackBar: {
        borderRadius: "10px",
        backgroundColor: Colors.BTN_ACTIVE,
        width: "280px",
        padding: "15px 0px",
        ...columnCenterStyles,
    },
    snackBarText: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
    },
    playerCode: {
        ...rowStyles,
        columnGap: "7px",
        padding: "5px 10px",
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "7px",
    },
}

export default PlayerCodeBtn;