/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-bitwise */
export enum ChoiceTypeEnum {
  NORMAL,
  TEXTBOX,
  PLAYER,
  RANDOM,
}

export enum SceneTypeEnum {
  NONE = 0, // 000000
  SHOW_BANNER = 1 << 0, // 000001
  SHOW_TEXT = 1 << 1, // 000010
  SHOW_CHOICE = 1 << 2, // 000100
  SHOW_FILLER = 1 << 3, // 001000
  SHOW_FULLSCREEN_IMAGE = 1 << 4, // 010000
  SHOW_IMAGE = 1 << 5, // 100000
  SHOW_TIMER = 1 << 6,
}

export enum ScreenType {
  NONE,
  TIMER,
  FULLSCREEN_IMAGE,
  NORMAL_IMAGE,
  STANDARD,
  POOLP,
  CHANGE,
  IMAGE_TIMER,
  SECRET,
}

export interface IStory {
  scenes: Array<IScene>;
  resume: string;
}

export interface IChoice {
  ornament: any;
  conditions: Array<any>;
  sceneLink: number;
  text: string;
  drawConditions: Array<IVariable>;
  playerStateForbidden: string;
  isRandom: boolean;
  randomScenes: Array<any>;
  diceNumber?: number | undefined;
  diceProbaType?: string | undefined;
}

export interface IScene {
  musicStop: any;
  number: number;
  variables: Array<any>;
  choices: Array<any>;
  choiceType: string;
  text: string;
  images: Array<any>;
  image: any;
  timer: number;
  ambianceStop: boolean;
  objective: any;
  timerSelect: any;
  essentials: Array<any>;
  information: any;
}

export interface IVariable {
  firstOperand: string;
  secondOperand: string;
  thirdOperand: string;
}
