/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material";
import { Colors } from "../../style";

const Separator = ({
  height = "2px",
  color = Colors.BTN_ACTIVE,
  style,
}: {
  height?: string;
  color?: string;
  style?: any;
}) => {
  return (
    <Box
      sx={[
        { width: "100%", height: height, backgroundColor: color },
        style ? style : {},
      ]}
    />
  );
};

export default Separator;
