/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase, Box, Typography } from "@mui/material";
import { Colors } from "../../style";
import { columnStyles, rowCenterStyles, rowStyles } from "../../style/flex";
import { Eczar } from "../../style/fonts";
import { isNotEmptyString } from "../../utils/StringHelper";
import DynamicTag from "../DynamicTag";
import GradientText from "../GradientText";
import TicketPromoAnimated from "../TicketPromoAnimated";
import { useEffect, useRef, useState } from "react";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import { getRouteManager } from "../../services/routeManager";
import { Player } from "@lottiefiles/react-lottie-player";
import { logoPass } from "../../constants/logoPass";

const Card = ({
  card,
  cardWidth,
  cardHeight,
  cardSpacing,
  active = false,
  findStory,
  showSkinsModalCallback,
  showPassModal,
}: {
  card: any;
  cardWidth: number;
  cardHeight: number;
  cardSpacing: number;
  findStory: any;
  active?: boolean | undefined;
  showSkinsModalCallback?: any;
  showPassModal?: any;
}) => {
  const textWidth = cardWidth * 0.25;
  const leftPartWith = cardWidth * 0.413;

  const [canPlay, setCanPlay] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [showPoster, setShowPoster] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const lottieRef = useRef<any>(null);

  // Handler pour le timer
  useEffect(() => {
    let timer: number | undefined;
    if (
      active &&
      card &&
      card.contentType &&
      (card.contentType === "video" || card.type === "pass")
    ) {
      // Commencer le timer uniquement si la carte est active
      setShowPoster(true);
      timer = window.setTimeout(
        () => {
          // Activer la lecture de la vidéo une fois que le timer est terminé
          setShowPoster(false);
          setCanPlay(true);
        },
        card.type === "pass" ? 1000 : 5000,
      );
    }

    return () => {
      // Nettoyer le timer lorsqu'on démonte le composant ou que la carte change
      resetVideo();
      if (timer) clearTimeout(timer);
      if (canPlay) setCanPlay(false);
      if (videoEnded) setVideoEnded(false);
    };
  }, [active]);

  useEffect(() => {
    if (
      active &&
      videoRef.current &&
      videoReady &&
      canPlay &&
      card.contentType &&
      card.contentType === "video"
    ) {
      videoRef.current.play();
    } else if (active && lottieRef.current && canPlay) {
      lottieRef.current.play();
    }
  }, [active, videoReady, canPlay]);

  const resetVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    if (lottieRef.current) {
      lottieRef.current.pause();
    }
  };

  // Handler pour la fin de la vidéo
  const handleVideoEnd = () => {
    // Mettre en pause la vidéo et réinitialiser le timer
    setVideoEnded(true);
  };

  const styles = {
    card: {
      ...rowStyles,
      borderRadius: "20px",
      alignItems: "center",
      width: `${cardWidth}px`,
      minWidth: `${cardWidth}px`,
      height: `${cardHeight}px`,
      marginRight: `${cardSpacing}px`,
    },
    cardLeft: {
      ...columnStyles,
      justifyContent: "center",
      height: "100%",
      flex: 1,
      background:
        card?.type && card?.type === "pass"
          ? `url(${require("../../assets/images/bg_carousel_pass.jpg")}) 50%/cover no-repeat`
          : `url(${require("../../assets/images/bg_carousel.jpg")}) 50%/cover no-repeat`,
      paddingLeft: `${leftPartWith * 0.128}px`,
    },
    cardLeftText: {
      ...columnStyles,
      justifyContent: "center",
      rowGap: "8px",
      height: "100%",
      width: `${textWidth}px`,
    },
    tagText: {
      fontSize: `${10 + 0.02 * textWidth}px`,
    },
    gradientText: {
      fontFamily: Eczar.semiBold,
      fontSize: `${textWidth * 0.19}px`,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "120%",
      textAlign: "start",
    },
    subtitle: {
      color: card.subtitleColor,
      fontSize: `${textWidth * 0.093}px`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "400",
      wordWrap: "break-word",
      textAlign: "start",
    },
    promo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    storyPrice: {
      fontFamily: "Gilroy-Bold",
      color: Colors.PROMO,
      marginLeft: `${textWidth * 0.03}px`,
      fontSize: `${textWidth * 0.15}px`,
    },
    storyPriceOld: {
      color: Colors.OLD_PRICE,
      fontFamily: "Gilroy-Bold",
      textDecorationLine: "line-through",
      textDecorationStyle: "solid",
      marginLeft: `${textWidth * 0.03}px`,
      fontSize: `${textWidth * 0.093}px`,
    },
    img: {
      position: "relative",
      "&.image-container::before": {
        content: '""',
        position: "absolute",
        top: 0,
        //left: 0,
        left: "-1px",
        width: "100%",
        height: "100%",
        backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0) 70%, ${Colors.PRIMARY} 100%)`,
      },
    },
  };

  return (
    <ButtonBase
      key={card.id}
      sx={styles.card}
      onClick={() => {
        card.type === "kit"
          ? showSkinsModalCallback
            ? showSkinsModalCallback(card.cardSkins)
            : null
          : card.type === "pass"
          ? showPassModal(true)
          : findStory
          ? findStory(card.itemId)
          : null;
      }}>
      <Box sx={styles.cardLeft}>
        <Box sx={styles.cardLeftText}>
          {card.tag && isNotEmptyString(card.tag) ? (
            <DynamicTag
              text={card.tag}
              textColor={card.tagColor}
              textStyle={styles.tagText}
              style={{ alignSelf: "flex-start" }}
            />
          ) : null}

          <GradientText
            color1="#FFF"
            color2="#8792CE"
            style={styles.gradientText}
            background={`linear-gradient(147deg, ${card.titleColor1} 0%, ${card.titleColor2} 84.18%)`}>
            {card.name ? card.name : ""}
          </GradientText>
          <Typography sx={styles.subtitle}>
            {card.subtitle ? card.subtitle : ""}
          </Typography>
          {card.type === "promo" ? (
            <Box sx={styles.promo}>
              <TicketPromoAnimated
                style={{ width: textWidth * 0.15, height: textWidth * 0.15 }}
              />
              <Typography sx={styles.storyPriceOld}>{"80"}</Typography>
              <Typography sx={styles.storyPrice}>{"56"}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box className="image-container" sx={styles.img}>
        {!videoEnded &&
        !showPoster &&
        card.contentType &&
        card.contentType === "video" ? (
          <video
            ref={videoRef}
            src={getRouteManager().getCardVideo(card.id)}
            preload="auto"
            autoPlay={isTrueBoolean(canPlay)}
            height={cardHeight}
            poster={getRouteManager().getCardImage(card.id)}
            muted
            onCanPlayThrough={() => setVideoReady(true)}
            onEnded={handleVideoEnd}>
            <img
              src={getRouteManager().getCardImage(card.id)}
              height={cardHeight}
              alt="carousel image"
            />
          </video>
        ) : card.type === "promo" && card.itemId && card.promo?.story ? (
          <img
            src={
              card.promo?.story?.isSeries
                ? getRouteManager().cover(card.promo.story.id)
                : getRouteManager().getCoverForSeries(card.promo.story.id)
            }
            height={cardHeight}
            alt="carousel image"
          />
        ) : card.type === "pass" ? (
          <Box
            sx={{
              height: cardHeight * 1.33,
              backgroundColor: Colors.PRIMARY,
              ...rowCenterStyles,
              width: cardWidth * 0.587,
            }}>
            <Player
              ref={lottieRef}
              src={logoPass}
              style={{ height: cardHeight * 1.33 }}
              loop
            />
          </Box>
        ) : card.type !== "promo" ? (
          <img
            src={getRouteManager().getCardImage(card.id)}
            height={cardHeight}
            alt="carousel image"
          />
        ) : null}
      </Box>
    </ButtonBase>
  );
};

/* 
._0r6cJW.U7TB9C video {
    object-fit: contain;
    right: 0;
    width: 100%;
}
._0r6cJW video {
    max-height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
     */

export default Card;
