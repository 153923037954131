import { useContext, useEffect, useState } from "react";
import useAuth from "../../services/Auth/useAuth";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import LoadingView from "../../components/LoadingView";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styles } from "./styles";
import { Story } from "../../models/story";
import { MainStoryBox } from "./MainStoryBox";
import { MAIN_QUEST_LEVELS } from "../../constants";
import { SidebarContext } from "../../utils/ContextsHelper";
import DynamicTag from "../../components/DynamicTag";
import { rowStartStyles } from "../../style/flex";

const MainQuestScreen = () => {
    /**
     *
     * STATES
     *
     */
    const auth = useAuth();
    const { sidebarCollapsed } = useContext(SidebarContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [stories, setStories] = useState<Story[] | null>(null);

    const matches = useMediaQuery(
        `(min-width:${sidebarCollapsed ? 1143 : 1195}px)`,
    );

    /**
     *
     * USE EFFECTS
     *
     */
    useEffect(() => {
        initData();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @initData
     * Get Stories FTUE
     */
    const initData = async () => {
        try {
            setLoading(true);
            if (auth?.user?.login_token) {
                const api = getRouteManager().getFTUEStoriesWithRelations();
                const data = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                });
                if (data && data.length) {
                    setStories(data);
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /**
     * @getIsStoryUnlocked
     * check if story is unlocked regarding
     * user level & MAIN_QUEST_LEVELS
     * @param index
     * @returns unlocked : boolean
     */
    const getIsStoryUnlocked = (index: number): boolean => {
        if (auth?.user?.level >= MAIN_QUEST_LEVELS[index]) {
            return true;
        }
        return false;
    };

    /**
     * @renderItems
     * renders stories list with MainStoryBox component
     * @returns Views
     */
    const renderItems = () => {
        if (stories && stories.length) {
            return stories.map((item: Story, index: number) => (
                <MainStoryBox
                    key={index}
                    story={item}
                    index={index}
                    unlocked={getIsStoryUnlocked(index)}
                />
            ));
        }
    };

    if (loading) {
        return <LoadingView />;
    }

    return (
        <Box
            sx={[
                matches ? styles.mainContainerBig : styles.mainContainerSmall,
                styles.mainContainer,
            ]}>
            <Box sx={matches ? styles.container : styles.containerSmall}>
                <Box
                    sx={{
                        ...rowStartStyles,
                        width: "100%",
                        columnGap: "10px",
                    }}>
                    <Typography
                        sx={[
                            styles.topMenuTitle,
                            !matches && {
                                justifyContent: "center",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.mainQuest.title}
                    </Typography>
                    <DynamicTag text={"solo"} />
                </Box>

                <Typography
                    sx={[
                        styles.subtitle,
                        !matches && {
                            justifyContent: "center",
                            marginBottom: "10px",
                            textAlign: "center",
                        },
                    ]}>
                    {strings.mainQuest.subtitle}
                </Typography>
                <Typography
                    sx={[
                        styles.subtitle,
                        !matches && {
                            justifyContent: "center",
                            marginBottom: "10px",
                            textAlign: "center",
                        },
                    ]}>
                    {strings.mainQuest.subtitle2}
                </Typography>
            </Box>
            <Box
                sx={[
                    styles.right,
                    !matches && {
                        justifyContent: "center",
                        textAlign: "center",
                    },
                ]}>
                <Box>
                    {stories && stories.length ? (
                        <Box sx={styles.storiesList}>{renderItems()}</Box>
                    ) : (
                        <Typography sx={styles.noStories}>
                            No stories
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default MainQuestScreen;
