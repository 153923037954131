import { Box, Typography } from "@mui/material";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import Separator from "../../Separator";
import { styles } from "../style";
import { ReactComponent as IconLocal } from "../../../assets/icons/icon_local.svg";
import { normalizeSize } from "../../../utils/fontSizer";

export const FTUEModalGame = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleColumnContainer}>
                <Box sx={styles.iconContainer}>
                    <IconLocal width={57} height={57} fill={Colors.PACK} />
                </Box>
                <Typography sx={[styles.title, { color: Colors.ACCENT }]}>
                    {strings.ftue.game.title}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box style={styles.contentContainer}>
                <Typography
                    component={"span"}
                    sx={[
                        styles.itemText,
                        { textAlign: "center", fontSize: normalizeSize(16) },
                    ]}>
                    {strings.ftue.game.intro}
                    <Typography
                        component={"span"}
                        sx={[
                            styles.itemText,
                            {
                                color: Colors.ACCENT,
                                fontSize: normalizeSize(16),
                            },
                        ]}>
                        {strings.ftue.game.title}
                    </Typography>
                    {strings.ftue.game.intro2}
                </Typography>
                <Typography sx={styles.itemText}>
                    {strings.ftue.game.names}
                    <Typography
                        component={"span"}
                        sx={[styles.itemText, { color: Colors.WHITE }]}>
                        {strings.ftue.game.names2}
                    </Typography>
                    {strings.ftue.game.names3}
                </Typography>
                <Typography sx={styles.itemText}>
                    {strings.ftue.game.companions}
                </Typography>
                <Typography sx={styles.itemText}>
                    {strings.ftue.game.solo}
                </Typography>
            </Box>
        </Box>
    );
};
