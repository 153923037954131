/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import "./style.css";
import { Box, ButtonBase, Typography } from "@mui/material";
import { rowCenterStyles } from "../../style/flex";
import SoundManager from "../../services/SoundManager";

interface Props {
    children: string;
    onPress: any;
    disabled?: boolean | any;
    width?: any;
    icon?: any;
    style?: any;
}

const GradientBtn = (props: Props) => {
    const { children, onPress, disabled, width, icon, style } = props;

    if (disabled === false) {
        return (
            <ButtonBase
                className="linear-gradient-btn"
                sx={[
                    {
                        width: width ? width : "50%",
                        ...rowCenterStyles,
                        borderRadius: "13px",
                    },
                    style ? style : {},
                ]}
                onClick={() => {
                    SoundManager.getInstance().playBtnSound();
                    onPress();
                }}>
                {icon ? (
                    <Box sx={{ ...rowCenterStyles, marginRight: "10px" }}>
                        {" "}
                        {icon}{" "}
                    </Box>
                ) : null}
                <Typography
                    style={{
                        color: Colors.WHITE,
                        fontSize: normalize(18),
                        fontFamily: "Gilroy-Bold",
                        textAlign: "center",
                    }}>
                    {children}
                </Typography>
            </ButtonBase>
        );
    } else {
        return (
            <ButtonBase
                className="disabled-gradient-btn"
                sx={{
                    width: width ? width : "50%",
                    ...rowCenterStyles,
                    borderRadius: "13px",
                    borderStyle: "solid",
                    borderWidth: "3px",
                    borderColor: Colors.FOND_ITEM_POPUP,
                }}>
                {icon ? (
                    <Box sx={{ ...rowCenterStyles, marginRight: "10px" }}>
                        {" "}
                        {icon}{" "}
                    </Box>
                ) : null}
                <Typography
                    style={{
                        color: Colors.FOND_ITEM_POPUP,
                        fontSize: normalize(18),
                        fontFamily: "Gilroy-Bold",
                        textAlign: "center",
                    }}>
                    {children}
                </Typography>
            </ButtonBase>
        );
    }
};

export default GradientBtn;

GradientBtn.defaultProps = {
    disabled: false,
};
