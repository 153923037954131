/* eslint-disable @typescript-eslint/no-explicit-any */
import { Colors } from "../../style";
import "./style.css";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon_achievement.svg";
import { strings } from "../../services/translation";
import normalize from "../../utils/fontSizer";
import { Box } from "@mui/material";

interface Props {
  story: any;
}

const SuccessList = (props: Props) => {
  const { story } = props;
  return (
    <Box sx={styles.container} className="success-list-container">
      {story.successes ?
        story.successes.map((s: any) => (
          <Box key={s.id} sx={styles.success}>
            {s.userSuccesses?.length ? (
              <Box sx={styles.successInside}>
                <Box sx={{ alignSelf: "center" }}>
                  <IconSuccess width={28} height={28} fill={Colors.ACCENT} />
                </Box>
                <Box sx={styles.successTextContainer}>
                  <span style={styles.successTextName}>
                    {s.successTrads?.length && s.successTrads[0].successName}
                  </span>
                  <span style={styles.successTextDescription}>
                    {s.successTrads?.length && s.successTrads[0].description}
                  </span>
                  {s.nbUnlock ? (
                    <span style={styles.successTextUnlocked}>
                      {s.nbUnlock}% {strings.playerUnlockedThis}
                    </span>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box sx={styles.successInside}>
                <Box sx={{ alignSelf: "center" }}>
                  <IconSuccess width={28} height={28} fill={Colors.LOCK} />
                </Box>
                <Box sx={styles.successTextLockedContainer}>
                  <span style={styles.successTextLocked}>
                    {strings.achievementLocked}
                  </span>
                  <span style={styles.successTextLocked2}>???</span>
                </Box>
              </Box>
            )}
          </Box>
        )) : story.success &&
        story.success.map((s: any) => (
          <Box key={s.id} sx={styles.success}>
            {s.unlocked ? (
              <Box sx={styles.successInside}>
                <Box sx={{ alignSelf: "center" }}>
                  <IconSuccess width={28} height={28} fill={Colors.ACCENT} />
                </Box>
                <Box sx={styles.successTextContainer}>
                  <span style={styles.successTextName}>
                    {s.successName}
                  </span>
                  <span style={styles.successTextDescription}>
                    {s.description}
                  </span>
                  {s.nbUnlock ? (
                    <span style={styles.successTextUnlocked}>
                      {s.nbUnlock}% {strings.playerUnlockedThis}
                    </span>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box sx={styles.successInside}>
                <Box sx={{ alignSelf: "center" }}>
                  <IconSuccess width={28} height={28} fill={Colors.LOCK} />
                </Box>
                <Box sx={styles.successTextLockedContainer}>
                  <span style={styles.successTextLocked}>
                    {strings.achievementLocked}
                  </span>
                  <span style={styles.successTextLocked2}>???</span>
                </Box>
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};

const styles = {
  container: {
    padding: "36px 60px",
  },
  success: {
    marginBottom: "8px",
    padding: "20px 15px",
    borderRadius: "13px",
    backgroundColor: Colors.BTN_ACTIVE,
  },
  successInside: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  successTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "80%",
  },
  successTextName: {
    color: Colors.ACCENT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    alignSelf: "flex-start",
    width: "80%",
  },
  successTextDescription: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
    alignSelf: "flex-start",
    width: "80%",
    marginTop: "5px",
  },
  successTextUnlocked: {
    color: "#9897A3",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
    alignSelf: "flex-start",
    marginTop: "5px",
  },
  successTextLockedContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "80%",
    height: "45px",
  },
  successTextLocked: {
    fontSize: normalize(12),
    color: "#9897A3",
    fontFamily: "Gilroy-Bold",
    alignSelf: "flex-start",
  },
  successTextLocked2: {
    fontSize: normalize(12),
    color: "#9897A3",
    fontFamily: "Gilroy-Bold",
    alignSelf: "flex-start",
    marginTop: "7px",
  },
};

export default SuccessList;
