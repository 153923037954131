/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { rewardTicketJson } from "../../../constants/rewardTicket";
import { columnCenterStyles } from "../../../style/flex";
import Separator from "../../../components/Separator";
import { strings } from "../../../services/translation";
import { styles } from "../styles";
import { logoPass } from "../../../constants/logoPass";

export const PassRewardModal = ({
    isOpen,
    callback,
}: {
    isOpen: boolean;
    callback: any;
}) => {
    return (
        <Modal open={isOpen} onClose={callback} sx={{ outline: "none" }}>
            <Box sx={styles.modalRewardContainer}>
                <Box
                    sx={{
                        width: "130px",
                        height: "130px",
                        ...columnCenterStyles,
                    }}>
                    <Player
                        src={logoPass}
                        autoplay
                        loop
                        speed={1.5}
                        style={{
                            width: "130px",
                            height: "130px",
                        }}
                    />
                </Box>

                <Typography sx={styles.modalRewardText}>
                    {strings.pass.enjoyYourNewPass}
                </Typography>
                <Separator />
                <Box sx={styles.popupButtons}>
                    <ButtonBase sx={styles.yesButton} onClick={callback}>
                        <Typography sx={styles.yesText}>
                            {strings.great}
                        </Typography>
                    </ButtonBase>
                </Box>
            </Box>
        </Modal>
    );
};
