/* eslint-disable @typescript-eslint/no-explicit-any */
import { isBoolean } from 'lodash';

// =======================================================

export function isObject(obj: any) {
  return typeof obj === 'object';
}

// =======================================================

export function isString(str: any) {
  return typeof str === 'string';
}

// =======================================================

export function isNumber(num: any) {
  return typeof num === 'number';
}

// =======================================================

export function isArray(arr: any) {
  return Array.isArray(arr);
}

// =======================================================

export function isFunction(func: any) {
  return typeof func === 'function';
}

// =======================================================

export function isTrueBoolean(value: any) {
  return isBoolean(value) && value === true;
}

// =======================================================