/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import TicketAnimated from "../TicketAnimated";
import { Box, ButtonBase, Typography } from "@mui/material";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";
import PassAnimated from "../PassAnimated";
import GradientText from "../GradientText";
import { strings } from "../../services/translation";
import { Gilroy } from "../../style/fonts";
import { ReactComponent as IconCompanions } from "../../assets/icons/icon_companion.svg";
import RegisterTemporaryAccountModal from "../RegisterTemporaryAccountModal";

interface Props {
    user?: any;
    goToShop?: any;
    style?: any;
    fontSize?: any;
    isCollapsed?: boolean | undefined;
}

const UserCurrencies = (props: Props) => {
    const { user, goToShop, style, fontSize, isCollapsed = false } = props;

    const [showRegisterModal, setShowRegisterModal] = useState(false);

    if (user) {
        return (
            <Box
                sx={
                    isCollapsed
                        ? { ...columnCenterStyles }
                        : { ...rowCenterStyles }
                }>
                {user && user.pass && user.pass.isActive ? (
                    <Box sx={{ ...rowCenterStyles }}>
                        {isCollapsed ? (
                            <Box
                                sx={[
                                    styles.ticketsOwnedBlock,
                                    isCollapsed
                                        ? {
                                              backgroundColor: "transparent",
                                          }
                                        : {},
                                    style ? style : {},
                                ]}>
                                <PassAnimated
                                    style={{
                                        width: 25,
                                        height: 25,
                                    }}
                                />
                            </Box>
                        ) : (
                            <Box
                                sx={[
                                    styles.ticketsOwnedBlock,
                                    isCollapsed
                                        ? {
                                              backgroundColor: "transparent",
                                          }
                                        : {
                                              marginRight: "5px",
                                          },
                                    style ? style : {},
                                ]}>
                                <PassAnimated
                                    style={{
                                        width: 25,
                                        height: 25,
                                    }}
                                />
                                <GradientText
                                    color1={Colors.PASS1}
                                    color2={Colors.PASS2}
                                    style={styles.passText}>
                                    {strings.pass.pass}
                                </GradientText>
                            </Box>
                        )}
                    </Box>
                ) : user?.isTemporaryAccount ? (
                    <Box sx={{ ...rowCenterStyles }}>
                        <ButtonBase
                            onClick={() => setShowRegisterModal(true)}
                            sx={[
                                styles.ticketsOwnedBlock,
                                isCollapsed
                                    ? {
                                          backgroundColor: "transparent",
                                      }
                                    : {
                                          marginRight: "5px",
                                      },
                                style ? style : {},
                            ]}>
                            <IconCompanions
                                width={25}
                                height={25}
                                fill={Colors.OLD_PRICE}
                            />
                            {!isCollapsed ? (
                                <Typography
                                    sx={[
                                        styles.passText,
                                        { color: Colors.OLD_PRICE },
                                    ]}>
                                    {strings.labels.guest}
                                </Typography>
                            ) : null}
                        </ButtonBase>
                    </Box>
                ) : null}

                <ButtonBase
                    sx={[
                        styles.ticketsOwnedBlock,
                        isCollapsed
                            ? {
                                  backgroundColor: "transparent",
                              }
                            : {},
                        style ? style : {},
                    ]}
                    onClick={() => {
                        goToShop();
                    }}>
                    <Box
                        sx={[
                            styles.ticketsOwned,
                            isCollapsed && {
                                ...columnCenterStyles,
                            },
                        ]}>
                        <TicketAnimated style={{ width: 25, height: 25 }} />
                        <Typography
                            sx={[
                                styles.ticketAmountOwned,
                                fontSize ? { fontSize: fontSize } : {},
                            ]}>
                            {user.tickets ? user.tickets : 0}
                        </Typography>
                    </Box>
                </ButtonBase>
                <RegisterTemporaryAccountModal
                    isOpen={showRegisterModal}
                    callback={() => setShowRegisterModal(false)}
                    onCloseCallback={() => setShowRegisterModal(false)}
                />
            </Box>
        );
    } else {
        return null;
    }
};

const flexStartStyles: any = {
    justifyContent: "flex-start",
    alignItems: "center",
};

const rowStyles: any = {
    display: "flex",
    flexDirection: "row",
};

const styles = {
    ticketsOwnedBlock: {
        ...rowStyles,
        ...flexStartStyles,
        zIndex: 20,
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "7px",
        padding: "5px 11px",
        display: "flex",
        alignItems: "center",
    },
    ticketAmountOwned: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.TICKET,
        marginLeft: "3px",
    },
    tokensOwned: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: "6px",
    },
    ticketsOwned: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    tokensAmountOwned: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.TOKEN,
        marginLeft: "3px",
    },
    passText: {
        fontFamily: Gilroy,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        marginLeft: "3px",
    },
};
export default UserCurrencies;
