/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowIconDown } from "../../assets/icons/popup_arrow_down.svg";
import { isFunction } from "lodash";
import { isNotEmptyString } from "../../utils/StringHelper";
import ColorManager from "../../services/colorManager";
import { Box, Slide, Typography } from "@mui/material";
import { columnStyles } from "../../style/flex";

// =======================================================

interface Props {
  onCancel?: any;
  text?: string | null;
  withoutArrow?: boolean | any;
  shouldGoUp?: number | any;
  containerRef?: any;
}

// =======================================================

const PopUpIndicator = (props: Props) => {
  const { withoutArrow, onCancel, containerRef } = props;
  const [open, setOpen] = useState(true);
  /**
   *
   */
  const getColor = (colorType: string) => {
    return ColorManager.getInstance().getColor(colorType);
  };

  /**
   *
   */
  const handleCancel = () => {
    if (isFunction(onCancel)) {
      setOpen(false);
      onCancel();
    }
  };

  useEffect(() => {
    if (isFunction(onCancel)) {
      setTimeout(() => {
        handleCancel();
      }, 1500);
    }
  }, []);

  // --
  return (
    <Slide
      direction="up"
      in={open}
      container={containerRef ? containerRef.current : null}
      mountOnEnter
      unmountOnExit
    >
      <Box
        onClick={() => handleCancel()}
        sx={[
          styles.popBg,
          {
            backgroundColor: getColor("tertiary"),
            ...columnStyles,
            alignItems: "center",
            ...columnStyles,
            zIndex: 99,
            minWidth: "150px",
            position: "absolute",
            alignSelf: "center",
            top: /* isNumber(shouldGoUp) ? shouldGoUp : */ "-45px",
          },
        ]}
      >
        {!withoutArrow ? (
          <ArrowIconDown
            width={25}
            height={25}
            fill={getColor("tertiary")}
            style={{ position: "absolute", bottom: "-18px", zIndex: 99 }}
          />
        ) : null}

        {isNotEmptyString(props.text) ? (
          <Typography
            style={{
              color: getColor("secondary"),
              fontFamily: "Gilroy-Bold",
              textAlign: "center",
            }}
          >
            {props.text}
          </Typography>
        ) : null}
      </Box>
    </Slide>
  );
};

// =======================================================

const styles = {
  popBg: {
    backgroundColor: "red",
    alignSelf: "center",
    padding: "8px 12px",
    borderRadius: "8px",
  },
};

// =======================================================

export default PopUpIndicator;
