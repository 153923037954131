/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { Tickets } from "../../../models/tickets";
import { styles } from "../styles";
import { columnCenterStyles } from "../../../style/flex";
import { Player } from "@lottiefiles/react-lottie-player";
import { rewardTicketJson } from "../../../constants/rewardTicket";
import { strings } from "../../../services/translation";
import TicketAnimated from "../../../components/TicketAnimated";
import Separator from "../../../components/Separator";

const PurchaseRewardModal = ({
  isOpen,
  tickets,
  callback,
}: {
  isOpen: boolean;
  tickets?: Tickets | undefined;
  callback: any;
}) => {
  if (isOpen && tickets && tickets.number) {
    return (
      <Modal open={isOpen} /* onClose={callback} */>
        <Box sx={styles.modalRewardContainer}>
          <Box
            sx={{
              width: "130px",
              height: "130px",
              ...columnCenterStyles,
            }}
          >
            <Player
              src={rewardTicketJson}
              autoplay
              loop
              speed={1.5}
              style={{
                width: "130px",
                height: "130px",
              }}
            />
          </Box>

          <Box
            sx={styles.ticketsNumber}
          >
            <TicketAnimated style={{ width: 25, height: 25 }} />
            <Typography sx={styles.modalRewardTickets}>
              {tickets.number}
            </Typography>
          </Box>

          <Typography sx={styles.modalRewardText}>
            {strings.enjoyYourNewTickets}
          </Typography>
          <Separator />
          <Box sx={styles.popupButtons}>
            <ButtonBase sx={styles.yesButton} onClick={callback}>
              <Typography sx={styles.yesText}>{strings.great}</Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Modal>
    );
  } else {
    return null;
  }
};

export default PurchaseRewardModal;
