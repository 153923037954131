/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback } from "react";

import { Colors } from "../../style";
import { isFunction } from "../../utils/TypeOfHelper";
import { strings } from "../../services/translation";
import SoundManager from "../../services/SoundManager";
import normalize from "../../utils/fontSizer";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { columnCenterStyles } from "../../style/flex";

// =======================================================

interface Props {
    buttonText: Required<string>;
    content?: JSX.Element | null;
    minHeight?: number;
    modalVisible: boolean;
    onCancel?: any;
    onCloseModal: any;
    title?: string;
    titleHeight?: number;
    twoButtons?: boolean;
    buttonCancelText?: string;
    onBackdropPress?: any;
    isErrorButton?: boolean;
}

// =======================================================

// =======================================================

const ClassicModal = (props: Props) => {
    // ====== Local states ======

    const {
        buttonText,
        content,
        minHeight,
        modalVisible,
        onCancel,
        onCloseModal,
        onBackdropPress = null,
        title,
        twoButtons,
        buttonCancelText,
        isErrorButton,
    } = props;

    const modalStyling: any = {};

    if (minHeight !== undefined) {
        modalStyling.minHeight = minHeight;
    }

    /**
     * Triggered when user taps on the submit button
     */
    const handleSubmit = useCallback(() => {
        onCloseModal();
    }, [props]);

    return (
        <Modal
            open={modalVisible}
            sx={styles.centeredView}
            onClose={() => (onBackdropPress ? onBackdropPress() : null)}>
            <Box sx={styles.modalView}>
                <Box sx={styles.contentContainer}>
                    <Typography sx={styles.title}>{title}</Typography>
                    <Box sx={styles.content}>{content}</Box>
                </Box>
                <Box sx={styles.separator} />

                <Box sx={styles.popupButtons}>
                    {twoButtons && isFunction(onCancel) ? (
                        <ButtonBase
                            sx={styles.noButton}
                            onClick={() => {
                                SoundManager.getInstance().playCloseModalSound();
                                onCancel();
                            }}>
                            <Typography sx={styles.noText}>
                                {buttonCancelText
                                    ? buttonCancelText
                                    : strings.cancel}
                            </Typography>
                        </ButtonBase>
                    ) : null}

                    <ButtonBase
                        sx={[
                            styles.yesButton,
                            isErrorButton
                                ? { backgroundColor: Colors.ERROR }
                                : {},
                        ]}
                        onClick={() => {
                            SoundManager.getInstance().playBtnSound();
                            handleSubmit();
                        }}>
                        <Typography
                            sx={[
                                styles.yesText,
                                isErrorButton ? { color: Colors.WHITE } : {},
                            ]}>
                            {buttonText}
                        </Typography>
                    </ButtonBase>
                </Box>
            </Box>
        </Modal>
    );
};

const styles = {
    centeredView: {
        flex: 1,
        ...columnCenterStyles,
    },
    modalView: {
        backgroundColor: Colors.PRIMARY,
        width: "350px",
        maxWidth: "350px",
        ...columnCenterStyles,
        shadowColor: Colors.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        borderRadius: "20px",
        paddingTop: "30px",
        paddingBottom: "20px",
    },
    contentContainer: {
        width: "90%",
    },
    title: {
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
        fontSize: normalize(20),
        padding: "0px 25px",
    },
    content: {
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        ...columnCenterStyles,
        marginTop: "25px",
    },
    separator: {
        width: "350px",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        marginTop: "25px",
    },
    popupButtons: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        marginTop: "25px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
};

// =======================================================

// =======================================================

ClassicModal.defaultProps = {
    modalVisible: false,
};

// =======================================================

export default ClassicModal;
