/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMediaQuery,
  Box,
  Typography,
  Input,
  InputAdornment,
  ButtonBase,
} from "@mui/material";
import { Formik } from "formik";
import GradientBtn from "../../../../components/GradientBtn";
import { strings } from "../../../../services/translation";
import { Colors } from "../../../../style";
import * as yup from "yup";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import { ReactComponent as SecretIcon } from "../../../../assets/icons/icon_secret.svg";
import { ReactComponent as IconPassword } from "../../../../assets/icons/icon_pwd.svg";
import { styles } from "../style";
import normalize from "../../../../utils/fontSizer";
import { useTogglePasswordVisibility } from "../hook";
import { useState } from "react";
import { isNotEmptyString } from "../../../../utils/StringHelper";

interface Props {
  callback: any;
}

const BetaBox = (props: Props) => {
  const { callback } = props;

  const matchesWidth = useMediaQuery("(min-width:800px)");
  const matchesHeight = useMediaQuery("(min-height:900px)");

  const { passwordVisibility, rightIcon, handlePasswordVisibility } =
    useTogglePasswordVisibility();

  const [error, setError] = useState("");

  const handleError = (error: any, type: string) => {
    if (error.endsWith("is a required field") && type === "code") {
      return strings.codeRequired;
    }
  };

  const handleBetaCode = (code: any) => {
    if (code === "betaperiple") {
      callback(false);
    } else {
      setError(strings.errors.invalidCode);
    }
  };
  return (
    <Box
      sx={[
        styles.loginContainer,
        matchesWidth ? { width: "50%" } : { width: "100%" },
      ]}
    >
      <Box
        sx={
          matchesHeight
            ? { width: "50px", height: "50px" }
            : { display: "none" }
        }
      >
        <IconTalesup width={50} height={50} fill={Colors.WHITE} />
      </Box>
      <Typography sx={styles.title}>{strings.closedBeta}</Typography>
      <Typography
        sx={[styles.subtitle, { marginBottom: "calc(10px + 0.5vh)" }]}
      >
        {strings.closedBetaSub}
      </Typography>
      <Typography
        sx={[styles.subtitle, { marginBottom: "calc(10px + 0.5vh)" }]}
      >
        {strings.closedBetaSub2}
      </Typography>
      <Typography sx={[styles.subtitle, { marginBottom: "calc(10px + 2vh)" }]}>
        {strings.closedBetaSub3}
      </Typography>

      <Formik
        initialValues={{
          code: "",
        }}
        onSubmit={(values) => {
          handleBetaCode(values.code);
        }}
        validationSchema={yup.object().shape({
          code: yup.string().required(),
        })}
      >
        {({
          values,
          errors,
          isValid,
          handleSubmit,
          handleChange,
          setFieldTouched,
          touched,
        }) => (
          <Box sx={styles.container}>
            <Box sx={styles.inputContainer}>
              <Typography sx={styles.label}>Code</Typography>
              <Input
                sx={styles.input}
                value={values.code}
                disableUnderline
                type={passwordVisibility ? "password" : "text"}
                name="code"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(event);
                }}
                onClick={() => {
                  if (isNotEmptyString(error)) {
                    setError("");
                  }
                }}
                onKeyUp={(event: any) =>
                  event.key && event.key === "Enter"
                    ? handleSubmit(event.target.value)
                    : null
                }
                onBlur={() => setFieldTouched("code")}
                placeholder={"Code"}
                startAdornment={
                  <InputAdornment position="start">
                    <IconPassword width={20} height={20} fill={Colors.WHITE} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <ButtonBase
                      onClick={handlePasswordVisibility}
                      sx={{
                        borderRadius: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <SecretIcon
                        width={20}
                        height={20}
                        fill={
                          rightIcon === "eye" ? Colors.OLD_PRICE : Colors.WHITE
                        }
                      />
                    </ButtonBase>
                  </InputAdornment>
                }
              />
              {touched.code && (errors.code || isNotEmptyString(error)) && (
                <Typography
                  style={{
                    fontFamily: "Gilroy-Bold",
                    fontSize: normalize(12),
                    color: Colors.ERROR,
                    marginTop: "15px",
                    textAlign: "center",
                  }}
                >
                  {isNotEmptyString(error)
                    ? error
                    : handleError(errors.code, "code")}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: matchesHeight ? "calc(20px + 1vh)" : "20px",
              }}
            >
              <GradientBtn
                disabled={!isValid || errors.code}
                onPress={handleSubmit}
              >
                {strings.continue}
              </GradientBtn>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default BetaBox;
