import React from "react";

import { Outlet } from "react-router-dom";
//import Menu from "../../components/Menu";
import Sidebar from "../../components/Sidebar";

function Home() {
  return (
    <div className="app">
      <Sidebar />
      {/* <Menu /> */}

      <main className="content">
        <Outlet />
      </main>
    </div>
  );
}

export default Home;
