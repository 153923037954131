/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import {
  changeLanguage,
  getLanguage,
  strings,
} from "../../../services/translation";
import { getRouteManager } from "../../../services/routeManager";
import { useNavigate } from "react-router";
import {
  Box,
  ButtonBase,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import BackgroundRow from "../../../components/BackgroundRow";
import {
  columnCenterStyles,
  columnStartStyles,
  columnStyles,
  rowBetweenStyles,
  rowStartStyles,
  rowStyles,
} from "../../../style/flex";
import normalize from "../../../utils/fontSizer";
import { Gilroy } from "../../../style/fonts";
import { Colors } from "../../../style";

import { ReactComponent as IconNarrator } from "../../../assets/icons/icon_narrator.svg";
import { ReactComponent as IconAudio } from "../../../assets/icons/icon_audio.svg";
import SoundManager from "../../../services/SoundManager";
import React from "react";
import { VolumeDown, VolumeUp } from "@mui/icons-material";
import { isNotEmptyString } from "../../../utils/StringHelper";
import Separator from "../../../components/Separator";
import Loading from "../../../components/Loading";

const SettingsScreen = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(getLanguage() === "fr" ? "fr" : "en");
  const [musicSwitch, setMusicSwitch] = useState(
    !SoundManager.getInstance().isMusicMuted
  );
  const [soundsSwitch, setSoundsSwitch] = useState(
    !SoundManager.getInstance().isSfxMuted
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLangModal, setShowLangModal] = useState(false);
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState(null), []);
  const [value, setValue] = React.useState<number>(
    SoundManager.getInstance().getMaxVolume() * 100
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    const val = newValue as number;
    setValue(val);
    localStorage.setItem("volume", JSON.stringify(val / 100));
    SoundManager.getInstance().setMaxVolume(val / 100);
  };

  const toggleSwitch = (v: any) => {
    setMusicSwitch((previousState) => !previousState);
    if (v === true) {
      SoundManager.getInstance().unmuteTheme();
      SoundManager.getInstance().unmuteMusic();
      localStorage.setItem("isMusicMuted", JSON.stringify(false));
    } else {
      SoundManager.getInstance().muteTheme();
      SoundManager.getInstance().muteMusic();
      localStorage.setItem("isMusicMuted", JSON.stringify(true));
    }
  };

  const toggleSoundsSwitch = (v: any) => {
    setSoundsSwitch((previousState) => !previousState);
    if (v === true) {
      SoundManager.getInstance().unmuteSfx();
      localStorage.setItem("isSfxMuted", JSON.stringify(false));
    } else {
      SoundManager.getInstance().muteSfx();
      localStorage.setItem("isSfxMuted", JSON.stringify(true));
    }
  };

  const confirmDeletion = async () => {
    try {
      setLoading(true);
      const res = await askForAccountDeletion();
      if (res && res.flashmessage) {
        if (res.flashmessage === "success") {
          setShowValidate(false);
          setShowConfirm(false);
          SoundManager.getInstance().playCloseModalSound();
          await logout();
        } else {
          setLoading(false);
          setErrorMsg(strings.deleteAccountError);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg(strings.deleteAccountError);
    }
  };

  const askForAccountDeletion = async (): Promise<any> => {
    return await getRouteManager().sendAskForAccountDeletion();
  };

  useEffect(() => {
    /* if (checked === 'first') {
      changeLanguage('en');
    }
    if (checked === 'second') {
      changeLanguage('fr');
    } */
    forceUpdate();
  }, [checked, forceUpdate]);

  const logout = async () => {
    SoundManager.getInstance().stopTheme();
    SoundManager.getInstance().removeAllSounds();
    localStorage.setItem("login_token", "");
    setErrorMsg("");
    setLoading(false);
    navigate("/login", { replace: true });
  };

  const changeLang = async (lang: string) => {
    setLoading(true);
    await changeLanguage(lang, true);
    setLoading(false);
    setShowLangModal(true);
  };

  const resetApp = () => {
    setShowLangModal(false);
    navigate("/", { replace: true });
  };

  const handleLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked((event.target as HTMLInputElement).value);
    changeLang((event.target as HTMLInputElement).value);
  };

  return (
    <BackgroundRow
      title={strings.options}
      loading={loading}
      goBack
      goBackAction={() => navigate(-1)}
      animType="normal"
      rightPart={
        <Box sx={styles.container}>
          <Box sx={styles.titleBox}>
            <IconNarrator width={28} height={28} fill={Colors.WHITE} />
            <Typography sx={styles.categoryTitle}>
              {strings.language}
            </Typography>
          </Box>
          <Box sx={styles.languages}>
            <RadioGroup
              aria-labelledby="select-lang-buttons-group"
              value={checked}
              onChange={handleLangChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="fr"
                control={
                  <Radio
                    sx={{
                      color: Colors.ACCENT,
                      "&.Mui-checked": {
                        color: Colors.ACCENT,
                      },
                    }}
                  />
                }
                label={<Typography sx={styles.label}>Français</Typography>}
              />
              <FormControlLabel
                value="en"
                control={
                  <Radio
                    sx={{
                      color: Colors.ACCENT,
                      "&.Mui-checked": {
                        color: Colors.ACCENT,
                      },
                    }}
                  />
                }
                label={<Typography sx={styles.label}>English</Typography>}
              />
            </RadioGroup>
          </Box>
          <Box sx={styles.separator} />
          <Box sx={styles.titleBox}>
            <IconAudio width={28} height={28} fill={Colors.WHITE} />
            <Typography sx={styles.categoryTitle}>Audio</Typography>
          </Box>
          <Box sx={styles.audioBox}>
            <Typography style={styles.label}>{strings.music}</Typography>
            <Switch
              sx={{
                color: musicSwitch ? Colors.ACCENT : "#767577",
                "& .MuiSwitch-thumb": {
                  backgroundColor: musicSwitch ? "#f4f3f4" : "#f4f3f4",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: musicSwitch
                    ? Colors.ACCENT + "!important"
                    : "#767577" + "!important",
                  opacity: musicSwitch ? 0.9 + "!important" : 0.5,
                },
              }}
              onChange={() => toggleSwitch(!musicSwitch)}
              checked={musicSwitch}
            />
          </Box>
          <Box sx={styles.audioBox}>
            <Typography style={styles.label}>{strings.sounds}</Typography>
            <Switch
              sx={{
                color: soundsSwitch ? Colors.ACCENT : "#767577",
                "& .MuiSwitch-thumb": {
                  backgroundColor: soundsSwitch ? "#f4f3f4" : "#f4f3f4",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: soundsSwitch
                    ? Colors.ACCENT + "!important"
                    : "#767577" + "!important",
                  opacity: soundsSwitch ? 0.9 + "!important" : 0.5,
                },
              }}
              onChange={() => toggleSoundsSwitch(!soundsSwitch)}
              checked={soundsSwitch}
            />
          </Box>
          <Box sx={styles.sliderBox}>
            <Typography style={styles.label}>{strings.volume}</Typography>
            <Stack spacing={2} direction="row" alignItems="center">
              <VolumeDown sx={{ color: Colors.WHITE }} />

              <Slider
                valueLabelDisplay="auto"
                aria-label="Volume"
                value={value}
                onChange={handleChange}
                sx={{
                  color: Colors.ACCENT,
                  height: 8,
                  "& .MuiSlider-track": {
                    border: "none",
                  },
                  "& .MuiSlider-thumb": {
                    height: 20,
                    width: 20,
                    backgroundColor: "#fff",
                    /* border: "2px solid currentColor", */
                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                      boxShadow: "inherit",
                    },
                    "&:before": {
                      display: "none",
                    },
                  },
                  "& .MuiSlider-valueLabel": {
                    lineHeight: 1.2,
                    fontSize: 12,
                    fontFamily: Gilroy,
                    background: "unset",
                    padding: 0,
                    width: 32,
                    height: 32,
                    borderRadius: "50% 50% 50% 0",
                    backgroundColor: Colors.ACCENT,
                    transformOrigin: "bottom left",
                    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
                    "&:before": { display: "none" },
                    "&.MuiSlider-valueLabelOpen": {
                      transform:
                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                    },
                    "& > *": {
                      transform: "rotate(45deg)",
                    },
                  },
                }}
              />
              <VolumeUp sx={{ color: Colors.WHITE }} />
            </Stack>
          </Box>
          <ButtonBase
            sx={{ ...columnStyles, marginTop: "40px" }}
            onClick={() => {
              setShowConfirm(true);
              SoundManager.getInstance().playOpenModalSound();
            }}
          >
            <Typography sx={styles.deleteAccountText}>
              {strings.deleteAccount}
            </Typography>
          </ButtonBase>
          <Modal
            open={showConfirm}
            onClose={() => {
              setShowValidate(false);
              setShowConfirm(false);
              setErrorMsg("");
              SoundManager.getInstance().playCloseModalSound();
            }}
          >
            <Box sx={styles.modalView}>
              {showValidate ? (
                <Box>
                  <Typography sx={styles.deleteTitle}>
                    {strings.deleteAccount}
                  </Typography>

                  {loading && <Loading color={Colors.ACCENT} />}

                  {!loading && isNotEmptyString(errorMsg) ? (
                    <Box sx={{ ...columnCenterStyles }}>
                      <Typography sx={styles.deleteSubtitleError}>
                        {errorMsg}
                      </Typography>
                      <Box sx={[styles.popupButtons2]}>
                        <ButtonBase
                          style={styles.noButton}
                          onClick={() => {
                            setShowValidate(false);
                            setShowConfirm(false);
                            setErrorMsg("");
                            SoundManager.getInstance().playCloseModalSound();
                          }}
                        >
                          <Typography sx={styles.noText}>
                            {strings.back}
                          </Typography>
                        </ButtonBase>
                      </Box>
                    </Box>
                  ) : null}
                  {!loading && !isNotEmptyString(errorMsg) ? (
                    <Box sx={{ ...columnCenterStyles }}>
                      <Typography sx={styles.deleteSubtitle} margin={"20px 0px"}>
                        {strings.deleteAccountConfirm}
                      </Typography>
                      <Separator />
                      <Box sx={[styles.popupButtons]}>
                        <ButtonBase
                          sx={styles.noButton}
                          onClick={() => {
                            setShowValidate(false);
                            setShowConfirm(false);
                            SoundManager.getInstance().playCloseModalSound();
                          }}
                        >
                          <Typography sx={styles.noText}>
                            {strings.back}
                          </Typography>
                        </ButtonBase>
                        <ButtonBase
                          sx={styles.yesButton}
                          onClick={() => {
                            confirmDeletion();
                          }}
                        >
                          <Typography sx={styles.yesText}>
                            {strings.confirm}
                          </Typography>
                        </ButtonBase>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              ) : (
                <Box sx={{ ...columnCenterStyles }}>
                  <Typography sx={styles.deleteTitle}>
                    {strings.deleteAccount}
                  </Typography>
                  <Typography sx={styles.deleteSubtitle} margin={"20px 0px"}>
                    {strings.deleteAccountInfo}
                  </Typography>
                  <Separator />
                  <Box sx={styles.popupButtons}>
                    <ButtonBase
                      sx={[styles.noButton]}
                      onClick={() => {
                        setShowValidate(false);
                        setShowConfirm(false);
                        SoundManager.getInstance().playCloseModalSound();
                      }}
                    >
                      <Typography sx={styles.noText}>{strings.back}</Typography>
                    </ButtonBase>
                    <ButtonBase
                      sx={[styles.yesButton]}
                      onClick={() => {
                        setShowValidate(true);
                        SoundManager.getInstance().playBtnSound();
                      }}
                    >
                      <Typography sx={styles.yesText}>
                        {strings.confirm}
                      </Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={showLangModal} sx={styles.centeredView}>
            <Box sx={styles.modalView}>
              <Typography
                sx={[
                  styles.deleteTitle,
                  { color: Colors.WHITE, marginBottom: "20px" },
                ]}
              >
                {strings.changeLang}
              </Typography>
              <Typography
                sx={[
                  styles.deleteSubtitle,
                  { padding: 0, marginBottom: "20px" },
                ]}
              >
                {strings.resetLang}
              </Typography>
              <Box sx={styles.separator2} />
              <Box sx={styles.popupButtons}>
                <ButtonBase
                  sx={[styles.yesButton, { backgroundColor: Colors.ACCENT }]}
                  onClick={() => {
                    resetApp();
                  }}
                >
                  <Typography sx={styles.yesText}>{strings.okay}</Typography>
                </ButtonBase>
              </Box>
            </Box>
          </Modal>
        </Box>
      }
    />
  );
};

const styles = {
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
    width: "370px",
  },
  titleBox: {
    ...rowStyles,
    alignItems: "center",
  },
  categoryTitle: {
    fontSize: normalize(20),
    fontFamily: Gilroy,
    color: Colors.WHITE,
    marginLeft: "14px",
  },
  languages: {
    ...rowStartStyles,
    marginTop: "34px",
    color: Colors.WHITE,
    fontFamily: Gilroy,
    fontSize: normalize(18),
  },
  label: {
    color: Colors.BTN_INACTIVE,
    fontFamily: Gilroy,
    fontSize: "18px",
  },
  separator: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
    margin: "25px 0px",
  },
  audioBox: {
    ...rowBetweenStyles,
    marginTop: "10px",
    width: "160px",
  },
  sliderBox: {
    ...columnStyles,
    marginTop: "10px",
    width: "250px",
  },
  centeredView: {
    flex: 1,
    ...columnCenterStyles,
  },
  modalView: {
    width: "300px",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    padding: "45px 45px 0px 45px",
    ...columnStyles,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  separator2: {
    width: "300px",
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
  },
  popupButtons: {
    width: "300px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop: "25px",
    marginBottom: "25px",
  },
  popupButtons2: {
    width: "300px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop: "25px",
  },
  yesButton: {
    backgroundColor: Colors.ERROR,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
  deleteTitle: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.ERROR,
    textAlign: "center",
    marginBottom: "5px",
  },
  deleteSubtitle: {
    fontSize: normalize(13),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
    padding: "0px 20px",
  },
  deleteSubtitleError: {
    fontSize: normalize(13),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
    padding: "0px 20px",
    marginTop: "-15px",
    width: "275px",
  },
  deleteAccountText: {
    color: Colors.PROMO,
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
  },
};

export default SettingsScreen;
