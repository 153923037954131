/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase } from "@mui/material";
import React from "react";
import { Colors } from "../../style";
import { ReactComponent as BackIcon } from "../../assets/icons/icon_back.svg";
import SoundManager from "../../services/SoundManager";

interface Props {
  callback: any;
  style?: any;
  extraStyle?: any;
}

const GoBackBtn = (props: Props) => {
  const { callback, style, extraStyle } = props;

  return (
    <ButtonBase
      onClick={() => {
        SoundManager.getInstance().playBtnSound();
        callback();
      }}
      sx={[styles.btn, style ? style : {}, extraStyle ? extraStyle : {}]}
    >
      <BackIcon width={60} height={60} fill={Colors.ACCENT} />
    </ButtonBase>
  );
};

const styles = {
  btn: {
    width: "61px",
    height: "61px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default GoBackBtn;
