/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router";
import useAuth from "../../services/Auth/useAuth";
import { Colors } from "../../style";
import { columnStartStyles, rowStyles } from "../../style/flex";
import { Eczar, Gilroy } from "../../style/fonts";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import { Box } from "@mui/material";
import LoadingView from "../../components/LoadingView";
import PregameModal from "../../components/PregameModal";
import StoryInfoModal from "../../components/StoryInfoModal";
import SoundManager from "../../services/SoundManager";
import "./style.css";
import BackgroundColumn from "../../components/BackgroundColumn";
import Carousel from "../../components/CarouselLibrary";
import { SidebarContext } from "../../utils/ContextsHelper";
import StoryCarousel from "../../components/StoryCarousel";
import FTUEModals from "../../components/FTUEModals";
import { FTUE_MODALS } from "../../constants";

const LibraryNext = () => {
    const { targetId } = useParams();
    const location: any = useLocation();
    const { state } = location;
    const auth = useAuth();
    const { sidebarCollapsed } = useContext(SidebarContext);

    /*  STATES */

    //const [library, updateLibrary] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState<any>(null);
    const [screenWidth, setScreenWidth] = useState(
        sidebarCollapsed ? window.innerWidth - 78 : window.innerWidth - 268,
    );
    const [showStoryInfo, setShowStoryInfo] = useState<boolean>(false);
    const [showEpisodesModal, setShowEpisodesModal] = useState(false);
    const [featured, setFeatured] = useState<any>(null);
    const [series, setSeries] = useState<any>(null);
    const [stories, setStories] = useState<any>(null);

    /* CAROUSEL PROPS */

    const aspectRatioCarousel = 1 / 3;

    /* USE EFFECTS */

    useEffect(() => {
        setupData();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", () => handleResize(sidebarCollapsed));
        return () => {
            window.removeEventListener("resize", () =>
                handleResize(sidebarCollapsed),
            );
        };
    }, [sidebarCollapsed]);

    useEffect(() => {
        handleResize(sidebarCollapsed);
    }, [sidebarCollapsed]);

    useEffect(() => {
        if (!loading && stories && series) {
            if (state) {
                const { seriesParam, shouldShowEpisodes } = state;
                if (seriesParam && shouldShowEpisodes) {
                    const tmp = series.find(
                        (item: any) => item.id && item.id === seriesParam.id,
                    );
                    if (tmp) {
                        setActive(tmp);
                        setShowEpisodesModal(true);
                    }
                }
            } else if (targetId) {
                findAndActiveStory(targetId);
            }
            SoundManager.getInstance().playTheme();
        }
    }, [loading]);

    /* FUNCTIONS */

    const handleResize = (collapsed: boolean) => {
        setScreenWidth(
            sidebarCollapsed ? window.innerWidth - 78 : window.innerWidth - 268,
        );
    };

    const calculateCarouselWidth = () => {
        const width = screenWidth * 0.9 > 1432 ? 1432 : screenWidth * 0.9;
        const height = width * aspectRatioCarousel;
        return { width: width, height: height };
    };

    const findAndActiveStory = (targetId: string) => {
        if (stories) {
            const item = stories.find(
                (item: any) => item.id === parseInt(targetId),
            );
            if (item) {
                setActive(item);
                setShowStoryInfo(true);
            } else {
                if (series) {
                    const itemSeries = series.find(
                        (item: any) => item.id === parseInt(targetId),
                    );
                    if (itemSeries) {
                        setActive(itemSeries);
                        setShowStoryInfo(true);
                    }
                }
            }
        }
    };

    const setupData = async () => {
        try {
            if (auth && auth.user) {
                const apiUrl = getRouteManager().getFeaturedLibrary();
                const data = await getRouteManager().fetchData(apiUrl, {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                });
                data.stories.map((story: any) => {
                    story.image_backdrop = getRouteManager().cover(story.id);
                    story.image_title = getRouteManager().titleForStory(
                        getLanguage(),
                        story.id,
                    );
                    story.owned =
                        auth?.user?.pass?.isActive ||
                        (story.userStories && story.userStories.length)
                            ? true
                            : false;
                    if (story.owned) {
                        story.price = 0;
                    }
                });
                data.series.map((series: any) => {
                    series.image_backdrop = getRouteManager().getCover(
                        series.idCover,
                    );
                    series.image_title = getRouteManager().titleForSeries(
                        getLanguage(),
                        series.id,
                    );
                    if (series && series.seriesEpisodes) {
                        let owned = 0;
                        series.hasLast = false;
                        if (
                            auth &&
                            auth?.user &&
                            auth?.user?.pass &&
                            auth?.user?.pass?.isActive
                        ) {
                            owned = series.seriesEpisodes.length;
                        } else {
                            series.seriesEpisodes.forEach((ep: any) => {
                                if (
                                    ep.story.userStories &&
                                    ep.story.userStories.length
                                ) {
                                    owned++;
                                }
                                if (
                                    ep.isLast &&
                                    !(
                                        getLanguage() === "fr" && ep.releaseDate
                                    ) &&
                                    !(
                                        getLanguage() !== "fr" &&
                                        ep.releaseDateEn
                                    )
                                ) {
                                    series.hasLast = true;
                                }
                            });
                        }

                        series.isSeries = true;
                        series.ownedEpisodes = owned;
                        series.totalEpisodes = series.seriesEpisodes.length;
                    }
                });
                data.stories && setStories(data.stories);
                data.series && setSeries(data.series);
                data?.featured[0]?.featuredCards?.length &&
                    setFeatured(data.featured[0].featuredCards);
                setLoading(false);
            } else {
                window.location.href = "/login";
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onSelectStory = (story: any) => {
        SoundManager.getInstance().playOpenModalSound();
        setActive(story);
        setShowStoryInfo(true);
    };

    const onCloseStoryInfo = () => {
        SoundManager.getInstance().playCloseModalSound();
        setShowStoryInfo(false);
        setActive(null);
    };

    /* RENDER */

    if (loading) {
        return <LoadingView />;
    } else if (active && showEpisodesModal) {
        return (
            <Box className="library" id="library">
                <PregameModal
                    active={active}
                    isSeries={!!active.isSeries}
                    callback={setShowEpisodesModal}
                    showEpisodesModal={showEpisodesModal}
                />
            </Box>
        );
    } else {
        return (
            <BackgroundColumn
                bodyStyle={{ width: "100%" }}
                contentStyle={{ width: "100%", maxWidth: "none" }}
                content={
                    <Box sx={styles.mainContainer}>
                        <Box sx={styles.container}>
                            {/* CAROUSEL START */}
                            {featured && featured.length ? (
                                <Carousel
                                    cards={featured}
                                    //style={{ margin: "30px 0px" }}
                                    cardWidth={calculateCarouselWidth().width}
                                    cardHeight={calculateCarouselWidth().height}
                                    screenWidth={screenWidth}
                                    findStory={findAndActiveStory}
                                />
                            ) : null}

                            {/* CAROUSEL END */}

                            <StoryCarousel
                                stories={series}
                                fullscreenWidth={screenWidth}
                                screenWidth={calculateCarouselWidth().width}
                                title={strings.seriess}
                                type="series"
                                onSelectStory={onSelectStory}
                            />
                            <StoryCarousel
                                stories={stories}
                                fullscreenWidth={screenWidth}
                                screenWidth={calculateCarouselWidth().width}
                                title={strings.stories}
                                type="stories"
                                onSelectStory={onSelectStory}
                            />
                            <Box sx={{ height: "50px" }} />
                        </Box>

                        {active ? (
                            <StoryInfoModal
                                active={active}
                                showStoryInfo={showStoryInfo}
                                onCloseStoryInfo={onCloseStoryInfo}
                                //callback={setShowEpisodesModal}
                            />
                        ) : null}
                        <FTUEModals modal={FTUE_MODALS.LIBRARY} />
                    </Box>
                }
            />
        );
    }
};

const styles = {
    mainContainer: {
        ...columnStartStyles,
        position: "relative",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        zIndex: 2,
    },
    container: {
        position: "relative",
        paddingTop: "70px",
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
            width: "0px",
        },
    },
    headerTitle: {
        fontSize: "calc(40px + 1vh)",
        fontFamily: Eczar.regular,
        color: Colors.ACCENT,
    },
    headerSubtitle: {
        fontSize: "calc(14px + 0.63vh)",
        fontFamily: Gilroy,
        color: Colors.WHITE,
        margin: "30px 0px 15px",
    },
    storiesContainer: {
        ...rowStyles,
        alignSelf: "center",
        flexWrap: "wrap",
        alignContent: "flex-start",
        gap: "16px",
        minWidth: "404px",
        maxWidth: "710px",
        height: "100%",
    },
};

export default LibraryNext;
