/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Colors } from "../../style";
import "./style.css";
import { Box } from "@mui/material";

interface Props {
  color?: string;
  size?: any;
  width?: any;
  circular?: boolean;
}

const Loading = (props: Props) => {
  const { color = Colors.ACCENT, size, width, circular } = props;

  const renderLoader = () => {
    if (circular) {
      return (
        <div className="lds-ellipsis">
          <div style={color ? { background: color } : {}}></div>
          <div style={color ? { background: color } : {}}></div>
          <div style={color ? { background: color } : {}}></div>
          <div style={color ? { background: color } : {}}></div>
        </div>
      );
    } else {
      if (size && size === "small") {
        return (
          <div className="lds-ellipsis-small">
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
          </div>
        );
      } else if (size && size === "normal") {
        return (
          <div className="lds-ellipsis-normal">
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
          </div>
        );
      } else {
        return (
          <div className="lds-ellipsis">
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
            <div style={color ? { background: color } : {}}></div>
          </div>
        );
      }
    }
  };
  return (
    <Box sx={[styles.container, width ? { width: width } : {}]}>
      {renderLoader()}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
};
export default Loading;
