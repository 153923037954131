/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import { itemBackgroundJson } from "../../constants/itemBackground";
import { rewardTicketJson } from "../../constants/rewardTicket";
import { rewardTitleJson } from "../../constants/rewardTitle";
import { tokensAnim } from "../../constants/tokens";
import { getRouteManager } from "../../services/routeManager";
import { strings, getLanguage } from "../../services/translation";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import CoverTitleSmallFlexible from "../coverTitleSmallFlexible";
import { Player } from "@lottiefiles/react-lottie-player";
import { columnCenterStyles, columnStyles, rowStartStyles } from "../../style/flex";
import { logoPass } from "../../constants/logoPass";
import GradientText from "../GradientText";

interface Props {
  reward: any;
}

const RewardPromoCode = (props: Props) => {
  const { reward } = props;

  return (
    <Box
      sx={[
        styles.skinBtn,
        reward.type === "story" ? { height: "100px" } : { height: "80px" },
      ]}
    >
      {reward.type === "tickets" && (
        <>
          <Box sx={styles.skinBtnImgTickets}>
            <Player src={rewardTicketJson} autoplay loop speed={1.5} style={{
              width: "60px",
              height: "60px",
            }} />
          </Box>
          <Box>
            {reward.tickets && (
              <Box sx={styles.skinBtnTitleBlock}>
                <Typography
                  sx={[styles.skinBtnTitle, { color: Colors.TICKET }]}
                >
                  {reward.tickets} {strings.tickets}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {reward.type === "tokens" && (
        <>
          <Box sx={styles.skinBtnImgTickets}>
            <Player src={tokensAnim} autoplay loop speed={1.5} style={{
              width: "60px",
              height: "60px",
            }} />
          </Box>
          <Box>
            {reward.tokens && (
              <Box sx={styles.skinBtnTitleBlock}>
                <Typography sx={[styles.skinBtnTitle, { color: Colors.TOKEN }]}>
                  {reward.tokens} {strings.tokens}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {reward.type === "item" && (
        <>
          <Box sx={styles.skinBtnImg}>
            <Player
              src={itemBackgroundJson}
              autoplay
              loop
              speed={1.5}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "60px",
                height: "60px",
              }}
            />
            <img
              src={getRouteManager().getItemImageForVersion(
                reward.item.id,
                "promo"
              )}
              style={{
                position: "absolute",
                width: "60px",
                height: "60px",
                borderRadius: "60px",
              }}
            />
          </Box>
          <Box>
            {reward.item?.name && (
              <Box sx={styles.skinBtnTitleBlock}>
                <Typography style={styles.skinBtnTitle}>
                  {reward.item.name}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {reward.type === "title" && (
        <>
          <Box sx={styles.skinBtnImgTickets}>
            <Player src={rewardTitleJson} autoplay loop speed={1.5} style={{
              width: "60px",
              height: "60px",
            }} />
          </Box>
          <Box>
            {reward.title?.name && (
              <Box sx={styles.skinBtnTitleBlock}>
                <Typography
                  sx={[styles.skinBtnTitle, { color: Colors.OLD_PRICE }]}
                >
                  {strings.title}
                </Typography>
                <Typography style={styles.skinBtnTitle}>
                  {reward.title.name}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {reward.type === "story" && (
        <>
          <Box style={styles.skinBtnImgStory}>
            <CoverTitleSmallFlexible
              cover={
                reward.story.type === "episode" && reward.story.episode
                  ? `${getRouteManager().getCover(
                    reward.story.episode.idCover
                  )}`
                  : getRouteManager().cover(reward.story.id)
              }
              title={getRouteManager().titleForStory(
                getLanguage(),
                reward.story.id
              )}
              width={50}
              height={79}
            />
          </Box>
          <Box>
            {reward.story?.title && (
              <Box sx={styles.skinBtnTitleBlock}>
                {reward.story.type === "episode" ? (
                  <>
                    <Typography style={styles.skinBtnTitle}>
                      {reward.story.title}
                    </Typography>
                    <Typography
                      sx={[
                        styles.skinBtnTitle,
                        { color: Colors.OLD_PRICE, fontSize: normalize(12) },
                      ]}
                    >
                      {strings.episode}{" "}
                      {reward.story.episode && reward.story.episode.number}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography style={styles.skinBtnTitle}>
                      {reward.story.title}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
      {reward.type === "pass" && (
        <>
          <Box sx={styles.skinBtnImgTickets}>
            <Player src={logoPass} autoplay loop speed={1.5} style={{
              width: "60px",
              height: "60px",
            }} />
          </Box>
          <Box>
            <Box sx={styles.skinBtnTitleBlock}>
              <GradientText
                color1={Colors.PASS1}
                color2={Colors.PASS2}
                style={styles.skinBtnTitle}>
                {strings.pass.adventurerPass}
              </GradientText>
              <Typography
                sx={[styles.skinBtnTitle, { color: Colors.OLD_PRICE, fontSize: normalize(10) }]}
              >
                {strings.pass.reloadPage}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const styles = {
  skinBtn: {
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
    width: "100%",
    minHeight: "80px",
    marginBottom: "10px",
    ...rowStartStyles,
    alignSelf: "center",
    padding: "10px",
  },
  skinBtnImg: {
    width: "60px",
    height: "60px",
    borderRadius: "60px",
    backgroundColor: Colors.FOND_ITEM_POPUP,
    ...columnCenterStyles,
    position: "relative",
  },
  skinBtnImgTickets: {
    width: "60px",
    height: "60px",
    borderRadius: "60px",
    display: "flex",
    ...columnStyles,
    justifyContent: "center",
    alignItems: "center",
  },
  skinBtnImgStory: {
    width: "70px",
    height: "70px",
    marginTop: "-6px",
    paddingLeft: "5px",
  },
  skinBtnTitleBlock: {
    ...columnStyles,
    justifyContent: "center",
    marginLeft: "10px",
    flex: 1,
  },
  skinBtnTitle: {
    color: Colors.ACCENT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
  },
};

export default RewardPromoCode;
