import { Colors } from "../../style";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowBetweenStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import normalize, { normalizeSize, normalizeVh } from "../../utils/fontSizer";

export const styles = {
    mainContainer: {
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        maxHeight: "calc(96vh - 20px)",
        flex: "1 1 auto",
    },
    mainContainerBig: {
        ...rowCenterStyles,
        alignItems: "flex-start",
    },
    mainContainerSmall: {
        ...columnStyles,
        alignItems: "center",
    },
    container: {
        width: "100%",
        alignItems: "center",
        ...columnStyles,
        justifyContent: "flex-start",
        maxWidth: "450px",
        minWidth: "450px",
        rowGap: "10px",
        paddingTop: "4vh",
        paddingBottom: "50px",
        flex: "0 0 auto",
    },
    containerSmall: {
        width: "100%",
        ...columnStartStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
        flex: "0 0 auto",
    },
    topMenuTitle: {
        width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "20px",
    },
    playerCode: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: normalize(16),
        ...rowStyles,
        alignItems: "center",
        marginBottom: normalizeSize(10),
    },
    separatorLeft: {
        width: normalizeSize(300),
        backgroundColor: Colors.BTN_ACTIVE,
        marginTop: normalizeSize(20),
    },
    right: {
        ...columnStyles,
        //flex: 1,
        justifyContent: "flex-start",
        maxWidth: normalizeSize(450),
        minWidth: normalizeSize(450),
        paddingTop: normalizeVh(4),
        paddingBottom: normalizeSize(50),
        rowGap: normalizeSize(16),
    },
    //Friend screen start
    list: {
        ...columnStyles,
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        columnGap: "10px",
        maxWidth: normalizeSize(1020),
        width: "100%",
        minWidth: normalizeSize(450),
    },
    listSmall: {
        ...columnStyles,
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: normalizeSize(8),
        maxWidth: normalizeSize(1020),
        width: normalizeSize(300),
    },
    userBox: {
        width: normalizeSize(300),
        minHeight: normalizeSize(80),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(13),
        alignItems: "center",
        flex: 1,
        ...rowStartStyles,
        flexShrink: 0,
        alignSelf: "center",
        columnGap: normalizeSize(12),
        padding: "8px 12px",
    },
    userBoxInside: {
        ...rowStartStyles,
        columnGap: normalizeSize(12),
    },
    positionBox: {
        width: normalizeSize(44),
        height: normalizeSize(44),
        borderRadius: normalizeSize(44),
        backgroundColor: Colors.PRIMARY,
        ...columnCenterStyles,
    },
    positionText: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(20),
        fontWeight: 700,
        color: Colors.OLD_PRICE,
        position: "relative",
        left: normalizeSize(2),
    },
    separator: {
        backgroundColor: Colors.FOND_ITEM_POPUP,
        width: normalizeSize(2),
        height: normalizeSize(50),
        borderRadius: normalizeSize(2),
    },
    separatorYou: {
        backgroundColor: Colors.ACCENT,
        width: normalizeSize(2),
        height: normalizeSize(50),
        borderRadius: normalizeSize(2),
    },
    userText: {
        ...columnStyles,
        justifyContent: "center",
        alignItems: "flex-start",
        rowGap: normalizeSize(4),
    },
    userPseudo: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(16),
        lineHeight: normalizeSize(16),
        fontWeight: 600,
        color: Colors.WHITE,
        textAlign: "left",
    },
    userTitle: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        lineHeight: normalizeSize(12),
        fontWeight: 600,
        color: Colors.ACCENT,
        textAlign: "left",
    },
    levelPart: {
        ...rowStartStyles,
        columnGap: normalizeSize(4),
    },
    levelCircle: {
        width: normalizeSize(25),
        height: normalizeSize(25),
        borderRadius: normalizeSize(25),
        //border: `solid 2px ${Colors.ACCENT}`,
        ...columnCenterStyles,
        backgroundColor: Colors.PRIMARY,
        zIndex: 10,
    },
    levelText: {
        fontSize: normalizeSize(12),
        lineHeight: normalizeSize(12),
        fontFamily: Gilroy,
        color: Colors.ACCENT,
    },
    xp: {
        fontSize: normalizeSize(12),
        lineHeight: normalizeSize(12),
        fontFamily: Gilroy,
        color: Colors.WHITE,
    },
    // ladder screen end
};
