/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import { db } from "../utils/FirebaseHelper";
import { FIREBASE_DATABASE } from "../constants";
import { isBoolean } from "lodash";
import { isFunction, isNumber } from "../utils/TypeOfHelper";
import { isNotEmptyArray } from "../utils/ArrayHelper";
import { isNotEmptyString } from "../utils/StringHelper";

// =======================================================

const handleRequest = (uri: string, value: any, callback: any = null): void => {
  if (isNotEmptyString(uri)) {
    db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${uri}`)
      .set(value)
      .then(() => {
        if (isFunction(callback)) {
          callback(true);
        }
      })
      .catch(() => {
        if (isFunction(callback)) {
          callback(false);
        }
      });
  } else {
    if (isFunction(callback)) {
      callback(false);
    }
  }
};

// =======================================================

export const onAddRandomPlayerID = (
  id: number | null,
  gameID: string | null
): void => {
  if (isNumber(id) && isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/story/randomPlayerID`, id);
  }
};

// =======================================================

export const onAddSecretPlayerID = (
  id: number | null,
  gameID: string | null
): void => {
  if (isNumber(id) && id !== -1 && isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/story/secretPlayerID`, id);
  }
};

// =======================================================

export const onRemoveSecretPlayerID = (gameID: string | null): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/story/secretPlayerID`, "");
  }
};

// =======================================================

export const onAddSoloPlayerID = (
  id: number | null,
  gameID: string | null
): void => {
  if (isNumber(id) && isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/story/soloPlayerID`, id);
  }
};

// =======================================================

export const onRemoveSoloPlayerID = (gameID: string | null): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/story/soloPlayerID`, "");
  }
};

// =======================================================

export const onRemoveRandomPlayerID = (gameID: string | null): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/story/randomPlayerID`, "");
  }
};

// =======================================================

export const onRemoveHasPlayedFeedbackAnimation = (
  gameID: string | null,
  callback: any = null
): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/hasPlayedFeedbackAnimation`, [], callback);
  }
};

// =======================================================

export const onRemoveHasChangedScene = (
  gameID: string | null,
  callback: any = null
): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/hasChangedScene`, [], callback);
  }
};

// =======================================================

export const onRemoveShouldPlayedFeedbackAnimation = (
  gameID: string | null,
  callback: any = null
): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/shouldPlayedFeedbackAnimation`, null, callback);
  }
};

// =======================================================

export const onUpdateShouldPlayedFeedbackAnimation = (
  value: any,
  gameID: string | null
): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/shouldPlayedFeedbackAnimation`, value);
  }
};

// =======================================================

export const onUpdateScene = (value: any, gameID: string | null): void => {
  if (isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/scene`, value);
  }
};

// =======================================================

export const onDeleteGameChoices = (
  gameID: string | null | undefined
): void => {
  if (isNotEmptyString(gameID)) {
    db.ref(`/${FIREBASE_DATABASE.REFERENCES.CHOICES}/${gameID}`).remove();
  }
};

// =======================================================

export const onDeleteGameChat = (
  gameID: string | null | undefined
): void => {
  if (isNotEmptyString(gameID)) {
    db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAME_CHATS}/${gameID}`).remove();
  }
};

// =======================================================


export const onUpdateIsTheEnd = (
  value: boolean,
  gameID: string | null
): void => {
  if (isBoolean(value) && isNotEmptyString(gameID)) {
    handleRequest(`${gameID}/isTheEnd`, value);
  }
};

// =======================================================

export const onDeletePlayersLogout = (
  gameID: string | null | undefined
): void => {
  if (isNotEmptyString(gameID)) {
    db.ref(
      `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_LOGOUT}/${gameID}`
    ).remove();
  }
};

// =======================================================

export const onDeletePlayersExcluded = (
  gameID: string | null | undefined
): void => {
  if (isNotEmptyString(gameID)) {
    db.ref(
      `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_EXCLUDED}/${gameID}`
    ).remove();
  }
};

// =======================================================

export const onDeletePlayers = (gameID: string | null | undefined): void => {
  if (isNotEmptyString(gameID)) {
    db.ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`).remove();
  }
};

// =======================================================

export const onAddHasChangedScene = async (
  value: string | null,
  gameID: string | null
) => {
  return handleAddPlayerToArray(value, gameID, "hasChangedScene");
};

// =======================================================

/* export const onAddHasPlayedFeedbackAnimation = async (
  value: string | null,
  gameID: string | null
): Promise<boolean> => {
  return handleAddPlayerToArray(value, gameID, "hasPlayedFeedbackAnimation");
}; */

export const onAddHasPlayedFeedbackAnimation = async (
  value: boolean,
  gameID: string | null
): Promise<void> => {
  return handleRequest(`${gameID}/hasPlayedFeedbackAnimation`, value)
};

// =======================================================

const handleAddPlayerToArray = async (
  value: string | null,
  gameID: string | null,
  property: string | null
): Promise<boolean> => {
  let hasPushed = false;

  if (
    isNotEmptyString(value) &&
    isNotEmptyString(gameID) &&
    isNotEmptyString(property)
  ) {
    const reference = db.ref(
      `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/${property}`
    );
    hasPushed = await reference
      .once("value")
      .then((snapshot: any): Promise<boolean> => {
        const playerKeys: any = _(snapshot.val()).values();
        const asArray = [...playerKeys];

        if (!asArray.includes(value)) {
          const newReference = reference.push(value);

          if (isNotEmptyString(newReference.key)) {
            return Promise.resolve(true);
          }
        }

        return Promise.resolve(false);
      });
  }

  return hasPushed;
};

// =======================================================

export const onSaveVariablesAndPlayers = (
  gameID: string | null,
  vars: Array<any>,
  playersInGame: Array<any>,
  informations?: Array<any>,
  currentObjective?: any,
  visitedScenes?: Array<any>
): void => {
  if (
    isNotEmptyString(gameID) &&
    isNotEmptyArray(vars) &&
    isNotEmptyArray(playersInGame)
  ) {
    const data = {
      variables: vars,
      players: playersInGame,
      informations: isNotEmptyArray(informations) ? informations : [],
      currentObjective: isNotEmptyString(currentObjective)
        ? currentObjective
        : "",
      visitedScenes: isNotEmptyArray(visitedScenes) ? visitedScenes : [],
    };

    handleRequest(`${gameID}/storyReader`, JSON.stringify(data));
  }
};

// =======================================================

export const onUnsubscribeHasPlayedFeedbackAnimationRef = (
  gameID: string | null | undefined
): void => {
  if (isNotEmptyString(gameID)) {
    // calling off() on the location with no arguments removes all listeners
    // at that location
    db.ref(
      `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasPlayedFeedbackAnimation`
    ).off("value");
  }
};

// =======================================================
