import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import {
  columnCenterStyles,
  columnStyles,
  rowBetweenStyles,
  rowEndStyles,
} from "../../style/flex";

// =======================================================

export default {
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...columnCenterStyles,
    zIndex: 10,
  },
  iconAndName: {
    marginTop: "5px",
    ...rowBetweenStyles,
  },
  letter: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    borderStyle: "solid",
    borderWidth: "2px",
    ...columnCenterStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
  popBg: {
    position: "relative",
    //top: 30,
    alignSelf: "center",
    display: "flex",
    zIndex: 999,
    width: "100%",
    height: "92px",
    marginBottom: "10px",
    paddingLeft: "10px",
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.BTN_ACTIVE,
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
    overflow: "hidden",
  },
  imageBackground: {
    width: "137px",
    height: "137px",
    maxWidth: "137px",
    maxHeight: "137px",
    position: "absolute",
    right: "-50px",
    bottom: '-22.5px',
    overflow: "hidden",
    alignSelf: "flex-end",
    ...columnStyles,
    justifyContent: "center",
  },
  normalText: {
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
  },
  rightPart: {
    ...columnStyles,
    justifyContent: "center",
    marginLeft: "15px",
    flexShrink: 1,
  },
  buyAllLeftAvatar: {
    width: "92px",
    position: "absolute",
    right: 0,
    overflow: "hidden",
  },
  linearGradient: {
    height: "100%",
    overflow: "hidden",
  },
  containerAvatar: {
    width: "100%",
    height: "92px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.BTN_ACTIVE,
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: "13px",
  },
  buyAllAvatar: {
    width: "100%",
    height: "100%",
    padding: "0px 12px",
    backgroundColor: Colors.BTN_ACTIVE,
    ...rowEndStyles,
    overflow: "hidden",
  },
};

// =======================================================
