export const rewardTitleJson = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 300.00001221925,
  w: 50,
  h: 50,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'UI_reward_title Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [25, 25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.784, 0],
                        [-0.324, -0.711],
                        [0, 0],
                        [0, 0],
                        [-0.242, -0.742],
                        [0.578, -0.526],
                        [0, 0],
                        [0, 0],
                        [0.635, -0.458],
                        [0.681, 0.385],
                        [0, 0],
                        [0, 0],
                        [0.634, 0.46],
                        [-0.158, 0.765],
                        [0, 0],
                        [0, 0],
                        [-0.242, 0.743],
                        [-0.78, 0.088],
                        [0, 0],
                      ],
                      o: [
                        [0.324, -0.711],
                        [0.784, 0],
                        [0, 0],
                        [0, 0],
                        [0.78, 0.087],
                        [0.242, 0.743],
                        [0, 0],
                        [0, 0],
                        [0.158, 0.764],
                        [-0.634, 0.46],
                        [0, 0],
                        [0, 0],
                        [-0.681, 0.386],
                        [-0.635, -0.458],
                        [0, 0],
                        [0, 0],
                        [-0.578, -0.526],
                        [0.242, -0.742],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.819, -8.804],
                        [0, -9.972],
                        [1.819, -8.804],
                        [3.805, -4.443],
                        [8.584, -3.909],
                        [10.261, -2.548],
                        [9.709, -0.464],
                        [6.157, 2.765],
                        [7.124, 7.457],
                        [6.341, 9.465],
                        [4.181, 9.586],
                        [0, 7.22],
                        [-4.181, 9.586],
                        [-6.341, 9.465],
                        [-7.124, 7.456],
                        [-6.157, 2.765],
                        [-9.709, -0.464],
                        [-10.261, -2.548],
                        [-8.584, -3.91],
                        [-3.805, -4.443],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25, 24.221], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.933, 0],
                        [0, -5.933],
                        [5.933, 0],
                        [0, 5.933],
                      ],
                      o: [
                        [5.933, 0],
                        [0, 5.933],
                        [-5.933, 0],
                        [0, -5.933],
                      ],
                      v: [
                        [0, -10.75],
                        [10.75, 0],
                        [0, 10.75],
                        [-10.75, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 0, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25, 24.999], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [25, 25], ix: 2 },
              a: { a: 0, k: [25, 25], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 300.00001221925, s: [360] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.822, 0],
                        [-0.752, -1.653],
                        [0, 0],
                        [0, 0],
                        [-0.561, -1.727],
                        [1.345, -1.223],
                        [0, 0],
                        [0, 0],
                        [1.474, -1.066],
                        [1.584, 0.897],
                        [0, 0],
                        [0, 0],
                        [1.475, 1.069],
                        [-0.367, 1.778],
                        [0, 0],
                        [0, 0],
                        [-0.564, 1.726],
                        [-1.812, 0.203],
                        [0, 0],
                      ],
                      o: [
                        [0.752, -1.653],
                        [1.823, 0],
                        [0, 0],
                        [0, 0],
                        [1.812, 0.203],
                        [0.564, 1.726],
                        [0, 0],
                        [0, 0],
                        [0.367, 1.778],
                        [-1.475, 1.069],
                        [0, 0],
                        [0, 0],
                        [-1.584, 0.897],
                        [-1.474, -1.066],
                        [0, 0],
                        [0, 0],
                        [-1.345, -1.224],
                        [0.561, -1.727],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.229, -20.475],
                        [0, -23.19],
                        [4.229, -20.475],
                        [8.849, -10.332],
                        [19.964, -9.092],
                        [23.862, -5.924],
                        [22.578, -1.079],
                        [14.318, 6.431],
                        [16.568, 17.341],
                        [14.748, 22.012],
                        [9.724, 22.293],
                        [0, 16.79],
                        [-9.724, 22.293],
                        [-14.748, 22.012],
                        [-16.568, 17.341],
                        [-14.318, 6.431],
                        [-22.578, -1.079],
                        [-23.862, -5.924],
                        [-19.964, -9.092],
                        [-8.849, -10.332],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25, 23.19], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 20, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-13.798, 0],
                        [0, -13.798],
                        [13.798, 0],
                        [0, 13.798],
                      ],
                      o: [
                        [13.798, 0],
                        [0, 13.798],
                        [-13.798, 0],
                        [0, -13.798],
                      ],
                      v: [
                        [0, -25],
                        [25, 0],
                        [0, 25],
                        [-25, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 0, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25, 25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [25, 25], ix: 2 },
              a: { a: 0, k: [25, 25], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 300.00001221925, s: [-144] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 304.000012382174,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
