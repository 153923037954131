/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import { popupSoundJson } from "../../constants/popupSound";
import { strings } from "../../services/translation";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { columnCenterStyles, columnStyles } from "../../style/flex";

// =======================================================

interface Props {
  onClose: any;
  visible: boolean;
  isOnline?: boolean;
}

// =======================================================

const GameVolumeModal = (props: Props) => {
  const { onClose, visible, isOnline = false } = props;
  return (
    <Modal
      open={visible}
      sx={styles.centeredView}
      onClose={() => onClose()}
    >
      <Box style={styles.modalViewBuyEpisode}>
        <Box sx={styles.animatedIconWrapper}>
          <Player
            src={popupSoundJson}
            autoplay
            loop
            speed={1.5}
          />
        </Box>

        <Typography sx={styles.text}>{isOnline ? strings.messages.sound : strings.activateSound}</Typography>

        {isOnline ? (
          <Typography sx={[styles.text, { marginTop: '15px' }]}>
            {strings.messages.bug}
          </Typography>
        ) : null}

        <Box sx={styles.separatorBuyEpisode} />

        <Box sx={styles.buttons}>
          <ButtonBase
            sx={styles.button}
            onClick={() => onClose()}
          >
            <Typography sx={styles.buttonText}>{strings.okay}</Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Modal>
  );
};

// =======================================================

const centerStyles: any = {
  alignItems: "center",
  justifyContent: "center",
};

const styles = {
  centeredView: {
    flex: 1,
    ...columnCenterStyles,
  },
  modalViewBuyEpisode: {
    width: '300px',
    backgroundColor: Colors.PRIMARY,
    borderRadius: '20px',
    padding: '35px 25px 25px 25px',
    ...columnStyles,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 450,
  },
  animatedIconWrapper: {
    width: '130px',
    height: '130px',
    borderRadius: '130px',
    display: "flex",
    flexDirection: "column",
    ...centerStyles,
  },
  text: {
    color: Colors.SECONDARY_TEXT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    textAlign: "center",
    marginTop: '10px',
  },
  separatorBuyEpisode: {
    width: '300px',
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
    marginTop: "25px",
  },
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginTop: '25px',
  },
  button: {
    backgroundColor: Colors.BTN_ACTIVE,
    ...columnCenterStyles,
    borderRadius: '10px',
    width: '120px',
    height: '40px',
    textAlign: "center",
    zIndex: 500,
  },
  buttonText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.ACCENT,
    textAlign: "center",
  },
};

// =======================================================

GameVolumeModal.defaultProps = {
  visible: false,
};

// =======================================================

export default GameVolumeModal;
