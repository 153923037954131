/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint max-classes-per-file: 0 */

import _ from 'lodash';
import { IVariable } from './interfaces';

export class Variable {
  name: string;

  // Should be a number?
  value: any;

  constructor(name: string, value: any) {
    this.name = name;
    this.value = parseInt(value, 10);
  }

  // TODO: any -> IVariable
  /**
   * Update variable value
   * @param {IVariable} newVar - variable that will update the var
   */
  public update(newVar: IVariable): void {
    const { secondOperand, thirdOperand } = newVar;
    if (secondOperand === '=') {
      this.value = parseInt(thirdOperand, 10);
    } else if (secondOperand === '+') {
      this.value += parseInt(thirdOperand, 10);
    } else if (secondOperand === '-') {
      this.value -= parseInt(thirdOperand, 10);
    }
  }

  public setValue(value: string) {
    this.value = value;
  }
}

export class Variables {
  variables: Array<Variable>;

  constructor() {
    this.variables = [];
  }

  /**
   * Update or create variable depending on if it exists.
   * @param {variable} newVar - Global variable from a scene
   */
  public handleVariable(newVar: IVariable): void {
    const variable = this.getVariableByName(newVar.firstOperand);
    if (variable == null) {
      // add variable because it does not exist.
      this.variables.push(
        new Variable(newVar.firstOperand, newVar.thirdOperand),
      );
    } else {
      variable.update(newVar);
    }
  }

  /**
   * Get a variable by name
   * @param {string} varName - the name to get.
   */
  public getVariableByName(varName: string): any {
    if (_.isEmpty(this.variables)) {
      return null;
    }
    return this.variables.find(el => el.name === varName);
  }
}
