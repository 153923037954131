/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase } from "@mui/material";
import { columnStyles } from "../../style/flex";
import { Colors } from "../../style";

interface Props {
  children: any;
  onPress: any;
  disabled?: boolean;
  width?: any;
  height?: any;
  color1?: string;
  color2?: string;
  customStyle: any;
}

const GradientBtnCustom = (props: Props) => {
  const {
    children,
    onPress,
    disabled,
    width,
    customStyle,
    height,
    color1 = Colors.HEART,
    color2 = Colors.ACCENT,
  } = props;

  return (
    <ButtonBase
      sx={[
        {
          width: width,
          height: height,
          ...columnStyles,
          justifyContent: "center",
          background: `linear-gradient(270deg, ${color1} 0%, ${color2} 100%)`,
        },
        customStyle,
      ]}
      onClick={() => onPress()}
      disabled={disabled}
    >
      {children}
    </ButtonBase>
  );
};

export default GradientBtnCustom;

GradientBtnCustom.defaultProps = {
  disabled: false,
};
