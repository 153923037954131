/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material";
import { columnCenterStyles } from "../../style/flex";
import Loading from "../Loading";

interface Props {
  color?: string | undefined;
  style?: any;
}
const LoadingView = (props: Props) => {
  const { color, style } = props;

  return (
    <Box sx={[styles.container, style ? style : {}]}>
      <Loading color={color} />
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    ...columnCenterStyles,
  },
};

export default LoadingView;
