export const newsIconAnimated = {
  v: '5.9.6',
  fr: 29.9700012207031,
  ip: 0,
  op: 90.0000036657751,
  w: 48,
  h: 48,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'dot',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.625, 5.375, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-7.375, -13.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [10.75, 10.75], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.658382999196, 0.847059003045, 0.139516000187, 1],
                ix: 3,
              },
              o: { a: 0, k: 30, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.913725495338, 0.313725501299, 0.313725501299, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-7.375, -13.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 1200.000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'icon',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [24, 13.999, 0], ix: 2, l: 2 },
        a: { a: 0, k: [24, 13.999, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.026, 0.003],
                        [0.433, 1.222],
                        [0, 0],
                        [0.03, -0.069],
                        [0, 0],
                        [0.522, -0.354],
                        [0, 0],
                        [0.147, -0.069],
                        [0.019, -0.009],
                        [0.043, -0.017],
                        [0.017, -0.007],
                        [0.303, -0.03],
                        [0.041, -0.003],
                        [0.054, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.012, 0.001],
                        [0.052, 0.004],
                      ],
                      o: [
                        [-1.338, -0.12],
                        [0, 0],
                        [-0.026, 0.072],
                        [0, 0],
                        [-0.258, 0.582],
                        [0, 0],
                        [-0.134, 0.089],
                        [-0.019, 0.009],
                        [-0.043, 0.019],
                        [-0.017, 0.007],
                        [-0.274, 0.104],
                        [-0.041, 0.004],
                        [-0.053, 0.003],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.012, 0],
                        [-0.053, -0.001],
                        [-0.027, -0.001],
                      ],
                      v: [
                        [23.693, 33.986],
                        [20.807, 31.744],
                        [27.193, 31.744],
                        [27.109, 31.956],
                        [27.096, 31.986],
                        [25.898, 33.418],
                        [25.868, 33.438],
                        [25.446, 33.676],
                        [25.389, 33.703],
                        [25.26, 33.757],
                        [25.209, 33.778],
                        [24.341, 33.983],
                        [24.219, 33.993],
                        [24.058, 33.999],
                        [24.034, 34],
                        [24, 34],
                        [23.966, 34],
                        [23.931, 33.999],
                        [23.773, 33.992],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [0, 8.87],
                        to: [-0.5, 0],
                        ti: [-0.5, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 5,
                        s: [-3, 8.87],
                        to: [0.5, 0],
                        ti: [-0.5, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 15,
                        s: [3, 8.87],
                        to: [0.5, 0],
                        ti: [0.5, 0],
                      },
                      {
                        i: { x: 0.667, y: 0.667 },
                        o: { x: 0.167, y: 0.167 },
                        t: 35,
                        s: [0, 8.87],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      { t: 90.0000036657751, s: [0, 8.87] },
                    ],
                    ix: 2,
                    x: 'var $bm_rt;\n$bm_rt = valueAtTime($bm_mod(time, key(numKeys).time));',
                  },
                  a: { a: 0, k: [24, 32.872], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.03, 0],
                    [0, 0],
                    [0, -0.821],
                    [-0.815, -1.466],
                    [0.403, -0.686],
                    [0.795, 0],
                    [0, 0],
                    [0.403, 0.685],
                    [-0.386, 0.695],
                    [0, 1.678],
                    [0, 0],
                  ],
                  o: [
                    [6.03, 0],
                    [0, 0],
                    [0, 1.678],
                    [0.386, 0.695],
                    [-0.403, 0.685],
                    [0, 0],
                    [-0.795, 0],
                    [-0.403, -0.686],
                    [0.815, -1.466],
                    [0, -0.821],
                    [0, 0],
                  ],
                  v: [
                    [0, -10],
                    [6.233, -3.72],
                    [6.233, -1.458],
                    [8.615, 2.266],
                    [8.588, 4.494],
                    [6.654, 5.6],
                    [-6.654, 5.6],
                    [-8.588, 4.494],
                    [-8.615, 2.266],
                    [-6.233, -1.458],
                    [-6.233, -3.72],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24, 24], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [20],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [-20],
                  },
                  { t: 35.0000014255792, s: [0] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90.0000036657751,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'BG',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [24, 13.999, 0], ix: 2, l: 2 },
        a: { a: 0, k: [24, 13.999, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-13.246, 0],
                    [0, -13.246],
                    [13.246, 0],
                    [0, 13.246],
                  ],
                  o: [
                    [13.246, 0],
                    [0, 13.246],
                    [-13.246, 0],
                    [0, -13.246],
                  ],
                  v: [
                    [0, -24],
                    [24, 0],
                    [0, 24],
                    [-24, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.145098039216, 0.149019607843, 0.18431372549, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 2',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24, 24], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90.0000036657751,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
