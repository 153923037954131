/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { columnCenterStyles, columnStartStyles } from "../../style/flex";
import { Colors } from "../../style";
import { normalizeSize } from "../../utils/fontSizer";
import { strings } from "../../services/translation";
import { isNotEmptyString } from "../../utils/StringHelper";

const ErrorModal = ({
    error,
    callback,
}: {
    error: string | null;
    callback: any;
}) => {
    return (
        <Modal
            open={!!error && isNotEmptyString(error)}
            onClose={() => callback()}
            style={{ outline: "none" }}>
            <Box
                style={{
                    ...columnCenterStyles,
                    outline: "none",
                }}>
                <Box style={styles.modalViewError}>
                    <Typography
                        style={{
                            color: Colors.WHITE,
                            fontFamily: "Eczar-Regular",
                            fontSize: normalizeSize(36),
                            marginBottom: "18px",
                            textAlign: "center",
                        }}>
                        {strings.error}
                    </Typography>

                    <Typography sx={styles.textModalError}>{error}</Typography>

                    <Box style={styles.separatorError} />

                    <ButtonBase onClick={() => callback()}>
                        <Typography style={styles.deleteStoryText}>
                            {strings.understood}
                        </Typography>
                    </ButtonBase>
                </Box>
            </Box>
        </Modal>
    );
};

const styles = {
    modalViewError: {
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        padding: "37px 0px 20px 0px",
        width: "400px",
        ...columnStartStyles,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    separatorError: {
        width: "100%",
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        marginBottom: "20px",
        marginTop: "10px",
    },
    textModalError: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
        width: "80%",
        textAlign: "center",
        marginBottom: "15px",
    },
    deleteStoryText: {
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
        color: Colors.ACCENT,
    },
};

export default ErrorModal;
