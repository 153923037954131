// =======================================================
// =======================================================

const Routes = {
  LIBRARY_STACK: {name: 'LIBRARY_STACK', url: '/'},

  GAME_ONLINE_SCREEN: {name: 'GAME_ONLINE', url: '/gameOnline'},
  LIBRARY_SCREEN: {name: 'LIBRARY', url: '/libray'},
  LOBBY_SCREEN: {name: 'LOBBY', url: '/lobby'},
  PRE_GAME_BACKGROUND_ONLINE_SCREEN: {name: 'PRE_GAME_BACKGROUND_ONLINE', url: '/'},
};


// =======================================================

export default Routes;
