/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalGameData } from "../models/localData";
import { isDateOlderThan } from "./DateHelper";
import { isPositiveNumber } from "./NumberHelper";
import { propertyExists } from "./ObjectHelper";
import { isNotEmptyString } from "./StringHelper";
import { isNumber } from "./TypeOfHelper";

export function isValidScene(scene: any): boolean {
  return (
    scene && propertyExists(scene, "number") && isPositiveNumber(scene.number)
  );
}

export function isValidSave(currentGame: LocalGameData): boolean {
  return (
    propertyExists(currentGame, "created") &&
    !isDateOlderThan(currentGame.created) &&
    (!propertyExists(currentGame, "isFinished") ||
      (propertyExists(currentGame, "isFinished") && !currentGame.isFinished))
  );
}

export function hasRequiredParameters(game: any): boolean {
  return (
    game &&
    isNumber(game._storyId) &&
    propertyExists(game, "_storyData") &&
    isNotEmptyString(game._title) &&
    isValidScene(game._currentScene)
  );
}
