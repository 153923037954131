/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router";
import useAuth from "../../services/Auth/useAuth";
import { useContext, useEffect, useRef, useState } from "react";
import { propertyExists } from "../../utils/ObjectHelper";
import { getLanguage, strings } from "../../services/translation";
import {
    goToLibrary,
    handleQuitGameLobby,
    isValidSnapshot,
    resetStorage,
    sortByJoinedAt,
    updateStorage,
} from "../../services/GameOnlineManager";
import { isNotEmptyString } from "../../utils/StringHelper";
import { db } from "../../utils/FirebaseHelper";
import {
    FIREBASE_DATABASE,
    FRIENDS,
    FTUE_MODALS,
    SOCKET_EVENTS,
} from "../../constants";
import { isNumber, isTrueBoolean } from "../../utils/TypeOfHelper";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { getRouteManager } from "../../services/routeManager";
import Routes from "../../utils/OnlineRoutesHelper";
import AvatarContainer from "../../components/AvatarContainer";
import { Colors } from "../../style";
import {
    Box,
    ButtonBase,
    Modal,
    Snackbar,
    Switch,
    Typography,
} from "@mui/material";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
    rowStartStyles,
} from "../../style/flex";
import normalize, { normalizeSize } from "../../utils/fontSizer";
import BackgroundRow from "../../components/BackgroundRow";
import Loading from "../../components/Loading";
import { ReactComponent as CompleteIcon } from "../../assets/icons/icon_complete.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GradientBtn from "../../components/GradientBtn";
import { GameContext, NotificationContext } from "../../utils/ContextsHelper";
import ClassicModal from "../../components/ClassicModal";
import { SCREEN_HEIGHT } from "../../utils/size";
import { getEventManager } from "../../services/EventManager";
import ColorManager from "../../services/colorManager";
import GameChat from "../../components/GameChat";
import { Player } from "../../services/StoryReader/players";
import { capitalize } from "lodash";
import SoundManager from "../../services/SoundManager";
import { ReactComponent as IconPlayer } from "../../assets/icons/icon_more.svg";
import { ReactComponent as IconAdd } from "../../assets/icons/icon_add.svg";
import { ReactComponent as IconCompanions } from "../../assets/icons/icon_companion.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/icon_chat.svg";
import { ReactComponent as IconPlayerInvited } from "../../assets/icons/icon_player_invited.svg";

import { Gilroy } from "../../style/fonts";
import { Friend } from "../../models/friend";
import { isPositiveNumber } from "../../utils/NumberHelper";
import SelectFriendModal from "../../components/SelectFriendModal";
import { socket } from "../../socket";
import FTUEModals from "../../components/FTUEModals";

interface Props {
    gameID: string;
    playerID: string;
    screenName: string;
    title: string;
    letterBorderColor: string;
    story: any;
    episode?: any;
    hostParam?: any;
    onReboot?: any;
}

const chatColors = [
    {
        value: Colors.PRIMARY,
        type: "primary",
    },
    {
        value: Colors.BTN_ACTIVE,
        type: "secondary",
    },
    {
        value: Colors.ACCENT,
        type: "tertiary",
    },
    {
        value: Colors.OLD_PRICE,
        type: "quaternary",
    },
    {
        value: Colors.BTN_ACTIVE,
        type: "quinary",
    },
];

const Lobby = () => {
    const auth = useAuth();
    const user = auth.user;
    const { state }: any = useLocation();
    const { gameIDParam } = useParams();

    const navigate = useNavigate();

    const INITIAL_STATE = {
        colors: "",
        currentFiles: 0,
        currentPlayerID: "",
        disableContinueButton: false,
        disableLeaveGameButton: false,
        excludedPlayers: [],
        host: { name: null, id: null },
        isDownloading: false,
        isReady: false,
        players: [["", { avatar: null, id: null, username: "" }]],
        progress: 0,
        refreshPlayersList: false,
        selectedPlayerId: null,
        showConfirmLeaveModal: false,
        showConfirmRemovePlayerModal: false,
        showHostModal: true,
        showStartGameButton: false,
        storyBackground: "",
        storyDateUpdated: null,
        storyId: null,
        storyPlayerMaxLimit: null,
        storyPlayerMinLimit: null,
        totalFiles: 0,
        waitingPlayerKeys: [],
        wasHost: false,
        // PROPS :
        gameID: state?.gameID ? state.gameID : gameIDParam ? gameIDParam : null,
        playerID: state?.playerID ? state.playerID : "",
        screenName: state?.screenName
            ? state.screenName
            : Routes.LOBBY_SCREEN.name,
        title: state?.title ? state.title : "",
        letterBorderColor: state?.letterBorderColor
            ? state.letterBorderColor
            : null,
        story: state?.story ? state.story : null,
        episode: state?.episode ? state.episode : null,
        save: null,
        hostParam: state?.hostParam ? state.hostParam : null,
        onReboot: state?.onReboot ? state.onReboot : false,
        storyData: null,
    };

    // ====== OLD PROPS ======
    const [gameID, setGameID] = useState(INITIAL_STATE.gameID);
    const [playerID, setPlayerID] = useState(INITIAL_STATE.playerID);
    const [screenName, setScreenName] = useState(INITIAL_STATE.screenName);
    const [title, setTitle] = useState(INITIAL_STATE.title);
    const [letterBorderColor, setLetterBorderColor] = useState(
        INITIAL_STATE.letterBorderColor,
    );
    const [story, setStory] = useState(INITIAL_STATE.story);
    const [episode, setEpisode] = useState(INITIAL_STATE.episode);
    const [hostParam, setHostParam] = useState(INITIAL_STATE.hostParam);
    const [onReboot, setOnReboot] = useState(INITIAL_STATE.onReboot);

    // ====== Local states ======
    const [colors, setColors] = useState(INITIAL_STATE.colors);
    const [currentPlayerID, _setCurrentPlayerID] = useState<string | null>(
        playerID,
    );
    const currentPlayerIDRef = useRef(currentPlayerID);
    const setCurrentPlayerID = (data: any) => {
        currentPlayerIDRef.current = data;
        _setCurrentPlayerID(data);
    };
    const [currentFiles, setCurrentFiles] = useState(
        INITIAL_STATE.currentFiles,
    );
    const [disableContinueButton, setDisableContinueButton] = useState(
        INITIAL_STATE.disableContinueButton,
    );
    const [disableLeaveGameButton, setDisableLeaveGameButton] = useState(
        INITIAL_STATE.disableLeaveGameButton,
    );
    const [excludedPlayers, setExcludedPlayers] = useState<any>(
        INITIAL_STATE.excludedPlayers,
    );
    const [host, setHost] = useState(
        hostParam && propertyExists(hostParam, "id")
            ? hostParam
            : INITIAL_STATE.host,
    );
    const [isDownloading, setIsDownloading] = useState(
        INITIAL_STATE.isDownloading,
    );
    const [isReady, setIsReady] = useState<boolean>(INITIAL_STATE.isReady);
    // --
    const [players, _setPlayers] = useState<any>(INITIAL_STATE.players);
    const playersRef = useRef(players);
    const setPlayers = (data: any) => {
        playersRef.current = data;
        _setPlayers(data);
    };
    const [progress, setProgress] = useState(INITIAL_STATE.progress);
    // --
    const [refreshPlayersList, _setRefreshPlayersList] = useState<boolean>(
        INITIAL_STATE.refreshPlayersList,
    );
    const refreshPlayersListRef = useRef(refreshPlayersList);
    const setRefreshPlayersList = (data: boolean) => {
        refreshPlayersListRef.current = data;
        _setRefreshPlayersList(data);
    };
    const [selectedPlayerId, setSelectedPlayerId] = useState(
        INITIAL_STATE.selectedPlayerId,
    );
    const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(
        INITIAL_STATE.showConfirmLeaveModal,
    );
    const [showConfirmRemovePlayerModal, setShowConfirmRemovePlayerModal] =
        useState(INITIAL_STATE.showConfirmRemovePlayerModal);
    const [showHostModal, setShowHostModal] = useState(
        INITIAL_STATE.showHostModal,
    );
    const [showStartGameButton, setShowStartGameButton] = useState<boolean>(
        INITIAL_STATE.showStartGameButton,
    );
    const [storyBackground, setStoryBackground] = useState<string>(
        INITIAL_STATE.storyBackground,
    );
    const [storyDateUpdated, setStoryDateUpdated] = useState(
        INITIAL_STATE.storyDateUpdated,
    );
    const [storyId, setStoryId] = useState<number | null>(
        INITIAL_STATE.storyId,
    );
    const [storyPlayerMaxLimit, setStoryPlayerMaxLimit] = useState(
        INITIAL_STATE.storyPlayerMaxLimit,
    );
    const [storyPlayerMinLimit, setStoryPlayerMinLimit] = useState<any>(
        INITIAL_STATE.storyPlayerMinLimit,
    );
    const [waitingPlayerKeys, setWaitingPlayerKeys] = useState<string[]>(
        INITIAL_STATE.waitingPlayerKeys,
    );
    const [wasHost, setWasHost] = useState(INITIAL_STATE.wasHost); // needed because the host gets reset twice if they leave game

    // ====== Local constants ======
    const playerPlural =
        strings.labels[playersRef.current.length === 1 ? "player" : "players"];

    const [showCopied, setShowCopied] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
    const [friendsList, setFriendsList] = useState<Friend[]>([]);
    const [showFriendRequestSent, setShowFriendRequestSent] = useState(false);
    const [showInvitationsSent, setShowInvitationsSent] = useState(false);
    const [showInvitationsDeclined, setShowInvitationsDeclined] =
        useState(false);

    const { getFriends } = useContext(NotificationContext);
    const { setGame } = useContext(GameContext);
    const [publicSwitchValue, setPublicSwitchValue] = useState(false);
    const [showGoPublicModal, setShowGoPublicModal] = useState(false);
    const [showGoPrivateModal, setShowGoPrivateModal] = useState(false);
    const [hasInvitedSaveFriends, setHasInvitedSaveFriends] = useState(false);
    const [hasPlayersFromSaveMissing, sethasPlayersFromSaveMissing] =
        useState(true);
    const [saveState, setSaveState] = useState<any>(INITIAL_STATE.save);

    const containerRef = useRef<any>(null);

    useEffect(() => {
        if (!isReady && (!state || !state?.gameID) && gameIDParam) {
            const dataSaved = localStorage.getItem("alreadyLaunchedOnlineGame");
            if (dataSaved) {
                const data = JSON.parse(dataSaved);
                if (data.playerID) {
                    setPlayerID(data.playerID);
                    setCurrentPlayerID(data.playerID);
                    setOnReboot(true);
                }
            }
        }
        setDataLoaded(true);

        /*  return () => {
      resetValues();
    }; */
    }, []);

    useEffect(() => {
        if (chatColors && isNotEmptyArray(chatColors)) {
            ColorManager.getInstance().Clear();
            ColorManager.getInstance().generateColors(chatColors);
        }
    }, []);

    /* const eventQuit = async (event: any) => {
    const result = await handleQuitGameLobby(
      currentPlayerID,
      gameID,
      { name: host[1], id: host[0] },
      1
    );
  }; */

    /**
     * Keep in memory that a current online game is launched
     * if the app is killed (for example), we redirect the user to the current
     * game on reboot (@see checkLogin() function in screens/loginScreen/index.tsx)
     */
    useEffect(() => {
        if (isNotEmptyString(currentPlayerIDRef.current)) {
            updateStorage(true, currentPlayerIDRef.current, gameID, screenName);
        }
    }, [currentPlayerID]);

    /**
     * Shortcut to determine if the current player is the game host
     */
    const isHost = (): boolean => {
        if (
            propertyExists(host, "id") &&
            isNotEmptyString(currentPlayerIDRef.current)
        ) {
            return currentPlayerIDRef.current === host.id;
        }
        return false;
    };

    /**
     * STEP 2 BIS - Firebase event subscriptions
     */
    useEffect(() => {
        let onHostChange: any = null;

        if (isReady && isNotEmptyString(currentPlayerIDRef.current)) {
            removeUserWaiting();

            // listen for Host change
            onHostChange = db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/host`)
                .on("value", (snapshot) => {
                    if (!isValidSnapshot(snapshot, 0)) {
                        goLibrary();
                        return;
                    }

                    if (snapshot.val() === "") {
                        if (!wasHost) {
                            handleQuit();
                        }
                    } else {
                        const host: any = Object.entries(snapshot.val())[0];
                        setHost({ name: host[1], id: host[0] });

                        if (currentPlayerIDRef.current === host[0]) {
                            setWasHost(true);
                        }
                        setGame(
                            gameID,
                            currentPlayerIDRef.current
                                ? currentPlayerIDRef.current
                                : "",
                            currentPlayerIDRef.current === host[0],
                        );
                    }
                });
        }

        return () => {
            if (onHostChange != null) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/host`,
                ).off("value", onHostChange);
            }
        };
    }, [currentPlayerID, isReady]);

    /**
     * STEP 1 BIS
     */
    useEffect(() => {
        if (!isReady && dataLoaded) {
            // if host was already added, don't add again
            if (playerID === "") {
                handleAddPlayer();
            }

            // get story data
            db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}`).once(
                "value",
                (snapshot) => {
                    if (!isValidSnapshot(snapshot, 12)) {
                        return;
                    }

                    updateState(snapshot.val().story, snapshot.val());
                },
            );
        }
    }, [dataLoaded]);

    /**
     * STEP 2 BIS - Firebase event subscriptions
     */
    useEffect(() => {
        let onPlayersRemovingChange: any = null;
        let onPlayersJoiningChange: any = null;
        let onPlayersWaitingChange: any = null;
        let onIsPublicChange: any = null;
        let onGameStart: any = null;

        if (isReady) {
            // listen for players deletion
            onPlayersRemovingChange = db
                .ref(
                    `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_EXCLUDED}/${gameID}`,
                )
                .on("value", (snapshot) => {
                    const excludedIds: any[] = _.toPairs(snapshot.val());
                    setExcludedPlayers([...excludedIds]);
                });

            // listen for any players that have been added to the game
            onPlayersJoiningChange = db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`)
                .on("value", (snapshot) => {
                    const dbPlayers: any[] = _.toPairs(snapshot.val());
                    const asArray = [...dbPlayers];

                    setShowStartGameButton(
                        asArray.some((player) => !player[1].isReady)
                            ? INITIAL_STATE.showStartGameButton
                            : true,
                    );
                    setPlayers(sortByJoinedAt(asArray));
                });

            // listen for players in 'waiting' state
            onPlayersWaitingChange = db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting`)
                .on("value", (snapshot) => {
                    const waiting: any = _(snapshot.val()).keys();
                    setWaitingPlayerKeys([...waiting]);
                });

            // listen for isPublic change
            onIsPublicChange = db
                .ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/isPublic`,
                )
                .on("value", (snapshot) => {
                    setPublicSwitchValue(snapshot.val());
                });

            // listen for game to start
            onGameStart = db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/status`)
                .on("value", (snapshot) => {
                    goToGameBackground(snapshot.val());
                });
        }

        // stop listening for updates when no longer required
        return () => {
            if (onPlayersJoiningChange != null) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`,
                ).off("value", onPlayersJoiningChange);
            }

            if (onPlayersWaitingChange != null) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting`,
                ).off("value", onPlayersWaitingChange);
            }

            if (onIsPublicChange != null) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/isPublic`,
                ).off("value", onIsPublicChange);
            }

            if (onGameStart != null) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/status`,
                ).off("value", onGameStart);
            }

            if (onPlayersRemovingChange != null) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_EXCLUDED}/${gameID}`,
                ).off("value", onPlayersRemovingChange);
            }
        };
    }, [isReady]);

    /**
     * The removed player is automatically redirected to the library screen
     */
    useEffect(() => {
        const predicate = (e: string) => e[1] === currentPlayerIDRef.current;

        if (
            isNotEmptyArray(excludedPlayers) &&
            _.find(excludedPlayers, predicate) != undefined &&
            isReady
        ) {
            const key = _.findKey(excludedPlayers, predicate);

            db.ref(
                `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_EXCLUDED}/${gameID}/${key}`,
            )
                .remove()
                .then(() => {
                    goLibrary();
                });
        }
    }, [excludedPlayers]);

    useEffect(() => {
        initDataFriends();
    }, []);
    /**
     * @initDataFriends
     * Initialize screen and get friendlist
     */
    const initDataFriends = async () => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                const data = await getFriends();
                let tmpFriendsList: any = [];
                if (isNotEmptyArray(data)) {
                    tmpFriendsList = data.filter((friend: Friend) => {
                        return (
                            (isPositiveNumber(friend.status) &&
                                friend.status === FRIENDS.STATUS.ACCEPTED) ||
                            friend.status === FRIENDS.STATUS.INVITED
                        );
                    });
                }
                setFriendsList(tmpFriendsList);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Shortcut to init local state values
     */
    const updateState = (storyData: any, gameData: any): void => {
        // is story data valid?
        if (
            propertyExists(storyData, "id") &&
            propertyExists(storyData, "colors") &&
            propertyExists(storyData, "updated") &&
            propertyExists(storyData, "playerMaxLimit") &&
            propertyExists(storyData, "playerMinLimit") &&
            propertyExists(storyData, "background")
        ) {
            if (storyData) {
                if (!story) {
                    setStory(storyData);
                }
                if (
                    propertyExists(storyData, "title") &&
                    !isNotEmptyString(title)
                ) {
                    setTitle(storyData.title);
                }
                if (
                    !episode &&
                    propertyExists(storyData, "isSeries") &&
                    isTrueBoolean(storyData.isSeries) &&
                    propertyExists(storyData, "episode")
                ) {
                    setEpisode(storyData.episode);
                }
            }
            setStoryBackground(storyData.background);
            setColors(storyData.colors);
            setStoryDateUpdated(storyData.updated);

            setStoryId(storyData.id);
            //loadStory(storyData.id);

            setStoryPlayerMaxLimit(storyData.playerMaxLimit);
            setStoryPlayerMinLimit(storyData.playerMinLimit);
            if (gameData && gameData.save) {
                setSaveState(gameData.save);
            }

            if (!isReady) {
                setIsReady(true);
            }
        } else {
            console.log("Snapshot (for story data) is not valid");
        }
    };

    /**
     * Make sure to reset initial values unmount
     */
    const resetValues = (): void => {
        setDisableContinueButton(INITIAL_STATE.disableContinueButton);
        setDisableLeaveGameButton(INITIAL_STATE.disableLeaveGameButton);
        setStoryBackground(INITIAL_STATE.storyBackground);
        setStoryDateUpdated(INITIAL_STATE.storyDateUpdated);
        setStoryId(INITIAL_STATE.storyId);
        setStoryPlayerMaxLimit(INITIAL_STATE.storyPlayerMaxLimit);
        setStoryPlayerMinLimit(INITIAL_STATE.storyPlayerMinLimit);
        setCurrentPlayerID(INITIAL_STATE.currentPlayerID);
        setIsReady(INITIAL_STATE.isReady);
    };

    /**
     * Shortcut to redirect user to the next screen (game background)
     */
    const goToGameBackground = (storyData: any): void => {
        const nextScreen = Routes.GAME_ONLINE_SCREEN;
        if (
            propertyExists(storyData, "currentScreen") &&
            storyData.currentScreen === nextScreen.name &&
            propertyExists(storyData, "storyId") &&
            isNumber(storyData.storyId) &&
            propertyExists(storyData, "storyBackground") &&
            propertyExists(storyData, "colors") &&
            isNotEmptyArray(playersRef.current)
        ) {
            const value = localStorage.getItem("alreadyLaunchedOnlineGame");
            if (value) {
                const data = JSON.parse(value);

                const nbrPlayers = propertyExists(data, "nbrPlayers")
                    ? data.nbrPlayers
                    : playersRef.current.length;

                updateStorage(
                    true,
                    data.playerID,
                    data.gameID,
                    screenName,
                    nbrPlayers,
                    data.background ? data.background : undefined,
                );
                getEventManager().logLaunchStoryOnline(storyId);
                //resetValues();
                navigate(`${nextScreen.url}/${gameID}`, {
                    replace: true,
                    state: {
                        storyID: storyId,
                        colors: storyData.colors,
                        save: storyData.save ? storyData.save : null,
                        gameID: gameID,
                        playerID: currentPlayerIDRef.current
                            ? currentPlayerIDRef.current
                            : playerID
                            ? playerID
                            : "",
                        screenName,
                        episode,
                        title,
                        host,
                        nbrPlayers,
                        background: data.background
                            ? data.background
                            : undefined,
                    },
                });
            }
        }
    };

    /**
     * Shortcut to make sure the `storyPlayerMaxLimit` value exists
     */
    const isValidPlayerMaxLimit = () => {
        return storyPlayerMaxLimit && isNumber(storyPlayerMaxLimit);
    };

    /**
     * Shortcut to make sure the `storyPlayerMinLimit` value exists
     */
    const isValidPlayerMinLimit = () => {
        return storyPlayerMinLimit && isNumber(storyPlayerMinLimit);
    };

    /**
     * The maximum number of players has been reached for this game
     */
    useEffect(() => {
        if (
            isValidPlayerMaxLimit() &&
            isNotEmptyArray(playersRef.current) &&
            isReady
        ) {
            let hasReached = false;

            // game is ready!
            if (
                playersRef.current.length == storyPlayerMaxLimit &&
                !waitingPlayerKeys.length
            ) {
                hasReached = true;
            }

            db.ref(
                `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasReachedPlayerMaxLimit`,
            ).set(hasReached);
        }
    }, [playersRef, waitingPlayerKeys]);

    /**
     * Remove player from player waiting list
     */
    const removeUserWaiting = (
        id?: string | null,
        canUpdateReady = true,
    ): void => {
        const userID = isNotEmptyString(id) ? id : currentPlayerIDRef.current;

        db.ref(
            `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting/${userID}`,
        )
            .remove()
            .then(() => {
                if (canUpdateReady) {
                    db.ref(
                        `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}/${userID}/isReady`,
                    )
                        .set(true)
                        .then(() => {
                            // used in order to force re-render players list
                            setRefreshPlayersList(
                                !refreshPlayersListRef.current,
                            );
                        });
                }
            });
    };

    /**
     * Add player to player waiting list
     */
    const addUserWaiting = (id: string | null, setPlayerId = true): void => {
        if (isNotEmptyString(id)) {
            db.ref(
                `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/waiting/${id}`,
            )
                .set(screenName)
                .then(() => {
                    if (setPlayerId) {
                        setCurrentPlayerID(id);
                    }
                });
        }
    };

    /**
     * Add player to the list of players for the game
     */
    const handleAddPlayer = async (
        friend: Friend | null = null,
        player: Player | null = null,
    ): Promise<any> => {
        let playerToAdd = null;
        if (friend) {
            playerToAdd = {
                username: propertyExists(friend, "pseudo")
                    ? friend.pseudo
                    : propertyExists(friend, "name")
                    ? friend.name
                    : "",
                avatar: propertyExists(friend, "avatar")
                    ? {
                          ...friend.avatar,
                          letterBorderColor: isNotEmptyString(letterBorderColor)
                              ? letterBorderColor
                              : null,
                      }
                    : null,
                title: propertyExists(friend, "title")
                    ? {
                          name: friend.title,
                      }
                    : null,
                joinedAt: Date.now(),
                isReady: false,
                isInvitedPlayer: true,
            };
        } else if (player) {
            playerToAdd = {
                username: propertyExists(player, "name") ? player.name : "",
                avatar: propertyExists(player, "avatar")
                    ? {
                          ...player.avatar,
                          letterBorderColor: null,
                      }
                    : null,
                title: null,
                joinedAt: Date.now(),
                isReady: false,
                isInvitedPlayer: true,
            };
        } else {
            // TODO : check if exists
            let exists: any = null;
            const currentPlayersList = await db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`)
                .once("value", (snapshot) => {
                    const dbPlayers: any[] = _.toPairs(snapshot.val());
                    const asArray = [...dbPlayers];

                    exists = asArray.find(
                        (p) =>
                            p[1]?.isInvitedPlayer &&
                            user &&
                            user.id &&
                            user.id === p[1].avatar?.userId,
                    );
                });

            // Cherche tous les joueurs invités de la liste old locale
            // qui ne sont pas dans la nouvelle liste (bdd);
            if (exists && exists[0] && isNotEmptyString(exists[0])) {
                // exists as invited : setIsInvited false, set current id, refresh list
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}/${exists[0]}/isInvitedPlayer`,
                )
                    .set(false)
                    .then(() => {
                        setCurrentPlayerID(exists[0]);
                        // used in order to force re-render players list
                        setRefreshPlayersList(!refreshPlayersListRef.current);
                    });
            } else {
                playerToAdd = {
                    username: screenName,
                    avatar: propertyExists(user, "avatar")
                        ? {
                              ...user.avatar,
                              letterBorderColor: isNotEmptyString(
                                  letterBorderColor,
                              )
                                  ? letterBorderColor
                                  : null,
                          }
                        : null,
                    title: propertyExists(user, "title")
                        ? {
                              ...user.title,
                          }
                        : null,
                    joinedAt: Date.now(),
                    isReady: false,
                };
            }
        }
        if (playerToAdd) {
            const playerReference = await db
                .ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}`)
                .push(playerToAdd);

            if (isNotEmptyString(playerReference.key)) {
                const newPlayerID = playerReference.key;
                // add player to 'waiting' state
                // to indicate (to others) they haven't submitted join
                addUserWaiting(newPlayerID, !playerToAdd.isInvitedPlayer);
                return newPlayerID;
            } else {
                console.log("Generated player key is not valid");
            }
        }
        return null;
    };

    /**
     * Shortcut to return to the library screen
     */
    const goLibrary = (): void => {
        resetStorage(); // because : see all updateStorage() calls
        setGame("", "", false);
        setIsReady(INITIAL_STATE.isReady);
        goToLibrary(navigate, playerID, host);
    };

    /**
     * Triggered when `Leave game` button was clicked
     */
    const handleQuit = async (): Promise<void> => {
        setShowConfirmLeaveModal(INITIAL_STATE.showConfirmLeaveModal);
        setDisableLeaveGameButton(true);
        if (
            isNotEmptyString(currentPlayerIDRef.current) &&
            isNotEmptyString(gameID) &&
            host &&
            host.id
        ) {
            const result = await handleQuitGameLobby(
                currentPlayerIDRef.current,
                gameID,
                host,
                1,
            );
            setGame("", "", false);
            if (!result) {
                setDisableLeaveGameButton(INITIAL_STATE.disableLeaveGameButton);
            } else if (result === "shouldBeExcluded") {
                handleRemovePlayerById(currentPlayerIDRef.current);
            } else {
                goLibrary();
            }
        } else {
            goLibrary();
        }
    };

    /**
     *
     */
    const handleLaunchPreGameBackground = async (): Promise<void> => {
        if (isNotEmptyArray(playersRef.current)) {
            const ref = `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}`;
            const session = {
                startDate: new Date(),
                userId: user.id,
                storyId: storyId,
                type: "online",
                lang: getLanguage(),
                nbPlayers: playersRef.current.length,
            };
            if (isHost()) {
                await getRouteManager().fetchStartSession(session, user);
            }
            db.ref(`${ref}/nbrPlayers`)
                .set(playersRef.current.length)
                .then(() => {
                    db.ref(`${ref}/status`).set({
                        currentScreen: Routes.GAME_ONLINE_SCREEN.name,
                        storyId,
                        storyBackground,
                        colors,
                    });
                });
        }
    };

    /**
     * Shortcut to print the waiting to join text
     */
    const getWaitingToJoinText = (): string => {
        let text = "";
        if (saveState && saveState?.players) {
            const missing = getPlayersMissingFromSave(saveState)?.length;
            if (isPositiveNumber(missing)) {
                const playerPlural =
                    missing === 1
                        ? strings.labels.player
                        : strings.labels.players;

                text = `${strings.labels.waitingFor} ${String(
                    missing,
                )} ${playerPlural.toLowerCase()} ${
                    strings.online.fromSave
                }... `;
            }
        } else {
            if (
                isValidPlayerMinLimit() &&
                playersRef.current.length < storyPlayerMinLimit
            ) {
                const playerPlural =
                    storyPlayerMinLimit - playersRef.current.length === 1
                        ? strings.labels.player
                        : strings.labels.players;

                text = `${strings.labels.waitingFor} ${String(
                    storyPlayerMinLimit - playersRef.current.length,
                )} ${playerPlural.toLowerCase()}... `;
            }
        }

        return text;
    };

    /**
     * Shortcut to close the confirmation remove player modal
     */
    const handleCloseConfirmRemovePlayerModal = (): void => {
        setShowConfirmRemovePlayerModal(
            INITIAL_STATE.showConfirmRemovePlayerModal,
        );
        setSelectedPlayerId(INITIAL_STATE.selectedPlayerId);
    };

    /**
     * Add player to the list of excluded players for the game
     */
    const handleAddExcludedPlayer = (id: string | null): void => {
        if (isNotEmptyString(id)) {
            db.ref(
                `/${FIREBASE_DATABASE.REFERENCES.PLAYERS_EXCLUDED}/${gameID}`,
            ).push(id);
        }
    };

    /**
     * Triggered when host taps on the `sure` button from the active modal
     * [in order to remove player from player list for game]
     */
    const handleRemovePlayerById = (id: string | null): void => {
        if (isNotEmptyString(id)) {
            db.ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}/${id}`)
                .remove()
                .then(() => {
                    removeUserWaiting(id, false);

                    // temporary save user id in a firebase reference
                    // because we apply a listener for that reference
                    // in order to redirect removed player
                    // @see `useEffect()` (STEP 1 - Firebase event subscriptions)
                    handleAddExcludedPlayer(id);
                })
                .catch((error: any) => {
                    console.log(`Remove player ${id} failed: ${error.message}`);
                });
        } else {
            console.log(`Remove player ${id} failed`);
        }
        handleCloseConfirmRemovePlayerModal();
    };

    /**
     * Triggered when user (host) taps on a specific player within the list
     */
    const handlePressPlayer = (id: any): void => {
        setShowConfirmRemovePlayerModal(true);
        setSelectedPlayerId(id);
    };

    const handlePressCode = () => {
        if (isNotEmptyString(gameID)) {
            navigator.clipboard.writeText(gameID);
            setShowCopied(true);
        }
    };

    /**
     * Shortcut to determine if the current user comes from reboot on this screen
     * [after app killing for instance] to avoid re-render the code modal
     */
    const userDoesNotComeFromReboot = (): boolean => {
        return !isTrueBoolean(onReboot);
    };

    /**
     * @isPlayerFriend
     * Render true if param is a friend false otherwise
     */
    const isPlayerFriend = (player: any): number | null => {
        if (isNotEmptyArray(friendsList) && player.avatar) {
            const isFriend = friendsList.find(
                (friend: Friend) => friend.friendId === player.avatar.userId,
            );
            return isFriend && isFriend.status ? isFriend.status : null;
        } else {
            return null;
        }
    };

    const getAvailableFriends = (): Friend[] | null => {
        const filteredList = friendsList?.filter((friend: Friend) => {
            const isAlreadyInGame = playersRef.current?.some(
                (player: any) => player[1]?.avatar?.userId === friend.friendId,
            );

            return (
                !isAlreadyInGame && friend.status === FRIENDS.STATUS.ACCEPTED
            );
        });

        return filteredList && filteredList.length ? filteredList : null;
    };

    const onHasDeclinedGameRequest = (playerIdToRemove: any) => {
        if (isNotEmptyString(playerIdToRemove)) {
            handleRemovePlayerById(playerIdToRemove);
            setShowInvitationsDeclined(true);
        }
    };

    useEffect(() => {
        socket.on(
            SOCKET_EVENTS.HAS_DECLINED_GAME_REQUEST,
            onHasDeclinedGameRequest,
        );
        return () => {
            socket.off(
                SOCKET_EVENTS.HAS_DECLINED_GAME_REQUEST,
                onHasDeclinedGameRequest,
            );
        };
    }, []);

    const addInvitedFriend = async (friend: Friend) => {
        if (auth && auth.user && auth.user.login_token) {
            if (gameID) {
                if (friend.player_code) {
                    const playerIdToAdd = await handleAddPlayer(friend);
                    const api = getRouteManager().inviteFriendToGame();
                    const data = await getRouteManager().fetchData(api, {
                        login_token: auth.user.login_token,
                        playerCode: friend.player_code,
                        gameId: gameID,
                        playerIdToAdd: playerIdToAdd,
                    });
                    setShowInvitationsSent(true);
                }
            }
        }
    };

    const addInvitedPlayerNotFriend = async (player: Player) => {
        if (auth && auth.user && auth.user.login_token) {
            if (gameID) {
                if (player) {
                    const playerIdToAdd = await handleAddPlayer(null, player);
                    const api = getRouteManager().inviteFriendToGameById();
                    const data = await getRouteManager().fetchData(api, {
                        login_token: auth.user.login_token,
                        userId: player.avatar.userId,
                        gameId: gameID,
                        playerIdToAdd: playerIdToAdd,
                    });
                    setShowInvitationsSent(true);
                }
            }
        }
    };

    const createMultipleFriends = (friendList: Friend[]) => {
        if (playersRef?.current && playersRef.current.length) {
            if (isNotEmptyArray(friendList)) {
                // TODO
                friendList.forEach((friend: Friend, index: number) => {
                    addInvitedFriend(friend);
                });
            }
        }

        setShowAddPlayerModal(false);
    };

    const addPlayerToFriendList = async (userId: number) => {
        try {
            if (auth && auth.user && auth.user.login_token) {
                if (isPositiveNumber(userId)) {
                    const api = getRouteManager().addFriendForUserById();
                    const data = await getRouteManager().fetchData(api, {
                        login_token: auth.user.login_token,
                        userId: userId,
                    });
                    if (data && data.flashmessage === "success") {
                        //return { error: null };
                        setShowFriendRequestSent(true);
                        initDataFriends();
                    }
                } else {
                    return { error: strings.friends.invalidCode };
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const findPlayerInFriends = (userId: number): Friend | null => {
        if (friendsList && isNotEmptyArray(friendsList)) {
            const tmpArray = [...friendsList];
            const tmp = tmpArray.find(
                (friend: Friend) =>
                    friend && friend.friendId && friend.friendId === userId,
            );
            return tmp ? tmp : null;
        }
        return null;
    };

    const getPlayersMissingFromSave = (save: any) => {
        if (save && save?.players && isNotEmptyArray(save?.players)) {
            const playersToInvite = save.players;
            const playersList = playersRef.current
                ? [...playersRef.current]
                : [];

            // Returns the players list from save filtered from current user &
            // players already in the game
            const filteredPlayersList = playersToInvite.filter(
                (p: any) =>
                    p.avatar &&
                    p.avatar.userId &&
                    p.avatar.userId !== auth.user.id &&
                    (!isNotEmptyArray(playersList) ||
                        !playersList.find(
                            (p2: any) =>
                                p2[1] &&
                                p2[1].avatar &&
                                p2[1].avatar.userId === p.avatar.userId,
                        )),
            );

            return filteredPlayersList;
        }
    };

    const invitePlayersFromSaveOnLoad = async (save: any) => {
        if (
            save &&
            save?.players &&
            isNotEmptyArray(save?.players) &&
            isHost()
        ) {
            // Returns the players list from save filtered from current user &
            // players already in the game
            const filteredPlayersList = getPlayersMissingFromSave(save);
            if (isNotEmptyArray(filteredPlayersList)) {
                filteredPlayersList.forEach((player: Player, index: number) => {
                    //
                    const isFriend = findPlayerInFriends(player.avatar.userId);
                    if (isFriend) {
                        addInvitedFriend(isFriend);
                    } else {
                        addInvitedPlayerNotFriend(player);
                    }
                });
                setHasInvitedSaveFriends(true);
            }
        }
    };

    useEffect(() => {
        if (
            isReady &&
            isNotEmptyString(currentPlayerIDRef.current) &&
            !hasInvitedSaveFriends &&
            isHost()
        ) {
            handleLoadSaveAndInvitePlayers();
        }
    }, [isReady, currentPlayerIDRef, hasInvitedSaveFriends, host]);

    const handleLoadSaveAndInvitePlayers = async () => {
        const test = await db
            .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}`)
            .once("value", (snapshot) => {
                if (!isValidSnapshot(snapshot, 12)) {
                    return;
                }
                if (snapshot.val().save) {
                    invitePlayersFromSaveOnLoad(snapshot.val().save);
                } else {
                    sethasPlayersFromSaveMissing(false);
                }
                return;
            });
    };

    const handleChangePublicMode = async (value: boolean) => {
        if (
            isHost() &&
            (!episode || (episode && episode.number && episode.number === 1))
        ) {
            await db
                .ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/isPublic`,
                )
                .set(value);
            if (value) {
                setShowGoPublicModal(false);
            } else {
                setShowGoPrivateModal(false);
            }
        }
    };

    const togglePublicSwitch = (value: any) => {
        if (value.target.checked) {
            setShowGoPublicModal(true);
        } else {
            setShowGoPrivateModal(true);
        }
    };

    /**
     * Shortcut to print the player avatar on the player card
     */
    const renderPlayerAvatar = (player: any) => {
        const hasAvatar = propertyExists(player, "avatar");

        const letterBorderColor =
            hasAvatar && propertyExists(player.avatar, "letterBorderColor")
                ? player.avatar.letterBorderColor
                : null;

        if (hasAvatar) {
            return (
                <AvatarContainer
                    user={player}
                    persoWidth={50}
                    borderColor={Colors.WHITE}
                    borderWidth={2}
                />
            );
        }

        // print default player avatar as first username letter
        return (
            <Box sx={[styles.avatarLetter, { borderColor: letterBorderColor }]}>
                <Typography sx={styles.avatarPlayerLetter}>
                    {player?.username ? player.username.charAt(0) : "?"}
                </Typography>
            </Box>
        );
    };

    const _renderPlayerList = () => (
        <Box sx={styles.container}>
            {playersRef.current &&
                playersRef.current.length &&
                playersRef.current.map((item: any, index: number) => {
                    if (isNotEmptyArray(item) && isNotEmptyString(item[0])) {
                        const playerState =
                            waitingPlayerKeys.includes(item[0]) ||
                            !item[1].isReady
                                ? "waiting..."
                                : "Ready";
                        const suffix =
                            item[0] === currentPlayerIDRef.current
                                ? ` (${strings.labels.you})`
                                : null;
                        const isFriend = isPlayerFriend(item[1]);

                        return (
                            <Box
                                sx={styles.playerItemBox}
                                key={`player-${index}`}>
                                {item[0] === playerID ||
                                item[0] === currentPlayerID ? (
                                    <Box sx={[styles.loadingView]} />
                                ) : isFriend === FRIENDS.STATUS.ACCEPTED ? (
                                    <Box sx={[styles.loadingView]}>
                                        <IconCompanions
                                            width={30}
                                            height={30}
                                            fill={Colors.WHITE}
                                        />
                                    </Box>
                                ) : isFriend === FRIENDS.STATUS.INVITED ? (
                                    <Box sx={[styles.loadingView]}>
                                        <IconPlayerInvited
                                            width={30}
                                            height={30}
                                            fill={Colors.WHITE}
                                        />
                                    </Box>
                                ) : (
                                    <ButtonBase
                                        onClick={() =>
                                            item[1]?.avatar?.userId &&
                                            addPlayerToFriendList(
                                                item[1].avatar.userId,
                                            )
                                        }
                                        sx={[
                                            styles.loadingView,
                                            { borderRadius: normalizeSize(30) },
                                        ]}>
                                        <IconAdd
                                            width={30}
                                            height={30}
                                            fill={Colors.ACCENT}
                                        />
                                    </ButtonBase>
                                )}
                                <ButtonBase
                                    sx={[
                                        styles.playerItem,
                                        item[1].isInvitedPlayer
                                            ? { opacity: 0.5 }
                                            : {},
                                    ]}
                                    disabled={!isHost() || item[0] === host.id}
                                    onClick={() => handlePressPlayer(item[0])}>
                                    {renderPlayerAvatar(item[1])}
                                    {item[1].title?.name ? (
                                        <Box sx={styles.nameTitleContainer}>
                                            <Typography
                                                sx={[
                                                    styles.playerName,
                                                    { color: Colors.WHITE },
                                                ]}>
                                                {item[1].username}
                                                {suffix}
                                            </Typography>
                                            <Typography
                                                sx={[
                                                    styles.titleName,
                                                    { color: Colors.ACCENT },
                                                ]}>
                                                {item[1].title.name}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Typography
                                                sx={[
                                                    styles.playerName,
                                                    { color: Colors.WHITE },
                                                ]}>
                                                {item[1].username}
                                                {suffix}
                                            </Typography>
                                        </Box>
                                    )}
                                </ButtonBase>
                                {playerState === "waiting..." ? (
                                    <Box sx={styles.loadingView}>
                                        <Loading size={"small"} />
                                    </Box>
                                ) : null}

                                {playerState === "Ready" ? (
                                    <Box sx={styles.loadingView}>
                                        <CompleteIcon width={30} height={30} />
                                    </Box>
                                ) : null}
                            </Box>
                        );
                    }
                    return null;
                })}
        </Box>
    );

    const _renderFooter = () => (
        <Box sx={styles.footer}>
            {(saveState &&
                saveState.players &&
                getPlayersMissingFromSave(saveState)?.length) ||
            (isValidPlayerMinLimit() &&
                playersRef.current.length < storyPlayerMinLimit) ? (
                <Typography sx={styles.footerText}>
                    {getWaitingToJoinText()}
                </Typography>
            ) : waitingPlayerKeys.length > 0 ? (
                <Typography sx={styles.footerText}>
                    {strings.messages.waitingPlayers}
                </Typography>
            ) : isHost() && showStartGameButton ? (
                <Box sx={styles.buttonView}>
                    <GradientBtn
                        onPress={() => handleLaunchPreGameBackground()}
                        disabled={disableContinueButton}>
                        {strings.lesgo}
                    </GradientBtn>
                </Box>
            ) : (
                <Typography sx={styles.footerText}>
                    {strings.messages.waitingHost}
                </Typography>
            )}
        </Box>
    );

    /**
     *
     */
    const _renderCodeModalContent = () => (
        <Box style={styles.modalCode}>
            <Typography sx={styles.modalCodeText}>{gameID}</Typography>
            <Typography sx={styles.modalCodeSub}>
                {strings.messages.shareGameID}
            </Typography>
        </Box>
    );

    /**
     *
     */
    const _renderConfirmLeaveModalContent = () => (
        <Box style={styles.modalContent}>
            <Typography sx={styles.modalText}>
                {isHost()
                    ? strings.messages.leavingGameForHost
                    : strings.messages.leavingGameForPlayer}
            </Typography>
        </Box>
    );

    /**
     *
     */
    const _renderConfirmRemovePlayerModalContent = () => (
        <Box style={styles.modalContent}>
            <Typography sx={styles.modalText}>
                {strings.messages.removePlayerForGame}
            </Typography>
        </Box>
    );

    const _renderGoPrivateModalContent = () => (
        <Box style={styles.modalContent}>
            <ChatIcon width={120} height={120} fill={Colors.BTN_ACTIVE} />
            <Box sx={{ ...rowCenterStyles }}>
                <Typography component={"span"} sx={styles.modalText}>
                    {strings.online.switchingTo}
                    <Typography
                        component={"span"}
                        sx={[styles.modalText, { color: Colors.WHITE }]}>
                        {strings.online.privateMode}
                    </Typography>
                    {strings.online.switchPrivateText}
                </Typography>
            </Box>
        </Box>
    );

    const _renderGoPublicModalContent = () => (
        <Box style={styles.modalContent}>
            <ChatIcon width={120} height={120} fill={Colors.BTN_ACTIVE} />
            <Box sx={{ ...rowCenterStyles }}>
                <Typography component={"span"} sx={styles.modalText}>
                    {strings.online.switchingTo}
                    <Typography
                        component={"span"}
                        sx={[styles.modalText, { color: Colors.WHITE }]}>
                        {strings.online.publicMode}
                    </Typography>
                    {strings.online.switchPublicText}
                </Typography>
            </Box>
        </Box>
    );

    /**
     * @_drawAddModal
     */
    const _drawAddModal = () => {
        return (
            <Modal
                open={showAddPlayerModal}
                onClose={() => setShowAddPlayerModal(false)}>
                <Box>
                    <SelectFriendModal
                        friends={getAvailableFriends()}
                        createMultipleFriends={createMultipleFriends}
                        currentPlayerCount={
                            playersRef.current?.length
                                ? playersRef.current.length
                                : 0
                        }
                        nbPlayerMax={
                            storyPlayerMaxLimit ? storyPlayerMaxLimit : 6
                        }
                    />
                </Box>
            </Modal>
        );
    };

    const renderInviteRequiredPlayersBtn = () => {
        return (
            <ButtonBase
                sx={[styles.addButton]}
                onClick={() => {
                    SoundManager.getInstance().playBtnSound();
                    handleLoadSaveAndInvitePlayers();
                }}>
                <Typography sx={styles.whiteText}>
                    {strings.online.inviteRequiredPlayers}
                </Typography>
            </ButtonBase>
        );
    };
    /**
     * @lAddBtn
     * render add player btn
     */
    const _drawAddBtn = () => {
        if (
            isHost() &&
            storyPlayerMaxLimit &&
            playersRef &&
            playersRef.current &&
            playersRef.current.length &&
            playersRef.current.length < storyPlayerMaxLimit
        ) {
            if (episode && episode.number && episode.number !== 1) {
                if (saveState) {
                    if (getPlayersMissingFromSave(saveState)?.length) {
                        return renderInviteRequiredPlayersBtn();
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            } else {
                return (
                    <Box
                        sx={{
                            ...rowStartStyles,
                            width: normalizeSize(370),
                            maxWidth: normalizeSize(370),
                            alignSelf: "center",
                        }}>
                        <Box sx={[styles.loadingView]} />
                        <ButtonBase
                            sx={[styles.addButton]}
                            onClick={() => {
                                SoundManager.getInstance().playBtnSound();
                                setShowAddPlayerModal(true);
                            }}>
                            <IconPlayer
                                width={50}
                                height={50}
                                fill={Colors.vertALEXTESGRANDSMORTS}
                            />
                            <Typography
                                sx={[
                                    styles.whiteText,
                                    { color: Colors.vertALEXTESGRANDSMORTS },
                                ]}>
                                {strings.actions.invitePlayers}
                            </Typography>
                        </ButtonBase>
                        <Box sx={[styles.loadingView]} />
                        {_drawAddModal()}
                    </Box>
                );
            }
        }
        return null;
    };

    return (
        <Box
            sx={{
                position: "relative",
                flex: 1,
                zIndex: 1,
                maxWidth: "100vw",
                overflow: "hidden",
                //boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                ...rowCenterStyles,
            }}
            ref={containerRef}>
            <BackgroundRow
                goBack
                goBackAction={() => {
                    //handleQuit();
                    setShowConfirmLeaveModal(true);
                }}
                title={title}
                subtitle={
                    story && story.data && story.data?.intro
                        ? story.data.intro
                        : story.storyTrad?.intro
                        ? story.storyTrad.intro
                        : story.data?.storyTrad?.intro
                        ? story.data.storyTrad.intro
                        : null
                }
                leftPart={
                    <Box>
                        <Snackbar
                            open={showCopied}
                            autoHideDuration={3000}
                            onClose={() => setShowCopied(false)}>
                            <Box sx={styles.snackBar}>
                                <Typography sx={styles.snackBarText}>
                                    {strings.copied}
                                </Typography>
                            </Box>
                        </Snackbar>
                        <Snackbar
                            open={showInvitationsSent}
                            autoHideDuration={3000}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            disableWindowBlurListener={true}
                            onClose={() => setShowInvitationsSent(false)}>
                            <Box sx={styles.snackBar}>
                                <Typography
                                    sx={[
                                        styles.snackBarText,
                                        {
                                            color: Colors.vertALEXTESGRANDSMORTS,
                                        },
                                    ]}>
                                    {strings.online.invitationsSent}
                                </Typography>
                            </Box>
                        </Snackbar>
                        <Snackbar
                            open={showFriendRequestSent}
                            autoHideDuration={3000}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            disableWindowBlurListener={true}
                            onClose={() => setShowFriendRequestSent(false)}>
                            <Box sx={styles.snackBar}>
                                <Typography
                                    sx={[
                                        styles.snackBarText,
                                        {
                                            color: Colors.vertALEXTESGRANDSMORTS,
                                        },
                                    ]}>
                                    {strings.friends.inviteSent}
                                </Typography>
                            </Box>
                        </Snackbar>
                        <Snackbar
                            open={showInvitationsDeclined}
                            autoHideDuration={3000}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            disableWindowBlurListener={true}
                            onClose={() => setShowInvitationsDeclined(false)}>
                            <Box sx={styles.snackBar}>
                                <Typography
                                    sx={[
                                        styles.snackBarText,
                                        { color: Colors.ERROR },
                                    ]}>
                                    {strings.online.invitationDeclined}
                                </Typography>
                            </Box>
                        </Snackbar>
                        {!episode ||
                        (episode && episode.number && episode.number === 1) ? (
                            <Box
                                sx={[
                                    styles.switch,
                                    { marginBottom: normalizeSize(10) },
                                ]}>
                                <Typography sx={styles.switchText}>
                                    {strings.online.public}
                                </Typography>

                                <Box style={styles.switch}>
                                    <Switch
                                        disabled={!isHost()}
                                        sx={{
                                            color:
                                                publicSwitchValue && isHost()
                                                    ? Colors.ACCENT
                                                    : Colors.FOND_ITEM_POPUP,
                                            "& .MuiSwitch-thumb": {
                                                backgroundColor: isHost()
                                                    ? Colors.WHITE
                                                    : Colors.OLD_PRICE,
                                            },
                                            "& .MuiSwitch-track": {
                                                backgroundColor:
                                                    publicSwitchValue &&
                                                    isHost()
                                                        ? Colors.ACCENT +
                                                          "!important"
                                                        : Colors.FOND_ITEM_POPUP +
                                                          "!important",
                                                opacity: publicSwitchValue
                                                    ? 0.9 + "!important"
                                                    : 0.5 + "!important",
                                            },
                                        }}
                                        onChange={togglePublicSwitch}
                                        checked={publicSwitchValue}
                                    />
                                </Box>
                            </Box>
                        ) : null}
                        <ButtonBase
                            sx={styles.gameCode}
                            onClick={() => handlePressCode()}>
                            <Typography style={styles.gameID}>
                                {"Code: "}
                                {gameID}
                            </Typography>
                            <ContentCopyIcon
                                sx={{
                                    color: Colors.OLD_PRICE,
                                    fontSize: normalize(16),
                                    marginLeft: "10px",
                                }}
                            />
                        </ButtonBase>
                        {isValidPlayerMinLimit() ? (
                            <Typography
                                sx={
                                    playersRef.current.length >=
                                    storyPlayerMinLimit
                                        ? styles.minititleOk
                                        : styles.minititle
                                }>
                                {`${playersRef.current.length} / ${storyPlayerMaxLimit} ${playerPlural}`}
                            </Typography>
                        ) : null}
                    </Box>
                }
                rightPart={
                    <Box sx={styles.container}>
                        {_renderPlayerList()}
                        {_drawAddBtn()}
                        {_renderFooter()}
                        {userDoesNotComeFromReboot() && !modalVisible ? (
                            <ClassicModal
                                title={strings.actions.invitePlayers}
                                modalVisible={isHost() && showHostModal}
                                buttonText={strings.understood}
                                onCloseModal={() => setShowHostModal(false)}
                                minHeight={SCREEN_HEIGHT / 5}
                                content={_renderCodeModalContent()}
                            />
                        ) : null}

                        <ClassicModal
                            modalVisible={showGoPublicModal}
                            buttonText={strings.confirm}
                            twoButtons
                            onCloseModal={() => handleChangePublicMode(true)}
                            onCancel={() => setShowGoPublicModal(false)}
                            onBackdropPress={() => setShowGoPublicModal(false)}
                            content={_renderGoPublicModalContent()}
                        />
                        <ClassicModal
                            modalVisible={showGoPrivateModal}
                            buttonText={strings.confirm}
                            twoButtons
                            onCloseModal={() => handleChangePublicMode(false)}
                            onCancel={() => setShowGoPrivateModal(false)}
                            onBackdropPress={() => setShowGoPrivateModal(false)}
                            content={_renderGoPrivateModalContent()}
                        />
                        <ClassicModal
                            title={strings.actions.sure}
                            modalVisible={showConfirmLeaveModal}
                            twoButtons={true}
                            buttonText={strings.actions.leave}
                            onCancel={() =>
                                setShowConfirmLeaveModal(
                                    INITIAL_STATE.showConfirmLeaveModal,
                                )
                            }
                            onCloseModal={() => handleQuit()}
                            minHeight={SCREEN_HEIGHT / 5}
                            titleHeight={SCREEN_HEIGHT / 26}
                            content={_renderConfirmLeaveModalContent()}
                        />

                        <ClassicModal
                            title={strings.actions.remove}
                            modalVisible={showConfirmRemovePlayerModal}
                            twoButtons={true}
                            buttonText={strings.actions.yes}
                            onCancel={() =>
                                handleCloseConfirmRemovePlayerModal()
                            }
                            onCloseModal={() =>
                                handleRemovePlayerById(selectedPlayerId)
                            }
                            minHeight={SCREEN_HEIGHT / 5}
                            titleHeight={SCREEN_HEIGHT / 26}
                            content={_renderConfirmRemovePlayerModalContent()}
                        />
                    </Box>
                }
            />
            {isNotEmptyString(gameID) &&
            ((playerID && isNotEmptyString(playerID)) ||
                (currentPlayerID && isNotEmptyString(currentPlayerID))) &&
            user ? (
                <GameChat
                    gameID={gameID}
                    player={
                        new Player(
                            user.pseudo,
                            user.id,
                            user.avatar,
                            capitalize(user.pseudo.substring(0, 12).trim())[0],
                            Colors.WHITE,
                            Colors.ACCENT,
                            playerID
                                ? playerID
                                : currentPlayerID
                                ? currentPlayerID
                                : "",
                        )
                    }
                    containerRef={containerRef.current}
                    dateColor={Colors.OLD_PRICE}
                    //backgroundUrl={getAnimForType("background")}
                />
            ) : null}
            <FTUEModals modal={FTUE_MODALS.ONLINE} />
        </Box>
    );
};

const styles = {
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
    },
    gameCode: {
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "13px",
        padding: "12px 20px",
    },
    gameID: {
        color: Colors.OLD_PRICE,
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
    },
    minititle: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.BTN_INACTIVE,
        marginTop: "20px",
    },
    minititleOk: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.HEART,
        marginTop: "20px",
    },
    avatarLetter: {
        ...columnCenterStyles,
        width: "50px",
        height: "50px",
        borderRadius: "50px",
        borderWidth: "2px",
        backgroundColor: Colors.PRIMARY,
    },
    avatarPlayerLetter: {
        fontWeight: "bold",
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(18),
        color: Colors.WHITE,
    },
    playerItemBox: {
        ...rowStartStyles,
        width: normalizeSize(370),
        maxWidth: normalizeSize(370),
        alignSelf: "center",
    },
    playerItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: Colors.BTN_ACTIVE,
        width: normalizeSize(300),
        maxWidth: normalizeSize(300),
        alignSelf: "center",
        borderRadius: "13px",
        padding: "15px 20px",
        margin: "8px 16px",
    },
    playerName: {
        color: Colors.WHITE,
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        marginLeft: "13px",
    },
    titleName: {
        fontFamily: "Gilroy-Bold",
        ...columnCenterStyles,
        textAlign: "center",
        fontSize: normalize(13),
        marginLeft: "12px",
    },
    nameTitleContainer: {
        ...columnStyles,
        justifyContent: "center",
        alignItems: "flex-start",
    },
    loadingView: {
        //marginLeft: "auto",
        width: "30px",
        height: "30px",
    },
    snackBar: {
        borderRadius: "10px",
        backgroundColor: Colors.BTN_ACTIVE,
        width: "280px",
        padding: "15px 0px",
        ...columnCenterStyles,
    },
    snackBarText: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
    },
    footer: {
        ...columnCenterStyles,
        width: "100%",
        marginTop: "40px",
    },
    footerText: {
        fontSize: normalize(20),
        fontFamily: "Gilroy-Bold",
        color: Colors.SECONDARY,
        textAlign: "center",
    },
    buttonView: {
        width: "100%",
        ...columnCenterStyles,
    },
    modalContent: {
        ...columnStyles,
        alignItems: "center",
        width: "100%",
    },
    modalCode: {
        ...columnCenterStyles,
    },
    modalCodeText: {
        color: Colors.ACCENT,
        fontSize: normalize(24),
        fontFamily: "Gilroy-Bold",
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "7px 20px",
        borderWidth: "1px",
        borderColor: Colors.BTN_ACTIVE,
        borderRadius: "13px",
        overflow: "hidden",
    },
    modalCodeSub: {
        color: Colors.OLD_PRICE,
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        textAlign: "center",
        marginTop: "15px",
    },
    modalText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
        marginTop: "10px",
    },
    addButton: {
        ...rowCenterStyles,
        alignSelf: "center",
        zIndex: 2,
        //width: normalizeSize(278),
        height: normalizeSize(80),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(13),
        //border: `3px solid ${Colors.BTN_ACTIVE}`,
        marginTop: normalizeSize(24),

        width: normalizeSize(300),
        maxWidth: normalizeSize(300),
        padding: "15px 20px",
        margin: "8px 16px",
    },
    whiteText: {
        color: Colors.WHITE,
        fontSize: normalize(18),
        fontFamily: Gilroy,
        marginLeft: normalizeSize(3),
    },
    switch: {
        ...rowStartStyles,
        columnGap: normalizeSize(8),
    },
    switchText: {
        color: Colors.OLD_PRICE,
        fontSize: normalizeSize(20),
        fontFamily: Gilroy,
    },
};
export default Lobby;
