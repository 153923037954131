/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal } from "@mui/material";
import GradientBorderBox from "../GradientBorderBox";
import { Colors } from "../../style";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";
import Pass from "../Pass";
import { useState } from "react";
import RegisterTemporaryAccountModal from "../RegisterTemporaryAccountModal";
import useAuth from "../../services/Auth/useAuth";

const PassBtn = ({
    callback = null,
    style,
    children,
    borderRadius = 13,
    disabled = false,
}: {
    callback?: any;
    children: any;
    style?: any;
    borderRadius?: any;
    disabled?: boolean;
}) => {
    const auth = useAuth();
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showPassModal, setShowPassModal] = useState(false);

    return (
        <>
            <ButtonBase
                disabled={disabled}
                onClick={
                    auth?.user?.isTemporaryAccount
                        ? () => setShowRegisterModal(true)
                        : callback
                        ? callback
                        : () => setShowPassModal(true)
                }
                sx={[
                    styles.btn,
                    style ? style : {},
                    disabled ? { opacity: 0.5 } : { opacity: 1 },
                ]}>
                <GradientBorderBox
                    color1={!disabled ? Colors.PASS1 : "transparent"}
                    color2={!disabled ? Colors.PASS2 : "transparent"}
                    backgroundColor={Colors.BTN_ACTIVE}
                    borderWidth={2}
                    borderRadius={borderRadius}
                    style={{
                        borderRadius: `${borderRadius}px`,
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        padding: "0px 20px",
                        ...rowCenterStyles,
                    }}>
                    {children}
                </GradientBorderBox>
            </ButtonBase>
            <Modal
                open={showPassModal}
                onClose={() => setShowPassModal(false)}
                sx={{ ...columnCenterStyles }}>
                <Box sx={{ outline: "none" }}>
                    <Pass />
                </Box>
            </Modal>
            <RegisterTemporaryAccountModal
                isOpen={showRegisterModal}
                callback={() => setShowRegisterModal(false)}
                onCloseCallback={() => setShowRegisterModal(false)}
            />
        </>
    );
};

const styles = {
    btn: {
        width: "50%",
        height: "60px",
        borderRadius: "13px",
        ...rowCenterStyles,
    },
};

export default PassBtn;
