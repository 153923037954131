export const levelRewardWaiting = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 90.0000036657751,
  w: 48,
  h: 48,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'UI_level_reward Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [24, 24, 0], ix: 2, l: 2 },
        a: { a: 0, k: [24, 24, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.384, -0.709],
                    [0.492, 0.708],
                    [0.549, 0.317],
                    [1.073, -1.858],
                    [-1.053, -1.218],
                    [0, 0],
                    [0.187, -0.188],
                    [0, -0.265],
                    [0, -0.872],
                    [-0.188, -0.188],
                    [-0.265, 0],
                    [-3.904, 0],
                    [-0.187, 0.187],
                    [0, 0.265],
                    [0, 0.871],
                    [0.188, 0.187],
                    [0.265, 0],
                    [0, 0],
                    [0.85, 1.471],
                    [1.858, -1.073],
                    [0.588, -0.845],
                  ],
                  o: [
                    [-0.384, -0.709],
                    [-0.588, -0.845],
                    [-1.858, -1.073],
                    [-0.85, 1.471],
                    [0, 0],
                    [-0.265, 0],
                    [-0.188, 0.187],
                    [0, 0.871],
                    [0, 0.265],
                    [0.187, 0.187],
                    [3.904, 0],
                    [0.265, 0],
                    [0.188, -0.188],
                    [0, -0.872],
                    [0, -0.265],
                    [-0.187, -0.188],
                    [0, 0],
                    [1.053, -1.218],
                    [-1.073, -1.858],
                    [-0.549, 0.317],
                    [-0.492, 0.708],
                  ],
                  v: [
                    [24, 16.103],
                    [22.651, 13.874],
                    [20.878, 12.021],
                    [15.568, 13.444],
                    [15.995, 17.932],
                    [13, 17.932],
                    [12.293, 18.225],
                    [12, 18.932],
                    [12, 21.932],
                    [12.293, 22.639],
                    [13, 22.932],
                    [35, 22.932],
                    [35.707, 22.639],
                    [36, 21.932],
                    [36, 18.932],
                    [35.707, 18.225],
                    [35, 17.932],
                    [32.005, 17.932],
                    [32.432, 13.444],
                    [27.122, 12.021],
                    [25.349, 13.874],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.816, 0.031],
                    [-0.533, 0.766],
                    [-0.363, 0.209],
                    [-0.521, -0.902],
                    [0.902, -0.521],
                    [0.678, -0.057],
                  ],
                  o: [
                    [0.381, -0.723],
                    [0.388, -0.559],
                    [0.902, -0.521],
                    [0.521, 0.901],
                    [-0.363, 0.21],
                    [-0.93, 0.079],
                  ],
                  v: [
                    [25.569, 17.406],
                    [26.991, 15.017],
                    [28.122, 13.753],
                    [30.7, 14.444],
                    [30.009, 17.021],
                    [28.349, 17.369],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.381, -0.723],
                    [0.93, 0.079],
                    [0.363, 0.21],
                    [-0.521, 0.901],
                    [-0.902, -0.521],
                    [-0.388, -0.559],
                  ],
                  o: [
                    [-0.817, 0.031],
                    [-0.678, -0.057],
                    [-0.902, -0.521],
                    [0.521, -0.902],
                    [0.363, 0.209],
                    [0.533, 0.766],
                  ],
                  v: [
                    [22.431, 17.406],
                    [19.651, 17.369],
                    [17.991, 17.021],
                    [17.3, 14.444],
                    [19.878, 13.753],
                    [21.009, 15.017],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [24, 17.216],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 0.896 },
                    o: { x: 0.333, y: 0 },
                    t: 5,
                    s: [22, 14.216],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0.104 },
                    t: 15,
                    s: [25.706, 14.216],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 25,
                    s: [22, 14.216],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 35.0000014255792, s: [24, 17.216] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [24, 17.216], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [-15],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [15],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 25,
                    s: [-15],
                  },
                  { t: 35.0000014255792, s: [0] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.552, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.552],
                    [0, 0],
                  ],
                  v: [
                    [21.5, 36.5],
                    [21.5, 25.932],
                    [13.5, 25.932],
                    [13.5, 35.5],
                    [14.5, 36.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.552],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.552, 0],
                    [0, 0],
                  ],
                  v: [
                    [34.5, 25.932],
                    [26.5, 25.932],
                    [26.5, 36.5],
                    [33.5, 36.5],
                    [34.5, 35.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24, 31.216], ix: 2 },
              a: { a: 0, k: [24, 31.216], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 0,
      op: 1200.000048877,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'dot',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.625, 5.375, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-7.375, -13.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [10.75, 10.75], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.658382999196, 0.847059003045, 0.139516000187, 1],
                ix: 3,
              },
              o: { a: 0, k: 30, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.913725495338, 0.313725501299, 0.313725501299, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-7.375, -13.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 1200.000048877,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'BG quest',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [24, 13.999, 0], ix: 2, l: 2 },
        a: { a: 0, k: [24, 13.999, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-13.246, 0],
                    [0, -13.246],
                    [13.246, 0],
                    [0, 13.246],
                  ],
                  o: [
                    [13.246, 0],
                    [0, 13.246],
                    [-13.246, 0],
                    [0, -13.246],
                  ],
                  v: [
                    [0, -24],
                    [24, 0],
                    [0, 24],
                    [-24, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.145098039216, 0.149019607843, 0.18431372549, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 2',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24, 24], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90.0000036657751,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
