/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Box, Typography, ButtonBase, Slide, Fade } from "@mui/material";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import {
    columnCenterStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../style/flex";
import { isFunction, isTrueBoolean } from "../../utils/TypeOfHelper";
import normalize from "../../utils/fontSizer";
import GradientBtn from "../GradientBtn";
import StoryCard from "../StoryCard";
import SuccessInfos from "../SuccessInfos";
import { ReactComponent as IconPlayer } from "../../assets/icons/icon_inapp_players.svg";
import { ReactComponent as IconStories } from "../../assets/icons/icon_stories.svg";
import { ReactComponent as TimeIcon } from "../../assets/icons/icon_inapp_time.svg";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon_achievement.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { useEffect, useRef, useState } from "react";
import {
    isPositiveNumber,
    isStrictlyPositiveNumber,
} from "../../utils/NumberHelper";
import SuccessList from "../SuccessList";
import SoundManager from "../../services/SoundManager";
import TicketAnimated from "../TicketAnimated";
import TicketPromoAnimated from "../TicketPromoAnimated";
import LoadingView from "../LoadingView";
import BuyStoryModal from "../BuyStoryModal";
import { styles } from "./style";
import PregameModal from "../PregameModal";
import DynamicTag from "../DynamicTag";
import PassBtn from "../PassBtn";
import PassAnimated from "../PassAnimated";
import GradientText from "../GradientText";
import { capitalize } from "lodash";
import useAuth from "../../services/Auth/useAuth";
import { useNavigate } from "react-router";

interface Props {
    active: any;
    showStoryInfo: boolean;
    onCloseStoryInfo: any;
    callback?: any;
}

const StoryInfoModal = (props: Props) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const { active, showStoryInfo, onCloseStoryInfo, callback = null } = props;

    const [loading, setLoading] = useState(false);
    const [priceState, setPriceState] = useState<any>(
        active.price ? active.price : 0,
    );
    const [showEpisodesModal, setShowEpisodesModal] = useState(false);
    const [showPopUpBuyStory, setShowPopUpBuyStory] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const containerRef = useRef(null);

    if (!active.image_backdrop && active.story?.image_backdrop) {
        active.image_backdrop = active.story.image_backdrop;
    }
    if (!active.image_title && active.story?.image_title) {
        active.image_title = active.story.image_title;
    }

    if (
        active &&
        active?.type &&
        active?.type === "series" &&
        !active.isSeries
    ) {
        active.isSeries = true;
        active.seriesId = active.id;
    }

    active.themes =
        active.isSeries && active.themeSeries?.length
            ? active.themeSeries
            : active.themeStories?.length
            ? active.themeStories
            : null;

    /*   active.storyTrad =
    active.storyTrad && active.storyTrad[0] ? active.storyTrad[0] : null; */

    useEffect(() => {
        if (active.owned) {
            setPriceState(0);
        }
    }, []);

    /* const checkStoryIsOwned = async () => {
    try {
      if (auth && auth.user) {
        const apiUs = getRouteManager().getUserStories(
          getLanguage(),
          auth.user.id,
        );
        const lib = await getRouteManager().getData(`${apiUs}`);
        const tmp = lib.find((item: any) => item.storyId === active.story.id);
        if (tmp) {
          setPriceState(0);
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkStoryIsOwned();
  }, []); */

    const getUserAsPlayer = () => {
        if (auth?.user?.avatar) {
            return {
                idx: 0,
                key: 0,
                name: capitalize(auth.user.pseudo.substring(0, 12).trim()),
                avatar: auth.user.avatar,
                letter: capitalize(auth.user.pseudo.substring(0, 12).trim())[0],
                letterColor: Colors.PRIMARY,
                playerCode: auth.user.player_code,
                letterBorderColor: Colors.WHITE,
                title: auth.user && auth.user.title ? auth.user.title : null,
            };
        } else {
            return null;
        }
    };

    const launchSoloGame = async () => {
        //navigate(navigatePathname, { replace: true });
        const player = getUserAsPlayer();
        if (player) {
            SoundManager.getInstance().removeAllSounds();
            navigate("/game", {
                state: {
                    players: [player],
                    storyId: active.id,
                    title: active?.storyTrad?.title,
                    save: null,
                    episode: null,
                    solo: true,
                    isFTUE: active?.isFTUE,
                    isBoard: false,
                },
            });
        }
    };

    return (
        <Modal open={showStoryInfo} onClose={() => onCloseStoryInfo()}>
            <>
                {showEpisodesModal ? (
                    <Fade in={showEpisodesModal} mountOnEnter>
                        <Box sx={styles.pregameContainer}>
                            <PregameModal
                                active={active}
                                isSeries={
                                    isTrueBoolean(active.isSeries) ||
                                    !!active.seriesId
                                }
                                callback={setShowEpisodesModal}
                                showEpisodesModal={showEpisodesModal}
                            />
                        </Box>
                    </Fade>
                ) : (
                    <Box sx={styles.selectedModal} ref={containerRef}>
                        {loading ? (
                            <LoadingView style={{ minHeight: "420px" }} />
                        ) : (
                            <Box sx={{ ...rowStyles }}>
                                <Box sx={styles.selectedBg}>
                                    <Box
                                        sx={[
                                            styles.selectedBgImage,
                                            active && active.image_backdrop
                                                ? {
                                                      background: `linear-gradient(180deg, rgba(37, 38, 47, 0) 0%, #25262F 100%), center no-repeat url(${active.image_backdrop})`,
                                                  }
                                                : active.story.image_backdrop
                                                ? {
                                                      background: `linear-gradient(180deg, rgba(37, 38, 47, 0) 0%, #25262F 100%), center no-repeat url(${active.story.image_backdrop})`,
                                                  }
                                                : {},
                                        ]}></Box>
                                </Box>
                                <Box sx={styles.selectedStoryContainer}>
                                    <Box sx={styles.closeBtn}>
                                        <ButtonBase
                                            sx={{ borderRadius: "50px" }}
                                            onClick={() => {
                                                SoundManager.getInstance().playCloseModalSound();
                                                onCloseStoryInfo();
                                            }}>
                                            <CloseIcon width={50} height={50} />
                                        </ButtonBase>
                                    </Box>
                                    <Box sx={styles.cover}>
                                        <StoryCard
                                            story={active}
                                            height={"302px"}
                                            width={"188px"}
                                            //absolutelyPositioned={false}
                                        />
                                    </Box>
                                    <Box sx={styles.selectedStoryInfo}>
                                        <Typography
                                            sx={styles.selectedStoryTitle}>
                                            {active?.storyTrad?.title
                                                ? active.storyTrad.title
                                                : strings.errorNoTrad}
                                        </Typography>
                                        {active?.storyTrad?.title ? (
                                            <Box sx={styles.selectedStoryInfos}>
                                                {active?.epNumber ? (
                                                    <Box
                                                        sx={
                                                            styles.infoContainer
                                                        }>
                                                        <DynamicTag
                                                            text={
                                                                strings.episode +
                                                                " " +
                                                                active?.epNumber
                                                            }
                                                            style={styles.tag}
                                                        />
                                                    </Box>
                                                ) : null}
                                                <Box sx={styles.infoContainer}>
                                                    <IconPlayer
                                                        width={25}
                                                        height={25}
                                                        fill={Colors.TOKEN}
                                                    />
                                                    {isTrueBoolean(
                                                        active.isFTUE,
                                                    ) ? (
                                                        <Typography
                                                            sx={
                                                                styles.iconInfo
                                                            }>
                                                            {`${active.nbPlayerMin}`}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={
                                                                styles.iconInfo
                                                            }>
                                                            {`${active.nbPlayerMin}-${active.nbPlayerMax}`}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box sx={styles.infoContainer}>
                                                    <TimeIcon
                                                        width={25}
                                                        height={25}
                                                        fill={Colors.ACCENT}
                                                    />
                                                    <Typography
                                                        sx={[
                                                            styles.iconInfo,
                                                            {
                                                                color: Colors.ACCENT,
                                                            },
                                                        ]}>
                                                        {active.averageTime}
                                                    </Typography>
                                                </Box>
                                                {!priceState ||
                                                priceState === 0 ? (
                                                    <SuccessInfos
                                                        active={active}
                                                    />
                                                ) : null}
                                                {active?.seriesEpisodes
                                                    ?.length ? (
                                                    <Box
                                                        sx={
                                                            styles.infoContainer
                                                        }>
                                                        <IconStories
                                                            width={25}
                                                            height={25}
                                                            fill={
                                                                Colors.EPISODES
                                                            }
                                                        />
                                                        <Typography
                                                            sx={[
                                                                styles.iconInfo,
                                                                {
                                                                    color: Colors.HEART,
                                                                },
                                                            ]}>
                                                            {
                                                                active
                                                                    ?.seriesEpisodes
                                                                    ?.length
                                                            }{" "}
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        ) : null}

                                        <Box sx={styles.themes}>
                                            {active?.themes &&
                                                active?.storyTrad?.title &&
                                                active?.themes.map((t: any) => (
                                                    <Box
                                                        key={
                                                            "theme-" +
                                                            t.theme.id
                                                        }
                                                        sx={styles.storyTheme}
                                                        style={{
                                                            borderColor:
                                                                t.theme.color,
                                                        }}>
                                                        <Typography
                                                            style={{
                                                                color: t.theme
                                                                    .color,
                                                                fontFamily:
                                                                    "Gilroy-Bold",
                                                                fontSize:
                                                                    normalize(
                                                                        11,
                                                                    ),
                                                                textAlign:
                                                                    "center",
                                                            }}>
                                                            {t.theme.name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            {active?.isSolo ? (
                                                <DynamicTag
                                                    text={"Solo"}
                                                    textColor={Colors.WHITE}
                                                />
                                            ) : null}
                                        </Box>
                                        {active?.isSolo ? (
                                            <Typography
                                                sx={[
                                                    styles.storyDescription,
                                                    {
                                                        color: Colors.WHITE,
                                                        marginBottom: 0,
                                                    },
                                                ]}>
                                                {strings.labels.soloOnly}
                                            </Typography>
                                        ) : null}
                                        <Typography
                                            sx={[
                                                styles.storyDescription,
                                                active.isSolo && {
                                                    marginTop: "10px",
                                                },
                                            ]}>
                                            {active.isSeries ||
                                            (active.type &&
                                                active.type === "series")
                                                ? active?.storyTrad?.description
                                                : active?.storyTrad?.resume}
                                        </Typography>
                                        {active?.storyTrad?.title &&
                                        (!priceState || priceState === 0) ? (
                                            <Box sx={[styles.storyButtons]}>
                                                {!isTrueBoolean(
                                                    active.isSaga,
                                                ) &&
                                                !isTrueBoolean(
                                                    active.isFTUE,
                                                ) ? (
                                                    <ButtonBase
                                                        sx={styles.successBtn}
                                                        onClick={() => {
                                                            setShowSuccessModal(
                                                                true,
                                                            );
                                                        }}>
                                                        <IconSuccess
                                                            width={26}
                                                            height={26}
                                                            fill={Colors.ACCENT}
                                                        />
                                                        <Typography
                                                            component={"span"}
                                                            sx={
                                                                styles.successBtnText
                                                            }>
                                                            {strings.success}
                                                        </Typography>
                                                    </ButtonBase>
                                                ) : null}
                                                <Box sx={styles.lesgoBtn}>
                                                    <GradientBtn
                                                        disabled={false}
                                                        onPress={() =>
                                                            callback &&
                                                            isFunction(callback)
                                                                ? callback()
                                                                : active?.isSolo
                                                                ? launchSoloGame()
                                                                : setShowEpisodesModal(
                                                                      true,
                                                                  )
                                                        }
                                                        width={"100%"}
                                                        icon={
                                                            active.seriesEpisodes ? (
                                                                <IconStories
                                                                    width={25}
                                                                    height={25}
                                                                    fill={
                                                                        Colors.WHITE
                                                                    }
                                                                />
                                                            ) : null
                                                        }>
                                                        {active.seriesEpisodes
                                                            ? strings.Episodes
                                                            : strings.lesgo}
                                                    </GradientBtn>
                                                </Box>
                                            </Box>
                                        ) : active.storyTrad?.title &&
                                          isStrictlyPositiveNumber(
                                              priceState,
                                          ) ? (
                                            <Box sx={[styles.storyButtons]}>
                                                {active.seriesEpisodes
                                                    ?.length ? (
                                                    <Box sx={styles.lesgoBtn}>
                                                        <GradientBtn
                                                            disabled={false}
                                                            onPress={() =>
                                                                callback &&
                                                                isFunction(
                                                                    callback,
                                                                )
                                                                    ? callback()
                                                                    : setShowEpisodesModal(
                                                                          true,
                                                                      )
                                                            }
                                                            width={"100%"}
                                                            icon={
                                                                active
                                                                    .seriesEpisodes
                                                                    ?.length ? (
                                                                    <IconStories
                                                                        width={
                                                                            25
                                                                        }
                                                                        height={
                                                                            25
                                                                        }
                                                                        fill={
                                                                            Colors.WHITE
                                                                        }
                                                                    />
                                                                ) : null
                                                            }>
                                                            {active
                                                                .seriesEpisodes
                                                                ?.length
                                                                ? strings.Episodes
                                                                : strings.lesgo}
                                                        </GradientBtn>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={[
                                                            styles.storyButtons,
                                                        ]}>
                                                        <PassBtn
                                                            borderRadius={13}
                                                            style={{
                                                                height: "60px",
                                                                backgroundColor:
                                                                    Colors.PRIMARY,
                                                                ...rowCenterStyles,
                                                                width: "49%",
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    ...rowStartStyles,
                                                                }}>
                                                                <PassAnimated
                                                                    style={{
                                                                        width: 25,
                                                                        height: 25,
                                                                    }}
                                                                />
                                                                <GradientText
                                                                    color1={
                                                                        Colors.PASS1
                                                                    }
                                                                    color2={
                                                                        Colors.PASS2
                                                                    }
                                                                    style={
                                                                        styles.gradientTextSmall
                                                                    }>
                                                                    {
                                                                        strings
                                                                            .pass
                                                                            .unlock
                                                                    }
                                                                </GradientText>
                                                            </Box>
                                                        </PassBtn>

                                                        <ButtonBase
                                                            sx={
                                                                styles.successBtn
                                                            }
                                                            onClick={() =>
                                                                setShowPopUpBuyStory(
                                                                    true,
                                                                )
                                                            }>
                                                            {!active.promo ||
                                                            !active.promo
                                                                ?.discount ? (
                                                                <Box
                                                                    sx={
                                                                        styles.ticketsOwned
                                                                    }>
                                                                    <TicketAnimated
                                                                        style={{
                                                                            width: 25,
                                                                            height: 25,
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        sx={
                                                                            styles.storyPrice
                                                                        }>
                                                                        {
                                                                            priceState
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            ) : active.promo
                                                                  ?.discount ? (
                                                                <Box
                                                                    sx={
                                                                        styles.ticketsOwned
                                                                    }>
                                                                    <TicketPromoAnimated
                                                                        style={{
                                                                            width: 25,
                                                                            height: 25,
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        sx={[
                                                                            styles.storyPrice,
                                                                            {
                                                                                color: Colors.OLD_PRICE,
                                                                                textDecorationLine:
                                                                                    "line-through",
                                                                                textDecorationStyle:
                                                                                    "solid",
                                                                                fontSize:
                                                                                    normalize(
                                                                                        12,
                                                                                    ),
                                                                            },
                                                                        ]}>
                                                                        {
                                                                            priceState
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={[
                                                                            styles.storyPrice,
                                                                            {
                                                                                marginLeft:
                                                                                    "7px",
                                                                                color: Colors.PROMO,
                                                                            },
                                                                        ]}>
                                                                        {Math.ceil(
                                                                            priceState *
                                                                                ((100 -
                                                                                    active
                                                                                        .promo
                                                                                        ?.discount) /
                                                                                    100),
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            ) : null}
                                                        </ButtonBase>
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                                {active &&
                                isPositiveNumber(active.nbUnlock) &&
                                isPositiveNumber(active.nbSuccess) &&
                                !active.isSaga ? (
                                    <Slide
                                        in={showSuccessModal}
                                        container={
                                            containerRef.current
                                                ? containerRef.current
                                                : null
                                        }
                                        direction="up"
                                        //timeout={{ enter: 400, exit: 300 }}
                                    >
                                        <Box sx={styles.successModal}>
                                            <ButtonBase
                                                sx={styles.closeBtn}
                                                onClick={() => {
                                                    SoundManager.getInstance().playCloseModalSound();
                                                    setShowSuccessModal(false);
                                                }}>
                                                <CloseIcon
                                                    width={50}
                                                    height={50}
                                                />
                                            </ButtonBase>
                                            <Typography
                                                component={"span"}
                                                sx={styles.successTitle}>
                                                {strings.success}
                                            </Typography>
                                            <Box>
                                                <span style={styles.successSub}>
                                                    {active.nbUnlock}/
                                                    {active.nbSuccess}{" "}
                                                    {
                                                        strings.achievementsCompleted
                                                    }
                                                </span>
                                                <SuccessList story={active} />
                                            </Box>
                                        </Box>
                                    </Slide>
                                ) : null}
                                <Fade in={showPopUpBuyStory}>
                                    <Box
                                        sx={styles.overlay}
                                        onClick={() =>
                                            setShowPopUpBuyStory(false)
                                        }
                                    />
                                </Fade>
                                {active &&
                                !active.isSaga &&
                                !active.isSeries &&
                                !active.seriesEpisodes?.length ? (
                                    <Slide
                                        in={showPopUpBuyStory}
                                        container={
                                            containerRef.current
                                                ? containerRef.current
                                                : null
                                        }
                                        direction="left">
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                right: 0,
                                                alignSelf: "center",
                                                width: "300px",
                                                height: "100%",
                                                borderRadius:
                                                    "20px 0px 0px 20px",
                                                backgroundColor: Colors.PRIMARY,
                                                ...columnCenterStyles,
                                                zIndex: 50,
                                            }}>
                                            <BuyStoryModal
                                                story={active}
                                                seriesId={
                                                    active.isSeries
                                                        ? active.id
                                                        : undefined
                                                }
                                                price={priceState}
                                                closeModal={() =>
                                                    setShowPopUpBuyStory(false)
                                                }
                                                setPriceState={setPriceState}
                                                isFromModal
                                            />
                                        </Box>
                                    </Slide>
                                ) : null}
                            </Box>
                        )}
                    </Box>
                )}
            </>
        </Modal>
    );
};

export default StoryInfoModal;
