/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    useMediaQuery,
    Box,
    Typography,
    Input,
    InputAdornment,
    ButtonBase,
} from "@mui/material";
import { Formik } from "formik";
import CustomCheckboxRegister from "../../../../components/CheckboxRegister";
import GradientBtn from "../../../../components/GradientBtn";
import { strings } from "../../../../services/translation";
import { Colors } from "../../../../style";
import * as yup from "yup";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import { styles } from "../style";
import { normalizeSize } from "../../../../utils/fontSizer";
import { useCallback, useState } from "react";
import { isNotEmptyString } from "../../../../utils/StringHelper";
import { getRouteManager } from "../../../../services/routeManager";
import GoBackBtn from "../../../../components/GoBackBtn";
import { ReactComponent as IconPseudo } from "../../../../assets/icons/icon_pseudo.svg";
import { columnCenterStyles, rowCenterStyles } from "../../../../style/flex";

interface Props {
    callback: any;
    isTemporaryModal?: boolean;
}

const CgvBox = (props: Props) => {
    const { callback, isTemporaryModal = false } = props;

    const [referral, setReferral] = useState("");
    const [showCgv, setShowCgv] = useState(false);
    //
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);

    const matchesWidth = useMediaQuery("(min-width:800px)");
    const matchesHeight = useMediaQuery("(min-height:800px)");

    const handleError = (error: string, type: string) => {
        if (error.includes("must be") && type === "referral") {
            return strings.code6Characters;
        }
        return error;
    };

    const onSubmitReferralCode = async (code: string) => {
        if (code && isNotEmptyString(code)) {
            const api = getRouteManager().getUserFromCode(code);
            const testCodeExist = await getRouteManager().getData(api);
            if (testCodeExist) {
                if (testCodeExist?.flashmessage === "error") {
                    return false;
                }
                return true;
            }
        }
        return false;
    };

    return (
        <Box
            sx={[
                styles.loginContainer,
                matchesWidth && !isTemporaryModal
                    ? { width: "50%" }
                    : { width: "100%" },
                isTemporaryModal ? { borderRadius: normalizeSize(20) } : {},
            ]}>
            {showCgv ? (
                <GoBackBtn
                    callback={() => {
                        setShowCgv(false);
                    }}
                    style={{}}
                    extraStyle={{
                        borderRadius: "50px",
                        position: "absolute",
                        top: 37,
                        left: 37,
                    }}
                />
            ) : null}

            <Box
                sx={
                    matchesHeight
                        ? { width: "50px", height: "50px" }
                        : { display: "none" }
                }>
                <IconTalesup width={50} height={50} fill={Colors.WHITE} />
            </Box>
            <Typography sx={styles.title}>{strings.register}</Typography>
            <Typography sx={styles.subtitle}>
                {showCgv ? strings.acceptTerms : strings.referral.addReferral}
            </Typography>

            <Formik
                initialValues={{
                    terms: false,
                    privacy: false,
                    referral: referral ? referral : "",
                }}
                onSubmit={(values) => {
                    callback(values);
                }}
                validationSchema={yup.object().shape({
                    terms: yup
                        .bool()
                        .oneOf([true], strings.acceptTermsMandatory),
                    privacy: yup
                        .bool()
                        .oneOf([true], strings.acceptRgpdMandatory),
                    referral: yup.string().min(6).max(6),
                })}>
                {({
                    values,
                    errors,
                    isValid,
                    handleSubmit,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                }) =>
                    showCgv ? (
                        <Box sx={styles.container}>
                            <Box
                                sx={[styles.inputContainer, { width: "100%" }]}>
                                <CustomCheckboxRegister
                                    value={values.terms}
                                    handleChange={(nextValue: any) => {
                                        setFieldValue("terms", nextValue);
                                    }}>
                                    <Typography
                                        sx={styles.cgvText}
                                        component={"span"}>
                                        {strings.agreeTerms}
                                        <Typography
                                            component={"span"}
                                            sx={[
                                                styles.commonText,
                                                { color: Colors.HEART },
                                            ]}
                                            onClick={() => {
                                                window
                                                    .open(
                                                        strings.cguvUrl,
                                                        "_blank",
                                                    )
                                                    ?.focus();
                                            }}>
                                            {strings.terms}
                                        </Typography>
                                    </Typography>
                                </CustomCheckboxRegister>
                                <CustomCheckboxRegister
                                    value={values.privacy}
                                    handleChange={(nextValue: any) => {
                                        setFieldValue("privacy", nextValue);
                                    }}>
                                    <Typography
                                        sx={styles.cgvText}
                                        component={"span"}>
                                        {strings.agreeTerm}
                                        <Typography
                                            component={"span"}
                                            sx={[
                                                styles.commonText,
                                                { color: Colors.HEART },
                                            ]}
                                            onClick={() => {
                                                window
                                                    .open(
                                                        strings.confiUrl,
                                                        "_blank",
                                                    )
                                                    ?.focus();
                                            }}>
                                            {strings.privacyPolicy}
                                        </Typography>
                                    </Typography>
                                </CustomCheckboxRegister>
                            </Box>

                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: matchesHeight
                                        ? "calc(20px + 1vh)"
                                        : "5px",
                                }}>
                                <GradientBtn
                                    disabled={
                                        !isValid ||
                                        errors.terms ||
                                        values.terms != true ||
                                        errors.privacy ||
                                        values.privacy != true
                                    }
                                    onPress={handleSubmit}>
                                    {strings.register}
                                </GradientBtn>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={styles.container}>
                            <Box sx={[styles.inputContainer, { width: "70%" }]}>
                                <Typography sx={styles.label}>
                                    {strings.referral.referralCode}
                                </Typography>
                                <Input
                                    sx={styles.input}
                                    value={values.referral}
                                    disableUnderline
                                    autoFocus
                                    type={"text"}
                                    name="referral"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("referral")}
                                    placeholder={"AAAAAA"}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconPseudo
                                                width={20}
                                                height={20}
                                                fill={Colors.WHITE}
                                            />
                                        </InputAdornment>
                                    }
                                />
                                {errors.referral && (
                                    <Typography
                                        style={{
                                            fontFamily: "Gilroy-Bold",
                                            fontSize: normalizeSize(12),
                                            color: Colors.ERROR,
                                            marginTop: "15px",
                                            textAlign: "center",
                                        }}>
                                        {handleError(
                                            errors.referral,
                                            "referral",
                                        )}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    ...columnCenterStyles,
                                    width: "100%",
                                    marginTop: "calc(30px)",
                                }}>
                                <Box
                                    style={{
                                        width: "100%",
                                        ...rowCenterStyles,
                                        marginTop: "25px",
                                    }}>
                                    <GradientBtn
                                        disabled={
                                            errors.referral ||
                                            values.referral == ""
                                        }
                                        onPress={async () => {
                                            //setLoading(true);
                                            const isValid =
                                                await onSubmitReferralCode(
                                                    values.referral,
                                                );
                                            //setLoading(false);
                                            if (isValid) {
                                                setReferral(values.referral);
                                                setShowCgv(true);
                                            } else {
                                                errors.referral =
                                                    strings.referral.codeDoesntExist;
                                                forceUpdate();
                                            }
                                        }}>
                                        {strings.continue}
                                    </GradientBtn>
                                </Box>
                                <ButtonBase
                                    sx={{
                                        width: "100%",
                                        ...rowCenterStyles,
                                        marginTop: "25px",
                                    }}
                                    onClick={() => {
                                        setShowCgv(true);
                                    }}>
                                    <Typography sx={styles.noCodeText}>
                                        {strings.referral.dontHaveCode}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        </Box>
                    )
                }
            </Formik>
        </Box>
    );
};

export default CgvBox;
