/* eslint-disable @typescript-eslint/no-explicit-any */
import SoundManager from "../../services/SoundManager";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { ButtonBase, Typography } from "@mui/material";

interface Props {
  icon: any;
  text: any;
  onPress: any;
  isAnimated?: any;
  iconAnimated?: any;
}

const CustomOptBtn = (props: Props) => {
  const { text, onPress, isAnimated, iconAnimated } = props;
  return (
    <ButtonBase
      sx={styles.touchable}
      onClick={() => {
        SoundManager.getInstance().playBtnSound();
        onPress();
      }}
    >
      {isAnimated && iconAnimated ? (
        iconAnimated()
      ) : (
        <props.icon height={45} width={45} />
      )}
      <Typography style={styles.text}>{text}</Typography>
    </ButtonBase>
  );
};

const styles = {
  touchable: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
  },
  text: {
    color: Colors.WHITE,
    marginLeft: "15px",
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
  },
};

export default CustomOptBtn;
