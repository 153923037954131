/* eslint-disable @typescript-eslint/no-explicit-any */
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import {
  columnStyles,
  rowWrapStyles,
} from "../../style/flex";

// =======================================================

export const centerStyles: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

// =======================================================

export default {
  container: {
    flex: 1,
    width: "60vh",
    maxWidth: "670px",
    minWidth: "500px",
    //width: "100%",
    ...columnStyles,
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    //marginBottom: "15px",
    padding: "0px 0px",
    zIndex: 15,
  },
  input: {
    borderWidth: "3px",
    borderRadius: "10px",
    textAlign: "center",
    fontSize: 18,
    height: "65px",
    borderColor: Colors.SECONDARY_BRAQUAGE,
    color: Colors.WHITE,
    width: "100%",
    zIndex: 200,
  },
  closeBtn: {
    position: "absolute",
    bottom: "10px",
    zIndex: 31,
  },
  btn: {
    zIndex: 60,
  },
  overlay: {
    position: "absolute",
    backgroundColor: Colors.BLACK,
    opacity: 0.6,
    width: "100%",
    height: "100%",
    ...centerStyles,
    paddingBottom: 0,
    zIndex: 50,
  },
  overlayTxt: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    color: Colors.WHITE,
  },
  overlayTxtTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    color: Colors.WHITE,
    marginBottom: "10px",
  },
  flatlistContainer: {
    ...rowWrapStyles,
    zIndex: 6,
  },
  iconKeyWrapper: {
    width: "21px",
    position: "absolute",
    left: "35%",
    zIndex: 300,
  },
};

// =======================================================
