/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import { ReactComponent as IconLegal } from "../../../assets/icons/icon_legal.svg";
import { ReactComponent as IconConfi } from "../../../assets/icons/icon_confi.svg";
import { ReactComponent as IconAbout } from "../../../assets/icons/icon_about.svg";
import { strings } from "../../../services/translation";
import { Box, Typography, ButtonBase } from "@mui/material";
import { useNavigate } from "react-router";
import BackgroundRow from "../../../components/BackgroundRow";
import { columnStartStyles, rowStyles } from "../../../style/flex";
import BigModal from "../../../components/BigModal";
import { Link } from "react-router-dom";
import SoundManager from "../../../services/SoundManager";

const AboutScreen = () => {
  const navigate = useNavigate();
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [visibleToast, setVisibleToast] = useState(false);
  const [showChoicesModal, setShowChoicesModal] = useState(false);

  const checkConsent = async (val: boolean) => {
    return true;
  };

  const handleButtonPress = async () => {
    await checkConsent(true);
    setVisibleToast(true);
  };

  return (
    <BackgroundRow
      title={strings.about}
      goBack
      goBackAction={() => navigate(-1)}
      rightPart={
        <Box sx={styles.container}>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              setShowAboutModal(true);
            }}
            style={styles.btn}
          >
            <IconAbout width={45} height={45} />
            <Box ml={"18px"}>
              <Typography style={styles.socialTitle}>
                {strings.whoWeAreTitle}
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              window.location.href = strings.legalUrl;
            }}
            style={styles.btn}
          >
            <IconLegal width={45} height={45} />
            <Box ml={"18px"}>
              <Typography style={styles.socialTitle}>
                {strings.legalMentions}
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              window.location.href = strings.cguvUrl;
            }}
            style={styles.btn}
          >
            <IconLegal width={45} height={45} />
            <Box ml={"18px"}>
              <Typography style={styles.socialTitle}>
                {strings.Terms}
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              window.location.href = strings.confiUrl;
            }}
            style={styles.btn}
          >
            <IconConfi width={45} height={45} />
            <Box ml={"18px"}>
              <Typography style={styles.socialTitle}>
                {strings.confidentiality}
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              navigate("cookies");
            }}
            style={styles.btn}
          >
            <IconConfi width={45} height={45} />
            <Box ml={"18px"}>
              <Typography style={styles.socialTitle}>
                {strings.cookies}
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              SoundManager.getInstance().playBtnSound();
              setShowChoicesModal(true);
            }}
            style={styles.btn}
          >
            <IconConfi width={45} height={45} />
            <Box ml={"18px"}>
              <Typography style={styles.socialTitle}>
                {strings.manageChoices}
              </Typography>
            </Box>
          </ButtonBase>

          <BigModal
            modalVisible={showAboutModal}
            callback={() => setShowAboutModal(false)}
            hasCloseIcon
            hasSeparator
            title={strings.whoWeAreTitle}
            contentStyle={{ padding: "0px 50px" }}
            headerStyle={{ padding: "50px 50px 20px 50px" }}
            modalStyle={{ minHeight: "400px", height: "70vh" }}
            content={
              <Box sx={styles.containerModal}>
                {strings.whoWeAre.map((item, index) => (
                  <Box key={"article-" + item.type + index}>
                    {item.type === "title" ? (
                      <Box mt={"10px"}>
                        <Typography style={styles.name}>
                          {item.content}
                        </Typography>
                      </Box>
                    ) : item.type === "text" ? (
                      <Box mt={"5px"}>
                        {item.content !== "" && (
                          <Typography style={styles.role}>
                            {item.content}
                          </Typography>
                        )}
                      </Box>
                    ) : item.type === "image" ? (
                      <Box key={index + 1} mt={"20px"}>
                        {index === 2 ? (
                          <img
                            src={require("../../../assets/images/team.jpg")}
                            style={styles.img}
                          />
                        ) : index === 5 ? (
                          <img
                            src={require("../../../assets/images/promo.jpg")}
                            style={styles.img}
                          />
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                ))}
                <img
                  src={require("../../../assets/images/logo_periple.png")}
                  style={{
                    width: "80px",
                    height: "40px",
                    alignSelf: "center",
                    marginTop: "20px",
                  }}
                />
                <Box height={"80px"} />
              </Box>
            }
          />
          <BigModal
            modalVisible={showChoicesModal}
            callback={() => setShowChoicesModal(false)}
            hasCloseIcon
            hasSeparator
            title={strings.manageChoices}
            contentStyle={{ padding: "50px 50px 20px 50px" }}
            headerStyle={{ padding: "50px 50px 20px 50px" }}
            modalStyle={{
              minHeight: "350px",
              maxHeight: "450px",
              height: "30vh",
            }}
            content={
              <Box sx={styles.containerModal}>
                <Typography
                  style={{
                    color: Colors.OLD_PRICE,
                    fontFamily: "Gilroy-Bold",
                    fontSize: normalize(13),
                    marginBottom: "15px",
                  }}
                >
                  {strings.cookie.cookieExplanation}{" "}
                  <Link
                    to="/menu/about/cookies"
                    style={{ color: Colors.HEART }}
                  >
                    {strings.cookie.cookiePolicy}
                  </Link>
                </Typography>
                <ButtonBase
                  sx={[
                    styles.yesButton,
                    { alignSelf: "center", marginTop: "20px" },
                  ]}
                  onClick={() => {
                    SoundManager.getInstance().playBtnSound();
                    handleButtonPress();
                  }}
                >
                  <Typography sx={styles.yesText}>
                    {strings.consent.manageOptions}
                  </Typography>
                </ButtonBase>
              </Box>
            }
          />
        </Box>
      }
    />
  );
};

const styles = {
  container: { ...columnStartStyles, alignItems: "flex-start" },
  containerModal: { ...columnStartStyles, alignItems: "flex-start" },
  socialTitle: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
  },
  btn: {
    marginTop: "30px",
    ...rowStyles,
    alignItems: "center",
  },
  name: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(18),
    color: Colors.WHITE,
  },
  role: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    color: Colors.OLD_PRICE,
  },
  img: {
    width: "400px",
    height: "230px",
    borderRadius: "13px",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(13),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
};

export default AboutScreen;
