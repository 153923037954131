/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from "react";
import ColorManager from "../../services/colorManager";
import normalize from "../../utils/fontSizer";
import { strings } from "../../services/translation";
import styles from "./styles";
import { ReactComponent as IconHint } from "../../assets/icons/icon_hint.svg";
import { Box, Fade, Typography } from "@mui/material";

// =======================================================

interface Props {
  colorAccent: any;
  textColor: any;
  notif: any;
  callback: any;
}

// =======================================================

const HintPopup = (props: Props) => {
  /**
   *
   */
  const { colorAccent, textColor, callback, notif } = props;

  notif.remove = true;

  const [shouldShow, setShouldShow] = useState(true);

  const [exist, setExist] = useState(true);

  const onAnimationEnd = () => {
    setTimeout(() => {
      setShouldShow(false);
      setTimeout(() => {
        setExist(false);
        callback(notif);
      }, 800);
    }, 3500);
  };

  if (exist) {
    return (
      <Fade
        in={shouldShow}
        appear
        addEndListener={() => {
          onAnimationEnd();
        }}
        timeout={600}>
        <Box
          sx={[
            styles.popBg,
            { backgroundColor: ColorManager.getInstance().getColor("quinary") },
          ]}>
          <IconHint
            width={48}
            height={48}
            fill={ColorManager.getInstance().getColor("primary")}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
          <Box sx={styles.rightPart}>
            <Typography sx={[styles.normalText, { color: colorAccent }]}>
              {strings.topMenu.hint}
            </Typography>
            <Typography
              sx={[
                styles.normalText,
                {
                  color: textColor,
                  fontSize: normalize(14),
                  justifyContent: "center",
                  flexShrink: 1,
                },
              ]}>
              {strings.topMenu.newHint}
            </Typography>
          </Box>
        </Box>
      </Fade>
    );
  } else {
    return null;
  }
};

// =======================================================

export default React.memo(HintPopup);
