export const storyShinyAnim = {
  v: '5.9.6',
  fr: 29.9700012207031,
  ip: 0,
  op: 120.0000048877,
  w: 250,
  h: 400,
  nm: 'Story',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'shiny',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [25.5, 100, 0],
              to: [0, 119.167, 0],
              ti: [0, -119.167, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 20,
              s: [25.5, 815, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 120.0000048877, s: [25.5, 815, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [41, 448], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 30, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.5, -241.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [110, 100], ix: 3 },
              r: { a: 0, k: 45, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'shiny',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 600.000024438501,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
