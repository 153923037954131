/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../style";
import { columnCenterStyles, columnStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import { CompanionList } from "../../pages/CompanionScreen/CompanionList";
import Separator from "../Separator";
import { Companion } from "../../models/companion";
import { useState } from "react";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import { strings } from "../../services/translation";
import SoundManager from "../../services/SoundManager";
// ICONS
import { ReactComponent as IconCompanions } from "../../assets/icons/icon_companion.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { ReactComponent as IconCompleted } from "../../assets/icons/icon_completed2.svg";

const SelectCompanionModal = ({
    companions,
    createMultipleCompanions,
    currentPlayerCount,
    nbPlayerMax,
}: {
    companions: Companion[] | null;
    createMultipleCompanions: (companions: Companion[]) => void;
    currentPlayerCount: number;
    nbPlayerMax: number;
}) => {
    const [playerCount, setPlayerCount] = useState(currentPlayerCount);
    const [selectedCompanions, setSelectedCompanions] = useState<Companion[]>(
        [],
    );

    const updateCompanionList = (companion: Companion) => {
        const shouldSelect =
            !isNotEmptyArray(selectedCompanions) ||
            !selectedCompanions.includes(companion);
        if (shouldSelect) {
            if (playerCount + 1 <= nbPlayerMax) {
                selectCompanion(companion);
            }
        } else {
            unselectCompanion(companion);
        }
    };

    const selectCompanion = (companion: Companion) => {
        setSelectedCompanions((current: Companion[]) => [
            ...current,
            companion,
        ]);
        setPlayerCount((current: number) => current + 1);
    };

    const unselectCompanion = (companion: Companion) => {
        setSelectedCompanions((prev: Companion[]) => [
            ...prev.filter((entry: any) => entry != companion),
        ]);
        setPlayerCount((current: number) => current - 1);
    };

    return (
        <Box sx={styles.companionModal}>
            <Box sx={styles.modalHeader}>
                <ButtonBase
                    onClick={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        createMultipleCompanions(selectedCompanions);
                    }}
                    sx={styles.closeModalBtn}>
                    {selectedCompanions &&
                    isNotEmptyArray(selectedCompanions) ? (
                        <IconCompleted
                            width={30}
                            height={30}
                            style={{ marginTop: "5px" }}
                        />
                    ) : (
                        <CloseIcon width={50} height={50} />
                    )}
                </ButtonBase>
                <IconCompanions width={40} height={40} fill={Colors.WHITE} />
                <Typography sx={styles.modalTitle}>
                    {strings.companions.companions}
                </Typography>
                {selectedCompanions && isNotEmptyArray(selectedCompanions) ? (
                    <Typography sx={styles.modalSubtitle}>
                        {`${selectedCompanions.length} ${
                            strings.companions.selectedCompanions
                        } ${playerCount >= nbPlayerMax ? "(MAX)" : ""}`}
                    </Typography>
                ) : null}
            </Box>
            <Separator />
            <Box sx={styles.companionModalBody}>
                <CompanionList
                    companions={companions}
                    setActiveCompanion={(comp: any) => {
                        updateCompanionList(comp);
                    }}
                    activeCompanion={null}
                    purchaseSlotCallback={() => null}
                    forceRowList={true}
                    selectedCompanions={selectedCompanions}
                />
            </Box>
        </Box>
    );
};

const styles = {
    companionModal: {
        ...columnCenterStyles,
        backgroundColor: Colors.PRIMARY,
        width: "400px",
        borderRadius: "20px",
        maxHeight: "max(475px, 50vh)",
        padding: "10px 0px",
    },
    modalHeader: {
        width: "100%",
        padding: "20px",
        ...columnCenterStyles,
        rowGap: "10px",
        position: "relative",
    },
    modalTitle: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: "18px",
    },
    modalSubtitle: {
        color: Colors.HEART,
        fontFamily: Gilroy,
        fontSize: "14px",
    },
    companionModalBody: {
        ...columnStyles,
        alingItems: "center",
        overflow: "auto",
        scrollbarWidth: "none",
        scrollBehaviour: "smooth",
        "::-webkit-scrollbar": {
            width: "5px",
        },
        "::-webkit-scrollbar-track": {
            background: Colors.BTN_ACTIVE,
            borderRadius: "5px",
        },

        "::-webkit-scrollbar-thumb": {
            background: Colors.OLD_PRICE,
            borderRadius: "5px",
        },

        "::-webkit-scrollbar-thumb:hover": {
            background: Colors.BTN_INACTIVE,
        },
    },
    closeModalBtn: {
        position: "absolute",
        width: "50px",
        height: "50px",
        top: "0px",
        right: "10px",
        borderRadius: "50px",
        backgroundColor: Colors.BTN_ACTIVE,
        ...columnCenterStyles,
    },
};

export default SelectCompanionModal;
