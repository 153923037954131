/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from "react";

//import Animated from 'react-native-reanimated';
import Choice from "../Choice";
import ColorManager from "../../services/colorManager";
import { ReactComponent as IconKey } from "../../assets/icons/icon_key.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/icon_next.svg";
import { ReactComponent as IconVoted } from "../../assets/icons/icon_voted.svg";
import { ReactComponent as IconBlocked } from "../../assets/icons/icon_blocked.svg";
import StoryReader from "../../services/StoryReader";
import styles, { centerStyles } from "./styles";
import _ from "lodash";
import { ChoiceTypeEnum, IChoice } from "../../services/StoryReader/interfaces";
import { Colors } from "../../style";
import {
  countDuplicates,
  findByKeyValue,
  isNotEmptyArray,
} from "../../utils/ArrayHelper";
import { isBoolean } from "lodash";
import { isFunction, isNumber } from "../../utils/TypeOfHelper";
import { isNotEmptyString } from "../../utils/StringHelper";
import { propertyExists } from "../../utils/ObjectHelper";
import { strings } from "../../services/translation";
import Loading from "../Loading";
import { Box, ButtonBase, InputAdornment, InputBase } from "@mui/material";
import {
  columnCenterStyles,
  columnStyles,
  rowWrapStyles,
} from "../../style/flex";
import PopUpIndicator from "../PopUpIndicator";

// =======================================================

interface Props {
  choices: Array<IChoice>;
  players: Array<any>;
  goNext: (choice: IChoice, index?: number, indexPlayer?: number) => void;
  choicesType: ChoiceTypeEnum;
  onSubmitTextbox: any;
  storyId: number;
  color: any;
  storyReader: StoryReader;
  bgColor: any;
  textColor: any;
  choiceColor: any;
  visitedScenes: Array<number>;
  toggleBottom: any;
  showStatePopUp: boolean;
  choiceTimerSelect: number;
  timerCallback: () => void;
  selectedChoices?: Array<any>;
  toggleOverlay: any;
  setAskExit?: any;
  disabled?: boolean;
  disabledText?: string | null;
  percent?: any;
  loading?: boolean;
  onEndGame?: any;
  overlay?: boolean;
  animUrl?: string | null;
  setShowReport?: any;
}

// =======================================================

const Choices = (props: Props) => {
  const {
    choices,
    goNext,
    choicesType,
    onSubmitTextbox,
    players,
    storyReader,
    visitedScenes,
    choiceColor,
    showStatePopUp,
    choiceTimerSelect,
    timerCallback,
    storyId,
    color,
    selectedChoices,
    setAskExit,
    onEndGame = null,
    disabled = false,
    disabledText = null,
    percent = null,
    loading = false,
    animUrl = null,
    setShowReport = null,
  } = props;

  let drawChoices;
  // ====== Local states ======
  const [overlayText, setOverlayText] = useState<any>("");
  const [choiceTimerSelectState, setChoiceTimerSelectState] = useState<
    number | null
  >();
  const [showPopUpIndicator, setShowPopUpIndicator] = useState(false);

  // ====== Local refs ======
  const animatedChoices = useRef<any>(null);

  const containerRef = useRef(null);

  /**
   *
   */
  useEffect(() => {
    let isActive = true;

    if (isActive) {
      setChoiceTimerSelectState(choiceTimerSelect);
    }

    return () => {
      isActive = false;
    };
  }, [choiceTimerSelect]);

  /**
   * Shortcut to get number of voters for the given choice
   */
  const getNumberOfVoters = useCallback(
    (choice: any): number => {
      let nbrVoters = 0; // default

      if (isNotEmptyArray(selectedChoices)) {
        // searching in array of objects
        let found: any = null;

        if (choicesType === ChoiceTypeEnum.PLAYER) {
          // case when it's a players selection
          // to get `count` property [each count value means `a voté`]
          const finalChoices = countDuplicates(selectedChoices, "playerIndex");
          found = findByKeyValue(finalChoices, "playerIndex", choice.id);
        } else {
          // case when it's a standard selection
          const finalChoices: any = countDuplicates(selectedChoices, "text");
          found = findByKeyValue(finalChoices, "text", choice.text);
        }

        if (propertyExists(found, "count")) {
          nbrVoters = found.count;
        }
      }

      return nbrVoters;
    },
    [selectedChoices],
  );

  /**
   * @see https://reactnative.dev/docs/textinput#onsubmitediting
   */
  const handleSubmitTextInput = (value: any): void => {
    if (value.length >= 1) {
      onSubmitTextbox(value.trim());
    }
    setOverlayText("");
  };

  /**
   * Shortcut to redirect user to the report screen
   */
  const goToReport = (choice: IChoice): void => {
    if (isFunction(setAskExit)) {
      setAskExit(false);
    }
    if (onEndGame !== null && isFunction(onEndGame)) {
      // online mode
      onEndGame(choice);
    } else {
      // local mode
      if (isFunction(setShowReport)) setShowReport(true);
    }
  };

  /**
   *
   */
  const isLoading = useCallback((): boolean => {
    return isBoolean(loading) && loading;
  }, [loading]);

  /**
   *
   */
  const renderLoading = () => (
    <Loading
      color={ColorManager.getInstance().getColor("tertiary")}
      size={"normal"}
    />
  );

  const renderVotedIcon = () => {
    if (isNotEmptyString(disabledText)) {
      if (disabledText === strings.messages.notConcerned) {
        return (
          <Box style={{ marginBottom: "5px" }}>
            <IconBlocked
              width={25}
              height={25}
              fillSecondary={ColorManager.getInstance().getColor("tertiary")}
            />
          </Box>
        );
      } else if (disabledText === strings.messages.alreadyVoted) {
        return (
          <Box style={{ marginBottom: "5px" }}>
            <IconVoted
              width={25}
              height={25}
              fillSecondary={ColorManager.getInstance().getColor("tertiary")}
            />
          </Box>
        );
      }
    }
    return null;
  };

  // --
  if (
    choicesType === ChoiceTypeEnum.NORMAL ||
    choicesType === ChoiceTypeEnum.RANDOM
  ) {
    drawChoices = (
      <Box sx={{ ...columnCenterStyles, width: "100%" }}>
        {isLoading() ? renderLoading() : renderVotedIcon()}
        <Box sx={{ ...rowWrapStyles, width: "100%" }}>
          {choices.map((choice, index) => (
            <Box key={"choice-" + index} style={{ zIndex: 11 }}>
              <Choice
                goNext={() => goNext(choice, -1, -1)}
                text={choice.text}
                storyId={storyId}
                color={color}
                ornament={choice.ornament}
                conditions={choice.conditions}
                sceneLink={choice.sceneLink}
                visitedScenes={visitedScenes}
                storyReader={storyReader}
                choiceTimerSelect={choiceTimerSelectState}
                choiceIndex={index}
                timerCallback={() => timerCallback()}
                nbrVoters={getNumberOfVoters(choice)}
                disabled={disabled}
                loading={isLoading()}
                showPopUpIndicator={setShowPopUpIndicator}
                animUrl={animUrl}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );

    if (_.isEmpty(choices) === false) {
      if (choices[0].text === "FIN") {
        drawChoices = (
          <Box sx={{ ...columnCenterStyles }}>
            {isLoading() ? renderLoading() : renderVotedIcon()}
            <Choice
              goNext={() => goToReport(choices[0])}
              text={strings.end}
              storyId={storyId}
              color={color}
              choiceTimerSelect={choiceTimerSelectState}
              choiceIndex={0}
              timerCallback={() => timerCallback()}
              nbrVoters={getNumberOfVoters(choices[0])}
              disabled={disabled}
              loading={isLoading()}
              showPopUpIndicator={() => setShowPopUpIndicator(true)}
              animUrl={animUrl}
              storyReader={storyReader}
            />
          </Box>
        );
      }

      if (
        choices.length === 1 &&
        (choices[0].text === "Continuer" || choices[0].text === "Continue")
      ) {
        drawChoices = (
          <Box sx={{ ...columnCenterStyles }}>
            {!showStatePopUp && (
              <Choice
                goNext={() => goNext(choices[0], -1, -1)}
                text="DrawArrow"
                storyId={storyId}
                color={color}
                choiceColor={choiceColor}
                choiceTimerSelect={choiceTimerSelectState}
                choiceIndex={0}
                timerCallback={() => timerCallback()}
                percent={percent}
                loading={loading}
                disabled={disabled}
                showPopUpIndicator={() => setShowPopUpIndicator(true)}
                disabledText={disabledText}
                animUrl={animUrl}
                storyReader={storyReader}
              />
            )}
          </Box>
        );
      }
    }
  } else if (choicesType === ChoiceTypeEnum.PLAYER) {
    drawChoices = (
      <>
        {isLoading() ? renderLoading() : renderVotedIcon()}
        {/*  <Choice
          goNext={() => null}
          text={strings.choose}
          callback={() => {
            if (!isLoading()) {
              sheetRef.current?.snapTo(0);
              props.toggleBottom(true);
            }
          }}
          oneTap
          storyId={storyId}
          color={color}
          textColor={props.textColor}
        /> */}
      </>
    );
  } else {
    drawChoices = (
      <Box sx={{ ...columnCenterStyles }}>
        {isLoading() ? renderLoading() : renderVotedIcon()}
        <Box sx={[centerStyles, { width: "63%", zIndex: 300 }]}>
          <InputBase
            onClick={() => (disabled ? setShowPopUpIndicator(true) : {})}
            sx={[
              styles.input,
              {
                backgroundColor:
                  ColorManager.getInstance().getColor("secondary"),
                fontFamily: "Gilroy-Bold",
                borderRadius: 100,
              },
            ]}
            startAdornment={
              <InputAdornment position="start" sx={{ marginLeft: "25px" }}>
                <IconKey
                  width={21}
                  height={21}
                  fill={ColorManager.getInstance().getColor("quaternary")}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  marginRight: "20px",
                  zIndex: 99,
                  width: "40px",
                  height: "40px",
                  borderRadius: "40px",
                }}>
                <ButtonBase
                  sx={{ width: "40px", height: "40px", borderRadius: "40px" }}
                  onClick={() => handleSubmitTextInput(overlayText)}>
                  <NextIcon
                    width={40}
                    height={40}
                    fill={ColorManager.getInstance().getColor("secondary")}
                    fillSecondary={Colors.WHITE}
                  />
                </ButtonBase>
              </InputAdornment>
            }
            disabled={disabled}
            spellCheck={false}
            onChange={(event: any) => setOverlayText(event.target.value)}
            onSubmit={(event: any) => handleSubmitTextInput(event.target.value)}
            onKeyUp={(event: any) =>
              event.key && event.key === "Enter"
                ? handleSubmitTextInput(event.target.value)
                : null
            }
            placeholder={strings.enter}
            value={overlayText}
          />
        </Box>
      </Box>
    );
  }

  /**
   *
   */
  const renderItem = ({ item, index }: any) => (
    <Box key={"choice-" + index} sx={{ ...columnCenterStyles }}>
      <Choice
        goNext={() => {
          goNext(choices[0], item.id, index);
        }}
        text={item.name}
        callback={() => null}
        storyId={storyId}
        color={color}
        textColor={color}
        choiceTimerSelect={choiceTimerSelectState}
        choiceIndex={index}
        timerCallback={() => {
          timerCallback();
        }}
        nbrVoters={getNumberOfVoters(item)}
        disabled={disabled}
        loading={isLoading()}
        showPopUpIndicator={() => setShowPopUpIndicator(true)}
        animUrl={animUrl}
        storyReader={storyReader}
      />
    </Box>
  );

  /**
   *
   */
  const renderPopUpIndicator = (
    withoutArrow = false,
    shouldGoUp: number | null = null,
  ) => {
    if (showPopUpIndicator && isNotEmptyString(disabledText) && !isLoading()) {
      return (
        <Box style={{ zIndex: 320, ...columnStyles }} ref={containerRef}>
          <PopUpIndicator
            onCancel={() => setShowPopUpIndicator(false)}
            text={disabledText}
            withoutArrow={withoutArrow}
            shouldGoUp={shouldGoUp}
            containerRef={containerRef}
          />
        </Box>
      );
    }

    return null;
  };

  /**
   *
   */
  const renderContent = () => {
    let newPlayers = players;

    if (
      choices[0] &&
      choices[0].playerStateForbidden &&
      choices[0].playerStateForbidden != ""
    ) {
      const filteredPlayers = newPlayers.filter(
        p => !p.hasState(choices[0].playerStateForbidden),
      );

      newPlayers = filteredPlayers;
    }

    return (
      <Box sx={[{ zIndex: 5, width: "100%" }, styles.flatlistContainer]}>
        {/* <Box style={{ zIndex: 320, ...centerStyles }}>
          {isLoading() ? renderLoading() : renderVotedIcon()}
          {renderPopUpIndicator(true, 0)}
        </Box> */}
        {newPlayers &&
          newPlayers.map((item: any, index: any) =>
            renderItem({ item, index }),
          )}
      </Box>
    );
  };

  // --
  if (isNumber(choiceTimerSelect)) {
    const isPlayerChoice = choicesType === ChoiceTypeEnum.PLAYER;

    return (
      <Box sx={[styles.container]} ref={animatedChoices}>
        {renderPopUpIndicator()}
        {drawChoices}
        {isPlayerChoice ? renderContent() : null}
      </Box>
    );
  }

  return null;
};

// =======================================================

export default Choices;
