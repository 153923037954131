import { Colors } from "../../style";
import { columnStyles, rowCenterStyles, rowStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import { normalizeSize } from "../../utils/fontSizer";

export const styles = {
    all: {
        width: "100%",
        height: "100%",
        ...columnStyles,
        backgroundColor: Colors.PRIMARY,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%), url(${require("../../assets/images/background.jpg")})`,
    },
    body: {
        height: "90vh",
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    },
    // MENU
    rewardMenu: {
        ...rowCenterStyles,
        marginTop: normalizeSize(20),
        flex: "0 0 auto",
    },
    menuContainer: {
        with: "100%",
        height: normalizeSize(40),
        ...rowStyles,
        backgroundColor: Colors.PRIMARY,
        borderRadius: normalizeSize(40),
    },
    menuBtn: {
        position: "relative",
        padding: "8px 30px",
        ...rowCenterStyles,
        borderRadius: normalizeSize(100),
    },
    menuBtnActive: {
        backgroundColor: Colors.BTN_ACTIVE,
    },
    topMenuItemActive: {
        backgroundColor: Colors.BTN_ACTIVE,
    },
    menuBtnText: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        color: Colors.OLD_PRICE,
    },
    notifIcon: {
        position: "absolute",
        top: normalizeSize(10),
        right: normalizeSize(10),
        width: normalizeSize(8),
        height: normalizeSize(8),
        borderRadius: normalizeSize(8),
        backgroundColor: Colors.PROMO,
    },
    iconBox: {
        width: "30px",
        height: "20px",
        position: "relative",
    },
};
