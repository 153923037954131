/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, ButtonBase } from "@mui/material";
import { strings } from "../../services/translation";
import CoverTitleSmallFlexible from "../coverTitleSmallFlexible";
import { Colors } from "../../style";
import { columnStyles } from "../../style/flex";
import normalize from "../../utils/fontSizer";
import { Save } from "../../models/save";
import { ReactComponent as IconDelete } from "../../assets/icons/icon_trash.svg";

interface Props {
  save: Save;
  isLast?: boolean | undefined;
  hasDelete?: boolean | undefined;
  onDelete?: any;
  onClick?: any;
  style?: any;
}
//index === saves.length - 1
const SaveBtn = (props: Props) => {
  const {
    save,
    hasDelete = false,
    onDelete = null,
    onClick = null,
    style,
  } = props;
  return (
    <Box
      onClick={() => (onClick !== null ? onClick() : null)}
      key={"save-" + save.type + "-" + save.id}
      sx={[
        styles.saveContainer,
        style ? style : {},
        onClick !== null
          ? {
            "&:hover": {
              cursor: "pointer",
            },
          }
          : {},
      ]}
    >
      <Box sx={styles.coverContainer}>
        <CoverTitleSmallFlexible
          cover={save.image_backdrop ? save.image_backdrop : null}
          title={save.image_title ? save.image_title : null}
          isAnimated={true}
          width={"86px"}
          height={"139.5px"}
        />
      </Box>

      <Box sx={styles.saveMiddleContainer}>
        <Typography sx={styles.socialTitle}>
          {save?.story?.title ? save.story.title : strings.notAvailable}
        </Typography>
        <Typography component="span" sx={styles.socialSubTitle}>
          {strings.episode}{" "}
          {save?.story?.episode?.number ? save.story.episode.number : "?"}
          {save?.type ? (
            <Typography
              component="span"
              sx={[
                styles.socialSubTitle,
                { backgroundColor: Colors.PRIMARY, borderRadius: "7px", padding: "5px 7px", marginLeft: "7px" },
                save.type === "normal" ?
                  { color: Colors.ACCENT }
                  : save.type === "solo" ?
                    { color: Colors.WHITE }
                    : save.type === "online" ?
                      { color: Colors.HEART } : save.type === "board" ?
                        { color: Colors.PASS2 } : {}
              ]}
            >
              {save.type}
            </Typography>
          ) : null}
        </Typography>
        <Typography sx={styles.players}>{save.players}</Typography>
      </Box>

      {hasDelete ? (
        <Box sx={styles.saveEndContainer}>
          <ButtonBase onClick={() => onDelete(save)}>
            <IconDelete width={32} height={32} />
          </ButtonBase>
          <Typography sx={styles.date}>{save.date}</Typography>
        </Box>
      ) : (
        <Box sx={[styles.saveEndContainer, { justifyContent: 'flex-end' }]}>
          <Typography sx={[styles.date]}>{save.date}</Typography>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  saveContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "7px",
    borderRadius: "13px",
    maxWidth: "370px",
    width: "100%",
    height: "100%",
  },
  coverContainer: {
    width: "86px",
    height: "139.5px",
    marginRight: "7px",
  },
  saveMiddleContainer: {
    ...columnStyles,
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    paddingRight: "10px",
  },
  saveEndContainer: {
    ...columnStyles,
    height: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end",
    position: "relative",
  },
  socialTitle: {
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    marginBottom: "5px",
  },
  socialSubTitle: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.ACCENT,
    marginBottom: "5px",
  },
  players: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.GRAY_TEXT,
  },
  date: {
    fontSize: normalize(12),
    fontFamily: "Gilroy-Bold",
    color: Colors.GRAY_TEXT,
  },
};

export default SaveBtn;
