/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material"
import { Companion } from "../../../models/companion"
import { styles } from "../styles"
import { useCallback, useEffect, useState } from "react";
import { Item } from "../../../models/item";
import SoundManager from "../../../services/SoundManager";
import { getRouteManager } from "../../../services/routeManager";
import useAuth from "../../../services/Auth/useAuth";
import AvatarItemEditUser from "../../../components/avatarItemEditUser";
import AvatarItemShop from "../../../components/avatarItemShop";
import AvatarContainer from "../../../components/AvatarContainer";
import { columnCenterStyles } from "../../../style/flex";
import { Colors } from "../../../style";
import { strings } from "../../../services/translation";
import AvatarItemMenu from "../../../components/AvatarItemMenu";
//ICONS
import { ReactComponent as IconEdit } from "../../../assets/icons/icon_edit.svg";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { ChangePseudoModal } from "./ChangePseudoModal";
import BigModal from "../../../components/BigModal";
import TitleModal from "../../../components/TitleModal";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ReactComponent as IconSlot } from "../../../assets/icons/icon_avatar_slot.svg";
import TicketAnimated from "../../../components/TicketAnimated";
import { BuyCompanionSlotModal } from "../BuyCompanionSlotModal";
import Separator from "../../../components/Separator";
import ClassicModal from "../../../components/ClassicModal";
import { COMPANIONS } from "../../../constants";

export const EditCompanion = (
    {
        companion,
        items,
        changeActiveCompanion,
        buyItemCallback,
        purchaseSlotCallback,
        goToNextIndex,
        goToPrevIndex,
    }:
        {
            companion: Companion,
            items: Item[],
            changeActiveCompanion: any,
            buyItemCallback: any,
            purchaseSlotCallback: any,
            goToNextIndex: any,
            goToPrevIndex: any,
        }) => {

    /** 
     * 
     * STATES
     * 
     * */
    const navigate = useNavigate();
    const auth = useAuth();
    const [avatar, setAvatar] = useState<any>(companion.avatar);
    const [loading, setLoading] = useState(true);
    const [showPopUpChangePseudo, setShowPopUpChangePseudo] = useState(false);
    const [showPopUpChangeTitle, setShowPopUpChangeTitle] = useState(false);
    const [showPopUpMission, setShowPopUpMission] = useState(false);
    const [showPopUpCantEquip, setShowPopUpCantEquip] = useState(false);
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);
    //items lists
    const [perso, setPerso] = useState<Item[] | null>(null);
    const [hat, setHat] = useState<Item[] | null>(null);
    const [bust, setBust] = useState<Item[] | null>(null);
    const [mask, setMask] = useState<Item[] | null>(null);
    const [background, setBackground] = useState<Item[] | null>(null);
    // MENU 
    const [menuActiveIndex, setMenuActiveIndex] = useState(0);
    const [showPurchaseSlotModal, setShowPurchaseSlotModal] = useState(false);


    //purchaseCompanionSlotForUser

    /** 
     * 
     * USE EFFECTS
     * 
     * */

    /**
     * Update avatar when companion props change
     */
    useEffect(() => {
        setAvatar(companion.avatar);
        setupItems();
    }, [companion]);

    /**
     * Update items when items props change
     */
    useEffect(() => {
        if (items) {
            setupItems();
        }
    }, [items]);

    /**
     * 
     *  FUNCTIONS
     * 
     *  */

    /**
     * @setupItems
     * populate items lists
     */
    const setupItems = () => {
        try {
            if (!items) {
                setLoading(false);
                return;
            }
            if (!loading) {
                setLoading(true);
            }
            const perso: Item[] = createItemArray("perso");
            const bust: Item[] = createItemArray("buste", true);
            const hat: Item[] = createItemArray("chapeau", true);
            const mask: Item[] = createItemArray("masque", true);
            const background: Item[] = createItemArray("fond");

            setPerso(perso);
            setMask(mask);
            setHat(hat);
            setBust(bust);
            setBackground(background);
            setLoading(false);
        } catch (err) { console.log(err) }

    };

    /**
     * @createItemArray
     * create the item array for one type 
     */
    const createItemArray = (
        type: string,
        hasEmpty = false,
    ): Item[] => {
        if (!items) {
            setLoading(false);
            return [];
        }
        const itemArray: Item[] = hasEmpty ? [{ id: null, type, unlocked: true } as Item] : [];
        items.forEach((item: Item) => {
            item.equiped = false;
            if (item.id === avatar[type]) {
                item.equiped = true;
            }
            if (item.type === type) {
                itemArray.push(item);
            }
            if (item.justBought && item.type === type) {
                changeMenuActive(type);
                item.justBought = undefined;
            }
        });
        return itemArray;
    };

    /**
     * @updateAvatar
     * Request for avatar update
     */
    const updateAvatar = async (item: Item) => {
        setLoading(true);
        const av = avatar;
        if (item.id === 74 || item.id === 75 || item.id === 76) {
            av.chapeau = null;
            av.masque = null;
            av.buste = null;
            av[item.type] = item.id;
            await processUpdateAvatar(av);
        } else {
            if ((av.perso === 74 || av.perso === 75 || av.perso === 76) && item.type !== "perso") {
                // try to add item on premade chara 
                setShowPopUpCantEquip(true);
                setLoading(false);
            } else {
                if ((av.perso === 74 || av.perso === 75 || av.perso === 76) && item.type === "perso") {
                    av["fond"] = 1;
                }
                av[item.type] = item.id;
                await processUpdateAvatar(av);
            }
        }
    };

    /**
     * @processUpdateAvatar
     * Fetch and update avatar + update local
     */
    const processUpdateAvatar = async (av: any) => {
        SoundManager.getInstance().playAvatarItemChangeSound();
        setAvatar(av);
        companion.avatar = av;
        setupItems();
        //await getEventManager().avatarUpdate(item.id, item.type);
        const token = auth?.user?.login_token;
        if (token != null) {
            const url = getRouteManager().updateCompanionForUser();
            const res = await getRouteManager().fetchData(url, {
                login_token: token,
                companion: companion,
            })
        }
        setLoading(false);
    }

    /**
     * @updatePseudo
     * Request for companion name update + update it in local
     */
    const updatePseudo = async (values: any) => {
        try {
            setShowPopUpChangePseudo(false);
            const token = auth.user.login_token;
            if (token && values?.name) {
                setLoading(true);
                companion.name = values.name;
                const api = getRouteManager().updateCompanionForUser();
                await getRouteManager().fetchData(api, {
                    login_token: token,
                    companion: companion,
                })
                setLoading(false);
            }
        } catch (err) { console.log(err) }
    };

    /**
     * @updateTitle
     * callback after update title
     */
    const updateTitle = (title: any) => {
        companion.title = title;
        forceUpdate();
    }


    /**
     * @goToShop
     * Go to shop (tickets part)
     */
    const goToShop = () => { navigate("/shop"); };

    /**
     * @changeMenuActive
     * Change active item menu 
     */
    const changeMenuActive = (type: string) => {
        SoundManager.getInstance().playTabBarSound();
        const menuTabs = ["chapeau", "buste", "masque", "perso", "fond"];
        const index = menuTabs.indexOf(type);
        if (index !== -1) {
            setMenuActiveIndex(index);
        }
    };

    /** 
     * @closeModalWithCallback
     */
    const closeModalWithCallback = async () => {
        setShowPurchaseSlotModal(false);
        await purchaseSlotCallback();
    }



    /** 
     * 
     * RENDER
     * 
     *  */

    const renderItems = () => {
        switch (menuActiveIndex) {
            case 0:
                return (
                    <Box sx={styles.item}>{hat && hat.map((p: any) => renderItem(p))}</Box>
                );
            case 1:
                return (
                    <Box sx={styles.item}>{bust && bust.map((p: any) => renderItem(p))}</Box>
                );
            case 2:
                return (
                    <Box sx={styles.item}>{mask && mask.map((p: any) => renderItem(p))}</Box>
                );
            case 3:
                return (
                    <Box sx={styles.item}>{perso && perso.map((p: any) => renderItem(p))}</Box>
                );
            case 4:
                return (
                    <Box sx={styles.item}>{background && background.map((p: any) => renderItem(p))}</Box>
                );
            default:
                return null;
        }
    }

    /**
     * @renderItem
     * Render one item with functions to update avatar / purchase item
     */
    const renderItem = (p: any) => {
        if (avatar) {
            return (
                <Box
                    key={p.id}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {p.unlocked || !p.shopVisible ? (
                        <AvatarItemEditUser
                            updateAvatar={updateAvatar}
                            title={p}
                            avatar={avatar}
                            goToShop={goToShop}
                            setShowPopUpMission={setShowPopUpMission}
                        />
                    ) : (
                        <AvatarItemShop buyItemCallback={buyItemCallback} title={p} avatar={avatar} />
                    )}
                </Box>
            );
        }
        return null;
    };

    if (companion) {
        return (
            <Box sx={styles.containerEdit}>
                {loading ? (
                    <Box sx={styles.loading}><Loading size={"small"} /></Box>
                ) : null}
                <Box sx={styles.topMenu}>



                    <ButtonBase
                        onClick={goToPrevIndex}
                        disableRipple
                        sx={[
                            styles.prev,
                        ]}>
                        <NavigateBeforeIcon sx={{ fontSize: "2.3rem" }} />
                    </ButtonBase>



                    <Box sx={{ ...columnCenterStyles }}>

                        {!companion?.isEmpty && companion?.avatar ? (
                            <AvatarContainer persoWidth={150} user={companion} borderWidth={0} />
                        ) : <IconSlot width={120} height={120} />}
                        {!companion?.isEmpty ? (
                            <Box mt={"1vh"} sx={{ ...columnCenterStyles }}>
                                <Typography style={styles.userName}>
                                    {companion.name ? companion.name : ""}
                                </Typography>
                                <ButtonBase
                                    sx={styles.editIcon}
                                    onClick={() => {
                                        SoundManager.getInstance().playOpenModalSound();
                                        setShowPopUpChangePseudo(true);
                                    }}>
                                    <IconEdit width={18} height={18} fill={Colors.WHITE} />
                                    <Typography style={styles.userId}>
                                        {strings.changePseudo}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        ) : (
                            <ButtonBase
                                onClick={() => {
                                    setShowPurchaseSlotModal(true);
                                }}
                                sx={{
                                    backgroundColor: Colors.BTN_ACTIVE,
                                    borderRadius: "13px",
                                    padding: "10px",
                                    ...columnCenterStyles,
                                    marginTop: "15px"
                                }}>
                                <Box sx={styles.priceBlock}>
                                    <TicketAnimated style={{ width: 25, height: 25 }} />
                                    <Typography sx={[styles.companionName, { color: Colors.TICKET }]}>{COMPANIONS.PRICE}</Typography>
                                </Box>
                                <Typography
                                    sx={styles.subtitle}
                                    component={"span"}>
                                    {strings.companions.buySlot1}
                                </Typography>
                            </ButtonBase>
                        )}

                        {!companion?.isEmpty ? (

                            <Box mt={"1vh"} sx={{ ...columnCenterStyles }}>
                                <Typography style={styles.userTitle}>
                                    {companion?.title && companion?.title?.name
                                        ? companion.title.name
                                        : ""}
                                </Typography>
                                <ButtonBase
                                    sx={styles.editIcon}
                                    onClick={() => {
                                        SoundManager.getInstance().playOpenModalSound();
                                        setShowPopUpChangeTitle(true);
                                    }}>
                                    <IconEdit width={18} height={18} fill={Colors.WHITE} />
                                    <Typography style={styles.userId}>{strings.changeTitle}</Typography>
                                </ButtonBase>
                            </Box>
                        ) : null}
                    </Box>

                    <ButtonBase
                        onClick={goToNextIndex}
                        disableRipple
                        sx={[
                            styles.next,
                        ]}>
                        <NavigateNextIcon sx={{ fontSize: "2.3rem" }} />
                    </ButtonBase>

                </Box>
                <AvatarItemMenu activeIndex={menuActiveIndex} changeMenuActive={changeMenuActive} />
                {companion && !companion?.isEmpty ? (
                    <Box
                        mt={"2vh"}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}>
                        {renderItems()}
                    </Box>
                ) : (<Box
                    mt={"2vh"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }} />
                )}


                {companion && !companion.isEmpty ? (
                    <>
                        <Modal
                            open={showPopUpChangePseudo}
                            onClose={() => setShowPopUpChangePseudo(false)}
                            sx={{ ...columnCenterStyles }}>
                            <Box>
                                <ChangePseudoModal
                                    user={companion}
                                    callback={updatePseudo}
                                    closeModal={() => setShowPopUpChangePseudo(false)}
                                />
                            </Box>
                        </Modal>

                        <ClassicModal
                            onCloseModal={() => setShowPopUpCantEquip(false)}
                            modalVisible={showPopUpCantEquip}
                            title={strings.error}
                            buttonText={strings.actions.cancel}
                            content={(
                                <Typography sx={[styles.modalSubtitle, { marginTop: 0 }]}>{strings.companions.cantEquip}</Typography>
                            )}
                        />

                        <ClassicModal
                            onCloseModal={() => setShowPopUpMission(false)}
                            modalVisible={showPopUpMission}
                            title={strings.error}
                            buttonText={strings.actions.cancel}
                            content={(
                                <Typography sx={[styles.modalSubtitle, { marginTop: 0 }]}>{strings.missionRewardPopup}</Typography>
                            )}
                        />

                        {/* CHANGE TITLE MODAL */}
                        <BigModal
                            modalVisible={showPopUpChangeTitle}
                            callback={() => setShowPopUpChangeTitle(false)}
                            hasCloseIcon
                            hasSeparator
                            title={strings.titlesList}
                            contentStyle={{ padding: "0px 50px" }}
                            headerStyle={{ padding: "50px 50px 20px 50px" }}
                            modalStyle={{ minHeight: "400px", height: "70vh" }}
                            content={<TitleModal companion={companion} callback={updateTitle} />}
                        />

                    </>

                ) : null
                }


                <Modal open={showPurchaseSlotModal} onClose={() => setShowPurchaseSlotModal(false)}>
                    <Box>
                        <BuyCompanionSlotModal callback={closeModalWithCallback} />
                    </Box>
                </Modal>

            </Box >
        )
    } else return null;

}