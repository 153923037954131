/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Input, InputAdornment, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { styles } from "../styles";
import { columnCenterStyles } from "../../../style/flex";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import { ReactComponent as IconUser } from "../../../assets/icons/icon_user.svg";
import SoundManager from "../../../services/SoundManager";


export const ChangePseudoModal = ({ user, callback, closeModal }: { user: any, callback: any, closeModal: any }) => {

    const handleError = (error: string | any, type: string) => {
        if (error.endsWith("is a required field") && type === "pseudo") {
            return strings.pseudoRequired;
        }
    };

    user.pseudo = !user.pseudo ?
        user.name ?
            user.name
            : user.pseudo
        : user.pseudo;

    return (
        <Box sx={styles.modalView}>
            <Formik
                initialValues={{
                    pseudo: user.name ? user.name : user.pseudo ? user.pseudo : "Pseudo",
                    name: user.name ? user.name : user.pseudo ? user.pseudo : "Pseudo",
                }}
                onSubmit={values => {
                    values.name = values.pseudo;
                    callback(values);
                }}
                validationSchema={yup.object().shape({
                    pseudo: yup.string().max(20).required(),
                })}>
                {({
                    values,
                    handleChange,
                    errors,
                    setFieldTouched,
                    touched,
                    isValid,
                    handleSubmit,
                }) => (
                    <Box sx={{ ...columnCenterStyles }}>
                        <Typography
                            sx={{
                                color: Colors.WHITE,
                                fontFamily: "Gilroy-Bold",
                                fontSize: normalize(20),
                                marginBottom: "30px",
                                alignSelf: "center",
                                justifySelf: "center",
                            }}>
                            {strings.newPseudo}
                        </Typography>
                        <Box sx={{ width: "70%" }}>
                            <Input
                                value={values.pseudo}
                                onChange={handleChange("pseudo")}
                                onBlur={() => setFieldTouched("pseudo")}
                                sx={styles.myInputView}
                                placeholder={strings.mypseudo}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconUser
                                            width={35}
                                            height={35}
                                            fillSecondary={Colors.INPUT}
                                            fill={Colors.WHITE}
                                        />
                                    </InputAdornment>
                                }
                            />
                            {touched.pseudo && errors.pseudo && (
                                <Typography
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        fontSize: normalize(12),
                                        color: Colors.ERROR,
                                        marginTop: 15,
                                        textAlign: "center",
                                    }}>
                                    {handleError(errors.pseudo, "pseudo")}
                                </Typography>
                            )}
                        </Box>

                        <Box style={styles.separator} />
                        <Box sx={styles.popupButtons}>
                            <ButtonBase
                                style={styles.noButton}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    closeModal();
                                }}>
                                <Typography sx={styles.noText}>{strings.back}</Typography>
                            </ButtonBase>
                            <ButtonBase
                                style={styles.yesButton}
                                disabled={!isValid}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    isValid ? handleSubmit() : "";
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.update}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                )}
            </Formik>
        </Box>
    );
}