/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useEffect } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../style";
import {
  columnCenterStyles,
  rowBetweenStyles,
  rowCenterStyles,
} from "../../style/flex";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Gilroy } from "../../style/fonts";
import { LibraryCard } from "../../pages/Library/newCard";

const StoryCarousel = ({
  stories,
  type,
  onSelectStory,
  screenWidth = 0,
  fullscreenWidth = 0,
  style,
  title,
}: {
  stories: any;
  type: string;
  onSelectStory: any;
  fullscreenWidth?: number | undefined; // marge entre les cartes en pixels
  screenWidth?: number | undefined; // marge entre les cartes en pixels
  style?: any;
  title?: any;
}) => {
  const breakpoints = [350, 528, 710, 889, 1070, 1250, 1432];
  const cardsPerRow = [2, 3, 4, 5, 6, 7, 8];
  const maxCardWidth = 300;

  let numCardsVisible =
    screenWidth <= breakpoints[0]
      ? cardsPerRow[0]
      : screenWidth <= breakpoints[1]
      ? cardsPerRow[1]
      : screenWidth <= breakpoints[2]
      ? cardsPerRow[2]
      : screenWidth <= breakpoints[3]
      ? cardsPerRow[3]
      : screenWidth <= breakpoints[4]
      ? cardsPerRow[4]
      : screenWidth <= breakpoints[5]
      ? cardsPerRow[5]
      : screenWidth <= breakpoints[6]
      ? cardsPerRow[6]
      : breakpoints[6];

  if (numCardsVisible > stories.length) {
    numCardsVisible = stories.length;
  }

  //const [startIndex, setStartIndex] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const carouselRef = useRef<HTMLDivElement>(null);
  const cardWidth = `${Math.min(
    maxCardWidth,
    (screenWidth - 16 * (numCardsVisible - 1)) / numCardsVisible,
  )}px`;
  const cardHeight = `${(264 / 165) * parseFloat(cardWidth)}px`; // Maintenir le ratio de largeur à hauteur
  const cardScrollWidth = parseInt(cardWidth) + 16;
  const totalPages = Math.ceil(stories.length / numCardsVisible);

  useEffect(() => {
    // When the carousel is mounted, scroll to the active page
    if (carouselRef.current) {
      const scrollAmount = cardScrollWidth * numCardsVisible * activePage;
      carouselRef.current.scrollLeft = scrollAmount;
    }
  }, [activePage, numCardsVisible]);

  const handlePrev = () => {
    if (carouselRef.current) {
      let newActivePage = activePage - 1;
      if (newActivePage < 0) {
        newActivePage = totalPages - 1;
      }
      const scrollAmount = cardScrollWidth * numCardsVisible * newActivePage;
      carouselRef.current.scrollLeft = scrollAmount;
      //setStartCardIndex(newActivePage * numCardsVisible);
      setActivePage(newActivePage);
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      let newActivePage = activePage + 1;
      if (newActivePage >= totalPages) {
        newActivePage = 0;
      }
      const scrollAmount = cardScrollWidth * numCardsVisible * newActivePage;
      carouselRef.current.scrollLeft = scrollAmount;
      //setStartCardIndex(newActivePage * numCardsVisible);
      setActivePage(newActivePage);
    }
  };

  return (
    <Box
      sx={[
        { ...columnCenterStyles, width: "100%", position: "relative" },
        style ? style : {},
      ]}>
      {totalPages > 1 ? (
        <ButtonBase
          disableRipple
          onClick={handlePrev}
          sx={[
            styles.prev,
            fullscreenWidth && screenWidth === 1432
              ? {
                  left: `${
                    (fullscreenWidth - 1432) / 2 - fullscreenWidth * 0.05
                  }px`,
                }
              : {},
          ]}>
          <NavigateBeforeIcon sx={{ fontSize: "2.3rem" }} />
        </ButtonBase>
      ) : null}

      <Box sx={{ ...rowCenterStyles, width: "100%", height: "100%" }}>
        <Box sx={{ width: "5%" }} />
        <Box
          position="relative"
          width={screenWidth}
          sx={styles.carouselOuterContainer}>
          <Box sx={{ ...rowBetweenStyles, width: "100%" }}>
            <Typography sx={[styles.headerSubtitle]}>
              {title ? title : ""}
            </Typography>
            <Box sx={styles.dotsContainer}>
              {totalPages > 1
                ? Array.from({ length: totalPages }).map((_, index) => (
                    <Box
                      key={"dot-" + index}
                      sx={{
                        ...columnCenterStyles,
                        width: activePage === index ? "8px" : "6px",
                        height: activePage === index ? "8px" : "6px",
                        borderRadius: "50%",
                        backgroundColor:
                          activePage === index
                            ? Colors.WHITE
                            : Colors.OLD_PRICE,
                        marginX: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (carouselRef.current) {
                          const scrollAmount =
                            cardScrollWidth *
                            numCardsVisible *
                            (index - activePage);
                          carouselRef.current.scrollLeft += scrollAmount;
                          //setStartIndex(index * numCardsVisible);
                          setActivePage(index);
                        }
                      }}
                    />
                  ))
                : null}
            </Box>
          </Box>

          <Box ref={carouselRef} sx={[styles.carousel]}>
            {stories.map((story: any, index: number) => (
              <LibraryCard
                key={type + "-" + story.id}
                story={story}
                onSelectStory={onSelectStory}
                isLast={index === stories.length - 1}
                height={cardHeight}
                width={cardWidth}
                type={type}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "5%" }} />
      </Box>
      {totalPages > 1 ? (
        <ButtonBase
          disableRipple
          onClick={handleNext}
          sx={[
            styles.next,
            fullscreenWidth && screenWidth === 1432
              ? {
                  right: `${
                    (fullscreenWidth - 1432) / 2 - fullscreenWidth * 0.05
                  }px`,
                }
              : {},
          ]}>
          <NavigateNextIcon sx={[{ fontSize: "2.3rem" }]} />
        </ButtonBase>
      ) : null}
    </Box>
  );
};

const styles = {
  headerSubtitle: {
    fontSize: "calc(14px + 0.63vh)",
    fontFamily: Gilroy,
    color: Colors.WHITE,
    margin: "30px 0px 10px",
    textAlign: "start",
    alignSelf: "flex-start",
  },
  carouselOuterContainer: {
    margin: "0 0",
    overflow: "hidden",
    borderRadius: "13px",
  },
  carousel: {
    display: "flex",
    alignItems: "flex-start",
    flexShrink: 0,
    borderRadius: "13px",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    webkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
      width: "0px",
    },
  },
  prev: {
    width: "5%",
    position: "absolute",
    left: 0,
    top: "calc(54px + 0.63vh)",
    height: "calc(100% - (54px + 0.63vh))",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: 2,
    color: "#fff",
  },
  next: {
    width: "5%",
    position: "absolute",
    right: 0,
    top: "calc(54px + 0.63vh)",
    height: "calc(100% - (54px + 0.63vh))",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: 2,
    color: "#fff",
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
    marginBottom: "16px",
  },
};

export default StoryCarousel;
