/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from "react";

import { ReactComponent as NextIcon } from "../../../assets/icons/icon_next.svg";
//import PopUpIndicator from '../../../components/PopUpIndicator';
import styles from "./styles";
import { Colors } from "../../../style";
import { isBoolean, isNumber } from "lodash";
import { isFunction } from "../../../utils/TypeOfHelper";
import { isNotEmptyString } from "../../../utils/StringHelper";
import ColorManager from "../../../services/colorManager";
import { ReactComponent as IconVoted } from "../../../assets/icons/icon_voted.svg";
import { ReactComponent as IconBlocked } from "../../../assets/icons/icon_blocked.svg";
import { strings } from "../../../services/translation";
import { Box, ButtonBase, Fade, Typography } from "@mui/material";
import { getRouteManager } from "../../../services/routeManager";
import Loading from "../../../components/Loading";
import PopUpIndicator from "../../../components/PopUpIndicator";
import SoundManager from "../../../services/SoundManager";
import CircularProgressNew from "../../../components/CircularProgress/CircularProgressNew";

// =======================================================

interface Props {
  imageId: number | null;
  onSkipImage: any;
  choices: any;
  passageNumber: number;
  storyId: number;
  storyReader: any;
  currPassageId: any;
  secondaryColor: any;
  textColor: any;
  showStatePopUp: boolean;
  color?: any;
  disabled?: boolean;
  disabledText?: string | null;
  percent?: any;
  loading?: boolean;
  onEndGame?: any;
  setAskExit?: any;
  text?: any;
  setShowReport?: any;
}

// =======================================================

const FullScreenImageScreen = (props: Props) => {
  const {
    imageId,
    currPassageId,
    showStatePopUp,
    disabled = false,
    percent = null,
    onSkipImage,
    choices,
    disabledText = "",
    onEndGame = null,
    setAskExit,
    text,
    setShowReport,
  } = props;

  const [shouldShowArrow, setShouldShowArrow] = useState(false);
  const [showPopUpIndicator, setShowPopUpIndicator] = useState(false);

  useEffect(() => {
    if (shouldShowArrow) {
      setShouldShowArrow(false);
      setTimeout(() => {
        setShouldShowArrow(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setShouldShowArrow(true);
      }, 1000);
    }
  }, [currPassageId]);

  /**
   *
   */
  const onGoNext = () => {
    if (choices[0].text === "FIN") {
      if (isFunction(setAskExit)) {
        setAskExit(false);
      }
      if (onEndGame !== null && isFunction(onEndGame)) {
        // online mode
        onEndGame({ ...choices[0], text: "FIN_NEXT" });
      } else {
        // local mode
        if (isFunction(setShowReport)) setShowReport(true);
      }
    } else {
      onSkipImage();
    }
  };

  /**
   *
   */
  const isLoading = () => {
    return isBoolean(props.loading) && props.loading;
  };

  /**
   *
   */
  const renderIcon = () => {
    if (isBoolean(props.loading) && props.loading) {
      return (
        <Loading
          size={"small"}
          color={ColorManager.getInstance().getColor("tertiary")}
        />
      );
    }

    if (isNotEmptyString(disabledText)) {
      if (disabledText === strings.messages.notConcerned) {
        return (
          <IconBlocked
            width={25}
            height={25}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
        );
      } else if (disabledText === strings.messages.alreadyVoted) {
        return (
          <IconVoted
            width={25}
            height={25}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
        );
      } else {
        return null;
      }
    }

    return (
      <NextIcon
        width={45}
        height={45}
        fill={ColorManager.getInstance().getColor("secondary")}
        fillSecondary={props.textColor}
      />
    );
  };

  /**
   *
   */
  const handleSubmit = () => {
    SoundManager.getInstance().playChoiceMadeSound();
    onGoNext();
  };

  /**
   *
   */
  const renderSubmitButton = (isDisabled: boolean | null) => {
    const disabled = isBoolean(isDisabled) && isDisabled;

    return (
      <ButtonBase
        sx={{ borderRadius: "45px" }}
        onClick={() =>
          !disabled ? handleSubmit() : setShowPopUpIndicator(true)
        }>
        {renderIcon()}
      </ButtonBase>
    );
  };

  // --
  return (
    <Fade in={true} appear timeout={800}>
      <Box
        sx={[
          styles.container,
          { zIndex: 2, position: "relative", height: "100%" },
          /* imageId
            ? {
                backgroundImage: `url(${getRouteManager().sceneImage(
                  imageId
                )})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }
            : {}, */
        ]}>
        {imageId ? (
          <img
            style={{ width: "100%", height: "100%", marginTop: "8px" }}
            src={getRouteManager().sceneImage(imageId)}
          />
        ) : null}
        {text && isNotEmptyString(text) ? (
          <Typography sx={[styles.text, { color: Colors.WHITE }]}>
            {text}
          </Typography>
        ) : null}

        {!showStatePopUp ? (
          <Box sx={styles.buttonContainer}>
            {isNumber(percent) ? (
              <>
                {showPopUpIndicator &&
                isNotEmptyString(disabledText) &&
                !isLoading() ? (
                  <PopUpIndicator
                    onCancel={() => setShowPopUpIndicator(false)}
                    text={disabledText}
                    shouldGoUp={50}
                  />
                ) : null}

                <CircularProgressNew
                  percent={percent}
                  color={ColorManager.getInstance().getColor("tertiary")}
                  bgColor={ColorManager.getInstance().getColor("quinary")}>
                  {renderSubmitButton(disabled)}
                </CircularProgressNew>
              </>
            ) : (
              shouldShowArrow && renderSubmitButton(disabled)
            )}
          </Box>
        ) : null}
      </Box>
    </Fade>
  );
};

// =======================================================

export default FullScreenImageScreen;
