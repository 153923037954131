/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography } from "@mui/material";
import { errorAnim } from "../../../constants/error";
import { styles } from "../styles";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import GradientBtn from "../../../components/GradientBtn";

const PurchaseFailure = ({
  error,
  errorType,
  helpCallback,
}: {
  error?: string | undefined;
  errorType?: string | undefined;
  helpCallback?: any | undefined;
}) => {
  return (
    <Box sx={styles.container}>
      <Player
        src={errorAnim}
        autoplay
        style={{ width: "120px", height: "120px" }}
        keepLastFrame
      />
      <Typography sx={styles.title} variant="h2">
        {errorType === "error-validation"
          ? strings.purchase.validationError
          : strings.purchase.paymentError}
      </Typography>
      <Typography sx={styles.subtitle}>
        {strings.purchase.paymentErrorSub}
      </Typography>
      {isNotEmptyString(error) ? (
        <Typography
          sx={[
            styles.subtitle,
            { marginTop: "15px", fontSize: "16px", color: Colors.BTN_INACTIVE },
          ]}
        >
          {error}
        </Typography>
      ) : null}
      {errorType !== "error-validation" ? (
        <Box sx={styles.gradientBtn}>
          <GradientBtn onPress={() => (window.location.href = "/shop")}>
            {strings.purchase.backToStore}
          </GradientBtn>
        </Box>
      ) : (
        <Box sx={styles.gradientBtn}>
          <GradientBtn onPress={helpCallback}>
            {strings.labels.needHelp}
          </GradientBtn>
        </Box>
      )}
    </Box>
  );
};

export default PurchaseFailure;
