/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnStartStyles } from "../../../style/flex";

// =======================================================

const centerStyles: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

// =======================================================

export default {
  container: {
    flex: 1,
    ...columnStartStyles,
    width: "100%",
    position: "relative",
    paddingTop: "32px",
    gap: "32px",
  },
  timerStyle: {
    ...centerStyles,
    flex: 1,
  },
  timerText: {
    color: Colors.WHITE,
    fontSize: "calc(25px + 1vh)",
    fontWeight: "bold",
  },
  image: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    flex: 2,
  },
  imageIOS: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    padding: "0px 40px",
  },
  changeContainer: {
    ...centerStyles,
    marginBottom: "10px",
  },
  changing: {
    color: Colors.WHITE,
    fontSize: normalize(24),
    fontFamily: "Eczar-Regular",
    marginTop: "5px",
  },
  letter: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    borderWidth: "2px",
    ...centerStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
};

// =======================================================
