import { Colors } from "../../style";
import {
    columnStartStyles,
    rowStyles,
    rowStartStyles,
    columnCenterStyles,
    rowCenterStyles,
    rowEvenlyStyles,
} from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import { normalizeSize } from "../../utils/fontSizer";

export const styles = {
    mainContainer: {
        marginTop: normalizeSize(20),
    },
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
        paddingTop: "4vh",
        paddingBottom: "50px",
    },
    containerRight: {
        ...columnStartStyles,
        alignItems: "flex-start",
        paddingTop: "4vh",
        paddingBottom: "50px",
    },
    containerSmall: {
        ...columnStartStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
    },
    bodyStyle: {
        paddingTop: "0px",
        height: "fit-content",
        minHeight: "unset",
        justifyContent: "center",
    },
    topMenuTitle: {
        width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "50px",
    },
    progressBar: {
        ...rowStartStyles,
        width: "240px",
        height: "15px",
        borderRadius: "15px",
        backgroundColor: "rgba(121, 112, 255, 0.3)",
        marginLeft: "-5px",
        zIndex: 5,
    },
    progressBarActive: {
        ...rowStartStyles,
        width: "0%",
        height: "100%",
        borderRadius: "15px",
        background: `linear-gradient(270deg, ${Colors.HEART} 0%, ${Colors.ACCENT} 100%)`,
        overflow: "hidden",
        zIndex: 6,
    },
    level: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        //marginBottom: normalizeSize(20),
        zIndex: 2,
    },
    levelLine: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        width: 2,
        backgroundColor: Colors.FOND_ITEM_POPUP,
        zIndex: 1,
    },
    levelLineCompleted: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        width: 2,
        backgroundColor: Colors.ACCENT,
        zIndex: 1,
    },
    levelNumber: {
        backgroundColor: Colors.BTN_ACTIVE,
        color: Colors.WHITE,
        width: 40,
        height: 40,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: normalizeSize(24),
        border: `2px solid ${Colors.FOND_ITEM_POPUP}`,
        fontFamily: Gilroy,
        fontSize: normalizeSize(16),
        zIndex: 2,
    },
    levelCircle: {
        width: "40px",
        height: "40px",
        borderRadius: "40px",
        border: `solid 2px ${Colors.ACCENT}`,
        ...columnCenterStyles,
        backgroundColor: Colors.BTN_ACTIVE,
        zIndex: 10,
    },
    levelText: {
        fontSize: "20px",
        lineHeight: "20px",
        fontFamily: "Gilroy-Bold",
        color: Colors.ACCENT,
    },
    categoryBox: {
        backgroundColor: Colors.BTN_ACTIVE,
        ...rowCenterStyles,
        marginBottom: normalizeSize(24),
        borderRadius: normalizeSize(7),
        alignSelf: "center",
        padding: "8px 30px",
        gap: normalizeSize(6),
        zIndex: 2,
    },
    categoryBoxText: {
        color: Colors.WHITE,
        fontFamily: Gilroy,
        fontSize: normalizeSize(16),
        ...columnCenterStyles,
        lineHeight: normalizeSize(16),
        marginTop: normalizeSize(3),
    },
    rewardBox: {
        border: `2px solid ${Colors.ACCENT}`,
        padding: normalizeSize(10),
        borderRadius: normalizeSize(13),
        textAlign: "center",
        width: 120,
        zIndex: 2,
    },
    rewardBtn: {
        ...rowCenterStyles,
        border: `2px solid ${Colors.BTN_ACTIVE}`,
        backgroundColor: Colors.BTN_ACTIVE,
        padding: `${normalizeSize(6)} ${normalizeSize(0)}`,
        borderRadius: normalizeSize(7),
        width: normalizeSize(140),
        height: normalizeSize(44),
        zIndex: 2,
    },
    rewardBtnInside: {
        ...rowCenterStyles,
        textAlign: "center",
        gap: normalizeSize(8),
    },
    emptyReward: {
        width: normalizeSize(140),
    },
    rewardName: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        color: Colors.FOND_ITEM_POPUP,
    },
    modalView: {
        width: "400px",
        borderRadius: "20px",
        backgroundColor: Colors.PRIMARY,
        position: "relative",
        padding: "25px 0px",
        ...columnCenterStyles,
        zIndex: 50,
    },
    congrats: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(20),
        marginBottom: "20px",
        textAlign: "center",
        marginTop: "10px",
        padding: "0px 25px",
    },
    congrats2: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
        textAlign: "center",
        marginTop: "10px",
    },
    rewardsList: {
        ...rowEvenlyStyles,
        gap: "20px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalizeSize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
};
