/* eslint-disable @typescript-eslint/no-explicit-any */
//import LocalizedStrings from 'react-native-localization';
import LocalizedStrings from "react-localization";
import english from "./en";
import french from "./fr";
import { getRouteManager } from "../routeManager";

export const strings = new LocalizedStrings({
  fr: french,
  en: english,
});

//export const strings = french;

/* export const changeLanguage = (languageKey: any) => {
  strings.setLanguage(languageKey);
  localStorage.setItem('language', languageKey);
}; */

export const changeLanguage = async (
  languageKey: any,
  shouldUpdateUser = false
) => {
  strings.setLanguage(languageKey);
  localStorage.setItem("language", languageKey);
  if (shouldUpdateUser) {
    await getRouteManager().updateUserLang(languageKey);
  }
  return true;
};

export const getLanguage = () => {
  //return 'fr';
  return strings.getLanguage();
};
