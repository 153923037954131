/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { LevelReward } from "../../../../models/Level";
import { Colors } from "../../../../style";
import { styles } from "../../styles";
import { ReactComponent as IconStory } from "../../../../assets/icons/icon_story.svg";
import { ReactComponent as IconLock } from "../../../../assets/icons/icon_lock.svg";
import { ReactComponent as IconItem } from "../../../../assets/icons/icon_item_normalize.svg";
import { ReactComponent as IconTitle } from "../../../../assets/icons/icon_news.svg";
import { rowCenterStyles } from "../../../../style/flex";
import { strings } from "../../../../services/translation";

export const LevelRewardBtn = ({
    reward,
    callback,
    unlocked = false,
    rewardReclaimed = false,
}: {
    reward: LevelReward;
    callback: any;
    unlocked?: boolean;
    rewardReclaimed?: boolean;
}) => {
    /**
     *
     * FUNCTIONS
     *
     */

    const isStory = !!(reward && reward.type && reward.type === "story");

    /**
     * @disabled
     * Is this reward btn disabled
     */
    const disabled = !!(!unlocked || rewardReclaimed || !reward);

    /**
     * @getRewardIcon
     * @returns the adequate reward icon
     */
    const getRewardIcon = () => {
        return reward?.type === "item" ? (
            <IconItem width={20} height={20} fill={Colors.HEART} />
        ) : reward?.type === "story" ? (
            <IconStory
                width={20}
                height={20}
                fill={Colors.vertALEXTESGRANDSMORTS}
            />
        ) : reward?.type === "title" ? (
            <IconTitle width={20} height={20} fill={Colors.ACCENT} />
        ) : (
            <IconLock width={20} height={20} fill={Colors.FOND_ITEM_POPUP} />
        );
    };

    /**
     * @getRewardColor
     * @returns the adequate reward icon
     */
    const getRewardColor = () => {
        return reward?.type === "item"
            ? Colors.HEART
            : reward?.type === "story"
            ? Colors.vertALEXTESGRANDSMORTS
            : reward?.type === "title"
            ? Colors.ACCENT
            : Colors.FOND_ITEM_POPUP;
    };

    /**
     * @getRewardText
     * @returns the adequate reward icon
     */
    const getRewardText = () => {
        return reward?.type === "item"
            ? strings.item
            : reward?.type === "story"
            ? reward?.storyName
                ? reward?.storyName
                : strings.mainQuest.title
            : reward?.type === "title"
            ? strings.title
            : "";
    };

    /**
     *
     * RENDER
     *
     */

    return (
        <ButtonBase
            sx={[
                styles.rewardBtn,
                unlocked && !rewardReclaimed && !disabled && !isStory
                    ? { border: `solid 2px ${Colors.vertALEXTESGRANDSMORTS}` }
                    : {},
            ]}
            disabled={disabled}
            onClick={() => !disabled && callback()}>
            {unlocked ? (
                <Box sx={styles.rewardBtnInside}>
                    {getRewardIcon()}
                    <Typography
                        sx={[
                            styles.rewardName,
                            {
                                color: getRewardColor(),
                            },
                        ]}>
                        {getRewardText()}
                    </Typography>
                </Box>
            ) : (
                <IconLock
                    width={20}
                    height={20}
                    fill={Colors.FOND_ITEM_POPUP}
                />
            )}
        </ButtonBase>
    );
};
