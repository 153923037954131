/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Colors } from "../../../../style";
import { columnCenterStyles } from "../../../../style/flex";
import { Box, ButtonBase } from "@mui/material";

interface Props {
  onPress: any;
  icon: any;
  style?: any;
}

const styles = {
  btn: {
    opacity: 1.0,
  },
  btnDisable: {
    opacity: 0.7,
  },
  text: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: 16,
  },
  container: {
    ...columnCenterStyles,
  },
  txtDisabled: {
    color: "rgba(255,255,255,0.5)",
    fontFamily: "Gilroy-Bold",
    fontSize: 16,
  },
};

const OptionBtn = (props: Props) => {
  const { onPress, icon, style } = props;
  return (
    <ButtonBase sx={[styles.container, style ? style : {}]} onClick={onPress}>
      <Box>{icon}</Box>
    </ButtonBase>
  );
};

export default OptionBtn;
