/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import useAuth from "../../services/Auth/useAuth";
import { useNavigate } from "react-router";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage } from "../../services/translation";
import { Colors } from "../../style";
import { columnStyles } from "../../style/flex";
import { Box } from "@mui/material";
import TicketShop from "./TicketShop";
import RegisterTemporaryAccountModal from "../../components/RegisterTemporaryAccountModal";
import FTUEModals from "../../components/FTUEModals";
import { FTUE_MODALS } from "../../constants";
/* ICONS */

const Shop = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [showRegisterModal, setShowRegisterModal] = useState(false);

    useEffect(() => {
        try {
            if (!auth || (auth && !auth.user)) {
                console.log("no auth");
                navigate("/login");
            }
            initData();
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (!auth || auth?.user?.isTemporaryAccount) {
            setShowRegisterModal(true);
        }
    }, [auth]);

    const initData = async () => {
        try {
            const isOK = await getRouteManager().isVersionAndMaintenanceOk();
            if (isOK) {
                await getUserInfos();
            } else {
                navigate("/login");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getUserInfos = async () => {
        try {
            const json = await getRouteManager().fetchGetUserInfos(auth.user);

            if (json.flashmessage === "error") {
                console.log("no user found");
            } else {
                const apiTitle = getRouteManager().getTitle(
                    json.titleId,
                    getLanguage(),
                );
                const title = await getRouteManager().getData(apiTitle);
                json.title = title;
                auth.setUser(json);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Box sx={styles.all}>
            <Box sx={styles.body}>
                <TicketShop />
            </Box>
            <RegisterTemporaryAccountModal
                isOpen={showRegisterModal}
                callback={() => setShowRegisterModal(false)}
            />
            {!showRegisterModal ? (
                <FTUEModals modal={FTUE_MODALS.SHOP} />
            ) : null}
        </Box>
    );
};

const styles = {
    all: {
        width: "100%",
        height: "100%",
        ...columnStyles,
        backgroundColor: Colors.PRIMARY,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%), url(${require("../../assets/images/background.jpg")})`,
    },
    body: {
        marginTop: "10vh",
        ...columnStyles,
        height: "90vh",
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
    },
};

export default Shop;
