/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNumber } from './TypeOfHelper';

// =======================================================

export function isPositiveNumber(number: any) {
  return isNumber(number) && number >= 0;
}

export function isStrictlyPositiveNumber(number: any) {
  return isNumber(number) && number > 0;
}

// =======================================================