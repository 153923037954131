/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalGameData } from "../../models/localData";
import { getRouteManager } from "../routeManager";
import { getLanguage } from "../translation";

class CacheManager {
  private getData = async (url: string): Promise<any> => {
    try {
      const data = await fetch(url);
      if (data) {
        return data.json();
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @param id
   */
  public loadStory = async (id: number): Promise<any> => {
    const lang = getLanguage();
    const url = getRouteManager().export(id, lang);
    try {
      const story = await this.getData(url);
      if (story) {
        const images = await this.getData(getRouteManager().imagesForStoryWeb(id));
        //const images = await this.getData(getRouteManager().imagesForStory(id));
        const sounds = await this.getData(getRouteManager().soundsForStory(id));
        const animations = await this.getData(
          getRouteManager().animationsForStory(id)
        );
        const colors = await this.getData(getRouteManager().colorsForStory(id));

        story.images = images ? images : [];
        story.sounds = sounds ? sounds : [];
        story.animations = animations ? animations : [];
        story.colors = colors ? colors : [];

        return story;
      } else return null;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  public hasCurrentGame = (): LocalGameData | null => {
    const data = localStorage.getItem("currentGame");
    if (data) {
      const currentGame: LocalGameData = JSON.parse(data);
      return currentGame;
    } else {
      return null;
    }
  };

  public saveCurrentGame = (currentGame: LocalGameData) => {
    localStorage.setItem("currentGame", JSON.stringify(currentGame));
  };

  public deleteCurrentGame = () => {
    localStorage.removeItem("currentGame");
  };
}

const cacheManager = new CacheManager();

export const getCacheManager = () => {
  return cacheManager;
};
