/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  ButtonBase,
  Typography,
  Modal,
  InputBase as Input,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRouteManager } from "../../../services/routeManager";
import { getLanguage, strings } from "../../../services/translation";
import { Colors } from "../../../style";
import normalize from "../../../utils/fontSizer";
import { Player } from "@lottiefiles/react-lottie-player";
import { Formik } from "formik";
import * as yup from "yup";
import AvatarContainer from "../../../components/AvatarContainer";
import AvatarItemEditUser from "../../../components/avatarItemEditUser";

//ICONS
import { ReactComponent as IconItem } from "../../../assets/icons/icon_item.svg";
import { ReactComponent as IconBust } from "../../../assets/icons/icon_bust.svg";
import { ReactComponent as IconHat } from "../../../assets/icons/icon_hat.svg";
import { ReactComponent as IconMask } from "../../../assets/icons/icon_mask.svg";
import { ReactComponent as IconBackground } from "../../../assets/icons/icon_background.svg";
import { ReactComponent as IconEdit } from "../../../assets/icons/icon_edit.svg";
import { ReactComponent as IconUser } from "../../../assets/icons/icon_user.svg";
import { questIconAnimated } from "../../../constants/questIconAnimated";
import useAuth from "../../../services/Auth/useAuth";
import SoundManager from "../../../services/SoundManager";
import BigModal from "../../../components/BigModal";
import TitleModal from "../../../components/TitleModal";
import AvatarItemShop from "../../../components/avatarItemShop";
import { columnCenterStyles } from "../../../style/flex";
import { styles } from "./styles";

const EditUser = () => {
  const auth = useAuth();

  const queryParams = new URLSearchParams(window.location.search);
  const itemCategory = queryParams.get("itemCategory");
  const [itemType, setItemType] = useState<any>(itemCategory);

  const [avatar, setAvatar] = useState<any>(auth.user.avatar);
  const [items, updateItems] = useState<any>();
  const [perso, setPerso] = useState<any>();
  const [hat, setHat] = useState<any>();
  const [bust, setBust] = useState<any>();
  const [mask, setMask] = useState<any>();
  const [background, setBackground] = useState<any>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedAvatar, setIsLoadedAvatar] = useState(true);

  const [persoActive, setPersoActive] = useState(
    itemType && itemType === "perso" ? true : false,
  );
  const [bustActive, setBustActived] = useState(
    itemType && itemType === "buste" ? true : false,
  );
  const [hatActive, setHatActive] = useState(
    !itemType || itemType === "chapeau" ? true : false,
  );
  const [maskActive, setMaskActive] = useState(
    itemType && itemType === "masque" ? true : false,
  );
  const [backgroundActive, setBackgroundActive] = useState(
    itemType && itemType === "fond" ? true : false,
  );

  const [showPopUpChangePseudo, setShowPopUpChangePseudo] = useState(false);
  const [showPopUpChangeTitle, setShowPopUpChangeTitle] = useState(false);
  const [showPopUpMission, setShowPopUpMission] = useState(false);
  const [setuped, setSetuped] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    maxWidth: 600,
    backgroundColor: "#17181c",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 20,
    p: 4,
  };

  useEffect(() => {
    !items && setIsLoadedAvatar(true);
    getItems();
  }, []);

  useEffect(() => {
    if (items) {
      setupItems();
    }
  }, [items]);

  useEffect(() => {
    if (setuped) {
      setIsLoadedAvatar(false);
    }
  }, [setuped]);

  const byValue = (a: any, b: any) => a.displayOrder - b.displayOrder;

  const getItems = async () => {
    const token = auth.user.login_token;

    if (token) {
      const apiItems = getRouteManager().getAllUserItemsForUser();

      const json = await getRouteManager().fetchData(`${apiItems}`, {
        login_token: token,
      });
      if (json.flashmessage != "error") {
        json.map((item: any, index: any) => {
          item.key = index + 1;
          item.displayOrder = item.id;

          if (!item.unlocked) {
            item.displayOrder += item.shopVisible ? 1000 : 10000;
          }

          const apiImage = getRouteManager().getItemImage(item.id);

          item.image_backdrop = apiImage;

          const apiImagePromo = getRouteManager().getItemImageForVersion(
            item.id,
            "promo",
          );

          item.image_backdrop_promo = apiImagePromo;
        });

        json.sort(byValue);
        updateItems(json);
      }
    }
  };

  const buyItemCallback = async (itemsCallback: any = undefined) => {
    const tmp = await getRouteManager().fetchGetUserInfos(auth.user);
    auth.setUser(tmp);
    await getItems();
    setIsLoaded(false);
  };

  const setupItems = () => {
    if (items) {
      const perso: any[] = [];
      const bust = [{ id: null, type: "buste", unlocked: true }];
      const hat = [{ id: null, type: "chapeau", unlocked: true }];
      const mask = [{ id: null, type: "masque", unlocked: true }];
      const background: any[] = [];
      items.map((i: any) => {
        i.equiped = false;
        switch (i.type) {
          case "perso":
            if (i.id === avatar.perso) {
              i.equiped = true;
            }
            perso.push(i);
            break;
          case "buste":
            if (i.id === avatar.buste) {
              i.equiped = true;
            }
            bust.push(i);
            break;
          case "chapeau":
            if (i.id === avatar.chapeau) {
              i.equiped = true;
            }
            hat.push(i);
            break;
          case "masque":
            if (i.id === avatar.masque) {
              i.equiped = true;
            }
            mask.push(i);
            break;
          case "fond":
            if (i.id === avatar.fond) {
              i.equiped = true;
            }
            background.push(i);
            break;
        }
        if (i.justBought) {
          changeMenuActive(i.type);
          i.justBought = null;
        }
      });
      setPerso(perso);
      setMask(mask);
      setHat(hat);
      setBust(bust);
      setBackground(background);
      setSetuped(true);
    }
  };

  const updateAvatar = async (item: any) => {
    setIsLoadedAvatar(true);
    const av = avatar;
    switch (item.type) {
      case "perso":
        av.perso = item.id;
        break;
      case "chapeau":
        av.chapeau = item.id;
        break;
      case "buste":
        av.buste = item.id;
        break;
      case "masque":
        av.masque = item.id;
        break;
      case "fond":
        av.fond = item.id;
        break;
      default:
        break;
    }
    SoundManager.getInstance().playAvatarItemChangeSound();
    setAvatar(av);
    const userTmp = { ...auth.user };
    userTmp.avatar = av;
    auth.setUser(userTmp);
    setupItems();
    //await getEventManager().avatarUpdate(item.id, item.type);
    const value = getRouteManager().getUser().getLoginToken();
    if (value != null) {
      const url = getRouteManager().updateAvatarForUser();
      fetch(`${url}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_token: value,
          avatar: av,
        }),
      })
        .then(response => response.json())
        .then(async json => {
          if (json.flashmessage === "error") {
            console.log("no user found");
            setIsLoadedAvatar(false);
          } else {
            setIsLoadedAvatar(false);
          }
        });
    }
  };

  const goToShop = (toBuy: any) => {
    //navigation.replace('Shop', { target: 'avatar', item: toBuy });
    /* navigation.replace("StoryCarousel", {
      screen: "Shop",
      params: { target: "avatar", item: toBuy },
    }); */
  };

  const changeMenuActive = (item: string) => {
    SoundManager.getInstance().playTabBarSound();
    switch (item) {
      case "perso":
        setPersoActive(true);
        setBustActived(false);
        setMaskActive(false);
        setHatActive(false);
        setBackgroundActive(false);
        break;
      case "chapeau":
        setPersoActive(false);
        setBustActived(false);
        setMaskActive(false);
        setHatActive(true);
        setBackgroundActive(false);
        break;
      case "buste":
        setPersoActive(false);
        setBustActived(true);
        setMaskActive(false);
        setHatActive(false);
        setBackgroundActive(false);
        break;
      case "masque":
        setPersoActive(false);
        setBustActived(false);
        setMaskActive(true);
        setHatActive(false);
        setBackgroundActive(false);
        break;
      case "fond":
        setPersoActive(false);
        setBustActived(false);
        setMaskActive(false);
        setHatActive(false);
        setBackgroundActive(true);
        break;
      default:
        setPersoActive(true);
        setBustActived(false);
        setMaskActive(false);
        setHatActive(false);
        setBackgroundActive(false);
        break;
    }
  };

  const getData = async (url: any) => {
    const response = await fetch(url);
    return response.json();
  };

  const updatePseudo = (values: any) => {
    setShowPopUpChangePseudo(false);
    setIsLoaded(true);
    const api = getRouteManager().updatePseudo();
    const update = fetch(api, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_token: auth.user.login_token,
        pseudo: values.pseudo,
      }),
    })
      .then(response => response.json())
      .then(async json => {
        if (json.flashmessage !== "error") {
          const apiTitle = getRouteManager().getTitle(
            json.titleId,
            getLanguage(),
          );
          const title = await getData(apiTitle);
          json.title = title;
          //getRouteManager().setUser(json);
          //updateUser(json);
          auth.setUser(json);
        }
        setIsLoaded(false);
      });
    setIsLoaded(false);
  };

  const handleError = (error: string | any, type: string) => {
    if (error.endsWith("is a required field") && type === "pseudo") {
      return strings.pseudoRequired;
    }
  };

  const goBack = () => {
    /* navigation.goBack(); */
  };

  /* const renderItem = ({ item }) => <Item title={item} />;

  const Item = ({ title }) => (

    <AvatarItemEditUser
      updateAvatar={updateAvatar}
      title={title}
      avatar={avatar}
      goToShop={goToShop}
      setShowPopUpMission={setShowPopUpMission}
    />
  ); */

  if (isLoaded) {
    //return <Loading />;
    return null;
  }

  const renderItem = (p: any) => {
    return (
      <Box
        key={p.id}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {p.unlocked || !p.shopVisible ? (
          <AvatarItemEditUser
            updateAvatar={updateAvatar}
            title={p}
            avatar={auth.user.avatar}
            goToShop={goToShop}
            setShowPopUpMission={setShowPopUpMission}
          />
        ) : (
          <AvatarItemShop buyItemCallback={buyItemCallback} title={p} />
        )}
      </Box>
    );
  };

  return (
    <Box sx={styles.all}>
      {/*  <GoBackIcon goBack={goBack} /> */}
      <Box sx={styles.topMenu}>
        {auth.user && auth.user.avatar && (
          <AvatarContainer
            user={auth.user ? auth.user : null}
            persoWidth={150}
          />
        )}
        <Box
          mt={"1vh"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          /* onPress={() => {
            //SoundManager.getInstance().PlayOpenModalSound();
            setShowPopUpChangePseudo(true);
          }} */
        >
          <Typography style={styles.userName}>
            {auth.user && auth.user.pseudo ? auth.user.pseudo : ""}
          </Typography>
          <ButtonBase
            sx={styles.editIcon}
            onClick={() => {
              SoundManager.getInstance().playOpenModalSound();
              setShowPopUpChangePseudo(true);
            }}>
            <IconEdit width={18} height={18} fill={Colors.WHITE} />
            <Typography style={styles.userId}>
              {strings.changePseudo}
            </Typography>
          </ButtonBase>
        </Box>

        <Box
          mt={"1vh"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          /* onPress={() => {
            SoundManager.getInstance().PlayBtnSound();
            navigation.navigate('TitleScreen', { user: user });
          }} */
        >
          <Typography style={styles.userTitle}>
            {auth.user && auth.user?.title && auth.user?.title?.name
              ? auth.user.title.name
              : ""}
          </Typography>
          <ButtonBase
            sx={styles.editIcon}
            onClick={() => {
              SoundManager.getInstance().playOpenModalSound();
              setShowPopUpChangeTitle(true);
            }}>
            <IconEdit width={18} height={18} fill={Colors.WHITE} />
            <Typography style={styles.userId}>{strings.changeTitle}</Typography>
          </ButtonBase>
        </Box>
        <Box sx={styles.iconsMenu} mt={5}>
          <ButtonBase
            onClick={() => changeMenuActive("chapeau")}
            sx={hatActive ? styles.iconActive : styles.iconInactive}>
            <IconHat
              width={32}
              height={34}
              fill={hatActive ? Colors.WHITE : Colors.LOCK}
            />
          </ButtonBase>
          <ButtonBase
            onClick={() => changeMenuActive("buste")}
            sx={bustActive ? styles.iconActive : styles.iconInactive}>
            <IconBust
              width={32}
              height={34}
              fill={bustActive ? Colors.WHITE : Colors.LOCK}
            />
          </ButtonBase>
          <ButtonBase
            onClick={() => changeMenuActive("masque")}
            sx={maskActive ? styles.iconActive : styles.iconInactive}>
            <IconMask
              width={32}
              height={34}
              fill={maskActive ? Colors.WHITE : Colors.LOCK}
            />
          </ButtonBase>
          <ButtonBase
            onClick={() => changeMenuActive("perso")}
            sx={persoActive ? styles.iconActive : styles.iconInactive}>
            <IconItem
              width={32}
              height={34}
              fill={persoActive ? Colors.WHITE : Colors.LOCK}
            />
          </ButtonBase>
          <ButtonBase
            onClick={() => changeMenuActive("fond")}
            sx={backgroundActive ? styles.iconActive : styles.iconInactive}>
            <IconBackground
              width={32}
              height={34}
              fill={backgroundActive ? Colors.WHITE : Colors.LOCK}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Box
        mt={"2vh"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        {persoActive && (
          <Box sx={styles.item}>
            {perso && perso.map((p: any) => renderItem(p))}
          </Box>
        )}
        {hatActive && (
          <Box sx={styles.item}>
            {hat && hat.map((p: any) => renderItem(p))}
          </Box>
        )}
        {bustActive && (
          <Box sx={styles.item}>
            {bust && bust.map((p: any) => renderItem(p))}
          </Box>
        )}
        {maskActive && (
          <Box sx={styles.item}>
            {mask && mask.map((p: any) => renderItem(p))}
          </Box>
        )}
        {backgroundActive && (
          <Box sx={styles.item}>
            {background && background.map((p: any) => renderItem(p))}
          </Box>
        )}
      </Box>
      {/* MISSION REWARD MODAL */}

      <Modal
        open={showPopUpMission}
        onClose={() => setShowPopUpMission(false)}
        sx={{ ...columnCenterStyles }}>
        <Box sx={styles.modalViewBuyEpisode}>
          <Player
            src={questIconAnimated}
            autoplay={true}
            loop={true}
            speed={1}
            style={{ width: 90, height: 90, backgroundSize: "cover" }}
          />
          <Typography
            style={{
              color: Colors.LOCK,
              fontFamily: "Gilroy-Bold",
              fontSize: normalize(14),
              marginTop: 22,
              textAlign: "center",
            }}>
            {strings.missionRewardPopup}
          </Typography>
          <Box style={styles.separatorBuyEpisode} />

          <Box sx={styles.popupButtons}>
            <ButtonBase
              sx={styles.yesButton}
              onClick={() => {
                SoundManager.getInstance().playBtnSound();
                setShowPopUpMission(false);
              }}>
              <Typography sx={styles.yesText}>{strings.ok}</Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Modal>

      {/* CHANGE PSEUDO MODAL */}

      <Modal
        open={showPopUpChangePseudo}
        onClose={() => setShowPopUpChangePseudo(false)}
        sx={{ ...columnCenterStyles }}>
        <Box sx={styles.modalViewBuyEpisode}>
          <Formik
            initialValues={{
              pseudo: auth.user.pseudo,
            }}
            onSubmit={values => {
              updatePseudo(values);
            }}
            validationSchema={yup.object().shape({
              pseudo: yup.string().max(20).required(),
            })}>
            {({
              values,
              handleChange,
              errors,
              setFieldTouched,
              touched,
              isValid,
              handleSubmit,
            }) => (
              <Box sx={{ ...columnCenterStyles }}>
                <Typography
                  sx={{
                    color: Colors.WHITE,
                    fontFamily: "Gilroy-Bold",
                    fontSize: normalize(20),
                    marginBottom: "30px",
                    alignSelf: "center",
                    justifySelf: "center",
                  }}>
                  {strings.newPseudo}
                </Typography>
                <Box sx={{ width: "70%" }}>
                  <Input
                    value={values.pseudo}
                    onChange={handleChange("pseudo")}
                    onBlur={() => setFieldTouched("pseudo")}
                    sx={styles.myInputView}
                    placeholder={strings.mypseudo}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconUser
                          width={35}
                          height={35}
                          fillSecondary={Colors.INPUT}
                          fill={Colors.WHITE}
                        />
                      </InputAdornment>
                    }
                  />
                  {touched.pseudo && errors.pseudo && (
                    <Typography
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: normalize(12),
                        color: Colors.ERROR,
                        marginTop: 15,
                        textAlign: "center",
                      }}>
                      {handleError(errors.pseudo, "pseudo")}
                    </Typography>
                  )}
                </Box>

                <Box style={styles.separatorBuyEpisode} />
                <Box sx={styles.popupButtons}>
                  <ButtonBase
                    style={styles.noButton}
                    onClick={() => {
                      SoundManager.getInstance().playCloseModalSound();
                      setShowPopUpChangePseudo(false);
                    }}>
                    <Typography sx={styles.noText}>{strings.back}</Typography>
                  </ButtonBase>
                  <ButtonBase
                    style={styles.yesButton}
                    disabled={!isValid}
                    onClick={() => {
                      SoundManager.getInstance().playCloseModalSound();
                      isValid ? handleSubmit() : "";
                    }}>
                    <Typography sx={styles.yesText}>
                      {strings.update}
                    </Typography>
                  </ButtonBase>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Modal>

      {/* CHANGE TITLE MODAL */}
      <BigModal
        modalVisible={showPopUpChangeTitle}
        callback={() => setShowPopUpChangeTitle(false)}
        hasCloseIcon
        hasSeparator
        title={strings.titlesList}
        contentStyle={{ padding: "0px 50px" }}
        headerStyle={{ padding: "50px 50px 20px 50px" }}
        modalStyle={{ minHeight: "400px", height: "70vh" }}
        content={<TitleModal />}
      />
    </Box>
  );
};

export default EditUser;
