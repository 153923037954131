export const rewardItemJson = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 80.0000032584668,
  w: 50,
  h: 50,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'UI_reward_item',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [25, 25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.518, 13.017],
                    [-0.696, 0.655],
                    [-5.755, 0],
                    [-2.238, -2.079],
                    [0.038, -0.949],
                    [3.284, -1.731],
                    [0.945, 0.468],
                  ],
                  o: [
                    [-0.039, -0.949],
                    [2.24, -2.079],
                    [5.756, 0],
                    [0.697, 0.656],
                    [-0.517, 13.032],
                    [-0.945, 0.467],
                    [-3.282, -1.737],
                  ],
                  v: [
                    [-12.871, -6.878],
                    [-11.837, -9.404],
                    [0, -13.737],
                    [11.836, -9.404],
                    [12.872, -6.878],
                    [1.502, 13.27],
                    [-1.501, 13.269],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.006, 0],
                        [-1.045, 0.593],
                        [-0.358, -0.252],
                        [0.124, -0.416],
                        [3.821, 0],
                        [0.715, 2.338],
                        [-0.387, 0.272],
                        [-0.387, -0.271],
                      ],
                      o: [
                        [2.005, 0],
                        [0.358, -0.252],
                        [0.358, 0.251],
                        [-0.634, 2.36],
                        [-3.823, 0],
                        [-0.132, -0.448],
                        [0.386, -0.271],
                        [0.998, 0.66],
                      ],
                      v: [
                        [25.042, 27.245],
                        [29.562, 26.162],
                        [30.758, 26.162],
                        [31.149, 27.278],
                        [25.042, 32.398],
                        [18.853, 27.3],
                        [19.277, 26.096],
                        [20.568, 26.095],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.36, 0],
                        [0.617, -0.498],
                        [0.195, 0.11],
                        [-0.049, 0.217],
                        [-2.581, 0],
                        [-0.303, -1.965],
                        [0.195, -0.109],
                        [0.166, 0.151],
                      ],
                      o: [
                        [-1.359, 0],
                        [-0.164, 0.153],
                        [-0.196, -0.11],
                        [0.301, -1.966],
                        [2.581, 0],
                        [0.048, 0.218],
                        [-0.196, 0.111],
                        [-0.615, -0.501],
                      ],
                      v: [
                        [30.013, 20.805],
                        [27.098, 21.763],
                        [26.493, 21.834],
                        [26.246, 21.284],
                        [30.013, 16.363],
                        [33.782, 21.283],
                        [33.534, 21.834],
                        [32.926, 21.766],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.36, 0],
                        [0.617, -0.498],
                        [0.196, 0.11],
                        [-0.049, 0.217],
                        [-2.582, 0],
                        [-0.303, -1.965],
                        [0.196, -0.109],
                        [0.165, 0.151],
                      ],
                      o: [
                        [-1.361, 0],
                        [-0.165, 0.153],
                        [-0.195, -0.11],
                        [0.301, -1.966],
                        [2.581, 0],
                        [0.048, 0.218],
                        [-0.195, 0.111],
                        [-0.616, -0.501],
                      ],
                      v: [
                        [19.985, 20.805],
                        [17.07, 21.763],
                        [16.464, 21.834],
                        [16.218, 21.284],
                        [19.985, 16.363],
                        [23.753, 21.283],
                        [23.505, 21.834],
                        [22.898, 21.766],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [25, 24.38], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.803921628466, 0.278431372549, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 2,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0.029 },
                    t: 0,
                    s: [25, 25],
                    to: [0, 1.872],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 0.9 },
                    o: { x: 0.333, y: 0 },
                    t: 5,
                    s: [25, 20],
                    to: [0, 0],
                    ti: [0, -0.5],
                  },
                  { t: 20.0000008146167, s: [25, 25] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 0,
                    s: [100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 5,
                    s: [120, 120],
                  },
                  { t: 20.0000008146167, s: [100, 100] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.06, 8.01],
                    [-0.199, 0.666],
                    [-3.626, 2.093],
                    [-2.166, -0.495],
                    [-0.321, -0.611],
                    [1.439, -2.284],
                    [0.765, -0.048],
                  ],
                  o: [
                    [-0.369, -0.584],
                    [0.655, -2.124],
                    [3.625, -2.093],
                    [0.678, 0.16],
                    [4.413, 8.396],
                    [-0.425, 0.638],
                    [-2.699, 0.1],
                  ],
                  v: [
                    [-10.129, 1.22],
                    [-10.397, -0.748],
                    [-4.516, -7.781],
                    [4.514, -9.356],
                    [6.085, -8.142],
                    [6.25, 8.683],
                    [4.359, 9.774],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.264, 0.73],
                        [-0.443, 0.754],
                        [-0.317, -0.028],
                        [-0.074, -0.307],
                        [2.407, -1.389],
                        [1.3, 1.212],
                        [-0.145, 0.312],
                        [-0.342, -0.03],
                      ],
                      o: [
                        [1.263, -0.729],
                        [0.134, -0.288],
                        [0.317, 0.029],
                        [0.459, 1.717],
                        [-2.408, 1.39],
                        [-0.246, -0.235],
                        [0.145, -0.311],
                        [0.868, 0.053],
                      ],
                      v: [
                        [12.384, 33.752],
                        [14.837, 31.426],
                        [15.59, 30.991],
                        [16.243, 31.552],
                        [14.258, 36.998],
                        [8.506, 36.038],
                        [8.335, 35.125],
                        [9.148, 34.655],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.857, -0.495],
                        [0.208, -0.538],
                        [0.163, -0.002],
                        [0.048, 0.155],
                        [-1.626, 0.938],
                        [-0.906, -1.127],
                        [0.083, -0.14],
                        [0.16, 0.035],
                      ],
                      o: [
                        [-0.856, 0.494],
                        [-0.047, 0.156],
                        [-0.163, 0.001],
                        [-0.526, -1.348],
                        [1.625, -0.939],
                        [0.109, 0.12],
                        [-0.083, 0.142],
                        [-0.569, -0.092],
                      ],
                      v: [
                        [13.173, 27.889],
                        [11.685, 29.552],
                        [11.33, 29.817],
                        [10.975, 29.56],
                        [11.558, 25.091],
                        [15.721, 26.819],
                        [15.765, 27.256],
                        [15.357, 27.434],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.856, -0.494],
                        [0.208, -0.538],
                        [0.163, -0.003],
                        [0.048, 0.155],
                        [-1.627, 0.939],
                        [-0.905, -1.128],
                        [0.084, -0.14],
                        [0.159, 0.035],
                      ],
                      o: [
                        [-0.857, 0.495],
                        [-0.048, 0.156],
                        [-0.164, 0.001],
                        [-0.526, -1.347],
                        [1.625, -0.938],
                        [0.11, 0.12],
                        [-0.082, 0.141],
                        [-0.57, -0.092],
                      ],
                      v: [
                        [6.857, 31.535],
                        [5.369, 33.199],
                        [5.014, 33.464],
                        [4.659, 33.206],
                        [5.242, 28.737],
                        [9.404, 30.466],
                        [9.448, 30.903],
                        [9.041, 31.081],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [10.307, 31.218], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.803921628466, 0.278431372549, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 2,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24.873, 50.005], ix: 2 },
              a: { a: 0, k: [14.375, 19.5], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [38],
                  },
                  { t: 15.0000006109625, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [20],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.407, 8.388],
                    [-0.677, 0.16],
                    [-3.625, -2.093],
                    [-0.654, -2.124],
                    [0.369, -0.584],
                    [2.697, 0.104],
                    [0.425, 0.639],
                  ],
                  o: [
                    [0.322, -0.611],
                    [2.166, -0.495],
                    [3.625, 2.093],
                    [0.2, 0.667],
                    [-5.065, 8.02],
                    [-0.766, -0.049],
                    [-1.436, -2.287],
                  ],
                  v: [
                    [-6.088, -8.145],
                    [-4.517, -9.36],
                    [4.514, -7.784],
                    [10.393, -0.751],
                    [10.127, 1.217],
                    [-4.361, 9.773],
                    [-6.252, 8.679],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.263, -0.73],
                        [-0.874, -0.006],
                        [-0.134, -0.289],
                        [0.229, -0.217],
                        [2.407, 1.39],
                        [-0.4, 1.733],
                        [-0.342, 0.03],
                        [-0.145, -0.312],
                      ],
                      o: [
                        [1.263, 0.729],
                        [0.317, -0.028],
                        [0.134, 0.288],
                        [-1.258, 1.257],
                        [-2.408, -1.39],
                        [0.08, -0.331],
                        [0.342, -0.031],
                        [0.388, 0.778],
                      ],
                      v: [
                        [37.669, 33.783],
                        [40.91, 34.744],
                        [41.663, 35.179],
                        [41.504, 36.024],
                        [35.795, 37.028],
                        [33.751, 31.567],
                        [34.456, 30.963],
                        [35.27, 31.432],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.856, 0.495],
                        [0.57, -0.09],
                        [0.083, 0.14],
                        [-0.11, 0.119],
                        [-1.625, -0.938],
                        [0.524, -1.348],
                        [0.163, 0.002],
                        [0.05, 0.156],
                      ],
                      o: [
                        [-0.856, -0.494],
                        [-0.158, 0.037],
                        [-0.084, -0.142],
                        [0.905, -1.129],
                        [1.626, 0.939],
                        [-0.049, 0.155],
                        [-0.164, -0.002],
                        [-0.205, -0.539],
                      ],
                      v: [
                        [43.142, 31.534],
                        [40.957, 31.078],
                        [40.551, 30.903],
                        [40.595, 30.466],
                        [44.757, 28.736],
                        [45.342, 33.206],
                        [44.985, 33.463],
                        [44.627, 33.199],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.856, 0.494],
                        [0.57, -0.089],
                        [0.084, 0.14],
                        [-0.11, 0.118],
                        [-1.626, -0.939],
                        [0.524, -1.348],
                        [0.163, 0.002],
                        [0.049, 0.156],
                      ],
                      o: [
                        [-0.857, -0.495],
                        [-0.159, 0.037],
                        [-0.083, -0.141],
                        [0.905, -1.129],
                        [1.626, 0.938],
                        [-0.049, 0.155],
                        [-0.164, -0.001],
                        [-0.205, -0.539],
                      ],
                      v: [
                        [36.826, 27.888],
                        [34.641, 27.431],
                        [34.234, 27.256],
                        [34.279, 26.82],
                        [38.441, 25.09],
                        [39.025, 29.559],
                        [38.669, 29.816],
                        [38.311, 29.552],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [39.708, 31.232], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.803921628466, 0.278431372549, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 2,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24.63, 50.007], ix: 2 },
              a: { a: 0, k: [-14.875, 19.5], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [-38],
                  },
                  { t: 15.0000006109625, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [20],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 306.000012463636,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
