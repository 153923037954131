/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from "react";
import PurchaseSuccess from "./PurchaseSuccess";
import PurchaseFailure from "./PurchaseFailure";
import BackgroundColumn from "../../components/BackgroundColumn";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { styles } from "./styles";
import { ReactComponent as IconQuestion } from "../../assets/icons/icon_question.svg";
import useAuth from "../../services/Auth/useAuth";
import HelpModal from "./HelpModal";
import { getRouteManager } from "../../services/routeManager";
import { strings } from "../../services/translation";
import PurchaseRewardModal from "./PurchaseRewardModal";
import { getEventManager } from "../../services/EventManager";
import Pass from "../../components/Pass";
import { columnCenterStyles } from "../../style/flex";
import { PassRewardModal } from "./PurchaseRewardModal/PassRewardModal";

const Purchase = () => {
    const auth = useAuth();
    const queryParams = new URLSearchParams(window.location.search);
    const purchaseInfo = queryParams.get("purchaseInfo");
    const purchaseStatus = queryParams.get("purchaseStatus");

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(true);
    const [purchaseValidated, setPurchaseValidated] = useState("pending");
    const [showHelp, setShowHelp] = useState(false);
    const [showPassModal, setShowPassModal] = useState(false);
    const [newTickets, setNewTickets] = useState<any>(null);

    useEffect(() => {
        validatePurchase();
    }, [purchaseInfo, purchaseStatus]);

    const handleError = (errInfo?: string) => {
        switch (errInfo) {
            case "ticket-error":
                setErrorMsg(strings.purchaseErrors.err01);
                break;
            case "no-transaction-error":
                setErrorMsg(strings.purchaseErrors.err02);
                break;
            case "success-error":
                setErrorMsg(strings.purchaseErrors.err03);
                break;
            case "invalid-user-error":
                setErrorMsg(strings.purchaseErrors.err04);
                break;
            case "invalid-token-error":
                setErrorMsg(strings.purchaseErrors.err05);
                break;
            case "login-error":
                setErrorMsg(strings.purchaseErrors.err06);
                break;
            case "error-cancel":
                setErrorMsg(strings.purchaseErrors.err08);
                break;
            default:
                setErrorMsg(strings.purchaseErrors.err07);
                break;
        }
    };

    const validatePurchase = async () => {
        try {
            if (auth && auth.user) {
                if (purchaseInfo && purchaseStatus === "success") {
                    // call api to validate purchase
                    const apiUrl = getRouteManager().achievePurchaseWeb();
                    const response = await getRouteManager().fetchData(apiUrl, {
                        login_token: auth.user.login_token,
                        purchaseToken: purchaseInfo,
                    });
                    if (response && response.flashmessage) {
                        if (
                            response.flashmessage === "success" &&
                            response.tickets
                        ) {
                            //handle success show popup congrats
                            const json =
                                await getRouteManager().fetchGetUserInfos(
                                    auth.user,
                                );
                            auth.setUser(json);
                            setPurchaseValidated("success");
                            setLoading(false);
                            setTimeout(() => {
                                setNewTickets(response.tickets);
                                if (
                                    response.tickets.code ===
                                        "ADVENTURERPASS" ||
                                    response.tickets.code ===
                                        "ADVENTURERPASSPLUS"
                                ) {
                                    setShowPassModal(true);
                                }
                                const sanitizedPrice = response.tickets.price
                                    .replace(",", ".")
                                    .match(/[\d\.\d]+/i);
                                if (sanitizedPrice && sanitizedPrice[0]) {
                                    const price = parseFloat(
                                        sanitizedPrice[0]
                                            ? sanitizedPrice[0]
                                            : response.tickets.price,
                                    );
                                    const currency =
                                        localStorage.getItem("currency");
                                    getEventManager().logPurchase(
                                        response.transactionId,
                                        price,
                                        currency ? currency : "EUR",
                                    );
                                }
                            }, 1300);
                        } else if (response.flashmessage === "error") {
                            handleError(
                                response.errInfo
                                    ? response.errInfo
                                    : "Autre erreur",
                            );
                            setPurchaseValidated("error-validation");
                            setLoading(false);
                        }
                    } else {
                        setPurchaseValidated("error-validation");
                        //handle error no errInfo
                        handleError(
                            response.errInfo
                                ? response.errInfo
                                : "Error. Code : ERR-09",
                        );
                    }
                } else if (purchaseInfo && purchaseStatus !== "success") {
                    const apiUrl = getRouteManager().achievePurchaseWeb();
                    const response = await getRouteManager().fetchData(apiUrl, {
                        login_token: auth.user.login_token,
                        purchaseToken: purchaseInfo,
                    });
                    setPurchaseValidated("error-cancel");
                    handleError("error-cancel");
                    setLoading(false);
                } else {
                    setPurchaseValidated("error-cancel");
                    handleError("error-cancel");
                    setLoading(false);
                }
            }
        } catch (error) {
            setPurchaseValidated("error-validation");
            handleError();
            setLoading(false);
        }
    };

    return (
        <BackgroundColumn
            hasBackground
            goBack
            goBackAction={() => (window.location.href = "/")}
            loading={loading}
            matchesValue={1200}
            contentStyle={{ height: "100vh" }}
            header={
                <ButtonBase
                    sx={styles.helpBtn}
                    onClick={() => setShowHelp(true)}>
                    <Typography
                        component={"span"}
                        sx={[styles.subtitle, { fontSize: "15px" }]}>
                        {strings.labels.needHelp}
                    </Typography>
                    <IconQuestion width={50} height={50} />
                </ButtonBase>
            }
            content={
                <Box sx={styles.contentContainer}>
                    {purchaseValidated === "success" ? (
                        <PurchaseSuccess />
                    ) : (
                        <PurchaseFailure
                            error={errorMsg}
                            errorType={purchaseValidated}
                            helpCallback={() => setShowHelp(true)}
                        />
                    )}
                </Box>
            }
            globalPart={
                <>
                    <HelpModal
                        isOpen={showHelp}
                        callback={() => setShowHelp(false)}
                    />
                    <PurchaseRewardModal
                        isOpen={newTickets && newTickets?.number ? true : false}
                        tickets={newTickets}
                        callback={() => setNewTickets(undefined)}
                    />
                    <PassRewardModal
                        isOpen={showPassModal}
                        callback={() => setShowPassModal(false)}
                    />
                </>
            }
        />
    );
};

export default Purchase;
