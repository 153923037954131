/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { Box, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import NewsBtn from "../../components/newsBtn";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import {
    NotificationContext,
    SidebarContext,
} from "../../utils/ContextsHelper";
import "./style.css";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
    rowStyles,
} from "../../style/flex";
import Loading from "../../components/Loading";
import BackgroundRow from "../../components/BackgroundRow";
import { Gilroy } from "../../style/fonts";

const News = () => {
    const [articles, setArticles] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const { getNews } = useContext(NotificationContext);
    const { sidebarCollapsed } = useContext(SidebarContext);
    const matches = useMediaQuery(
        `(min-width:${sidebarCollapsed ? 1143 : 1195}px)`,
    );

    const updateNews = async () => {
        try {
            localStorage.setItem(
                "newsFetched",
                JSON.stringify(moment.utc(new Date())),
            );
            const tmp = await getNews();
            if (tmp) {
                setArticles(tmp);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        updateNews();
    }, []);

    if (loading) {
        return (
            <Box sx={{ width: "100%", height: "100%", ...columnCenterStyles }}>
                <Loading />
            </Box>
        );
    }
    return (
        <Box
            sx={[
                matches
                    ? {
                          ...rowCenterStyles,
                          alignItems: "flex-start",
                      }
                    : {
                          ...columnStyles,
                          alignItems: "center",
                      },
                styles.mainContainer,
            ]}>
            <Box
                sx={[
                    { width: "100%", alignItems: "center" },
                    matches ? styles.container : styles.containerSmall,
                    {
                        flex: "0 0 auto",
                    },
                ]}>
                <Typography
                    sx={[
                        styles.topMenuTitle,
                        !matches && {
                            justifyContent: "center",
                            textAlign: "center",
                        },
                    ]}>
                    {strings.news}
                </Typography>
                <Typography
                    sx={[
                        styles.subtitle,
                        !matches && {
                            justifyContent: "center",
                            marginBottom: "10px",
                            textAlign: "center",
                        },
                    ]}>
                    {strings.newsSubtitle}
                </Typography>
            </Box>

            <Box
                sx={[
                    {
                        ...columnStyles,
                        //flex: 1,
                        justifyContent: "flex-start",
                        maxWidth: "450px",
                        minWidth: "450px",
                        paddingTop: "4vh",
                        paddingBottom: "50px",
                    },
                    !matches && {
                        justifyContent: "center",
                        textAlign: "center",
                    },
                ]}>
                <Box sx={styles.articleList}>
                    {articles.length > 0 &&
                        articles.map((article: any) => {
                            return (
                                <NewsBtn article={article} key={article.id} />
                            );
                        })}
                </Box>
            </Box>
        </Box>
    );
};

const styles = {
    all: {
        width: "100%",
        height: "100%",
        ...columnStyles,
        flex: 1,
        position: "relative",
    },
    mainContainer: {
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        maxHeight: "calc(96vh - 20px)",
        flex: "1 1 auto",
    },
    container: {
        width: "100%",
        ...columnStyles,
        flex: 1,
        justifyContent: "flex-start",
        maxWidth: "450px",
        minWidth: "450px",
        rowGap: "10px",
        paddingTop: "4vh",
        paddingBottom: "50px",
    },
    container2: {
        ...columnStartStyles,
        gap: "5px",
        paddingTop: "4vh",
    },
    containerSmall: {
        ...columnStartStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
    },
    topMenuTitle: {
        width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "50px",
    },
    title: {
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        paddingTop: "60px",
        paddingBottom: "1vh",
    },
    separatorTop: {
        height: "2px",
        backgroundColor: Colors.BTN_ACTIVE,
        width: "100%",
    },
    articleList: {
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        columnGap: "10px",
        maxWidth: "1020px",
        width: "100%",
        minWidth: "450px",
    },
};

export default News;
