/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component, useEffect, useState } from "react";
import ColorManager from "../../../services/colorManager";
import StoryReader from "../../../services/StoryReader";
import styles from "./styles";
import TextScene from "../../../components/TextScene";
import { isBoolean, isNumber } from "lodash";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { strings } from "../../../services/translation";
import { ReactComponent as NextIcon } from "../../../assets/icons/icon_next.svg";
import { ReactComponent as IconVoted } from "../../../assets/icons/icon_voted.svg";
import { ReactComponent as IconBlocked } from "../../../assets/icons/icon_blocked.svg";
import { Box, ButtonBase, Fade, Typography } from "@mui/material";
import { isPositiveNumber } from "../../../utils/NumberHelper";
import AvatarContainer from "../../../components/AvatarContainer";
import Loading from "../../../components/Loading";
import SoundManager from "../../../services/SoundManager";
import PopUpIndicator from "../../../components/PopUpIndicator";
import CircularProgressNew from "../../../components/CircularProgress/CircularProgressNew";

// =======================================================

interface Props {
  text: string;
  onSkipImage: any;
  players: any;
  storyId: number;
  color: any;
  hlText: string;
  textColor: any;
  secondaryColor: any;
  showStatePopUp: boolean;
  overlay: boolean;
  storyReader: StoryReader;
  animUrl: string | null;
  disabled?: boolean;
  disabledText?: string | null;
  percent?: any;
  loading?: boolean;
  isVisibleText?: boolean;
  masterPlayer?: any;
}

// =======================================================

const PoolpScreen = (props: Props) => {
  const {
    text,
    players,
    color,
    hlText,
    textColor,
    showStatePopUp,
    overlay,
    storyReader,
    animUrl,
    disabled = false,
    percent = null,
    isVisibleText = true,
    onSkipImage,
    disabledText = "",
    masterPlayer = null,
  } = props;
  /**
   *
   */

  /*   const [fillValue, setFillValue] = useState(new Animated.Value(25));
  const [opacityValue, setOpacityValue] = useState(new Animated.Value(0)); */
  const [shouldShowArrow, setShouldShowArrow] = useState(true);
  //const animatedChoices = useRef<any>(null);
  const [showPopUpIndicator, setShowPopUpIndicator] = useState(false);

  /* useEffect(() => {
    let timeout: any;
    if (shouldShowArrow) {
      setShouldShowArrow(false);
      timeout = setTimeout(() => {
        setShouldShowArrow(true);
      }, 1000);
    } else {
      timeout = setTimeout(() => {
        setShouldShowArrow(true);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [text]); */

  /**
   *
   */
  const isLoading = () => {
    return isBoolean(props.loading) && props.loading;
  };

  /**
   *
   */
  const renderIcon = () => {
    if (isBoolean(props.loading) && props.loading) {
      return (
        <Loading
          size={"small"}
          color={ColorManager.getInstance().getColor("tertiary")}
        />
      );
    }

    if (isNotEmptyString(disabledText)) {
      if (disabledText === strings.messages.notConcerned) {
        return (
          <IconBlocked
            width={25}
            height={25}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
        );
      } else if (disabledText === strings.messages.alreadyVoted) {
        return (
          <IconVoted
            width={25}
            height={25}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
        );
      } else {
        return null;
      }
    }

    return (
      <NextIcon
        width={45}
        height={45}
        fill={ColorManager.getInstance().getColor("secondary")}
        fillSecondary={props.textColor}
      />
    );
  };

  /**
   *
   */
  const handleSubmit = () => {
    SoundManager.getInstance().playChoiceMadeSound();
    onSkipImage();
  };

  /**
   *
   */
  const renderSubmitButton = (isDisabled: boolean | null) => {
    const disabled = isBoolean(isDisabled) && isDisabled;

    return (
      <ButtonBase
        sx={{ borderRadius: "45px" }}
        onClick={() =>
          !disabled ? handleSubmit() : setShowPopUpIndicator(true)
        }
      >
        {renderIcon()}
      </ButtonBase>
    );
  };

  /**
   *
   */
  const drawPlayer = (players: any) => {
    if (isPositiveNumber(masterPlayer)) {
      const master = masterPlayer ? masterPlayer : 0;
      const player = players[master];

      if (player.avatar) {
        return (
          <AvatarContainer
            user={player}
            persoWidth={98}
            borderWidth={5}
            borderColor={ColorManager.getInstance().getColor("secondary")}
          />
        );
      } else {
        return (
          <Box
            sx={[
              styles.letter,
              {
                backgroundColor: player.letterColor,
                borderColor: ColorManager.getInstance().getColor("secondary"),
              },
            ]}
          >
            <Typography style={styles.playerLetter}>{player.letter}</Typography>
          </Box>
        );
      }
    }
  };

  return (
    <Fade in={true} appear timeout={800}>
      <Box sx={styles.container}>
        {isVisibleText ? (
          <TextScene
            players={players}
            color={color}
            hlText={hlText}
            textColor={textColor}
            shouldResize={false}
            marginTop={true}
            overlay={overlay}
            storyReader={storyReader}
          >
            {text}
          </TextScene>
        ) : (
          <>
            <Box style={styles.changeContainer}>
              {drawPlayer(players)}
              <Typography sx={[styles.changing, { color: textColor }]}>
                {strings.secret}
              </Typography>
            </Box>
            <TextScene
              players={players}
              color={color}
              hlText={hlText}
              textColor={textColor}
              overlay={overlay}
              storyReader={storyReader}
              shouldResize={false}
              marginTop={false}
            >
              {strings.secretTextHide}
            </TextScene>
          </>
        )}
        {!showStatePopUp ? (
          <Box sx={styles.buttonContainer}>
            {isNumber(percent) ? (
              <>
                {showPopUpIndicator &&
                isNotEmptyString(disabledText) &&
                !isLoading() ? (
                  <PopUpIndicator
                    onCancel={() => setShowPopUpIndicator(false)}
                    text={disabledText}
                    shouldGoUp={50}
                  />
                ) : null}
                <CircularProgressNew
                  percent={percent}
                  color={ColorManager.getInstance().getColor("tertiary")}
                  bgColor={ColorManager.getInstance().getColor("quinary")}
                >
                  {renderSubmitButton(disabled)}
                </CircularProgressNew>
              </>
            ) : (
              shouldShowArrow && renderSubmitButton(disabled)
            )}
          </Box>
        ) : null}
      </Box>
    </Fade>
  );
};

// =======================================================

export default PoolpScreen;
