export const itemBackgroundJson = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 900.000036657751,
  w: 50,
  h: 50,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'UI_item_BG Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [25, 25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.437, -1.252],
                    [0, 0],
                    [0, 0],
                    [2.252, -4.022],
                    [0, 0],
                    [0, 0],
                    [-1.252, -4.437],
                    [0, 0],
                    [0, 0],
                    [-4.022, -2.252],
                    [0, 0],
                    [0, 0],
                    [-4.437, 1.252],
                    [0, 0],
                    [0, 0],
                    [-2.252, 4.022],
                    [0, 0],
                    [0, 0],
                    [1.252, 4.437],
                    [0, 0],
                    [0, 0],
                    [4.022, 2.252],
                    [0, 0],
                  ],
                  o: [
                    [-4.437, -1.252],
                    [0, 0],
                    [0, 0],
                    [-4.022, 2.252],
                    [0, 0],
                    [0, 0],
                    [-1.252, 4.437],
                    [0, 0],
                    [0, 0],
                    [2.252, 4.022],
                    [0, 0],
                    [0, 0],
                    [4.437, 1.252],
                    [0, 0],
                    [0, 0],
                    [4.022, -2.252],
                    [0, 0],
                    [0, 0],
                    [1.252, -4.437],
                    [0, 0],
                    [0, 0],
                    [-2.252, -4.022],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.786, -24.061],
                    [-6.786, -24.061],
                    [-1.913, -4.619],
                    [-12.216, -21.812],
                    [-21.812, -12.216],
                    [-4.619, -1.913],
                    [-24.061, -6.786],
                    [-24.061, 6.786],
                    [-4.619, 1.913],
                    [-21.812, 12.216],
                    [-12.216, 21.812],
                    [-1.913, 4.619],
                    [-6.786, 24.061],
                    [6.786, 24.061],
                    [1.913, 4.619],
                    [12.216, 21.812],
                    [21.812, 12.216],
                    [4.619, 1.913],
                    [24.061, 6.786],
                    [24.061, -6.786],
                    [4.619, -1.913],
                    [21.812, -12.216],
                    [12.216, -21.812],
                    [1.913, -4.619],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'gf',
              o: { a: 0, k: 70, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0,
                    1,
                    1,
                    1,
                    0.5,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    0,
                    1,
                    0.25,
                    0.5,
                    1,
                    0,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [0, 0], ix: 5 },
              e: { a: 0, k: [23, 0], ix: 6 },
              t: 2,
              h: { a: 0, k: 0, ix: 7 },
              a: { a: 0, k: 0, ix: 8 },
              nm: 'Gradient Fill 1',
              mn: 'ADBE Vector Graphic - G-Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [25, 25], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 900.000036657751, s: [360] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 916.000037309445,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
