import { ReactComponent as IconDisciple } from "../../assets/icons/icon_disciple.svg";
import { ReactComponent as IconProdigy } from "../../assets/icons/icon_prodige.svg";
import { ReactComponent as IconMaster } from "../../assets/icons/icon_maitre.svg";
import { ReactComponent as IconLegend } from "../../assets/icons/icon_legend.svg";
import { Colors } from "../../style";
import { strings } from "../../services/translation";

export const storyRewards = [
    {
        level: 1,
        storyName: strings.mainQuest.storyTitles[0],
    },
    {
        level: 2,
        storyName: strings.mainQuest.storyTitles[1],
    },
    {
        level: 5,
        storyName: strings.mainQuest.storyTitles[2],
    },
    {
        level: 9,
        storyName: strings.mainQuest.storyTitles[3],
    },
    {
        level: 14,
        storyName: strings.mainQuest.storyTitles[4],
    },
    {
        level: 20,
        storyName: strings.mainQuest.storyTitles[5],
    },
];

export const categories = [
    {
        name: strings.levels.disciple,
        start: 1,
        end: 5,
        color: Colors.BRONZE,
        icon: <IconDisciple width={32} height={32} />,
    },
    {
        name: strings.levels.prodigy,
        start: 6,
        end: 10,
        color: Colors.TOKEN,
        icon: <IconProdigy width={32} height={32} />,
    },
    {
        name: strings.levels.master,
        start: 11,
        end: 15,
        color: Colors.GOLD,
        icon: <IconMaster width={32} height={32} />,
    },
    {
        name: strings.levels.legend,
        start: 16,
        end: 20,
        color: Colors.ACCENT,
        icon: <IconLegend width={32} height={32} />,
    },
];
