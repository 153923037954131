/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import {
  columnStartStyles,
  rowCenterStyles,
} from "../../style/flex";
import { Colors } from "../../style";
import { Eczar } from "../../style/fonts";
import normalize from "../../utils/fontSizer";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import SoundManager from "../../services/SoundManager";

interface Props {
  modalVisible: boolean;
  callback: any;
  title?: string | undefined;
  hasSeparator?: boolean | undefined;
  hasCloseIcon?: boolean | undefined;
  titleStyle?: any;
  content?: any;
  contentStyle?: any;
  modalStyle?: any;
  headerStyle?: any;
}

const BigModal = (props: Props) => {
  const {
    modalVisible,
    title,
    hasSeparator = true,
    hasCloseIcon = true,
    titleStyle,
    content,
    contentStyle,
    callback,
    modalStyle,
    headerStyle,
  } = props;

  return (
    <Modal open={modalVisible} onClose={() => callback()}>
      <Box style={styles.centeredView}>
        <Box sx={[styles.modalView, modalStyle ? modalStyle : {}]}>
          {title ? (
            <Box sx={[styles.header, headerStyle ? headerStyle : {}]}>
              <Typography sx={[styles.title, titleStyle ? titleStyle : {}]}>
                {title}
              </Typography>
            </Box>
          ) : null}
          {hasSeparator ? <Box sx={styles.separator} /> : null}
          <Box sx={[styles.container, contentStyle ? contentStyle : {}]}>
            {content ? content : null}
          </Box>
        </Box>
        {hasCloseIcon ? (
          <Box sx={[styles.closeView, modalStyle ? modalStyle : {}]}>
            <ButtonBase onClick={() => {
              SoundManager.getInstance().playCloseModalSound();
              callback(false)}} sx={styles.closeBtn}>
              <CloseIcon width={60} height={60} />
            </ButtonBase>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

const styles = {
  centeredView: {
    ...rowCenterStyles,
  },
  modalView: {
    width: "500px",
    height: "50vh",
    minHeight: "350px",
    maxHeight: "600px",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: "2px",
    },
    ...columnStartStyles,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  closeView: {
    width: "60px",
    height: "50vh",
    minHeight: "350px",
    maxHeight: "600px",
    ...columnStartStyles,
  },
  separator: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
  },
  header: {
    width: "100%",
    padding: "60px 100px 20px 100px",
  },
  title: {
    color: Colors.ACCENT,
    fontFamily: Eczar.regular,
    fontSize: normalize(36),
  },
  container: {
    width: "100%",
    padding: "0px 100px 0px 100px",
    ...columnStartStyles,
    alignItems: "flex-start",
    overflow: "auto",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  closeBtn: {
    position: "relative",
    left: "20px",
    width: "60px",
    height: "60px",
    borderRadius: "60px",
  },
};

export default BigModal;
