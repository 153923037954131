/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export class User {
  public id: number;
  private email: string;
  private playerCode: string;
  private tickets: number;
  private lang: string;
  private loginToken: string;
  public pseudo: string;
  public level: number;
  public experience: number;
  public nextLevel: any;
  public currentLevel: any;
  public avatar: any;
  public title: any;
  public tokens: number;

  constructor(
    id: number,
    email: string,
    playerCode: string,
    tickets: number,
    lang: string,
    loginToken: string,
    pseudo: string,
    avatar: any,
    level: number,
    experience: number,
    nextLevel: any,
    currentLevel: any,
    title = null,
    tokens: any
  ) {
    this.id = id;
    this.email = email;
    this.playerCode = playerCode;
    this.tickets = tickets;
    this.lang = lang;
    this.loginToken = loginToken;
    this.pseudo = pseudo;
    this.avatar = avatar;
    this.level = level;
    this.experience = experience;
    this.nextLevel = nextLevel;
    this.currentLevel = currentLevel;
    this.title = title;
    this.tokens = tokens;
  }

  public GetId(): number {
    return this.id;
  }

  public getEmail(): string {
    return this.email;
  }

  public getPlayerCode(): string {
    return this.playerCode;
  }

  public getTickets(): number {
    return this.tickets;
  }

  public getLang(): string {
    return this.lang;
  }

  public getLoginToken(): string {
    return this.loginToken;
  }

  public getLevel(): number {
    return this.level;
  }

  public getExperience(): number {
    return this.experience;
  }

  public getNextLevel(): any {
    return this.nextLevel;
  }

  public getCurrentLevel(): any {
    return this.currentLevel;
  }

  public getTitle(): any {
    return this.title;
  }

  public getTokens(): any {
    return this.tokens;
  }

  public setLoginToken(loginToken: string): void {
    this.loginToken = loginToken;
  }

  public setId(id: number): void {
    this.id = id;
  }

  public setEmail(email: string): void {
    this.email = email;
  }

  public setTickets(tickets: number): void {
    this.tickets = tickets;
  }

  public setLang(lang: string): void {
    this.lang = lang;
  }

  public setPlayerCode(playerCode: string): void {
    this.playerCode = playerCode;
  }

  public setLevel(level: number): void {
    this.level = level;
  }

  public setExperience(xp: number): void {
    this.experience = xp;
  }

  public setNextLevel(nextLevel: any): void {
    this.nextLevel = nextLevel;
  }

  public setCurrentLevel(currentLevel: any): void {
    this.currentLevel = currentLevel;
  }

  public setTitle(title: any): void {
    this.title = title;
  }

  public setTokens(tokens: any): void {
    this.tokens = tokens;
  }
}
