/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography, ButtonBase } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { rewardTicketJson } from "../../constants/rewardTicket";
import useAuth from "../../services/Auth/useAuth";
import SoundManager from "../../services/SoundManager";
import { getRouteManager } from "../../services/routeManager";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import {
    columnCenterStyles,
    rowCenterStyles,
    rowStyles,
} from "../../style/flex";
import normalize from "../../utils/fontSizer";
import LoadingView from "../LoadingView";
import TicketAnimated from "../TicketAnimated";
import TicketPromoAnimated from "../TicketPromoAnimated";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import Avatar from "../Avatar";
import { itemBackgroundJson } from "../../constants/itemBackground";
import { getEventManager } from "../../services/EventManager";

const BuyItemModal = ({
    item,
    closeModal,
    buyItemCallback,
    avatarToShow,
    style,
}: {
    item: any;
    closeModal: any;
    buyItemCallback: any;
    avatarToShow: any;
    style?: any;
}) => {
    const auth = useAuth();
    const { user } = auth;
    const avatar = avatarToShow ? avatarToShow : user.avatar;
    const navigate = useNavigate();

    const [items, setItems] = useState();
    const [showPopUpBuyTickets, setShowPopUpBuyTickets] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

    const closeWithCallback = () => {
        buyItemCallback(items ? items : undefined);
        closeModal();
    };
    /* FUNCTIONS */

    const byValue = (a: any, b: any) => a.displayOrder - b.displayOrder;

    /**
     * purchaseItem : purchase item and get response or error
     * @param item
     */
    const purchaseItem = async (item: any) => {
        try {
            if (auth && auth.user) {
                setLoading(true);
                const api = getRouteManager().createUserItem();

                const purchase = await getRouteManager().fetchData(`${api}`, {
                    login_token: auth.user.login_token,
                    item: item,
                });
                if (purchase.flashmessage === "not_enougth_tickets") {
                    setShowPopUpBuyTickets(true);
                    setLoading(false);
                } else {
                    purchase.map((i: any, index: number) => {
                        i.key = index + 1;
                        i.displayOrder = i.id;
                        if (!i.unlocked) {
                            i.displayOrder += i.shopVisible ? 1000 : 5000;
                        }
                        if (i.id === item.id) {
                            i.justBought = true;
                        }
                        const apiImage = getRouteManager().getItemImage(i.id);
                        i.image_backdrop = apiImage;
                        const apiImagePromo =
                            getRouteManager().getItemImageForVersion(
                                i.id,
                                "promo",
                            );
                        i.image_backdrop_promo = apiImagePromo;
                    });
                    purchase.sort(byValue);
                    setItems(purchase);
                    getEventManager().logItemPurchase(
                        item.id,
                        item.price,
                        item.type,
                    );
                    setShowPopUpCongrats(true);
                    SoundManager.getInstance().playUnlockItemSound();
                    setLoading(false);
                }
            } else {
                setLoading(false);
                alert("Not authenticated, refresh page");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const goToShop = () => {
        navigate("/shop");
    };

    const goToLibrary = () => {
        navigate(`/editUser`);
    };

    if (loading) {
        return <LoadingView />;
    } else {
        return (
            <Box sx={[styles.container, style ? style : {}]}>
                {showPopUpBuyTickets ? (
                    <Box sx={styles.container}>
                        <Player
                            src={rewardTicketJson}
                            loop
                            autoplay
                            speed={1.5}
                            style={{ width: "130px", height: "130px" }}
                        />

                        <Typography
                            sx={{
                                color: Colors.SECONDARY_TEXT,
                                fontFamily: "Gilroy-Bold",
                                fontSize: normalize(14),
                                width: "250px",
                                marginTop: "19px",
                                textAlign: "center",
                            }}>
                            {strings.notEnougthTickets}
                        </Typography>

                        <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
                            <ButtonBase
                                sx={styles.noButton}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    setShowPopUpBuyTickets(false);
                                }}>
                                <Typography sx={styles.noText}>
                                    {strings.notNow}
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    goToShop();
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.goToShop}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : showPopUpCongrats ? (
                    <Box sx={styles.container}>
                        <Box
                            style={{
                                width: "130px",
                                height: "130px",
                                borderRadius: "130px",
                                backgroundColor: Colors.FOND_ITEM_POPUP,
                                ...columnCenterStyles,
                                position: "relative",
                            }}>
                            <Player
                                src={itemBackgroundJson}
                                autoplay={true}
                                loop={true}
                                speed={1.5}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    overflow: "hidden",
                                    width: "130px",
                                    height: "130px",
                                    borderRadius: "130px",
                                    zIndex: 1,
                                }}
                            />

                            <img
                                src={getRouteManager().getItemImageForVersion(
                                    item.id,
                                    "promo",
                                )}
                                style={{
                                    width: "120px",
                                    height: "120px",
                                    borderRadius: "120px",
                                    zIndex: 2,
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                color: Colors.SECONDARY_TEXT,
                                fontFamily: "Gilroy-Bold",
                                fontSize: normalize(14),
                                width: "250px",
                                marginTop: "19px",
                                textAlign: "center",
                            }}>
                            {strings.greatChoiceItem}
                        </Typography>
                        <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    closeWithCallback();
                                }}>
                                <Typography sx={styles.yesText}>
                                    {strings.great}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ ...columnCenterStyles }}>
                        <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                            <ButtonBase
                                sx={{ borderRadius: "50px" }}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    closeWithCallback();
                                }}>
                                <CloseIcon width={50} height={50} />
                            </ButtonBase>
                        </Box>

                        {user && avatar ? (
                            <Avatar
                                fond={
                                    item.type === "fond"
                                        ? getRouteManager().getItemImage(
                                              item.id,
                                          )
                                        : avatar.fond != null
                                        ? getRouteManager().getItemImage(
                                              avatar.fond,
                                          )
                                        : null
                                }
                                bustePerso={
                                    item.type === "perso"
                                        ? getRouteManager().getItemImageForVersion(
                                              item.id,
                                              "bust",
                                          )
                                        : avatar.perso != null
                                        ? getRouteManager().getItemImageForVersion(
                                              avatar.perso,
                                              "bust",
                                          )
                                        : null
                                }
                                buste={
                                    item.type === "buste"
                                        ? getRouteManager().getItemImage(
                                              item.id,
                                          )
                                        : avatar.buste != null
                                        ? getRouteManager().getItemImage(
                                              avatar.buste,
                                          )
                                        : null
                                }
                                perso={
                                    item.type === "perso"
                                        ? avatar.buste != null
                                            ? avatar.chapeau != null
                                                ? getRouteManager().getItemImageForVersion(
                                                      item.id,
                                                      "head",
                                                  )
                                                : getRouteManager().getItemImageForVersion(
                                                      item.id,
                                                      "noBust",
                                                  )
                                            : avatar.chapeau != null
                                            ? getRouteManager().getItemImageForVersion(
                                                  item.id,
                                                  "noHat",
                                              )
                                            : getRouteManager().getItemImageForVersion(
                                                  item.id,
                                                  "full",
                                              )
                                        : avatar.perso != null
                                        ? avatar.buste != null
                                            ? avatar.chapeau != null ||
                                              item.type === "chapeau"
                                                ? getRouteManager().getItemImageForVersion(
                                                      avatar.perso,
                                                      "head",
                                                  )
                                                : getRouteManager().getItemImageForVersion(
                                                      avatar.perso,
                                                      "noBust",
                                                  )
                                            : avatar.chapeau != null ||
                                              item.type === "chapeau"
                                            ? item.type === "buste"
                                                ? getRouteManager().getItemImageForVersion(
                                                      avatar.perso,
                                                      "head",
                                                  )
                                                : getRouteManager().getItemImageForVersion(
                                                      avatar.perso,
                                                      "noHat",
                                                  )
                                            : item.type === "buste"
                                            ? getRouteManager().getItemImageForVersion(
                                                  avatar.perso,
                                                  "noBust",
                                              )
                                            : getRouteManager().getItemImageForVersion(
                                                  avatar.perso,
                                                  "full",
                                              )
                                        : null
                                }
                                masque={
                                    item.type === "masque"
                                        ? getRouteManager().getItemImage(
                                              item.id,
                                          )
                                        : avatar.masque != null
                                        ? getRouteManager().getItemImage(
                                              avatar.masque,
                                          )
                                        : null
                                }
                                chapeau={
                                    item.type === "chapeau"
                                        ? getRouteManager().getItemImage(
                                              item.id,
                                          )
                                        : avatar.chapeau != null
                                        ? getRouteManager().getItemImage(
                                              avatar.chapeau,
                                          )
                                        : null
                                }
                                persoWidth={120}
                                borderColor={null}
                                borderWidth={null}
                            />
                        ) : null}

                        <Typography
                            style={{
                                color: Colors.SECONDARY_TEXT,
                                fontFamily: "Gilroy-Bold",
                                padding: "20px",
                                fontSize: normalize(16),
                                marginTop: 0,
                                textAlign: "center",
                            }}>
                            {strings.buyItem}
                        </Typography>
                        <Box sx={[styles.popupButtons]}>
                            {item.promo && item.promo.discount ? (
                                <ButtonBase
                                    sx={styles.noButton}
                                    onClick={() => {
                                        purchaseItem(item);
                                    }}>
                                    <Box sx={styles.ticketsOwned}>
                                        <TicketPromoAnimated
                                            style={{ width: 25, height: 25 }}
                                        />
                                        <Typography
                                            sx={[
                                                styles.storyPrice,
                                                {
                                                    color: Colors.OLD_PRICE,
                                                    textDecorationLine:
                                                        "line-through",
                                                    textDecorationStyle:
                                                        "solid",
                                                    fontSize: normalize(12),
                                                },
                                            ]}>
                                            {item.price}
                                        </Typography>
                                        <Typography
                                            sx={[
                                                styles.storyPrice,
                                                {
                                                    marginLeft: "7px",
                                                    color: Colors.PROMO,
                                                },
                                            ]}>
                                            {Math.ceil(
                                                item.price *
                                                    ((100 -
                                                        item.promo.discount) /
                                                        100),
                                            )}
                                        </Typography>
                                    </Box>
                                </ButtonBase>
                            ) : (
                                <ButtonBase
                                    sx={styles.noButton}
                                    onClick={() => {
                                        purchaseItem(item);
                                    }}>
                                    <Box sx={styles.ticketsOwned}>
                                        <TicketAnimated
                                            style={{ width: 25, height: 25 }}
                                        />
                                        <Typography
                                            sx={[
                                                styles.tokensAmountOwned,
                                                { color: Colors.TICKET },
                                            ]}>
                                            {item.price}
                                        </Typography>
                                    </Box>
                                </ButtonBase>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
};

const styles = {
    container: {
        ...columnCenterStyles,
        width: "100%",
    },
    storyPrice: {
        fontSize: normalize(21),
        fontFamily: "Gilroy-Bold",
        color: Colors.TICKET,
        marginLeft: "7px",
    },
    ticketsOwned: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    tokensAmountOwned: {
        fontSize: normalize(18),
        fontFamily: "Gilroy-Bold",
        color: Colors.TOKEN,
        marginLeft: "3px",
    },
    popupButtons: {
        width: "100%",
        minWidth: "220px",
        ...rowStyles,
        justifyContent: "space-evenly",
        alignItems: "flex-end",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        ...rowCenterStyles,
        padding: "10px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    tag: {
        //alignSelf: "flex-start",
        backgroundColor: Colors.BTN_ACTIVE,
        color: Colors.WHITE,
        marginBottom: "5px",
    },
};

export default BuyItemModal;
