export const ticketPack4Json = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 240.0000097754,
  w: 300,
  h: 120,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'pack4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -30, ix: 10 },
        p: { a: 0, k: [190, 63, 0], ix: 2, l: 2 },
        a: { a: 0, k: [45, 54, 0], ix: 1, l: 2 },
        s: { a: 0, k: [30, 30, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -270], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -140], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -10], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 120], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 250], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [330, 22.466], ix: 2 },
                  a: { a: 0, k: [400, 22.466], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -270], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -140], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -10], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 120], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 250], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 380], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket0',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 6,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [217, -42.534], ix: 2 },
                  a: { a: 0, k: [400, 22.466], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 6,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -270], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -140], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -10], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 120], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 250], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [104, 22.466], ix: 2 },
                  a: { a: 0, k: [400, 22.466], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -270], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -140], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, -10], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 120], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'gr',
                      it: [
                        {
                          ind: 0,
                          ty: 'sh',
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [-1.767, -0.006],
                                [-0.828, -1.558],
                                [0, 0],
                                [0, 0],
                                [-0.55, -1.673],
                                [1.229, -1.259],
                                [0, 0],
                                [0, 0],
                                [1.422, -1.028],
                                [1.586, 0.778],
                                [0, 0],
                                [0, 0],
                                [1.431, 1.037],
                                [-0.25, 1.74],
                                [0, 0],
                                [0, 0],
                                [-0.54, 1.669],
                                [-1.74, 0.292],
                                [0, 0],
                              ],
                              o: [
                                [0.817, -1.551],
                                [1.767, 0.005],
                                [0, 0],
                                [0, 0],
                                [1.741, 0.302],
                                [0.55, 1.674],
                                [0, 0],
                                [0, 0],
                                [0.258, 1.739],
                                [-1.423, 1.032],
                                [0, 0],
                                [0, 0],
                                [-1.58, 0.768],
                                [-1.429, -1.041],
                                [0, 0],
                                [0, 0],
                                [-1.233, -1.264],
                                [0.543, -1.673],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [451.679, 98.89],
                                [455.879, 96.375],
                                [460.096, 98.918],
                                [465.436, 108.963],
                                [476.652, 110.932],
                                [480.372, 114.146],
                                [479.274, 118.915],
                                [471.366, 127.024],
                                [473.018, 138.258],
                                [471.118, 142.761],
                                [466.225, 143.171],
                                [455.998, 138.137],
                                [445.799, 143.111],
                                [440.902, 142.678],
                                [438.98, 138.16],
                                [440.565, 126.939],
                                [432.612, 118.782],
                                [431.484, 114.008],
                                [435.19, 110.813],
                                [446.395, 108.91],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: 'Path 2',
                          mn: 'ADBE Vector Shape - Group',
                          hd: false,
                        },
                        {
                          ty: 'fl',
                          c: {
                            a: 0,
                            k: [
                              0.145098039216,
                              0.149019607843,
                              0.18431372549,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: 'Fill 1',
                          mn: 'ADBE Vector Graphic - Fill',
                          hd: false,
                        },
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [455.933, 120.018], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: 'Transform',
                        },
                      ],
                      nm: 'interior',
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: 'ADBE Vector Group',
                      hd: false,
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-6.622, -0.018],
                            [-0.576, -2.121],
                            [-5.162, -0.015],
                            [-1.534, 4.624],
                            [-2.061, -0.008],
                            [0, 0],
                            [-1.76, -1.754],
                            [-0.006, -2.469],
                            [-0.056, -20.275],
                            [1.748, -1.74],
                            [2.48, 0.004],
                            [6.622, 0.018],
                            [0.576, 2.121],
                            [5.162, 0.016],
                            [1.533, -4.623],
                            [2.062, 0.007],
                            [0, 0],
                            [1.761, 1.754],
                            [0.006, 2.47],
                            [0.056, 20.275],
                            [-1.747, 1.74],
                            [-2.481, -0.004],
                          ],
                          o: [
                            [2.2, 0.01],
                            [1.26, 4.712],
                            [5.158, 0.013],
                            [0.53, -1.986],
                            [6.621, -0.294],
                            [2.48, 0.004],
                            [1.755, 1.751],
                            [0.06, 20.278],
                            [0.01, 2.473],
                            [-1.75, 1.744],
                            [0, 0],
                            [-2.2, -0.01],
                            [-1.26, -4.713],
                            [-5.157, -0.013],
                            [-0.531, 1.987],
                            [-6.621, 0.293],
                            [-2.481, -0.003],
                            [-1.755, -1.752],
                            [-0.06, -20.277],
                            [-0.01, -2.473],
                            [1.75, -1.745],
                            [0, 0],
                          ],
                          v: [
                            [-15.642, -53.611],
                            [-10.934, -50.003],
                            [-0.117, -41.923],
                            [10.948, -49.859],
                            [15.341, -53.21],
                            [34.898, -53.466],
                            [41.516, -50.719],
                            [44.269, -44.126],
                            [44.521, 44.374],
                            [41.803, 50.956],
                            [35.2, 53.667],
                            [15.642, 53.609],
                            [10.934, 50.004],
                            [0.117, 41.921],
                            [-10.947, 49.859],
                            [-15.341, 53.21],
                            [-34.898, 53.465],
                            [-41.516, 50.719],
                            [-44.269, 44.125],
                            [-44.521, -44.374],
                            [-41.803, -50.956],
                            [-35.199, -53.666],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'gf',
                      o: { a: 0, k: 100, ix: 10 },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [0, 1, 0.839, 0, 0.5, 1, 0.78, 0, 1, 1, 0.722, 0],
                          ix: 9,
                        },
                      },
                      s: { a: 0, k: [-55.405, -44.257], ix: 5 },
                      e: { a: 0, k: [54.392, 44.257], ix: 6 },
                      t: 1,
                      nm: 'Gradient Fill 1',
                      mn: 'ADBE Vector Graphic - G-Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [455.946, 250], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'ticket1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-10, -42.534], ix: 2 },
                  a: { a: 0, k: [400, 22.466], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [100.5, 75.005],
                    to: [0, 36.468],
                    ti: [0, -13.101],
                  },
                  { t: 240.0000097754, s: [100.5, 205] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [102.5, 22.5], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'content',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 241.000009816131,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
