/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material"
import { isNotEmptyString } from "../../../utils/StringHelper";
import { getRouteManager } from "../../../services/routeManager";
import { useState } from "react";
import { strings } from "../../../services/translation";
import useAuth from "../../../services/Auth/useAuth";
import { styles } from "../styles";
import { useNavigate } from "react-router";
import LoadingView from "../../../components/LoadingView";
import { Player } from "@lottiefiles/react-lottie-player";
import { rewardTicketJson } from "../../../constants/rewardTicket";
import SoundManager from "../../../services/SoundManager";
import { rewardStoryJson } from "../../../constants/rewardStory";
import { errorAnim } from "../../../constants/error";
import { ReactComponent as IconCompanions } from "../../../assets/icons/icon_companion.svg";
import { Colors } from "../../../style";
import TicketAnimated from "../../../components/TicketAnimated";
import { COMPANIONS } from "../../../constants";


export const BuyCompanionSlotModal = ({ callback }: { callback: any }) => {



    const auth = useAuth();
    const navigate = useNavigate();

    // STATES
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPopUpBuyTickets, setShowPopUpBuyTickets] = useState(false);
    const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

    // FUNCTIONS
    /**
     * @handleError
     * Handle error message to set error
     */
    const handleError = (message: string) => {
        if (isNotEmptyString(message)) {
            switch (message) {
                case "error-login":
                    setError(strings.errors.errorOccured);
                    break;
                case "error-not-enough-slots":
                    setError(strings.errors.notEnoughSlots);
                    break;
                case "error-not-enough-tickets":
                    setShowPopUpBuyTickets(true);
                    break;
                default:
                    setError(strings.errors.errorOccured);
                    break;
            }
        }
    }

    /**
     * @purchaseSlot
     * Try purchase companion slot, reset data if ok, handle error otherwise
     */
    const purchaseCompanionSlot = async () => {
        try {
            const token = auth.user.login_token;
            if (token) {
                setLoading(true)
                const url = getRouteManager().purchaseCompanionSlotForUser();
                const res = await getRouteManager().fetchData(url, {
                    login_token: token,
                })
                if (res) {
                    console.log(res);
                    if (res.flashmessage) {
                        setLoading(false)
                        handleError(res.flashmessage);
                    } else {
                        setLoading(false);
                        setShowPopUpCongrats(true);
                    }
                } else {
                    setLoading(false)
                    console.log("api error");
                }
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    /**
    * @goToShop
    * Navigate to shop
    */
    const goToShop = () => {
        navigate("/shop");
    };


    return <Box sx={styles.purchaseSlotModal}>
        {loading ? (
            <LoadingView />
        ) : (
            <Box sx={styles.containerModal}>
                {showPopUpBuyTickets ? (
                    <Box sx={styles.containerModal}>
                        <Player
                            src={rewardTicketJson}
                            loop
                            autoplay
                            speed={1.5}
                            style={{ width: "130px", height: "130px" }}
                        />
                        <Typography
                            sx={styles.modalSubtitle}>
                            {strings.notEnougthTickets}
                        </Typography>
                        <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
                            <ButtonBase
                                sx={styles.noButton}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    setShowPopUpBuyTickets(false);
                                }}>
                                <Typography sx={styles.noText}>{strings.notNow}</Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    goToShop();
                                }}>
                                <Typography sx={styles.yesText}>{strings.goToShop}</Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : showPopUpCongrats ? (
                    <Box sx={styles.containerModal}>
                        <Player
                            src={rewardStoryJson}
                            loop
                            autoplay
                            speed={1.5}
                            style={{ width: "130px", height: "130px" }}
                        />
                        <Typography
                            sx={styles.modalSubtitle}>
                            {strings.greatChoiceSlot}
                        </Typography>
                        <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    callback();
                                }}>
                                <Typography sx={styles.yesText}>{strings.great}</Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : isNotEmptyString(error) ? (
                    <Box sx={styles.containerModal}>
                        <Player
                            src={errorAnim}
                            autoplay
                            style={{ width: "120px", height: "120px" }}
                            keepLastFrame
                        />
                        <Typography
                            sx={styles.modalSubtitle}>
                            {error}
                        </Typography>
                        <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
                            <ButtonBase
                                sx={styles.yesButton}
                                onClick={() => {
                                    SoundManager.getInstance().playBtnSound();
                                    callback();
                                }}>
                                <Typography sx={styles.yesText}>{strings.cancel}</Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={styles.containerModal}>
                        <IconCompanions width={80} height={80} fill={Colors.WHITE} />
                        <Typography
                            sx={styles.modalSubtitle}>
                            {strings.companions.buySlot}
                        </Typography>

                        <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
                            <ButtonBase
                                sx={styles.noButton}
                                onClick={() => {
                                    SoundManager.getInstance().playCloseModalSound();
                                    callback();
                                }}>
                                <Typography sx={styles.noText}>{strings.notNow}</Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={styles.noButton}
                                onClick={() => {
                                    purchaseCompanionSlot();
                                }}>
                                <Box sx={styles.ticketsOwned}>
                                    <TicketAnimated style={{ width: 25, height: 25 }} />
                                    <Typography
                                        sx={[styles.ticketsAmountOwned, { color: Colors.TICKET }]}>
                                        {COMPANIONS.PRICE}
                                    </Typography>
                                </Box>
                            </ButtonBase>
                        </Box>
                    </Box>
                )}
            </Box>
        )}
    </Box>
}