/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    ButtonBase,
    Modal,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useAuth from "../../../services/Auth/useAuth";
import { NotificationContext } from "../../../utils/ContextsHelper";
import { Referral } from "../../../models/referral";
import { getRouteManager } from "../../../services/routeManager";
import { getLanguage, strings } from "../../../services/translation";
import LoadingView from "../../../components/LoadingView";
import { styles } from "./styles";
import BackgroundRow from "../../../components/BackgroundRow";
import {
    columnStartStyles,
    columnStyles,
    rowStyles,
} from "../../../style/flex";
import { ReferralBoxEmpty } from "./ReferralBoxEmpty";
import { ReferralBox } from "./ReferralBox";
import { RewardInviteeBtn } from "./RewardInviteeBtn";
import Separator from "../../../components/Separator";
import ItemReward from "../../../components/Rewards/ItemReward";
import TitleReward from "../../../components/Rewards/TitleReward";
import PlayerCodeBtn from "../../../components/FriendScreen/PlayerCodeBtn";
import { normalizeSize } from "../../../utils/fontSizer";
import { isPositiveNumber } from "../../../utils/NumberHelper";

export const ReferralScreen = () => {
    /**
     *
     * LOCAL STATES & CONTEXTS
     *
     */

    const auth = useAuth();
    const { getReferrals } = useContext(NotificationContext);
    const matches = useMediaQuery(`(min-width:${1148}px)`);

    /**
     *
     * STATES
     *
     */

    const [activeReferral, setActiveReferral] = useState<Referral | null>(null);
    const [error, setError] = useState("");
    const [inviteeState, setInviteeState] = useState<Referral | null>(null);
    const [loading, setLoading] = useState(false);
    const [referralsState, setReferralsState] = useState<Referral[]>([]);
    const [reward, setReward] = useState<any>(null);
    const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

    /**
     *
     * USE EFFECTS
     *
     */
    useEffect(() => {
        initData();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @initData
     * Get levels and update rewards
     */
    const initData = async () => {
        try {
            if (!loading) setLoading(true);
            const data = await getReferrals();
            const { referrals, invitee } = data;
            if (referrals) {
                setReferralsState(referrals);
            }
            if (invitee) {
                setInviteeState(invitee);
            } else {
                setInviteeState(null);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    /**
     * @checkAndGetReward
     * Fetch reward for a certain referral
     * @param level
     */
    const checkAndGetReward = async (referral: Referral) => {
        try {
            if (!auth?.user?.login_token) {
                return;
            }
            if (referral.status === 0) {
                //if (referral.status === 1) {
                setLoading(true);
                setActiveReferral(referral);

                const api = await getRouteManager().unlockRewardForReferral();
                const data = await getRouteManager().fetchData(api, {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                    referralId: referral.id,
                });
                if (data?.flashmessage) {
                    handleError(data);
                    setLoading(false);
                    return;
                }
                if (data && data.reward) {
                    setReward(data.reward);
                    await initData();
                    setShowPopUpCongrats(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleError = (error?: {
        flashmessage?: string;
        errInfo?: string;
    }) => {
        if (error?.errInfo) {
            setError(error.errInfo);
        } else if (error?.flashmessage) {
            setError(error.flashmessage);
        } else {
            setError(strings.errors.errorOccured);
        }
    };

    /**
     * @closeModal
     * close reward modal
     */
    const closeModal = () => {
        setShowPopUpCongrats(false);
        setReward(null);
        setActiveReferral(null);
    };

    /**
     *
     * RENDER
     *
     */

    /**
     * @renderReferrals
     * @returns referrals items
     */
    const renderReferrals = () => {
        if (referralsState && referralsState.length) {
            return (
                <Box sx={{ ...columnStartStyles, gap: "10px" }}>
                    <Typography sx={styles.playerCode}>{`${
                        referralsState?.length
                            ? referralsState.filter(
                                  (item: Referral) =>
                                      !!item?.userId &&
                                      isPositiveNumber(item?.status),
                              )?.length
                            : 0
                    } / 5 ${strings.referral.friendsReferred}`}</Typography>
                    {referralsState.map((referral: Referral, index: number) => {
                        if (referral.status === -1) {
                            return <ReferralBoxEmpty key={index} />;
                        }
                        if (referral.user) {
                            return (
                                <ReferralBox
                                    referral={referral}
                                    callback={checkAndGetReward}
                                    disabled={
                                        referral.status !== 0 ||
                                        !referral.rewardId
                                    }
                                    key={index}
                                />
                            );
                        }
                        return null;
                    })}
                </Box>
            );
        }
        return null;
    };

    const renderInvitee = () => {
        if (inviteeState) {
            return (
                <Box sx={{ ...columnStartStyles }}>
                    <Typography sx={styles.referralCategory}>
                        {strings.referral.inviteeReward}
                    </Typography>
                    <RewardInviteeBtn
                        invitee={inviteeState}
                        callback={checkAndGetReward}
                        disabled={inviteeState?.status !== 0}
                    />
                </Box>
            );
        }
        return null;
    };

    const renderReward = () => {
        if (!reward) {
            return null;
        }
        if (
            reward &&
            activeReferral &&
            activeReferral.rewardType === "title" &&
            reward?.name
        ) {
            return <TitleReward title={reward} />;
        } else if (
            reward &&
            activeReferral &&
            activeReferral.rewardType === "item" &&
            reward?.id
        ) {
            return <ItemReward item={reward} />;
        }
    };

    if (loading) {
        return <LoadingView />;
    }

    return (
        <BackgroundRow
            hasBackground={false}
            style={{ height: "100%" }}
            bodyStyle={styles.bodyStyle}
            matchesValue={1148}
            leftPartStyle={
                !matches
                    ? { width: "100%", maxWidth: "unset", alignItems: "center" }
                    : {}
            }
            leftPart={
                <Box sx={matches ? styles.container : styles.containerSmall}>
                    <Typography
                        sx={[
                            styles.topMenuTitle,
                            !matches && { textAlign: "center" },
                        ]}>
                        {strings.referral.title}
                    </Typography>
                    <Typography
                        sx={[
                            styles.subtitle,
                            !matches && {
                                justifyContent: "center",
                                marginBottom: "10px",
                                textAlign: "center",
                            },
                        ]}>
                        {strings.referral.subtitle}
                    </Typography>
                    <Box
                        sx={[
                            {
                                ...rowStyles,
                                alignItems: "center",
                                width: "100%",
                                columnGap: "7px",
                            },
                            matches
                                ? { justifyContent: "flex-start" }
                                : { justifyContent: "center" },
                        ]}>
                        <Typography
                            sx={[
                                styles.playerCode,
                                !matches && {
                                    justifyContent: "center",
                                    textAlign: "center",
                                },
                            ]}>
                            {strings.labels.yourPlayerCode}
                        </Typography>
                        <PlayerCodeBtn />
                    </Box>
                </Box>
            }
            rightPartStyle={{
                height: "90vh",
            }}
            rightPart={
                <Box
                    sx={[
                        styles.containerRight,
                        matches
                            ? { marginLeft: normalizeSize(20) }
                            : { alignSelf: "center", paddingTop: "2vh" },
                    ]}>
                    {renderInvitee()}
                    <Typography sx={styles.referralCategory}>
                        {strings.referral.referralRewards}
                    </Typography>
                    {referralsState && referralsState.length ? (
                        renderReferrals()
                    ) : (
                        <Typography style={styles.referralSubtitle}>
                            {strings.referral.noReferrals}
                        </Typography>
                    )}
                    {activeReferral && showPopUpCongrats ? (
                        <Modal
                            open={showPopUpCongrats}
                            onClose={() => closeModal()}>
                            <Box sx={styles.modalView}>
                                <Typography sx={styles.congrats}>
                                    {strings.congrats}
                                </Typography>
                                <Box sx={styles.rewardsList}>
                                    {/* TODO: render reward */}
                                    {renderReward()}
                                </Box>
                                <Typography sx={styles.congrats2}>
                                    {strings.addedToLibrary}
                                </Typography>

                                <Separator style={{ margin: "25px 0px" }} />

                                <ButtonBase
                                    sx={styles.yesButton}
                                    onClick={() => closeModal()}>
                                    <Typography sx={styles.yesText}>
                                        {strings.great}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        </Modal>
                    ) : null}
                </Box>
            }
        />
    );
};
