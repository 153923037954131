import { Box, ButtonBase } from "@mui/material";
import { styles } from "./style";
import SoundManager from "../../services/SoundManager";
import { useEffect, useState } from "react";
import News from "../News";
import FriendScreen from "../../components/FriendScreen";
import LadderScreen from "../../components/LadderScreen";
import OnlineListScreen from "../../components/OnlineListScreen";
import useAuth from "../../services/Auth/useAuth";
import RegisterTemporaryAccountModal from "../../components/RegisterTemporaryAccountModal";
import FTUEModals from "../../components/FTUEModals";
import { FTUE_MODALS } from "../../constants";
import SocialMenu from "./SocialMenu";
import { strings } from "../../services/translation";
import { getRouteManager } from "../../services/routeManager";
import { ReactComponent as Discord } from "../../assets/icons/icon_discord.svg";

const SocialScreen = () => {
    /**
     *
     * STATES
     *
     */
    const auth = useAuth();

    // MENU
    const [menuActiveIndex, setMenuActiveIndex] = useState(0);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    /**
     *
     * USE EFFETCS
     *
     */

    useEffect(() => {
        if (!auth || auth?.user?.isTemporaryAccount) {
            setShowRegisterModal(true);
        }
    }, [auth]);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @changeMenuActive
     * Change active item menu
     */
    const changeMenuActive = (type: string) => {
        SoundManager.getInstance().playTabBarSound();
        const menuTabs = ["online", "friends", "ladder", "news"];
        const index = menuTabs.indexOf(type);
        if (index !== -1) {
            setMenuActiveIndex(index);
        }
    };

    /**
     * @renderSocialPart
     * Renders the good component for menu tab selected
     */
    const renderSocialPart = () => {
        switch (menuActiveIndex) {
            case 0:
                return <OnlineListScreen />;
            case 1:
                return <FriendScreen />;
            case 2:
                return <LadderScreen />;
            case 3:
                return <News />;
            default:
                return;
        }
    };

    const unlockMissionForUser = async () => {
        try {
            return await getRouteManager().fetchData(
                getRouteManager().unlockMissionForUser(),
                {
                    login_token: auth.user.login_token,
                    missionId: 40,
                },
            );
        } catch (err) {
            console.log(err);
        }
    };

    const clickBtn = async (type: string) => {
        await unlockMissionForUser();
        window.open(strings.socials.discord, "_blank")?.focus();
    };

    return (
        <Box sx={styles.containerGlobal}>
            <SocialMenu
                activeIndex={menuActiveIndex}
                changeMenuActive={changeMenuActive}
            />
            <Box sx={styles.container}>{renderSocialPart()}</Box>
            <RegisterTemporaryAccountModal
                isOpen={showRegisterModal}
                callback={() => setShowRegisterModal(false)}
            />
            <ButtonBase onClick={() => clickBtn("discord")} sx={styles.discord}>
                <Discord width={60} height={60} />
            </ButtonBase>
            {!showRegisterModal ? (
                <FTUEModals modal={FTUE_MODALS.SOCIAL} />
            ) : null}
        </Box>
    );
};

export default SocialScreen;
