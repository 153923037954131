/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React from "react";
import { strings } from "../../../services/translation";
import GradientBtn from "../../../components/GradientBtn";
import { SUPPORT_MAIL } from "../../../utils/constants";
import { getRouteManager } from "../../../services/routeManager";
import useAuth from "../../../services/Auth/useAuth";
import { Box } from "@mui/material";
import BackgroundRow from "../../../components/BackgroundRow";
import { useNavigate } from "react-router";
import { columnStartStyles, rowCenterStyles } from "../../../style/flex";
import SupportBtn from "../../../components/SupportBtn";
/* import GoBackIcon from "../../../components/goBackIcon";
import SupportBtn from "../../../components/supportBtn";
import MoreText from "../../../components/supportBtn/copy" ;/*/

const SupportScreen = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const contact = async () => {
    try {
      if (auth && auth.user) {
        const value = auth.user.login_token;
        const { email, player_code, pseudo } = auth.user;
        if (player_code && email && pseudo) {
          const issueTitle = player_code + strings.contact.issue;
          const body =
            strings.contact.infos +
            pseudo +
            "%0Aemail : " +
            email +
            "%0Apseudo : " +
            pseudo +
            strings.contact.problem;
          window.location.href = `mailto:${SUPPORT_MAIL}?subject=${issueTitle}&body=${body}`;
        } else if (value) {
          const url = getRouteManager().getUserInfos();
          const getUser = await getRouteManager().fetchData(url, {
            login_token: value,
          });
          if (getUser) {
            const issueTitle = getUser.player_code + strings.contact.issue;
            const body =
              strings.contact.infos + getUser.player_code
                ? getUser.player_code
                : "" + "%0Aemail : " + getUser.email
                ? getUser.email
                : "" + "%0Apseudo : " + getUser.pseudo
                ? getUser.pseudo
                : "" + strings.contact.problem;
            window.location.href = `mailto:${SUPPORT_MAIL}?subject=${issueTitle}&body=${body}`;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BackgroundRow
      title={strings.support}
      subtitle={strings.contactSupportDescription}
      goBack
      goBackAction={() => navigate(-1)}
      leftPart={
        <Box sx={styles.container}>
          <GradientBtn onPress={() => contact()} width={"370px"}>
            {strings.contactUs}
          </GradientBtn>
        </Box>
      }
      rightPart={
        <Box sx={styles.container}>
          {strings.faqList.map((f: any, index: number) => (
            <SupportBtn
              key={"articles" + f.title + index}
              faq={f}
              index={index}
            />
          ))}
        </Box>
      }
    />
  );
};

const styles = {
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
  },
  contactBtn: {
    width: "100%",
    ...rowCenterStyles,
    alignSelf: "center",
  },
};

export default SupportScreen;
