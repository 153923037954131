/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import StoryReader from "../../../services/StoryReader";
import TextScene from "../../../components/TextScene";
import { strings } from "../../../services/translation";
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { getRouteManager } from "../../../services/routeManager";
import ColorManager from "../../../services/colorManager";
import AvatarContainer from "../../../components/AvatarContainer";
import { isPositiveNumber } from "../../../utils/NumberHelper";
import { Box, Fade, Typography } from "@mui/material";
import {
  columnCenterStyles,
  columnStartStyles,
  rowStyles,
} from "../../../style/flex";

// =======================================================

interface Props {
  text: string;
  drawChoices: any;
  imageId: number | null;
  players: any;
  color: any;
  hlText: string;
  storyId: any;
  textColor: any;
  passageNumber: any;
  currPassageId: any;
  overlay: boolean;
  storyReader: StoryReader;
  showOverlay: any;
  isVisibleText?: boolean;
  masterPlayer?: number | undefined;
}

// =======================================================

// =======================================================

const ImageScreen = (props: Props) => {
  /**
   *
   */
  const {
    text,
    drawChoices,
    imageId,
    players,
    color,
    hlText,
    textColor,
    overlay,
    storyReader,
    showOverlay,
    isVisibleText = true,
    masterPlayer = null,
  } = props;

  /**
   *
   */
  const drawPlayer = (players: any) => {
    if (isPositiveNumber(masterPlayer)) {
      const master = masterPlayer ? masterPlayer : 0;
      const player = players[master];

      if (player.avatar) {
        return (
          <AvatarContainer
            user={player}
            persoWidth={98}
            borderWidth={5}
            borderColor={ColorManager.getInstance().getColor("secondary")}
          />
        );
      } else {
        return (
          <Box
            sx={[
              styles.letter,
              {
                backgroundColor: player.letterColor,
                borderColor: ColorManager.getInstance().getColor("secondary"),
              },
            ]}>
            <Typography sx={styles.playerLetter}>{player.letter}</Typography>
          </Box>
        );
      }
    }
  };

  // --
  return (
    <Fade in={true} appear timeout={800}>
      <Box sx={[styles.container, { zIndex: 6 }]}>
        {!showOverlay && isVisibleText ? (
          <TextScene
            players={players}
            color={color}
            hlText={hlText}
            textColor={textColor}
            shouldResize={false}
            marginTop={false}
            overlay={overlay}
            storyReader={storyReader}>
            {text}
          </TextScene>
        ) : !isVisibleText ? (
          <>
            <Box style={styles.changeContainer}>
              {drawPlayer(players)}

              <Typography sx={[styles.changing, { color: textColor }]}>
                {strings.secret}
              </Typography>
            </Box>
            <TextScene
              players={players}
              color={color}
              hlText={hlText}
              textColor={textColor}
              overlay={overlay}
              storyReader={storyReader}
              shouldResize={false}
              marginTop={false}>
              {strings.secretTextHide}
            </TextScene>
          </>
        ) : null}

        {imageId && isVisibleText ? (
          <Box sx={{ ...columnCenterStyles }}>
            <img
              width={"60%"}
              style={styles.image}
              src={getRouteManager().sceneImage(imageId)}
            />
          </Box>
        ) : null}

        <Box
          style={{
            zIndex: 10,
            flex: 1,
            ...rowStyles,
            alignItems: "flex-end",
          }}>
          {drawChoices()}
        </Box>
      </Box>
    </Fade>
  );
};

// =======================================================

const centerStyles: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const styles = {
  container: {
    flex: 1,
    ...columnStartStyles,
    width: "100%",
    paddingTop: "32px",
    gap: "32px",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    flex: 2,
  },
  changeContainer: {
    ...centerStyles,
    marginBottom: "10px",
  },
  changing: {
    color: Colors.WHITE,
    fontSize: normalize(24),
    fontFamily: "Eczar-Regular",
    marginTop: "5px",
  },
  letter: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    borderWidth: "2px",
    ...centerStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
};

export default ImageScreen;
