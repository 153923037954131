/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography, ButtonBase } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { rewardTicketJson } from "../../constants/rewardTicket";
import useAuth from "../../services/Auth/useAuth";
import SoundManager from "../../services/SoundManager";
import { getRouteManager } from "../../services/routeManager";
import { strings, getLanguage } from "../../services/translation";
import { Colors } from "../../style";
import {
  columnCenterStyles,
  rowCenterStyles,
  rowStartStyles,
  rowStyles,
} from "../../style/flex";
import normalize from "../../utils/fontSizer";
import LoadingView from "../LoadingView";
import TicketAnimated from "../TicketAnimated";
import TicketPromoAnimated from "../TicketPromoAnimated";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { rewardStoryJson } from "../../constants/rewardStory";
import DynamicTag from "../DynamicTag";
import { getEventManager } from "../../services/EventManager";
import PassBtn from "../PassBtn";
import PassAnimated from "../PassAnimated";
import GradientText from "../GradientText";

const BuyStoryModal = ({
  story,
  price,
  closeModal,
  setPriceState,
  seriesId,
  style,
  hasCloseBtn = true,
  isFromModal = false,
}: {
  story: any;
  price: any;
  closeModal: any;
  setPriceState: any;
  seriesId?: number | undefined;
  style?: any;
  hasCloseBtn?: boolean;
  isFromModal?: boolean;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPopUpBuyTickets, setShowPopUpBuyTickets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

  /**
   * purchaseStory : purchase story and get response or error
   * @param story
   */
  const purchaseStory = async (id: number) => {
    SoundManager.getInstance().playBtnSound();
    try {
      //setShowPopUpBuyStory(false);
      if (auth.user) {
        setLoading(true);
        const api = getRouteManager().purchaseStory();
        const purchase = await getRouteManager().fetchData(api, {
          userId: auth.user.id,
          login_token: auth.user.login_token,
          storyId: id,
        });
        if (purchase.flashmessage === "already_exists") {
          alert(strings.alreadyExists);
          setLoading(false);
        } else if (purchase.flashmessage === "not_enougth_tickets") {
          setLoading(false);
          SoundManager.getInstance().playOpenModalSound();
          setShowPopUpBuyTickets(true);
        } else {
          //await getUserInfos();
          setPriceState(0);
          setLoading(false);
          SoundManager.getInstance().playUnlockStorySound();
          setShowPopUpCongrats(true);
          getEventManager().logStoryPurchase(story.id, price);
          const tmp = await getRouteManager().fetchGetUserInfos(auth.user);
          auth.setUser(tmp);
        }
        setLoading(false);
      } else {
        alert("Not authenticated, refresh page");
      }
    } catch (err: any) {
      setLoading(false);
    }
  };

  const goToShop = () => {
    navigate("/shop");
  };

  const goToLibrary = (id?: number) => {
    navigate(id ? `/${id}` : `/`);
  };

  if (loading) {
    return <LoadingView />;
  } else {
    return (
      <Box sx={[styles.container, style ? style : {}]}>
        {showPopUpBuyTickets ? (
          <Box sx={styles.container}>
            <Player
              src={rewardTicketJson}
              loop
              autoplay
              speed={1.5}
              style={{ width: "130px", height: "130px" }}
            />

            <Typography
              sx={{
                color: Colors.SECONDARY_TEXT,
                fontFamily: "Gilroy-Bold",
                fontSize: normalize(14),
                width: "250px",
                marginTop: "19px",
                textAlign: "center",
              }}>
              {strings.notEnougthTickets}
            </Typography>

            <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
              {showPopUpBuyTickets ? (
                <ButtonBase
                  sx={styles.noButton}
                  onClick={() => {
                    SoundManager.getInstance().playCloseModalSound();
                    setShowPopUpBuyTickets(false);
                  }}>
                  <Typography sx={styles.noText}>{strings.notNow}</Typography>
                </ButtonBase>
              ) : null}

              <ButtonBase
                sx={styles.yesButton}
                onClick={() => {
                  SoundManager.getInstance().playBtnSound();
                  goToShop();
                }}>
                <Typography sx={styles.yesText}>{strings.goToShop}</Typography>
              </ButtonBase>
            </Box>
          </Box>
        ) : showPopUpCongrats ? (
          <Box sx={styles.container}>
            <Player
              src={rewardStoryJson}
              loop
              autoplay
              speed={1.5}
              style={{ width: "130px", height: "130px" }}
            />
            <Typography
              sx={{
                color: Colors.SECONDARY_TEXT,
                fontFamily: "Gilroy-Bold",
                fontSize: normalize(14),
                width: "250px",
                marginTop: "19px",
                textAlign: "center",
              }}>
              {strings.greatChoice}
            </Typography>
            <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
              <ButtonBase
                sx={styles.yesButton}
                onClick={() => {
                  SoundManager.getInstance().playBtnSound();
                  if (isFromModal) {
                    closeModal();
                  } else {
                    goToLibrary(seriesId ? seriesId : story.id);
                  }
                }}>
                <Typography sx={styles.yesText}>
                  {isFromModal ? strings.great : strings.showMe}
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        ) : (
          <Box sx={{ ...columnCenterStyles }}>
            {hasCloseBtn ? (
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <ButtonBase
                  sx={{ borderRadius: "50px" }}
                  onClick={() => {
                    SoundManager.getInstance().playCloseModalSound();
                    closeModal();
                  }}>
                  <CloseIcon width={50} height={50} />
                </ButtonBase>
              </Box>
            ) : null}

            <img
              src={getRouteManager().titleForStory(getLanguage(), story.id)}
              alt="story-title"
              style={{ width: "100%" }}
            />
            {story.epNumber ? (
              <DynamicTag
                text={strings.episode + " " + story.epNumber}
                style={styles.tag}
              />
            ) : null}
            <Typography
              style={{
                color: Colors.SECONDARY_TEXT,
                fontFamily: "Gilroy-Bold",
                padding: "20px",
                fontSize: normalize(16),
                marginTop: 0,
                textAlign: "center",
              }}>
              {strings.buyStory}
            </Typography>
            <Box sx={[styles.popupButtons]}>
              <PassBtn
                borderRadius={13}
                style={{
                  width: "100px",
                  height: "47px",
                  opacity: 0.9,
                }}>

                <Box sx={{ ...rowStartStyles }}>
                  <PassAnimated style={{ width: 20, height: 20 }} />
                  <GradientText
                    color1={Colors.PASS1}
                    color2={Colors.PASS2}
                    style={styles.gradientTextSmall}>

                    {strings.pass.pass}
                  </GradientText>
                </Box>

              </PassBtn>
              {price && !story.promo ? (
                <ButtonBase
                  sx={styles.noButton}
                  onClick={() => {
                    purchaseStory(story.id);
                  }}>
                  <Box sx={styles.ticketsOwned}>
                    <TicketAnimated style={{ width: 25, height: 25 }} />
                    <Typography
                      sx={[styles.tokensAmountOwned, { color: Colors.TICKET }]}>
                      {price}
                    </Typography>
                  </Box>
                </ButtonBase>
              ) : price && story.promo ? (
                <ButtonBase
                  sx={styles.noButton}
                  onClick={() => {
                    purchaseStory(story.id);
                  }}>
                  <Box sx={styles.ticketsOwned}>
                    <TicketPromoAnimated style={{ width: 25, height: 25 }} />
                    <Typography
                      sx={[
                        styles.storyPrice,
                        {
                          color: Colors.OLD_PRICE,
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                          fontSize: normalize(12),
                        },
                      ]}>
                      {price}
                    </Typography>
                    <Typography
                      sx={[
                        styles.storyPrice,
                        { marginLeft: "7px", color: Colors.PROMO },
                      ]}>
                      {Math.ceil(price * ((100 - story.promo.discount) / 100))}
                    </Typography>
                  </Box>
                </ButtonBase>
              ) : null}
            </Box>
          </Box>
        )}
      </Box>
    );
  }
};

const styles = {
  container: {
    ...columnCenterStyles,
    width: "100%",
  },
  storyPrice: {
    fontSize: normalize(21),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "7px",
  },
  tokensOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "6px",
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokensAmountOwned: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.TOKEN,
    marginLeft: "3px",
  },
  popupButtons: {
    width: "100%",
    minWidth: "220px",
    ...rowStyles,
    justifyContent: "space-evenly",
    alignItems: "flex-end",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    ...rowCenterStyles,
    padding: "10px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
  tag: {
    //alignSelf: "flex-start",
    backgroundColor: Colors.BTN_ACTIVE,
    color: Colors.WHITE,
    marginBottom: "5px",
  },
  gradientTextSmall: {
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    marginLeft: '5px',
  }
};

export default BuyStoryModal;
