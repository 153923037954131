/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from "react";
import {
    Box,
    ButtonBase,
    Fade,
    Slide,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../style";
import { IChoice, ScreenType } from "../../services/StoryReader/interfaces";
import { getCacheManager } from "../../services/CacheManager";
import ColorManager from "../../services/colorManager";
import StoryReader from "../../services/StoryReader";
import { isNumber } from "../../utils/TypeOfHelper";
import { getRouteManager } from "../../services/routeManager";
import { isNotEmptyArray } from "../../utils/ArrayHelper";
import AvatarContainer from "../../components/AvatarContainer";
import normalize from "../../utils/fontSizer";
import { strings } from "../../services/translation";
import StandardScreen from "./standardScreen";
import Loading from "../../components/Loading";
import ImageScreen from "./imageScreen";
import PoolpScreen from "./poolpScreen";

/* ICONS */
import { ReactComponent as SecretIcon } from "../../assets/icons/icon_secret.svg";
import { ReactComponent as OptionsBtn } from "../../assets/icons/icon_logbook_btn.svg";
import { ReactComponent as BackgroundIcons } from "../../assets/icons/icons_background.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import FullScreenImageScreen from "./fullscreenImageScreen";
import ChangeScreen from "./changeScreen";
import SecretScreen from "./secretScreen";
import TimerScreen from "./timerScreen";
import TimerImageScreen from "./timerImageScreen";
import Choices from "../../components/Choices";
import TopMenu from "./TopMenu";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
} from "../../style/flex";
import StatePopUp from "../../components/StatePopUp";
import VarPopUp from "../../components/VarPopUp";
import HintPopup from "../../components/HintPopup";
import SuccessPopup from "../../components/SuccessPopup";
import ReportScreen from "../../components/Report";
import SoundManager from "../../services/SoundManager";
import Sound from "../../services/SoundManager/Sound";
import { LocalGameData } from "../../models/localData";
import { propertyExists } from "../../utils/ObjectHelper";
import { isValidSave, hasRequiredParameters } from "../../utils/GameHelper";
import ClassicModal from "../../components/ClassicModal";
import { SCREEN_HEIGHT } from "../../utils/size";
import GameVolumeModal from "../../components/ClassicModal/GameVolumeModal";
import "./style.css";
import DicePopup from "../../components/DicePopup";
import DiceFeedbackPopup from "../../components/DiceFeedbackPopup";
import { isPositiveNumber } from "../../utils/NumberHelper";

const Game = () => {
    /**
     * PARAMS
     */
    const { state }: any = useLocation();
    //const { players, storyId, title, save, episode, solo, isBoard } = state;
    const navigate = useNavigate();

    /**
     * STATES
     * */
    const INITIAL_STATE = {
        askExit: true,
        background: null,
        boardInfo: [],
        choices: [],
        choiceTimerSelect: -1,
        choiceType: null,
        countdown: 0,
        currPassageId: 0,
        episode: state?.episode ? state.episode : null,
        hlText: "",
        isBoard: state?.isBoard ? state.isBoard : false,
        isImageChanged: true,
        isReady: false,
        isVisibleVolumeModal: state?.solo ? false : true,
        loading: true,
        jsonVan: null,
        notificationList: [],
        passageNumber: 0,
        players: state?.players ? state.players : [],
        save: state?.save ? state.save : null,
        screenType: ScreenType.STANDARD,
        secretSequence: false,
        showBoardPopup: false,
        showInfo: null,
        showOverlay: false,
        showPlayersList: true,
        showPopUp: false,
        showPopUpBackToGroup: false,
        showPopUpExit: false,
        showPopUpGameOver: false,
        showPopUpPlayersConcerned: false,
        showPopUpSuccess: false,
        showStatePopUp: false,
        showVarPopUp: false,
        //showWaitingModal: false,
        solo: state?.solo ? state.solo : false,
        isFTUE: state?.isFTUE ? state.isFTUE : false,
        stateCurrVar: "",
        storyData: null,
        storyId: state?.storyId ? state.storyId : null,
        switchValueFirst: !SoundManager.getInstance().isSfxMuted,
        switchValueSecond: !SoundManager.getInstance().isMusicMuted,
        text: "",
        title: state?.title ? state.title : "",
        toggleBottom: false,
        topMenuIsOpen: false,
        visitedScenes: [],
        showBoardDicePopup: false,
        currentChoiceForDicePopup: null,
        randomForDicePopup: null,
        //wasHost: false,
        //shouldFetchRandom: null,
    };

    const [askExit, setAskExit] = useState<boolean>(INITIAL_STATE.askExit);
    const [background, setBackground] = useState<any>(INITIAL_STATE.background);
    const [boardInfo, setBoardInfo] = useState<Array<any>>(
        INITIAL_STATE.boardInfo,
    );
    const [choices, setChoices] = useState<Array<IChoice>>(
        INITIAL_STATE.choices,
    );
    const [choiceTimerSelect, setChoiceTimerSelect] = useState<any>(
        INITIAL_STATE.choiceTimerSelect,
    );
    const [choiceType, setChoiceType] = useState<any>(INITIAL_STATE.choiceType);
    const [countdown, setCountdown] = useState<any>(INITIAL_STATE.countdown);
    const [currPassageId, setCurrPassageId] = useState<any>(
        INITIAL_STATE.currPassageId,
    );
    const [episode, setEpisode] = useState<any>(INITIAL_STATE.episode);
    const [hlText, setHlText] = useState<string>(INITIAL_STATE.hlText);
    const [isBoard, setIsBoard] = useState<boolean>(INITIAL_STATE.isBoard);
    const [isImageChanged, setIsImageChanged] = useState(
        INITIAL_STATE.isImageChanged,
    );
    const [isReady, setIsReady] = useState<boolean>(INITIAL_STATE.isReady);
    const [isVisibleVolumeModal, setIsVisibleVolumeModal] = useState(
        INITIAL_STATE.isVisibleVolumeModal,
    );
    const [jsonVan, setJsonVan] = useState<any>(INITIAL_STATE.jsonVan);
    const [loading, setLoading] = useState(INITIAL_STATE.loading);
    const [notificationList, setNotificationList] = useState<Array<any>>(
        INITIAL_STATE.notificationList,
    );
    const [passageNumber, setPassageNumber] = useState(
        INITIAL_STATE.passageNumber,
    );
    const [players, setPlayers] = useState<any>(INITIAL_STATE.players);
    const [save, setSave] = useState<any>(INITIAL_STATE.save);
    const [screenType, setScreenType] = useState<ScreenType>(
        INITIAL_STATE.screenType,
    );
    const [secretSequence, setSecretSequence] = useState<boolean>(
        INITIAL_STATE.secretSequence,
    );
    const [showBoardPopup, setShowBoardPopup] = useState<boolean>(
        INITIAL_STATE.showBoardPopup,
    );
    const [showInfo, setShowInfo] = useState<any>(INITIAL_STATE.showInfo);
    const [showOverlay, setShowOverlay] = useState<boolean>(
        INITIAL_STATE.showOverlay,
    );
    const [showPlayersList, setShowPlayersList] = useState<boolean>(
        INITIAL_STATE.showPlayersList,
    );
    const [showPopUp, setShowPopUp] = useState<boolean>(
        INITIAL_STATE.showPopUp,
    );
    const [showPopUpBackToGroup, setShowPopUpBackToGroup] = useState<boolean>(
        INITIAL_STATE.showPopUpBackToGroup,
    );
    const [showPopupExit, setShowPopupExit] = useState<boolean>(
        INITIAL_STATE.showPopUpExit,
    );
    const [showPopUpGameOver, setShowPopUpGameOver] = useState<boolean>(
        INITIAL_STATE.showPopUpGameOver,
    );
    const [showPopUpPlayersConcerned, setShowPopUpPlayersConcerned] =
        useState<boolean>(INITIAL_STATE.showPopUpPlayersConcerned);
    const [showPopUpSuccess, setShowPopUpSuccess] = useState<boolean>(
        INITIAL_STATE.showPopUpSuccess,
    );
    const [showPopupVolume, setShowPopupVolume] = useState<boolean>();
    const [showStatePopUp, setShowStatePopUp] = useState<boolean>(
        INITIAL_STATE.showStatePopUp,
    );
    const [showReportScreen, setShowReportScreen] = useState<boolean>(false);
    const [showVarPopUp, setShowVarPopUp] = useState<boolean>(
        INITIAL_STATE.showVarPopUp,
    );
    const [solo, setSolo] = useState<boolean>(INITIAL_STATE.solo);
    const [isFTUE, setIsFTUE] = useState<boolean>(INITIAL_STATE.isFTUE);
    const [stateCurrVar, seStateCurrVar] = useState<any>(
        INITIAL_STATE.stateCurrVar,
    );
    const [storyData, setStoryData] = useState<any>(INITIAL_STATE.storyData);
    const [storyId, setStoryId] = useState<any>(INITIAL_STATE.storyId);
    const [switchValueFirst, setSwitchValueFirst] = useState<boolean>(
        INITIAL_STATE.switchValueFirst,
    );
    const [switchValueSecond, setSwitchValueSecond] = useState<boolean>(
        INITIAL_STATE.switchValueSecond,
    );
    const [text, setText] = useState<string>(INITIAL_STATE.text);
    const [title, setTitle] = useState<string>(INITIAL_STATE.title);
    const [toggleBottom, setToggleBottom] = useState<any>(
        INITIAL_STATE.toggleBottom,
    );
    const [topMenuIsOpen, setTopMenuIsOpen] = useState<boolean>(
        INITIAL_STATE.topMenuIsOpen,
    );
    const [visitedScenes, setVisitedScenes] = useState<Array<number>>(
        INITIAL_STATE.visitedScenes,
    );
    // RANDOM DICE
    const [showBoardDicePopup, setShowBoardDicePopup] = useState<boolean>(
        INITIAL_STATE.showBoardDicePopup,
    );
    const [currentChoiceForDicePopup, setCurrentChoiceForDicePopup] =
        useState<IChoice | null>(INITIAL_STATE.currentChoiceForDicePopup);
    const [randomForDicePopup, setRandomForDicePopup] = useState<number | null>(
        INITIAL_STATE.randomForDicePopup,
    );

    // ====== Local refs ======
    const countdownCall = useRef<any>(null);
    const lastSound = useRef<Sound>();
    //const soundManager = useRef<SoundManager>();
    const storyReader = useRef<StoryReader>();
    const timerRef = useRef<any>();
    const timerTextRef = useRef<any>();
    const containerRef = useRef<any>(null);

    const cover = storyId ? getRouteManager().cover(storyId) : null;

    const getStoryData = async (): Promise<any> => {
        const data = await getCacheManager().loadStory(storyId);
        if (data) {
            setStoryData(data);
            return data;
        }
        return null;
    };

    const loadFromLocal = (currentGame: LocalGameData) => {
        // step 0 : check save
        if (isValidSave(currentGame)) {
            if (propertyExists(currentGame, "background")) {
                setBackground(currentGame.background);
            }
            if (propertyExists(currentGame, "storyReader")) {
                const game = currentGame.storyReader;

                if (hasRequiredParameters(game)) {
                    // step 1 : get players
                    const gamePlayers = propertyExists(game, "_players")
                        ? game._players.players
                        : null;
                    if (isNotEmptyArray(gamePlayers)) {
                        setPlayers(gamePlayers);

                        storyReader.current = new StoryReader(
                            game._storyId,
                            game._storyData,
                            gamePlayers,
                            game._title,
                            game._currentScene.number,
                            false,
                            setAskExitCallback,
                            game.isSolo,
                            false,
                            game.isBoard,
                        );

                        // step 2: storyReader: load save and all data from local

                        storyReader.current.loadSave({
                            players: game._players.players,
                            variables: propertyExists(game, "_variables")
                                ? game._variables.variables
                                : [],
                            hints: propertyExists(game, "_informations")
                                ? game._informations
                                : [],
                        });
                        storyReader.current.setNbPlayers(gamePlayers.length);

                        if (propertyExists(game, "_episode")) {
                            storyReader.current.setEpisode(game._episode);
                        }
                        if (propertyExists(game, "_activePlayerId")) {
                            storyReader.current.setActivePlayerId(
                                game._activePlayerId,
                            );
                        }
                        if (propertyExists(game, "_masterPlayerId")) {
                            storyReader.current.setActivePlayerId(
                                game._masterPlayerId,
                            );
                        }
                        if (propertyExists(game, "_currentObjective")) {
                            storyReader.current.setCurrentObjective(
                                game._currentObjective,
                            );
                        }
                        if (propertyExists(game, "_visitedScenes")) {
                            storyReader.current.setVisitedScenes(
                                game._visitedScenes,
                            );
                        }

                        if (propertyExists(game, "_storyData")) {
                            setStoryData(game._storyData);
                            const { colors } = game._storyData;
                            ColorManager.getInstance().Clear();
                            ColorManager.getInstance().generateColors(colors);
                        }
                        setJsonVan(
                            getAnimForType(
                                "banner",
                                game._storyData.animations,
                            ),
                        );
                        // Indicate to updateState that we come from local
                        updateState(null, true);
                        setIsReady(true);
                        storyReader.current.shouldPlaySound();
                    }
                }
            }
        } else {
            leaveGame();
        }
    };

    const loadNewGame = (data: any) => {
        if (data) {
            const { colors } = data;
            ColorManager.getInstance().Clear();
            ColorManager.getInstance().generateColors(colors);
            if (save != null && save !== undefined) {
                const players = save.players;
                storyReader.current = new StoryReader(
                    storyId,
                    data,
                    players,
                    title,
                    undefined,
                    false,
                    setAskExitCallback,
                    solo,
                    false,
                    isBoard,
                );
                storyReader.current.loadSave(save);
            } else {
                storyReader.current = new StoryReader(
                    storyId,
                    data,
                    players,
                    title,
                    undefined,
                    false,
                    setAskExitCallback,
                    solo,
                    false,
                    isBoard,
                );
                storyReader.current.setNbPlayers(players.length);
            }

            if (episode != null && episode != undefined) {
                storyReader.current.setEpisode(episode);
            }

            setJsonVan(getAnimForType("banner", data.animations));

            setPassageNumber(0);
            setLoading(false);
            updateState();
            storyReader.current.shouldPlaySound();
            setIsReady(true);
        }
    };

    const initGame = async (): Promise<void> => {
        const data = getCacheManager().hasCurrentGame();
        if (storyId) {
            if (data) {
                getCacheManager().deleteCurrentGame();
            }
            const newData = await getStoryData();
            if (newData) {
                loadNewGame(newData);
            }
        } else if (data) {
            loadFromLocal(data);
        } else {
            leaveGame();
        }
    };

    useEffect(() => {
        if (isReady && storyData) {
            setLoading(false);
        }
    }, [isReady, storyData]);

    const getColor = (colorType: string) => {
        return ColorManager.getInstance().getColor(colorType);
    };

    const getAnimForType = (type: string, animations: any = null) => {
        if (animations && animations.length) {
            const anim = animations.find((item: any) => item.type === type);
            if (anim && anim.id) {
                return getRouteManager().animation(anim.id);
            } else {
                return null;
            }
        } else if (storyData) {
            if (storyData.animations && storyData.animations.length) {
                const anim = storyData.animations.find(
                    (item: any) => item.type === type,
                );
                if (anim && anim.id) {
                    return getRouteManager().animation(anim.id);
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
        return null;
    };

    /**
     * Constructor en gros
     */
    useEffect(() => {
        initGame();
    }, []);

    /**
     * Shortcut to reset the timer in game
     */
    const resetTimer = useCallback((): void => {
        clearInterval(countdownCall.current);
        countdownCall.current = null;
        //timerTextRef.current = null;
    }, [countdownCall]);

    /**
     * Triggered each time the `countdown` value decrements
     * [specially on a screen with a timer]
     */
    useEffect(() => {
        // condition to avoid trigger multiple times the following code
        if (
            countdownCall.current &&
            isScreenWithTimer(screenType) &&
            storyReader.current
        ) {
            const currChoices = storyReader.current.getCurrentChoices();
            if (countdown > 0) {
                SoundManager.getInstance().playTimerSound();
            } else if (countdown <= 0) {
                // the current timer is over
                resetTimer();
                // we determine the definitive choice when timer is over
                if (isNotEmptyArray(currChoices)) {
                    const getTimerDefault =
                        storyReader.current.getTimerSelect();
                    const target = getTimerDefault ? getTimerDefault : 0;
                    //const defaultChoice = currChoices[target];
                    setChoiceTimerSelect(target);
                }
            }
        }
    }, [countdown]);

    const updateBackgroundCallback = (newbg: any) => {
        if (newbg && newbg[0] && newbg[0].background === true && newbg[0].id) {
            setIsImageChanged(false);
            setTimeout(() => {
                setBackground(getRouteManager().sceneImage(newbg[0].id));
                setIsImageChanged(true);
            }, 400);
        }
    };

    /**
     * @launchTimer
     * Launch timer shortcut
     */
    const launchTimer = () => {
        if (storyReader.current) {
            const type = storyReader.current.getScreenType();
            if (isScreenWithTimer(type) && !countdownCall.current) {
                // handle launch timer
                countdownCall.current = setInterval(() => {
                    setCountdown((currentTime: any) => currentTime - 1);
                }, 1000);
            }
        }
    };

    /**
     * Update UI state from Story Reader.
     * @param {Any} callback - can be to launch a timer if necessary.
     */
    const updateState = (
        callback?: any,
        comesFromLocal = false,
        wasRandomDiceChoice = false,
    ): void => {
        if (storyReader.current) {
            resetTimer();
            const type = storyReader.current.getScreenType(
                updateBackgroundCallback,
            );
            setToggleBottom(false);
            setChoices(storyReader.current.getCurrentChoices());
            setChoiceType(storyReader.current.getChoiceType());
            setHlText(storyReader.current.getHlText().arrToReturn);
            setCountdown(storyReader.current.getTimerValue());
            setScreenType(type);
            setText(storyReader.current.getCurrentText());

            setCurrPassageId(storyReader.current.getCurrPassageId());
            setVisitedScenes(storyReader.current.getVisitedScenes());

            if (type == ScreenType.CHANGE) {
                const mj = storyReader.current.getActivePlayerId();
                storyReader.current.setMasterPlayerId(mj);
                setShowPlayersList(false);
            }
            if (type == ScreenType.SECRET) {
                const mj = storyReader.current.getActivePlayerId();
                storyReader.current.setMasterPlayerId(mj);
                setShowPlayersList(false), setSecretSequence(true);
                setPassageNumber((old) => old + 1);
            }
            if (isScreenWithTimer(type) && !countdownCall.current) {
                // handle launch timer
                /* countdownCall.current = setInterval(() => {
          setCountdown((currentTime: any) => currentTime - 1);
        }, 1000); */
                //const timerToMs = storyReader.current.getTimerValue() * 1000;
                //timerRef.current.reAnimate(100, 0, timerToMs, Easing.linear);

                if (
                    !wasRandomDiceChoice ||
                    (wasRandomDiceChoice && showPlayersList)
                ) {
                    launchTimer();
                }
            }
            if (!comesFromLocal) {
                saveStateToLocal();
            }
        }
    };

    const saveStateToLocal = () => {
        if (storyReader.current && storyData) {
            const currentGame: LocalGameData = {
                storyReader: storyReader.current,
                storyId: storyId,
                created: new Date(),
                background: background,
                isFinished: showReportScreen,
            };
            getCacheManager().saveCurrentGame(currentGame);
        }
    };

    /**
     * Go to next scene
     * @param {IChoice} choice - story choice
     * @param {number} [index] - player index to set active (-1 if normal choice)
     */
    const goNext = async (choice: IChoice, index = -1): Promise<void> => {
        /*  if (lastSound.current) {
      SoundManager.getInstance().removeSound(lastSound.current.id);
    } */
        if (storyReader.current) {
            if (index !== -1) {
                storyReader.current.setActivePlayerId(index);
            }
        }
        setNotificationList(INITIAL_STATE.notificationList);
        setShowBoardDicePopup(INITIAL_STATE.showBoardDicePopup);
        setCurrentChoiceForDicePopup(INITIAL_STATE.currentChoiceForDicePopup);
        setRandomForDicePopup(INITIAL_STATE.randomForDicePopup);
        //setShowInfo(INITIAL_STATE.showInfo);
        if (storyReader.current) {
            await storyReader.current.goToNextScene(
                choice,
                "",
                showStateUpdate,
                showSuccessUnlocked,
                showGroupCallback,
                showSecretCallback,
                showGameOverCallback,
                updateBoardPopup,
                showVarupdate,
                showInformationCallback,
                showBoardDicePopupCallback,
            );
            const wasRandomDiceChoice =
                choice.diceNumber &&
                isPositiveNumber(choice.diceNumber) &&
                choice.randomScenes?.length
                    ? true
                    : false;
            updateState(null, false, wasRandomDiceChoice);
            storyReader.current.shouldPlaySound();
        }
    };

    const onSubmitTextbox = async (text: string): Promise<void> => {
        if (storyReader.current) {
            await storyReader.current.goToNextScene(
                choices[0],
                text,
                showStateUpdate,
                showSuccessUnlocked,
                showGroupCallback,
                showSecretCallback,
                showGameOverCallback,
                updateBoardPopup,
                showVarupdate,
                showInformationCallback,
                showBoardDicePopupCallback,
            );
            updateState();
            storyReader.current.shouldPlaySound();
        }
    };

    const isScreenWithTimer = (type: any): boolean => {
        let bool = false;
        if (isNumber(type)) {
            bool = type === ScreenType.TIMER || type === ScreenType.IMAGE_TIMER;
        }
        return bool;
    };

    const handleLeavingStoryNew = (): void => {
        setShowPopupExit(true);
    };

    const showSuccessUnlocked = (currVar: any) => {
        const token = getRouteManager().getUser().getLoginToken();
        if (token != null) {
            const api = getRouteManager().checkSucccessForUser();
            const check = fetch(api, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login_token: token,
                    name: currVar,
                }),
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.flashmessage === "success") {
                        addNotificationToList({ type: "success", item: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const showSecretCallback = (newVal: boolean) => {
        setSecretSequence(newVal);
    };

    const showGameOverCallback = (newVal: boolean) => {
        setShowPopUpGameOver(newVal);
    };

    const showGroupCallback = (newVal: boolean) => {
        const old = showPlayersList;
        setShowPlayersList(newVal);
        if (newVal === true && !old) {
            setShowPopUpBackToGroup(true);
            setTimeout(() => {
                setShowPopUpBackToGroup(INITIAL_STATE.showPopUpBackToGroup);
            }, 2000);
        }
    };

    const updateBoardPopup = (newVal: boolean, boardInfo: Array<any> = []) => {
        setBoardInfo(boardInfo);
        setShowBoardPopup(newVal);
        /* if (!newVal) {
      launchTimer();
    } */
    };

    const showInformationCallback = (newVal: boolean) => {
        addNotificationToList({ type: "hint", item: newVal });
    };

    /**
     * @showDiceGroupFeedback
     * Add notif to indicate a dice has been rolled
     */
    const showDiceGroupFeedback = (newVal: boolean) => {
        addNotificationToList({ type: "dice", item: newVal });
    };

    const showBoardDicePopupCallback = (
        value: boolean,
        choice: IChoice | null,
        random: number | null = null,
    ) => {
        setShowBoardDicePopup(value);
        setCurrentChoiceForDicePopup(choice);
        setRandomForDicePopup(random);
        if (value && showPlayersList) {
            addNotificationToList({ type: "dice", item: value });
        }
        if (!value && !showPlayersList) {
            launchTimer();
        }
    };

    const showStateUpdate = (currVar: any) => {
        if (storyReader.current) {
            addNotificationToList({
                type: "state",
                item: {
                    currVar: currVar,
                    player: storyReader.current
                        .getPlayers()
                        .getPlayerById(storyReader.current.getActivePlayerId()),
                },
            });
        }
    };

    const showVarupdate = (currVar: any) => {
        addNotificationToList({ type: "var", item: currVar });
    };

    const addNotificationToList = (notif: any) => {
        if (notif && notif.type && notif.item) {
            if (notificationList && notificationList.length > 0) {
                setTimeout(() => {
                    setNotificationList((current) => [...current, notif]);
                }, 600);
            } else {
                setNotificationList((current) => [...current, notif]);
            }
        }
    };

    useEffect(() => {
        if (showInfo && !notificationList.includes(showInfo)) {
            if (!showInfo.remove) {
                addNotificationToList(showInfo);
            }
        } else if (showInfo && notificationList.includes(showInfo)) {
            setNotificationList((prev) => [
                ...prev.filter((entry: any) => entry != showInfo),
            ]);
        }
    }, [showInfo]);

    const removeNotificationFromList = useCallback(
        (notif: any) => {
            if (notif) {
                setShowInfo(notif);
                /* console.log('old list:', notificationList);
        const newList = notificationList.filter((entry: any) => entry != notif);
        console.log('newList', newList);
        setNotificationList(newList); */
            }
        },
        [notificationList],
    );

    const renderNotificationItem = useCallback(
        (item: any, index: any) => (
            <Box key={"notif-" + item.type + "-" + index}>
                {item.type === "state" ? (
                    <StatePopUp
                        currVar={item.item.currVar}
                        player={item.item.player}
                        storyId={storyId}
                        colorAccent={ColorManager.getInstance().getColor(
                            "tertiary",
                        )}
                        textColor={ColorManager.getInstance().getColor(
                            "quaternary",
                        )}
                        notif={item}
                        callback={removeNotificationFromList}
                        storyData={storyData}
                    />
                ) : item.type === "var" ? (
                    <VarPopUp
                        currVar={item.item}
                        storyId={storyId}
                        colorAccent={ColorManager.getInstance().getColor(
                            "tertiary",
                        )}
                        textColor={ColorManager.getInstance().getColor(
                            "quaternary",
                        )}
                        notif={item}
                        callback={removeNotificationFromList}
                        storyData={storyData}
                    />
                ) : item.type === "hint" ? (
                    <HintPopup
                        colorAccent={ColorManager.getInstance().getColor(
                            "tertiary",
                        )}
                        textColor={ColorManager.getInstance().getColor(
                            "quaternary",
                        )}
                        notif={item}
                        callback={removeNotificationFromList}
                    />
                ) : item.type === "dice" ? (
                    <DiceFeedbackPopup
                        colorAccent={ColorManager.getInstance().getColor(
                            "tertiary",
                        )}
                        textColor={ColorManager.getInstance().getColor(
                            "quaternary",
                        )}
                        notif={item}
                        callback={removeNotificationFromList}
                    />
                ) : item.type === "success" ? (
                    <SuccessPopup
                        notif={item}
                        callback={removeNotificationFromList}
                    />
                ) : null}
            </Box>
        ),
        [storyData],
    );

    const drawNotificationList = useCallback(() => {
        return (
            <Box sx={styles.notificationList}>
                {notificationList &&
                    storyData &&
                    !(
                        showBoardDicePopup &&
                        currentChoiceForDicePopup &&
                        randomForDicePopup &&
                        isPositiveNumber(randomForDicePopup) &&
                        !showPlayersList
                    ) &&
                    notificationList.map((item: any, index: any) =>
                        renderNotificationItem(item, index),
                    )}
            </Box>
        );
    }, [notificationList, showBoardDicePopup]);

    const toggleBottomFn = (v: boolean) => {
        setToggleBottom(v);
    };

    const toggleOverlay = (v: boolean) => {
        setShowOverlay(v);
    };

    const clearChoiceTimerCallback = () => {
        setChoiceTimerSelect(-1);
    };

    const setAskExitCallback = (value: boolean): void => {
        setAskExit(value);
    };

    const openTopMenu = useCallback(() => {
        setTopMenuIsOpen(true);
        SoundManager.getInstance().playTopMenuSound();
    }, []);

    const closeTopMenu = useCallback(() => {
        setTopMenuIsOpen(INITIAL_STATE.topMenuIsOpen);
    }, []);

    const onSkipImage = () => {
        SoundManager.getInstance().playChoiceMadeSound();
        goNext(choices[0]);
    };

    const toggleSound = useCallback((switchValue: boolean) => {
        setSwitchValueFirst(switchValue);
        if (switchValue === false) {
            SoundManager.getInstance().muteSfx();
        } else {
            SoundManager.getInstance().unmuteSfx();
        }
    }, []);

    const toggleMusic = useCallback((switchValue: boolean) => {
        setSwitchValueSecond(switchValue);

        if (switchValue === false) {
            SoundManager.getInstance().muteMusic();
        } else {
            SoundManager.getInstance().unmuteMusic();
        }
    }, []);

    const leaveGame = () => {
        getCacheManager().deleteCurrentGame();
        SoundManager.getInstance().removeAllSounds();
        //alert("Error while loading game");
        navigate("/", { replace: true });
    };

    const drawPlayerActive = () => {
        if (storyReader.current) {
            const players = storyReader.current.getAvailablePlayers();
            return players.map((player, index) => {
                if (player.avatar) {
                    return (
                        <AvatarContainer
                            key={"avatar" + index}
                            user={player}
                            persoWidth={40}
                            borderWidth={2}
                            borderColor={ColorManager.getInstance().getColor(
                                "secondary",
                            )}
                            avatarStyle={
                                index > 0 && index != 4
                                    ? { marginLeft: "-7px", zIndex: index + 1 }
                                    : { zIndex: index + 1 }
                            }
                        />
                    );
                } else {
                    return (
                        <Box
                            key={"letter" + index}
                            sx={[
                                styles.letter,
                                {
                                    backgroundColor: player.letterColor,
                                    borderColor:
                                        ColorManager.getInstance().getColor(
                                            "secondary",
                                        ),
                                },
                                index > 0 && index !== 4
                                    ? { marginLeft: "-7px", zIndex: index + 1 }
                                    : { zIndex: index + 1 },
                            ]}>
                            <Typography style={styles.playerLetter}>
                                {player.letter}
                            </Typography>
                        </Box>
                    );
                }
            });
        }
    };

    const drawMJActive = () => {
        if (storyReader.current) {
            const mj = storyReader.current.getMasterPlayerId();
            const player = storyReader.current.getPlayers().getPlayerById(mj);
            if (player.avatar) {
                return (
                    <AvatarContainer
                        user={player}
                        persoWidth={40}
                        borderWidth={2}
                        borderColor={ColorManager.getInstance().getColor(
                            "secondary",
                        )}
                    />
                );
            } else {
                return (
                    <Box
                        sx={[
                            styles.letter,
                            {
                                backgroundColor: player.letterColor,
                                borderColor:
                                    ColorManager.getInstance().getColor(
                                        "secondary",
                                    ),
                            },
                        ]}>
                        <Typography style={styles.playerLetter}>
                            {player.letter}
                        </Typography>
                    </Box>
                );
            }
        }
    };

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: getColor("tertiary"),
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: getColor("tertiary"),
            borderRadius: "8px",
            padding: "8px 12px",
        },
    }));

    const drawPlayersActiveList = () => {
        if (
            !secretSequence &&
            (screenType === ScreenType.TIMER ||
                screenType === ScreenType.STANDARD ||
                screenType === ScreenType.NORMAL_IMAGE ||
                screenType === ScreenType.IMAGE_TIMER ||
                screenType === ScreenType.POOLP)
        ) {
            if (showPlayersList) {
                return (
                    <CustomTooltip
                        title={
                            <Typography
                                style={{
                                    color: getColor("secondary"),
                                    fontFamily: "Gilroy-Bold",
                                }}>
                                {showPlayersList
                                    ? strings.playersConcerned
                                    : strings.playerConcerned}
                            </Typography>
                        }
                        arrow
                        placement="bottom"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 400 }}>
                        <Box
                            style={{
                                maxWidth: "320px",
                                position: "relative",
                            }}>
                            <Box
                                style={{
                                    maxWidth: "271px",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>
                                {drawPlayerActive()}
                            </Box>
                            <Box
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    width: "100%",
                                    maxWidth: "325px",
                                    height: "100%",
                                    maxHeight: "65px",
                                    zIndex: 10,
                                    backgroundColor:
                                        ColorManager.getInstance().getColor(
                                            "primary",
                                        ),
                                    opacity: 0.3,
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                );
            } else {
                return (
                    <CustomTooltip
                        title={
                            <Typography
                                style={{
                                    color: getColor("secondary"),
                                    fontFamily: "Gilroy-Bold",
                                }}>
                                {showPlayersList
                                    ? strings.playersConcerned
                                    : strings.playerConcerned}
                            </Typography>
                        }
                        arrow
                        placement="bottom"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 400 }}>
                        <Box
                            style={{
                                maxWidth: "320px",
                                position: "relative",
                            }}>
                            <Box
                                style={{
                                    maxWidth: "320px",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>
                                {drawMJActive()}
                            </Box>
                            <Box
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    width: "100%",
                                    maxWidth: "325px",
                                    height: "100%",
                                    maxHeight: "65px",
                                    zIndex: 10,
                                    backgroundColor:
                                        ColorManager.getInstance().getColor(
                                            "primary",
                                        ),
                                    opacity: 0.3,
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                );
            }
        }
    };

    const drawBackToGroupPopup = () => {
        if (storyReader.current && showPopUpBackToGroup) {
            return (
                <Fade in={showPopUpBackToGroup}>
                    <Box
                        sx={[
                            styles.popBg,
                            {
                                backgroundColor: getColor("tertiary"),
                                alignItems: "center",
                                zIndex: 300,
                            },
                        ]}>
                        <Typography
                            style={{
                                color: getColor("secondary"),
                                fontFamily: "Gilroy-Bold",
                            }}>
                            {strings.backToGroup}
                        </Typography>
                    </Box>
                </Fade>
            );
        }
    };

    const drawSecretIcon = () => {
        if (
            secretSequence &&
            !showPlayersList &&
            (screenType === ScreenType.TIMER ||
                screenType === ScreenType.STANDARD ||
                screenType === ScreenType.NORMAL_IMAGE ||
                screenType === ScreenType.IMAGE_TIMER ||
                screenType === ScreenType.POOLP)
        ) {
            return (
                <CustomTooltip
                    title={
                        <Typography
                            style={{
                                color: getColor("secondary"),
                                fontFamily: "Gilroy-Bold",
                            }}>
                            {strings.playerConcerned}
                        </Typography>
                    }
                    arrow
                    placement="bottom"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 400 }}>
                    <Box
                        style={{
                            maxWidth: "320px",
                            position: "relative",
                        }}>
                        <Box
                            style={{
                                maxWidth: "271px",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                columnGap: "10px",
                            }}>
                            {drawMJActive()}
                            <SecretIcon
                                width={38}
                                height={38}
                                fill={ColorManager.getInstance().getColor(
                                    "quaternary",
                                )}
                            />
                        </Box>
                        <Box
                            style={{
                                position: "absolute",
                                top: 0,
                                width: "100%",
                                maxWidth: "325px",
                                height: "100%",
                                maxHeight: "65px",
                                zIndex: 10,
                                backgroundColor:
                                    ColorManager.getInstance().getColor(
                                        "primary",
                                    ),
                                opacity: 0.3,
                            }}
                        />
                    </Box>
                </CustomTooltip>
            );
        }
    };

    /**
     * @_renderBoardDicePopup
     * Draws the dice popup
     */
    const _renderBoardDicePopup = () => {
        if (
            showBoardDicePopup &&
            currentChoiceForDicePopup &&
            randomForDicePopup &&
            isPositiveNumber(randomForDicePopup) &&
            !isBoard
        ) {
            if (!showPlayersList) {
                return (
                    <DicePopup
                        choice={currentChoiceForDicePopup}
                        random={randomForDicePopup}
                        callback={() => {
                            showBoardDicePopupCallback(false, null, null);
                        }}
                        onReboot={false}
                        isSoloPlayer={true}
                        storyReader={storyReader.current}
                    />
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    /* const drawBoardPopUp = () => {
    if (storyReader.current) {
      return (
        <BoardPopUp
          essentials={boardInfo}
          player={storyReader.current
            .getPlayers()
            .getPlayerById(storyReader.current.getActivePlayerId())}
          modalVisible={showBoardPopup}
          callback={updateBoardPopup}
          storyReader={storyReader.current}
        />
      );
    }
  };
  */

    const createImgs = () => {
        if (storyData) {
            const { iconStates } = storyData;
            if (iconStates) {
                const varsCopy = [];
                const { globalVars } = storyData;
                for (let i = 0; i < iconStates.length; i += 1) {
                    const globalVar = globalVars.find(
                        (entry: any) =>
                            entry.name === iconStates[i].name &&
                            entry.type === "var",
                    );

                    if (globalVar && storyReader.current) {
                        const findVar = storyReader.current
                            ?.getVariables()
                            .getVariableByName(globalVar.name);
                        if (findVar && findVar.value) {
                            const component = (
                                <Box style={{ ...columnStartStyles }} key={i}>
                                    <img
                                        key={i}
                                        src={getRouteManager().icon(
                                            iconStates[i].iconId,
                                        )}
                                        style={{
                                            width: 48,
                                            height: 48,
                                            /* tintColor:
                        ColorManager.getInstance().getColor("quaternary"), */
                                        }}
                                    />
                                </Box>
                            );

                            varsCopy.push({
                                name: globalVar.defaultValue,
                                varName: globalVar.name,
                                component: component,
                                value: findVar.value,
                            });
                        }
                    }
                }
                if (varsCopy && varsCopy.length > 0) {
                    return varsCopy;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    /**
     * Draw choices depending on choice type.
     * @param {Type} Description
     */
    const drawChoices = () => {
        if (storyReader.current) {
            return (
                <Choices
                    choices={choices}
                    players={storyReader.current.getAvailablePlayers()}
                    goNext={goNext}
                    choicesType={choiceType}
                    onSubmitTextbox={onSubmitTextbox}
                    storyId={storyId}
                    color={getColor("tertiary")}
                    storyReader={storyReader.current}
                    bgColor={getColor("primary")}
                    textColor={getColor("quaternary")}
                    choiceColor={getColor("secondary")}
                    visitedScenes={visitedScenes}
                    toggleBottom={toggleBottomFn}
                    showStatePopUp={showStatePopUp}
                    choiceTimerSelect={choiceTimerSelect}
                    timerCallback={clearChoiceTimerCallback}
                    toggleOverlay={toggleOverlay}
                    setAskExit={setAskExitCallback}
                    animUrl={getAnimForType("choice")}
                    setShowReport={setShowReportScreen}
                />
            );
        }
    };

    const _renderGameOverModalContent = () => {
        return (
            <Box style={styles.modalContent}>
                <Typography sx={styles.modalText}>
                    {strings.messages.gameOver}
                </Typography>
            </Box>
        );
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                    width: "100%%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Loading />
            </Box>
        );
    }

    return (
        <Box ref={containerRef} sx={[styles.container]}>
            <Fade
                in={isImageChanged}
                timeout={{ appear: 0, enter: 1000, exit: 400 }}>
                <Box
                    sx={[
                        { position: "absolute", width: "100%", height: "100%" },
                        {
                            backgroundColor: !showReportScreen
                                ? getColor("quinary")
                                : Colors.PRIMARY,
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), center / cover no-repeat url(${
                                background
                                    ? background
                                    : (storyId && storyId === "92") ||
                                      storyId === 92
                                    ? require("../../assets/images/dragons.jpeg")
                                    : (storyId && storyId === "41") ||
                                      storyId === 41
                                    ? require("../../assets/images/BG25.jpeg")
                                    : (storyId && storyId === "44") ||
                                      storyId === 44
                                    ? require("../../assets/images/BGViking.jpeg")
                                    : (storyId && storyId === "51") ||
                                      storyId === 51
                                    ? require("../../assets/images/BG5.jpg")
                                    : (storyId && storyId === "59") ||
                                      storyId === 59
                                    ? require("../../assets/images/BG27.jpeg")
                                    : (storyId && storyId === "67") ||
                                      storyId === 67
                                    ? require("../../assets/images/BG8.jpg")
                                    : (storyId && storyId === "72") ||
                                      storyId === 72
                                    ? require("../../assets/images/BG22.jpg")
                                    : (storyId && storyId === "83") ||
                                      storyId === 83
                                    ? require("../../assets/images/BG11.jpg")
                                    : (storyId && storyId === "87") ||
                                      storyId === 87
                                    ? require("../../assets/images/BGFantome.jpeg")
                                    : (storyId && storyId === "99") ||
                                      storyId === 99
                                    ? require("../../assets/images/BG15.jpg")
                                    : (storyId && storyId === "102") ||
                                      storyId === 102
                                    ? require("../../assets/images/BG26.jpeg")
                                    : (storyId && storyId === "56") ||
                                      storyId === 56
                                    ? require("../../assets/images/BGPirate.jpeg")
                                    : (storyId && storyId === "57") ||
                                      storyId === 57
                                    ? require("../../assets/images/BGPirate.jpeg")
                                    : (storyId && storyId === "66") ||
                                      storyId === 66
                                    ? require("../../assets/images/BGPirate.jpeg")
                                    : (storyId && storyId === "61") ||
                                      storyId === 61
                                    ? require("../../assets/images/BG6.jpg")
                                    : (storyId && storyId === "62") ||
                                      storyId === 62
                                    ? require("../../assets/images/BG6.jpg")
                                    : (storyId && storyId === "63") ||
                                      storyId === 63
                                    ? require("../../assets/images/BG6.jpg")
                                    : (storyId && storyId === "64") ||
                                      storyId === 64
                                    ? require("../../assets/images/BG6.jpg")
                                    : (storyId && storyId === "68") ||
                                      storyId === 68
                                    ? require("../../assets/images/BG7.jpg")
                                    : (storyId && storyId === "69") ||
                                      storyId === 69
                                    ? require("../../assets/images/BG7.jpg")
                                    : (storyId && storyId === "70") ||
                                      storyId === 70
                                    ? require("../../assets/images/BG7.jpg")
                                    : (storyId && storyId === "71") ||
                                      storyId === 71
                                    ? require("../../assets/images/BG7.jpg")
                                    : (storyId && storyId === "75") ||
                                      storyId === 75
                                    ? require("../../assets/images/BG21.jpg")
                                    : (storyId && storyId === "76") ||
                                      storyId === 76
                                    ? require("../../assets/images/BG21.jpg")
                                    : (storyId && storyId === "77") ||
                                      storyId === 77
                                    ? require("../../assets/images/BG21.jpg")
                                    : (storyId && storyId === "78") ||
                                      storyId === 78
                                    ? require("../../assets/images/BG10.jpg")
                                    : (storyId && storyId === "79") ||
                                      storyId === 79
                                    ? require("../../assets/images/BG10.jpg")
                                    : (storyId && storyId === "80") ||
                                      storyId === 80
                                    ? require("../../assets/images/BG10.jpg")
                                    : (storyId && storyId === "81") ||
                                      storyId === 81
                                    ? require("../../assets/images/BG10.jpg")
                                    : (storyId && storyId === "88") ||
                                      storyId === 88
                                    ? require("../../assets/images/BG12.jpg")
                                    : (storyId && storyId === "89") ||
                                      storyId === 89
                                    ? require("../../assets/images/BG12.jpg")
                                    : (storyId && storyId === "90") ||
                                      storyId === 90
                                    ? require("../../assets/images/BG12.jpg")
                                    : (storyId && storyId === "91") ||
                                      storyId === 91
                                    ? require("../../assets/images/BG12.jpg")
                                    : (storyId && storyId === "95") ||
                                      storyId === 95
                                    ? require("../../assets/images/BG13.jpg")
                                    : (storyId && storyId === "96") ||
                                      storyId === 96
                                    ? require("../../assets/images/BG13.jpg")
                                    : (storyId && storyId === "97") ||
                                      storyId === 97
                                    ? require("../../assets/images/BG13.jpg")
                                    : (storyId && storyId === "98") ||
                                      storyId === 98
                                    ? require("../../assets/images/BG13.jpg")
                                    : (storyId && storyId === "101") ||
                                      storyId === 101
                                    ? require("../../assets/images/BG101.jpeg")
                                    : (storyId && storyId === "103") ||
                                      storyId === 103
                                    ? require("../../assets/images/BG1.jpg")
                                    : (storyId && storyId === "104") ||
                                      storyId === 104
                                    ? require("../../assets/images/BG2.jpg")
                                    : (storyId && storyId === "105") ||
                                      storyId === 105
                                    ? require("../../assets/images/BG3.jpg")
                                    : (storyId && storyId === "106") ||
                                      storyId === 106
                                    ? require("../../assets/images/BG19.jpg")
                                    : (storyId && storyId === "107") ||
                                      storyId === 107
                                    ? require("../../assets/images/BG17.jpg")
                                    : cover
                                    ? cover
                                    : require("../../assets/images/BG7.jpeg")
                            })`,
                        },
                    ]}
                />
            </Fade>
            {/* {!showReportScreen ? (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <BackgroundIcons
            width={"100vw"}
            height={"100vh"}
            fill={ColorManager.getInstance().hexToRgba(
              getColor("quaternary"),
              0.99
            )}
          />
        </Box>
      ) : null} */}
            {!showReportScreen ? (
                <ButtonBase
                    onClick={() => openTopMenu()}
                    style={{
                        alignItems: "center",
                        width: "48px",
                        position: "absolute",
                        top: "30px",
                        left: "35px",
                        borderRadius: "48px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        zIndex: 300,
                    }}>
                    <OptionsBtn
                        width={48}
                        height={48}
                        fill={getColor("secondary")}
                    />
                </ButtonBase>
            ) : null}

            {storyReader.current && !showReportScreen ? (
                <Slide
                    direction="right"
                    in={topMenuIsOpen}
                    container={containerRef.current}
                    timeout={400}>
                    <Box
                        sx={[
                            {
                                backgroundColor:
                                    ColorManager.getInstance().getColor(
                                        "primary",
                                    ),
                            },
                            styles.topMenuContainer,
                        ]}>
                        <TopMenu
                            onClose={closeTopMenu}
                            players={storyReader.current.getPlayers().players}
                            soundManager={SoundManager.getInstance()}
                            navigate={navigate}
                            currentObjective={storyReader.current.getCurrentObjective()}
                            switchValueFirst={switchValueFirst}
                            switchValueSecond={switchValueSecond}
                            toggleSound={toggleSound}
                            toggleMusic={toggleMusic}
                            colorBg={getColor("primary")}
                            textColor={getColor("quaternary")}
                            accentColor={getColor("tertiary")}
                            secondaryColor={getColor("secondary")}
                            storyId={storyId}
                            setShowPopupExit={setShowPopupExit}
                            storyReader={storyReader.current}
                            storyData={storyData}
                            informations={storyReader.current.getInformations()}
                            varToShow={createImgs()}
                            jsonVan={jsonVan}
                            isSolo={solo}
                            isFTUE={isFTUE}
                        />
                    </Box>
                </Slide>
            ) : null}
            {storyReader.current && !showReportScreen ? (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        ...columnCenterStyles,
                        zIndex: 900,
                    }}>
                    {notificationList && drawNotificationList()}
                </Box>
            ) : null}
            {storyReader.current && !showReportScreen ? (
                <Box
                    sx={[
                        styles.gameContainer,
                        {
                            backgroundColor:
                                ColorManager.getInstance().getColor("primary"),
                            /* border: `solid 5px ${ColorManager.getInstance().hexToRgba(
                ColorManager.getInstance().getColor("quaternary"),
                "0.3"
              )}`,
              borderBottomWidth: "0px",
              borderTopWidth: "0px", */
                        },
                        screenType === ScreenType.FULLSCREEN_IMAGE
                            ? {
                                  width: "47vh",
                                  maxWidth: "650px",
                                  minWidth: "250px",
                              }
                            : {},
                    ]}>
                    {showBoardDicePopup && !showPlayersList ? (
                        <>{_renderBoardDicePopup()}</>
                    ) : (
                        <>
                            <Box sx={styles.playersList}>
                                {drawPlayersActiveList()}
                            </Box>
                            <Box sx={styles.playersList}>
                                {drawSecretIcon()}
                            </Box>
                            <Box sx={styles.playersList}>
                                {drawBackToGroupPopup()}
                            </Box>
                            {/* <Box sx={{ width: "100%", ...columnCenterStyles, zIndex: 900 }}>
            {notificationList && drawNotificationList()}
          </Box> */}

                            {screenType === ScreenType.STANDARD && (
                                <StandardScreen
                                    text={text}
                                    drawChoices={drawChoices}
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    color={getColor("tertiary")}
                                    hlText={hlText}
                                    textColor={getColor("quaternary")}
                                    overlay={toggleBottom}
                                    showOverlay={showOverlay}
                                    storyReader={storyReader.current}
                                    choices={choices}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                />
                            )}
                            {screenType === ScreenType.NORMAL_IMAGE && (
                                <ImageScreen
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    text={text}
                                    drawChoices={drawChoices}
                                    imageId={storyReader.current.GetImageId()}
                                    color={getColor("tertiary")}
                                    hlText={hlText}
                                    storyId={storyId}
                                    textColor={getColor("quaternary")}
                                    passageNumber={passageNumber}
                                    currPassageId={currPassageId}
                                    overlay={toggleBottom}
                                    storyReader={storyReader.current}
                                    showOverlay={showOverlay}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                />
                            )}
                            {screenType === ScreenType.POOLP && (
                                <PoolpScreen
                                    text={text}
                                    onSkipImage={onSkipImage}
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    storyId={storyId}
                                    color={getColor("tertiary")}
                                    hlText={hlText}
                                    textColor={getColor("quaternary")}
                                    secondaryColor={getColor("secondary")}
                                    showStatePopUp={showStatePopUp}
                                    overlay={toggleBottom}
                                    storyReader={storyReader.current}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                    animUrl={getAnimForType("background")}
                                />
                            )}
                            {screenType === ScreenType.FULLSCREEN_IMAGE && (
                                <FullScreenImageScreen
                                    onSkipImage={onSkipImage}
                                    imageId={storyReader.current.GetImageId()}
                                    choices={choices}
                                    passageNumber={passageNumber}
                                    storyId={storyId}
                                    storyReader={storyReader.current}
                                    currPassageId={currPassageId}
                                    secondaryColor={getColor("secondary")}
                                    textColor={getColor("quaternary")}
                                    showStatePopUp={showStatePopUp}
                                    text={text}
                                    setShowReport={setShowReportScreen}
                                />
                            )}
                            {screenType === ScreenType.CHANGE && (
                                <ChangeScreen
                                    text={text}
                                    onSkipImage={onSkipImage}
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    storyId={storyId}
                                    color={getColor("tertiary")}
                                    hlText={hlText}
                                    textColor={getColor("quaternary")}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                    showStatePopUp={showStatePopUp}
                                    overlay={toggleBottom}
                                    storyReader={storyReader.current}
                                />
                            )}
                            {screenType === ScreenType.SECRET && (
                                <SecretScreen
                                    text={text}
                                    onSkipImage={onSkipImage}
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    storyId={storyId}
                                    color={getColor("tertiary")}
                                    hlText={hlText}
                                    secondaryColor={getColor("secondary")}
                                    textColor={getColor("quaternary")}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                    showStatePopUp={showStatePopUp}
                                    overlay={toggleBottom}
                                    storyReader={storyReader.current}
                                />
                            )}
                            {screenType === ScreenType.TIMER && (
                                <TimerScreen
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    countdown={countdown}
                                    timerRef={timerRef}
                                    timerTextRef={timerTextRef}
                                    text={text}
                                    drawChoices={drawChoices}
                                    color={getColor("tertiary")}
                                    textColor={getColor("quaternary")}
                                    hlText={hlText}
                                    overlay={toggleBottom}
                                    storyReader={storyReader.current}
                                    choicesLength={choices.length}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                    max={storyReader.current.getTimerValue()}
                                />
                            )}
                            {screenType === ScreenType.IMAGE_TIMER && (
                                <TimerImageScreen
                                    players={
                                        storyReader.current.getPlayers().players
                                    }
                                    countdown={countdown}
                                    timerRef={timerRef}
                                    timerTextRef={timerTextRef}
                                    text={text}
                                    drawChoices={drawChoices}
                                    color={getColor("tertiary")}
                                    textColor={getColor("quaternary")}
                                    hlText={hlText}
                                    imageId={storyReader.current.GetImageId()}
                                    storyId={storyId}
                                    passageNumber={passageNumber}
                                    currPassageId={currPassageId}
                                    overlay={toggleBottom}
                                    storyReader={storyReader.current}
                                    masterPlayer={storyReader.current.getMasterPlayerId()}
                                    max={storyReader.current.getTimerValue()}
                                />
                            )}
                        </>
                    )}
                </Box>
            ) : null}
            {!isVisibleVolumeModal ? (
                <ClassicModal
                    title={strings.labels.gameOver}
                    modalVisible={showPopUpGameOver}
                    twoButtons={false}
                    buttonText={strings.actions.leave}
                    onCloseModal={() => leaveGame()}
                    minHeight={SCREEN_HEIGHT / 5}
                    titleHeight={SCREEN_HEIGHT / 26}
                    content={_renderGameOverModalContent()}
                />
            ) : (
                <GameVolumeModal
                    onClose={() => setIsVisibleVolumeModal(false)}
                    visible={isVisibleVolumeModal}
                />
            )}

            {showReportScreen && storyReader.current && storyData ? (
                <ReportScreen
                    storyData={storyData}
                    storyReader={storyReader.current}
                />
            ) : null}
        </Box>
    );
};

const styles = {
    container: {
        flex: 1,
        zIndex: 1,
        ...rowCenterStyles,
        position: "relative",
        maxWidth: "100vw",
        overflow: "hidden",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    },
    topMenuContainer: {
        position: "absolute",
        left: 0,
        width: "27.77vw",
        minWidth: "400px",
        maxWidth: "500px",
        height: "calc(100% - 40px)",
        margin: "20px 0px",
        zIndex: 900,
        borderLeftWidth: "0px",
        borderRadius: "0px 20px 20px 0px",
        boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.25)",
        overflow: "hidden",
    },
    gameContainer: {
        display: "flex",
        zIndex: 1,
        flexDirection: "column",
        width: "60vh",
        maxWidth: "670px",
        minWidth: "500px",
        //height: "100vh",
        height: "fit-content",
        maxHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        overflowX: "hidden",
        overflowY: "auto",
        position: "relative",
        padding: "24px 24px 24px",
        boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.35)",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        /* backdropFilter: "blur( 20px )",
    "-webkit-backdrop-filter": "blur( 10px )", */
        borderRadius: "20px",
    },
    playersList: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        zIndex: 300,
    },
    popBg: {
        position: "absolute",
        alignSelf: "center",
        top: "24px",
        padding: "8px 12px",
        borderRadius: "8px",
    },
    letter: {
        width: "40px",
        height: "40px",
        borderRadius: "40px",
        borderWidth: "2px",
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    playerLetter: {
        fontWeight: "bold",
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
        color: Colors.WHITE,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalViewBuyEpisode: {
        width: "400px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        padding: "35px 25px 25px 25px",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        zIndex: 450,
    },
    separatorBuyEpisode: {
        width: "400px",
        height: 0,
        color: Colors.BTN_ACTIVE,
        borderColor: Colors.BTN_ACTIVE,
        borderWidth: "1px",
        marginTop: "25px",
    },
    popupButtons: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        marginTop: "25px",
    },
    yeahButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        width: "120px",
        height: "40px",
        textAlign: "center",
        zIndex: 500,
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.ACCENT,
        textAlign: "center",
    },
    overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: Colors.BLACK,
        opacity: 0.9,
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 0,
        zIndex: 5,
    },
    overlayTxt: {
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
        color: Colors.WHITE,
    },
    overlayTxtTitle: {
        position: "absolute",
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
        color: Colors.WHITE,
        //top: SCREEN_HEIGHT > 750 ? bannerHeight : bannerHeight - 50,
    },
    noButton: {
        padding: "7px 11px",
        borderRadius: "10px",
        width: "100px",
    },
    noText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    modalContent: {
        ...columnStyles,
        alignItems: "center",
        width: "100%",
    },
    modalText: {
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
        marginTop: "10px",
    },
    notificationList: {
        position: "absolute",
        top: "30px",
        alignSelf: "center",
        zIndex: 10,
        width: "30%",
        minWidth: "250px",
        maxWidth: "390px",
    },
};

export default Game;
