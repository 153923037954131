/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import useAuth from "../../services/Auth/useAuth";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import moment from "moment";
import { Save } from "../../models/save";
import BackgroundRow from "../../components/BackgroundRow";
import { Box, ButtonBase, Typography } from "@mui/material";
import { columnStartStyles } from "../../style/flex";
import { ReactComponent as NextIcon } from "../../assets/icons/icon_next_image.svg";
import { useNavigate } from "react-router";
import { Colors } from "../../style";
import "./style.css";
import SaveBtn from "../../components/SaveBtn";
import { normalText } from "../../style/fonts";
import normalize from "../../utils/fontSizer";
import SoundManager from "../../services/SoundManager";
import { onCreateGame } from "../../services/GameOnlineManager";
import { getEventManager } from "../../services/EventManager";

interface Props {
    storyId: number;
    episode: any;
    callback: any;
}

const LoadSaveScreen = (props: Props) => {
    const { storyId, episode, callback } = props;
    const auth = useAuth();
    const navigate = useNavigate();

    const [parent, setParent] = useState();
    const [saves, updateSaves] = useState<Array<Save>>();
    const [loading, setLoading] = useState(true);
    const [shouldGo, setShouldGo] = useState(false);
    const [selectedSave, setSelectedSave] = useState(0);

    const abortController = new AbortController();
    const cancelSignal = abortController.signal;

    useEffect(() => {
        if (episode.number !== 1) {
            getParentAndSaves();
        }
        return () => {
            abortController.abort();
        };
    }, []);

    const getSaves = async (parentId: number) => {
        if (auth && auth.user && parentId) {
            const api = getRouteManager().getAllSavesForUserForStory();
            const getSaves = await getRouteManager().fetchData(
                api,
                {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                    storyId: parentId,
                },
                cancelSignal,
            );
            if (getSaves.flashmessage === "error") {
                setLoading(false);
            } else if (getSaves && getSaves.length) {
                getSaves.map((s: Save, index: number) => {
                    s.key = index + 1;
                    s.image_backdrop =
                        episode && episode.series && episode.series.idCover
                            ? `${getRouteManager().getCover(
                                  episode.series.idCover,
                              )}`
                            : s.storyId
                            ? getRouteManager().cover(s.storyId)
                            : null;
                    s.image_title = s.storyId
                        ? getRouteManager().titleForStory(
                              getLanguage(),
                              s.storyId,
                          )
                        : null;
                    s.date =
                        getLanguage() === "fr"
                            ? moment(s.created).format("DD/MM/YYYY HH:mm")
                            : moment(s.created).format("MM/DD/YYYY hh:mm");
                });
                updateSaves(getSaves);
            }
        }
    };

    const getParentAndSaves = async () => {
        const route = getRouteManager().getParentEpisodeForStory(
            episode.story.id,
        );
        const parentData = await getRouteManager().getData(route, cancelSignal);
        if (parentData) {
            setParent(parentData);
            await getSaves(parentData.id);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedSave !== 0) {
            setShouldGo(true);
            //fadeInImage();
            SoundManager.getInstance().stopTheme();
            SoundManager.getInstance().playStoryStartSound();
        }
    }, [selectedSave]);

    const goToGame = async () => {
        //const colorsObject = JSON.parse(colors);
        /*  const solo = type === "solo";
     const isBoard = type === "board"; */
        const api = getRouteManager().getSave(selectedSave);
        const save = await getRouteManager().getData(api, cancelSignal);
        if (save && save.flashmessage === "error") {
            alert("error while retrieving save");
        } else if (
            save &&
            (!save.flashmessage ||
                (save.flashmessag && save.flashmessag !== "error"))
        ) {
            const session = {
                startDate: new Date(),
                userId: getRouteManager().getUser().GetId(),
                storyId: storyId,
                type:
                    save.type && save.type === "solo"
                        ? "solo"
                        : save.type === "board"
                        ? "board"
                        : save.type === "online"
                        ? "online"
                        : "normal",
                lang: getLanguage(),
                nbPlayers:
                    save.type && save.type === "solo" ? 1 : save.players.length,
            };
            if (save.type && save.type === "solo") {
                getEventManager().logLaunchStorySolo(storyId);
            } else if (save.type === "board") {
                getEventManager().logLaunchStoryBoard(storyId);
            }

            const res = await getRouteManager().fetchStartSession(
                session,
                auth.user,
            );

            if (save.type === "online") {
                const api = getRouteManager().colorsForStory(storyId);
                const story = episode && episode.story ? episode.story : null;
                if (story) {
                    const colors = await getRouteManager().getData(api);

                    await onCreateGame(
                        storyId,
                        colors,
                        story,
                        true,
                        story.nbPlayerMin,
                        story.nbPlayerMax,
                        story.title,
                        story.updated,
                        navigate,
                        auth.user,
                        episode,
                        save,
                    );
                } else {
                    alert("Error occured during loading save");
                }
            } else {
                navigate("/game", {
                    state: {
                        storyId: storyId,
                        title: episode.story.title ? episode.story.title : null,
                        save: save,
                        episode: episode,
                        solo: save.type === "solo",
                        isFTUE: false,
                        isBoard: save.type === "board",
                    },
                });
            }

            SoundManager.getInstance().stopTheme();
        }

        setShouldGo(false);
    };

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <BackgroundRow
                loading={loading}
                title={strings.saves}
                hasBackground={false}
                goBack
                goBackAction={() => callback()}
                style={{ width: "100%" }}
                subtitle={strings.selectSaveToLoad}
                rightPart={
                    <Box sx={styles.container}>
                        {saves && saves.length ? (
                            <Box sx={styles.containerGap}>
                                {saves.map((save: Save, index: number) => (
                                    <SaveBtn
                                        save={save}
                                        isLast={index === saves.length - 1}
                                        key={"save-" + save.type + "-" + index}
                                        onClick={() => setSelectedSave(save.id)}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Typography sx={styles.noSaves}>
                                {strings.noSaveToLoad}
                            </Typography>
                        )}
                    </Box>
                }
            />
            {!loading && shouldGo ? (
                <Box sx={[styles.showNext]} className={"fade-in-image"}>
                    <img
                        src={
                            episode && episode.series
                                ? `${getRouteManager().getCover(
                                      episode.series.idCover,
                                  )}`
                                : getRouteManager().cover(storyId)
                        }
                        style={{ position: "absolute", height: "100vh" }}
                    />
                    <img
                        src={getRouteManager().titleForStory(
                            getLanguage(),
                            storyId,
                        )}
                        style={{
                            zIndex: 50,
                            width: "46vh",
                            position: "absolute",
                            bottom: "70px",
                        }}
                    />
                    <Box
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            zIndex: 50,
                            alignSelf: "center",
                        }}>
                        <ButtonBase
                            sx={[{ borderRadius: "50px" }]}
                            onClick={() => {
                                goToGame();
                            }}>
                            <NextIcon width={50} height={50} fill="black" />
                        </ButtonBase>
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

const styles = {
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
    },
    showNext: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.PRIMARY,
        zIndex: 999,
    },
    containerGap: {
        ...columnStartStyles,
        alignItems: "flex-start",
        rowGap: "15px",
    },
    noSaves: {
        ...normalText,
        color: Colors.WHITE,
        fontSize: normalize(18),
    },
};

export default LoadSaveScreen;
