/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import ColorManager from "../../../../services/colorManager";
import { ReactComponent as MusicIcon } from "../../../../assets/icons/icon_music.svg";
import { ReactComponent as OptionsIcon } from "../../../../assets/icons/icon_settings_big.svg";
import { ReactComponent as SoundIcon } from "../../../../assets/icons/icon_sound.svg";
//import SoundManager from '../../../../services/SoundManager';
import styles from "./styles";
import SwitchOptionRow from "./switchOptionRow";
import { strings } from "../../../../services/translation";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { VolumeDown, VolumeUp } from "@mui/icons-material";
import { Gilroy } from "../../../../style/fonts";
import SoundManager from "../../../../services/SoundManager";

// =======================================================

interface Props {
  //soundManager: SoundManager;
  switchCallback: any;
  navigate: any;
  toggleMusic: any;
  switchValueFirst: boolean;
  switchValueSecond: boolean;
  color: any;
  textColor: any;
  backColor: any;
  btnColor: any;
  isOnlineMode: boolean;
  onLogoutOnlineGame?: any;
  disabledLogout: boolean;
  setShowPopupExit?: any;
}

// =======================================================

const OptionsScreen = (props: Props) => {
  /**
   *
   */
  const {
    switchCallback,
    toggleMusic,
    switchValueFirst,
    switchValueSecond,
    color,
    textColor,
  } = props;

  const [value, setValue] = React.useState<number>(
    SoundManager.getInstance().getMaxVolume() * 100,
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    const val = newValue as number;
    setValue(val);
    localStorage.setItem("volume", JSON.stringify(val / 100));
    SoundManager.getInstance().setMaxVolume(val / 100);
  };

  // --

  return (
    <Box style={styles.container}>
      <Box sx={styles.header}>
        <OptionsIcon width={25} height={25} fill={color} />
        <Typography sx={[styles.quitText, { color: color }]}>
          {strings.options}
        </Typography>
      </Box>
      <Box
        sx={[
          styles.separator,
          {
            backgroundColor: ColorManager.getInstance().getColor("quinary"),
          },
        ]}
      />

      <Box style={styles.topOptions}>
        <SwitchOptionRow
          text={strings.sounds}
          icon={<SoundIcon width={50} height={50} fill={textColor} />}
          switchCallback={switchCallback}
          switchValue={switchValueFirst}
          color={color}
          textColor={textColor}
        />
        <SwitchOptionRow
          text={strings.music}
          icon={<MusicIcon width={50} height={50} fill={textColor} />}
          switchCallback={toggleMusic}
          switchValue={switchValueSecond}
          color={color}
          textColor={textColor}
        />
        <Box sx={styles.sliderBox}>
          <Typography sx={[styles.text, { color: textColor }]}>
            {strings.volume}
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <VolumeDown sx={{ color: textColor }} />

            <Slider
              valueLabelDisplay="auto"
              aria-label="Volume"
              value={value}
              onChange={handleChange}
              sx={{
                color: color,
                height: 8,
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  height: 20,
                  width: 20,
                  backgroundColor: "#f4f3f4",
                  /* border: "2px solid currentColor", */
                  "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                    boxShadow: "inherit",
                  },
                  "&:before": {
                    display: "none",
                  },
                },
                "& .MuiSlider-valueLabel": {
                  lineHeight: 1.2,
                  fontSize: 12,
                  fontFamily: Gilroy,
                  background: "unset",
                  padding: 0,
                  width: 32,
                  height: 32,
                  borderRadius: "50% 50% 50% 0",
                  backgroundColor: color,
                  transformOrigin: "bottom left",
                  transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
                  "&:before": { display: "none" },
                  "&.MuiSlider-valueLabelOpen": {
                    transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
                  },
                  "& > *": {
                    transform: "rotate(45deg)",
                  },
                },
              }}
            />
            <VolumeUp sx={{ color: textColor }} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

// =======================================================

export default OptionsScreen;
