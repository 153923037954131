/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from "react";
import StoryReader from "../../../services/StoryReader";
import { strings } from "../../../services/translation";
import ColorManager from "../../../services/colorManager";
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import AvatarContainer from "../../../components/AvatarContainer";
import { Box, Fade, Typography } from "@mui/material";
import TextScene from "../../../components/TextScene";
import { columnStartStyles, rowStyles } from "../../../style/flex";

// =======================================================

interface Props {
  text: string;
  drawChoices: any;
  players: any;
  color: any;
  hlText: string;
  textColor: any;
  overlay: boolean;
  storyReader: StoryReader;
  choices: any;
  showOverlay?: boolean;
  isVisibleText?: boolean;
  masterPlayer?: any;
}

// =======================================================

// =======================================================

const StandardScreen = (props: Props) => {
  /**
   *
   */
  const {
    text,
    drawChoices,
    players,
    color,
    hlText,
    textColor,
    overlay,
    storyReader,
    showOverlay,
    isVisibleText = true,
    masterPlayer = null,
  } = props;

  /**
   *
   */
  const drawPlayer = (players: any, master: number | any) => {
    const player = players[master];

    if (player.avatar) {
      return (
        <AvatarContainer
          user={player}
          persoWidth={98}
          borderWidth={5}
          borderColor={ColorManager.getInstance().getColor("secondary")}
        />
      );
    } else {
      return (
        <Box
          sx={[
            styles.letter,
            {
              backgroundColor: player.letterColor,
              borderColor: ColorManager.getInstance().getColor("secondary"),
            },
          ]}
        >
          <Typography style={styles.playerLetter}>{player.letter}</Typography>
        </Box>
      );
    }
  };

  // --
  return (
    <Fade in={true} appear timeout={800}>
      <Box sx={[styles.container, { zIndex: 6 }]}>
        {!showOverlay && isVisibleText ? (
          <TextScene
            players={players}
            color={color}
            hlText={hlText}
            textColor={textColor}
            overlay={overlay}
            storyReader={storyReader}
            shouldResize={false}
            marginTop={false}
          >
            {text}
          </TextScene>
        ) : !isVisibleText ? (
          <>
            <Box style={styles.changeContainer}>
              {drawPlayer(players, masterPlayer)}

              <Typography sx={[styles.changing, { color: textColor }]}>
                {strings.secret}
              </Typography>
            </Box>
            <TextScene
              players={players}
              color={color}
              hlText={hlText}
              textColor={textColor}
              overlay={overlay}
              storyReader={storyReader}
              shouldResize={false}
              marginTop={false}
            >
              {strings.secretTextHide}
            </TextScene>
          </>
        ) : null}

        <Box
          style={{ zIndex: 10, flex: 1, ...rowStyles, alignItems: "flex-end" }}
        >
          {drawChoices()}
        </Box>
      </Box>
    </Fade>
  );
};

// =======================================================

const centerStyles: any = {
  display: "flex",
  flexDirection: "comlumn",
  alignItems: "center",
  justifyContent: "center",
};

const styles = {
  container: {
    flex: 1,
    ...columnStartStyles,
    paddingTop: "32px",
    gap: "32px",
    width: "100%",
  },
  changeContainer: {
    ...centerStyles,
    marginBottom: "10px",
  },
  changing: {
    color: Colors.WHITE,
    fontSize: normalize(24),
    fontFamily: "Eczar-Regular",
    marginTop: "5px",
  },
  letter: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    borderWidth: "2px",
    ...centerStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
};

export default StandardScreen;
