/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    ButtonBase,
    Input,
    InputAdornment,
    Modal,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useTogglePasswordVisibility } from "../hook";
import { getRouteManager } from "../../../../services/routeManager";
import { getLanguage, strings } from "../../../../services/translation";
import LoadingView from "../../../../components/LoadingView";
import GoBackBtn from "../../../../components/GoBackBtn";
import { Formik } from "formik";
import * as yup from "yup";
import CryptoJS from "crypto-js";
import GradientBtn from "../../../../components/GradientBtn";
import { columnCenterStyles, rowCenterStyles } from "../../../../style/flex";
import { Colors } from "../../../../style";
import normalize, { normalizeSize } from "../../../../utils/fontSizer";
import { ReactComponent as SecretIcon } from "../../../../assets/icons/icon_secret.svg";
import { ReactComponent as IconEmail } from "../../../../assets/icons/icon_email.svg";
import { ReactComponent as IconPassword } from "../../../../assets/icons/icon_pwd.svg";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import { ReactComponent as IconPseudo } from "../../../../assets/icons/icon_pseudo.svg";
import { ReactComponent as CompleteIcon } from "../../../../assets/icons/icon_complete.svg";
import CustomCheckboxRegister from "../../../../components/CheckboxRegister";
import SoundManager from "../../../../services/SoundManager";
import { styles } from "../style";
import { getEventManager } from "../../../../services/EventManager";
import useAuth from "../../../../services/Auth/useAuth";
import { Player } from "@lottiefiles/react-lottie-player";
import { successAnim } from "../../../../constants/success";
import { isNotEmptyString } from "../../../../utils/StringHelper";

interface Props {
    callback: any;
    isTemporaryModal?: boolean;
    finishCallback?: any;
}

const RegisterBox = (props: Props) => {
    const { callback, isTemporaryModal = false, finishCallback = null } = props;
    const auth = useAuth();

    const { passwordVisibility, rightIcon, handlePasswordVisibility } =
        useTogglePasswordVisibility();

    const matchesWidth = useMediaQuery("(min-width:800px)");
    const matchesHeight = useMediaQuery("(min-height:800px)");

    /* **** STATES **** */

    const [email, setEmail] = useState();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [pseudo, setPseudo] = useState<string>(
        auth?.user?.pseudo ? auth.user.pseudo : "",
    );
    const [referral, setReferral] = useState("");
    const [showCgv, setShowCgv] = useState(false);
    const [showConfirmed, setShowConfirmed] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showContinue, setShowContinue] = useState(true);
    const [showContinueEmail, setShowContinueEmail] = useState(false);
    const [showContinuePassword, setShowContinuePassword] = useState(false);
    const [showContinueReferral, setShowContinueReferral] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPseudo, setShowPseudo] = useState(true);
    const [showReferral, setShowReferral] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    //
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);

    /* **** FUNCTIONS **** */

    const testRegister = async (values: any) => {
        try {
            setLoading(true);
            setEmail(values.email);
            setPseudo(values.pseudo);
            const passHash = CryptoJS.SHA512(values.password);
            if (
                isTemporaryModal &&
                auth &&
                auth?.user &&
                auth?.user?.login_token &&
                finishCallback
            ) {
                const api = getRouteManager().finalizeRegisterTemporary();
                const res = await getRouteManager().fetchData(`${api}`, {
                    user: {
                        email: values.email,
                        password: passHash.toString(),
                        pseudo: values.pseudo,
                        newsletter: values.newsletter,
                        login_token: auth.user.login_token,
                        referralId: isNotEmptyString(values.referral)
                            ? values.referral
                            : null,
                    },
                });
                if (res && res.flashmessage && res.flashmessage !== "success") {
                    setError(res.errInfo ? res.errInfo : res.flashmessage);
                    setShowSubmit(false);
                    setShowCgv(false);
                    setShowPassword(false);
                    setShowReferral(false);
                    setShowContinuePassword(false);
                    setShowContinueReferral(false);
                    setShowEmail(false);
                    setShowContinueEmail(false);
                    setShowContinue(true);
                    setShowPseudo(true);
                    setLoading(false);
                } else {
                    if (res && res.ftueDone) {
                        res.flashmessage = "OK_FTUE_DONE";
                        auth.signin(res, () => {
                            getEventManager().logLogin();
                            finishCallback ? finishCallback() : callback();
                        });
                    } else if (res && !res.ftueDone) {
                        res.flashmessage = "OK_FTUE_NOT_DONE";
                        auth.signin(res, () => {
                            setLoading(false);
                            getEventManager().logSignUp();
                            finishCallback();
                        });
                        return res;
                    }
                }
            } else {
                const api = getRouteManager().registerV2();
                const res = await getRouteManager().fetchData(`${api}`, {
                    user: {
                        email: values.email,
                        password: passHash.toString(),
                        pseudo: values.pseudo,
                        lang: values.lang,
                        newsletter: values.newsletter,
                        referralId: isNotEmptyString(values.referral)
                            ? values.referral
                            : null,
                    },
                });

                if (res.flashmessage && res.flashmessage !== "success") {
                    setError(res.errInfo ? res.errInfo : res.flashmessage);
                    setShowSubmit(false);
                    setShowCgv(false);
                    setShowPassword(false);
                    setShowReferral(false);
                    setShowContinuePassword(false);
                    setShowContinueReferral(false);
                    setShowEmail(false);
                    setShowContinueEmail(false);
                    setShowContinue(true);
                    setShowPseudo(true);
                    setLoading(false);
                } else {
                    setShowCgv(false);
                    setShowSubmit(false);
                    setShowConfirmed(true);
                    setLoading(false);
                    getEventManager().logSignUp();
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleError = (error: string, type: string) => {
        if (error.endsWith("is a required field") && type === "email") {
            return strings.emailRequired;
        }
        if (error.endsWith("must be a valid email") && type === "email") {
            return strings.emailInvalid;
        }
        if (error.endsWith("is a required field") && type === "password") {
            return strings.passwordRequired;
        }
        if (error.endsWith("must be a valid password") && type === "password") {
            return strings.passwordInvalid;
        }
        if (error.endsWith("is a required field") && type === "pseudo") {
            return strings.pseudoRequired;
        }
        if (error.endsWith("must be a valid pseudo") && type === "pseudo") {
            return strings.pseudoInvalid;
        }
        if (error.includes("must be") && type === "referral") {
            return strings.code6Characters;
        } else if (type === "referral") {
            return error;
        }
        return error;
    };

    const onSubmitReferralCode = async (code: string) => {
        if (code && isNotEmptyString(code)) {
            const api = getRouteManager().getUserFromCode(code);
            const testCodeExist = await getRouteManager().getData(api);
            if (testCodeExist) {
                if (testCodeExist?.flashmessage === "error") {
                    return false;
                }
                return true;
            }
        }
        return false;
    };

    const goBackAction = () => {
        if (showPseudo) {
            callback(false);
        } else if (showEmail) {
            setShowEmail(false);
            setShowContinueEmail(false);
            setShowContinue(true);
            setShowPseudo(true);
        } else if (showPassword) {
            setShowPassword(false);
            setShowContinuePassword(false);
            setShowEmail(true);
            setShowContinueEmail(true);
        } else if (showReferral) {
            setShowReferral(false);
            setShowContinueReferral(false);
            setShowPassword(true);
            setShowContinuePassword(true);
        } else if (showCgv) {
            setShowSubmit(false);
            setShowCgv(false);
            setShowReferral(true);
            setShowContinueReferral(true);
        }
    };

    if (loading) {
        return <LoadingView color={Colors.ACCENT} />;
    }

    return (
        <Box
            sx={[
                styles.loginContainer,
                matchesWidth && !isTemporaryModal
                    ? { width: "50%" }
                    : { width: "100%" },
                isTemporaryModal ? { borderRadius: normalizeSize(20) } : {},
            ]}>
            <GoBackBtn
                callback={() => {
                    goBackAction();
                }}
                style={{}}
                extraStyle={{
                    borderRadius: "50px",
                    position: "absolute",
                    top: 37,
                    left: 37,
                }}
            />

            <Box
                sx={[
                    matchesHeight
                        ? showConfirmed
                            ? { width: "70px", height: "70px" }
                            : { width: "50px", height: "50px" }
                        : { display: "none" },
                ]}>
                {showConfirmed ? (
                    <Player
                        src={successAnim}
                        autoplay
                        style={{ width: "70px", height: "70px" }}
                        keepLastFrame
                    />
                ) : (
                    <IconTalesup width={50} height={50} fill={Colors.WHITE} />
                )}
            </Box>

            <Formik
                initialValues={{
                    email: email ? email : "",
                    password: "",
                    pseudo: pseudo ? pseudo : "",
                    lang: getLanguage(),
                    terms: false,
                    newsletter: false,
                    privacy: false,
                    referral: referral ? referral : "",
                }}
                onSubmit={(values) => testRegister(values)}
                validationSchema={yup.object().shape({
                    email: yup.string().email().required(),
                    pseudo: yup.string().max(20).required(),
                    terms: yup
                        .bool()
                        .oneOf([true], "Accept Terms & Conditions is required"),
                    privacy: yup
                        .bool()
                        .oneOf([true], "Accept privacy policy is required"),
                    password: yup
                        .string()
                        .required()
                        .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&,;:.+_\-=€#])[A-Za-z\d@$!%*?&,;:.+_\-=€#]{8,}$/,
                            strings.passwordStrength,
                        ),
                    lang: yup.string().required(),
                    referral: yup.string().min(6).max(6),
                })}>
                {({
                    values,
                    handleChange,
                    errors,
                    setFieldTouched,
                    touched,
                    setFieldValue,
                    isValid,
                    handleSubmit,
                }) => (
                    <Box style={styles.container}>
                        {!showConfirmed ? (
                            <Box style={styles.container}>
                                <Typography sx={styles.title2}>
                                    {strings.register}
                                </Typography>
                                <Typography
                                    sx={[
                                        styles.subtitle,
                                        !matchesHeight && {
                                            padding: "0px 10px",
                                            marginBottom: "20px",
                                        },
                                    ]}>
                                    {strings.registerSubtitle}
                                </Typography>

                                {showPseudo ? (
                                    <Box sx={styles.inputContainer}>
                                        <Typography sx={styles.label}>
                                            {strings.pseudo}
                                        </Typography>
                                        <Input
                                            sx={styles.input}
                                            value={values.pseudo}
                                            disableUnderline
                                            autoFocus
                                            type="pseudo"
                                            name="pseudo"
                                            onChange={handleChange}
                                            onBlur={() =>
                                                setFieldTouched("pseudo")
                                            }
                                            placeholder={"pseudo"}
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ paddingLeft: "5px" }}>
                                                    <IconPseudo
                                                        width={20}
                                                        height={20}
                                                        fill={Colors.WHITE}
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.pseudo && errors.pseudo && (
                                            <Typography
                                                style={{
                                                    fontFamily: "Gilroy-Bold",
                                                    fontSize: normalize(12),
                                                    color: Colors.ERROR,
                                                    marginTop: "15px",
                                                    textAlign: "center",
                                                }}>
                                                {handleError(
                                                    errors.pseudo,
                                                    "pseudo",
                                                )}
                                            </Typography>
                                        )}
                                    </Box>
                                ) : null}
                                {showContinue ? (
                                    <Box
                                        style={{
                                            width: "100%",
                                            ...rowCenterStyles,
                                            marginTop: "calc(20px + 2.2vh)",
                                        }}>
                                        <GradientBtn
                                            disabled={
                                                errors.pseudo ||
                                                values.pseudo == ""
                                            }
                                            onPress={() => {
                                                setShowPseudo(false);
                                                setShowContinue(false);
                                                setShowEmail(true);
                                                setShowContinueEmail(true);
                                            }}>
                                            {strings.continue}
                                        </GradientBtn>
                                    </Box>
                                ) : null}
                                {showEmail ? (
                                    <Box sx={styles.inputContainer}>
                                        <Typography sx={styles.label}>
                                            Email
                                        </Typography>
                                        <Input
                                            sx={styles.input}
                                            value={values.email}
                                            disableUnderline
                                            autoFocus
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={() =>
                                                setFieldTouched("email")
                                            }
                                            autoComplete="email"
                                            placeholder={
                                                getLanguage() == "fr"
                                                    ? "email@email.com"
                                                    : "myemail@email.com"
                                            }
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ paddingLeft: "5px" }}>
                                                    <IconEmail
                                                        width={20}
                                                        height={20}
                                                        fill={Colors.WHITE}
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.email && errors.email && (
                                            <Typography
                                                style={{
                                                    fontFamily: "Gilroy-Bold",
                                                    fontSize: normalize(12),
                                                    color: Colors.ERROR,
                                                    marginTop: "15px",
                                                    textAlign: "center",
                                                }}>
                                                {handleError(
                                                    errors.email,
                                                    "email",
                                                )}
                                            </Typography>
                                        )}
                                    </Box>
                                ) : null}
                                {showContinueEmail ? (
                                    <Box
                                        style={{
                                            width: "100%",
                                            ...rowCenterStyles,
                                            marginTop: "calc(25px + 2vh)",
                                        }}>
                                        <GradientBtn
                                            disabled={
                                                errors.email ||
                                                values.email == ""
                                            }
                                            onPress={() => {
                                                setShowEmail(false);
                                                setShowContinueEmail(false);
                                                setShowPassword(true);
                                                setShowContinuePassword(true);
                                            }}>
                                            {strings.continue}
                                        </GradientBtn>
                                    </Box>
                                ) : null}
                                {showPassword ? (
                                    <Box sx={styles.inputContainer}>
                                        <Typography sx={styles.label}>
                                            {strings.password}
                                        </Typography>
                                        <Input
                                            sx={styles.input}
                                            value={values.password}
                                            disableUnderline
                                            autoFocus
                                            type={
                                                passwordVisibility
                                                    ? "password"
                                                    : "text"
                                            }
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={() =>
                                                setFieldTouched("password")
                                            }
                                            placeholder={
                                                getLanguage() == "fr"
                                                    ? "Mot de passe"
                                                    : "mypassword"
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconPassword
                                                        width={20}
                                                        height={20}
                                                        fill={Colors.WHITE}
                                                    />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <ButtonBase
                                                        onClick={
                                                            handlePasswordVisibility
                                                        }
                                                        sx={{
                                                            borderRadius:
                                                                "20px",
                                                            marginRight: "5px",
                                                        }}>
                                                        <SecretIcon
                                                            width={20}
                                                            height={20}
                                                            fill={
                                                                rightIcon ===
                                                                "eye"
                                                                    ? Colors.OLD_PRICE
                                                                    : Colors.WHITE
                                                            }
                                                        />
                                                    </ButtonBase>
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.password &&
                                            errors.password && (
                                                <Typography
                                                    style={{
                                                        fontFamily:
                                                            "Gilroy-Bold",
                                                        fontSize: normalize(12),
                                                        color: Colors.ERROR,
                                                        marginTop: "15px",
                                                        textAlign: "center",
                                                    }}>
                                                    {handleError(
                                                        errors.password,
                                                        "password",
                                                    )}
                                                </Typography>
                                            )}
                                    </Box>
                                ) : null}

                                {showContinuePassword && (
                                    <Box
                                        style={{
                                            width: "100%",
                                            ...rowCenterStyles,
                                            marginTop: "calc(25px + 2vh)",
                                        }}>
                                        <GradientBtn
                                            disabled={
                                                errors.password ||
                                                values.password == ""
                                            }
                                            onPress={() => {
                                                setShowPassword(false);
                                                setShowContinuePassword(false);
                                                setShowReferral(true);
                                                setShowContinueReferral(true);
                                            }}>
                                            {strings.continue}
                                        </GradientBtn>
                                    </Box>
                                )}

                                {showReferral && (
                                    <Box sx={styles.inputContainer}>
                                        <Typography sx={styles.label}>
                                            {strings.referral.referralCode}
                                        </Typography>
                                        <Input
                                            sx={styles.input}
                                            value={values.referral}
                                            disableUnderline
                                            autoFocus
                                            type={"text"}
                                            name="referral"
                                            onChange={handleChange}
                                            onBlur={() =>
                                                setFieldTouched("referral")
                                            }
                                            placeholder={"AAAAAA"}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconPseudo
                                                        width={20}
                                                        height={20}
                                                        fill={Colors.WHITE}
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.referral && (
                                            <Typography
                                                style={{
                                                    fontFamily: "Gilroy-Bold",
                                                    fontSize: normalize(12),
                                                    color: Colors.ERROR,
                                                    marginTop: "15px",
                                                    textAlign: "center",
                                                }}>
                                                {handleError(
                                                    errors.referral,
                                                    "referral",
                                                )}
                                            </Typography>
                                        )}
                                    </Box>
                                )}

                                {showContinueReferral && (
                                    <Box
                                        sx={{
                                            ...columnCenterStyles,
                                            width: "100%",
                                            marginTop: "calc(30px)",
                                        }}>
                                        <Box
                                            style={{
                                                width: "100%",
                                                ...rowCenterStyles,
                                                marginTop: "25px",
                                            }}>
                                            <GradientBtn
                                                disabled={
                                                    errors.referral ||
                                                    values.referral == ""
                                                }
                                                onPress={async () => {
                                                    //setLoading(true);
                                                    const isValid =
                                                        await onSubmitReferralCode(
                                                            values.referral,
                                                        );
                                                    //setLoading(false);
                                                    if (isValid) {
                                                        setReferral(
                                                            values.referral,
                                                        );
                                                        setShowReferral(false);
                                                        setShowContinueReferral(
                                                            false,
                                                        );
                                                        setShowCgv(true);
                                                        setShowSubmit(true);
                                                    } else {
                                                        errors.referral =
                                                            strings.referral.codeDoesntExist;
                                                        forceUpdate();
                                                    }
                                                }}>
                                                {strings.continue}
                                            </GradientBtn>
                                        </Box>
                                        <ButtonBase
                                            sx={{
                                                width: "100%",
                                                ...rowCenterStyles,
                                                marginTop: "25px",
                                            }}
                                            onClick={() => {
                                                setShowReferral(false);
                                                setShowContinueReferral(false);
                                                setShowCgv(true);
                                                setShowSubmit(true);
                                            }}>
                                            <Typography sx={styles.noCodeText}>
                                                {strings.referral.dontHaveCode}
                                            </Typography>
                                        </ButtonBase>
                                    </Box>
                                )}

                                {showCgv && (
                                    <Box
                                        sx={[
                                            styles.inputContainer,
                                            !matchesHeight
                                                ? {
                                                      width: "100%",
                                                  }
                                                : {},
                                        ]}>
                                        <CustomCheckboxRegister
                                            value={values.newsletter}
                                            handleChange={(nextValue: any) =>
                                                setFieldValue(
                                                    "newsletter",
                                                    nextValue,
                                                )
                                            }>
                                            <Typography sx={styles.cgvText}>
                                                {strings.newsletterRegister}
                                            </Typography>
                                        </CustomCheckboxRegister>
                                        <CustomCheckboxRegister
                                            value={values.terms}
                                            handleChange={(nextValue: any) => {
                                                setFieldValue(
                                                    "terms",
                                                    nextValue,
                                                );
                                            }}>
                                            <Typography
                                                sx={styles.cgvText}
                                                component={"span"}>
                                                {strings.agreeTerms}
                                                <Typography
                                                    component={"span"}
                                                    sx={[
                                                        styles.commonText,
                                                        { color: Colors.HEART },
                                                    ]}
                                                    onClick={() => {
                                                        window
                                                            .open(
                                                                strings.cguvUrl,
                                                                "_blank",
                                                            )
                                                            ?.focus();
                                                    }}>
                                                    {strings.terms}
                                                </Typography>
                                            </Typography>
                                        </CustomCheckboxRegister>
                                        <CustomCheckboxRegister
                                            value={values.privacy}
                                            handleChange={(nextValue: any) => {
                                                setFieldValue(
                                                    "privacy",
                                                    nextValue,
                                                );
                                            }}>
                                            <Typography
                                                sx={styles.cgvText}
                                                component={"span"}>
                                                {strings.agreeTerm}
                                                <Typography
                                                    component={"span"}
                                                    sx={[
                                                        styles.commonText,
                                                        { color: Colors.HEART },
                                                    ]}
                                                    onClick={() => {
                                                        window
                                                            .open(
                                                                strings.confiUrl,
                                                                "_blank",
                                                            )
                                                            ?.focus();
                                                    }}>
                                                    {strings.privacyPolicy}
                                                </Typography>
                                            </Typography>
                                        </CustomCheckboxRegister>
                                    </Box>
                                )}

                                {showSubmit && (
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: matchesHeight
                                                ? "calc(20px + 1vh)"
                                                : "5px",
                                        }}>
                                        <GradientBtn
                                            disabled={
                                                !isValid ||
                                                errors.terms ||
                                                values.terms != true ||
                                                errors.privacy ||
                                                values.privacy != true
                                            }
                                            onPress={handleSubmit}>
                                            {strings.register}
                                        </GradientBtn>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box style={styles.container}>
                                <Typography sx={styles.title2}>
                                    {strings.welcomeAboard}
                                </Typography>
                                <Typography
                                    sx={[
                                        styles.subtitle,
                                        { marginBottom: "15px" },
                                    ]}>
                                    {strings.registerConfirmedSubtitle}
                                </Typography>
                                <Typography
                                    sx={[
                                        styles.subtitle,
                                        {
                                            marginBottom: matchesHeight
                                                ? "calc(20px + 2.2vh)"
                                                : "25px",
                                        },
                                    ]}>
                                    {strings.registerConfirmedSubtitle2}
                                </Typography>

                                {/*  <ButtonBase
                  sx={{ width: "50%" }}
                  onClick={() => {
                    if (values) {
                      askForActivationEmail(values);
                    }
                  }}
                >
                  <Box sx={styles.notReceivedBtn}>
                    <Typography sx={styles.notReceivedBtnText}>
                      {strings.stillNotReceived}
                    </Typography>
                  </Box>
                </ButtonBase> */}

                                <GradientBtn onPress={() => callback()}>
                                    {strings.signIn}
                                </GradientBtn>
                            </Box>
                        )}
                    </Box>
                )}
            </Formik>
            {error ? (
                <Modal open={!!error} onClose={() => setError(null)}>
                    <Box style={{ ...columnCenterStyles }}>
                        <Box style={styles.modalViewError}>
                            <Typography
                                style={{
                                    color: Colors.WHITE,
                                    fontFamily: "Eczar-Regular",
                                    fontSize: normalize(36),
                                    marginBottom: "18px",
                                    textAlign: "center",
                                }}>
                                {strings.error}
                            </Typography>

                            <Typography sx={styles.textModalError}>
                                {error}
                            </Typography>

                            <Box style={styles.separatorError} />

                            <ButtonBase onClick={() => setError(null)}>
                                <Typography style={styles.deleteStoryText}>
                                    {strings.understood}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                </Modal>
            ) : null}
            {showConfirmedModal ? (
                <Modal
                    open={showConfirmedModal}
                    onClose={() => setShowConfirmedModal(false)}>
                    <Box sx={{ ...columnCenterStyles }}>
                        <Box sx={[styles.modalViewError]}>
                            <CompleteIcon width={70} height={70} />
                            <Typography
                                sx={[styles.title2, { marginTop: "20px" }]}>
                                {strings.sent}
                            </Typography>
                            <Typography
                                sx={[styles.subtitle, { marginTop: "10px" }]}>
                                {strings.emailResent}
                            </Typography>
                            <Box style={styles.separatorError} />

                            <Box sx={styles.popupButtons}>
                                <ButtonBase
                                    sx={styles.yesButton}
                                    onClick={() => {
                                        SoundManager.getInstance().playCloseModalSound();
                                        setShowConfirmedModal(false);
                                    }}>
                                    <Typography sx={styles.yesText}>
                                        {strings.great}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            ) : null}
        </Box>
    );
};

export default RegisterBox;
