/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from "react";
import { Colors } from "../../../../../style";
import { Box, Switch, Typography } from "@mui/material";

interface Props {
  text: string;
  icon: any;
  switchCallback?: any;
  switchValue: boolean;
  color: any;
  textColor: any;
}

interface State {
  switchValue: boolean;
}

const styles = {
  container: {
    marginTop: "20px",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: Colors.WHITE,
    fontSize: 20,
    fontFamily: "Gilroy-Bold",
  },
  groupIcon: {
    display: "flex",
    // backgroundColor: 'red',
    flexDirection: "row",
    // alignSelf: 'flex-start',
    // justifyContent: 'center',
    marginRight: "40px",
    alignItems: "center",
  },
  switch: {
    alignSelf: "center",
  },
};

class SwitchOptionRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      switchValue: props.switchValue,
    };
  }

  toggleSwitch = (value: any) => {
    const { switchValue } = this.state;
    const { switchCallback } = this.props;
    this.setState(
      {
        switchValue: value.target.checked,
      },
      () => {
        if (switchValue === false) {
          switchCallback(true);
        } else {
          switchCallback(false);
        }
      }
    );
  };

  render() {
    const { text, icon, color, textColor } = this.props;
    const { switchValue } = this.state;
    return (
      <Box style={styles.container}>
        <Box sx={styles.subContainer}>
          <Box sx={styles.groupIcon}>
            {icon}
            <Typography sx={[styles.text, { color: textColor }]}>
              {text}
            </Typography>
          </Box>
          <Box style={styles.switch}>
            <Switch
              sx={{
                color: switchValue ? color : textColor,
                "& .MuiSwitch-thumb": {
                  backgroundColor: switchValue ? '#f4f3f4' : '#f4f3f4',
                },
                "& .MuiSwitch-track": {
                  backgroundColor: switchValue ? color + '!important' : textColor + '!important',
                  opacity: switchValue ? 0.9 + '!important' : 0.5,
                },
              }}
              onChange={this.toggleSwitch}
              checked={switchValue}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default SwitchOptionRow;
