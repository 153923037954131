import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography } from "@mui/material";
import { rewardTitleJson } from "../../../constants/rewardTitle";
import { normalizeSize } from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnCenterStyles } from "../../../style/flex";

const TitleReward = ({ title }: { title: any }) => {
    if (title && title.name) {
        return (
            <Box sx={styles.rewardBlock}>
                <Box sx={styles.rewardBlock}>
                    <Player src={rewardTitleJson} autoplay loop speed={1.5} />
                    <Typography sx={[styles.titleReward]}>
                        {title.name}
                    </Typography>
                </Box>
            </Box>
        );
    }
    return null;
};

const styles = {
    rewardBlock: {
        ...columnCenterStyles,
        flex: 1,
    },
    titleReward: {
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
        marginTop: "10px",
    },
};

export default TitleReward;
