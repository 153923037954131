/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router";
import useAuth from "../../services/Auth/useAuth";
import { Save } from "../../models/save";
import { useEffect, useState } from "react";
import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import moment from "moment";
import {
  columnCenterStyles,
  columnStartStyles,
  columnStyles,
} from "../../style/flex";
import { Colors } from "../../style";
import SaveBtn from "../../components/SaveBtn";
import BackgroundRow from "../../components/BackgroundRow";
import normalize from "../../utils/fontSizer";
import Loading from "../../components/Loading";
import { ReactComponent as IconSave } from "../../assets/icons/icon_save.svg";
import SoundManager from "../../services/SoundManager";

interface Props {
  players: any;
  variables: any;
  hints: any;
  storyId: number;
  type: any;
  save: any | undefined;
  savesLoad?: Array<Save> | undefined | null;
  series?: any;
}

const CreateSaveScreen = (props: Props) => {
  const { players, variables, hints, storyId, series, type, save, savesLoad } =
    props;
  const auth = useAuth();
  const navigate = useNavigate();
  const [saves, setSaves] = useState(savesLoad ? savesLoad : null);
  const [currentSave, setCurrentSave] = useState(save ? save : null);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [activeSave, setActiveSave] = useState<any>();
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);

  useEffect(() => {
    if (auth && auth.user) {
      if (!currentSave) {
        const saveTmp = {
          userId: auth.user.id,
          storyId: storyId,
          players: players.players,
          variables: variables.variables,
          hints: hints,
          type: type,
        };
        setCurrentSave(saveTmp);
      }

      updateSaves();
    }
  }, []);

  const updateSaves = async () => {
    try {
      if (auth && auth.user) {
        const api = getRouteManager().getSavesForUser(
          auth.user.id,
          getLanguage(),
          "all",
        );

        const data = await getRouteManager().getData(api);

        if (data && data.length) {
          data.map((s: Save, index: number) => {
            s.key = index + 1;
            if (s.storyId) {
              s.image_backdrop = getRouteManager().getCoverForSeries(s.storyId);
              s.image_title = getRouteManager().titleForStory(
                getLanguage(),
                s.storyId,
              );
            }
            if (s.created) {
              s.date =
                getLanguage() === "fr"
                  ? moment(s.created).format(
                    'DD/MM/YYYY HH:mm',
                  )
                  : moment(s.created).format(
                    'MM/DD/YYYY hh:mm',
                  );
            }
          });
          setSaves(data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  /* const createSaveForUser = async () => {
    try {
      if (currentSave) {
        setIsLoading(true);
        const api = getRouteManager().createSaveForUser();
        const creation = await getRouteManager().fetchData(api, {
          save: currentSave,
        });
        if (
          creation &&
          creation.flashmessage &&
          creation.flashmessage !== "success"
        ) {
          alert(creation.flashmessage);
          setIsLoading(false);
        } else if (
          creation ||
          (creation &&
            creation.flashmessage &&
            creation.flashmessage === "success")
        ) {
          setIsLoading(false);
          setShowPopupSuccess(true);
        } else {
          alert("An error occured while creating save");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }; */

  const replaceSaveForUser = async (id: number) => {
    try {
      setShowConfirm(false);
      if (currentSave) {
        setIsLoading(true);
        const saveCopy = currentSave;
        saveCopy.oldSave = id;
        const api = getRouteManager().replaceSaveForUser();
        const write = await getRouteManager().fetchData(api, {
          save: saveCopy,
        });
        if (write && write.flashmessage && write.flashmessage !== "success") {
          alert(write.flashmessage);
          setIsLoading(false);
        } else if (
          write ||
          (write && write.flashmessage && write.flashmessage === "success")
        ) {
          setIsLoading(false);
          setShowPopupSuccess(true);
        } else {
          alert("An error occured while replacing save");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const confirmDelete = () => {
    //setShowPopUp(false);
    setShowConfirm(true);
  };

  const drawSaves = () => {
    if (saves && saves.length) {
      return (
        <Box sx={styles.containerGap}>
          {saves.map((s: any, index: number) => (
            <SaveBtn
              save={s}
              isLast={index === saves.length - 1}
              key={index}
              onClick={() => {
                setActiveSave(s);
                setShowPopUp(true);
              }}
            />
          ))}
        </Box>
      );
    } else return null;
  };

  return (
    <BackgroundRow
      title={strings.saves}
      subtitle={strings.allSaves}
      loading={isLoading}
      animType="normal"
      leftPart={
        <Typography sx={styles.nbSaves}>
          {saves && saves.length ? saves.length : 0}
          {auth && auth?.user && auth?.user?.nbSaves
            ? auth.user.nbSaves
            : 20}{" "}
          {strings.savesUsed}
        </Typography>
      }
      rightPart={
        <Box sx={styles.container}>
          {saves && saves.length && drawSaves()}
          <Modal
            open={showPopUp}
            onClose={() => {
              setShowConfirm(false);
              setShowPopUp(false);
            }}>
            <Box sx={styles.centeredView}>
              {activeSave && !showConfirm ? (
                <Box sx={styles.modalView}>
                  <Typography sx={styles.storyTitle}>
                    {activeSave.story.title}
                  </Typography>
                  <Typography sx={styles.playersModal}>
                    {activeSave.players}
                  </Typography>
                  <Typography sx={styles.dateModal}>
                    {activeSave.date}
                  </Typography>
                  <Box sx={styles.separator} />
                  <Box sx={styles.popupButtons}>
                    <ButtonBase
                      sx={styles.noButton}
                      onClick={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        setShowPopUp(false);
                      }}>
                      <Typography sx={styles.noText}>
                        {strings.keepIt}
                      </Typography>
                    </ButtonBase>
                    <ButtonBase
                      sx={styles.yesButton}
                      onClick={() => {
                        SoundManager.getInstance().playBtnSound();
                        confirmDelete();
                      }}>
                      <Typography sx={styles.yesText}>
                        {strings.replace}
                      </Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              ) : activeSave && showConfirm ? (
                <Box sx={styles.modalView}>
                  <Typography sx={styles.deleteSaveTitle}>
                    {strings.confirm}
                  </Typography>
                  <Typography sx={styles.deleteSaveMsg}>
                    {strings.replaceSaveMsg}
                  </Typography>
                  <Box sx={styles.separator} />
                  <Box sx={styles.popupButtons}>
                    <ButtonBase
                      sx={styles.noButton}
                      onClick={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        setShowConfirm(false);
                        setShowPopUp(false);
                      }}>
                      <Typography sx={styles.noText}>
                        {strings.keepIt}
                      </Typography>
                    </ButtonBase>
                    <ButtonBase
                      sx={styles.yesButton}
                      onClick={() => {
                        SoundManager.getInstance().playCloseModalSound();
                        replaceSaveForUser(activeSave.id);
                      }}>
                      <Typography sx={styles.yesText}>
                        {strings.confirm}
                      </Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              ) : (
                <Loading />
              )}
            </Box>
          </Modal>
          <Modal open={showPopupSuccess}>
            <Box sx={styles.centeredView}>
              <Box sx={styles.modalView}>
                <IconSave width={90} height={90} fill={Colors.WHITE} />
                <Typography sx={styles.replaceSuccessText}>
                  {strings.saveSuccessful}
                </Typography>
                <Box style={styles.separator} />

                <Box sx={styles.popupButtons}>
                  <ButtonBase
                    sx={styles.noButton}
                    onClick={() => {
                      setShowPopupSuccess(false);
                      navigate("/", { replace: true });
                    }}>
                    <Typography sx={styles.noText}>{strings.back}</Typography>
                  </ButtonBase>
                  <ButtonBase
                    sx={styles.continueButton}
                    onClick={() => {
                      setShowPopupSuccess(false);
                      navigate(`/`, {
                        replace: true,
                        state: {
                          seriesParam: series,
                          shouldShowEpisodes: true,
                        },
                      });
                    }}>
                    <Typography sx={styles.continueText}>
                      {strings.continue}
                    </Typography>
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      }
    />
  );
};

const styles = {
  nbSaves: {
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
  },
  container: {
    ...columnStartStyles,
    alignItems: "flex-start",
  },
  containerGap: {
    ...columnStartStyles,
    alignItems: "flex-start",
    rowGap: "15px",
  },
  centeredView: {
    flex: 1,
    ...columnStyles,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: "300px",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    paddingTop: "45px",
    ...columnCenterStyles,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  storyTitle: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(18),
    textAlign: "center",
    padding: "0px 45px",
  },
  playersModal: {
    marginTop: "15px",
    color: Colors.BTN_INACTIVE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    textAlign: "center",
    padding: "0px 45px",
  },
  dateModal: {
    marginTop: "15px",
    color: Colors.INPUT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    textAlign: "center",
    padding: "0px 45px",
  },
  deleteSaveTitle: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(18),
    marginTop: "19px",
  },
  deleteSaveMsg: {
    color: Colors.BTN_INACTIVE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    marginTop: "19px",
    textAlign: "center",
  },
  replaceSuccessText: {
    color: Colors.LOCK,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
    marginTop: "22px",
    textAlign: "center",
  },
  separator: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.BTN_ACTIVE,
    marginTop: "25px",
  },
  popupButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginTop: "25px",
    marginBottom: "25px",
  },
  yesButton: {
    backgroundColor: Colors.ERROR,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  continueButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  continueText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
};

export default CreateSaveScreen;
