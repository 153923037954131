/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { getRouteManager } from "../routeManager";
import { fakeAuthProvider } from "./auth";
import { socket } from "../../socket";
import { SOCKET_EVENTS } from "../../constants";

interface AuthContextType {
    user: any;
    signin: (user: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
    setUser: (user: any) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<any>(null);

    const signin = (newUser: any, callback: VoidFunction) => {
        return fakeAuthProvider.signin(() => {
            setUser(newUser);
            localStorage.setItem("login_token", newUser.login_token);
            localStorage.setItem("hasAlreadyLaunchGame", "true");
            getRouteManager().setUser(newUser);
            if (newUser && newUser.player_code) {
                socket.emit(SOCKET_EVENTS.LOGIN, newUser.player_code);
            }
            callback();
        });
    };

    const signout = (callback: VoidFunction) => {
        return fakeAuthProvider.signout(() => {
            socket.emit(SOCKET_EVENTS.DISCONNECT, {
                playerCode: user?.player_code,
            });
            setUser(null);
            localStorage.setItem("login_token", "");
            callback();
        });
    };

    const value = { user, signin, signout, setUser };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export default function useAuth() {
    return React.useContext(AuthContext);
}

/* function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
} */
