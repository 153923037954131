export const diceAnim = {
    v: '5.9.6',
    fr: 29.9700012207031,
    ip: 0,
    op: 180.00000733155,
    w: 144,
    h: 144,
    nm: 'Rolling Dice',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'dice Outlines',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.001], y: [0.993] },
                            o: { x: [0.168], y: [0.199] },
                            t: 0,
                            s: [0],
                        },
                        {
                            i: { x: [0.665], y: [1] },
                            o: { x: [0.332], y: [-0.132] },
                            t: 160,
                            s: [2165],
                        },
                        { t: 180.00000733155, s: [2160] },
                    ],
                    ix: 10,
                },
                p: { a: 0, k: [72, 72, 0], ix: 2, l: 2 },
                a: { a: 0, k: [72, 72, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.199, 0.628],
                                        [0, 0],
                                        [0, 0],
                                        [-0.266, -0.264],
                                        [-0.359, -0.184],
                                        [-0.389, -0.104],
                                        [-0.332, -0.017],
                                        [-0.266, 0.065],
                                        [-0.169, 0.18],
                                        [-0.011, 0.31],
                                        [0.239, 0.185],
                                        [0.284, 0.076],
                                        [0.293, -0.074],
                                        [0.187, -0.222],
                                        [0.008, -0.359],
                                        [0, 0],
                                        [-0.158, 0.407],
                                        [-0.303, 0.263],
                                        [-0.394, 0.095],
                                        [-0.426, -0.115],
                                        [-0.305, -0.323],
                                        [-0.103, -0.452],
                                        [0.133, -0.501],
                                        [0.406, -0.349],
                                        [0.561, -0.09],
                                        [0.636, 0.17],
                                        [0.473, 0.384],
                                        [0.267, 0.528],
                                        [0.047, 0.606],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0.104, 0.357],
                                        [0.263, 0.271],
                                        [0.367, 0.187],
                                        [0.337, 0.09],
                                        [0.33, 0.024],
                                        [0.273, -0.063],
                                        [0.166, -0.172],
                                        [-0.049, -0.237],
                                        [-0.234, -0.175],
                                        [-0.314, -0.084],
                                        [-0.284, 0.075],
                                        [-0.181, 0.224],
                                        [0, 0],
                                        [-0.054, -0.519],
                                        [0.157, -0.407],
                                        [0.31, -0.262],
                                        [0.399, -0.085],
                                        [0.485, 0.13],
                                        [0.312, 0.332],
                                        [0.101, 0.46],
                                        [-0.161, 0.598],
                                        [-0.408, 0.355],
                                        [-0.563, 0.098],
                                        [-0.665, -0.178],
                                        [-0.467, -0.382],
                                        [-0.267, -0.528],
                                        [-0.046, -0.605],
                                    ],
                                    v: [
                                        [-3.512, -2.983],
                                        [-1.771, -2.793],
                                        [-2.696, -0.865],
                                        [-2.14, 0.066],
                                        [-1.205, 0.749],
                                        [-0.072, 1.185],
                                        [0.931, 1.346],
                                        [1.826, 1.285],
                                        [2.488, 0.921],
                                        [2.754, 0.199],
                                        [2.322, -0.434],
                                        [1.545, -0.81],
                                        [0.635, -0.825],
                                        [-0.073, -0.378],
                                        [-0.356, 0.496],
                                        [-1.263, 0.385],
                                        [-1.108, -1.004],
                                        [-0.418, -2.009],
                                        [0.639, -2.544],
                                        [1.878, -2.5],
                                        [3.066, -1.821],
                                        [3.688, -0.645],
                                        [3.64, 0.797],
                                        [2.79, 2.217],
                                        [1.337, 2.885],
                                        [-0.462, 2.776],
                                        [-2.171, 1.933],
                                        [-3.272, 0.568],
                                        [-3.743, -1.133],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [125.324, 86.034], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-4.197, -0.645],
                                        [-4.008, -1.839],
                                        [2.932, -4.687],
                                        [3.715, -4.01],
                                        [2.828, -2.864],
                                        [2.593, -3.434],
                                        [-2.83, -0.757],
                                        [-3.049, -1.382],
                                        [2.785, 1.809],
                                        [2.334, 2.675],
                                        [1.777, 2.804],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [-0.386, -0.073],
                                        [-0.336, -0.086],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0.337, 0.012],
                                        [0.387, 0.074],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-3.415, 2.43],
                                        [-3.258, 1.99],
                                        [-2.173, 2.117],
                                        [-1.088, 2.357],
                                        [-1.798, 3.364],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-1.158, 3.733],
                                        [-0.618, 2.854],
                                        [0.906, 4.088],
                                        [0.495, 4.687],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-2.076, 3.203],
                                        [0.908, -1.962],
                                        [1.978, -3.483],
                                        [2.712, -4.476],
                                        [4.197, -3.731],
                                        [-0.373, 4.186],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [87.619, 21.234], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 2',
                    np: 6,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-1.562, 5.77],
                                        [-5.826, -1.616],
                                        [-4.181, -2.807],
                                        [0.188, 4.76],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [-0.163, 0.202],
                                        [-0.188, 0.194],
                                        [-0.189, 0.173],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0.118, -0.186],
                                        [0.158, -0.209],
                                        [0.183, -0.203],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-3.131, 6.676],
                                        [-3.433, 6.319],
                                        [-3.012, 5.739],
                                        [-2.493, 5.133],
                                        [-1.934, 4.57],
                                        [-1.417, 5.687],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-0.065, 4.907],
                                        [-0.557, 3.999],
                                        [1.274, 3.296],
                                        [1.588, 3.951],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-6.266, 0.939],
                                        [-7.068, 0.469],
                                        [-5.265, -2.873],
                                        [-4.807, -2.525],
                                        [-4.53, -1.35],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-5.058, -1.817],
                                        [-5.265, -2.873],
                                        [-4.181, -2.807],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: 'sh',
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0.564, -0.325],
                                        [0.533, -0.061],
                                        [0.427, 0.183],
                                        [0.242, 0.418],
                                        [0.008, 0.403],
                                        [-0.187, 0.42],
                                        [-0.388, 0.407],
                                        [0, 0],
                                        [0.008, -0.413],
                                        [-0.177, -0.305],
                                        [-0.366, -0.132],
                                        [-0.419, 0.242],
                                        [-0.115, 0.098],
                                        [-0.113, 0.12],
                                        [0.031, 0.165],
                                        [0.088, 0.153],
                                        [0.232, 0.123],
                                        [0.369, 0.044],
                                        [0.544, -0.025],
                                        [0, 0],
                                        [0.44, 0.186],
                                        [0.242, 0.418],
                                        [-0.016, 0.438],
                                        [-0.286, 0.433],
                                        [-0.555, 0.32],
                                        [-0.472, 0.036],
                                        [-0.373, -0.181],
                                        [-0.2, -0.346],
                                        [0.063, -0.541],
                                        [0.451, -0.594],
                                        [0, 0],
                                        [-0.034, 0.256],
                                        [0.045, 0.189],
                                        [0.069, 0.121],
                                        [0.343, 0.091],
                                        [0.338, -0.195],
                                        [0.099, -0.089],
                                        [0.085, -0.113],
                                        [-0.027, -0.177],
                                        [-0.074, -0.128],
                                        [-0.267, -0.092],
                                        [-0.354, 0.001],
                                        [-0.382, 0.007],
                                        [-0.464, -0.043],
                                        [-0.386, -0.185],
                                        [-0.247, -0.426],
                                        [0.042, -0.485],
                                        [0.323, -0.444],
                                    ],
                                    o: [
                                        [-0.555, 0.32],
                                        [-0.533, 0.061],
                                        [-0.427, -0.182],
                                        [-0.2, -0.346],
                                        [0.001, -0.408],
                                        [0.191, -0.431],
                                        [0, 0],
                                        [-0.263, 0.399],
                                        [-0.009, 0.413],
                                        [0.199, 0.346],
                                        [0.369, 0.12],
                                        [0.104, -0.06],
                                        [0.115, -0.099],
                                        [0.003, -0.237],
                                        [-0.035, -0.172],
                                        [-0.14, -0.241],
                                        [-0.232, -0.124],
                                        [-0.368, -0.044],
                                        [0, 0],
                                        [-0.592, -0.002],
                                        [-0.444, -0.194],
                                        [-0.223, -0.386],
                                        [0.018, -0.451],
                                        [0.286, -0.434],
                                        [0.539, -0.312],
                                        [0.48, -0.042],
                                        [0.373, 0.182],
                                        [0.223, 0.387],
                                        [-0.06, 0.528],
                                        [0, 0],
                                        [0.184, -0.332],
                                        [0.035, -0.256],
                                        [-0.049, -0.197],
                                        [-0.186, -0.322],
                                        [-0.335, -0.097],
                                        [-0.104, 0.061],
                                        [-0.095, 0.076],
                                        [-0.037, 0.215],
                                        [0.036, 0.173],
                                        [0.144, 0.25],
                                        [0.268, 0.092],
                                        [0.353, 0],
                                        [0.474, -0.016],
                                        [0.465, 0.042],
                                        [0.385, 0.185],
                                        [0.255, 0.443],
                                        [-0.046, 0.477],
                                        [-0.315, 0.439],
                                    ],
                                    v: [
                                        [5.155, 2.23],
                                        [3.523, 2.803],
                                        [2.082, 2.62],
                                        [1.079, 1.719],
                                        [0.768, 0.596],
                                        [1.049, -0.645],
                                        [1.917, -1.903],
                                        [2.941, -1.576],
                                        [2.534, -0.36],
                                        [2.786, 0.717],
                                        [3.634, 1.434],
                                        [4.816, 1.251],
                                        [5.145, 1.014],
                                        [5.487, 0.686],
                                        [5.445, 0.084],
                                        [5.26, -0.404],
                                        [4.702, -0.952],
                                        [3.8, -1.204],
                                        [2.431, -1.234],
                                        [1.571, -1.219],
                                        [0.024, -1.501],
                                        [-1.005, -2.42],
                                        [-1.316, -3.656],
                                        [-0.859, -4.981],
                                        [0.403, -6.113],
                                        [1.92, -6.635],
                                        [3.2, -6.424],
                                        [4.059, -5.633],
                                        [4.299, -4.243],
                                        [3.532, -2.561],
                                        [2.513, -2.681],
                                        [2.841, -3.563],
                                        [2.826, -4.23],
                                        [2.648, -4.706],
                                        [1.855, -5.325],
                                        [0.846, -5.179],
                                        [0.541, -4.954],
                                        [0.271, -4.67],
                                        [0.256, -4.081],
                                        [0.421, -3.63],
                                        [1.038, -3.117],
                                        [1.971, -2.98],
                                        [3.075, -2.99],
                                        [4.482, -2.949],
                                        [5.758, -2.607],
                                        [6.706, -1.691],
                                        [7.026, -0.299],
                                        [6.473, 1.083],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 6',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [53.44, 21.386], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 3',
                    np: 8,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.466, 2.703],
                                        [-2.399, 4.542],
                                        [-3.003, 2.961],
                                        [4.03, 1.077],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.126, 0.174],
                                        [0.116, 0.193],
                                        [0.099, 0.19],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-0.124, -0.135],
                                        [-0.135, -0.173],
                                        [-0.123, -0.191],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.857, 4.161],
                                        [4.504, 4.328],
                                        [4.129, 3.864],
                                        [3.753, 3.315],
                                        [3.419, 2.743],
                                        [4.43, 2.568],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.093, 1.312],
                                        [3.258, 1.512],
                                        [3.086, -0.113],
                                        [3.681, -0.225],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-0.437, 5.448],
                                        [-0.989, 5.992],
                                        [-3.289, 3.819],
                                        [-2.911, 3.526],
                                        [-1.906, 3.557],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-2.396, 3.88],
                                        [-3.289, 3.819],
                                        [-3.003, 2.961],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: 'sh',
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.34, 0.133],
                                        [0.241, 0.032],
                                        [0.187, -0.05],
                                        [0.142, -0.126],
                                        [0.059, -0.201],
                                        [-0.074, -0.277],
                                        [-0.105, -0.212],
                                        [-0.138, -0.188],
                                        [0, 0],
                                        [0.261, 0.435],
                                        [0.095, 0.415],
                                        [-0.098, 0.411],
                                        [-0.267, 0.288],
                                        [-0.396, 0.106],
                                        [-0.309, -0.046],
                                        [-0.372, -0.165],
                                        [-0.487, -0.262],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [-0.476, -0.249],
                                        [-0.343, -0.141],
                                        [-0.25, -0.037],
                                        [-0.18, 0.049],
                                        [-0.144, 0.119],
                                        [-0.058, 0.2],
                                        [0.04, 0.149],
                                        [0.103, 0.205],
                                        [0, 0],
                                        [-0.336, -0.327],
                                        [-0.27, -0.441],
                                        [-0.117, -0.466],
                                        [0.091, -0.409],
                                        [0.266, -0.295],
                                        [0.292, -0.078],
                                        [0.301, 0.047],
                                        [0.364, 0.159],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [3.472, -1.004],
                                        [2.624, -0.536],
                                        [-0.166, -1.977],
                                        [-1.389, -2.55],
                                        [-2.265, -2.809],
                                        [-2.921, -2.79],
                                        [-3.404, -2.528],
                                        [-3.708, -2.049],
                                        [-3.685, -1.334],
                                        [-3.467, -0.792],
                                        [-3.105, -0.203],
                                        [-3.288, 0.868],
                                        [-4.182, -0.275],
                                        [-4.73, -1.559],
                                        [-4.759, -2.874],
                                        [-4.222, -3.92],
                                        [-3.229, -4.522],
                                        [-2.328, -4.571],
                                        [-1.318, -4.253],
                                        [-0.042, -3.621],
                                        [2.095, -2.426],
                                        [2.172, -2.495],
                                        [0.929, -5.516],
                                        [2.174, -5.85],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 6',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 6,
                            ty: 'sh',
                            ix: 7,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.363, -0.008],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.405, 0.044],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.335, -0.066],
                                        [0.405, -0.045],
                                    ],
                                    v: [
                                        [2.138, -5.984],
                                        [2.299, -4.308],
                                        [-0.095, -4.941],
                                        [-0.122, -5.763],
                                        [0.987, -5.928],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 7',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 7,
                            ty: 'sh',
                            ix: 8,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.164, -0.076],
                                        [0.183, -0.065],
                                        [0.17, -0.054],
                                        [0.146, -0.024],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-0.134, 0.068],
                                        [-0.174, 0.071],
                                        [-0.183, 0.065],
                                        [-0.18, 0.048],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-1.564, -0.869],
                                        [-1.288, 0.163],
                                        [-1.734, 0.379],
                                        [-2.27, 0.583],
                                        [-2.799, 0.761],
                                        [-3.288, 0.868],
                                        [-3.656, -0.825],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 8',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [18.932, 86.637], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 4',
                    np: 10,
                    cix: 2,
                    bm: 0,
                    ix: 4,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.803, -0.158],
                                        [-0.222, 4.869],
                                        [-1.536, 3.8],
                                        [3.613, -1.347],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.197, 0.089],
                                        [0.197, 0.109],
                                        [0.181, 0.115],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-0.175, -0.054],
                                        [-0.203, -0.082],
                                        [-0.202, -0.105],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [5.87, 0.911],
                                        [5.649, 1.231],
                                        [5.091, 1.016],
                                        [4.491, 0.731],
                                        [3.916, 0.402],
                                        [4.705, -0.256],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [3.785, -1.175],
                                        [3.161, -0.584],
                                        [2.2, -1.907],
                                        [2.66, -2.3],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1.929, 4.671],
                                        [1.724, 5.419],
                                        [-1.355, 4.687],
                                        [-1.175, 4.244],
                                        [-0.288, 3.769],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-0.551, 4.295],
                                        [-1.355, 4.687],
                                        [-1.536, 3.8],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: 'sh',
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.345, -0.345],
                                        [-0.126, -0.421],
                                        [0.077, -0.438],
                                        [0.235, -0.421],
                                        [0.35, -0.35],
                                        [0.432, -0.236],
                                        [0.454, -0.071],
                                        [0.416, 0.121],
                                        [0.345, 0.344],
                                        [0.082, 0.542],
                                        [-0.236, 0.586],
                                        [-0.542, 0.542],
                                        [-0.438, 0.252],
                                        [-0.449, 0.077],
                                        [-0.416, -0.12],
                                    ],
                                    o: [
                                        [0.356, 0.356],
                                        [0.115, 0.422],
                                        [-0.087, 0.438],
                                        [-0.241, 0.417],
                                        [-0.383, 0.383],
                                        [-0.444, 0.236],
                                        [-0.455, 0.071],
                                        [-0.427, -0.119],
                                        [-0.416, -0.416],
                                        [-0.093, -0.542],
                                        [0.23, -0.58],
                                        [0.394, -0.394],
                                        [0.438, -0.252],
                                        [0.438, -0.076],
                                        [0.416, 0.12],
                                    ],
                                    v: [
                                        [0.594, -4.579],
                                        [1.318, -3.413],
                                        [1.374, -2.124],
                                        [0.891, -0.835],
                                        [0.003, 0.316],
                                        [-1.219, 1.243],
                                        [-2.566, 1.704],
                                        [-3.872, 1.629],
                                        [-5.031, 0.932],
                                        [-5.777, -0.506],
                                        [-5.563, -2.198],
                                        [-4.406, -3.881],
                                        [-3.157, -4.85],
                                        [-1.827, -5.343],
                                        [-0.546, -5.277],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 6',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 6,
                            ty: 'sh',
                            ix: 7,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0.191, 0.192],
                                        [0.115, 0.06],
                                        [0.29, -0.148],
                                        [0.29, -0.224],
                                        [0.236, -0.236],
                                        [0.208, -0.296],
                                        [0.115, -0.29],
                                        [-0.011, -0.252],
                                        [-0.181, -0.18],
                                        [-0.054, -0.044],
                                        [-0.071, -0.039],
                                        [-0.306, 0.154],
                                        [-0.295, 0.219],
                                        [-0.23, 0.23],
                                        [-0.213, 0.29],
                                        [-0.137, 0.301],
                                        [-0.005, 0.268],
                                    ],
                                    o: [
                                        [-0.088, -0.087],
                                        [-0.263, 0.011],
                                        [-0.302, 0.148],
                                        [-0.296, 0.219],
                                        [-0.268, 0.268],
                                        [-0.219, 0.295],
                                        [-0.121, 0.285],
                                        [0.005, 0.246],
                                        [0.038, 0.039],
                                        [0.05, 0.038],
                                        [0.246, -0.006],
                                        [0.301, -0.147],
                                        [0.291, -0.224],
                                        [0.224, -0.225],
                                        [0.214, -0.29],
                                        [0.131, -0.296],
                                        [-0.005, -0.268],
                                    ],
                                    v: [
                                        [-0.242, -4.021],
                                        [-0.546, -4.242],
                                        [-1.376, -4.003],
                                        [-2.262, -3.446],
                                        [-3.059, -2.764],
                                        [-3.773, -1.917],
                                        [-4.274, -1.04],
                                        [-4.438, -0.235],
                                        [-4.16, 0.406],
                                        [-4.02, 0.529],
                                        [-3.839, 0.644],
                                        [-3.011, 0.406],
                                        [-2.115, -0.145],
                                        [-1.334, -0.826],
                                        [-0.679, -1.598],
                                        [-0.152, -2.485],
                                        [0.053, -3.331],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 7',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [35.264, 114.265], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 5',
                    np: 9,
                    cix: 2,
                    bm: 0,
                    ix: 5,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0.097, -5.261],
                                        [5.122, -0.236],
                                        [4.055, 1.078],
                                        [-1.094, -4.07],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.088, -0.198],
                                        [0.109, -0.197],
                                        [0.115, -0.181],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-0.055, 0.175],
                                        [-0.082, 0.202],
                                        [-0.104, 0.203],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1.164, -6.329],
                                        [1.485, -6.107],
                                        [1.271, -5.548],
                                        [0.984, -4.949],
                                        [0.655, -4.374],
                                        [-0.003, -5.163],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-0.922, -4.243],
                                        [-0.331, -3.619],
                                        [-1.653, -2.658],
                                        [-2.047, -3.118],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.925, -2.387],
                                        [5.673, -2.182],
                                        [4.942, 0.898],
                                        [4.498, 0.717],
                                        [4.021, -0.17],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.548, 0.093],
                                        [4.942, 0.898],
                                        [4.055, 1.078],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: 'sh',
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.4, 0.4],
                                        [-0.372, 0.153],
                                        [-0.405, 0.011],
                                        [-0.405, -0.164],
                                        [-0.367, -0.366],
                                        [-0.218, -0.394],
                                        [-0.098, -0.461],
                                        [0.066, -0.504],
                                        [0.268, -0.52],
                                        [0.52, -0.52],
                                        [0, 0],
                                        [0, 0],
                                        [0.082, 0.389],
                                        [0.219, 0.373],
                                        [0.301, 0.301],
                                        [0.372, 0.186],
                                        [0.339, -0.022],
                                        [0.263, -0.295],
                                        [-0.087, -0.251],
                                        [-0.203, -0.203],
                                        [-0.214, -0.092],
                                        [-0.203, 0.016],
                                        [-0.148, 0.149],
                                        [-0.066, 0.142],
                                        [-0.033, 0.186],
                                        [0, 0],
                                        [0.148, -0.279],
                                        [0.154, -0.153],
                                        [0.361, -0.077],
                                        [0.367, 0.115],
                                        [0.307, 0.307],
                                        [0.12, 0.426],
                                        [-0.115, 0.454],
                                    ],
                                    o: [
                                        [0.296, -0.295],
                                        [0.367, -0.159],
                                        [0.399, -0.005],
                                        [0.405, 0.176],
                                        [0.295, 0.296],
                                        [0.225, 0.4],
                                        [0.099, 0.47],
                                        [-0.06, 0.51],
                                        [-0.269, 0.531],
                                        [0, 0],
                                        [0, 0],
                                        [0.115, -0.334],
                                        [-0.087, -0.383],
                                        [-0.219, -0.371],
                                        [-0.328, -0.329],
                                        [-0.367, -0.181],
                                        [-0.345, 0.027],
                                        [-0.06, 0.269],
                                        [0.088, 0.263],
                                        [0.18, 0.18],
                                        [0.208, 0.099],
                                        [0.197, -0.012],
                                        [0.088, -0.087],
                                        [0.06, -0.136],
                                        [0, 0],
                                        [-0.022, 0.405],
                                        [-0.153, 0.284],
                                        [-0.268, 0.268],
                                        [-0.356, 0.082],
                                        [-0.372, -0.109],
                                        [-0.323, -0.323],
                                        [-0.126, -0.421],
                                        [0.11, -0.449],
                                    ],
                                    v: [
                                        [-4.794, -0.7],
                                        [-3.792, -1.373],
                                        [-2.633, -1.628],
                                        [-1.426, -1.39],
                                        [-0.269, -0.577],
                                        [0.503, 0.458],
                                        [0.988, 1.748],
                                        [1.037, 3.209],
                                        [0.545, 4.753],
                                        [-0.639, 6.329],
                                        [-1.821, 5.147],
                                        [-0.179, 3.948],
                                        [-0.13, 2.864],
                                        [-0.59, 1.73],
                                        [-1.37, 0.721],
                                        [-2.421, -0.051],
                                        [-3.48, -0.289],
                                        [-4.392, 0.195],
                                        [-4.35, 0.975],
                                        [-3.915, 1.673],
                                        [-3.324, 2.083],
                                        [-2.708, 2.208],
                                        [-2.191, 1.968],
                                        [-1.961, 1.624],
                                        [-1.821, 1.14],
                                        [-1.115, 1.484],
                                        [-1.37, 2.511],
                                        [-1.829, 3.168],
                                        [-2.774, 3.685],
                                        [-3.857, 3.636],
                                        [-4.876, 3.011],
                                        [-5.541, 1.887],
                                        [-5.557, 0.573],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 6',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [109.02, 113.53], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 6',
                    np: 8,
                    cix: 2,
                    bm: 0,
                    ix: 6,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-1.084, 0],
                                        [-0.821, -0.356],
                                        [-0.464, -0.619],
                                        [0, -0.805],
                                        [0.326, -0.588],
                                        [0.62, -0.449],
                                        [0.898, -0.264],
                                        [0, 0],
                                        [-0.356, 0.589],
                                        [0, 0.588],
                                        [0.418, 0.496],
                                        [0.805, 0],
                                        [0.248, -0.047],
                                        [0.263, -0.078],
                                        [0.093, -0.263],
                                        [0, -0.294],
                                        [-0.233, -0.372],
                                        [-0.495, -0.372],
                                        [-0.805, -0.418],
                                        [0, 0],
                                        [-0.48, -0.635],
                                        [0, -0.805],
                                        [0.387, -0.619],
                                        [0.774, -0.386],
                                        [1.069, 0],
                                        [0.712, 0.34],
                                        [0.388, 0.572],
                                        [0, 0.665],
                                        [-0.542, 0.728],
                                        [-1.146, 0.48],
                                        [0, 0],
                                        [0.263, -0.341],
                                        [0.093, -0.31],
                                        [0, -0.232],
                                        [-0.418, -0.418],
                                        [-0.651, 0],
                                        [-0.217, 0.046],
                                        [-0.217, 0.093],
                                        [-0.109, 0.279],
                                        [0, 0.247],
                                        [0.309, 0.356],
                                        [0.511, 0.294],
                                        [0.557, 0.31],
                                        [0.634, 0.449],
                                        [0.403, 0.588],
                                        [0, 0.821],
                                        [-0.464, 0.666],
                                        [-0.836, 0.372],
                                    ],
                                    o: [
                                        [1.069, 0],
                                        [0.82, 0.356],
                                        [0.465, 0.619],
                                        [0, 0.666],
                                        [-0.34, 0.589],
                                        [-0.634, 0.465],
                                        [0, 0],
                                        [0.713, -0.357],
                                        [0.356, -0.588],
                                        [0, -0.666],
                                        [-0.433, -0.48],
                                        [-0.201, 0],
                                        [-0.247, 0.046],
                                        [-0.201, 0.34],
                                        [-0.093, 0.279],
                                        [0, 0.464],
                                        [0.232, 0.371],
                                        [0.496, 0.371],
                                        [0, 0],
                                        [0.852, 0.496],
                                        [0.48, 0.651],
                                        [0, 0.743],
                                        [-0.402, 0.635],
                                        [-0.774, 0.387],
                                        [-1.037, 0],
                                        [-0.728, -0.34],
                                        [-0.387, -0.573],
                                        [0, -0.743],
                                        [0.526, -0.712],
                                        [0, 0],
                                        [-0.542, 0.325],
                                        [-0.264, 0.34],
                                        [-0.092, 0.325],
                                        [0, 0.619],
                                        [0.403, 0.418],
                                        [0.201, 0],
                                        [0.201, -0.032],
                                        [0.232, -0.279],
                                        [0.093, -0.279],
                                        [0, -0.479],
                                        [-0.31, -0.357],
                                        [-0.511, -0.293],
                                        [-0.697, -0.372],
                                        [-0.635, -0.45],
                                        [-0.402, -0.589],
                                        [0, -0.852],
                                        [0.465, -0.65],
                                        [0.821, -0.372],
                                    ],
                                    v: [
                                        [0.023, -8.001],
                                        [2.857, -7.467],
                                        [4.784, -6.004],
                                        [5.481, -3.867],
                                        [4.993, -1.986],
                                        [3.553, -0.43],
                                        [1.254, 0.662],
                                        [0.046, -0.662],
                                        [1.649, -2.079],
                                        [2.183, -3.844],
                                        [1.556, -5.586],
                                        [-0.302, -6.306],
                                        [-0.976, -6.236],
                                        [-1.742, -6.05],
                                        [-2.183, -5.145],
                                        [-2.323, -4.285],
                                        [-1.974, -3.031],
                                        [-0.883, -1.916],
                                        [1.068, -0.732],
                                        [2.322, -0.035],
                                        [4.32, 1.66],
                                        [5.04, 3.844],
                                        [4.459, 5.888],
                                        [2.694, 7.42],
                                        [-0.07, 8.001],
                                        [-2.694, 7.49],
                                        [-4.367, 6.12],
                                        [-4.947, 4.262],
                                        [-4.134, 2.055],
                                        [-1.626, 0.267],
                                        [-0.256, 1.289],
                                        [-1.463, 2.288],
                                        [-1.998, 3.263],
                                        [-2.137, 4.099],
                                        [-1.51, 5.655],
                                        [0.07, 6.282],
                                        [0.697, 6.213],
                                        [1.324, 6.027],
                                        [1.835, 5.191],
                                        [1.974, 4.401],
                                        [1.51, 3.147],
                                        [0.279, 2.171],
                                        [-1.324, 1.266],
                                        [-3.321, 0.035],
                                        [-4.878, -1.521],
                                        [-5.481, -3.635],
                                        [-4.785, -5.911],
                                        [-2.834, -7.444],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [72.579, 116.675], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 7',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 7,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.293, 0.67],
                                        [0.296, 0.385],
                                        [0.335, 0.194],
                                        [0.38, 0.022],
                                        [0.366, -0.2],
                                        [0.286, -0.497],
                                        [0.151, -0.449],
                                        [0.069, -0.461],
                                        [0, 0],
                                        [-0.246, 0.985],
                                        [-0.453, 0.722],
                                        [-0.72, 0.443],
                                        [-0.786, 0.029],
                                        [-0.71, -0.41],
                                        [-0.372, -0.501],
                                        [-0.294, -0.76],
                                        [-0.318, -1.06],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [-0.32, -1.026],
                                        [-0.285, -0.683],
                                        [-0.302, -0.407],
                                        [-0.322, -0.185],
                                        [-0.372, -0.037],
                                        [-0.366, 0.2],
                                        [-0.155, 0.268],
                                        [-0.144, 0.435],
                                        [0, 0],
                                        [-0.013, -0.938],
                                        [0.241, -1.005],
                                        [0.493, -0.823],
                                        [0.707, -0.45],
                                        [0.794, -0.043],
                                        [0.523, 0.302],
                                        [0.359, 0.493],
                                        [0.289, 0.739],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [3.532, 7.523],
                                        [1.671, 6.986],
                                        [-0.239, 1.003],
                                        [-1.158, -1.54],
                                        [-2.029, -3.143],
                                        [-2.985, -4.044],
                                        [-4.038, -4.355],
                                        [-5.145, -4.11],
                                        [-6.123, -3.065],
                                        [-6.583, -1.991],
                                        [-6.903, -0.647],
                                        [-8.675, 0.611],
                                        [-8.325, -2.273],
                                        [-7.285, -4.863],
                                        [-5.465, -6.762],
                                        [-3.226, -7.48],
                                        [-0.97, -6.929],
                                        [0.373, -5.725],
                                        [1.353, -3.845],
                                        [2.263, -1.148],
                                        [3.593, 3.562],
                                        [3.8, 3.576],
                                        [6.316, -2.454],
                                        [8.549, -1.165],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.503, -0.523],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.635, -0.51],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.567, 0.38],
                                        [0.635, 0.51],
                                    ],
                                    v: [
                                        [8.688, -1.406],
                                        [6.545, 1.19],
                                        [4.055, -3.089],
                                        [5.178, -4.292],
                                        [6.981, -2.955],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.339, 0.124],
                                        [0.351, 0.167],
                                        [0.316, 0.165],
                                        [0.239, 0.174],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-0.285, -0.094],
                                        [-0.345, -0.146],
                                        [-0.351, -0.167],
                                        [-0.322, -0.186],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-3.783, 0.591],
                                        [-4.852, 2.443],
                                        [-5.789, 2.116],
                                        [-6.833, 1.647],
                                        [-7.833, 1.149],
                                        [-8.675, 0.611],
                                        [-6.804, -2.306],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [37.047, 56.784], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 8',
                    np: 5,
                    cix: 2,
                    bm: 0,
                    ix: 8,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.52, 0.706],
                                        [-0.223, 0.539],
                                        [0, 0.464],
                                        [0.204, 0.409],
                                        [0.427, 0.26],
                                        [0.687, 0],
                                        [0.558, -0.111],
                                        [0.52, -0.204],
                                        [0, 0],
                                        [-1.171, 0.335],
                                        [-1.022, -0.038],
                                        [-0.892, -0.483],
                                        [-0.501, -0.799],
                                        [0, -0.985],
                                        [0.297, -0.687],
                                        [0.613, -0.762],
                                        [0.91, -0.966],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0.873, -0.947],
                                        [0.539, -0.706],
                                        [0.242, -0.557],
                                        [0, -0.446],
                                        [-0.186, -0.408],
                                        [-0.427, -0.26],
                                        [-0.372, 0],
                                        [-0.539, 0.112],
                                        [0, 0],
                                        [0.966, -0.576],
                                        [1.189, -0.353],
                                        [1.152, 0.018],
                                        [0.892, 0.465],
                                        [0.521, 0.799],
                                        [0, 0.725],
                                        [-0.297, 0.669],
                                        [-0.594, 0.743],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-14.186, 9.174],
                                        [-14.744, 6.917],
                                        [-9.671, 1.342],
                                        [-7.581, -1.138],
                                        [-6.438, -3.005],
                                        [-6.076, -4.538],
                                        [-6.382, -5.821],
                                        [-7.302, -6.824],
                                        [-8.974, -7.214],
                                        [-10.368, -7.047],
                                        [-11.956, -6.573],
                                        [-14.325, -7.659],
                                        [-11.12, -9.026],
                                        [-7.804, -9.499],
                                        [-4.738, -8.747],
                                        [-2.648, -6.852],
                                        [-1.867, -4.176],
                                        [-2.313, -2.058],
                                        [-3.679, 0.088],
                                        [-5.936, 2.652],
                                        [-10.033, 6.861],
                                        [-9.922, 7.084],
                                        [-2.146, 6.08],
                                        [-2.146, 9.174],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0.241, -0.836],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.149, -0.967],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.055, 0.818],
                                        [-0.149, 0.966],
                                    ],
                                    v: [
                                        [-1.811, 9.174],
                                        [-5.797, 8.505],
                                        [-2.843, 3.349],
                                        [-0.92, 3.796],
                                        [-1.226, 6.471],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.074, 0.427],
                                        [0.038, 0.465],
                                        [0.018, 0.428],
                                        [-0.038, 0.353],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-0.075, -0.354],
                                        [-0.056, -0.446],
                                        [-0.037, -0.464],
                                        [0, -0.445],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-11.371, -2.586],
                                        [-13.935, -2.586],
                                        [-14.158, -3.758],
                                        [-14.298, -5.124],
                                        [-14.381, -6.462],
                                        [-14.325, -7.659],
                                        [-10.173, -7.464],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [1.171, 0],
                                        [0.929, 0.502],
                                        [0.613, 0.873],
                                        [0.316, 1.115],
                                        [0, 1.19],
                                        [-0.335, 1.134],
                                        [-0.651, 0.891],
                                        [-0.911, 0.502],
                                        [-1.17, 0],
                                        [-1.059, -0.78],
                                        [-0.595, -1.393],
                                        [0, -1.839],
                                        [0.316, -1.171],
                                        [0.632, -0.891],
                                        [0.91, -0.502],
                                    ],
                                    o: [
                                        [-1.207, 0],
                                        [-0.91, -0.52],
                                        [-0.595, -0.892],
                                        [-0.297, -1.114],
                                        [0, -1.3],
                                        [0.353, -1.152],
                                        [0.65, -0.892],
                                        [0.929, -0.52],
                                        [1.412, 0],
                                        [1.078, 0.762],
                                        [0.594, 1.375],
                                        [0, 1.338],
                                        [-0.316, 1.17],
                                        [-0.613, 0.874],
                                        [-0.911, 0.502],
                                    ],
                                    v: [
                                        [7.442, 9.536],
                                        [4.237, 8.784],
                                        [1.952, 6.694],
                                        [0.586, 3.684],
                                        [0.14, 0.227],
                                        [0.642, -3.424],
                                        [2.147, -6.488],
                                        [4.488, -8.58],
                                        [7.637, -9.36],
                                        [11.344, -8.19],
                                        [13.853, -4.957],
                                        [14.744, -0.135],
                                        [14.271, 3.628],
                                        [12.849, 6.721],
                                        [10.564, 8.784],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.65, 0],
                                        [-0.297, 0.093],
                                        [-0.241, 0.744],
                                        [-0.112, 0.873],
                                        [0, 0.799],
                                        [0.148, 0.854],
                                        [0.297, 0.688],
                                        [0.446, 0.409],
                                        [0.613, 0],
                                        [0.167, -0.019],
                                        [0.186, -0.055],
                                        [0.26, -0.781],
                                        [0.13, -0.874],
                                        [0, -0.78],
                                        [-0.13, -0.855],
                                        [-0.279, -0.744],
                                        [-0.446, -0.464],
                                    ],
                                    o: [
                                        [0.297, 0],
                                        [0.427, -0.465],
                                        [0.26, -0.761],
                                        [0.13, -0.874],
                                        [0, -0.91],
                                        [-0.13, -0.874],
                                        [-0.279, -0.687],
                                        [-0.428, -0.409],
                                        [-0.13, 0],
                                        [-0.149, 0.018],
                                        [-0.408, 0.428],
                                        [-0.26, 0.762],
                                        [-0.112, 0.873],
                                        [0, 0.762],
                                        [0.13, 0.855],
                                        [0.279, 0.724],
                                        [0.464, 0.446],
                                    ],
                                    v: [
                                        [7.916, 7.167],
                                        [8.808, 7.029],
                                        [9.811, 5.216],
                                        [10.369, 2.764],
                                        [10.564, 0.255],
                                        [10.341, -2.392],
                                        [9.7, -4.734],
                                        [8.613, -6.378],
                                        [7.052, -6.991],
                                        [6.606, -6.963],
                                        [6.104, -6.852],
                                        [5.101, -5.04],
                                        [4.516, -2.586],
                                        [4.349, -0.107],
                                        [4.544, 2.319],
                                        [5.157, 4.715],
                                        [6.244, 6.498],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [72.085, 79.083], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 9',
                    np: 7,
                    cix: 2,
                    bm: 0,
                    ix: 9,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-10.027, -3.384],
                                        [2.283, -10.491],
                                        [4.268, -7.749],
                                        [-8.343, -0.468],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [-0.335, -0.271],
                                        [-0.324, -0.313],
                                        [-0.289, -0.315],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0.31, 0.197],
                                        [0.349, 0.263],
                                        [0.337, 0.306],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-11.537, -5.999],
                                        [-10.943, -6.503],
                                        [-9.975, -5.801],
                                        [-8.965, -4.936],
                                        [-8.025, -4.004],
                                        [-9.888, -3.143],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-8.587, -0.89],
                                        [-7.076, -1.709],
                                        [-5.904, 1.342],
                                        [-6.996, 1.866],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-1.976, -11.224],
                                        [-1.192, -12.561],
                                        [4.378, -9.556],
                                        [3.8, -8.792],
                                        [1.84, -8.331],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2.62, -9.211],
                                        [4.378, -9.556],
                                        [4.268, -7.749],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: 'sh',
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-3.152, 0.953],
                                        [-1.883, -0.612],
                                        [10.507, 1.059],
                                        [11.073, 2.69],
                                        [8.838, 3.604],
                                        [8.975, 2.587],
                                        [-1.085, 1.931],
                                        [-0.879, 0.847],
                                        [4.881, 10.314],
                                        [3.508, 11.187],
                                        [2.596, 10.909],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 6',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 6,
                            ty: 'sh',
                            ix: 7,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [-0.498, -0.428],
                                        [-0.413, -0.405],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0.476, 0.297],
                                        [0.497, 0.428],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-4.584, 6.044],
                                        [-3.99, 5.54],
                                        [-2.53, 6.628],
                                        [-1.165, 7.878],
                                        [-3.028, 8.739],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 7',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 7,
                            ty: 'sh',
                            ix: 8,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-2.413, 9.805],
                                        [-0.901, 8.986],
                                        [0.271, 12.037],
                                        [-0.822, 12.561],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 8',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 8,
                            ty: 'sh',
                            ix: 9,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-3.295, 8.276],
                                        [5.314, 3.306],
                                        [8.128, 2.003],
                                        [10.014, 1.182],
                                        [11.537, 3.494],
                                        [-1.658, 11.112],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 9',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [104.935, 55.569], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 10',
                    np: 11,
                    cix: 2,
                    bm: 0,
                    ix: 10,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, -57.484],
                                        [41.806, 13.355],
                                        [-41.806, 13.355],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 55.161],
                                        [41.806, 13.355],
                                        [-41.806, 13.355],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: 'sh',
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 57.484],
                                        [42.387, 12.774],
                                        [61.548, 20.903],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 3',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: 'sh',
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 57.484],
                                        [-42.387, 12.774],
                                        [-61.548, 20.903],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 4',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: 'sh',
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60.387, -48.774],
                                        [0, -57.484],
                                        [41.226, 12.774],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 5',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: 'sh',
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-60.387, -48.774],
                                        [0, -57.484],
                                        [-41.226, 12.774],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 6',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 6,
                            ty: 'sh',
                            ix: 7,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, -57.484],
                                        [0, -86.516],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 7',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'mm',
                            mm: 1,
                            nm: 'Merge Paths 1',
                            mn: 'ADBE Vector Filter - Merge',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.323, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [72, 86.516], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 11',
                    np: 9,
                    cix: 2,
                    bm: 0,
                    ix: 11,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0.581, -70.684],
                                        [0, -71.019],
                                        [-0.581, -70.684],
                                        [-60.923, -35.845],
                                        [-61.504, -35.509],
                                        [-61.504, 35.508],
                                        [-60.923, 35.843],
                                        [-0.581, 70.683],
                                        [0, 71.019],
                                        [0.581, 70.683],
                                        [60.924, 35.843],
                                        [61.504, 35.508],
                                        [61.504, -35.509],
                                        [60.924, -35.845],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [
                                    0.090196078431, 0.094117654539,
                                    0.109803929048, 1,
                                ],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.323, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.16862745098, 0.172549019608,
                                    0.215686289469, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [72, 72], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 12',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 12,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 180.00000733155,
            st: 0,
            ct: 1,
            bm: 0,
        },
    ],
    markers: [],
};
