/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { ReactComponent as IconPlayer } from "../../assets/icons/icon_more.svg";
import { strings } from "../../services/translation";
import { vertALEXTESGRANDSMORTS } from "../../style/colors";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";
import { rowCenterStyles } from "../../style/flex";
import { normalizeSize } from "../../utils/fontSizer";

interface Props {
    callback: any;
    collapsed: boolean;
}

const joinBtn = (props: Props) => {
    const { callback, collapsed } = props;
    if (collapsed) {
        return (
            <ButtonBase sx={styles.collapsed} onClick={() => callback(true)}>
                <IconPlayer
                    width={40}
                    height={40}
                    fill={vertALEXTESGRANDSMORTS}
                />
            </ButtonBase>
        );
    } else {
        return (
            <ButtonBase sx={styles.joinBtn} onClick={() => callback(true)}>
                <IconPlayer
                    width={40}
                    height={40}
                    fill={vertALEXTESGRANDSMORTS}
                />
                <Box>
                    <Typography sx={styles.joinText}>
                        {strings.joinGame}
                    </Typography>
                </Box>
            </ButtonBase>
        );
    }
};

const styles = {
    joinText: {
        color: Colors.WHITE,
        display: "block",
        fontFamily: Gilroy,
        fontWeight: 700,
        fontSize: "16px",
        marginLeft: "10px",
    },
    collapsed: {
        width: "50px",
        height: "50px",
        borderRadius: "11px",
        backgroundColor: Colors.BTN_ACTIVE,
    },
    joinBtn: {
        textDecoration: "none",
        minWidth: normalizeSize(228.5),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: "13px",
        padding: "5px 12px",
        ...rowCenterStyles,
        justifySelf: "end",
    },
};
export default joinBtn;
