/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getLanguage, strings } from "../../services/translation";
import moment from "moment";
import Heart from "../heart";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { getRouteManager } from "../../services/routeManager";
import { Box, ButtonBase, Typography } from "@mui/material";
import "./style.css";

interface Props {
  article: any;
}

const NewsBtn = (props: Props) => {
  const { article } = props;

  const startingHeight = 0;
  const [expander, setExpander] = useState(false);
  const [expanded, setExpanded] = useState<any>(false);
  const [fullHeight, setFullHeight] = useState(startingHeight);
  const [animatedHeight, setAnimatedHeight] = useState(startingHeight);

  const [nbExpand, setNbExpand] = useState(0);

  useEffect(() => {
    // expanded?setText(props.text): setText(props.text.substring(0, 40));
    setNbExpand((nbExpand) => nbExpand + 1);
    setAnimatedHeight(expanded ? fullHeight : startingHeight);
  }, [expanded]);

  const onTextLayout = (e: any) => {
    let { height } = e.nativeEvent.layout;
    height = Math.floor(height) + 40;
    if (height > startingHeight) {
      setFullHeight(height);
      setExpander(true);
    }
  };

  const date =
    getLanguage() === "fr"
      ? moment(article.date).format("DD/MM/YYYY")
      : moment(article.date).format("MM/DD/YYYY");

  return (
    <Box style={styles.block}>
      <Box
        style={{
          backgroundColor: Colors.BTN_ACTIVE,
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        {article.filename && (
          <ButtonBase
            disabled={!article.link || article.link === ""}
            onClick={() => {
              window.open(article.link, "_blank")?.focus();
            }}
            style={{
              width: "100%",
              height: "250px",
              overflow: "hidden",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              backgroundColor: Colors.BTN_ACTIVE,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, ${
                Colors.BTN_ACTIVE
              } 92.14%),
              url(${getRouteManager().getNewsImage(article.id)}) 50%/cover`,
            }}
          >
            {/*  <img
              src={getRouteManager().getNewsImage(article.id)}
              style={{
                backgroundColor: Colors.BTN_ACTIVE,
                flex: 1,
                justifyContent: "flex-end",
                backgroundSize: "cover",
              }}
            /> */}
          </ButtonBase>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            padding: "15px",
          }}
        >
          <Box
            style={{
              flex: 1,
              padding: "5px 5px 0px 0px",
            }}
          >
            <Typography style={styles.titleText}>{article.title}</Typography>
            <Typography style={styles.date}>{date}</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heart
              defaultLiked={false}
              newsId={article.id}
              likeNumber={article.likes}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.containerText}>
        {expanded ? (
          <Box sx={styles.textBox}>
            <Typography whiteSpace={"pre-wrap"} style={styles.content}>{`${article.text}`}</Typography>
          </Box>
        ) : null}
        <ButtonBase
          style={styles.readMoreBlock}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography style={styles.readMoreText}>
            {expanded ? strings.readLess : strings.readMore}
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

const styles = {
  block: {
    backgroundColor: Colors.BTN_ACTIVE,
    borderRadius: 20,
    marginBottom: 15,
    width: "450px",
  },
  titleText: {
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    fontSize: normalize(16),
    marginBottom: "5px",
  },
  date: {
    fontFamily: "Gilroy-Bold",
    color: Colors.FOND_ITEM_POPUP,
    fontSize: normalize(12),
  },
  content: {
    fontFamily: "Gilroy-Bold",
    color: Colors.SECONDARY_TEXT,
    fontSize: normalize(14),
    marginTop: "5px",
    padding: "0px 15px",
  },
  likedText: {
    fontFamily: "Gilroy-Bold",
    color: Colors.SECONDARY_TEXT,
    fontSize: normalize(12),
    marginTop: "9px",
  },
  containerText: {
    flex: 1,
    backgroundColor: Colors.BTN_ACTIVE,
    borderTop: `solid 3px `,
    borderColor: Colors.PRIMARY,
    borderBottomRightRadius: "20px",
    borderBottomLeftRadius: "20px",
    position: "relative",
  },
  viewPort: {
    borderBottomRightRadius: "13px",
    borderBottomLeftRadius: "13px",
    flex: 1,
  },
  textBox: {
    display: "flex",
    margin: "15px 0px",
    flex: 1,
  },
  readMoreBlock: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "100%",
  },
  readMoreText: {
    color: Colors.ACCENT,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(14),
  },
};

export default NewsBtn;
