/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import { Colors } from "../../style";
import { columnStyles, rowStyles } from "../../style/flex";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import normalize from "../../utils/fontSizer";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon_achievement.svg";
import { isPositiveNumber } from "../../utils/NumberHelper";

interface Props {
    active: any;
}

const SuccessInfos = (props: Props) => {
    const { active } = props;

    return (
        <Box sx={styles.infoContainer}>
            {isTrueBoolean(active.isSaga) ? (
                <Box sx={styles.progressBar}>
                    {active.nbDone === active.nbSuccess ? (
                        <Box sx={styles.achievementNumber}>
                            <IconSuccess
                                width={26}
                                height={26}
                                fill={Colors.ACCENT}
                                style={{ marginRight: 5 }}
                            />
                            <Typography sx={styles.successUnlocked}>
                                {active.nbDone ? active.nbDone : "0"}/
                                {active.nbSuccess ? active.nbSuccess : "0"}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={styles.achievementNumber}>
                            <IconSuccess
                                width={26}
                                height={26}
                                fill={Colors.OLD_PRICE}
                                style={{ marginRight: 5 }}
                            />
                            <Typography
                                component={"span"}
                                sx={styles.successLocked}>
                                {active.nbDone ? (
                                    <Typography
                                        component={"span"}
                                        style={styles.successUnlocked}>
                                        {active.nbDone}
                                    </Typography>
                                ) : (
                                    "0"
                                )}
                                /{active.nbSuccess ? active.nbSuccess : "0"}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : isPositiveNumber(active.nbSuccess) ? (
                <Box style={styles.progressBar}>
                    {active.nbUnlock === active.nbSuccess ? (
                        <Box style={styles.achievementNumber}>
                            <IconSuccess
                                width={26}
                                height={26}
                                fill={Colors.ACCENT}
                                style={{ marginRight: 5 }}
                            />

                            <Typography
                                component={"span"}
                                style={styles.successUnlocked}>
                                {active.nbUnlock ? (
                                    <Typography
                                        component={"span"}
                                        style={styles.successUnlocked}>
                                        {active.nbUnlock}
                                    </Typography>
                                ) : (
                                    "0"
                                )}
                                /{active.nbSuccess}
                            </Typography>
                        </Box>
                    ) : (
                        <Box style={styles.achievementNumber}>
                            <IconSuccess
                                width={26}
                                height={26}
                                fill={Colors.OLD_PRICE}
                                style={{ marginRight: 5 }}
                            />

                            <Typography
                                component={"span"}
                                style={styles.successLocked}>
                                {active.nbUnlock ? (
                                    <Typography
                                        component={"span"}
                                        style={styles.successUnlocked}>
                                        {active.nbUnlock}
                                    </Typography>
                                ) : (
                                    "0"
                                )}
                                /{active.nbSuccess}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

const styles = {
    infoContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: "20px",
    },
    iconInfo: {
        color: Colors.TOKEN,
        fontSize: normalize(16),
        fontFamily: "Gilroy-Bold",
        marginLeft: "7px",
    },
    progressBar: {
        ...columnStyles,
        alignItems: "flex-start",
        justifyContent: "center",
    },
    achievementNumber: {
        ...rowStyles,
        alignItems: "center",
        justifyContent: "flex-start",
    },
    successUnlocked: {
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
    },
    successLocked: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
    },
};

export default SuccessInfos;
