import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { isNotEmptyString } from "../../utils/StringHelper";
import { FTUEModalLibrary } from "./FTUEModalLibrary";
import { FTUEModalShop } from "./FTUEModalShop";
import { FTUEModalRewards } from "./FTUEModalRewards";
import { FTUEModalSocial } from "./FTUEModalSocial";
import { FTUEModalOnline } from "./FTUEModalOnline";
import { FTUEModalGame } from "./FTUEModalGame";
import { styles } from "./style";
import { strings } from "../../services/translation";
import ModalButton from "../ModalButton";

const FTUEModals = ({ modal }: { modal: string }) => {
    /**
     *
     * STATES
     *
     */
    const [modalVisible, setModalVisible] = useState(false);

    /**
     *
     * USE EFFECTS
     *
     */

    useEffect(() => {
        checkShowModal();
    }, []);

    /**
     *
     * FUNCTIONS
     *
     */

    /**
     * @getLocalItem
     * Returns the item name to check in localStorage
     * @param item
     * @returns
     */
    const getLocalItem = (item: string) => {
        return localStorage.getItem(`FTUE_modal_${item}`);
    };

    /**
     * @getLocalItemName
     * Returns the item name to check in localStorage
     * @param item
     * @returns
     */
    const getLocalItemName = (item: string) => {
        return `FTUE_modal_${item}`;
    };

    /**
     * @checkShowModal
     * Check if need to show props modal
     * (if not in local storage then show)
     */
    const checkShowModal = () => {
        if (isNotEmptyString(modal)) {
            if (!getLocalItem(modal)) {
                setModalVisible(true);
            }
        }
    };

    /**
     * @handleCloseModal
     * Close modal + set item in localStorage
     */
    const handleCloseModal = () => {
        if (isNotEmptyString(modal)) {
            const item = getLocalItemName(modal);
            if (item && isNotEmptyString(item)) {
                localStorage.setItem(item, "true");
            }
        }
        if (modalVisible) {
            setModalVisible(false);
        }
    };

    /**
     *
     * RENDER
     *
     */

    /**
     * renderModal
     * @returns Appropriate Modal or null
     */
    const renderModal = () => {
        if (isNotEmptyString(modal) && modalVisible) {
            switch (modal) {
                case "library":
                    return <FTUEModalLibrary />;
                case "shop":
                    return <FTUEModalShop />;
                case "rewards":
                    return <FTUEModalRewards />;
                case "social":
                    return <FTUEModalSocial />;
                case "online":
                    return <FTUEModalOnline />;
                case "game":
                    return <FTUEModalGame />;
                default:
                    return null;
            }
        }
        return null;
    };

    return (
        <Box sx={styles.modalContainer}>
            <Modal open={modalVisible} onClose={() => null}>
                <Box sx={styles.modalInsideContainer}>
                    {renderModal()}
                    <ModalButton
                        callback={handleCloseModal}
                        text={strings.understood}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default FTUEModals;
