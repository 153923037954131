/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CircularProgress } from "@mui/material";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";

interface Props {
  percent: number;
  color: string;
  bgColor: string;
  size?: number;
  thickness?: number;
  children: any;
}

const CircularProgressNew = (props: Props) => {
  const { percent, color, bgColor, size, thickness, children } = props;


  return (
    <Box
      sx={{
        position: "relative",
        ...rowCenterStyles,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: bgColor,
        }}
        size={size ? size : 72}
        thickness={thickness ? thickness : 4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        size={size ? size : 72}
        thickness={thickness ? thickness : 4}
        value={percent}
        sx={{
          position: "absolute",
          left: 0,
          color: color,
          strokeLinecap: "round",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          /* top: '2px',
          right: 0,
          bottom: '0px' */
          ...columnCenterStyles,
         /*  margin: "2px 0px 0px 2px", */
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CircularProgressNew;
