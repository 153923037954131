/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography, ButtonBase } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { rewardTicketJson } from "../../constants/rewardTicket";
import useAuth from "../../services/Auth/useAuth";
import SoundManager from "../../services/SoundManager";
import { getRouteManager } from "../../services/routeManager";
import { strings, getLanguage } from "../../services/translation";
import { Colors } from "../../style";
import {
  columnCenterStyles,
  rowCenterStyles,
  rowStartStyles,
  rowStyles,
} from "../../style/flex";
import normalize from "../../utils/fontSizer";
import LoadingView from "../LoadingView";
import TicketAnimated from "../TicketAnimated";
import TicketPromoAnimated from "../TicketPromoAnimated";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_removeplayer.svg";
import { rewardStoryJson } from "../../constants/rewardStory";
import GradientText from "../GradientText";
import GradientBorderBox from "../GradientBorderBox";
import { getEventManager } from "../../services/EventManager";
import PassBtn from "../PassBtn";
import PassAnimated from "../PassAnimated";

const BuySeriesModal = ({
  series,
  episodes,
  priceCount,
  priceNormalCount,
  remainingEpisodes,
  closeModal,
  refreshEpisodes,
  style,
  hasCloseBtn = true,
  isFromModal = false,
}: {
  series: any;
  episodes: any;
  priceCount: any;
  priceNormalCount: any;
  remainingEpisodes: any;
  closeModal: any;
  refreshEpisodes: any;
  style?: any;
  hasCloseBtn?: boolean;
  isFromModal?: boolean;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPopUpBuyTickets, setShowPopUpBuyTickets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopUpCongrats, setShowPopUpCongrats] = useState(false);

  /**
   * purchaseAllEpisodes : purchase all episodes and get response or error
   * @param story
   */
  const purchaseAllEpisodes = async () => {
    SoundManager.getInstance().playBtnSound();
    if (auth.user && episodes) {
      if (priceCount > 0) {
        setLoading(true);
        const api = getRouteManager().purchaseSeries();
        const purchase = await getRouteManager().fetchData(api, {
          userId: auth.user.id,
          login_token: auth.user.login_token,
          seriesId: series.id,
          price: priceCount,
          remainingEpisodes: remainingEpisodes,
        });
        if (
          purchase.flashmessage &&
          purchase.flashmessage === "already_exists"
        ) {
          alert(strings.alreadyExists);
          setLoading(false);
        } else if (
          purchase.flashmessage &&
          purchase.flashmessage === "not_enougth_tickets"
        ) {
          setLoading(false);
          SoundManager.getInstance().playOpenModalSound();
          setShowPopUpBuyTickets(true);
        } else {
          const tmp = await getRouteManager().fetchGetUserInfos(auth.user);
          auth.setUser(tmp);
          SoundManager.getInstance().playUnlockStorySound();
          await refreshEpisodes(series);
          setLoading(false);
          getEventManager().logSeriesPurchase(series?.id, priceCount);
          setShowPopUpCongrats(true);
        }
      }
    }
  };

  const goToShop = () => {
    navigate("/shop");
  };

  const goToLibrary = (id?: number) => {
    navigate(id ? `/${id}` : `/`);
  };

  if (loading) {
    return <LoadingView />;
  } else {
    return (
      <Box sx={[styles.container, style ? style : {}]}>
        {showPopUpBuyTickets ? (
          <Box sx={styles.container}>
            <Player
              src={rewardTicketJson}
              loop
              autoplay
              speed={1.5}
              style={{ width: "130px", height: "130px" }}
            />

            <Typography
              sx={{
                color: Colors.SECONDARY_TEXT,
                fontFamily: "Gilroy-Bold",
                fontSize: normalize(14),
                width: "250px",
                marginTop: "19px",
                textAlign: "center",
              }}
            >
              {showPopUpBuyTickets
                ? strings.notEnougthTickets
                : strings.notEnoughTokens}
            </Typography>

            <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
              {showPopUpBuyTickets ? (
                <ButtonBase
                  sx={styles.noButton}
                  onClick={() => {
                    SoundManager.getInstance().playCloseModalSound();
                    setShowPopUpBuyTickets(false);
                  }}
                >
                  <Typography sx={styles.noText}>{strings.notNow}</Typography>
                </ButtonBase>
              ) : null}

              <ButtonBase
                sx={styles.yesButton}
                onClick={() => {
                  SoundManager.getInstance().playBtnSound();
                  showPopUpBuyTickets
                    ? goToShop()
                    : setShowPopUpBuyTickets(false);
                }}
              >
                <Typography sx={styles.yesText}>
                  {showPopUpBuyTickets ? strings.goToShop : strings.ok}
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        ) : showPopUpCongrats ? (
          <Box sx={styles.container}>
            <Player
              src={rewardStoryJson}
              loop
              autoplay
              speed={1.5}
              style={{ width: "130px", height: "130px" }}
            />
            <Typography
              sx={{
                color: Colors.SECONDARY_TEXT,
                fontFamily: "Gilroy-Bold",
                fontSize: normalize(14),
                width: "250px",
                marginTop: "19px",
                textAlign: "center",
              }}
            >
              {strings.greatChoice}
            </Typography>
            <Box sx={[styles.popupButtons, { marginTop: "25px" }]}>
              <ButtonBase
                sx={styles.yesButton}
                onClick={() => {
                  SoundManager.getInstance().playBtnSound();
                  if (isFromModal) {
                    closeModal();
                  } else {
                    goToLibrary(series.id);
                  }
                }}
              >
                <Typography sx={styles.yesText}>
                  {isFromModal ? strings.great : strings.showMe}
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        ) : (
          <Box sx={{ ...columnCenterStyles }}>
            {hasCloseBtn ? (
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <ButtonBase
                  sx={{ borderRadius: "50px" }}
                  onClick={() => {
                    SoundManager.getInstance().playCloseModalSound();
                    closeModal();
                  }}
                >
                  <CloseIcon width={50} height={50} />
                </ButtonBase>
              </Box>
            ) : null}

            <img
              src={getRouteManager().titleForSeries(getLanguage(), series.id)}
              alt="story-title"
              style={{ width: "100%" }}
            />
            {remainingEpisodes ? (
              <GradientBorderBox
                color1={Colors.ACCENT}
                color2={Colors.MENU_ICON}
                borderRadius={100}
                style={{ ...columnCenterStyles, padding: "8px 20px" }}
                backgroundColor={Colors.PRIMARY}
                borderWidth={2}
              >
                <GradientText
                  style={{
                    fontSize: normalize(12),
                    fontFamily: "Gilroy-Bold",
                  }}
                  color1={Colors.ACCENT}
                  color2={Colors.MENU_ICON}
                >
                  {remainingEpisodes} {strings.episodes}
                </GradientText>
              </GradientBorderBox>
            ) : null}
            <Typography
              style={{
                color: Colors.SECONDARY_TEXT,
                fontFamily: "Gilroy-Bold",
                padding: "8px 20px",
                fontSize: normalize(14),
                marginTop: "8px",
                textAlign: "center",
              }}
            >
              {strings.buyAllEpisode}
            </Typography>

            <Box sx={[styles.popupButtons]}>
              <PassBtn
                borderRadius={13}
                style={{
                  width: "100px",
                  height: "50px",
                  opacity: 0.9,
                }}>

                <Box sx={{ ...rowStartStyles }}>
                  <PassAnimated style={{ width: 20, height: 20 }} />
                  <GradientText
                    color1={Colors.PASS1}
                    color2={Colors.PASS2}
                    style={styles.gradientTextSmall}>

                    {strings.pass.pass}
                  </GradientText>
                </Box>

              </PassBtn>

              <ButtonBase
                sx={styles.noButton}
                onClick={() => {
                  purchaseAllEpisodes();
                }}
              >
                <Box sx={{...rowStartStyles}}>
                  <TicketAnimated style={{ width: 25, height: 25 }} />
                  <Typography sx={[styles.storyPrice]}>{priceCount}</Typography>
                </Box>
              </ButtonBase>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
};

const styles = {
  container: {
    ...columnCenterStyles,
    width: "100%",
  },
  storyPrice: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.TICKET,
    marginLeft: "3px",
  },
  tokensOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "6px",
  },
  ticketsOwned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tokensAmountOwned: {
    fontSize: normalize(18),
    fontFamily: "Gilroy-Bold",
    color: Colors.TOKEN,
    marginLeft: "3px",
  },
  popupButtons: {
    width: "100%",
    minWidth: "220px",
    ...rowStyles,
    justifyContent: "space-evenly",
    alignItems: "flex-end",
  },
  yesButton: {
    backgroundColor: Colors.ACCENT,
    padding: "11px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  noButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    ...rowCenterStyles,
    padding: "10px 15px",
    borderRadius: "10px",
    minWidth: "100px",
  },
  noText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
  },
  gradientTextSmall: {
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    marginLeft: '5px',
  },
  storyPriceBlockPopup: {
    ...rowStartStyles,
    margin: "8px 0px 12px 0px",
  },
};

export default BuySeriesModal;
