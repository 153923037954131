/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, Box, Typography, ButtonBase } from "@mui/material";
import GradientBtnCustom from "../../../../components/GradientBtnCustom";
import { strings } from "../../../../services/translation";
import { Colors } from "../../../../style";
import { rowEvenlyStyles, columnCenterStyles } from "../../../../style/flex";
import normalize, { normalizeSize } from "../../../../utils/fontSizer";
import AppleSignin from "react-apple-signin-auth";
import { ReactComponent as IconEmail } from "../../../../assets/icons/icon_email.svg";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import { ReactComponent as GoogleIcon } from "../../../../assets/icons/icon_google.svg";
import { ReactComponent as AppleIcon } from "../../../../assets/icons/icon_apple.svg";
import { styles } from "../style";

interface Props {
    loginGoogleEnabled: boolean;
    loginFbENabled: boolean;
    loginAppleEnabled: boolean;
    setShowRegister: any;
    setShowLogin: any;
    loginWithGoogle: any;
    loginWithFacebook: any;
    signInSocialCallback: any;
    isBeta?: any;
    isTemporaryModal?: boolean;
}

const MainBox = (props: Props) => {
    const {
        loginGoogleEnabled,
        loginFbENabled,
        loginAppleEnabled,
        setShowRegister,
        setShowLogin,
        loginWithGoogle,
        loginWithFacebook,
        signInSocialCallback,
        isBeta = true,
        isTemporaryModal = false,
    } = props;

    const matchesWidth = useMediaQuery("(min-width:800px)");
    const matchesHeight = useMediaQuery("(min-height:800px)");

    return (
        <Box
            sx={[
                styles.loginContainer,
                matchesWidth && !isTemporaryModal
                    ? { width: "50%" }
                    : { width: "100%" },
                isTemporaryModal ? { borderRadius: normalizeSize(20) } : {},
            ]}>
            <Box
                sx={
                    matchesHeight
                        ? { width: "50px", height: "50px" }
                        : { display: "none" }
                }>
                <IconTalesup width={50} height={50} fill={Colors.WHITE} />
            </Box>
            <Typography sx={styles.title}>
                {isTemporaryModal
                    ? strings.user.finalizeRegister
                    : strings.welcomeToTalesUp}
            </Typography>
            <Typography sx={styles.subtitle}>
                {isTemporaryModal
                    ? strings.user.finalizeRegisterSubtitle
                    : strings.createAccount}
            </Typography>
            <Box sx={{ height: "60px" }}>
                <GradientBtnCustom
                    height={"60px"}
                    width={"205px"}
                    color1={Colors.HEART}
                    color2={Colors.ACCENT}
                    customStyle={{
                        borderRadius: "10px",
                    }}
                    onPress={() => setShowRegister(true)}>
                    <Box
                        sx={{
                            ...rowEvenlyStyles,
                            width: "100%",
                        }}>
                        <IconEmail width={20} height={20} fill={"#fff"} />
                        <Typography
                            style={{
                                color: Colors.WHITE,
                                fontSize: normalize(16),
                                fontFamily: "Gilroy-Bold",
                            }}>
                            {isTemporaryModal
                                ? strings.user.finalizeWithEmail
                                : strings.registerWithEmail}
                        </Typography>
                    </Box>
                </GradientBtnCustom>
            </Box>

            {/* social login */}
            {(loginGoogleEnabled || loginFbENabled || loginAppleEnabled) && (
                <Box sx={styles.separatorLoginContainer}>
                    <Box sx={styles.separatorLogin} />
                    <Typography sx={styles.separatorLoginText}>
                        {strings.orContinueWith}
                    </Typography>
                    <Box sx={styles.separatorLogin} />
                </Box>
            )}

            <Box sx={styles.socials}>
                {loginGoogleEnabled ? (
                    <ButtonBase
                        sx={styles.loginSocials}
                        onClick={() => loginWithGoogle()}>
                        <GoogleIcon width={23} height={23} />
                    </ButtonBase>
                ) : null}
                {loginFbENabled ? (
                    <ButtonBase
                        sx={[
                            styles.loginSocials,
                            { backgroundColor: Colors.FACEBOOK },
                        ]}
                        onClick={() => loginWithFacebook()}>
                        <Box
                            sx={{
                                width: "50px",
                                height: "50px",
                                ...columnCenterStyles,
                            }}>
                            <img
                                src={require("../../../../assets/images/f_logo_RGB-White_250.png")}
                                style={{ width: 22.5, height: 22.5 }}
                            />
                        </Box>
                    </ButtonBase>
                ) : null}
                {loginAppleEnabled && (
                    <>
                        <AppleSignin
                            authOptions={{
                                /** Client ID - eg: 'com.example.com' */
                                clientId: "com.talesupreader.signin",
                                /** Requested scopes, seperated by spaces - eg: 'email name' */
                                scope: "email name",
                                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                                redirectURI: isBeta
                                    ? "https://beta.talesup.io"
                                    : "https://play.talesup.io/login",
                                /** State string that is returned with the apple response */
                                state: "state",
                                /** Nonce */
                                nonce: "talesupweb8623",
                                /** Uses popup auth instead of redirection */
                                usePopup: true,
                            }}
                            uiType="light"
                            className="apple-auth-btn"
                            noDefaultStyle={false}
                            onSuccess={(response: any) => {
                                signInSocialCallback(
                                    response.authorization.id_token,
                                    "apple",
                                );
                            }} // default = undefined
                            /** Called upon signin error */
                            onError={(error: any) => console.error(error)} // default = undefined
                            render={(props: any) => (
                                <ButtonBase
                                    {...props}
                                    sx={[styles.loginSocials]}>
                                    <AppleIcon width={50} height={50} />
                                </ButtonBase>
                            )}
                        />
                    </>
                )}
            </Box>
            {!isTemporaryModal ? (
                <ButtonBase onClick={() => setShowLogin(true)}>
                    <Typography component={"span"} sx={styles.switchAccount}>
                        {strings.alreadyOnTalesUp}
                        <Typography
                            component={"span"}
                            sx={[
                                styles.switchAccount,
                                { color: Colors.MENU_ICON },
                            ]}>
                            {strings.login}
                        </Typography>
                    </Typography>
                </ButtonBase>
            ) : null}
        </Box>
    );
};

export default MainBox;
