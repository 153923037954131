/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from "react";
import { strings } from "../../services/translation";
import styles from "./styles";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon_achievement.svg";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import { Box, Fade, Typography } from "@mui/material";

// =======================================================

interface Props {
  notif: any;
  callback: any;
}

// =======================================================

const SuccessPopup = (props: Props) => {
  /**
   *
   */
  const { callback, notif } = props;

  notif.remove = true;

  const [shouldShow, setShouldShow] = useState(true);

  const [exist, setExist] = useState(true);

  const onAnimationEnd = () => {
    setTimeout(() => {
      setShouldShow(false);
      setTimeout(() => {
        setExist(false);
        callback(notif);
      }, 800);
    }, 3500);
  };

  if (exist) {
    return (
      <Fade
        in={shouldShow}
        appear
        addEndListener={() => {
          onAnimationEnd();
        }}
        timeout={600}
      >
        <Box sx={[styles.popBg]}>
          <Box sx={[styles.imageBackground]}>
            <img
              style={{ flex: 1, bottom: 0, opacity: 0.1 }}
              src={require("../../assets/icons/icon_success.png")}
            />
          </Box>
          <IconSuccess width={25} height={25} fill={Colors.ACCENT} />
          <Typography
            style={{
              color: Colors.ACCENT,
              fontFamily: "Gilroy-Bold",
              fontSize: normalize(16),
              marginLeft: "15px",
            }}
          >
            {strings.achievementCompleted}
          </Typography>
        </Box>
      </Fade>
    );
  } else {
    return null;
  }
};

// =======================================================

export default React.memo(SuccessPopup);
