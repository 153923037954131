/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";
import React from "react";

interface Props {
    style: any;
    color1: string;
    color2: string;
    children: any;
    fontSize?: any;
    background?: string | undefined;
    isSpan?: boolean | undefined;
}

const GradientText = (props: Props) => {
    const {
        color1,
        color2,
        style,
        children,
        background,
        isSpan = false,
    } = props;

    const styleNew = {
        ...style,
        ...{
            background: background
                ? background
                : `linear-gradient(270deg, ${color2} 0%, ${color1} 100%)`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
        },
    };
    return (
        <Typography sx={styleNew} component={isSpan ? "span" : "p"}>
            {children}
        </Typography>
    );
};

export default GradientText;
