/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from "react";
import {
  Box,
  Fade,
} from "@mui/material";
import GoBackBtn from "../GoBackBtn";
import { strings } from "../../services/translation";
import { ReactComponent as IconLocal } from "../../assets/icons/icon_local.svg";
import { ReactComponent as IconOnline } from "../../assets/icons/icon_online.svg";
import { ReactComponent as IconSolo } from "../../assets/icons/icon_join_room.svg";
import "./style.css";
import { rowCenterStyles } from "../../style/flex";
import GameModeBtn from "../GameModeBtn";

interface Props {
  goNext: any;
  storyId: any;
  active: any;
  episode: any;
  callback: any;
}

const PregameMode = (props: Props) => {
  const { goNext, callback } = props;

  const [isReady,] = useState(true);

  return (
    <Box sx={styles.all}>
      <Box sx={styles.modalBody}>
        <GoBackBtn
          callback={() => {
            callback(false);
          }}
          style={{ alignSelf: "flex-start", position: "absolute", top: 37 }}
        />
        <Fade appear in={!!isReady} timeout={600}>
          <Box sx={[styles.modalBodyRight]}>
            <GameModeBtn
              goNext={goNext}
              icon={<IconLocal width={90} height={90} />}
              title={strings.boardGameRoom}
              subtitle={strings.boardGameRoomSub}
              type="normal"
            />
            <GameModeBtn
              goNext={goNext}
              icon={<IconOnline width={90} height={90} />}
              title={strings.onlineRoom}
              subtitle={strings.onlineRoomSub}
              type="online"
            />
            {/*<GameModeBtn
              goNext={goNext}
              icon={<IconSolo width={70} height={70} />}
              title={strings.soloRoom}
              subtitle={strings.soloRoomSub}
              type="solo"
            /> */}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

const styles = {
  all: {
    position: "relative",
    display: "flex",
    width: "1000%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody: {
    position: "relative",
    display: "flex",
    width: "90%",
    minHeight: "100vh",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBodyRight: {
    width: "90%",
    columnGap: "10px",
    ...rowCenterStyles,
    flexWrap: "wrap",
    rowGap: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    flex: 1,
  },
};

export default PregameMode;
