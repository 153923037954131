/* eslint-disable @typescript-eslint/no-explicit-any */
import normalize from "../../../utils/fontSizer";
import { Colors } from "../../../style";
import { columnEndStyles, columnStartStyles } from "../../../style/flex";

// =======================================================

const centerStyles: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

// =======================================================

export default {
  container: {
    flex: 1,
    ...columnStartStyles,
    width: "100%",
    paddingTop: "32px",
    gap: "32px",
  },
  changing: {
    color: Colors.WHITE,
    fontSize: normalize(24),
    fontFamily: "Eczar-Regular",
    marginTop: "5px",
  },
  changeContainer: {
    flex: 1,
    ...columnEndStyles,
    marginBottom: "10px",
  },
  letter: {
    width: "70px",
    height: "70px",
    borderRadius: "70px",
    borderWidth: "2px",
    ...centerStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(30),
    color: Colors.WHITE,
  },
  text: {
    color: Colors.WHITE,
    fontSize: normalize(15),
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    paddingHorizontal: "30px",
  },
};

// =======================================================
