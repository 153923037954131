/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { db } from "../utils/FirebaseHelper";
import { FIREBASE_DATABASE, GAME_ONLINE } from "../constants";
import { isBoolean } from "lodash";
import {
    isArray,
    isFunction,
    isNumber,
    isTrueBoolean,
} from "../utils/TypeOfHelper";
import { isNotEmptyArray, randomItem } from "../utils/ArrayHelper";
import { isNotEmptyString } from "../utils/StringHelper";
import {
    onDeleteGameChat,
    onDeleteGameChoices,
    onDeletePlayers,
    onDeletePlayersExcluded,
    onDeletePlayersLogout,
} from "./FirebaseRealtimeDatabaseManager";
import { propertyExists } from "../utils/ObjectHelper";
import rand from "random-seed";
import { Colors } from "../style";
import { ScreenType } from "./StoryReader/interfaces";
import { getLanguage, strings } from "./translation";
import { getRouteManager } from "./routeManager";
import Routes from "../utils/OnlineRoutesHelper";
import StoryReader from "./StoryReader";
import ColorManager from "./colorManager";

// =======================================================

export const validateGame = (game: any): boolean => {
    return !!game.timestamp && isNumber(game.timestamp);
};

// =======================================================

export const isValidSnapshot = (snapshot: any, errorCode: number): boolean => {
    if (!snapshot || snapshot.val() === null || snapshot.val() === undefined) {
        // TODO: log event firebase

        return false;
    }

    return true;
};

export const isValid = (value: any, errorCode: number): boolean => {
    if (!value || value === null || value === undefined) {
        return false;
    }

    return true;
};

// =======================================================

export const updateStorage = (
    launched = false,
    playerID?: string | null,
    gameID?: string,
    screenName?: string,
    nbrPlayers?: number,
    background?: string,
) => {
    if (isNotEmptyString(playerID) && isNotEmptyString(gameID)) {
        localStorage.setItem(
            "alreadyLaunchedOnlineGame",
            JSON.stringify({
                status: launched,
                playerID,
                gameID,
                screenName: isNotEmptyString(screenName) ? screenName : "",
                nbrPlayers: isNumber(nbrPlayers) ? nbrPlayers : "",
                background: isNotEmptyString(background) ? background : "",
            }),
        );
    } else {
        resetStorage();
    }
};
// =======================================================

export const resetStorage = () => {
    localStorage.setItem(
        "alreadyLaunchedOnlineGame",
        JSON.stringify({
            status: false,
            playerID: null,
            gameID: null,
            screenName: null,
            nbrPlayers: null,
        }),
    );
};

// =======================================================

export const deleteGame = (gameID?: string): void => {
    if (isNotEmptyString(gameID)) {
        onDeletePlayers(gameID);
        onDeletePlayersExcluded(gameID);
        onDeletePlayersLogout(gameID);
        onDeleteGameChoices(gameID);
        onDeleteGameChat(gameID);
        db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}`).remove();
    }
};

// =======================================================

export const addPlayerLogout = (
    gameID?: string,
    playerID?: string,
    userID?: number,
): boolean => {
    if (
        isNotEmptyString(gameID) &&
        isNotEmptyString(playerID) &&
        isNumber(userID)
    ) {
        const newReference = db
            .ref(`/${FIREBASE_DATABASE.REFERENCES.PLAYERS_LOGOUT}/${gameID}`)
            .push({ playerID, userID });

        return isNotEmptyString(newReference.key);
    }

    return false;
};

// =======================================================

export const handleQuitGame = (
    playerID?: string,
    gameID?: string,
    host?: any,
    currentUserID?: number | null,
): Promise<boolean> => {
    if (propertyExists(host, "id") && isNotEmptyString(playerID)) {
        if (playerID === host.id) {
            // is the host player?
            return db
                .ref(
                    `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}/${playerID}`,
                )
                .remove()
                .then(() => {
                    deleteGame(gameID);
                    return true;
                })
                .catch((error: any) => {
                    console.log(
                        `Remove player ${playerID} failed: ${error.message}`,
                    );
                    return false;
                });
        } else if (currentUserID !== null && isNumber(currentUserID)) {
            // is a lambda player?
            return Promise.resolve(
                addPlayerLogout(gameID, playerID, currentUserID),
            );
        }
    }
    return Promise.resolve(false);
};

export const handleQuitGameLobby = (
    playerID?: string | null,
    gameID?: string,
    host?: any,
    currentUserID?: number | null,
): Promise<boolean | string> => {
    if (propertyExists(host, "id") && isNotEmptyString(playerID)) {
        if (playerID === host.id) {
            // is the host player?
            return db
                .ref(
                    `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}/${playerID}`,
                )
                .remove()
                .then(() => {
                    deleteGame(gameID);
                    return true;
                })
                .catch((error: any) => {
                    console.log(
                        `Remove player ${playerID} failed: ${error.message}`,
                    );
                    return false;
                });
        } else if (currentUserID !== null && isNumber(currentUserID)) {
            // is a lambda player?
            //addPlayerLogout(gameID, playerID, currentUserID)
            return Promise.resolve("shouldBeExcluded");
        }
    }

    return Promise.resolve(false);
};

export const handleQuitGamePregame = (
    playerID?: string,
    gameID?: string,
): Promise<boolean | string> => {
    if (isNotEmptyString(playerID) && isNotEmptyString(gameID)) {
        // is the host player?
        return db
            .ref(
                `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${gameID}/${playerID}`,
            )
            .remove()
            .then(() => {
                deleteGame(gameID);
                return true;
            })
            .catch((error: any) => {
                console.log(
                    `Remove player ${playerID} failed: ${error.message}`,
                );
                return false;
            });
    }
    return Promise.resolve(false);
};

// =======================================================

export const goToLibrary = (navigate: any, playerID?: string, host?: any) => {
    const value = localStorage.getItem("deleteOnlineGameData");
    if (value != null) {
        const data = JSON.parse(value);

        resetStorage(); // because : see all updateStorage() calls

        const leftMessage = strings.messages.hasLeftOnlineGameMessage;

        localStorage.removeItem("deleteOnlineGameData");

        // is the host player?
        if (
            propertyExists(host, "id") &&
            isNotEmptyString(playerID) &&
            playerID === host.id
        ) {
            if (propertyExists(data, "story")) {
                const item = data.story;

                if (
                    data.isSeries === "true" &&
                    propertyExists(item, "series")
                ) {
                    navigate("/", {
                        replace: true,
                        state: {
                            seriesParam: item.series,
                            user: getRouteManager().getUser(),
                            hasLeftOnlineGameMessage: leftMessage,
                        },
                    });
                } else if (data.isSeries === "false") {
                    goLibraryStack(navigate, leftMessage);
                    /* navigation.reset({
            index: 0,
            routes: [
              {
                name: "StoryIntro",
                params: {
                  storyId: item.id,
                  title: item.title,
                  summary: item.resume,
                  dateUpdated: item.updated,
                  nbPlayerMin: item.nbPlayerMin,
                  nbPlayerMax: item.nbPlayerMax,
                  intro: item.intro,
                  averageTime: item.averageTime,
                  accentColor: item.accentColor,
                  storyVersion: item.version,
                  totalCount: item.count,
                  type: item.type,
                  price: 0,
                  artists: item.artists,
                  promo: item.promo,
                  themes: item.themes,
                  hasLeftOnlineGameMessage: leftMessage,
                  story: item,
                },
              },
            ],
          }); */
                } else {
                    // no valid data
                    goLibraryStack(navigate, leftMessage);
                }
            } else {
                // no valid data
                goLibraryStack(navigate, leftMessage);
            }
        } else {
            // is a lambda player?
            goLibraryStack(navigate, leftMessage);
        }
    }
};

// =======================================================

export const goToOnlineGame = (
    navigate: any,
    game?: any,
    gameID?: string,
    playerID?: string,
    username?: string,
    user?: any,
    onError?: any,
    nbrPlayers?: number,
    onReboot = false,
    background?: string | undefined,
) => {
    const status = propertyExists(game, "status") ? game.status : null;
    const story = propertyExists(game, "story") ? game.story : null;
    const host = propertyExists(game, "host") ? game.host : null;

    if (
        isNotEmptyString(gameID) &&
        username &&
        isNotEmptyString(username) &&
        isNotEmptyString(playerID) &&
        propertyExists(user, "id") &&
        propertyExists(status, "currentScreen") &&
        story !== null
    ) {
        const name = username.trim();

        let playersSize = null;

        if (isNumber(nbrPlayers)) {
            playersSize = nbrPlayers;
        } else if (
            propertyExists(game, "nbrPlayers") &&
            isNumber(game.nbrPlayers)
        ) {
            playersSize = game.nbrPlayers;
        }

        if (
            isNumber(playersSize) ||
            status.currentScreen === Routes.LOBBY_SCREEN.name
        ) {
            updateStorage(true, playerID, gameID, name, playersSize);
            navigate(
                status.currentScreen === Routes.LOBBY_SCREEN.name
                    ? Routes.LOBBY_SCREEN.url
                    : status.currentScreen === Routes.GAME_ONLINE_SCREEN.name
                    ? Routes.GAME_ONLINE_SCREEN.url + "/" + gameID
                    : status.currentScreen ===
                      Routes.PRE_GAME_BACKGROUND_ONLINE_SCREEN.name
                    ? Routes.PRE_GAME_BACKGROUND_ONLINE_SCREEN.url
                    : Routes.LOBBY_SCREEN.url,
                {
                    replace: true,
                    state: {
                        colors: propertyExists(status, "colors")
                            ? status.colors
                            : "",
                        episode: propertyExists(story, "episode")
                            ? story.episode
                            : null,
                        gameID,
                        host:
                            host !== null
                                ? { id: host[0], name: host[1] }
                                : { id: null, name: null },
                        playerID,
                        screenName: name,
                        storyBackground: propertyExists(
                            status,
                            "storyBackground",
                        )
                            ? status.storyBackground
                            : "",
                        storyID: propertyExists(status, "storyId")
                            ? status.storyId
                            : null,
                        title: propertyExists(story, "title")
                            ? story.title
                            : "",
                        user,
                        onReboot,
                        nbrPlayers: playersSize,
                        story: story,
                        background: background,
                    },
                },
            );
        } else if (isFunction(onError)) {
            onError();
        }
    } else if (isFunction(onError)) {
        onError();
    }
};

// =======================================================

const goLibraryStack = (navigate: any, leftMessage: string) => {
    navigate("/", {
        replace: true,
        state: {
            screen: Routes.LIBRARY_SCREEN.name,
            params: {
                hasLeftOnlineGameMessage: leftMessage,
            },
        },
    });
};

// =======================================================

export const isValidScene = (data: any, shouldForce: boolean): boolean => {
    return (
        propertyExists(data, "number") &&
        isNumber(data.number) &&
        propertyExists(data, "enableCallback") &&
        (isTrueBoolean(data.enableCallback) || shouldForce)
    );
};

// =======================================================

export const isSingleNextChoice = (storyReader: StoryReader): boolean => {
    if (storyReader) {
        const currChoices = storyReader.getCurrentChoices();

        if (isArray(currChoices)) {
            return (
                currChoices.length === 1 &&
                (currChoices[0].text === "Continuer" ||
                    currChoices[0].text === "Continue")
            );
        }
    }

    return false;
};

// =======================================================

export const isScreenWithChoices = (storyReader: StoryReader): boolean => {
    if (storyReader) {
        const type = storyReader.getScreenType();

        if (isNumber(type)) {
            return (
                !isSingleNextChoice(storyReader) &&
                (type === ScreenType.STANDARD ||
                    type === ScreenType.TIMER ||
                    type === ScreenType.IMAGE_TIMER ||
                    type === ScreenType.NORMAL_IMAGE)
            );
        }
    }

    return false;
};

// =======================================================

export const isScreenWithNextButton = (storyReader: StoryReader): boolean => {
    if (storyReader) {
        const type = storyReader.getScreenType();

        if (isNumber(type)) {
            return (
                type === ScreenType.POOLP ||
                type === ScreenType.FULLSCREEN_IMAGE ||
                type === ScreenType.NORMAL_IMAGE ||
                type === ScreenType.CHANGE ||
                type === ScreenType.SECRET ||
                (isSingleNextChoice(storyReader) &&
                    (type === ScreenType.TIMER ||
                        type === ScreenType.IMAGE_TIMER ||
                        type === ScreenType.STANDARD))
            );
        }
    }

    return false;
};

// =======================================================

export const isScreenWithTimer = (type: any): boolean => {
    let bool = false;

    if (isNumber(type)) {
        bool = type === ScreenType.TIMER || type === ScreenType.IMAGE_TIMER;
    }

    return bool;
};

// =======================================================

export const getFinalChoice = (finalChoices: any): any => {
    return Object.keys(finalChoices).reduce(
        (acc: any, curr: any) =>
            acc.count
                ? finalChoices[curr].count > acc.count
                    ? finalChoices[curr]
                    : acc
                : finalChoices[curr],
        randomItem(finalChoices), // ensure a default value
    );
};

// =======================================================

export const getNbPlayers = (storyReader: any): number => {
    let number = 0;

    if (storyReader) {
        const items = storyReader.getAvailablePlayers();

        if (isNotEmptyArray(items)) {
            number = items.length;
        }
    }

    return number;
};

// =======================================================

export function sortByJoinedAt(arr: Array<any>) {
    return arr.sort((a, b) =>
        new Date(b.joinedAt) < new Date(a.joinedAt) ? 1 : -1,
    );
}

// =======================================================

export const getGamePlayers = (
    players: Array<any>,
    playerID: string | null,
    sort = true,
): Array<any> => {
    const gamePlayers = [];

    if (isNotEmptyArray(players) && isNotEmptyString(playerID)) {
        for (let i = 0; i < players.length; i++) {
            const gamer =
                players[i][1] !== undefined ? players[i][1] : players[i];
            const gamerID =
                players[i][0] !== undefined ? players[i][0] : gamer.playerCode;

            const you = `(${strings.labels.you})`;
            const suffix = playerID === gamerID ? you : "";

            let bgColor = "";

            if (
                propertyExists(gamer, "avatar") &&
                propertyExists(gamer.avatar, "letterBorderColor")
            ) {
                bgColor = gamer.avatar.letterBorderColor;
            }

            const username = propertyExists(gamer, "name")
                ? gamer.name
                : gamer.username;
            const re = new RegExp(`(${strings.labels.you})`, "g");
            const name = username.replace(re, "").replace("()", "").trim();

            const gamePlayer = {
                ...gamer,
                name: isNotEmptyString(suffix)
                    ? `${name} ${suffix}`
                    : `${name}`,
                avatar: gamer.avatar,
                letter: name.charAt(0),
                letterColor: Colors.PRIMARY,
                letterBorderColor: bgColor,
                playerCode: gamerID,
                joinedAt: propertyExists(gamer, "joinedAt")
                    ? gamer.joinedAt
                    : null, // to order list
            };

            gamePlayers.push(gamePlayer);
        }
    }

    // in order to get the SAME list ordering for all player's devices
    return isTrueBoolean(sort) ? sortByJoinedAt(gamePlayers) : gamePlayers;
};

// =======================================================

export const editPlayersList = (
    players: any,
    playerID: any,
    userID: any,
    newName: any,
) =>
    players.map(({ avatar, playerCode, name, ...players }: any) => ({
        ...players,
        avatar: avatar,
        playerCode: avatar.userId === userID ? playerID : playerCode,
        name: avatar.userId === userID ? newName : name,
    }));

export const replacePlayersInSave = (players: any, save: any) => {
    const saveCopy = { ...save };
    saveCopy.players = [...players];
    return saveCopy;
};

export const onCreateGame = async (
    storyId: number,
    colors: any,
    story: any,
    isSeriesStory: boolean,
    nbPlayerMin: number,
    nbPlayerMax: number,
    title: string,
    dateUpdated: any,
    navigate: any,
    user: any,
    episode: any = null,
    save = null,
): Promise<void> => {
    if (colors !== undefined) {
        ColorManager.getInstance().Clear();
        ColorManager.getInstance().generateColors(colors);

        if (propertyExists(user, "id") && propertyExists(user, "pseudo")) {
            const currPlayerName = user.pseudo.substring(0, 12);

            if (isNotEmptyString(currPlayerName)) {
                let newGameID = makeGameID(GAME_ONLINE.GAME_ID_LENGTH);
                const isNotValid = await isNotValidGameID(newGameID);

                if (isNotValid) {
                    while (await isNotValidGameID(newGameID)) {
                        console.log("not valid");
                        newGameID = makeGameID(GAME_ONLINE.GAME_ID_LENGTH);
                    }
                }

                if (isNotEmptyString(newGameID)) {
                    // TODO: log event firebase

                    try {
                        const gameReference = db.ref(
                            `/${FIREBASE_DATABASE.REFERENCES.GAMES}`,
                        );

                        gameReference.child(newGameID).set({
                            hasChangedScene: [],
                            hasPlayedFeedbackAnimation: [],
                            hasReachedPlayerMaxLimit: false,
                            host: null,
                            isTheEnd: false,
                            isPublic: false,
                            nbrPlayers: null,
                            scene: {
                                number: 0, // default start scene
                                enableCallback: true,
                            }, // will be filled later
                            shouldPlayedFeedbackAnimation: null,
                            status: {
                                currentScreen: Routes.LOBBY_SCREEN.name,
                                storyId: "", // will be filled later
                                storyBackground: "", // will be filled later
                                colors: "", // will be filled later
                            },
                            story: {
                                background:
                                    episode && episode.series
                                        ? `${getRouteManager().getCover(
                                              episode.series.idCover,
                                          )}`
                                        : `${getRouteManager().cover(storyId)}`,
                                colors: JSON.stringify(colors),
                                data: story,
                                episode: episode,
                                id: storyId,
                                isSeries: isSeriesStory,
                                playerMinLimit: nbPlayerMin,
                                playerMaxLimit: nbPlayerMax,
                                randomPlayerID: "",
                                secretPlayerID: "",
                                soloPlayerID: "",
                                title: title,
                                updated: dateUpdated,
                            },
                            storyReader: null,
                            timestamp: Date.now(),
                            save: save,
                            lang: getLanguage(),
                            created: new Date().toLocaleString("fr-FR"),
                        });

                        // add host to game
                        const playerReference = await db
                            .ref(
                                `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${newGameID}`,
                            )
                            .push({
                                username: currPlayerName.trim(),
                                avatar: propertyExists(user, "avatar")
                                    ? {
                                          ...user.avatar,
                                          letterBorderColor:
                                              ColorManager.getInstance().getColor(
                                                  "tertiary",
                                              ),
                                      }
                                    : null,
                                title: propertyExists(user, "title")
                                    ? {
                                          ...user.title,
                                      }
                                    : null,
                                joinedAt: Date.now(),
                                isReady: true,
                            });

                        if (isNotEmptyString(playerReference.key)) {
                            // (auto generated key)
                            const playerID: any = playerReference.key;

                            // add player to 'waiting' state to indicate (to others) they haven't submitted join
                            db.ref(
                                `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${newGameID}/waiting/${playerID}`,
                            ).set(currPlayerName.trim());
                            db.ref(
                                `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${newGameID}/host/${playerID}`,
                            ).set({ [playerID]: currPlayerName.trim() });

                            // If save, then replace host in save with new player id & name and set in firebase
                            if (save && propertyExists(save, "players")) {
                                await db
                                    .ref(
                                        `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${newGameID}/save`,
                                    )
                                    .set(save);
                            }

                            await cleanDatabase();

                            // will be used to redirect user when game will be stopped
                            localStorage.setItem(
                                "deleteOnlineGameData",
                                JSON.stringify({
                                    story: isSeriesStory ? episode : story,
                                    isSeries: isSeriesStory ? "true" : "false",
                                }),
                            );
                            navigate(
                                `${Routes.LOBBY_SCREEN.url}/${newGameID}`,
                                {
                                    replace: false,
                                    state: {
                                        playerID,
                                        gameID: newGameID,
                                        screenName: currPlayerName,
                                        user,
                                        episode,
                                        title,
                                        story: {
                                            background:
                                                episode && episode.series
                                                    ? `${getRouteManager().getCover(
                                                          episode.series
                                                              .idCover,
                                                      )}`
                                                    : `${getRouteManager().cover(
                                                          storyId,
                                                      )}`,
                                            colors: colors,
                                            data: story,
                                            episode: episode,
                                            id: storyId,
                                            isSeries: isSeriesStory,
                                            playerMinLimit: nbPlayerMin,
                                            playerMaxLimit: nbPlayerMax,
                                            randomPlayerID: "",
                                            secretPlayerID: "",
                                            soloPlayerID: "",
                                            title: title,
                                            updated: dateUpdated,
                                        },
                                    },
                                },
                            );
                        } else {
                            console.log("Generated player key is not valid");
                        }
                    } catch (error) {
                        // ...
                    }
                }
            } else {
                console.log(
                    `Current player name (${currPlayerName}) is not valid`,
                );
            }
        } else {
            console.log("Current user is not valid");
        }
    }
};

// =======================================================

const makeGameID = (length: number): string => {
    try {
        let id = "";

        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const seededRandom = rand.create(Date.now().toString());

        for (let i = 0; i < length; i++) {
            id += characters.charAt(
                Math.floor(seededRandom(characters.length)),
            );
        }

        return id;
    } catch (err) {
        console.log("makeGameID");
        return "";
    }
};

// =======================================================

const isNotValidGameID = async (id: string): Promise<boolean> => {
    if (id === "") {
        return true;
    }

    try {
        const snapshot = await db
            .ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}/${id}`)
            .orderByKey()
            .once("value");

        // we want to make sure the game doesn't exist yet
        if (snapshot.val() == null) {
            //console.log(`Game ID (${id}) is valid`);
            return false;
        } else {
            console.log(`Game id (${id}) is not valid`);
            return true;
        }
    } catch {
        console.log(`Failed to check if game id ${id} is valid`);
        return true;
    }
};

// =======================================================

export const cleanDatabase = async () => {
    try {
        const gameExpiredTimestamp =
            Date.now() - GAME_ONLINE.GAME_EXPIRATION_LENGTH;

        const gameReference = db.ref(`/${FIREBASE_DATABASE.REFERENCES.GAMES}`);

        const oldGames = await gameReference
            .orderByChild("timestamp")
            .endAt(gameExpiredTimestamp)
            .once("value");

        if (oldGames.val() !== null) {
            const IDs = Object.keys(oldGames.val());

            if (isNotEmptyArray(IDs)) {
                const validGameIDs: string[] = [];

                IDs.forEach((ID) => {
                    if (validateGame(oldGames.val()[ID])) {
                        validGameIDs.push(ID);
                    }
                });

                if (isNotEmptyArray(validGameIDs)) {
                    validGameIDs.forEach(async (validID) => {
                        await db
                            .ref(
                                `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${validID}`,
                            )
                            .remove();

                        await db
                            .ref(
                                `/${FIREBASE_DATABASE.REFERENCES.PLAYERS}/${validID}`,
                            )
                            .remove();
                    });
                }
            }
        }
    } catch (error) {
        console.log(error);
        return;
    }
};

// =======================================================
