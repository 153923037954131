/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from "react";
import ColorManager from "../../services/colorManager";
import normalize from "../../utils/fontSizer";
import { isNotEmptyString } from "../../utils/StringHelper";
import { strings } from "../../services/translation";
import { propertyExists } from "../../utils/ObjectHelper";
import styles from "./styles";
import { ReactComponent as IconPlayer } from "../../assets/icons/icon_group.svg";
import { Box, Fade, Typography } from "@mui/material";
import IconTint from "react-icon-tint";
import { getRouteManager } from "../../services/routeManager";
import { rowStartStyles, rowStyles } from "../../style/flex";

// =======================================================

interface Props {
  currVar: any;
  storyId: any;
  colorAccent: any;
  textColor: any;
  notif: any;
  callback: any;
  storyData: any;
}

// =======================================================

const VarPopUp = (props: Props) => {
  /**
   *
   */
  const { currVar, colorAccent, textColor, callback, notif, storyData } = props;

  notif.remove = true;

  const stateName = currVar.firstOperand ? currVar.firstOperand : null;
  const [shouldShow, setShouldShow] = useState(true);
  const { iconStates } = storyData;

  const iconObject = iconStates.find((item: any) => item.name === stateName);

  const [exist, setExist] = useState(true);

  const onAnimationEnd = () => {
    setTimeout(() => {
      setShouldShow(false);
      setTimeout(() => {
        setExist(false);
        callback(notif);
      }, 800);
    }, 3500);
  };
  let nameToShow = "";

  const { globalVars } = storyData;

  // globalVars est un objet et pas un array => si check array alors toujours false
  if (globalVars && typeof globalVars !== "undefined") {
    const obj = globalVars.find((entry: any) => entry.name === stateName);

    if (propertyExists(obj, "defaultValue")) {
      nameToShow = obj.defaultValue;
    }
  }

  const icon =
    iconObject && iconObject.iconId ? (
      <Box style={{ ...rowStyles }}>
        <Box maxWidth={"45px"} maxHeight={"45px"} sx={{ alignSelf: "center" }}>
          <IconTint
            color={textColor}
            src={getRouteManager().icon(iconObject.iconId)}
            maxWidth={45}
            maxHeight={45}
          />
        </Box>

        {currVar?.secondOperand &&
          currVar?.secondOperand === "=" &&
          currVar?.thirdOperand &&
          currVar?.thirdOperand > 1 ? (
          <Box
            sx={{
              position: "relative",
              left: "-15px",
              alignSelf: "flex-end",
              borderRadius: "100px",
              padding: "0px 3px",
              zIndex: 10,
              backgroundColor: ColorManager.getInstance().getColor("quinary"),
            }}
          >
            <Typography
              style={{
                fontSize: normalize(14),
                color: colorAccent,
                fontFamily: "Gilroy-Bold",
              }}
            >
              {currVar.thirdOperand}
            </Typography>
          </Box>
        ) : null}
      </Box>
    ) : null;

  if (exist) {
    return (
      <Fade
        in={shouldShow}
        appear
        addEndListener={() => {
          onAnimationEnd();
        }}
        timeout={600}
      >
        <Box
          sx={[
            styles.popBg,
            { backgroundColor: ColorManager.getInstance().getColor("quinary") },
          ]}
        >
          <Box sx={[styles.imageBackground, { opacity: 0.1 }]}>
            <IconTint
              color={textColor}
              maxWidth={137}
              maxHeight={137}
              src={getRouteManager().icon(iconObject.iconId)}
            />
          </Box>
          <IconPlayer
            width={72}
            height={72}
            fill={ColorManager.getInstance().getColor("tertiary")}
            fillSecondary={ColorManager.getInstance().getColor("primary")}
          />
          <Box style={styles.rightPart}>
            <Typography sx={[styles.normalText, { color: colorAccent }]}>
              {strings.topMenu.teamObject}
            </Typography>
            <Box style={styles.iconAndName}>
              {icon}
              {isNotEmptyString(nameToShow) ? (
                <Typography
                  style={{
                    fontSize: normalize(14),
                    color: textColor,
                    paddingLeft: "5px",
                    fontFamily: "Gilroy-Bold",
                    flexShrink: 1,
                    ...rowStartStyles,
                  }}
                >
                  {currVar?.secondOperand &&
                    currVar?.secondOperand === "+" &&
                    currVar?.thirdOperand ? (
                    <Typography
                      style={{
                        fontSize: normalize(14),
                        color: colorAccent,
                        fontFamily: "Gilroy-Bold",
                        flexShrink: 1,
                        marginRight: "5px",
                      }}
                    >
                      {"+"}
                      {currVar.thirdOperand}{" "}
                    </Typography>
                  ) : null}
                  {nameToShow}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Fade>
    );
  } else {
    return null;
  }
};

// =======================================================

export default React.memo(VarPopUp);
