import { Colors } from "../../style";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
    rowStartStyles,
} from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import { normalizeSize } from "../../utils/fontSizer";

export const styles = {
    modalContainer: {
        ...columnCenterStyles,
    },
    modalInsideContainer: {
        ...columnStartStyles,
        backgroundColor: Colors.PRIMARY,
        borderRadius: normalizeSize(20),
        maxWidth: normalizeSize(400),
        width: "100vw",
        outline: "none",
        padding: "20px 0px",
    },
    container: {
        ...columnStartStyles,
    },
    titleContainer: {
        ...rowCenterStyles,
        gap: normalizeSize(10),
    },
    titleColumnContainer: {
        ...columnStartStyles,
        gap: normalizeSize(10),
    },
    title: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(25),
        color: Colors.WHITE,
    },
    iconContainer: {
        ...columnCenterStyles,
        padding: normalizeSize(15),
        borderRadius: normalizeSize(100),
        backgroundColor: Colors.BTN_ACTIVE,
    },
    subtitle: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    introContainer: {
        ...rowStartStyles,
        padding: "0px 10%",
        gap: normalizeSize(15),
    },
    introText: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.OLD_PRICE,
        textAlign: "left",
    },
    separator: {
        margin: "15px 0px",
    },
    contentContainer: {
        ...columnStyles,
        padding: "0px 10% 30px",
        rowGap: normalizeSize(15),
    },
    itemContainer: {
        ...rowStartStyles,
        gap: normalizeSize(15),
    },
    itemIcon: {
        zIndex: 3,
        width: normalizeSize(50),
        height: normalizeSize(50),
    },
    itemText: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        color: Colors.OLD_PRICE,
        textAlign: "left",
    },
    footerText: {
        fontFamily: Gilroy,
        width: "100%",
        fontSize: normalizeSize(14),
        color: Colors.WHITE,
        textAlign: "center",
    },
};
