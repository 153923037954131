/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from "react";
import ColorManager from "../../../services/colorManager";
import { ReactComponent as NextIcon } from "../../../assets/icons/icon_next.svg";
import PopUpIndicator from "../../../components/PopUpIndicator";
import StoryReader from "../../../services/StoryReader";
import styles from "./styles";
import TextScene from "../../../components/TextScene";
import { isBoolean, isNumber } from "lodash";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { strings } from "../../../services/translation";
import { ReactComponent as IconVoted } from "../../../assets/icons/icon_voted.svg";
import { ReactComponent as IconBlocked } from "../../../assets/icons/icon_blocked.svg";
import AvatarContainer from "../../../components/AvatarContainer";
import { Box, ButtonBase, Fade, Typography } from "@mui/material";
import Loading from "../../../components/Loading";
import SoundManager from "../../../services/SoundManager";
import CircularProgressNew from "../../../components/CircularProgress/CircularProgressNew";

// =======================================================

interface Props {
  text: string;
  onSkipImage: any;
  players: any;
  storyId: number;
  color: any;
  hlText: string;
  textColor: any;
  masterPlayer: number;
  showStatePopUp: boolean;
  overlay: boolean;
  storyReader: StoryReader;
  disabled?: boolean;
  disabledText?: string | null;
  percent?: any;
  loading?: boolean;
  isVisibleText?: boolean;
}

interface State {
  fillValue: any;
  opacityValue: any;
  jsonPoolp: any;
  showPopUpIndicator: boolean;
}
// =======================================================

class ChangeScreen extends Component<Props, State> {
  /**
   *
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      fillValue: null,
      opacityValue: null,
      jsonPoolp: null,
      showPopUpIndicator: false,
    };
  }

  /**
   *
   */
  skipScreen = () => {
    const { onSkipImage } = this.props;
    onSkipImage();
  };

  /**
   *
   */
  drawPlayer = (players: any, master: any) => {
    const player = players[master];

    if (player.avatar) {
      return (
        <AvatarContainer
          user={player}
          persoWidth={150}
          borderWidth={5}
          borderColor={ColorManager.getInstance().getColor("secondary")}
        />
      );
    } else {
      return (
        <Box
          sx={[
            styles.letter,
            {
              backgroundColor: player.letterColor,
              borderColor: ColorManager.getInstance().getColor("secondary"),
            },
          ]}
        >
          <Typography style={styles.playerLetter}>{player.letter}</Typography>
        </Box>
      );
    }
  };

  /**
   *
   */
  onClick = () => {
    this.skipScreen();
  };

  /**
   *
   */
  isLoading = () => {
    return isBoolean(this.props.loading) && this.props.loading;
  };

  renderIcon = () => {
    const { disabledText } = this.props;
    if (this.isLoading()) {
      return (
        <Loading
          size={"small"}
          color={ColorManager.getInstance().getColor("tertiary")}
        />
      );
    }

    if (isNotEmptyString(disabledText)) {
      if (disabledText === strings.messages.notConcerned) {
        return (
          <IconBlocked
            width={25}
            height={25}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
        );
      } else if (disabledText === strings.messages.alreadyVoted) {
        return (
          <IconVoted
            width={25}
            height={25}
            fillSecondary={ColorManager.getInstance().getColor("tertiary")}
          />
        );
      } else {
        return null;
      }
    }

    return (
      <NextIcon
        width={45}
        height={45}
        fill={ColorManager.getInstance().getColor("quinary")}
      />
    );
  };

  /**
   *
   */
  handleSubmit = () => {
    SoundManager.getInstance().playChoiceMadeSound();
    this.onClick();
  };

  /**
   *
   */
  renderSubmitButton = (isDisabled: boolean | null) => {
    const disabled = isBoolean(isDisabled) && isDisabled;

    return (
      <ButtonBase
        onClick={() =>
          !disabled
            ? this.handleSubmit()
            : this.setState({ showPopUpIndicator: true })
        }
      >
        {this.renderIcon()}
      </ButtonBase>
    );
  };

  // --
  render() {
    const {
      text,
      players,
      color,
      hlText,
      textColor,
      masterPlayer,
      showStatePopUp,
      overlay,
      storyReader,
      disabled = false,
      percent = null,
      isVisibleText = true,
      disabledText = "",
    } = this.props;

    const { showPopUpIndicator } = this.state;

    return (
      <Fade in={true} appear timeout={800}>
        <Box style={styles.container}>
          <Box style={styles.changeContainer}>
            {this.drawPlayer(players, masterPlayer)}

            <Typography sx={[styles.changing, { color: textColor }]}>
              {strings.change}
            </Typography>
          </Box>

          {isVisibleText ? (
            <TextScene
              players={players}
              color={color}
              hlText={hlText}
              textColor={textColor}
              overlay={overlay}
              storyReader={storyReader}
              shouldResize={false}
              marginTop={false}
              style={{ justifyContent: "flex-start" }}
            >
              {text}
            </TextScene>
          ) : (
            <TextScene
              players={players}
              color={color}
              hlText={hlText}
              textColor={textColor}
              overlay={overlay}
              storyReader={storyReader}
              shouldResize={false}
              marginTop={false}
            >
              {strings.secretTextHide}
            </TextScene>
          )}

          <Box sx={styles.submitButtonWrapper}>
            {!showStatePopUp ? (
              isNumber(percent) ? (
                <>
                  {showPopUpIndicator &&
                  isNotEmptyString(disabledText) &&
                  !this.isLoading() ? (
                    <PopUpIndicator
                      onCancel={() =>
                        this.setState({ showPopUpIndicator: false })
                      }
                      text={disabledText}
                      shouldGoUp={50}
                    />
                  ) : null}

                  <CircularProgressNew
                    percent={percent}
                    color={ColorManager.getInstance().getColor("tertiary")}
                    bgColor={ColorManager.getInstance().getColor("quinary")}
                  >
                    {this.renderSubmitButton(disabled)}
                  </CircularProgressNew>
                </>
              ) : (
                this.renderSubmitButton(disabled)
              )
            ) : null}
          </Box>
        </Box>
      </Fade>
    );
  }
}

// =======================================================

export default ChangeScreen;
