/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import type { LottiePlayer } from "lottie-web";
import { Box } from "@mui/material";

interface Props {
  src: string;
  autoplay?: boolean | undefined;
  loop?: boolean | undefined;
  style?: any;
  speed?: any;
}

const Animation = (props: Props) => {
  const { src, autoplay = true, loop = true, style, speed = 1 } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import("lottie-web").then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: loop,
        /* animationData: { speed: speed }, */
        autoplay: autoplay,
        // path to your animation file, place it inside public folder
        path: src,
      });
      if (speed) {
        animation.setSpeed(speed);
      }

      return () => animation.destroy();
    }
  }, [lottie]);

  return <Box sx={style ? style : {}} ref={ref} />;
};

export default Animation;
