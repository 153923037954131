import { Colors } from "../../../style";
import {
    columnCenterStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../../style/flex";
import { Gilroy } from "../../../style/fonts";
import { normalizeSize, normalizeVh } from "../../../utils/fontSizer";

export const styles = {
    mainContainer: {
        marginTop: normalizeSize(20),
    },
    container: {
        ...columnStartStyles,
        alignItems: "flex-start",
        paddingTop: normalizeVh(4),
        paddingBottom: normalizeSize(50),
    },
    topMenuTitle: {
        width: "100%",
        fontSize: normalizeSize(50),
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
    },
    subtitle: {
        gap: normalizeSize(10),
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: normalizeSize(20),
        lineHeight: normalizeSize(20),
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: normalizeSize(50),
    },
    playerCode: {
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        fontSize: normalizeSize(16),
        ...rowStyles,
        alignItems: "center",
    },
    containerRight: {
        ...columnStartStyles,
        alignItems: "flex-start",
        paddingTop: normalizeVh(4),
        paddingBottom: normalizeSize(50),
    },
    containerSmall: {
        ...columnStartStyles,
        alignItems: "center",
        gap: normalizeSize(5),
        paddingTop: normalizeVh(4),
    },
    bodyStyle: {
        paddingTop: normalizeSize(0),
        height: "fit-content",
        minHeight: "unset",
        justifyContent: "center",
    },
    referralCategory: {
        fontSize: normalizeSize(20),
        fontFamily: Gilroy,
        color: Colors.WHITE,
        textAlign: "left",
        width: "100%",
        marginBottom: normalizeSize(10),
        marginTop: normalizeSize(10),
    },
    referralSubtitle: {
        fontSize: normalizeSize(16),
        fontFamily: Gilroy,
        color: Colors.OLD_PRICE,
    },
    // BOX EMPTY
    referralBoxEmpty: {
        ...rowCenterStyles,
        width: normalizeSize(300),
        height: normalizeSize(90),
        border: `3px dashed ${Colors.BTN_ACTIVE}`,
        borderRadius: normalizeSize(13),
        gap: normalizeSize(5),
    },
    referralBoxEmptyText: {
        fontSize: normalizeSize(16),
        fontFamily: Gilroy,
        color: Colors.PACK,
    },
    // BOX INVITEE REWARD
    referralBoxInvitee: {
        ...rowCenterStyles,
        width: normalizeSize(300),
        height: normalizeSize(90),
        borderRadius: normalizeSize(13),
        backgroundColor: Colors.BTN_ACTIVE,
        gap: normalizeSize(12),
        alignSelf: "center",
    },
    referralBoxInviteeText: {
        fontSize: normalizeSize(16),
        fontFamily: Gilroy,
        color: Colors.ACCENT,
    },
    // BOX REFERRAL
    referralBox: {
        width: normalizeSize(300),
        borderRadius: normalizeSize(13),
        backgroundColor: Colors.BTN_ACTIVE,
        minHeight: normalizeSize(90),
        alignItems: "center",
        flex: 1,
        ...rowStartStyles,
        flexShrink: 0,
        alignSelf: "center",
        columnGap: normalizeSize(12),
        padding: "8px 12px",
    },
    rewardBox: {
        width: normalizeSize(44),
        height: normalizeSize(44),
        borderRadius: normalizeSize(44),
        backgroundColor: Colors.PRIMARY,
        ...columnCenterStyles,
    },
    separator: {
        backgroundColor: Colors.FOND_ITEM_POPUP,
        width: normalizeSize(2),
        height: normalizeSize(50),
        borderRadius: normalizeSize(2),
    },
    userBoxInside: {
        ...rowStartStyles,
        columnGap: normalizeSize(12),
    },
    userText: {
        ...columnStyles,
        justifyContent: "center",
        alignItems: "flex-start",
        rowGap: normalizeSize(4),
    },
    userPseudo: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(16),
        lineHeight: normalizeSize(16),
        fontWeight: 600,
        color: Colors.WHITE,
        textAlign: "left",
    },
    userTitle: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        lineHeight: normalizeSize(12),
        fontWeight: 600,
        color: Colors.ACCENT,
        textAlign: "left",
    },
    levelPart: {
        ...rowStartStyles,
        columnGap: normalizeSize(4),
    },
    levelCircle: {
        width: normalizeSize(25),
        height: normalizeSize(25),
        borderRadius: normalizeSize(25),
        //border: `solid 2px ${Colors.ACCENT}`,
        ...columnCenterStyles,
        backgroundColor: Colors.PRIMARY,
        zIndex: 10,
    },
    levelText: {
        fontSize: normalizeSize(12),
        lineHeight: normalizeSize(12),
        fontFamily: Gilroy,
        color: Colors.ACCENT,
    },
    xp: {
        fontSize: normalizeSize(12),
        lineHeight: normalizeSize(12),
        fontFamily: Gilroy,
        color: Colors.WHITE,
    },
    // MODAL
    rewardBtn: {
        ...rowCenterStyles,
        border: `2px solid ${Colors.BTN_ACTIVE}`,
        backgroundColor: Colors.BTN_ACTIVE,
        padding: `${normalizeSize(6)} ${normalizeSize(0)}`,
        borderRadius: normalizeSize(7),
        width: normalizeSize(140),
        height: normalizeSize(44),
        zIndex: 2,
    },
    rewardBtnInside: {
        ...rowCenterStyles,
        textAlign: "center",
        gap: normalizeSize(8),
    },
    emptyReward: {
        width: normalizeSize(140),
    },
    rewardName: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        color: Colors.FOND_ITEM_POPUP,
    },
    modalView: {
        width: "400px",
        borderRadius: "20px",
        backgroundColor: Colors.PRIMARY,
        position: "relative",
        padding: "25px 0px",
        ...columnCenterStyles,
        zIndex: 50,
    },
    congrats: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(20),
        marginBottom: "20px",
        textAlign: "center",
        marginTop: "10px",
        padding: "0px 25px",
    },
    congrats2: {
        color: Colors.OLD_PRICE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
        textAlign: "center",
        marginTop: "10px",
    },
    rewardsList: {
        ...rowCenterStyles,
        gap: "20px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalizeSize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
};
