/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase, Box } from "@mui/material";
import DynamicTag from "../../components/DynamicTag";
import StoryCard from "../../components/StoryCard";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import { columnEndStyles, rowEndStyles, rowStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import TicketAnimated from "../../components/TicketAnimated";
import GradientBorderBox from "../../components/GradientBorderBox";
import GradientText from "../../components/GradientText";
import { isPositiveNumber } from "../../utils/NumberHelper";

export const LibraryCard = ({
    story,
    onSelectStory,
    type,
    isLast,
    width = "0px",
    height = "0px",
}: {
    story: any;
    onSelectStory: any;
    type: string;
    isLast: boolean;
    width: string;
    height: string;
}) => {
    if (type === "series") {
        return (
            <ButtonBase
                id={story.id}
                key={"series-" + story.id}
                onClick={() => {
                    onSelectStory(story);
                }}
                sx={{
                    position: "relative",
                    overflow: "hidden",
                    /*  width: story.isSaga ? `calc(${width} - 4px)` : width,
          height: story.isSaga ? `calc(${height} - 4px)` : height, */
                    /* width: width,
          height: height, */
                    borderRadius: "13px",
                    marginRight: isLast ? 0 : "16px",
                    flexShrink: 0,
                }}>
                <Box sx={[styles.tag, { ...rowEndStyles, columnGap: "7px" }]}>
                    {story.isSaga ? (
                        <DynamicTag
                            text={""}
                            textColor={Colors.vertALEXTESGRANDSMORTS}
                            icon={() => (
                                <GradientText
                                    color1="#E6FF00"
                                    color2="#60B2CE"
                                    style={styles.gradientText}
                                    background={`linear-gradient(147deg, #E6FF00 0%, #60B2CE 84.18%)`}>
                                    {strings.saga}
                                </GradientText>
                            )}
                            //style={styles.tag}
                        />
                    ) : null}
                    {isPositiveNumber(story.ownedEpisodes) &&
                    isPositiveNumber(story.totalEpisodes) &&
                    story.ownedEpisodes !== story.totalEpisodes ? (
                        <DynamicTag
                            text={
                                story.ownedEpisodes + "/" + story.totalEpisodes
                            }
                            textColor={Colors.OLD_PRICE}
                            //style={styles.tag}
                        />
                    ) : null}
                </Box>
                {story.isSaga ? (
                    <GradientBorderBox
                        /* color2="#60B2CE"
            color1="#60B2CE" */
                        //color1="#A6CBD7"
                        color2="#E6FF00"
                        color1="#60B2CE"
                        borderWidth={3}
                        borderRadius={13}
                        deg={147}
                        style={{ borderRadius: "13px", overflow: "hidden" }}>
                        <StoryCard
                            story={story}
                            width={
                                story.isSaga ? `calc(${width} - 6px)` : width
                            }
                            height={
                                story.isSaga ? `calc(${height} - 6px)` : height
                            }
                        />
                    </GradientBorderBox>
                ) : (
                    <StoryCard story={story} width={width} height={height} />
                )}
            </ButtonBase>
        );
    } else {
        return (
            <ButtonBase
                id={story.id}
                key={"story-" + story.id}
                onClick={() => {
                    onSelectStory(story);
                }}
                sx={{
                    position: "relative",
                    overflow: "hidden",
                    /* width: width,
          height: height, */
                    borderRadius: "13px",
                    marginRight: isLast ? 0 : "16px",
                    flexShrink: 0,
                }}>
                <Box sx={[styles.tag, { ...rowEndStyles, columnGap: "7px" }]}>
                    {story.isSolo ? (
                        <DynamicTag
                            text={"Solo"}
                            /* icon={() => (
                                <TicketAnimated
                                    style={{ width: 25, height: 25 }}
                                />
                            )} */
                            textColor={Colors.WHITE}
                            //style={styles.tag}
                        />
                    ) : null}
                    {!story.owned ? (
                        <DynamicTag
                            text={""}
                            icon={() => (
                                <TicketAnimated
                                    style={{ width: 25, height: 25 }}
                                />
                            )}
                            textColor={Colors.OLD_PRICE}
                            //style={styles.tag}
                        />
                    ) : null}
                </Box>
                <StoryCard story={story} width={width} height={height} />
            </ButtonBase>
        );
    }
};

const styles = {
    gradientText: {
        fontFamily: Gilroy,
        fontSize: `14px`,
        fontStyle: "normal",
        fontWeight: 500,
    },
    subtitle: {
        color: "#A6CBD7",
        fontSize: `${17}px`,
        fontFamily: "Gilroy-Bold",
        fontWeight: "400",
        //wordWrap: "break-word",
        textAlign: "start",
    },
    img: {
        position: "relative",
        ...columnEndStyles,
        "&.image-container::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0) 20%, #233E47 100%)`,
        },
    },
    tagText: {
        fontSize: `${16}px`,
    },
    tag: {
        position: "absolute",
        top: 10,
        right: 10,
        zIndex: 25,
    },
    sagaBtn: {
        width: "527px",
        height: "heightpx",
        ...rowStyles,
        borderRadius: "13px",
        alignItems: "center",
        overflow: "hidden",
        transition: "all 0.2s ease-in-out",
        filter: "drop-shadow(0px 12.0538px 12.0538px rgba(0, 0, 0, 0.25))",
    },
};
