import SoundManager from "..";
import { isTrueBoolean } from "../../../utils/TypeOfHelper";

class Sound {
  public id: string;
  public name: string;
  public audio: HTMLAudioElement;
  public category: string;
  public volume: number;
  public loop: boolean;

  constructor(
    id: string,
    name: string,
    audio: HTMLAudioElement,
    category: string,
    volume: number,
    loop = false
  ) {
    this.id = id;
    this.name = name;
    this.audio = audio;
    this.category = category;
    this.volume = volume;
    this.loop = loop;
    this.audio.volume = volume;
    this.audio.addEventListener("ended", () => {
      if (!this.loop) {
        this.stop(false);
        SoundManager.getInstance().removeSound(this.id);
      }
    });
  }

  public play(): void {
    if (
      (this.category === "music" &&
        !isTrueBoolean(SoundManager.getInstance().isMusicMuted)) ||
      this.category === "sfx" ||
      this.category === "loop" ||
      (this.category === "ambiance" &&
        !isTrueBoolean(SoundManager.getInstance().isSfxMuted))
    ) {
      if (this.name === "timer" || this.category === "sfx") {
        this.audio.play();
      } else {
        this.audio.volume = 0;
        this.audio.play();

        // Fade à l'entrée sur 0.3 secondes
        const fadeDuration = 0.3;
        const fadeStep = fadeDuration / 100;
        let currentVolume = 0;

        const fadeInInterval = setInterval(() => {
          if (currentVolume >= this.volume) {
            clearInterval(fadeInInterval);
          } else {
            currentVolume += fadeStep;
            this.audio.volume =
              currentVolume <= SoundManager.getInstance().getMaxVolume()
                ? currentVolume
                : SoundManager.getInstance().getMaxVolume();
          }
        }, fadeStep * 800);
      }
    }
  }

  public stop(fade = true): void {
    if (fade) {
      // Effectuer le fondu lors de l'arrêt
      this.audio.volume = this.volume;
      const fadeOutInterval = setInterval(() => {
        if (this.audio.volume > 0.01) {
          this.audio.volume -= 0.01;
        } else {
          this.audio.volume = 0;
          this.audio.pause();
          clearInterval(fadeOutInterval);
        }
      }, 10);
    } else {
      // Arrêt direct sans fondu
      this.audio.pause();
    }
  }
}

export default Sound;
