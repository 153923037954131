export const testJson = {
  v: '5.5.7',
  meta: { g: 'LottieFiles AE ', a: '', k: '', d: '', tc: '' },
  fr: 60,
  ip: 0,
  op: 600,
  w: 1131,
  h: 2436,
  nm: 'star_composition',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Vector',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 149,
                  s: [24],
                },
                { t: 299, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [74.657, 73.269, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 0,
                  s: [40, 40, 100],
                },
                { t: 149, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: 'a',
              pt: {
                a: 0,
                k: {
                  i: [
                    [19.726, 0],
                    [0, -19.737],
                    [-19.726, 0],
                    [0, 19.737],
                  ],
                  o: [
                    [-19.726, 0],
                    [0, 19.737],
                    [19.726, 0],
                    [0, -19.737],
                  ],
                  v: [
                    [0, -35.737],
                    [-35.717, 0],
                    [0, 35.737],
                    [35.717, 0],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: 'Mask 1',
            },
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -6.227],
                        [6.422, -0.285],
                        [0, 6.263],
                        [-6.429, 0.285],
                      ],
                      o: [
                        [0, 6.67],
                        [-6.429, 0.292],
                        [0, -6.67],
                        [6.429, -0.285],
                      ],
                      v: [
                        [11.906, 0.003],
                        [-0.004, 11.902],
                        [-11.906, 0.003],
                        [-0.004, -11.902],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.392156869173, 0.172549024224, 0.886274516582, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Vector',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 29,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1.178] },
                  o: { x: [0.333], y: [0] },
                  t: 60,
                  s: [60],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [1.178] },
                  t: 72,
                  s: [80],
                },
                { t: 390, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 48, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 5.835],
                        [0, -5.821],
                      ],
                      o: [
                        [0, -5.835],
                        [0, 5.807],
                      ],
                      v: [
                        [-4.524, 0.003],
                        [4.524, 0.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.396078437567, 0.388235300779, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Vector',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 58,
                  s: [16],
                },
                {
                  i: { x: [0.667], y: [0.675] },
                  o: { x: [0.333], y: [0] },
                  t: 180,
                  s: [40],
                },
                { t: 391, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [36.65, 37.158, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 5.828],
                        [0, -5.821],
                      ],
                      o: [
                        [0, -5.828],
                        [0, 5.835],
                      ],
                      v: [
                        [-4.524, -0.003],
                        [4.524, -0.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.392156869173, 0.172549024224, 0.886274516582, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Vector',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 29,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 109,
                  s: [60],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 208,
                  s: [80],
                },
                { t: 360, s: [10] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [30, 30, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 2.14],
                        [0, -2.14],
                      ],
                      o: [
                        [0, -2.14],
                        [0, 2.14],
                      ],
                      v: [
                        [-1.666, 0],
                        [1.666, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.396078437567, 0.388235300779, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'blue 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 299,
                  s: [48],
                },
                { t: 599, s: [5] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [903.332, 2127.87, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0,
                        0.725,
                        0.388,
                        1,
                        0.5,
                        0.56,
                        0.388,
                        1,
                        1,
                        0.396,
                        0.388,
                        1,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [0, 0], ix: 5 },
                  e: { a: 0, k: [2.38, 0], ix: 6 },
                  t: 2,
                  h: { a: 0, k: 0, ix: 7 },
                  a: { a: 0, k: 0, ix: 8 },
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'purple 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 299,
                  s: [48],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 599,
                  s: [5],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [787.384, 99.021, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.06],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.06],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.439215689898, 0.090196080506, 0.737254917622, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'pink 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 299,
                  s: [48],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 599,
                  s: [5],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1015.384, 859.868, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.724596321583, 0.388235330582, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'blue 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 300,
                  s: [5],
                },
                { t: 599, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [671.332, 499.87, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0,
                        0.725,
                        0.388,
                        1,
                        0.5,
                        0.56,
                        0.388,
                        1,
                        1,
                        0.396,
                        0.388,
                        1,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [0, 0], ix: 5 },
                  e: { a: 0, k: [2.38, 0], ix: 6 },
                  t: 2,
                  h: { a: 0, k: 0, ix: 7 },
                  a: { a: 0, k: 0, ix: 8 },
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'pink 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [36],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 300,
                  s: [5],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 599,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [279.384, 1071.868, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.724596321583, 0.388235330582, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'purple 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [36],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 300,
                  s: [5],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 599,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [679.384, 2143.021, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.06],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.06],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.439215689898, 0.090196080506, 0.737254917622, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'blue 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 299,
                  s: [47],
                },
                { t: 599, s: [8] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [919.332, 1403.87, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0,
                        0.725,
                        0.388,
                        1,
                        0.5,
                        0.56,
                        0.388,
                        1,
                        1,
                        0.396,
                        0.388,
                        1,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [0, 0], ix: 5 },
                  e: { a: 0, k: [2.38, 0], ix: 6 },
                  t: 2,
                  h: { a: 0, k: 0, ix: 7 },
                  a: { a: 0, k: 0, ix: 8 },
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'purple 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.659], y: [1.074] },
                  o: { x: [0.297], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.815], y: [1] },
                  o: { x: [0.425], y: [0.088] },
                  t: 299,
                  s: [42.415],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 599,
                  s: [8],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [267.384, 131.021, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.06],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.06],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.439215689898, 0.090196080506, 0.737254917622, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'pink 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.659], y: [1.074] },
                  o: { x: [0.297], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.815], y: [1] },
                  o: { x: [0.425], y: [0.088] },
                  t: 299,
                  s: [42.415],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 599,
                  s: [8],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [455.384, 711.868, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.724596321583, 0.388235330582, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'blue 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 300,
                  s: [10],
                },
                { t: 599, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [179.332, 1975.87, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0,
                        0.725,
                        0.388,
                        1,
                        0.5,
                        0.56,
                        0.388,
                        1,
                        1,
                        0.396,
                        0.388,
                        1,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [0, 0], ix: 5 },
                  e: { a: 0, k: [2.38, 0], ix: 6 },
                  t: 2,
                  h: { a: 0, k: 0, ix: 7 },
                  a: { a: 0, k: 0, ix: 8 },
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'pink 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [36],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 300,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 599,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [467.384, 299.868, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.724596321583, 0.388235330582, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'purple 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [36],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 300,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 599,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [15.384, 931.021, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.06],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.06],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.439215689898, 0.090196080506, 0.737254917622, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'blue 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 299,
                  s: [48],
                },
                { t: 599, s: [5] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [295.332, 1491.87, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0,
                        0.725,
                        0.388,
                        1,
                        0.5,
                        0.56,
                        0.388,
                        1,
                        1,
                        0.396,
                        0.388,
                        1,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [0, 0], ix: 5 },
                  e: { a: 0, k: [2.38, 0], ix: 6 },
                  t: 2,
                  h: { a: 0, k: 0, ix: 7 },
                  a: { a: 0, k: 0, ix: 8 },
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'purple 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 299,
                  s: [48],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 599,
                  s: [5],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [199.384, 2235.021, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.06],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.06],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.439215689898, 0.090196080506, 0.737254917622, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'pink 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [10],
                },
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 299,
                  s: [48],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 599,
                  s: [5],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [203.384, 351.868, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.724596321583, 0.388235330582, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'blue',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 300,
                  s: [10],
                },
                { t: 599, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [819.332, 303.87, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0,
                        0.725,
                        0.388,
                        1,
                        0.5,
                        0.56,
                        0.388,
                        1,
                        1,
                        0.396,
                        0.388,
                        1,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [0, 0], ix: 5 },
                  e: { a: 0, k: [2.38, 0], ix: 6 },
                  t: 2,
                  h: { a: 0, k: 0, ix: 7 },
                  a: { a: 0, k: 0, ix: 8 },
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'pink',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [36],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 300,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 599,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [795.384, 915.868, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.067],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.067],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.724596321583, 0.388235330582, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'purple',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [36],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 300,
                  s: [10],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 599,
                  s: [47],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 899,
                  s: [0],
                },
                { t: 1198, s: [47] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [551.384, 1755.021, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.06],
                        [0, -3.06],
                      ],
                      o: [
                        [0, -3.06],
                        [0, 3.067],
                      ],
                      v: [
                        [-2.38, -0.001],
                        [2.38, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.439215689898, 0.090196080506, 0.737254917622, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [300, 300], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Vector',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 601,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 49,
              s: [345.5, 1706, 0],
              to: [0, 0, 0],
              ti: [-178, 130, 0],
            },
            { t: 485, s: [761.5, 1766, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 9,
      op: 609,
      st: 9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 143,
              s: [69.5, 446, 0],
              to: [0, 0, 0],
              ti: [26, -222, 0],
            },
            { t: 656, s: [341.5, 790, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 140,
      op: 740,
      st: 140,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 477,
              s: [717.5, 1494, 0],
              to: [0, 0, 0],
              ti: [0.001, -0.001, 0],
            },
            { t: 900, s: [829.5, 1766, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 384,
      op: 984,
      st: 384,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 415,
              s: [797.5, 770, 0],
              to: [0.001, -0.001, 0],
              ti: [0, 0, 0],
            },
            { t: 838, s: [1009.5, 562, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 322,
      op: 922,
      st: 322,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 126,
              s: [-82.5, 1946, 0],
              to: [0.001, -0.001, 0],
              ti: [0, 0, 0],
            },
            { t: 549, s: [197.5, 2050, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 33,
      op: 633,
      st: 33,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 341,
              s: [325.5, 738, 0],
              to: [-102, -10, 0],
              ti: [-42, 114, 0],
            },
            { t: 764, s: [189.5, 474, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 303,
      op: 848,
      st: 248,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: 'bigstar',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 219,
              s: [161.5, 2138, 0],
              to: [0, 0, 0],
              ti: [-178, 130, 0],
            },
            { t: 655, s: [385.5, 1790, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [75, 75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 150,
      h: 150,
      ip: 179,
      op: 779,
      st: 179,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 434,
              s: [821.5, 1570, 0],
              to: [146.667, 130.667, 0],
              ti: [69.333, -118.667, 0],
            },
            { t: 900, s: [1009.5, 1970, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 355,
      op: 955,
      st: 355,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 162,
              s: [837.5, 706, 0],
              to: [-81.333, -37.333, 0],
              ti: [153.333, -58.667, 0],
            },
            { t: 628, s: [469.5, 690, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 83,
      op: 683,
      st: 83,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 299,
              s: [0],
            },
            { t: 349, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 300,
              s: [805.5, 2002, 0],
              to: [-81.333, -37.333, 0],
              ti: [41.333, 149.333, 0],
            },
            { t: 766, s: [557.5, 1634, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 221,
      op: 821,
      st: 221,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 50, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 1,
              s: [245.5, 242, 0],
              to: [-81.333, -37.333, 0],
              ti: [41.333, 149.333, 0],
            },
            { t: 467, s: [-2.5, -126, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: -78,
      op: 522,
      st: -78,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 269,
              s: [373.5, 1658, 0],
              to: [-81.333, -37.333, 0],
              ti: [-2.667, -138.667, 0],
            },
            { t: 735, s: [269.5, 2014, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 190,
      op: 790,
      st: 190,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 71,
              s: [909.5, 1266, 0],
              to: [106.667, 118.667, 0],
              ti: [-158.667, -134.667, 0],
            },
            { t: 616, s: [1229.5, 1218, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 71,
      op: 671,
      st: 71,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 279,
              s: [-50.5, 1266, 0],
              to: [106.667, 118.667, 0],
              ti: [-158.667, -134.667, 0],
            },
            { t: 552, s: [269.5, 1218, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 279,
      op: 879,
      st: 279,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 28,
              s: [269.5, 1218, 0],
              to: [-158.667, -134.667, 0],
              ti: [106.667, 118.667, 0],
            },
            { t: 573, s: [-50.5, 1266, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 28,
      op: 628,
      st: 28,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 257,
              s: [725.5, 690, 0],
              to: [129.333, -154.667, 0],
              ti: [-81.333, -37.333, 0],
            },
            { t: 802, s: [1101.5, 482, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 257,
      op: 857,
      st: 257,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 0,
      nm: 'medstar',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 372,
              s: [433.5, 2030, 0],
              to: [102.667, -93.333, 0],
              ti: [-78.667, 149.333, 0],
            },
            { t: 917, s: [565.5, 1618, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 372,
      op: 972,
      st: 372,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 586,
              s: [100],
            },
            { t: 595, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 360,
              s: [197.5, 270, 0],
              to: [96, 34.667, 0],
              ti: [-73.333, -159.333, 0],
            },
            { t: 753, s: [485.5, 578, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 360,
      op: 960,
      st: 360,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 68,
              s: [485.5, 578, 0],
              to: [-73.333, -159.333, 0],
              ti: [96, 34.667, 0],
            },
            { t: 461, s: [197.5, 270, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 68,
      op: 668,
      st: 68,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [669.5, 346, 0],
              to: [-5.333, 148.667, 0],
              ti: [-156, -17.333, 0],
            },
            { t: 393, s: [921.5, 742, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 569,
              s: [100],
            },
            { t: 590, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 368,
              s: [645.5, 1658, 0],
              to: [-5.333, 148.667, 0],
              ti: [-156, -17.333, 0],
            },
            { t: 761, s: [897.5, 2054, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 368,
      op: 968,
      st: 368,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 157,
              s: [829.5, 1762, 0],
              to: [-176, -109.333, 0],
              ti: [-73.333, 140.667, 0],
            },
            { t: 550, s: [565.5, 1218, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 157,
      op: 757,
      st: 157,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 81,
              s: [157.5, 1466, 0],
              to: [96, 34.667, 0],
              ti: [-73.333, 140.667, 0],
            },
            { t: 474, s: [565.5, 1218, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 81,
      op: 681,
      st: 81,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 0,
      nm: 'smallstar',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [565.5, 1218, 0],
              to: [-73.333, 140.667, 0],
              ti: [96, 34.667, 0],
            },
            { t: 393, s: [157.5, 1466, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [40, 40, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 80,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 0,
      nm: 'tinystar',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 257,
              s: [825.5, 318, 0],
              to: [0, 0.001, 0],
              ti: [0, -0.001, 0],
            },
            {
              i: { x: 0.667, y: 0.355 },
              o: { x: 0.333, y: 0.001 },
              t: 258,
              s: [865.499, 238.002, 0],
              to: [-27.934, 59.848, 0],
              ti: [68.197, -146.136, 0],
            },
            { t: 662, s: [451.299, 611.287, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [30, 30, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 60,
      h: 60,
      ip: 256,
      op: 856,
      st: 256,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 0,
      nm: 'tinystar',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0.754 },
              t: 47,
              s: [191.299, 831.287, 0],
              to: [68.197, -146.137, 0],
              ti: [-27.929, 59.848, 0],
            },
            { t: 452, s: [565.5, 538, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [30, 30, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 60,
      h: 60,
      ip: 46,
      op: 646,
      st: 46,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 0,
      nm: 'tinystar',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 277,
              s: [405.5, 1498, 0],
              to: [46.667, -100, 0],
              ti: [-46.667, 100, 0],
            },
            { t: 636, s: [685.5, 898, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [30, 30, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 60,
      h: 60,
      ip: 277,
      op: 877,
      st: 277,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 0,
      nm: 'tinystar',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 60,
              s: [725.5, 1178, 0],
              to: [-46.667, 126.667, 0],
              ti: [46.667, -126.667, 0],
            },
            { t: 419, s: [445.5, 1938, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [30, 30, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 60,
      h: 60,
      ip: 60,
      op: 660,
      st: 60,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 0,
      nm: 'allover',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [565.5, 1218, 0], ix: 2 },
        a: { a: 0, k: [565.5, 1218, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1131,
      h: 2436,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
