/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from './TypeOfHelper';

// =======================================================

export function isNotEmptyArray(arr: any) {
  return arr && isArray(arr) && arr.length > 0;
}

// =======================================================

export function countDuplicates(arr: any, propName: string) {
  const result = [...arr.reduce( (mp: any, o: any) => {
    if (!mp.has(o[propName])) mp.set(o[propName], { ...o, count: 0 });
    mp.get(o[propName]).count++;
    return mp;
  }, new Map).values()];

  return result;
}

// =======================================================

export function findByKeyValue(arr: any, key: string, value: string) {
  const result = arr.find((item: any) => {
    if (item[key] == value) {
      return true;
    }
  });

  return result;
}

// =======================================================

export function randomItem(arr: any) {
  return arr[Math.floor(Math.random() * arr.length)];
}

// =======================================================