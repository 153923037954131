/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { styles } from "../../Social/style";
import { Colors } from "../../../style";
import { useContext, useEffect } from "react";
import { isPositiveNumber } from "../../../utils/NumberHelper";
import { NotificationContext } from "../../../utils/ContextsHelper";
import { strings } from "../../../services/translation";

const SocialMenu = ({
    activeIndex,
    changeMenuActive,
}: {
    activeIndex: number;
    changeMenuActive: any;
}) => {
    const { hasNews, hasFriendRequest, getFriends } =
        useContext(NotificationContext);

    useEffect(() => {
        getFriends();
    }, []);

    return (
        <Box sx={styles.socialMenu}>
            <Box sx={styles.menuContainer}>
                <ButtonBase
                    onClick={() => changeMenuActive("online")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 0 ? styles.menuBtnActive : {},
                    ]}>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 0 ? { color: Colors.HEART } : {},
                        ]}>
                        {strings.onlineRoom}
                    </Typography>
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("friends")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 1 ? styles.menuBtnActive : {},
                    ]}>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 1 ? { color: Colors.WHITE } : {},
                        ]}>
                        {strings.friends.friends}
                    </Typography>
                    {isPositiveNumber(hasFriendRequest) ? (
                        <Box sx={styles.notifIcon} />
                    ) : null}
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("ladder")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 2 ? styles.menuBtnActive : {},
                    ]}>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 2
                                ? { color: Colors.BOARDGAME }
                                : {},
                        ]}>
                        {strings.ladder.ladder}
                    </Typography>
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("news")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 3 ? styles.menuBtnActive : {},
                    ]}>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 3 ? { color: Colors.PACK } : {},
                        ]}>
                        {strings.news}
                    </Typography>
                    {isPositiveNumber(hasNews) ? (
                        <Box sx={styles.notifIcon} />
                    ) : null}
                </ButtonBase>
            </Box>
        </Box>
    );
};

export default SocialMenu;
