/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Modal } from "@mui/material";
import { CompanionEmpty } from "../CompanionEmpty";
import { CompanionSingle } from "../CompanionSingle";
import { styles } from "../styles";
import { useState } from "react";
import { BuyCompanionSlotModal } from "../BuyCompanionSlotModal";
import { Companion } from "../../../models/companion";

export const CompanionList = (
    {
        companions,
        setActiveCompanion,
        purchaseSlotCallback,
        setActiveCompanionIndex = null,
        forceRowList = false,
        selectedCompanions = null,
    }:
        {
            companions: any,
            activeCompanion: any,
            setActiveCompanion: any,
            setActiveCompanionIndex?: any,
            purchaseSlotCallback: any,
            forceRowList?: boolean,
            selectedCompanions?: Companion[] | null,
        }) => {

    const [showPurchaseSlotModal, setShowPurchaseSlotModal] = useState(false);

    const closeModalWithCallback = async () => {
        setShowPurchaseSlotModal(false);
        await purchaseSlotCallback();
    }

    return (
        <Box sx={styles.companionsList}>
            {companions && companions.length && companions.map((companion: any, index: number) => (
                <Box key={"companion-" + index}>
                    {!companion.isEmpty ? (
                        <ButtonBase onClick={() => {
                            setActiveCompanion(companion);
                            setActiveCompanionIndex && setActiveCompanionIndex(index);
                        }}>
                            <CompanionSingle
                                companion={companion}
                                forceRowList={forceRowList}
                                selected={(selectedCompanions && selectedCompanions.includes(companion)) ? true : false}
                            />
                        </ButtonBase>
                    ) : (
                        <ButtonBase onClick={() => setShowPurchaseSlotModal(true)}>
                            <CompanionEmpty />
                        </ButtonBase>
                    )}
                </Box>
            ))}
            <Modal open={showPurchaseSlotModal} onClose={() => setShowPurchaseSlotModal(false)}>
                <Box>
                    <BuyCompanionSlotModal callback={closeModalWithCallback} />
                </Box>
            </Modal>
        </Box>
    )
}