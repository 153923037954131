import { Box, ButtonBase } from "@mui/material";
//ICONS
import { ReactComponent as IconItem } from "../../assets/icons/icon_item.svg";
import { ReactComponent as IconBust } from "../../assets/icons/icon_bust.svg";
import { ReactComponent as IconHat } from "../../assets/icons/icon_hat.svg";
import { ReactComponent as IconMask } from "../../assets/icons/icon_mask.svg";
import { ReactComponent as IconBackground } from "../../assets/icons/icon_background.svg";
import { Colors } from "../../style";
import { rowCenterStyles } from "../../style/flex";

/* eslint-disable @typescript-eslint/no-explicit-any */
const AvatarItemMenu = ({
    activeIndex,
    changeMenuActive,
}: {
    activeIndex: number
    changeMenuActive: any
}) => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.iconsMenu} mt={5}>
                <ButtonBase
                    onClick={() => changeMenuActive("chapeau")}
                    sx={activeIndex === 0 ? styles.iconActive : styles.iconInactive}>
                    <IconHat
                        width={32}
                        height={34}
                        fill={activeIndex === 0 ? Colors.WHITE : Colors.LOCK}
                    />
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("buste")}
                    sx={activeIndex === 1 ? styles.iconActive : styles.iconInactive}>
                    <IconBust
                        width={32}
                        height={34}
                        fill={activeIndex === 1 ? Colors.WHITE : Colors.LOCK}
                    />
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("masque")}
                    sx={activeIndex === 2 ? styles.iconActive : styles.iconInactive}>
                    <IconMask
                        width={32}
                        height={34}
                        fill={activeIndex === 2 ? Colors.WHITE : Colors.LOCK}
                    />
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("perso")}
                    sx={activeIndex === 3 ? styles.iconActive : styles.iconInactive}>
                    <IconItem
                        width={32}
                        height={34}
                        fill={activeIndex === 3 ? Colors.WHITE : Colors.LOCK}
                    />
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("fond")}
                    sx={activeIndex === 4 ? styles.iconActive : styles.iconInactive}>
                    <IconBackground
                        width={32}
                        height={34}
                        fill={activeIndex === 4 ? Colors.WHITE : Colors.LOCK}
                    />
                </ButtonBase>
            </Box>
        </Box>
    )

}

const styles = {
    container: {
        ...rowCenterStyles,
        width: "100%",
        borderBottom: `2px solid ${Colors.BTN_ACTIVE}`,
    },
    iconsMenu: {
        ...rowCenterStyles,
        width: "80%",
    },
    iconActive: {
        marginBottom: "-2px",
        paddingBottom: "1.5vh",
        borderBottom: `2px solid ${Colors.WHITE}`,
        width: "20%",
        ...rowCenterStyles,
    },
    iconInactive: {
        marginBottom: "-2px",
        width: "20%",
        ...rowCenterStyles,
    },
}

export default AvatarItemMenu;