/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import normalize from "../../utils/fontSizer";
import PromoStoryShop from "../promoStoryShop";

import { Colors } from "../../style";
import CoverTitleSmallFlexible from "../coverTitleSmallFlexible";
import { getLanguage, strings } from "../../services/translation";
import TicketAnimated from "../TicketAnimated";
import TokenAnimated from "../TokenAnimated";
import { isTrueBoolean } from "../../utils/TypeOfHelper";
import DynamicTag from "../DynamicTag";
import { getRouteManager } from "../../services/routeManager";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon_achievement.svg";
import moment from "moment";
import { Box, ButtonBase, LinearProgress, Typography } from "@mui/material";
import SoundManager from "../../services/SoundManager";
import StoryInfoModal from "../StoryInfoModal";
import { styles } from "./style";

interface Props {
  number: string;
  goNext: any;
  episode: any;
}

const EpisodeAnimatedSaga = (props: Props) => {
  const { number, goNext, episode } = props;

  if (!episode.story) {
    episode.story = {};
  }
  episode.story.epNumber = number;
  const progress =
    episode.story?.nbDone && episode.story?.success
      ? (episode.story.nbDone / episode.story.success.length) * 100
      : 0;
  const cover = (getLanguage() === 'fr' && episode.releaseDate) || (getLanguage() !== 'fr' && episode.releaseDateEn)
    ? null
    : getRouteManager().cover(episode.storyId);
  const title = (getLanguage() === 'fr' && episode.releaseDate) || (getLanguage() !== 'fr' && episode.releaseDateEn)
    ? null
    : getRouteManager().titleForStory(getLanguage(), episode.storyId);

  episode.nbUnlock = episode.story?.nbDone ? episode.story.nbDone : 0;
  episode.nbSuccess = episode.story?.success?.length
    ? episode.story.success.length
    : 0;
  episode.success = episode.story?.success ? episode.story.success : [];
  episode.story.description = episode.story?.resume ? episode.story.resume : "";
  episode.story.image_backdrop = cover
    ? cover
    : getRouteManager().getCoverForSeries(episode.seriesId);
  const [showStoryInfo, setShowStoryInfo] = useState(false);
  const [priceState, setPriceState] = useState<any>(episode.story.price);
  episode.story.storyTrad = {
    resume: episode.story.description,
    intro: episode.story.intro,
    title: episode.story.title,
  };
  episode.story.nbUnlock = episode.nbUnlock;
  episode.story.nbSuccess = episode.nbSuccess;

  useEffect(() => {
    setPriceState(episode.story.price);
  }, [episode]);

  const closeModal = () => {
    setShowStoryInfo(false);
    SoundManager.getInstance().playCloseModalSound();
  };

  return (
    <ButtonBase
      sx={[styles.container, { height: 159.75 }]}
      onClick={() => {
        if (!showStoryInfo) {
          if ((getLanguage() === 'fr' && !episode.releaseDate) || (getLanguage() !== 'fr' && !episode.releaseDateEn)) {
            setShowStoryInfo(true);
          }
        }
      }}>
      <Box sx={styles.buyAll}>
        <Box sx={styles.leftPart}>
          <Box sx={styles.coverContainer}>
            {episode.realeaseDate ? (
              <CoverTitleSmallFlexible
                cover={null}
                title={null}
                height={"139.5px"}
                width={"86px"}
                isAnimated={false}
                maxWidth={"115px"}
                maxHeight={"186px"}
                minWidth={"86px"}
                minHeight={"139.5px"}
              />
            ) : (
              <CoverTitleSmallFlexible
                cover={cover}
                title={null}
                height={"139.5px"}
                width={"86px"}
                isAnimated={false}
                maxWidth={"115px"}
                maxHeight={"186px"}
                minWidth={"86px"}
                minHeight={"139.5px"}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={[
            styles.rightPart,
            {
              background: `linear-gradient(90deg, #17181C 0%, rgba(23, 24, 28, 0.7) 100%), no-repeat center/100% url(${cover})`,
            },
          ]}>
          {(getLanguage() === 'fr' &&  episode.releaseDate) || (getLanguage() !== 'fr' &&  episode.releaseDateEn) ? (
            <Box sx={styles.priceContainer}>
              <DynamicTag
                text={strings.episode + " " + number}
                style={styles.tag}
              />
              <Typography
                sx={[styles.socialTitle, { color: Colors.OLD_PRICE }]}>
                {strings.comingSoon}
              </Typography>
              <Typography
                sx={[styles.normalTextOld, { fontSize: normalize(12) }]}>
                {getLanguage() === "fr"
                  ? moment(episode.releaseDate).format("DD/MM/YYYY")
                  : moment(episode.releaseDateEn).format("YYYY/MM/DD")}
              </Typography>
            </Box>
          ) : priceState === 0 ? (
            <Box sx={styles.priceContainer}>
              <DynamicTag
                text={strings.episode + " " + number}
                style={styles.tag}
              />
              <Typography sx={styles.socialTitle}>
                {episode.story.title}
              </Typography>
              <Box sx={styles.rowCenter}>
                <Box sx={styles.progressBar}>
                  {episode.story.nbDone === episode.story.success.length ? (
                    <Box sx={styles.achievementNumber}>
                      <IconSuccess
                        width={20}
                        height={20}
                        fill={Colors.ACCENT}
                        style={{ marginBottom: 7, marginRight: 7 }}
                      />

                      <Typography sx={styles.normalTextAccent}>
                        {episode.story.nbDone ? episode.story.nbDone : "0"}/
                        {episode.story.success.length
                          ? episode.story.success.length
                          : "0"}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={styles.achievementNumber}>
                      <IconSuccess
                        width={20}
                        height={20}
                        fill={Colors.OLD_PRICE}
                        style={{ marginBottom: 7, marginRight: 7 }}
                      />

                      <Typography component={"span"} sx={styles.normalTextOld}>
                        {episode.story.nbDone ? (
                          <Typography
                            component={"span"}
                            sx={styles.normalTextAccent}>
                            {" "}
                            {episode.story.nbDone}{" "}
                          </Typography>
                        ) : (
                          "0"
                        )}
                        /
                        {episode.story.success.length
                          ? episode.story.success.length
                          : "0"}
                      </Typography>
                    </Box>
                  )}
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    color={"primary"}
                    sx={{
                      width: "150px",
                      maxWidth: "200px",
                      minWidth: "150px",
                      height: "6.6px",
                      borderRadius: "6.6px",
                      backgroundColor: Colors.PRIMARY,
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: Colors.ACCENT,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ) : episode.promo && episode.promo.discount ? (
            <PromoStoryShop title={title} />
          ) : (
            <Box sx={styles.priceContainer}>
              <DynamicTag
                text={strings.episode + " " + number}
                style={styles.tag}
              />
              <Typography sx={styles.socialTitle}>
                {episode.story.title}
              </Typography>
              <Box sx={styles.rowCenter}>
                {isTrueBoolean(episode.story.buyWithTokens) &&
                  episode.story.priceTokens > 0 ? (
                  <>
                    <Box sx={styles.tokensOwned}>
                      <TokenAnimated style={{ width: 25, height: 25 }} />
                      <Typography sx={styles.tokensAmountOwned}>
                        {episode.story.priceTokens}
                      </Typography>
                    </Box>
                    <Box sx={styles.separatorPrice} />
                  </>
                ) : null}
                <Box sx={styles.ticketsOwned}>
                  <TicketAnimated style={{ width: 25, height: 25 }} />
                  <Typography sx={styles.storyPrice}>{priceState}</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {episode.story ? (
        <StoryInfoModal
          active={episode.story}
          showStoryInfo={showStoryInfo}
          onCloseStoryInfo={closeModal}
          callback={() => {
            setShowStoryInfo(false);
            goNext(episode);
          }}
        />
      ) : null}
    </ButtonBase>
  );
};

export default EpisodeAnimatedSaga;
