import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import { styles } from "../styles";
import { strings } from "../../../../services/translation";
import { ReactComponent as IconPlayer } from "../../../../assets/icons/icon_more.svg";
import { Colors } from "../../../../style";
import useAuth from "../../../../services/Auth/useAuth";

export const ReferralBoxEmpty = () => {
    const auth = useAuth();

    const onInvite = () => {
        const { player_code } = auth.user;
        if (player_code) {
            const title = strings.referral.joinMe;
            const body =
                strings.referral.joinMeBoby +
                "%0A%0A" +
                strings.referral.joinMeBoby2 +
                "%0A" +
                strings.referral.joinMeBoby3 +
                player_code;
            window
                .open(`mailto:?subject=${title}&body=${body}`, "_blank")
                ?.focus();
        }
    };

    /**
     *
     * RENDER
     *
     */

    return (
        <Box>
            <ButtonBase onClick={() => onInvite()} sx={styles.referralBoxEmpty}>
                <IconPlayer width={40} height={40} fill={Colors.PACK} />
                <Typography sx={styles.referralBoxEmptyText}>
                    {strings.referral.referFriend}
                </Typography>
            </ButtonBase>
        </Box>
    );
};
