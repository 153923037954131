/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from "react";
import ColorManager from "../../services/colorManager";
import Highlighter from "react-highlight-words";
import { ReactComponent as NextIcon } from "../../assets/icons/icon_next.svg";
import SoundManager from "../../services/SoundManager";
import StoryReader from "../../services/StoryReader";
import styles from "./styles";
import _, { isFunction } from "lodash";
import { isBoolean } from "lodash";
import { isNumber, isTrueBoolean } from "../../utils/TypeOfHelper";
import { ReactComponent as IconVoted } from "../../assets/icons/icon_voted.svg";
import { ReactComponent as IconBlocked } from "../../assets/icons/icon_blocked.svg";
import { isNotEmptyString } from "../../utils/StringHelper";
import { strings } from "../../services/translation";
import { Box, ButtonBase, Typography } from "@mui/material";
import Loading from "../Loading";
import { Player } from "@lottiefiles/react-lottie-player";
import { columnCenterStyles } from "../../style/flex";
import CircularProgressNew from "../CircularProgress/CircularProgressNew";

// =======================================================

interface Props {
  goNext: any;
  text: string;
  callback?: any;
  oneTap?: boolean;
  storyId: number;
  color: any;
  textColor?: any;
  secondCb?: any;
  ornament?: any;
  conditions?: any;
  sceneLink?: any;
  visitedScenes?: Array<number>;
  choiceColor?: any;
  storyReader?: StoryReader;
  choiceTimerSelect?: any;
  choiceIndex?: any;
  timerCallback?: any;
  nbrVoters?: number;
  percent?: any;
  loading?: boolean;
  disabled?: boolean;
  showPopUpIndicator?: any;
  disabledText?: string | null;
  animUrl?: string | null;
}

interface State {
  progress: any;
  jsonChoice: any;
  backgroundColor: any;
  choiceTimerSelectState: any;
  isPlayingAnimation: boolean;
  isPlayingAnimationTimer: boolean;
}


// =======================================================

class Choice extends Component<Props, State> {
  _isMounted = false;
  private playerRef: any;
  /**
   *
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      progress: 0,
      jsonChoice: props.animUrl,
      backgroundColor: props.color,
      choiceTimerSelectState: props.choiceTimerSelect,
      isPlayingAnimation: false,
      isPlayingAnimationTimer: false,
    };

    this.playerRef = React.createRef(); // initialize your ref
  }

  /**
   *
   */
  componentDidMount() {
    this._isMounted = true;
  }

  /**
   *
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   *
   */
  componentDidUpdate(prevProps: any) {
    const { choiceIndex, choiceTimerSelect } = this.props;
    if (prevProps.choiceTimerSelect !== choiceTimerSelect) {
      if (
        choiceTimerSelect != undefined &&
        choiceTimerSelect != -1 &&
        choiceTimerSelect === choiceIndex
      ) {
        if (this._isMounted) {
          this.setState({ choiceTimerSelectState: choiceTimerSelect });
        }
        this.timerAnim();
      }
    }
  }

  /**
   *
   */
  onFinishAnim = (shouldGoNext = true) => {
    this.setState({
      isPlayingAnimation: false,
      isPlayingAnimationTimer: false,
    });
    if (shouldGoNext) {
      this.onClick();
    }
  };

  /**
   *
   */
  timerAnim = () => {
    const { callback, goNext, timerCallback, oneTap, text, storyReader } = this.props;

    this.setState({ isPlayingAnimationTimer: true });

    if (oneTap) {
      if (callback) {
        callback();
      }

      if (timerCallback) {
        timerCallback();
      }

      goNext();
    } else {
      if (text === "DrawArrow") {
        this.onFinishAnim(!storyReader?._isOnlineMode);
      } else {
        setTimeout(() => {
          this.onFinishAnim(!storyReader?._isOnlineMode);
        }, 1000);
      }
      //this.playAnim();
    }

    //this.onFinishAnim();
  };

  /**
   *
   */
  onClick = () => {
    SoundManager.getInstance().playChoiceMadeSound();
    const { callback, goNext, timerCallback } = this.props;

    if (callback) {
      callback();
    }

    if (timerCallback) {
      timerCallback();
    }

    goNext();
  };

  playAnim = () => {
    if (this.hasShowPopUpIndicatorCallback()) {
      this.props.showPopUpIndicator(true);
    } else {
      const { isPlayingAnimation } = this.state;
      if (this.playerRef && this.playerRef.current && !isPlayingAnimation) {
        this.setState({ isPlayingAnimation: true }, () => {
          this.playerRef.current.play();
        });
      } else if (
        this.playerRef &&
        this.playerRef.current &&
        isPlayingAnimation
      ) {
        this.playerRef.current.stop();
        this.setState({ isPlayingAnimation: false });
      }
    }
  };

  /**
   * Shortcut to determine if the `disabled` prop exists
   * and it's a true boolean
   */
  isDisabled = () => {
    return isBoolean(this.props.disabled) && isTrueBoolean(this.props.disabled);
  };

  /**
   * Shortcut to determine if the `showPopUpIndicator`
   * callback exists
   */
  hasShowPopUpIndicatorCallback = () => {
    return this.isDisabled() && isFunction(this.props.showPopUpIndicator);
  };

  /**
   *
   */
  getCurrentText(): any {
    const storyReader = this.props.storyReader;
    let txt = this.props.text;

    if (storyReader) {
      const playerIdentifier = "{PLAYER}";

      const needReplace = txt.includes(playerIdentifier);

      const hlText = this.getHlText2().arrToReturn;
      const minus = this.getHlText2().minus;

      for (let i = 0; i < hlText.length - minus; i += 1) {
        txt = txt.replace("$$", "");
        txt = txt.replace("$$", "");
      }

      if (needReplace) {
        txt = txt.replace(
          playerIdentifier,
          storyReader
            .getPlayers()
            .getPlayerName(storyReader.getActivePlayerId())
        );

        hlText.push(
          storyReader
            .getPlayers()
            .getPlayerName(storyReader.getActivePlayerId())
        );
      }

      const drawVar = /.*({VAR_(.*)}).*/.exec(txt);

      if (drawVar != null) {
        txt = txt.replace(
          drawVar[1],
          this.numberWithSpaces(
            storyReader
              .getVariables()
              .getVariableByName(`var_${_.capitalize(drawVar[2])}`).value
          )
        );
      }

      const drawPlayerState = /.*({STATE_(.*)}).*/.exec(txt);

      if (drawPlayerState != null) {
        const playerWithState = storyReader
          .getPlayers()
          .players.filter((player) =>
            player.hasState(`state_${_.capitalize(drawPlayerState[2])}`)
          );

        const playerName = playerWithState[0].GetName();

        txt = txt.replace(drawPlayerState[1], playerName);

        hlText.push(playerName);
      }

      return { txt, hlText };
    }
    return { txt: txt, hlText: [] };
  }

  /**
   *
   */
  numberWithSpaces(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  /**
   *
   */
  getHlText2(): any {
    const storyReader = this.props.storyReader;
    if (storyReader) {
      const txt = this.props.text;

      const hl = txt.match(/\$\$(.*?)\$\$/g);

      const arrToReturn = [];

      let minus = 0;

      if (hl !== null) {
        for (let i = 0; i < hl.length; i += 1) {
          hl[i] = hl[i].replace("$$", "");
          hl[i] = hl[i].replace("$$", "");
          arrToReturn.push(hl[i]);
        }
      }

      const drawVar = /.*({VAR_(.*)}).*/.exec(txt);

      if (drawVar != null) {
        arrToReturn.push(
          this.numberWithSpaces(
            storyReader
              .getVariables()
              .getVariableByName(`var_${_.capitalize(drawVar[2])}`).value
          )
        );

        minus += 1;
      }

      return { arrToReturn, minus };
    } else {
      return null;
    }
  }

  /**
   *
   */
  showIndicator = (): boolean => {
    const { ornament, conditions, sceneLink, visitedScenes } = this.props;

    if (ornament !== "EXPLO") {
      return false;
    }

    const foundInSceneLink = visitedScenes?.filter(
      (sceneNumber) => sceneNumber === sceneLink
    );

    if (sceneLink === null) {
      const founds = visitedScenes?.filter(
        (sceneNumber) =>
          sceneNumber === conditions[0].linkTrue ||
          sceneNumber === conditions[0].linkFalse
      );

      if (founds?.length === 0) {
        return true;
      }

      return false;
    }

    if (foundInSceneLink?.length === 0) {
      return true;
    }

    return false;
  };

  /**
   * Shortcut to determine if the `nbrVoters` property exists
   */
  hasVoters = () => {
    const { nbrVoters } = this.props;
    if (nbrVoters) {
      return isNumber(nbrVoters) && nbrVoters > 0;
    } else {
      return false;
    }
  };

  /**
   * Shortcut to draw small circle shape above choice button
   * to indicate the number of voters
   */
  drawVoters = () => {
    const { nbrVoters } = this.props;

    if (nbrVoters && this.hasVoters()) {
      const numbers = [];

      for (let i = 0; i < nbrVoters; i++) {
        numbers.push(i);
      }

      return numbers.map((num: any, key: any) => (
        <Box
          key={key}
          sx={[
            styles.voterShape,
            {
              backgroundColor: ColorManager.getInstance().getColor("tertiary"),
              borderColor: ColorManager.getInstance().getColor("secondary"),
            },
          ]}
        />
      ));
    }

    return null;
  };

  /**
   *
   */
  isLoading = () => {
    return isBoolean(this.props.loading) && this.props.loading;
  };

  renderNextIcon = () => {
    const { disabledText } = this.props;
    if (this.isLoading()) {
      return (
        <Loading
          color={ColorManager.getInstance().getColor("tertiary")}
          size={"small"}
        />
      );
    }

    if (isNotEmptyString(disabledText)) {
      if (disabledText === strings.messages.notConcerned) {
        return (
          <Box>
            <IconBlocked
              width={25}
              height={25}
              fillSecondary={ColorManager.getInstance().getColor("tertiary")}
            />
          </Box>
        );
      } else if (disabledText === strings.messages.alreadyVoted) {
        return (
          <Box>
            <IconVoted
              width={25}
              height={25}
              fillSecondary={ColorManager.getInstance().getColor("tertiary")}
            />
          </Box>
        );
      } else {
        return null;
      }
    }

    return (
      <NextIcon
        width={45}
        height={45}
        fill={ColorManager.getInstance().getColor("quinary")}
      />
    );
  };

  /**
   *
   */
  handlePressNext = () => {
    //SoundManager.getInstance().playChoiceMadeSound();

    this.onClick();
  };

  /**
   *
   */
  renderNextButton = (style: any = {}) => {
    return (
      <ButtonBase
        sx={[style, { ...columnCenterStyles, borderRadius: "100px" }]}
        onClick={() =>
          this.hasShowPopUpIndicatorCallback()
            ? this.props.showPopUpIndicator()
            : !this.isDisabled()
              ? this.handlePressNext()
              : {}
        }
        disabled={this.isLoading()}
      >
        {this.renderNextIcon()}
      </ButtonBase>
    );
  };

  /**
   *
   */
  renderButton = () => {
    const { text, color, textColor, percent } = this.props;

    const { isPlayingAnimationTimer } = this.state;

    if (text === "DrawArrow") {
      if (isNumber(percent)) {
        return (
          <CircularProgressNew
            percent={percent}
            color={ColorManager.getInstance().getColor("tertiary")}
            bgColor={ColorManager.getInstance().getColor("quinary")}
          >
            {this.renderNextButton()}
          </CircularProgressNew>
        );
      }

      return (
        <ButtonBase
          sx={[{ ...columnCenterStyles, borderRadius: "100px" }]}
          onClick={() => this.handlePressNext()}
          disabled={this.isLoading()}
        >
          {this.renderNextIcon()}
        </ButtonBase>
      );
    }

    // --
    return (
      <Box
        sx={[
          styles.button,
          {
            backgroundColor: isPlayingAnimationTimer ? color : "transparent",
            width: "22.9vh",
          },
          isPlayingAnimationTimer
            ? {
              borderStyle: "solid",
              borderWidth: "3px",
              borderColor: color,
            }
            : {
              /* borderStyle: "solid",
              borderWidth: "3px",
              borderColor: color, */
            },
        ]}
      >
        {this.hasVoters() ? (
          <Box sx={styles.voterWrapper}>{this.drawVoters()}</Box>
        ) : null}

        <ButtonBase
          onClick={() => this.playAnim()}
          sx={[styles.touchable]}
          disabled={isPlayingAnimationTimer || this.isLoading()}
        >
          <Box sx={styles.animationView}>
            {this.state.jsonChoice ? (
              <Player
                ref={this.playerRef}
                src={this.state.jsonChoice}
                onEvent={(event: any) => {
                  if (event === "complete") {
                    this.onFinishAnim();
                  }
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  overflow: "hidden",
                  height: "100%",
                }}
              />
            ) : null}
          </Box>

          {this.showIndicator() === true && (
            <Box
              sx={[
                styles.circle,
                {
                  right: "10px",
                  backgroundColor:
                    ColorManager.getInstance().getColor("tertiary"),
                },
              ]}
            />
          )}

          <Typography sx={[styles.text, textColor ? { color: textColor } : {}]}>
            {this.showIndicator() === true ? (
              <Highlighter
                highlightStyle={{
                  fontFamily: "Gilroy-Bold",
                  color,
                  backgroundColor: "transparent",
                }}
                searchWords={this.getCurrentText().hlText}
                textToHighlight={this.getCurrentText().txt}
              />
            ) : (
              <Highlighter
                highlightStyle={{
                  fontFamily: "Gilroy-Bold",
                  color,
                  backgroundColor: "transparent",
                }}
                searchWords={this.getCurrentText().hlText}
                textToHighlight={this.getCurrentText().txt}
              />
            )}
          </Typography>
        </ButtonBase>
      </Box>
    );
  };

  // --
  render() {
    return <Box>{this.renderButton()}</Box>;
  }
}

// =======================================================

export default Choice;
