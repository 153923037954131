/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { Box, ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../style";
import { Eczar } from "../../style/fonts";
import useAuth from "../../services/Auth/useAuth";
import { useState, useEffect } from "react";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import { COMPANIONS } from "../../constants";
import { styles } from "./styles";
import { CompanionList } from "./CompanionList";
import { Companion } from "../../models/companion";
import { EditCompanion } from "./EditCompanion";
//ICONS
import { ReactComponent as BackIcon } from "../../assets/icons/icon_back.svg";

const CompanionScreen = () => {
    /**
     *
     * STATES
     *
     * */

    const auth = useAuth();
    const [companions, setCompanions] = useState<Companion[] | null>(null);
    const [activeCompanion, setActiveCompanion] = useState<Companion | null>(
        null,
    );
    const [activeCompanionIndex, setActiveCompanionIndex] = useState(0);
    const [items, setItems] = useState<any>();

    /**
     *
     * USE EFFECTS
     *
     * */

    useEffect(() => {
        if (auth && auth.user) {
            initializeData();
        }
    }, []);

    /**
     * @initializeData
     * initialize data for companions & items
     */
    const initializeData = async () => {
        try {
            const token = auth.user.login_token;
            if (token) {
                setActiveCompanion(null);
                setActiveCompanionIndex(0);
                const apiUrl = getRouteManager().getCompanionsForUser();
                const data = await getRouteManager().fetchData(apiUrl, {
                    login_token: auth.user.login_token,
                    lang: getLanguage(),
                });
                if (data && data?.length) {
                    data.map((comp: any) => {
                        if (comp.title && comp.title?.titleTrads?.length) {
                            comp.title.name = comp.title.titleTrads[0].name;
                        }
                        comp.isEmpty = false;
                    });
                    const cpt = COMPANIONS.NBMAX - data.length;
                    for (let i = 0; i < cpt; i++) {
                        data.push({ isEmpty: true });
                    }
                    setCompanions(data);
                }
                await getItems();
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * @getItems
     * get all items, populate data + sort them
     */
    const getItems = async () => {
        const token = auth.user.login_token;
        if (token) {
            const apiItems =
                getRouteManager().getAllUserItemsForUserCompanion();
            const json = await getRouteManager().fetchData(`${apiItems}`, {
                login_token: token,
            });
            if (json && json.flashmessage != "error") {
                json.map((item: any, index: any) => {
                    item.key = index + 1;
                    item.displayOrder = item.id;

                    if (!item.unlocked) {
                        item.displayOrder += item.shopVisible ? 1000 : 10000;
                    }
                    const apiImage = getRouteManager().getItemImage(item.id);
                    item.image_backdrop = apiImage;
                    const apiImagePromo =
                        getRouteManager().getItemImageForVersion(
                            item.id,
                            "promo",
                        );
                    item.image_backdrop_promo = apiImagePromo;
                });
                const byValue = (a: any, b: any) =>
                    a.displayOrder - b.displayOrder;
                json.sort(byValue);
                setItems(json);
            }
        }
    };

    /**
     * @buyItemCallback
     * Callback when item is bought
     */
    const buyItemCallback = async (itemsCallback: any = undefined) => {
        const tmp = await getRouteManager().fetchGetUserInfos(auth.user);
        auth.setUser(tmp);
        await getItems();
    };

    /**
     * @purchaseSlotCallback
     * callback after purchase slot
     */
    const purchaseSlotCallback = async () => {
        await initializeData();
    };

    /**
     * @goToNextIndex
     * Take next index companion in list
     */
    const goToNextIndex = () => {
        const companionsCopy = companions?.length ? [...companions] : [];
        if (activeCompanion && companionsCopy && companionsCopy?.length) {
            const newIndex =
                activeCompanionIndex === (companionsCopy?.length || 0) - 1
                    ? 0 // Revenir au premier élément si on est à la fin de la liste
                    : (activeCompanionIndex + 1) %
                      (companionsCopy?.length || 1);

            if (newIndex != activeCompanionIndex) {
                if (companionsCopy[newIndex]) {
                    setActiveCompanionIndex(newIndex);
                    setActiveCompanion(companionsCopy[newIndex]);
                }
            }
        }
    };

    /**
     * @goToPrevIndex
     * Take prev index companion in list
     */
    const goToPrevIndex = () => {
        const companionsCopy = companions?.length ? [...companions] : [];
        if (activeCompanion && companionsCopy && companionsCopy?.length) {
            const newIndex =
                activeCompanionIndex === 0
                    ? companionsCopy?.length
                        ? companionsCopy.length - 1
                        : 0 // Revenir au dernier élément si companions existe
                    : (activeCompanionIndex - 1) %
                      (companionsCopy?.length || 1);
            if (newIndex != activeCompanionIndex) {
                if (companionsCopy[newIndex]) {
                    setActiveCompanionIndex(newIndex);
                    setActiveCompanion(companionsCopy[newIndex]);
                }
            }
        }
    };

    /**
     *
     * RENDER
     *
     * */

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <Typography
                    variant="h4"
                    style={{ color: Colors.WHITE, fontFamily: Eczar.semiBold }}>
                    {strings.companions.companions}
                </Typography>
                {activeCompanion ? (
                    <ButtonBase
                        onClick={() => setActiveCompanion(null)}
                        sx={styles.backButton}>
                        <BackIcon width={40} height={40} />
                    </ButtonBase>
                ) : null}
            </Box>

            {activeCompanion ? (
                <Box sx={styles.containerInside}>
                    <EditCompanion
                        companion={activeCompanion}
                        items={items}
                        changeActiveCompanion={() => {
                            return;
                        }}
                        buyItemCallback={buyItemCallback}
                        purchaseSlotCallback={purchaseSlotCallback}
                        goToNextIndex={goToNextIndex}
                        goToPrevIndex={goToPrevIndex}
                    />
                </Box>
            ) : (
                <CompanionList
                    companions={companions}
                    activeCompanion={activeCompanion}
                    setActiveCompanion={setActiveCompanion}
                    setActiveCompanionIndex={setActiveCompanionIndex}
                    purchaseSlotCallback={purchaseSlotCallback}
                />
            )}
        </Box>
    );
};

export default CompanionScreen;
