import { Box, Typography } from "@mui/material";
import { Colors } from "../../style";
import { columnStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";

/* eslint-disable react/no-unescaped-entities */
const Error = () => {
  return (
    <Box sx={{ ...columnStyles }}>
      <Typography variant="h4" style={{ color: Colors.WHITE, fontFamily: Gilroy }}>
        Oups 🙈 Cette page n'existe pas
      </Typography>
    </Box>
  );
};

export default Error;
