/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Colors } from "../../style";
import normalize from "../../utils/fontSizer";
import ColorManager from "../../services/colorManager";
import { Box, Typography } from "@mui/material";
import { columnStyles, rowStartStyles } from "../../style/flex";

interface Props {
  text: string;
  icon?: any;
  number?: any;
}

const InfoBoxObject = (props: Props) => {
  const { text, icon, number } = props;
  if (number && text) {
    return (
      <Box
        sx={[
          styles.objectiveBox,
          {
            backgroundColor: ColorManager.getInstance().getColor("quinary"),
          },
        ]}
      >
        {icon ? (
          <Box sx={styles.iconNumber}>
            {icon}
            <Box
              sx={[
                styles.number,
                {
                  backgroundColor:
                    ColorManager.getInstance().getColor("quinary"),
                },
              ]}
            >
              <Typography
                sx={[
                  styles.numberText,
                  { color: ColorManager.getInstance().getColor("tertiary") },
                ]}
              >
                {number ? number : 1}
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Box sx={{ ...columnStyles, justifyContent: "center", width: "100%" }}>
          {text ? (
            <Typography
              sx={[
                styles.infoTxt,
                { color: ColorManager.getInstance().getColor("quaternary") },
              ]}
            >
              {text}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

const styles = {
  objectiveBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
    borderRadius: "13px",
    padding: "12px 10px",
  },
  infoTxt: {
    width: "calc(100% - 30px)",
    marginLeft: "15px",
    fontSize: normalize(14),
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
  },
  iconNumber: {
    ...rowStartStyles,
    position: "relative",
  },
  number: {
    position: "absolute",
    left: "25px",
    bottom: 0,
    display: "flex",
    alignSelf: "flex-end",
    borderRadius: "100px",
    padding: "0px 3px",
    zIndex: 10,
  },
  numberText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
  },
};

export default InfoBoxObject;
