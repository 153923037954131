//export const apiUrl = 'http://10.22.16.191:8001/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
//export const apiUrl = 'https://preprod.talesup.io/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
//export const apiUrl = 'https://talesup.io/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
//export const apiUrl = 'http://192.168.1.11:8001/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
//export const apiUrl = 'http://192.168.1.16:8001/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
//export const apiUrl = 'http://172.20.10.3:8001/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
//export const apiUrl = 'http://192.168.1.152:8001/tool/api/4uWlAqZFdQ971EAMdWHkBccEeJe0McHC/';
const appVersion = "beta-web-1.0.3";
//const appVersion = "web-1.0.3";
export default appVersion;
