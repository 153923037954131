import { Colors } from "../../style";
import { columnStyles, rowCenterStyles } from "../../style/flex";
import normalize from "../../utils/fontSizer";

export const styles = {
  container: {
    flex: 1,
    zIndex: 1,
    ...rowCenterStyles,
    position: "relative",
    maxWidth: "100vw",
    overflow: "hidden",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  topMenuContainer: {
    position: "absolute",
    left: 0,
    width: "27.77vw",
    minWidth: "400px",
    maxWidth: "500px",
    height: "calc(100% - 40px)",
    margin: "20px 0px",
    zIndex: 900,
    borderLeftWidth: "0px",
    borderRadius: "0px 20px 20px 0px",
    boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
  },
  gameContainer: {
    display: "flex",
    zIndex: 1,
    flexDirection: "column",
    width: "60vh",
    maxWidth: "670px",
    minWidth: "500px",
    //height: "100vh",
    height: "fit-content",
    maxHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    padding: "24px 24px 24px",
    boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.35)",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    /* backdropFilter: "blur( 20px )",
    "-webkit-backdrop-filter": "blur( 10px )", */
    borderRadius: "20px",
  },
  playersList: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    zIndex: 300,
  },
  popBg: {
    position: "absolute",
    alignSelf: "center",
    top: "24px",
    padding: "8px 12px",
    borderRadius: "8px",
  },
  letter: {
    width: "40px",
    height: "40px",
    borderRadius: "40px",
    borderWidth: "2px",
    borderStyle: "solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    color: Colors.WHITE,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalViewBuyEpisode: {
    width: "400px",
    backgroundColor: Colors.PRIMARY,
    borderRadius: "20px",
    padding: "35px 25px 25px 25px",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 450,
  },
  separatorBuyEpisode: {
    width: "400px",
    height: 0,
    color: Colors.BTN_ACTIVE,
    borderColor: Colors.BTN_ACTIVE,
    borderWidth: "1px",
    marginTop: "25px",
  },
  popupButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    marginTop: "25px",
  },
  yeahButton: {
    backgroundColor: Colors.BTN_ACTIVE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    width: "120px",
    height: "40px",
    textAlign: "center",
    zIndex: 500,
  },
  yesText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.ACCENT,
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.BLACK,
    opacity: 0.9,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 0,
    zIndex: 5,
  },
  overlayTxt: {
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    color: Colors.WHITE,
  },
  overlayTxtTitle: {
    position: "absolute",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    color: Colors.WHITE,
    //top: SCREEN_HEIGHT > 750 ? bannerHeight : bannerHeight - 50,
  },
  noButton: {
    padding: "7px 11px",
    borderRadius: "10px",
    width: "100px",
  },
  noText: {
    fontSize: normalize(14),
    fontFamily: "Gilroy-Bold",
    color: Colors.WHITE,
    textAlign: "center",
  },
  modalContent: {
    ...columnStyles,
    alignItems: "center",
    width: "100%",
  },
  modalText: {
    fontSize: normalize(16),
    fontFamily: "Gilroy-Bold",
    color: Colors.OLD_PRICE,
    textAlign: "center",
    marginTop: "10px",
  },
  notificationList: {
    position: "absolute",
    top: "30px",
    alignSelf: "center",
    zIndex: 10,
    width: "30%",
    minWidth: "250px",
    maxWidth: "390px",
  },
};
