/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { OnlineGame } from "../../../models/onlineGame";
import { Colors } from "../../../style";
import {
    columnStyles,
    rowCenterStyles,
    rowStartStyles,
} from "../../../style/flex";
import { Gilroy } from "../../../style/fonts";
import { normalizeSize } from "../../../utils/fontSizer";
import { isNotEmptyString } from "../../../utils/StringHelper";
import StoryCard from "../../StoryCard";
import { getRouteManager } from "../../../services/routeManager";
import { getLanguage } from "../../../services/translation";
import { isNotEmptyArray } from "../../../utils/ArrayHelper";
import AvatarContainer from "../../AvatarContainer";
import { ReactComponent as IconCompanions } from "../../../assets/icons/icon_companion.svg";

const OnlineGameBox = ({
    game,
    onClick,
    disabled,
}: {
    game: OnlineGame;
    onClick: any;
    disabled: boolean;
}) => {
    /**
     *
     * FUNCTIONS
     *
     */

    return (
        <Box>
            <ButtonBase
                disabled={disabled}
                onClick={() => {
                    onClick(game);
                }}
                sx={[
                    styles.container,
                    {
                        background: `linear-gradient(90deg, #17181C 0%, rgba(23, 24, 28, 0.7) 100%), no-repeat center/100% url(${
                            game.story?.episode
                                ? getRouteManager().getCoverForSeries(
                                      game.story?.id,
                                  )
                                : getRouteManager().cover(game.story.id)
                        })`,
                    },
                ]}>
                <StoryCard
                    story={{
                        ...game.story,
                        image_backdrop: game.story?.episode
                            ? getRouteManager().getCoverForSeries(
                                  game.story?.id,
                              )
                            : getRouteManager().cover(game.story.id),
                        image_title: game.story?.episode
                            ? getRouteManager().titleForSeries(
                                  getLanguage(),
                                  game.story?.episode?.seriesId,
                              )
                            : getRouteManager().titleForStory(
                                  getLanguage(),
                                  game.story.id,
                              ),
                    }}
                    height={normalizeSize(85)}
                    width={normalizeSize(53)}
                    borderRadius={7}
                />
                <Box sx={styles.textContainer}>
                    <Box sx={styles.playerContainer}>
                        {game.hostPlayer ? (
                            <AvatarContainer
                                user={game.hostPlayer}
                                persoWidth={32}
                            />
                        ) : (
                            <Box sx={styles.circlePlaceholder} />
                        )}
                        <Typography sx={styles.playerName}>
                            {game.hostPlayer ? game.hostPlayer.username : "?"}
                        </Typography>
                    </Box>

                    {isNotEmptyString(game.story.title) ? (
                        <Typography sx={styles.storyName}>
                            {game.story.title}
                        </Typography>
                    ) : null}
                </Box>
                <Box sx={styles.nbPlayerContainer}>
                    <IconCompanions
                        width={16}
                        height={16}
                        fill={Colors.WHITE}
                    />
                    <Typography component={"span"} sx={styles.nbPlayer}>
                        <Typography
                            component={"span"}
                            sx={styles.nbPlayerActive}>
                            {isNotEmptyArray(game.players)
                                ? game.players.length
                                : "?"}
                        </Typography>
                        /
                        <Typography component={"span"} sx={styles.nbPlayer}>
                            {game.story?.playerMinLimit
                                ? game.story.playerMaxLimit
                                : "?"}
                        </Typography>
                    </Typography>
                </Box>
            </ButtonBase>
        </Box>
    );
};

const styles = {
    container: {
        ...rowStartStyles,
        gap: normalizeSize(16),
        width: normalizeSize(300),
        minHeight: normalizeSize(100),
        padding: "10px 13px 10px 8px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: normalizeSize(13),
        position: "relative",
    },
    cover: {
        width: normalizeSize(53),
        height: normalizeSize(84),
        borderRadius: normalizeSize(7),
        backgroundColor: Colors.BTN_INACTIVE,
    },
    textContainer: {
        ...columnStyles,
        justifyContent: "center",
        gap: normalizeSize(6),
    },
    playerContainer: {
        ...rowStartStyles,
        alignItems: "center",
        gap: normalizeSize(8),
    },
    circlePlaceholder: {
        width: normalizeSize(32),
        height: normalizeSize(32),
        borderRadius: normalizeSize(32),
        backgroundColor: Colors.BTN_INACTIVE,
    },
    playerName: {
        ...rowStartStyles,
        alignItems: "center",
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        color: Colors.WHITE,
    },
    storyName: {
        ...rowStartStyles,
        alignItems: "center",
        fontFamily: Gilroy,
        textAlign: "left",
        fontSize: normalizeSize(16),
        color: Colors.WHITE,
    },
    nbPlayerContainer: {
        ...rowCenterStyles,
        padding: "8px 12px",
        gap: normalizeSize(4),
        position: "absolute",
        top: normalizeSize(8),
        right: normalizeSize(8),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(7),
    },
    nbPlayer: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.OLD_PRICE,
    },
    nbPlayerActive: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.HEART,
    },
};

export default OnlineGameBox;
