/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { getRouteManager } from "../../../services/routeManager";
import { Colors } from "../../../style";
import {
    columnCenterStyles,
    columnStyles,
    rowCenterStyles,
    rowStyles,
} from "../../../style/flex";
import { Gilroy } from "../../../style/fonts";
import { Tickets } from "../../../models/tickets";
import useAuth from "../../../services/Auth/useAuth";
import { isNotEmptyString } from "../../../utils/StringHelper";
import { IN_APP_PURCHASE_WEB } from "../../../constants/parameters";
import { isTrueBoolean } from "../../../utils/TypeOfHelper";
import Loading from "../../../components/Loading";
import BackgroundRow from "../../../components/BackgroundRow";
import { strings } from "../../../services/translation";
import { ReactComponent as IconMenuTicket } from "../../../assets/icons/icon_menu_ticket.svg";
import { ticketPack1Json } from "../../../constants/ticketPacks/pack1";
import { ticketPack2Json } from "../../../constants/ticketPacks/pack2";
import { ticketPack3Json } from "../../../constants/ticketPacks/pack3";
import { ticketPack4Json } from "../../../constants/ticketPacks/pack4";
import TicketButton from "../../../components/TicketButton";
import SwitchMenu from "../../../components/SwitchMenu";
import { getEventManager } from "../../../services/EventManager";
import PassBtnBig from "../../../components/PassBtnBig";
import { isStrictlyPositiveNumber } from "../../../utils/NumberHelper";

const TicketShop = () => {
    const auth = useAuth();

    const abortController = new AbortController();
    const cancelSignal = abortController.signal;

    const [currency, setCurrency] = useState<string | null>("EUR");
    const [loading, setLoading] = useState(true);
    const [purchaseDisabled, setPurchaseDisabled] = useState(false);
    const [ticketsPacks, setTicketsPacks] = useState<Tickets[] | null>(null);
    const matches = useMediaQuery(`(min-width:${1148}px)`);

    useEffect(() => {
        checkCurrency();
    }, []);

    useEffect(() => {
        try {
            initTickets();
        } catch (err) {
            console.log(err);
        }
    }, [currency]);

    const checkCurrency = () => {
        const test = localStorage.getItem("currency");
        if (isNotEmptyString(test)) {
            setCurrency(test);
        }
    };

    const calculateBonus = (ppt1: number, ppt2: number) => {
        return Math.floor(((ppt1 - ppt2) / ppt2) * 100);
    };

    const initTickets = async () => {
        try {
            setLoading(true);
            const param =
                getRouteManager().getParamForType(IN_APP_PURCHASE_WEB);
            const res = await getRouteManager().postCheckParamValueNew(param);
            if (
                res &&
                res.value &&
                isTrueBoolean(res.value) &&
                auth &&
                auth.user
            ) {
                const json = await getRouteManager().fetchGetTickets(
                    auth.user,
                    cancelSignal,
                );

                if (json && json.length) {
                    const list = json.filter(
                        (item: any) =>
                            item.code !== "ADVENTURERPASS" &&
                            item.code !== "ADVENTURERPASSPLUS" &&
                            isStrictlyPositiveNumber(item.number),
                    );
                    const prev = list[0];
                    let prevPrice = null;
                    let prevTickets = null;
                    let prevPricePerTicket: number | null = null;
                    if (prev) {
                        prevPrice = parseFloat(
                            prev?.sku
                                ? prev.sku.localizedPrice
                                      .replace(",", ".")
                                      .match(/[\d\.\d]+/i)
                                : prev.price,
                        );
                        prevTickets = prev?.number ? prev.number : null;
                        prevPricePerTicket =
                            prevPrice && prevTickets
                                ? prevPrice / prevTickets
                                : null;
                    }
                    list.map((item: Tickets, index: number) => {
                        item.json =
                            index === 0
                                ? ticketPack2Json
                                : index === 1
                                ? ticketPack3Json
                                : index === 2
                                ? ticketPack4Json
                                : index === 3
                                ? ticketPack4Json
                                : ticketPack1Json;

                        item.image_backdrop = require(`../../../assets/images/ticketpack${
                            index + 1
                        }.png`);
                        item.image_backdrop_big = require(`../../../assets/images/ticketpackbig${
                            index + 1
                        }.png`);
                        item.key = index + 1;
                        const sanitizedPrice = item.price
                            .replace(",", ".")
                            .match(/[\d\.\d]+/i);
                        if (sanitizedPrice && sanitizedPrice[0]) {
                            const price = parseFloat(
                                sanitizedPrice[0]
                                    ? sanitizedPrice[0]
                                    : item.price,
                            );
                            const currTickets = item?.number
                                ? item.number
                                : null;
                            const pricePerTicket =
                                price && currTickets
                                    ? price / currTickets
                                    : null;
                            if (prevPricePerTicket && pricePerTicket) {
                                item.bonus = calculateBonus(
                                    prevPricePerTicket,
                                    pricePerTicket,
                                );
                            } else {
                                item.bonus =
                                    index === 1
                                        ? 8
                                        : index === 2
                                        ? 24
                                        : index === 3
                                        ? 45
                                        : null;
                            }

                            item.price =
                                currency === "EUR" ? `${price}€` : `$${price}`;
                        }
                    });
                    setTicketsPacks(list);
                } else if (
                    (json.flashmessage && json.flashmessage === "error") ||
                    !json.flashmessage
                ) {
                    console.log("error while retrieving tickets");
                }
            } else {
                setPurchaseDisabled(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const initPurchase = async (tickets: Tickets) => {
        try {
            if (auth && auth.user) {
                if (tickets && tickets.code) {
                    setLoading(true);
                    const apiPurchase = getRouteManager().purchaseTicketsWeb();
                    const purchase = await getRouteManager().fetchData(
                        apiPurchase,
                        {
                            login_token: auth.user.login_token,
                            code: tickets.code,
                            currency: currency,
                            //prefix: "http://localhost:3000",
                        },
                    );
                    if (purchase.url && isNotEmptyString(purchase.url)) {
                        const sanitizedPrice = tickets.price
                            .replace(",", ".")
                            .match(/[\d\.\d]+/i);
                        if (sanitizedPrice && sanitizedPrice[0]) {
                            const price = parseFloat(
                                sanitizedPrice[0]
                                    ? sanitizedPrice[0]
                                    : tickets.price,
                            );
                            getEventManager().logBeginCheckout(
                                price,
                                currency ? currency : "EUR",
                            );
                        }
                        window.location.href = purchase.url;
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const changeCurrency = (curr: string) => {
        setCurrency(curr);
        localStorage.setItem("currency", curr);
    };

    const menuItems = [
        {
            title: strings.currency.eur,
            callback: () => changeCurrency("EUR"),
            isActive: currency === "EUR",
            activeColor: Colors.WHITE,
        },
        {
            title: strings.currency.usd,
            callback: () => changeCurrency("USD"),
            isActive: currency === "USD",
            activeColor: Colors.WHITE,
        },
    ];

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            {purchaseDisabled ? (
                <Box
                    sx={{
                        paddingTop: "4vh",
                        width: "90%",
                        alignSelf: "center",
                        ...columnCenterStyles,
                    }}>
                    <Typography
                        sx={{
                            color: Colors.WHITE,
                            textAlign: "center",
                            fontSize: "24px",
                            fontFamily: Gilroy,
                            maxWidth: "1000px",
                        }}>
                        {
                            "Indisponible pour le moment 🤙 (en attendant, tu peux toujours acheter des tickets via l'application Tales Up, dispo sur iOS et Android)"
                        }
                    </Typography>
                </Box>
            ) : (
                <BackgroundRow
                    hasBackground={false}
                    style={{ height: "100%" }}
                    bodyStyle={{
                        paddingTop: "0px",
                        height: "fit-content",
                        minHeight: "unset",
                        justifyContent: "center",
                    }}
                    matchesValue={1148}
                    leftPartStyle={
                        !matches
                            ? {
                                  width: "100%",
                                  maxWidth: "unset",
                                  alignItems: "center",
                              }
                            : {}
                    }
                    leftPart={
                        <Box
                            sx={
                                matches
                                    ? styles.container
                                    : styles.containerSmall
                            }>
                            <Typography
                                sx={[
                                    styles.topMenuTitle,
                                    !matches && { textAlign: "center" },
                                ]}>
                                {strings.shop}
                            </Typography>
                            <Box
                                sx={[
                                    { ...rowStyles },
                                    matches && { marginBottom: "10px" },
                                    !matches && {
                                        justifyContent: "center",
                                        textAlign: "center",
                                    },
                                ]}>
                                <IconMenuTicket
                                    width={30}
                                    height={30}
                                    fill={Colors.OLD_PRICE}
                                />
                                <Typography
                                    sx={[
                                        styles.subtitle,
                                        {
                                            color: Colors.OLD_PRICE,
                                            marginLeft: "10px",
                                            marginBottom: "0px",
                                            alignSelf: "center",
                                        },
                                        !matches && {
                                            justifyContent: "center",
                                            textAlign: "center",
                                        },
                                    ]}>
                                    {strings.pass.pass +
                                        " & " +
                                        strings.tickets}
                                </Typography>
                            </Box>

                            <Typography
                                sx={[
                                    styles.subtitle,
                                    !matches && {
                                        justifyContent: "center",
                                        textAlign: "center",
                                    },
                                ]}>
                                {strings.shopSubtitleTickets}
                            </Typography>

                            <Typography
                                sx={[
                                    styles.subtitle,
                                    !matches && {
                                        justifyContent: "center",
                                        textAlign: "center",
                                    },
                                ]}>
                                {strings.shopSubtitleTickets2}
                            </Typography>
                            <Typography
                                sx={[
                                    styles.subtitle,
                                    {
                                        color: Colors.WHITE,
                                        marginBottom: "20px",
                                    },
                                    !matches && {
                                        justifyContent: "center",
                                        textAlign: "center",
                                    },
                                ]}>
                                {strings.shopSubtitleTickets3}
                            </Typography>
                            <SwitchMenu
                                items={menuItems}
                                style={styles.topMenu}
                            />
                        </Box>
                    }
                    rightPartStyle={{
                        height: "90vh",
                        maxWidth: "350px",
                    }}
                    rightPart={
                        <Box
                            sx={[
                                styles.storiesList,
                                !matches && {
                                    alignSelf: "center",
                                    paddingTop: "2vh",
                                },
                            ]}>
                            {auth &&
                            auth.user &&
                            (!auth.user.pass ||
                                (auth.user.pass &&
                                    !auth.user.pass.isActive)) ? (
                                <PassBtnBig currency={currency} />
                            ) : null}
                            {ticketsPacks &&
                                ticketsPacks.length &&
                                ticketsPacks.map(
                                    (item: Tickets, index: number) => {
                                        return (
                                            <TicketButton
                                                purchaseTickets={initPurchase}
                                                item={item}
                                                key={index}
                                            />
                                        );
                                    },
                                )}
                        </Box>
                    }
                />
            )}
        </>
    );
};

const styles = {
    container: {
        ...columnStyles,
        gap: "5px",
        paddingTop: "4vh",
    },
    containerSmall: {
        ...columnStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
    },
    topMenuTitle: {
        width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "10px",
    },
    storiesList: {
        width: "100%",
        ...columnStyles,
        justifyContent: "flex-start",
        maxWidth: "350px",
        minWidth: "350px",
        rowGap: "10px",
        paddingTop: "4vh",
        paddingBottom: "50px",
    },
    modalView: {
        width: "300px",
        borderRadius: "20px",
        backgroundColor: Colors.PRIMARY,
        position: "relative",
        ...columnCenterStyles,
        zIndex: 50,
    },
    topMenu: {
        ...columnStyles,
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    topMenuItems: {
        with: "100%",
        height: "40px",
        ...rowStyles,
        backgroundColor: Colors.PRIMARY,
        borderRadius: "100px",
    },
    topMenuItem: {
        ...rowCenterStyles,
        padding: "8px 30px",
        borderRadius: "100px",
    },
    topMenuItemActive: {
        backgroundColor: Colors.BTN_ACTIVE,
    },
    topMenuItemInactive: {},
    topMenuItemActiveTitle: {
        fontSize: "14px",
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        marginLeft: "3px",
    },
    topMenuItemInactiveTitle: {
        fontSize: "14px",
        fontFamily: "Gilroy-Bold",
        color: Colors.FOND_ITEM_POPUP,
        marginLeft: "3px",
    },
};

export default TicketShop;
