/* eslint-disable @typescript-eslint/no-explicit-any */
import { Variables } from './variables';
import { Player } from './players';

export default class ConditionHandler {
  private _condition: any;

  private _variables: Variables;

  private _activePlayer: Player;

  constructor(condition: any, variables: Variables, activePlayer: Player) {
    this._condition = condition;
    this._variables = variables;
    this._activePlayer = activePlayer;
  }

  // TODO: Handle -1!
  /**
   * Handle a global var or state condition
   * @return {number} the ID of next scene, -1 if problem.
   */
  public handleCondition(): number {
    const {
      firstOperand,
      secondOperand,
      thirdOperand,
      linkTrue,
      linkFalse,
    } = this._condition;

    let variable;

    if (firstOperand.startsWith('var_')) {
      variable = this._variables.getVariableByName(firstOperand);
    } else {
      variable = this._activePlayer.getStateByName(firstOperand);
    }

    // It means it does not exist, therefore it's false.
    if (variable == null) {
      return linkFalse;
    }

    if (secondOperand === '=') {
      if (variable.value === parseInt(thirdOperand, 10)) {
        return linkTrue;
      }
      return linkFalse;
    }

    if (secondOperand === '>') {
      if (variable.value > parseInt(thirdOperand, 10)) {
        return linkTrue;
      }
      return linkFalse;
    }

    if (secondOperand === '<') {
      if (variable.value < parseInt(thirdOperand, 10)) {
        return linkTrue;
      }
      return linkFalse;
    }

    return -1;
  }

  public handleTextbox(answer: string): number {
    const { thirdOperand, linkTrue, linkFalse } = this._condition;
    // TODO: Handle '<' and '>'
    let copy = thirdOperand.replace('[', '');
    copy = copy.replace(']', '');
    copy = copy.split(',');

    if (thirdOperand === answer || copy.includes(answer)) {
      return linkTrue;
    }
    return linkFalse;
  }
}
