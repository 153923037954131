import { Box, Typography } from "@mui/material";
import { styles } from "../style";
import { Player } from "@lottiefiles/react-lottie-player";

export const FTUEItem = ({ item }: { item: any }) => (
    <Box sx={styles.itemContainer}>
        {item.type === "anim" && item.anim ? (
            <Player src={item.anim} style={styles.itemIcon} autoplay loop />
        ) : item.type === "image" && item.image ? (
            <img src={item.image} style={styles.itemIcon} alt="image" />
        ) : null}
        <Typography sx={styles.itemText}>{item.text}</Typography>
    </Box>
);
