/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import AvatarContainer from "../../AvatarContainer";
import { UserLadder } from "../../../models/userLadder";
import { styles } from "../style";
import { Colors } from "../../../style";
import Separator from "../../Separator";
import { rowStartStyles } from "../../../style/flex";

import { ReactComponent as IconXp } from "../../../assets/icons/icon_xp.svg";
import { normalizeSize } from "../../../utils/fontSizer";

const UserLadderBox = ({
    user,
    isYou = false,
}: {
    user: UserLadder;
    isYou?: boolean;
}) => {
    const getPositionColor = () => {
        switch (user.position) {
            case 1:
                return Colors.BOARDGAME;
            case 2:
                return Colors.vertALEXTESGRANDSMORTS;
            case 3:
                return Colors.PACK;
            default:
                return Colors.OLD_PRICE;
        }
    };

    const determineTextSize = (number: number): number => {
        if (number < 100) {
            return 20; // Taille de texte pour < 100
        } else if (number < 1000) {
            return 14; // Taille de texte pour < 1000
        } else {
            return 10; // Taille de texte pour >= 1000
        }
    };

    const numberWithSpaces = (x: number): string => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    return (
        <Box
            sx={[
                styles.userBox,
                isYou ? { backgroundColor: Colors.PROGRESS } : {},
            ]}>
            <Box sx={styles.positionBox}>
                <Typography
                    sx={[
                        styles.positionText,
                        {
                            color: getPositionColor(),
                            fontSize: normalizeSize(
                                user.position
                                    ? determineTextSize(user.position)
                                    : 10,
                            ),
                        },
                    ]}>
                    {user.position
                        ? numberWithSpaces(user.position) + "."
                        : "?"}
                </Typography>
            </Box>
            <Separator
                style={!isYou ? styles.separator : styles.separatorYou}
            />
            <Box sx={styles.userBoxInside}>
                <AvatarContainer persoWidth={70} user={user} borderWidth={0} />
                <Box sx={styles.userText}>
                    <Typography component={"span"} sx={styles.userPseudo}>
                        {user?.pseudo ? user.pseudo : ""}
                    </Typography>
                    <Typography sx={styles.userTitle} component={"span"}>
                        {user?.title ? user.title : ""}
                    </Typography>
                    <Box sx={styles.levelPart}>
                        <Box style={styles.levelCircle}>
                            <Typography style={styles.levelText}>
                                {user.level ? user.level : "?"}
                            </Typography>
                        </Box>
                        <Box sx={{ ...rowStartStyles }}>
                            <IconXp width={25} height={25} />
                            <Typography sx={styles.xp}>
                                {user.experience}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UserLadderBox;
