import { Box, Typography } from "@mui/material";
import ColorManager from "../../services/colorManager";
import { Colors } from "../../style";
import { columnStyles, rowStyles } from "../../style/flex";
import normalize from "../../utils/fontSizer";
import AvatarContainer from "../AvatarContainer";
import { Player } from "../../services/StoryReader/players";

interface ChatMessage {
    id: string;
    gameID: string;
    playerID: string;
    avatar: string;
    name: string;
    message: string;
    timestamp: number;
}

interface Props {
    message: ChatMessage;
    player: Player | undefined;
    lastMessage: ChatMessage | undefined;
    dateColor?: string | undefined;
}

const Message = (props: Props) => {
    const { message, player, lastMessage, dateColor = undefined } = props;

    const shouldShowInfos =
        !lastMessage ||
        (lastMessage &&
            lastMessage.playerID &&
            lastMessage.playerID !== message.playerID)
            ? true
            : false;

    if (message) {
        return (
            <Box
                sx={[
                    styles.chatMessage,
                    player && message.playerID === player.playerCode
                        ? styles.messageSent
                        : styles.messageReceived,
                ]}>
                {shouldShowInfos ? (
                    <Box sx={[styles.messageHeader]}>
                        <AvatarContainer
                            persoWidth={30}
                            user={{ avatar: message.avatar }}
                            borderWidth={2}
                            borderColor={ColorManager.getInstance().getColor(
                                "secondary",
                            )}
                        />
                        <Typography
                            sx={[
                                styles.messageHeaderText,
                                {
                                    color: ColorManager.getInstance().getColor(
                                        "tertiary",
                                    ),
                                },
                            ]}>
                            {message.name &&
                            player &&
                            message.playerID === player.playerCode
                                ? message.name
                                : message.name.replace(" (Toi)", "")}
                        </Typography>
                    </Box>
                ) : null}

                <Box
                    sx={[
                        styles.messageContent,
                        player && message.playerID === player.playerCode
                            ? {
                                  backgroundColor:
                                      ColorManager.getInstance().getColor(
                                          "secondary",
                                      ),
                              }
                            : {
                                  backgroundColor:
                                      ColorManager.getInstance().getColor(
                                          "secondary",
                                      ),
                              },
                    ]}>
                    <Typography
                        component={"span"}
                        sx={[
                            styles.messageContentText,
                            {
                                color: Colors.WHITE,
                            },
                        ]}>
                        {message.message}
                    </Typography>
                </Box>
                <Box
                    sx={[
                        styles.messageTimestamp,
                        {
                            color: dateColor
                                ? dateColor
                                : ColorManager.getInstance().getColor(
                                      "secondary",
                                  ),
                        },
                    ]}>
                    {new Date(message.timestamp).toLocaleString()}
                </Box>
            </Box>
        );
    } else {
        return null;
    }
};

const styles = {
    chatMessage: {
        //marginBottom: "10px",
        padding: "5px",
        maxWidth: "95%",
        borderRadius: "5px",
    },
    messageSent: {
        alignSelf: "flex-end",
        ...columnStyles,
        alignItems: "flex-end",
        //backgroundColor: "#DCF8C6",
    },
    messageReceived: {
        alignSelf: "flex-start",
        ...columnStyles,
        //backgroundColor: "#F5F5F5",
    },
    messageHeader: {
        ...rowStyles,
        alignItems: "center",
    },
    messageHeaderText: {
        color: Colors.ACCENT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
        marginLeft: "5px",
    },
    messageContent: {
        ...columnStyles,
        alignItems: "flex-start",
        borderRadius: "13px",
        padding: "12px 15px",
        marginTop: "5px",
    },
    messageContentText: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
    },
    messageTimestamp: {
        fontSize: "12px",
        color: "#888888",
    },
};

export default Message;
