// PARAMETERS NAME TO GET VALUES
/* export const FACEBOOK_LOGIN_IOS = 'facebook_login_ios';
export const FACEBOOK_LOGIN_ANDROID = 'facebook_login_android'; */
export const FACEBOOK_LOGIN_WEB = "facebook_login_web";
/* export const GOOGLE_LOGIN_IOS = 'google_login_ios';
export const GOOGLE_LOGIN_ANDROID = 'google_login_android'; */
export const GOOGLE_LOGIN_WEB = "google_login_web";
/* export const APPLE_LOGIN_IOS = 'apple_login_ios';
export const APPLE_LOGIN_ANDROID = 'apple_login_android'; */
export const APPLE_LOGIN_WEB = "apple_login_web";
/* export const IN_APP_PURCHASE_ANDROID = 'in_app_purchase_android';
export const IN_APP_PURCHASE_IOS = 'in_app_purchase_ios'; */
export const IN_APP_PURCHASE_WEB = "in_app_purchase_web";
export const SERVER_MAINTENANCE = "server_maintenance";
export const ONLINE_AVAILABLE = "online_available";
/* export const ADS_AVAILABLE_IOS = 'ads_available_ios';
export const ADS_AVAILABLE_ANDROID = 'ads_available_android'; */
export const REGISTER_AVAILABLE_WEB = "register_available_web";
