import { Colors } from "../../style";
import {
    columnStartStyles,
    columnStyles,
    rowBetweenStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import { normalizeSize, normalizeVh } from "../../utils/fontSizer";

export const styles = {
    mainContainer: {
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        maxHeight: "calc(96vh - 20px)",
        flex: "1 1 auto",
    },
    mainContainerBig: {
        ...rowCenterStyles,
        alignItems: "flex-start",
    },
    mainContainerSmall: {
        ...columnStyles,
        alignItems: "center",
    },
    container: {
        width: "100%",
        alignItems: "center",
        ...columnStyles,
        justifyContent: "flex-start",
        maxWidth: "450px",
        minWidth: "450px",
        rowGap: "10px",
        paddingTop: "4vh",
        paddingBottom: "50px",
        flex: "0 0 auto",
    },
    containerSmall: {
        width: "100%",
        ...columnStartStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
        flex: "0 0 auto",
    },
    topMenuTitle: {
        width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "20px",
    },
    right: {
        ...columnStyles,
        //flex: 1,
        justifyContent: "flex-start",
        maxWidth: normalizeSize(450),
        minWidth: normalizeSize(450),
        paddingTop: normalizeVh(4),
        paddingBottom: normalizeSize(50),
        rowGap: normalizeSize(16),
    },
    //Friend screen start
    list: {
        ...columnStyles,
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        columnGap: "10px",
        maxWidth: normalizeSize(1020),
        width: "100%",
        minWidth: normalizeSize(450),
    },
    listSmall: {
        ...columnStyles,
        minHeight: "-webkit-min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: normalizeSize(8),
        maxWidth: normalizeSize(1020),
        width: normalizeSize(300),
    },
    gameCategory: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(18),
        fontWeight: 600,
        color: Colors.WHITE,
    },
    refreshBtn: {
        ...rowStyles,
        columnGap: normalizeSize(7),
        padding: "5px 10px",
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(7),
    },
    refreshBtnText: {
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalizeSize(14),
    },
    listHeader: {
        gap: normalizeSize(8),
        width: normalizeSize(300),
        ...rowBetweenStyles,
    },
    modalContent: {
        ...columnStyles,
        alignItems: "center",
        width: "100%",
    },
    joinGameErrorText: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.ERROR,
        margin: normalizeSize(15),
        textAlign: "center",
    },
    popupButtons: {
        width: "70%",
        alignSelf: "center",
        ...rowStyles,
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        margin: "25px 0px",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalizeSize(14),
        fontFamily: Gilroy,
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        padding: "11px 15px",
        borderRadius: "10px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalizeSize(14),
        fontFamily: Gilroy,
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    playerContainer: {
        ...rowStartStyles,
        alignItems: "center",
        gap: normalizeSize(8),
    },
    circlePlaceholder: {
        width: normalizeSize(32),
        height: normalizeSize(32),
        borderRadius: normalizeSize(32),
        backgroundColor: Colors.BTN_INACTIVE,
    },
    playerName: {
        //...rowStartStyles,
        //alignItems: "center",
        textAlign: "center",
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        marginTop: normalizeSize(16),
        color: Colors.OLD_PRICE,
    },
};
