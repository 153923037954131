/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase } from "@mui/material";
import React, { useState, useRef } from "react";
import { columnCenterStyles } from "../../style/flex";
import CoverTitleSmallFlexible from "../coverTitleSmallFlexible";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./style.css";

interface Props {
  cards: any;
  cardWidth?: number | undefined; // Largeur d'une carte de référence en pixels
  cardHeight?: number | undefined; // hauteur d'une carte de référence en pixels
  marginBetweenCards?: number | undefined; // marge entre les cartes en pixels
  numCardsPerView?: number | undefined; // nombre de cartes de références visibles
}

const Carousel = (props: Props) => {
  const {
    cards,
    cardWidth = 220,
    cardHeight = 352,
    marginBetweenCards = 15,
    numCardsPerView = 4,
  } = props;

  const widthTotal = window.innerWidth;

  const widthTotalWithSideBar = widthTotal - 270; // 995
  const cardMarginRight = widthTotal * 0.04;
  const listWidth = widthTotalWithSideBar - 2 * cardMarginRight;
  const visibleListWidth =
    listWidth - (numCardsPerView - 1) * marginBetweenCards;

  const finalWidth =
    visibleListWidth * (1/numCardsPerView) > cardWidth ? cardWidth : visibleListWidth * (1/numCardsPerView);
  const finalHeight =
    (cardHeight * finalWidth) / cardWidth > cardHeight
      ? cardHeight
      : (cardHeight * finalWidth) / cardWidth;

  const cardWithMargins = finalWidth + marginBetweenCards;

  const getCardsPerView = () => {
    const tmp = Math.floor(listWidth / cardWithMargins);
    const cardsPerViewCount =
      widthTotalWithSideBar > 1155 ? tmp : numCardsPerView;

    return cardsPerViewCount;
  };

  const cardsPerView = getCardsPerView();

  const carouselRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToNextCards = () => {
    let nextIndex = 0;
    const remainingCards = cards.length - (currentIndex + 1);
    if (remainingCards >= cardsPerView) {
      nextIndex = currentIndex + cardsPerView;
    } else if (remainingCards > 0) {
      nextIndex = currentIndex + remainingCards;
    }
    setCurrentIndex(nextIndex);
    scrollToCurrentCard(nextIndex);
  };

  const goToPreviousCards = () => {
    const cardsPerView = currentIndex === cards.length ? numCardsPerView + 1 : numCardsPerView;
    let nextIndex = cards.length;
    const remainingCards = currentIndex;
    if (remainingCards >= cardsPerView) {
      nextIndex = currentIndex - cardsPerView;
    } else if (remainingCards > 0) {
      nextIndex = currentIndex - remainingCards;
    }
    setCurrentIndex(nextIndex);
    scrollToCurrentCard(nextIndex);
  };

  const scrollToCurrentCard = (index: number) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollLeft = index * cardWithMargins;
      carousel.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  /* 
  const scrollToCurrentCard = (index: number) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const numVisibleCards = Math.floor(
        (0.92 * window.innerWidth - 270) / (cardWidth + marginBetweenCards)
      );
      const scrollLeft = Math.min(
        index * (cardWidth + marginBetweenCards),
        (cards.length - numVisibleCards) * (cardWidth + marginBetweenCards)
      );
      carousel.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  
  const goToNextCards = () => {
    const visibleCards =
      Math.floor((0.92 * window.innerWidth - 270) / cardWidth);
    let nextIndex = (currentIndex + 1) % cards.length;
    const lastIndex = cards.length - visibleCards;
    if (nextIndex > lastIndex) {
      nextIndex = 0;
    }
    setCurrentIndex(nextIndex);
    scrollToCurrentCard(nextIndex);
  };
 const goToNextCards = () => {
    const numVisibleCards = Math.floor((0.92 * window.innerWidth - 270) / cardWidth);
    const nextIndex = (currentIndex + 1) % cards.length;
    const numRemainingCards = cards.length - (currentIndex + 1);
  
    let scrollDistance = numVisibleCards * cardWidth;
    if (numRemainingCards < numVisibleCards) {
      scrollDistance = numRemainingCards * cardWidth;
    }
  
    setCurrentIndex(nextIndex);
    carouselRef.current.parentNode.scrollLeft += scrollDistance;
  };

  const goToPreviousCards = () => {
    const numVisibleCards =
      Math.floor((0.92 * window.innerWidth - 270) / cardWidth);
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      // Revenir à la dernière carte si on est au début du carousel
      prevIndex = cards.length - numVisibleCards;
    }
    setCurrentIndex(prevIndex);
    scrollToCurrentCard(prevIndex);
  };

  const scrollToCurrentCard = (index: number) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollLeft = (index * cardWidth);
      carousel.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  }; */

  return (
    <Box
      sx={{
        width: "100%",
        ...columnCenterStyles,
        position: "relative",
        height: finalHeight ? finalHeight : 352,
      }}
      className={"slider"}
    >
      <Box
        ref={carouselRef}
        sx={{
          width: "calc(100vw - 270px)",
          overflow: "hidden",
          height: "100%",
        }}
      >
        {cards && cards.length ? (
          <Box
            sx={{ display: "inline-flex", height: "100%", overflow: "visible" }}
          >
            {cards.map((card: any, index: number) => (
              <ButtonBase
                key={index}
                className={"carouselCard"}
                sx={[
                  styles.card,
                  {
                    flex: `0 0 ${finalWidth}`,
                    height: finalHeight,
                  },
                ]}
              >
                <Box
                  sx={[
                    styles.cover,
                    index !== cards.length - 1
                      ? { marginRight: `${marginBetweenCards}px` }
                      : {},
                    cardWidth ? { width: finalWidth } : {},
                    cardHeight
                      ? {
                          height: finalHeight,
                        }
                      : {},
                  ]}
                  key={"reco-" + index}
                >
                  <CoverTitleSmallFlexible
                    cover={card.item.image_backdrop}
                    title={card.item.image_title}
                    height={finalHeight}
                    width={finalWidth}
                  />
                </Box>
              </ButtonBase>
            ))}
          </Box>
        ) : null}
      </Box>
      <ButtonBase
        className={"prevButton"}
        sx={[styles.btn, styles.prevButton]}
        onClick={goToPreviousCards}
      >
        <NavigateBeforeIcon sx={{ fontSize: "2rem" }} className="prevIcon" />
      </ButtonBase>
      <ButtonBase
        className={"nextButton"}
        sx={[styles.btn, styles.nextButton]}
        onClick={goToNextCards}
      >
        <NavigateNextIcon sx={{ fontSize: "2rem" }} className="nextIcon" />
      </ButtonBase>
    </Box>
  );
};

const styles = {
  carouselOuterContainer: {
    overflow: "hidden",
    position: "relative",
  },
  carouselContainer: {
    display: "flex",
  },
  carousel: {
    display: "flex",
    transition: "transform 0.3s ease-in-out",
  },
  card: {
    /* height: "100%", */
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    /*  marginRight: "15px", */
  },
  active: {
    backgroundColor: "#ccc",
  },
  btn: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "4vw",
    height: "100%",
    background: "hsla(0,0%,8%,.3)",
    border: "none",
    color: "#fff",
    fontSize: "24px",
    zIndex: 999,
  },
  cover: {},
  prevButton: {
    left: "0",
  },
  nextButton: {
    right: "0",
  },
};

export default Carousel;
