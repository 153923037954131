/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik } from "formik";
import * as yup from "yup";
import CryptoJS from "crypto-js";
import { ReactComponent as SecretIcon } from "../../../../assets/icons/icon_secret.svg";
import { ReactComponent as IconEmail } from "../../../../assets/icons/icon_email.svg";
import { ReactComponent as IconPassword } from "../../../../assets/icons/icon_pwd.svg";
import { ReactComponent as IconTalesup } from "../../../../assets/icons/icon_logo.svg";
import GoBackBtn from "../../../../components/GoBackBtn";
import GradientBtn from "../../../../components/GradientBtn";
import normalize, { normalizeSize } from "../../../../utils/fontSizer";
import { getRouteManager } from "../../../../services/routeManager";
import {
    useMediaQuery,
    Box,
    Typography,
    Input,
    InputAdornment,
    ButtonBase,
    Modal,
} from "@mui/material";
import {
    strings,
    getLanguage,
    changeLanguage,
} from "../../../../services/translation";
import { Colors } from "../../../../style";
import { columnCenterStyles, rowCenterStyles } from "../../../../style/flex";
import { useTogglePasswordVisibility } from "../hook";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../../../services/Auth/useAuth";
import LoadingView from "../../../../components/LoadingView";
import { styles } from "../style";
import ForgotPasswordBox from "../ForgotPasswordBox";

interface Props {
    callback: any;
    ftueCallback: any;
    isTemporaryModal?: boolean;
}

const LoginBox = (props: Props) => {
    const { callback, ftueCallback, isTemporaryModal = false } = props;

    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const { passwordVisibility, rightIcon, handlePasswordVisibility } =
        useTogglePasswordVisibility();

    const matchesWidth = useMediaQuery("(min-width:800px)");
    const matchesHeight = useMediaQuery("(min-height:800px)");

    const navigatePathname = useMemo(() => {
        const state = location.state as { from: Location };
        if (state && state.from) {
            return state.from;
        }
        return "/";
    }, [location]);

    /* **** STATES **** */

    const [error, setError] = useState<any>(null);
    const [email, setEmail] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [showContinue, setShowContinue] = useState(true);
    const [showEmail, setShowEmail] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);

    /* **** FUNCTIONS **** */

    /**
     *
     */
    const testLogin = async (values: any) => {
        try {
            setLoading(true);
            setEmail(values.email);

            const passHash = CryptoJS.SHA512(values.password);
            const apiUrl = getRouteManager().login();

            const res = await getRouteManager().fetchData(apiUrl, {
                email: values.email,
                password: passHash.toString(),
                lang: getLanguage(),
            });

            if (res.flashmessage === "error") {
                setError(res.errorInfo);
                setShowEmail(true);
                setShowPassword(false);
                setShowContinue(true);
                setShowSubmit(false);
                setLoading(false);
            } else {
                getRouteManager().setUser(res);
                if (res && res?.lang && res?.lang !== getLanguage()) {
                    changeLanguage(res.lang);
                }
                if (res && res.login_token && res.ftueDone) {
                    auth.signin(res, () => {
                        navigate(navigatePathname, { replace: true });
                    });
                } else if (res && res.login_token && !res.ftueDone) {
                    auth.signin(res, () => {
                        ftueCallback();
                        //navigate(navigatePathname, { replace: true });
                    });
                }
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /**
     *
     */
    const handleError = (error: any, type: string) => {
        if (error.endsWith("is a required field") && type === "email") {
            return strings.emailRequired;
        }
        if (error.endsWith("must be a valid email") && type === "email") {
            return strings.emailInvalid;
        }
        if (error.endsWith("is a required field") && type === "password") {
            return strings.passwordRequired;
        }
        if (error.endsWith("must be a valid password") && type === "password") {
            return strings.passwordInvalid;
        }
    };

    const goBackAction = () => {
        if (showEmail) {
            callback();
        } else if (showPassword) {
            setShowPassword(false);
            setShowSubmit(false);
            setShowContinue(true);
            setShowEmail(true);
        }
    };

    if (showPasswordReset) {
        return (
            <ForgotPasswordBox
                callback={() => {
                    setShowPasswordReset(false);
                }}
                email={email}
            />
        );
    }

    return (
        <Box
            sx={[
                styles.loginContainer,
                matchesWidth && !isTemporaryModal
                    ? {
                          width: "50%",
                      }
                    : {
                          width: "100%",
                          minWidth: "400px",
                      },
                isTemporaryModal ? { borderRadius: normalizeSize(20) } : {},
            ]}>
            <GoBackBtn
                callback={() => {
                    goBackAction();
                }}
                style={{}}
                extraStyle={{
                    borderRadius: "50px",
                    position: "absolute",
                    top: 37,
                    left: 37,
                }}
            />
            <Box
                sx={
                    matchesHeight
                        ? { width: "50px", height: "50px" }
                        : { display: "none" }
                }>
                <IconTalesup width={50} height={50} fill={Colors.WHITE} />
            </Box>
            <Typography sx={styles.title}>{strings.signIn}</Typography>
            <Typography sx={styles.subtitle}>
                {strings.signInSubtitle}
            </Typography>
            {loading ? (
                <LoadingView color={Colors.ACCENT} />
            ) : (
                <Formik
                    initialValues={{
                        email: email ? email : "",
                        password: "",
                    }}
                    onSubmit={(values) => testLogin(values)}
                    validationSchema={yup.object().shape({
                        email: yup.string().email().required(),
                        password: yup.string().required(),
                    })}>
                    {({
                        values,
                        handleChange,
                        errors,
                        setFieldTouched,
                        touched,
                        isValid,
                        handleSubmit,
                    }) => (
                        <Box style={styles.container}>
                            {showEmail ? (
                                <Box sx={styles.inputContainer}>
                                    <Typography sx={styles.label}>
                                        Email
                                    </Typography>
                                    <Input
                                        sx={styles.input}
                                        value={values.email}
                                        disableUnderline
                                        autoFocus
                                        type="email"
                                        name="email"
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            setEmail(event.target.value);
                                            handleChange(event);
                                        }}
                                        onBlur={() => setFieldTouched("email")}
                                        placeholder={
                                            getLanguage() == "fr"
                                                ? "email@email.com"
                                                : "myemail@email.com"
                                        }
                                        onKeyUp={(event: any) => {
                                            if (
                                                event.key &&
                                                event.key === "Enter"
                                            ) {
                                                if (
                                                    !errors.email &&
                                                    values.email !== ""
                                                ) {
                                                    setShowEmail(false);
                                                    setShowPassword(true);
                                                    setShowContinue(false);
                                                    setShowSubmit(true);
                                                }
                                            } else return null;
                                        }}
                                        startAdornment={
                                            <InputAdornment
                                                position="start"
                                                sx={{ paddingLeft: "5px" }}>
                                                <IconEmail
                                                    width={20}
                                                    height={20}
                                                    fill={Colors.WHITE}
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                    {touched.email && errors.email && (
                                        <Typography
                                            style={{
                                                fontFamily: "Gilroy-Bold",
                                                fontSize: normalize(12),
                                                color: Colors.ERROR,
                                                marginTop: "15px",
                                                textAlign: "center",
                                            }}>
                                            {handleError(errors.email, "email")}
                                        </Typography>
                                    )}
                                </Box>
                            ) : null}
                            {showContinue ? (
                                <Box
                                    style={{
                                        width: "100%",
                                        ...rowCenterStyles,
                                        marginTop: "calc(25px + 2vh)",
                                    }}>
                                    <GradientBtn
                                        disabled={
                                            errors.email || values.email == ""
                                        }
                                        onPress={() => {
                                            setShowEmail(false);
                                            setShowPassword(true);
                                            setShowContinue(false);
                                            setShowSubmit(true);
                                        }}>
                                        {strings.continue}
                                    </GradientBtn>
                                </Box>
                            ) : null}
                            {showPassword ? (
                                <Box sx={styles.inputContainer}>
                                    <Typography sx={styles.label}>
                                        {strings.password}
                                    </Typography>
                                    <Input
                                        sx={styles.input}
                                        value={values.password}
                                        disableUnderline
                                        autoFocus
                                        type={
                                            passwordVisibility
                                                ? "password"
                                                : "text"
                                        }
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={() =>
                                            setFieldTouched("password")
                                        }
                                        placeholder={
                                            getLanguage() == "fr"
                                                ? "Mot de passe"
                                                : "mypassword"
                                        }
                                        onKeyUp={(event: any) => {
                                            if (
                                                event.key &&
                                                event.key === "Enter"
                                            ) {
                                                event.key &&
                                                event.key === "Enter"
                                                    ? handleSubmit(
                                                          event.target.value,
                                                      )
                                                    : null;
                                            } else return null;
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconPassword
                                                    width={20}
                                                    height={20}
                                                    fill={Colors.WHITE}
                                                />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <ButtonBase
                                                    onClick={
                                                        handlePasswordVisibility
                                                    }
                                                    sx={{
                                                        borderRadius: "20px",
                                                        marginRight: "5px",
                                                    }}>
                                                    <SecretIcon
                                                        width={20}
                                                        height={20}
                                                        fill={
                                                            rightIcon === "eye"
                                                                ? Colors.OLD_PRICE
                                                                : Colors.WHITE
                                                        }
                                                    />
                                                </ButtonBase>
                                            </InputAdornment>
                                        }
                                    />
                                    {touched.password && errors.password && (
                                        <Typography
                                            style={{
                                                fontFamily: "Gilroy-Bold",
                                                fontSize: normalize(12),
                                                color: Colors.ERROR,
                                                marginTop: "15px",
                                                textAlign: "center",
                                            }}>
                                            {handleError(
                                                errors.password,
                                                "password",
                                            )}
                                        </Typography>
                                    )}
                                    <Box
                                        style={styles.forgotButton}
                                        onClick={() => {
                                            setShowPasswordReset(true);
                                        }}>
                                        <Typography
                                            component="span"
                                            sx={styles.forgotText}>
                                            {strings.forgotPassword} ?
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : null}

                            {showSubmit && (
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: "calc(25px + 2vh)",
                                    }}>
                                    <GradientBtn
                                        disabled={!isValid}
                                        onPress={handleSubmit}>
                                        {strings.signIn}
                                    </GradientBtn>
                                </Box>
                            )}
                        </Box>
                    )}
                </Formik>
            )}
            <Modal open={!!error} onClose={() => setError(null)}>
                <Box style={{ ...columnCenterStyles }}>
                    <Box style={styles.modalViewError}>
                        <Typography
                            style={{
                                color: Colors.WHITE,
                                fontFamily: "Eczar-Regular",
                                fontSize: normalize(36),
                                marginBottom: "18px",
                                textAlign: "center",
                            }}>
                            {strings.error}
                        </Typography>

                        <Typography sx={styles.textModalError}>
                            {error}
                        </Typography>

                        <Box style={styles.separatorError} />

                        <ButtonBase onClick={() => setError(null)}>
                            <Typography style={styles.deleteStoryText}>
                                {strings.understood}
                            </Typography>
                        </ButtonBase>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default LoginBox;
