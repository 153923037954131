/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Typography } from "@mui/material";
import { Colors } from "../../../style";
import { useContext } from "react";
import { isPositiveNumber } from "../../../utils/NumberHelper";
import { NotificationContext } from "../../../utils/ContextsHelper";
import { strings } from "../../../services/translation";
import { styles } from "../styles";
import { ReactComponent as IconMenuPack } from "../../../assets/icons/icon_menu_pack.svg";
import { ReactComponent as IconNoQuest } from "../../../assets/icons/icon_no_quest.svg";
import { ReactComponent as IconMainQuest } from "../../../assets/icons/icon_main_quest.svg";

const RewardMenu = ({
    activeIndex,
    changeMenuActive,
}: {
    activeIndex: number;
    changeMenuActive: (type: string) => void;
}) => {
    const { hasNewLevel, hasNewMissions, hasReferralReward } =
        useContext(NotificationContext);

    return (
        <Box sx={styles.rewardMenu}>
            <Box sx={styles.menuContainer}>
                <ButtonBase
                    onClick={() => changeMenuActive("level")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 0 ? styles.menuBtnActive : {},
                    ]}>
                    <Box sx={styles.iconBox}>
                        <IconMainQuest
                            width={20}
                            height={20}
                            fill={
                                activeIndex === 0
                                    ? Colors.ACCENT
                                    : Colors.FOND_ITEM_POPUP
                            }
                        />
                    </Box>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 0 ? { color: Colors.ACCENT } : {},
                        ]}>
                        {strings.levels.title}
                    </Typography>
                    {isPositiveNumber(hasNewLevel) ? (
                        <Box sx={styles.notifIcon} />
                    ) : null}
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("mission")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 1 ? styles.menuBtnActive : {},
                    ]}>
                    <Box sx={styles.iconBox}>
                        <IconNoQuest
                            width={20}
                            height={20}
                            fill={
                                activeIndex === 1
                                    ? Colors.HEART
                                    : Colors.FOND_ITEM_POPUP
                            }
                        />
                    </Box>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 1 ? { color: Colors.HEART } : {},
                        ]}>
                        {strings.quests.quests}
                    </Typography>
                    {isPositiveNumber(hasNewMissions) ? (
                        <Box sx={styles.notifIcon} />
                    ) : null}
                </ButtonBase>
                <ButtonBase
                    onClick={() => changeMenuActive("referral")}
                    sx={[
                        styles.menuBtn,
                        activeIndex === 2 ? styles.menuBtnActive : {},
                    ]}>
                    <Box sx={styles.iconBox}>
                        <IconMenuPack
                            width={20}
                            height={20}
                            fill={
                                activeIndex === 2
                                    ? Colors.PACK
                                    : Colors.FOND_ITEM_POPUP
                            }
                        />
                    </Box>
                    <Typography
                        sx={[
                            styles.menuBtnText,
                            activeIndex === 2 ? { color: Colors.PACK } : {},
                        ]}>
                        {strings.referral.title}
                    </Typography>
                    {isPositiveNumber(hasReferralReward) ? (
                        <Box sx={styles.notifIcon} />
                    ) : null}
                </ButtonBase>
            </Box>
        </Box>
    );
};

export default RewardMenu;
