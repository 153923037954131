export const popupSoundJson = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 80.0000032584668,
  w: 150,
  h: 150,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'UI_intro_popup Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [75, 75, 0], ix: 2, l: 2 },
        a: { a: 0, k: [75, 75, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-5.792, 4.855],
                    [-0.87, -0.405],
                    [0, -0.96],
                    [0, -11.442],
                    [0.87, -0.405],
                    [0.735, 0.618],
                    [0, 0],
                    [0, 0],
                    [0, 7.513],
                    [0, 0],
                    [-7.512, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.735, -0.617],
                    [0.87, 0.405],
                    [0, 11.443],
                    [0, 0.96],
                    [-0.87, 0.405],
                    [-5.792, -4.855],
                    [0, 0],
                    [-7.512, 0],
                    [0, 0],
                    [0, -7.512],
                    [0, 0],
                  ],
                  v: [
                    [-11.673, -13.607],
                    [4.067, -26.8],
                    [6.7, -27.145],
                    [8.125, -24.905],
                    [8.125, 24.905],
                    [6.7, 27.145],
                    [4.067, 26.8],
                    [-11.673, 13.608],
                    [-18.573, 13.608],
                    [-32.175, 0.005],
                    [-32.175, -0.005],
                    [-18.573, -13.607],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.448, -1.477],
                    [0, -9.507],
                    [0, 0],
                    [-1.477, -1.448],
                    [-1.447, 1.478],
                    [0, 12.915],
                    [0, 0],
                    [1.48, -1.448],
                  ],
                  o: [
                    [0, 0],
                    [0, 9.508],
                    [-1.448, 1.478],
                    [1.48, 1.447],
                    [0, 0],
                    [0, -12.915],
                    [-1.447, -1.477],
                    [-1.477, 1.447],
                  ],
                  v: [
                    [19.543, -14.93],
                    [24.675, 0],
                    [19.543, 14.93],
                    [19.6, 20.233],
                    [24.905, 20.175],
                    [32.175, 0],
                    [24.905, -20.175],
                    [19.6, -20.232],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.474509833841, 0.439215716194, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [67.175, 75], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 0,
                    s: [100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 5,
                    s: [130, 130],
                  },
                  { t: 20.0000008146167, s: [100, 100] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.325, -0.563],
                    [0, 0],
                    [0.832, -0.481],
                    [-1.35, -2.338],
                    [-2.339, 1.35],
                    [1.334, 2.336],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.832, -0.48],
                    [-1.35, -2.339],
                    [-2.338, 1.351],
                    [1.344, 2.338],
                    [0, 0],
                    [0, 0],
                    [0.353, 0.04],
                    [0.275, -0.274],
                    [2.474, -2.473],
                  ],
                  o: [
                    [0, 0],
                    [-0.894, -0.037],
                    [-2.339, 1.35],
                    [1.35, 2.339],
                    [2.331, -1.346],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.894, -0.037],
                    [-2.339, 1.351],
                    [1.35, 2.338],
                    [2.336, -1.349],
                    [0, 0],
                    [0, 0],
                    [-0.189, -0.329],
                    [-0.352, -0.041],
                    [-2.793, 2.792],
                    [-0.47, 0.471],
                  ],
                  v: [
                    [-12.944, -4.242],
                    [-5.511, 8.632],
                    [-8.153, 9.285],
                    [-9.943, 15.963],
                    [-3.263, 17.753],
                    [-1.463, 11.093],
                    [-1.457, 11.09],
                    [-8.089, -0.395],
                    [3.135, -11.619],
                    [7.883, -3.393],
                    [5.242, -2.743],
                    [3.452, 3.938],
                    [10.131, 5.727],
                    [11.925, -0.945],
                    [11.931, -0.948],
                    [1.799, -18.482],
                    [0.946, -19.063],
                    [-0.039, -18.696],
                    [-12.69, -6.045],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.235294117647, 0.227450980392, 0.392156862745, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 0,
                    s: [74, 74],
                    to: [-6, 8.534],
                    ti: [6, -8.534],
                  },
                  { t: 80.0000032584668, s: [37.999, 125.204] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0.664] },
                    t: 0,
                    s: [30],
                  },
                  { t: 77.0000031362743, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.325, -0.563],
                    [0, 0],
                    [0.832, -0.481],
                    [-1.35, -2.338],
                    [-2.339, 1.35],
                    [1.334, 2.336],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.832, -0.48],
                    [-1.35, -2.339],
                    [-2.338, 1.351],
                    [1.344, 2.338],
                    [0, 0],
                    [0, 0],
                    [0.353, 0.041],
                    [0.275, -0.274],
                    [2.474, -2.473],
                  ],
                  o: [
                    [0, 0],
                    [-0.895, -0.037],
                    [-2.339, 1.35],
                    [1.35, 2.339],
                    [2.331, -1.346],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.894, -0.037],
                    [-2.339, 1.351],
                    [1.35, 2.338],
                    [2.336, -1.348],
                    [0, 0],
                    [0, 0],
                    [-0.189, -0.328],
                    [-0.352, -0.041],
                    [-2.793, 2.792],
                    [-0.47, 0.471],
                  ],
                  v: [
                    [-12.944, -4.241],
                    [-5.511, 8.632],
                    [-8.153, 9.285],
                    [-9.943, 15.964],
                    [-3.263, 17.754],
                    [-1.463, 11.093],
                    [-1.457, 11.09],
                    [-8.089, -0.396],
                    [3.134, -11.62],
                    [7.883, -3.393],
                    [5.242, -2.742],
                    [3.452, 3.938],
                    [10.131, 5.727],
                    [11.925, -0.946],
                    [11.931, -0.948],
                    [1.799, -18.483],
                    [0.946, -19.062],
                    [-0.039, -18.695],
                    [-12.69, -6.045],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.235294117647, 0.227450980392, 0.392156862745, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 0,
                    s: [74, 74],
                    to: [8.688, -2.902],
                    ti: [-8.688, 2.902],
                  },
                  { t: 80.0000032584668, s: [126.131, 56.59] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [-30],
                  },
                  { t: 77.0000031362743, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.118, -0.439],
                    [0, 0],
                    [0.65, 0.174],
                    [0.49, -1.826],
                    [-1.826, -0.489],
                    [-0.496, 1.817],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.65, 0.174],
                    [0.49, -1.826],
                    [-1.826, -0.489],
                    [-0.491, 1.823],
                    [0, 0],
                    [0, 0],
                    [0.155, 0.194],
                    [0.272, 0],
                    [2.449, 0.001],
                  ],
                  o: [
                    [0, 0],
                    [-0.425, -0.461],
                    [-1.826, -0.489],
                    [-0.489, 1.826],
                    [1.82, 0.488],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.425, -0.461],
                    [-1.826, -0.489],
                    [-0.489, 1.826],
                    [1.824, 0.489],
                    [0, 0],
                    [0, 0],
                    [0.069, -0.256],
                    [-0.153, -0.195],
                    [-2.764, -0.001],
                    [-0.466, 0],
                  ],
                  v: [
                    [-2.705, -8.039],
                    [-5.398, 2.013],
                    [-7.029, 1.028],
                    [-11.221, 3.448],
                    [-8.801, 7.64],
                    [-4.613, 5.235],
                    [-4.609, 5.236],
                    [-2.205, -3.732],
                    [8.904, -3.732],
                    [7.185, 2.69],
                    [5.553, 1.705],
                    [1.361, 4.125],
                    [3.782, 8.317],
                    [7.973, 5.902],
                    [7.977, 5.904],
                    [11.641, -7.79],
                    [11.506, -8.499],
                    [10.837, -8.805],
                    [-1.687, -8.806],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.235294117647, 0.227450980392, 0.392156862745, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 0,
                    s: [74, 74],
                    to: [-10.444, 6.263],
                    ti: [10.444, -6.263],
                  },
                  { t: 80.0000032584668, s: [11.338, 111.579] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [-30],
                  },
                  { t: 77.0000031362743, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.228, -0.394],
                    [0, 0],
                    [0.583, 0.337],
                    [0.945, -1.637],
                    [-1.637, -0.945],
                    [-0.949, 1.627],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.583, 0.337],
                    [0.945, -1.637],
                    [-1.637, -0.945],
                    [-0.946, 1.633],
                    [0, 0],
                    [0, 0],
                    [0.099, 0.228],
                    [0.263, 0.071],
                    [2.365, 0.635],
                  ],
                  o: [
                    [0, 0],
                    [-0.29, -0.555],
                    [-1.637, -0.945],
                    [-0.945, 1.637],
                    [1.632, 0.942],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.29, -0.555],
                    [-1.637, -0.945],
                    [-0.945, 1.637],
                    [1.635, 0.945],
                    [0, 0],
                    [0, 0],
                    [0.133, -0.23],
                    [-0.099, -0.228],
                    [-2.669, -0.715],
                    [-0.45, -0.12],
                  ],
                  v: [
                    [-0.901, -8.912],
                    [-6.104, 0.1],
                    [-7.424, -1.274],
                    [-12.099, -0.021],
                    [-10.846, 4.655],
                    [-6.18, 3.415],
                    [-6.176, 3.418],
                    [-1.532, -4.623],
                    [9.199, -1.747],
                    [5.874, 4.011],
                    [4.554, 2.637],
                    [-0.12, 3.89],
                    [1.132, 8.565],
                    [5.805, 7.318],
                    [5.808, 7.32],
                    [12.893, -4.959],
                    [12.945, -5.679],
                    [12.377, -6.148],
                    [0.282, -9.39],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.235294117647, 0.227450980392, 0.392156862745, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 0,
                    s: [74, 74],
                    to: [-3.588, -9.596],
                    ti: [3.588, 9.596],
                  },
                  { t: 80.0000032584668, s: [52.47, 16.424] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [-30],
                  },
                  { t: 77.0000031362743, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.39, -0.676],
                    [0, 0],
                    [0.999, 0.577],
                    [1.62, -2.806],
                    [-2.807, -1.62],
                    [-1.627, 2.788],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.999, 0.576],
                    [1.62, -2.806],
                    [-2.807, -1.62],
                    [-1.623, 2.8],
                    [0, 0],
                    [0, 0],
                    [0.17, 0.391],
                    [0.45, 0.121],
                    [4.055, 1.088],
                  ],
                  o: [
                    [0, 0],
                    [-0.498, -0.951],
                    [-2.806, -1.62],
                    [-1.62, 2.806],
                    [2.798, 1.616],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.498, -0.952],
                    [-2.806, -1.621],
                    [-1.62, 2.807],
                    [2.803, 1.619],
                    [0, 0],
                    [0, 0],
                    [0.227, -0.393],
                    [-0.169, -0.391],
                    [-4.576, -1.227],
                    [-0.771, -0.206],
                  ],
                  v: [
                    [-1.545, -15.277],
                    [-10.465, 0.171],
                    [-12.727, -2.184],
                    [-20.742, -0.035],
                    [-18.594, 7.979],
                    [-10.593, 5.855],
                    [-10.586, 5.859],
                    [-2.628, -7.924],
                    [15.769, -2.996],
                    [10.07, 6.875],
                    [7.808, 4.521],
                    [-0.207, 6.669],
                    [1.941, 14.684],
                    [9.951, 12.544],
                    [9.958, 12.549],
                    [22.102, -8.501],
                    [22.192, -9.736],
                    [21.219, -10.538],
                    [0.482, -16.096],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.235294117647, 0.227450980392, 0.392156862745, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 0,
                    s: [74, 74],
                    to: [9.333, 3.917],
                    ti: [-9.333, -3.917],
                  },
                  { t: 80.0000032584668, s: [127.701, 98.883] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [30],
                  },
                  { t: 77.0000031362743, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.236, -0.879],
                    [0, 0],
                    [1.3, -0.349],
                    [-0.979, -3.652],
                    [-3.652, 0.978],
                    [0.959, 3.642],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.3, -0.349],
                    [-0.978, -3.652],
                    [-3.652, 0.979],
                    [0.972, 3.648],
                    [0, 0],
                    [0, 0],
                    [0.464, 0.183],
                    [0.471, -0.272],
                    [4.243, -2.448],
                  ],
                  o: [
                    [0, 0],
                    [-1.196, -0.374],
                    [-3.651, 0.978],
                    [0.978, 3.651],
                    [3.641, -0.976],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.196, -0.374],
                    [-3.652, 0.978],
                    [0.979, 3.651],
                    [3.648, -0.978],
                    [0, 0],
                    [0, 0],
                    [-0.137, -0.512],
                    [-0.461, -0.183],
                    [-4.788, 2.763],
                    [-0.806, 0.466],
                  ],
                  v: [
                    [-14.583, -9.518],
                    [-9.196, 10.585],
                    [-13.005, 10.509],
                    [-17.846, 18.893],
                    [-9.462, 23.733],
                    [-4.613, 15.38],
                    [-4.605, 15.377],
                    [-9.411, -2.559],
                    [9.834, -13.67],
                    [13.275, -0.825],
                    [9.467, -0.901],
                    [4.625, 7.483],
                    [13.009, 12.323],
                    [17.852, 3.95],
                    [17.861, 3.947],
                    [10.515, -23.435],
                    [9.571, -24.528],
                    [8.107, -24.388],
                    [-13.587, -11.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.235294117647, 0.227450980392, 0.392156862745, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 0,
                    s: [74, 74],
                    to: [-9.32, -6.352],
                    ti: [9.32, 6.352],
                  },
                  { t: 80.0000032584668, s: [18.08, 35.888] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [30],
                  },
                  { t: 77.0000031362743, s: [0] },
                ],
                ix: 6,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 5,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 80.0000032584668, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90.0000036657751,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
