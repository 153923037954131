/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
import { Box } from '@mui/material';
import { ticketAnim } from '../../constants/ticket';

interface Props {
  style?: any;
}

const TicketAnimated = (props: Props) => {
  const { style } = props;

  return (
    <Box style={style ? style : {}}>
      <Player
        src={ticketAnim}
        autoplay
        loop
        style={{backgroundSize: 'cover'}}
      />
    </Box>
  );
};

export default React.memo(TicketAnimated);


