/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import useAuth from "../../services/Auth/useAuth";
import { getRouteManager } from "../../services/routeManager";
import { getLanguage, strings } from "../../services/translation";
import LoadingView from "../LoadingView";
import { Box, ButtonBase, Typography } from "@mui/material";
import { columnStyles, rowStyles } from "../../style/flex";
import SoundManager from "../../services/SoundManager";
import normalize from "../../utils/fontSizer";
import { Colors } from "../../style";
import { ReactComponent as IconNews } from "../../assets/icons/icon_news.svg";
import { ReactComponent as IconLock } from "../../assets/icons/icon_lock.svg";

const TitleModal = ({ companion = null, callback = null }: { companion?: any, callback?: any }) => {
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [nbUnlocked, setNbUnlocked] = useState(0);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    getTitles();
  }, []);

  const byValue = (a: any, b: any) => a.displayOrder - b.displayOrder;

  const getTitles = async () => {
    try {
      if (auth && auth.user) {
        let nbUnlock = 0;
        const api = getRouteManager().getTitlesList(getLanguage());
        const token = auth.user.login_token;
        const json = await getRouteManager().fetchData(api, {
          login_token: token,
        });

        if (json.flashmessage !== "error") {
          json.map((item: any) => {
            item.displayOrder = item.id;
            if (item.unlocked) {
              nbUnlock++;
            } else {
              item.displayOrder += 1000;
            }
          });
          json.sort(byValue);
          setTitles(json);
          setNbUnlocked(nbUnlock);
        }
      } else {
        alert("not logged in, please refresh the page");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const changeTitle = async (item: any) => {
    try {
      setLoading(true);
      if (auth && auth.user) {
        const token = auth.user.login_token;
        if (companion) {
          const api = getRouteManager().updateCompanionForUser();
          companion.titleId = item.id
          const res = await getRouteManager().fetchData(api, {
            login_token: token,
            companion: companion,
          })
          if (callback) {
            callback(item);
          }
        } else {
          const api = getRouteManager().changeTitle();
          const json = await getRouteManager().fetchData(api, {
            login_token: token,
            titleId: item.id,
          });
          if (json.flashmessage !== "error") {
            getRouteManager().setUser(json);
            auth.setUser(json);
          }
        }

      } else {
        alert("Not logged in, please refresh the page");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const renderItem = (title: any, index: number) => (
    <ButtonBase
      disabled={!title.unlocked}
      onClick={() => {
        SoundManager.getInstance().playBtnSound();
        changeTitle(title);
      }}
      key={index}
      sx={[
        styles.single,
        index == titles.length - 1 ? { marginBottom: "80px" } : {},
        (companion && companion.titleId === title.id) || (!companion && auth.user.titleId === title.id)
          ? {
            border: `solid 2px ${Colors.ACCENT}`,
          }
          : {},
      ]}
    >
      {title.unlocked === true ? (
        <Box sx={styles.titleBlock}>
          <Box sx={styles.circle}>
            <IconNews width={28} height={28} fill={Colors.ACCENT} />
          </Box>
          <Box sx={styles.titleLocked}>
            <Typography sx={styles.issueDoneText}>{title.name}</Typography>
            <Typography sx={styles.issueDonePercent}>
              {title.description}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.titleBlock}>
          <Box sx={styles.circle}>
            <IconLock width={28} height={28} fill="#535467" />
          </Box>
          <Box sx={styles.titleLocked}>
            <Typography sx={styles.issueNotDonePercent}>
              {strings.notUnlocked}
            </Typography>
            <Typography sx={[styles.issueNotDonePercent, { marginTop: "5px" }]}>
              {strings.notUnlockedSub}
            </Typography>
          </Box>
        </Box>
      )}
    </ButtonBase>
  );

  if (loading) {
    return <LoadingView />;
  } else {
    return (
      <Box sx={styles.container}>
        <Typography sx={styles.nbUnlocked}>
          {nbUnlocked}/{titles.length} {strings.titlesUnlocked}
        </Typography>
        {titles &&
          titles.length &&
          titles.map((title: any, index: number) => renderItem(title, index))}
      </Box>
    );
  }
};

const styles = {
  container: {
    ...columnStyles,
    flex: 1,
    width: "100%",
  },
  nbUnlocked: {
    fontSize: normalize(14),
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    marginBottom: "15px",
  },
  single: {
    width: "100%",
    marginBottom: "8px",
    padding: "20px 15px",
    borderRadius: "13px",
    backgroundColor: Colors.BTN_ACTIVE,
  },
  titleBlock: {
    ...rowStyles,
    justifyContent: "space-between",
    width: "100%",
  },
  titleUnlocked: {
    ...columnStyles,
    justifyContent: "space-between",
    width: "70%",
  },
  titleLocked: {
    ...columnStyles,
    justifyContent: "center",
    alignItems: "flex-start",
    width: "80%",
  },
  circle: {
    alignSelf: "center",
  },
  issueDoneText: {
    color: Colors.WHITE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    alignSelf: "flex-start",
    textAlign: "start",
    width: "80%",
  },
  issueDonePercent: {
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(12),
    alignSelf: "flex-start",
    textAlign: "start",
    marginTop: "5px",
  },
  issueNotDoneText: {
    fontSize: normalize(16),
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    alignSelf: "flex-start",
    textAlign: "start",
    width: "80%",
  },
  issueNotDonePercent: {
    fontSize: normalize(12),
    color: Colors.OLD_PRICE,
    fontFamily: "Gilroy-Bold",
    alignSelf: "flex-start",
    textAlign: "start",
  },
};

export default TitleModal;
