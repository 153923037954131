/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { Level } from "../models/Level";
import { Mission } from "../models/mission";
import { Friend } from "../models/friend";
import { GameRequest } from "../models/gameRequest";
import { Referral } from "../models/referral";

/* export const PurchaseContext = createContext({
  purchaseDisabled: false,
  handlePurchaseDisabled: (val?: boolean) => {},
});

export const AdsContext = createContext({
  isInitialized: false,
  rewarded: null,
  consent: null,
  adsLoaded: false,
  loadAd: () => {},
  handleLoaded: (val: any) => {},
  checkConsent: async (val: any) => {},
  adsError: null,
}); */

interface GameContextType {
    gameID: string;
    playerID: string;
    gameRequest: GameRequest | null;
    wasHost: boolean;
    setGame: (
        gameIDParam: string,
        playerIDParam: string,
        wasHostParam: boolean,
    ) => void;
    removeRequest: VoidFunction;
}

export const GameContext = createContext<GameContextType>({
    gameID: "",
    playerID: "",
    gameRequest: null,
    wasHost: false,
    setGame: (
        gameIDParam: string,
        playerIDParam: string,
        wasHostParam = false,
    ) => {},
    removeRequest: () => {},
});

export const NotificationContext = createContext({
    hasNewLevel: -1,
    hasNewMissions: -1,
    hasNews: -1,
    hasReward: -1,
    hasFriendRequest: -1,
    hasReferralReward: -1,
    getLevels: async (): Promise<Array<Level> | null> => null,
    getMissions: async (): Promise<Array<Mission> | null> => null,
    getNews: async (): Promise<any> => {},
    getFeatured: async () => {},
    getFriends: async (): Promise<Array<Friend>> => [],
    getReferrals: async (): Promise<any> => [],
});

export const CookiesContext = createContext({
    cookiesSet: false,
    cookieAudience: false,
    cookieUsage: false,
    checkCookies: () => {},
    updateCookies: (consent: boolean, usage: boolean, audience: boolean) => {},
});

export const SidebarContext = createContext({
    sidebarCollapsed: false,
    setSidebarCollapsed: (val: boolean) => {},
});
