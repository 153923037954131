import { Colors } from "../../style";
import {
    columnCenterStartStyles,
    columnStartStyles,
    columnStyles,
    rowCenterStyles,
    rowStartStyles,
    rowStyles,
} from "../../style/flex";
import { Eczar, Gilroy } from "../../style/fonts";
import { normalizeSize, normalizeVh } from "../../utils/fontSizer";

export const styles = {
    mainContainer: {
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        //maxHeight: "calc(96vh - 20px)",
        flex: "1 1 auto",
        background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%), url(${require("../../assets/images/background.jpg")}) 50%/cover`,
    },
    mainContainerBig: {
        ...rowCenterStyles,
        alignItems: "flex-start",
    },
    mainContainerSmall: {
        ...columnStyles,
        alignItems: "center",
    },
    container: {
        width: "100%",
        alignItems: "center",
        ...columnStyles,
        justifyContent: "flex-start",
        maxWidth: "450px",
        minWidth: "450px",
        rowGap: "10px",
        paddingTop: "4vh",
        paddingBottom: "50px",
        flex: "0 0 auto",
    },
    containerSmall: {
        width: "100%",
        ...columnStartStyles,
        alignItems: "center",
        gap: "5px",
        paddingTop: "4vh",
        flex: "0 0 auto",
    },
    topMenuTitle: {
        //width: "100%",
        fontSize: "50px",
        fontFamily: "Eczar-Regular",
        fontWeight: 400,
        color: Colors.WHITE,
        //marginBottom: "2vh",
    },
    subtitle: {
        gap: "10px",
        ...rowStyles,
        alignItems: "center",
        width: "100%",
        fontSize: "20px",
        lineHeight: "20px",
        color: Colors.OLD_PRICE,
        fontFamily: Gilroy,
        marginBottom: "20px",
    },
    noStories: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(14),
        color: Colors.WHITE,
    },
    storiesList: {
        ...columnStartStyles,
        flex: 1,
        padding: "20px",
        rowGap: normalizeSize(10),
    },
    right: {
        ...columnStyles,
        //flex: 1,
        justifyContent: "flex-start",
        maxWidth: normalizeSize(450),
        minWidth: normalizeSize(450),
        paddingTop: normalizeVh(4),
        paddingBottom: normalizeSize(50),
        rowGap: normalizeSize(16),
    },
    // STORY BOX
    storyContainer: {
        ...rowStartStyles,
        width: normalizeSize(300),
        height: normalizeSize(120),
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: normalizeSize(7),
        position: "relative",
        overflow: "hidden",
    },
    imageBackgroundContainer: {
        height: "100%",
        width: normalizeSize(180),
        //backgroundColor: "red",
        position: "absolute",
        right: 0,
        overflow: "hidden",
    },
    imageBackgroundLeftContainer: {
        height: "100%",
        width: normalizeSize(119),
        position: "absolute",
        left: 0,
    },
    titleContainer: {
        width: "45%",
        height: "100%",
        ...columnCenterStartStyles,
        paddingLeft: normalizeSize(16),
        zIndex: 2,
    },
    titleContainerLocked: {
        ...rowStartStyles,
        width: "45%",
        height: "100%",
        columnGap: normalizeSize(10),
        paddingLeft: normalizeSize(16),
        zIndex: 2,
    },
    gradientTitle: {
        fontFamily: Eczar.regular,
        fontSize: normalizeSize(24),
        lineHeight: normalizeSize(26),
        color: Colors.WHITE,
        textAlign: "left",
    },
    subtitleUnlocked: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.vertALEXTESGRANDSMORTS,
        textAlign: "left",
    },
    subtitleLocked: {
        fontFamily: Gilroy,
        fontSize: normalizeSize(12),
        color: Colors.OLD_PRICE,
        marginTop: normalizeSize(2),
        textAlign: "left",
    },
};
