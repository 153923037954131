/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Component } from "react";

import ColorManager from "../../../services/colorManager";
import StoryReader from "../../../services/StoryReader";
import styles from "./styles";
import TextScene from "../../../components/TextScene";
import { isNumber } from "../../../utils/TypeOfHelper";
import { strings } from "../../../services/translation";
import AvatarContainer from "../../../components/AvatarContainer";
import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { rowCenterStyles } from "../../../style/flex";

// =======================================================

interface Props {
  countdown: any;
  timerRef: any;
  text: any;
  drawChoices: any;
  players: any;
  color: any;
  textColor: any;
  hlText: any;
  timerTextRef: any;
  overlay: boolean;
  storyReader: StoryReader;
  choicesLength: number;
  max?: number;
  isVisibleText?: boolean;
  masterPlayer?: any;
}

interface State {
  textScale: any;
}

// =======================================================

class TimerScreen extends Component<Props, State> {
  /**
   *
   */
  constructor(props: Props) {
    super(props);
  }

  /**
   * Current progresse / fill
   * @see https://github.com/bartgryszko/react-native-circular-progress#configuration
   */
  onFill = () => {
    const { countdown, max = null } = this.props;
    return isNumber(max) && max !== null ? (countdown / max) * 100 : 0;
  };

  /**
   *
   */
  drawPlayer = (players: any, master: number | any) => {
    const player = players[master];

    if (player.avatar) {
      return (
        <AvatarContainer
          user={player}
          persoWidth={98}
          borderWidth={5}
          borderColor={ColorManager.getInstance().getColor("secondary")}
        />
      );
    } else {
      return (
        <Box
          sx={[
            styles.letter,
            {
              backgroundColor: player.letterColor,
              borderColor: ColorManager.getInstance().getColor("secondary"),
            },
          ]}
        >
          <Typography style={styles.playerLetter}>{player.letter}</Typography>
        </Box>
      );
    }
  };

  getTimerSize = () => {
    const aaa = 0.6 * window.innerHeight;
    const size =
      aaa > 500 && aaa < 650
        ? aaa * 0.214
        : aaa > 650
        ? 140
        : aaa < 500
        ? 107
        : 120;
    return size;
  };

  // --
  render() {
    const {
      text,
      timerRef,
      drawChoices,
      players,
      countdown,
      color,
      textColor,
      hlText,
      overlay,
      storyReader,
      isVisibleText = true,
      masterPlayer = null,
    } = this.props;

    return (
      <Fade in={true} appear timeout={800}>
        <Box sx={[styles.container, { zIndex: 6 }]}>
          {isVisibleText ? (
            <Box style={styles.timerStyle}>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: ColorManager.getInstance().getColor("quinary"),
                  }}
                  size={this.getTimerSize()} // 107 140
                  thickness={3}
                  value={100}
                />

                <CircularProgress
                  variant="determinate"
                  size={this.getTimerSize()}
                  ref={timerRef}
                  thickness={3}
                  value={this.onFill()}
                  sx={{
                    position: "absolute",
                    left: 0,
                    color: color,
                    strokeLinecap: "round",
                  }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    ...rowCenterStyles,
                  }}
                >
                  <Typography
                    sx={[
                      styles.timerText,
                      {
                        color: textColor,
                      },
                    ]}
                  >
                    {countdown}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : null}

          {isVisibleText ? (
            <TextScene
              players={players}
              color={color}
              textColor={textColor}
              hlText={hlText}
              overlay={overlay}
              storyReader={storyReader}
              shouldResize={false}
              marginTop={false}
            >
              {text}
            </TextScene>
          ) : (
            <>
              <Box style={styles.changeContainer}>
                {this.drawPlayer(players, masterPlayer)}

                <Typography sx={[styles.changing, { color: textColor }]}>
                  {strings.secret}
                </Typography>
              </Box>
              <TextScene
                players={players}
                color={color}
                hlText={hlText}
                textColor={textColor}
                overlay={overlay}
                storyReader={storyReader}
                shouldResize={false}
                marginTop={false}
              >
                {strings.secretTextHide}
              </TextScene>
            </>
          )}

          <Box style={{ zIndex: 10 }}>{drawChoices()}</Box>
        </Box>
      </Fade>
    );
  }
}

// =======================================================

export default TimerScreen;
