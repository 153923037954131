/* eslint-disable @typescript-eslint/no-explicit-any */
import { analytics } from "../../utils/FirebaseHelper";
import { getRouteManager } from "../routeManager";

class EventManager {
  private cookieAudience: boolean;

  constructor() {
    this.cookieAudience = false;
  }

  setCookieAudience(cookieAudience: boolean) {
    this.cookieAudience = cookieAudience;
  }

  // OK
  logLogin = () => {
    if (this.cookieAudience) {
      analytics.logEvent("login", {
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  // OK
  logSignUp = () => {
    if (this.cookieAudience) {
      analytics.logEvent("sign_up");
    }
  };

  // OK
  logFtueCompleted = () => {
    if (this.cookieAudience) {
      analytics.logEvent("tutorial_complete", {
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  logLaunchStoryOnline = (id?: number | null) => {
    if (this.cookieAudience) {
      analytics.logEvent("launch_story_online", {
        id: id,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  logLaunchStorySolo = (id?: number | null) => {
    if (this.cookieAudience) {
      analytics.logEvent("launch_story_solo", {
        id: id,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  logLaunchStoryBoard = (id?: number | null) => {
    if (this.cookieAudience) {
      analytics.logEvent("launch_story_board", {
        id: id,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  logBeginCheckout = (value?: number, currency?: string) => {
    if (this.cookieAudience) {
      analytics.logEvent('begin_checkout', {
        value: value,
        currency: currency,
      });
    }
  };

  logPurchase = (
    transactionId: string,
    value?: number,
    currency?: string
  ) => {
    if (this.cookieAudience) {
      analytics.logEvent("purchase", {
        transaction_id: transactionId,
        value: value,
        currency: currency,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  logItemPurchase = (id: number, price: number, type: string) => {
    if (this.cookieAudience) {
      analytics.logEvent("item_purchase", {
        id: id,
        price: price,
        type: type,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  /**
   * Event triggered on story transaction completed
   */
  logStoryPurchase = (id: number, price: number) => {
    if (this.cookieAudience) {
      analytics.logEvent("story_purchase", {
        id: id,
        price: price,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  };

  /**
   * Event triggered on series transaction completed
   */
  logSeriesPurchase = (id: number, price: number) => {
    if (this.cookieAudience) {
      analytics.logEvent("series_purchase", {
        user: getRouteManager().getUser()?.GetId(),
        id: id,
        price: price,
        user_id: getRouteManager().getUser()?.GetId(),
      });
    }
  }

  // Autres fonctions pour envoyer des événements spécifiques...
}

const eventManager = new EventManager();

export const getEventManager = () => {
  return eventManager;
};
