/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import {
    SCREEN_HEIGHT,
} from '../../utils/size';
import { Colors } from '../../style';

import ColorManager from '../../services/colorManager';
import normalize from '../../utils/fontSizer';
import { Eczar, Gilroy } from '../../style/fonts';
import {
    columnBetweenStyles,
    columnCenterStyles,
    columnEndStyles,
    columnStartStyles,
    rowCenterStyles,
} from '../../style/flex';
import { strings } from '../../services/translation';
import { diceAnim } from '../../constants/dice';
import { IChoice } from '../../services/StoryReader/interfaces';
import { Box, ButtonBase, Slide, Typography, Zoom } from '@mui/material';

/* ICONS */
import { ReactComponent as NextIcon } from '../../assets/icons/icon_next.svg';
import { ReactComponent as DiceIcon } from '../../assets/icons/icon_dice.svg';
import { ReactComponent as RandomIcon } from '../../assets/icons/icon_random.svg';
import { Player } from '@lottiefiles/react-lottie-player';
import StoryReader from '../../services/StoryReader';
import { db } from '../../utils/FirebaseHelper';
import { FIREBASE_DATABASE } from '../../constants';
import { isNotEmptyString } from '../../utils/StringHelper';
import { isTrueBoolean } from '../../utils/TypeOfHelper';
import { onAddHasPlayedFeedbackAnimation } from '../../services/FirebaseRealtimeDatabaseManager';

const DicePopup = ({
    choice,
    random,
    callback,
    storyReader,
    onReboot = false,
    isSoloPlayer = false,
    gameID = "",
}: {
    choice: IChoice;
    random: number;
    callback: any;
    onReboot?: boolean;
    isSoloPlayer?: boolean;
    storyReader?: StoryReader;
    gameID?: string;
}) => {

    /* COLORS */
    const bgColor = ColorManager.getInstance().getColor('primary');
    const dicebgColor = ColorManager.getInstance().getColor('secondary');
    const accentColor = ColorManager.getInstance().getColor('tertiary');
    const textColor = ColorManager.getInstance().getColor('quaternary');
    const buttonColor = ColorManager.getInstance().getColor('quinary');

    /* STATES */
    const [isAnimating, setIsAnimating] = useState(false);
    const [isAnimFinished, setIsAnimFinished] = useState(false);
    const [isScaled, setIsScaled] = useState(false);

    /** 
     * 
     * 
     * 
     * 
     * 
     * USE EFFECTS
     * 
     * 
     * 
     * 
     *  */

    useEffect(() => {
        if (isAnimating) {
            setIsScaled(true);
        }
    }, [isAnimating]);

    /**
     * Enable firebase listener for `hasPlayedFeedbackAnimation` ref
     * [when `hasChangedSceneRef` state value is true]
     */
    useEffect(() => {
        let isActive = true;

        let onHasPlayedFeedbackAnimation: any = null;

        if (isActive && isNotEmptyString(gameID)) {
            onHasPlayedFeedbackAnimation = db
                .ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasPlayedFeedbackAnimation`
                )
                .on("value", hasPlayedFeedbackAnimationCallback);
        }

        // stop listening for updates when no longer required
        return () => {
            isActive = false;

            if (onHasPlayedFeedbackAnimation != null && isNotEmptyString(gameID)) {
                db.ref(
                    `/${FIREBASE_DATABASE.REFERENCES.GAMES}/${gameID}/hasPlayedFeedbackAnimation`
                ).off("value", onHasPlayedFeedbackAnimation);
            }
        };
    }, []);



    /**
     * 
     * 
     * 
     * 
     *  FUNCTIONS
     * 
     * 
     * 
     * 
     *  */

    const getDiceAnimWithColors = () => {
        let anim = diceAnim;
        anim = ColorManager.getInstance().changeAnimColors(
            anim,
            [Colors.PRIMARY, Colors.GRAY_DICE],
            [bgColor, dicebgColor],
        );
        return anim;
    };

    const clickRollBtn = () => {
        if (isNotEmptyString(gameID)) {
            onAddHasPlayedFeedbackAnimation(true, gameID);
        } else {
            setIsAnimating(true);
        }
    }

    const hasPlayedFeedbackAnimationCallback = (snapshot: any) => {
        if (isTrueBoolean(snapshot.val())) {
            setIsAnimating(true);
        }
    }

    const onFinishAnim = () => {
        setIsAnimFinished(true);
        setIsScaled(false);
        setIsAnimating(false);
    }

    /**
     * 
     * 
     * 
     * 
     *  RENDER FN 
     * 
     * 
     * 
     * 
     * */

    const renderButton = () => {
        if (!isAnimating && isAnimFinished) {
            return renderNextButton();
        } else if (!isAnimating && isSoloPlayer) {
            return renderRollButton();
        } else if (!isAnimating && !isSoloPlayer) {
            if (isNotEmptyString(gameID)) {
                return renderPlayerIcon();
            } else {
                return renderRollButton();
            }
        } else {
            return null;
        }
    };

    const renderNextIcon = () => {
        return (
            <NextIcon
                width={45}
                height={45}
                fill={buttonColor ? buttonColor : Colors.BTN_ACTIVE}
                fillSecondary={textColor ? textColor : Colors.WHITE}
            />
        );
    };

    const renderNextButton = () => (
        <ButtonBase
            onClick={() => { callback(); }}>
            {renderNextIcon()}
        </ButtonBase>
    );

    const renderRollButton = () => (
        <ButtonBase
            onClick={() => {
                clickRollBtn();
            }}
            sx={[
                styles.button,
                {
                    backgroundColor: dicebgColor
                        ? dicebgColor
                        : Colors.GRAY_DICE,
                },
            ]}>
            <RandomIcon width={25} height={25} fill={Colors.WHITE} />
            <Typography sx={[styles.text, styles.buttonText]}>
                {strings.actions.roll}
            </Typography>
        </ButtonBase>
    );

    const renderPlayerIcon = () => {
        if (storyReader) {
            const mj = storyReader.getPlayers().getPlayerById(storyReader.getMasterPlayerId());
            if (mj) {
                return (
                    <Box sx={{ ...rowCenterStyles }}>
                        <Typography component={"span"} sx={styles.text}>{strings.labels.waitingFor}{" "}<Typography component={"span"} sx={[styles.text, {
                            color: accentColor ? accentColor : Colors.ACCENT
                        }]}>
                            {mj.GetName()}</Typography></Typography>
                    </Box>
                );
            } else return (
                <Box sx={{ ...rowCenterStyles }}>
                    <Typography sx={styles.text}>{strings.labels.waiting}</Typography>
                </Box>

            );
        } else {
            return (
                <Box sx={{ ...rowCenterStyles }}>
                    <Typography sx={styles.text}>{strings.labels.waiting}</Typography>
                </Box>
            );
        }
    }


    /**
     * 
     * 
     * 
     * 
     * 
     * RENDER
     * 
     * 
     * 
     * 
     * 
     */

    return (
        <Box sx={styles.centeredView}>
            <Box
                sx={[
                    styles.modalView,
                    {
                        backgroundColor: bgColor ? bgColor : Colors.PRIMARY,
                    },
                ]}>
                <Box sx={styles.container}>
                    <Box sx={[styles.content]}>
                        <Box
                            sx={[
                                styles.content,
                                {
                                    width: 120,
                                    height: 120,
                                    transform: isScaled ? 'scale(1.875)' : 'scale(1)',
                                    transition: 'transform 0.5s',
                                    transformOrigin: 'center center',
                                    marginTop: '70px',
                                },
                            ]}>
                            {!isAnimating && !isAnimFinished ? (
                                <DiceIcon
                                    width={'100%'}
                                    height={'100%'}
                                    fill={
                                        dicebgColor
                                            ? dicebgColor
                                            : Colors.GRAY_DICE
                                    }
                                    fillSecondary={
                                        bgColor ? bgColor : Colors.PRIMARY
                                    }
                                />
                            ) : (
                                <Player
                                    src={getDiceAnimWithColors()}
                                    keepLastFrame
                                    autoplay={isAnimating && !isAnimFinished}
                                    loop={false}
                                    /* loop={false} */
                                    speed={1.5}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onEvent={(event: any) => {
                                        if (event === "complete") {
                                            onFinishAnim();
                                        }
                                    }}
                                />
                            )}

                            <Box sx={styles.resultNumberView}>
                                <Typography
                                    sx={[
                                        styles.resultNumberText,
                                        {
                                            color: accentColor
                                                ? accentColor
                                                : Colors.ACCENT,
                                        },
                                        isAnimating && {
                                            fontSize: 80,
                                        }
                                    ]}>
                                    {isAnimFinished && random
                                        ? random
                                        : isAnimating
                                            ? '?'
                                            : 20}
                                </Typography>
                            </Box>
                        </Box>
                        {!isAnimating ? (
                            <Box sx={[styles.textContainer]}>
                                {!isAnimFinished ? (
                                    <Typography
                                        sx={[
                                            styles.title,
                                            {
                                                color: accentColor
                                                    ? accentColor
                                                    : Colors.ACCENT,
                                            },
                                        ]}>
                                        {strings.labels.rollDice}
                                    </Typography>
                                ) : (
                                    <Zoom in={true} appear timeout={600}>
                                        <Typography
                                            sx={[
                                                styles.title,
                                                {
                                                    color: accentColor
                                                        ? accentColor
                                                        : Colors.ACCENT,
                                                },
                                            ]}>
                                            {random &&
                                                choice.diceNumber &&
                                                random > choice.diceNumber
                                                ? strings.labels.success
                                                : strings.labels.failure}
                                        </Typography>
                                    </Zoom>
                                )}
                                {isAnimFinished ? (
                                    <Slide in={true} direction='left' appear timeout={600}>
                                        <Typography
                                            component={"span"}
                                            sx={[
                                                styles.text,
                                                {
                                                    color: textColor
                                                        ? textColor
                                                        : Colors.WHITE,
                                                    marginTop:
                                                        '30px',
                                                },
                                            ]}>
                                            {strings.labels.youGotA}{' '}
                                            <Typography
                                                component={"span"}
                                                sx={[
                                                    styles.text,
                                                    {
                                                        color: accentColor
                                                            ? accentColor
                                                            : Colors.ACCENT,
                                                    },
                                                ]}>
                                                {random}
                                            </Typography>
                                            .
                                        </Typography>
                                    </Slide>
                                ) : (
                                    <Typography
                                        component={"span"}
                                        sx={[
                                            styles.text,
                                            {
                                                color: textColor
                                                    ? textColor
                                                    : Colors.WHITE,
                                                marginTop:
                                                    '30px',
                                            },
                                        ]}>
                                        {strings.labels.getMoreThan}{' '}
                                        <Typography
                                            component={"span"}
                                            sx={[
                                                styles.text,
                                                {
                                                    color: accentColor
                                                        ? accentColor
                                                        : Colors.ACCENT,
                                                },
                                            ]}>
                                            {choice.diceNumber}
                                        </Typography>{' '}
                                        {strings.labels.toSucceed}
                                    </Typography>
                                )}
                            </Box>
                        ) : null}
                    </Box>
                    <Box sx={styles.contentBottom}>
                        {renderButton()}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const styles = {
    centeredView: {
        display: "flex",
        zIndex: 1,
        flex: 1,
        flexDirection: "column",
        width: "100%",
        //height: "100vh",
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        overflowX: "hidden",
        overflowY: "auto",
        position: "relative",
        //padding: "24px 24px 24px",
        //boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.35)",
        /* scrollbarWidth: "none",
        "::-webkit-scrollbar": {
                        display: "none",
        }, */
        /* backdropFilter: "blur( 20px )",
        "-webkit-backdrop-filter": "blur( 10px )", */
        //borderRadius: "20px",
    },
    modalView: {
        width: '100%',
    },
    container: {
        ...columnBetweenStyles,
        flex: 1,
        width: '100%',
    },
    content: {
        flex: 1,
        position: 'relative',
        ...columnCenterStyles,
        width: '100%',
    },
    textContainer: { ...columnStartStyles, flex: 1.5, width: '100%', },
    title: {
        position: 'relative',
        fontSize: normalize(36),
        fontFamily: Eczar.regular,
        marginTop: '30px',
    },
    text: {
        fontSize: normalize(16),
        fontFamily: Gilroy,
        color: Colors.WHITE,
    },

    buttonText: {
        marginLeft: '10px',
    },
    contentBottom: {
        ...columnEndStyles,
        paddingTop: '60px',
        paddingBottom: '37px',
        width: '100%',
    },
    button: {
        width: '53%',
        height: '60px',
        borderRadius: '100px',
        ...rowCenterStyles,
    },
    resultNumberView: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        ...columnCenterStyles,
    },
    resultNumberText: {
        fontSize: 48,
        fontFamily: Eczar.semiBold,
    },
};

export default DicePopup;
