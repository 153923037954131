/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    ButtonBase,
    Input,
    InputAdornment,
    Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { strings } from "../../../services/translation";
import { styles } from "../style";
import { Colors } from "../../../style";
import Separator from "../../Separator";
import { normalizeSize } from "../../../utils/fontSizer";
import { columnCenterStyles } from "../../../style/flex";

import { ReactComponent as IconCompanions } from "../../../assets/icons/icon_companion.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/icon_next_standard.svg";
import { useState } from "react";
import { isNotEmptyString } from "../../../utils/StringHelper";

const AddFriendModal = ({ addFriend }: { addFriend: any }) => {
    const [error, setError] = useState("");
    const handleError = (error: string | any) => {
        if (error === "error-no-add-yourself") {
            setError(strings.errors.cantAddYourself);
            return strings.errors.cantAddYourself;
        } else if (error.endsWith("is a required field")) {
            return strings.codeRequired;
        } else if (error.includes("must be")) {
            return strings.code6Characters;
        } else {
            setError(error);
        }
    };

    const validationSchema = yup.object().shape({
        playerCode: yup.string().max(6).min(5).required(),
    });

    const submitFriend = async (playerCode: string) => {
        const res = await addFriend(playerCode);
        if (res && res.error) {
            handleError(res.error);
        }
    };

    return (
        <Formik
            initialValues={{
                playerCode: "",
            }}
            onSubmit={(values) => {
                submitFriend(values.playerCode);
            }}
            validationSchema={validationSchema}>
            {({
                values,
                handleChange,
                errors,
                setFieldTouched,
                touched,
                isValid,
                handleSubmit,
            }) => (
                <Box sx={{ ...columnCenterStyles }}>
                    <Box sx={styles.modalView}>
                        <Box sx={styles.modalContent}>
                            <Box sx={styles.modalCodeIconBackground}>
                                <IconCompanions
                                    width={64}
                                    height={64}
                                    fill={Colors.WHITE}
                                />
                            </Box>
                            <Typography
                                component={"span"}
                                sx={styles.modalText}>
                                {strings.friends.addFriendText}
                                <Typography
                                    component={"span"}
                                    sx={[
                                        styles.modalText,
                                        {
                                            color: Colors.vertALEXTESGRANDSMORTS,
                                        },
                                    ]}>
                                    {strings.friends.playerCode}
                                </Typography>
                            </Typography>
                            <Separator
                                style={{
                                    marginTop: normalizeSize(24),
                                    marginBottom: normalizeSize(16),
                                }}
                            />
                            <Box sx={{ width: "70%" }}>
                                <Input
                                    value={values.playerCode}
                                    onChange={handleChange("playerCode")}
                                    onBlur={() => setFieldTouched("playerCode")}
                                    onKeyUp={(event: any) =>
                                        event.key && event.key === "Enter"
                                            ? handleSubmit()
                                            : null
                                    }
                                    sx={[
                                        styles.myInputView,
                                        {
                                            color: Colors.vertALEXTESGRANDSMORTS,
                                            "::placeholder": {
                                                color: Colors.vertALEXTESGRANDSMORTS,
                                            },
                                            "::-ms-input-placeholder": {
                                                color: Colors.vertALEXTESGRANDSMORTS,
                                            },
                                            "::-webkit-input-placeholder": {
                                                color: Colors.vertALEXTESGRANDSMORTS,
                                            },
                                            "::before": {
                                                borderBottom: `2px solid ${Colors.vertALEXTESGRANDSMORTS}`,
                                            },
                                            "::after": {
                                                borderBottom: `2px solid ${Colors.vertALEXTESGRANDSMORTS}`,
                                            },
                                        },
                                    ]}
                                    placeholder={"AAAAAA"}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Typography sx={styles.whiteText}>
                                                #
                                            </Typography>
                                        </InputAdornment>
                                    }
                                />
                                {touched.playerCode && errors.playerCode && (
                                    <Typography sx={styles.inputError}>
                                        {handleError(errors.playerCode)}
                                    </Typography>
                                )}
                                {isNotEmptyString(error) && (
                                    <Typography sx={styles.inputError}>
                                        {error}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <ButtonBase
                        onClick={() => handleSubmit()}
                        sx={styles.nextButton}>
                        <NextIcon
                            width={45}
                            height={45}
                            fill={Colors.PRIMARY}
                        />
                    </ButtonBase>
                </Box>
            )}
        </Formik>
    );
};

export default AddFriendModal;
