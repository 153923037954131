import { Box, Typography } from "@mui/material";
import { strings } from "../../../services/translation";
import { Colors } from "../../../style";
import { isNotEmptyArray } from "../../../utils/ArrayHelper";
import { normalizeSize } from "../../../utils/fontSizer";
import Separator from "../../Separator";
import { FTUEItem } from "../FTUEItem";
import { styles } from "../style";
import { ReactComponent as IconSocial } from "../../../assets/icons/heart_filled.svg";
import { newsIconAnimated } from "../../../constants/newsIconAnimated";
//import { items } from "./dataSocial";

export const FTUEModalSocial = () => {
    const items = [
        {
            type: "image",
            text: strings.ftue.social.publicLobby,
            image: require("../../../assets/images/FTUE_ONLINE.png"),
        },
        {
            type: "image",
            text: strings.ftue.social.friends,
            image: require("../../../assets/images/FTUE_FRIENDS.png"),
        },
        {
            type: "image",
            text: strings.ftue.social.ladder,
            image: require("../../../assets/images/FTUE_LADDER.png"),
        },
        {
            type: "anim",
            text: strings.ftue.social.news,
            anim: newsIconAnimated,
        },
    ];

    const renderItems = () => {
        if (isNotEmptyArray(items)) {
            return items.map((item: any, index: number) => (
                <FTUEItem item={item} key={index} />
            ));
        }
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <IconSocial width={30} height={30} fill={Colors.PACK} />
                <Typography sx={[styles.title, { color: Colors.PACK }]}>
                    {strings.ftue.social.title}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box sx={styles.introContainer}>
                <img
                    src={require("../../../assets/images/icon_peintre.png")}
                    alt="painter"
                    width={normalizeSize(70)}
                    height={normalizeSize(70)}
                />
                <Typography sx={styles.introText}>
                    {strings.ftue.social.intro}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box style={styles.contentContainer}>{renderItems()}</Box>
        </Box>
    );
};
