import { Box, Typography } from "@mui/material";
import { styles } from "../style";
import { Colors } from "../../../style";
import { strings } from "../../../services/translation";
import { rewardStoryJson } from "../../../constants/rewardStory";
import { Player } from "@lottiefiles/react-lottie-player";
import { normalizeSize } from "../../../utils/fontSizer";
//ICONS
import { ReactComponent as IconStories } from "../../../assets/icons/icon_menu_story.svg";
import { isNotEmptyArray } from "../../../utils/ArrayHelper";
//import { items } from "./dataLibrary";
import { FTUEItem } from "../FTUEItem";
import Separator from "../../Separator";

export const FTUEModalLibrary = () => {
    const items = [
        {
            type: "anim",
            text: strings.ftue.library.stories,
            anim: rewardStoryJson,
        },
        {
            type: "image",
            text: strings.ftue.library.avatar,
            image: require("../../../assets/images/FTUE_LIBRARY_AVATAR.png"),
        },
        {
            type: "image",
            text: strings.ftue.library.companions,
            image: require("../../../assets/images/FTUE_LIBRARY_COMPANION.png"),
        },
    ];

    const renderItems = () => {
        if (isNotEmptyArray(items)) {
            return items.map((item: any, index: number) => (
                <FTUEItem item={item} key={index} />
            ));
        }
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <IconStories
                    width={30}
                    height={30}
                    fill={Colors.vertALEXTESGRANDSMORTS}
                />
                <Typography
                    sx={[
                        styles.title,
                        { color: Colors.vertALEXTESGRANDSMORTS },
                    ]}>
                    {strings.library}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box sx={styles.introContainer}>
                <img
                    src={require("../../../assets/images/icon_peintre.png")}
                    alt="painter"
                    width={normalizeSize(70)}
                    height={normalizeSize(70)}
                />
                <Typography sx={styles.introText}>
                    {strings.ftue.library.intro}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box style={styles.contentContainer}>
                {renderItems()}
                <Typography
                    sx={[
                        styles.footerText,
                        { color: Colors.vertALEXTESGRANDSMORTS },
                    ]}>
                    {strings.ftue.library.footer}
                </Typography>
            </Box>
        </Box>
    );
};
