/* eslint-disable @typescript-eslint/no-explicit-any */
import { isString } from './TypeOfHelper';

// =======================================================

export function isNotEmptyString(str: any) {
  return isString(str) && str !== '';
}

// =======================================================
