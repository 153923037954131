/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Box, ButtonBase, Typography } from "@mui/material";
import GradientBtn from "../../../components/GradientBtn";
import Separator from "../../../components/Separator";
import { getRouteManager } from "../../../services/routeManager";
import { strings } from "../../../services/translation";
import { SUPPORT_MAIL } from "../../../utils/constants";
import { styles } from "../styles";
import useAuth from "../../../services/Auth/useAuth";
import { ReactComponent as CloseIcon } from "../../../assets/icons/icon_removeplayer.svg";

const HelpModal = ({
  isOpen,
  callback,
}: {
  isOpen: boolean;
  callback: any;
}) => {
  const auth = useAuth();

  const contact = async () => {
    try {
      if (auth && auth.user) {
        const value = auth.user.login_token;
        const { email, player_code, pseudo } = auth.user;
        if (player_code && email && pseudo) {
          const issueTitle = player_code + strings.contact.issue;
          const body =
            strings.contact.infos +
            pseudo +
            "%0Aemail : " +
            email +
            "%0Apseudo : " +
            pseudo +
            strings.contact.problem;
          window.open(
            `mailto:${SUPPORT_MAIL}?subject=${issueTitle}&body=${body}`
          );
        } else if (value) {
          const url = getRouteManager().getUserInfos();
          const getUser = await getRouteManager().fetchData(url, {
            login_token: value,
          });
          if (getUser) {
            const issueTitle = getUser.player_code + strings.contact.issue;
            const body =
              strings.contact.infos + getUser.player_code
                ? getUser.player_code
                : "" + "%0Aemail : " + getUser.email
                ? getUser.email
                : "" + "%0Apseudo : " + getUser.pseudo
                ? getUser.pseudo
                : "" + strings.contact.problem;
            window.open(
              `mailto:${SUPPORT_MAIL}?subject=${issueTitle}&body=${body}`
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal open={isOpen} onClose={callback}>
      <Box sx={styles.modalContainer}>
        <ButtonBase
          onClick={() => {
            callback();
          }}
          sx={styles.closeBtn}
        >
          <CloseIcon width={60} height={60} />
        </ButtonBase>
        <Box sx={styles.helpContainer}>
          <Typography sx={styles.helpTitle}>
            {strings.labels.needHelp}
          </Typography>
          <Separator style={{ margin: "5px 0px 10px" }} />
          <Typography sx={styles.helpSubtitle}>
            {strings.purchaseHelp.notReceived}
          </Typography>
          <Typography sx={styles.helpText}>
            {strings.purchaseHelp.notReceivedSub}
          </Typography>
          <Typography sx={styles.helpSubtitle}>
            {strings.purchaseHelp.validation}
          </Typography>
          <Typography sx={styles.helpText}>
            {strings.purchaseHelp.validationSub}
          </Typography>
          <Typography sx={styles.helpSubtitle}>
            {strings.purchaseHelp.payment}
          </Typography>
          <Typography sx={styles.helpText}>
            {strings.purchaseHelp.paymentSub}
          </Typography>
        </Box>
        <Box sx={styles.gradientBox}>
          <Box sx={{ width: "100%", maxWidth: "300px" }}>
            <GradientBtn onPress={contact} width={"100%"}>
              {strings.contactUs}
            </GradientBtn>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default HelpModal;
