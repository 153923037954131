export const successAnim = {
  nm: "Comp 1",
  ddd: 0,
  h: 200,
  w: 200,
  meta: { g: "@lottiefiles/toolkit-js 0.26.1" },
  layers: [
    {
      ty: 4,
      nm: "check",
      sr: 1,
      st: 0,
      op: 1800,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [16.666666666666657, 16.666666666666657, 100],
              t: 114,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [17.49999999999999, 17.49999999999999, 100],
              t: 130,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [15.833333333333325, 15.833333333333325, 100],
              t: 136,
            },
            { s: [16.666666666666657, 16.666666666666657, 100], t: 142 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [95.87499999999996, 100.57749999999994, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [
        {
          ty: 0,
          mn: "Pseudo/BNCA2506f0b33",
          nm: "Excite - Skew - Transform",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              mn: "Pseudo/BNCA2506f0b33-0001",
              nm: "Enable",
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 6,
              mn: "Pseudo/BNCA2506f0b33-0002",
              nm: "Properties",
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0003",
              nm: "Overshoot",
              ix: 3,
              v: { a: 0, k: 10, ix: 3 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0004",
              nm: "Bounce",
              ix: 4,
              v: { a: 0, k: 15, ix: 4 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0005",
              nm: "Friction",
              ix: 5,
              v: { a: 0, k: 40, ix: 5 },
            },
            { ty: 6, mn: "Pseudo/BNCA2506f0b33-0006", nm: "", ix: 6, v: 0 },
          ],
        },
        {
          ty: 0,
          mn: "Pseudo/BNCA2506f0b33",
          nm: "Excite - Scale - Transform",
          ix: 2,
          en: 1,
          ef: [
            {
              ty: 7,
              mn: "Pseudo/BNCA2506f0b33-0001",
              nm: "Enable",
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 6,
              mn: "Pseudo/BNCA2506f0b33-0002",
              nm: "Properties",
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0003",
              nm: "Overshoot",
              ix: 3,
              v: { a: 0, k: 10, ix: 3 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0004",
              nm: "Bounce",
              ix: 4,
              v: { a: 0, k: 15, ix: 4 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0005",
              nm: "Friction",
              ix: 5,
              v: { a: 0, k: 40, ix: 5 },
            },
            { ty: 6, mn: "Pseudo/BNCA2506f0b33-0006", nm: "", ix: 6, v: 0 },
          ],
        },
        {
          ty: 0,
          mn: "Pseudo/BNCA2506f0b33",
          nm: "Excite - Position - Transform",
          ix: 3,
          en: 1,
          ef: [
            {
              ty: 7,
              mn: "Pseudo/BNCA2506f0b33-0001",
              nm: "Enable",
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 6,
              mn: "Pseudo/BNCA2506f0b33-0002",
              nm: "Properties",
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0003",
              nm: "Overshoot",
              ix: 3,
              v: { a: 0, k: 10, ix: 3 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0004",
              nm: "Bounce",
              ix: 4,
              v: { a: 0, k: 15, ix: 4 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0005",
              nm: "Friction",
              ix: 5,
              v: { a: 0, k: 40, ix: 5 },
            },
            { ty: 6, mn: "Pseudo/BNCA2506f0b33-0006", nm: "", ix: 6, v: 0 },
          ],
        },
        {
          ty: 0,
          mn: "Pseudo/BNCA2506f0b33",
          nm: "Excite - Anchor Point - Transform",
          ix: 4,
          en: 1,
          ef: [
            {
              ty: 7,
              mn: "Pseudo/BNCA2506f0b33-0001",
              nm: "Enable",
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 6,
              mn: "Pseudo/BNCA2506f0b33-0002",
              nm: "Properties",
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0003",
              nm: "Overshoot",
              ix: 3,
              v: { a: 0, k: 10, ix: 3 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0004",
              nm: "Bounce",
              ix: 4,
              v: { a: 0, k: 15, ix: 4 },
            },
            {
              ty: 0,
              mn: "Pseudo/BNCA2506f0b33-0005",
              nm: "Friction",
              ix: 5,
              v: { a: 0, k: 40, ix: 5 },
            },
            { ty: 6, mn: "Pseudo/BNCA2506f0b33-0006", nm: "", ix: 6, v: 0 },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [214, -137],
                    [-68, 130],
                    [-164, 34],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 56, ix: 5 },
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [99, 99], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "tm",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Filter - Trim",
          nm: "Trim Paths 1",
          ix: 2,
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.5, y: 0 }, i: { x: 0.5, y: 1 }, s: [100], t: 42 },
              { s: [0], t: 58 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "circle - stroke",
      sr: 1,
      st: 0,
      op: 1800,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [16.666666666666657, 16.666666666666657, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [102.95349999999995, 102.42999999999995, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 35,
            },
            { s: [0], t: 36 },
          ],
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Ellipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [172.277, 0],
                    [0, -172.277],
                    [-172.277, 0],
                    [0, 172.277],
                  ],
                  o: [
                    [-172.277, 0],
                    [0, 172.277],
                    [172.277, 0],
                    [0, -172.277],
                  ],
                  v: [
                    [0, -311.936],
                    [-311.936, 0],
                    [0, 311.936],
                    [311.936, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 40, ix: 5 },
              c: { a: 0, k: [0.2706, 0.8588, 0.5059], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [130.536, 130.536], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-17.721, -14.58], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "tm",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Filter - Trim",
          nm: "Trim Paths 1",
          ix: 2,
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.5, y: 0 }, i: { x: 0.5, y: 1 }, s: [100], t: 0 },
              { s: [0], t: 23 },
            ],
            ix: 1,
          },
          m: 2,
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: "circle - bg",
      sr: 1,
      st: 0,
      op: 1800,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.5, y: 0 }, i: { x: 0.5, y: 1 }, s: [0, 0, 100], t: 19 },
            {
              o: { x: 0.5, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [19.166666666666654, 19.166666666666654, 100],
              t: 33,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [15.833333333333325, 15.833333333333325, 100],
              t: 40,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [16.666666666666657, 16.666666666666657, 100],
              t: 45,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [16.666666666666657, 16.666666666666657, 100],
              t: 114,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [18.333333333333325, 18.333333333333325, 100],
              t: 130,
            },
            {
              o: { x: 0.167, y: 0 },
              i: { x: 0.833, y: 1 },
              s: [15.833333333333325, 15.833333333333325, 100],
              t: 136,
            },
            { s: [17.49999999999999, 17.49999999999999, 100], t: 142 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [102.93083333333328, 102.41149999999995, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Ellipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [172.277, 0],
                    [0, -172.277],
                    [-172.277, 0],
                    [0, 172.277],
                  ],
                  o: [
                    [-172.277, 0],
                    [0, 172.277],
                    [172.277, 0],
                    [0, -172.277],
                  ],
                  v: [
                    [0, -311.936],
                    [-311.936, 0],
                    [0, 311.936],
                    [311.936, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2706, 0.8588, 0.5059], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [130.536, 130.536], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-17.721, -14.58], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: "burst",
      sr: 1,
      st: 0,
      op: 1800,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [11.999999999999993, 11.999999999999993, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [102.12649999999994, 101.7496666666666, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Ellipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [172.277, 0],
                    [0, -172.277],
                    [-172.277, 0],
                    [0, 172.277],
                  ],
                  o: [
                    [-172.277, 0],
                    [0, 172.277],
                    [172.277, 0],
                    [0, -172.277],
                  ],
                  v: [
                    [0, -311.936],
                    [-311.936, 0],
                    [0, 311.936],
                    [311.936, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  { o: { x: 0.5, y: 0 }, i: { x: 0.5, y: 1 }, s: [40], t: 30 },
                  { s: [0], t: 68 },
                ],
                ix: 5,
              },
              d: [
                { nm: "dash", n: "d", v: { a: 0, k: 0, ix: 1 } },
                { nm: "gap", n: "g", v: { a: 0, k: 0, ix: 2 } },
                { nm: "offset", n: "o", v: { a: 0, k: 0, ix: 7 } },
              ],
              c: { a: 0, k: [0.2706, 0.8588, 0.5059], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.5, y: 0 },
                    i: { x: 0.5, y: 1 },
                    s: [167.536, 167.536],
                    t: 26,
                  },
                  { s: [251.536, 251.536], t: 68 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-17.721, -14.58], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 26,
                  },
                  { s: [100], t: 34 },
                ],
                ix: 7,
              },
            },
          ],
        },
      ],
      ind: 4,
    },
    {
      ty: 4,
      nm: "burst 2",
      sr: 1,
      st: 0,
      op: 1800,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [11.999999999999993, 11.999999999999993, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [102.12649999999994, 101.7496666666666, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Ellipse 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [172.277, 0],
                    [0, -172.277],
                    [-172.277, 0],
                    [0, 172.277],
                  ],
                  o: [
                    [-172.277, 0],
                    [0, 172.277],
                    [172.277, 0],
                    [0, -172.277],
                  ],
                  v: [
                    [0, -311.936],
                    [-311.936, 0],
                    [0, 311.936],
                    [311.936, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  { o: { x: 0.5, y: 0 }, i: { x: 0.5, y: 1 }, s: [40], t: 122 },
                  { s: [0], t: 170 },
                ],
                ix: 5,
              },
              d: [
                { nm: "dash", n: "d", v: { a: 0, k: 0, ix: 1 } },
                { nm: "gap", n: "g", v: { a: 0, k: 0, ix: 2 } },
                { nm: "offset", n: "o", v: { a: 0, k: 0, ix: 7 } },
              ],
              c: { a: 0, k: [0.2706, 0.8588, 0.5059], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.5, y: 0 },
                    i: { x: 0.5, y: 1 },
                    s: [167.536, 167.536],
                    t: 118,
                  },
                  { s: [251.536, 251.536], t: 170 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-17.721, -14.58], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 124,
                  },
                  { s: [100], t: 132 },
                ],
                ix: 7,
              },
            },
          ],
        },
      ],
      ind: 5,
    },
  ],
  v: "5.6.7",
  fr: 50,
  op: 120,
  ip: 0,
  assets: [],
};
