/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ColorManager from "../../../../services/colorManager";
import normalize from "../../../../utils/fontSizer";
import { Colors } from "../../../../style";
import { getRouteManager } from "../../../../services/routeManager";
import {
  Box,
  Fade,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { columnCenterStyles, columnStartStyles } from "../../../../style/flex";
import AvatarContainer from "../../../../components/AvatarContainer";
import IconTint from "react-icon-tint";

// =======================================================

interface Props {
  player: any;
  color: any;
  storyId: any;
  iconColor: any;
  pointColor: any;
  setShowName: any;
  showName: any;
  idx: any;
  storyData: any;
}

// =======================================================

const iconSize = 40;

const styles = {
  playerName: {
    color: Colors.ACCENT_BRAQUAGE,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(16),
    marginTop: "5px",
    marginBottom: "10px",
  },
  letter: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    borderWidth: "2px",
    borderStyle: "solid",
    ...columnCenterStyles,
  },
  playerLetter: {
    fontWeight: "bold",
    fontFamily: "Gilroy-Bold",
    fontSize: normalize(20),
    lineHeight: normalize(20),
    color: Colors.WHITE,
  },
};

// =======================================================

const PlayerStates = (props: Props) => {
  /**
   *
   */
  const {
    player,
    color,
    iconColor,
    pointColor,
    storyData,
  } = props;

  const { iconStates } = storyData;

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: ColorManager.getInstance().getColor("tertiary"),
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: ColorManager.getInstance().getColor("tertiary"),
      borderRadius: "8px",
      padding: "8px 12px",
    },
  }));

  /**
    * Utilise useMemo pour mémoriser le tableau d'images
    */
  const imgs = useMemo(() => {
    const { globalVars } = storyData;
    const result = [];

    for (let i = 0; i < iconStates.length; i += 1) {
      const n = globalVars.find((entry: any) => entry.name === iconStates[i].name).defaultValue;
      result.push({
        stateName: iconStates[i].name,
        component: (
          <Box
            style={{ alignItems: "center" }}
            key={"icon" + i + "player-" + player.name}
          >
            <CustomTooltip
              title={
                <Typography
                  style={{
                    color: ColorManager.getInstance().getColor("secondary"),
                    fontFamily: "Gilroy-Bold",
                  }}
                >
                  {n ? n : ""}
                </Typography>
              }
              arrow
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 400 }}
            >
              <Box maxWidth={iconSize} maxHeight={iconSize}>
                <IconTint
                  key={i}
                  color={iconColor}
                  src={getRouteManager().icon(iconStates[i].iconId)}
                  maxWidth={iconSize}
                  maxHeight={iconSize}
                //style={{ width: iconSize, height: iconSize }}
                />
                {/*  <img
                  key={i}
                  src={getRouteManager().icon(iconStates[i].iconId)}
                  style={{ width: iconSize, height: iconSize }}
                /> */}
              </Box>
            </CustomTooltip>
          </Box>
        ),
      });
    }

    return result;
  }, [iconStates, storyData, player.states]);

  const nbStatesImages = () => {
    let cpt = 0;

    player.states.map((el: any) => {
      if (imgs.find((img) => img.stateName === el.name && el.value > 0)) {
        cpt++;
      }
    });

    return cpt;
  }

  /**
   * Utilise useMemo pour mémoriser le tableau d'images vides
   */
  /* const imgsEmpty = useMemo(() => {
    let cpt = 0;

    player.states.map((el: any) => {
      if (imgs.find((img) => img.stateName === el.name && el.value > 0)) {
        cpt++;
      }
    });
    console.log(cpt)

    const result = [];
    for (let i = 0; i < 9 - cpt; i++) {
      result.push({
        stateName: "",
        component: (
          <Box
            key={"icon-state" + i + "player-" + player.name}
            style={{
              ...columnCenterStyles,
              width: "40px",
              height: "40px",
            }}
          >
            <Box>
              <Box
                style={{
                  width: "7px",
                  height: "7px",
                  borderRadius: "7px",
                  backgroundColor: pointColor,
                }}
              />
            </Box>
          </Box>
        ),
      });
    }

    return result;
  }, [iconStates, storyData, player, player.states, imgs, imgs.length]);
 */

  const renderDot = (i: number) => (
    <Box
      key={"icon-state" + i + "player-" + player.name}
      style={{
        ...columnCenterStyles,
        width: "40px",
        height: "40px",
      }}
    >
      <Box>
        <Box
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "7px",
            backgroundColor: pointColor,
          }}
        />
      </Box>
    </Box>
  )


  // --
  return (
    <Box
      style={{
        ...columnStartStyles,
        paddingTop: "10px",
      }}
    >
      {player.avatar ? (
        <AvatarContainer
          user={player}
          persoWidth={50}
          borderWidth={2}
          borderColor={ColorManager.getInstance().getColor("secondary")}
        />
      ) : (
        <Box
          sx={[
            styles.letter,
            {
              backgroundColor: player.letterColor,
              borderColor: ColorManager.getInstance().getColor("secondary"),
            },
          ]}
        >
          <Typography style={styles.playerLetter}>{player.letter}</Typography>
        </Box>
      )}

      <Typography sx={[styles.playerName, { color }]}>{player.name}</Typography>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "7px",
        }}
      >
        {player.states.map((el: any) => {
          const imgF = imgs.find(
            (img) => img.stateName === el.name && el.value > 0
          );
          if (imgF) {
            if (el.value > 1) {
              return (
                <Box
                  key={"player-state-" + el.name}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  {imgF.component}
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      alignSelf: "flex-end",
                      backgroundColor:
                        ColorManager.getInstance().getColor("quinary"),
                      borderRadius: "100px",
                      padding: "0px 3px",
                      zIndex: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: normalize(14),
                        color: ColorManager.getInstance().getColor("tertiary"),
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      {el.value}
                    </Typography>
                  </Box>
                </Box>
              );
            } else if (el.value === 1) {
              return imgF.component;
            } else {
              return null;
            }
          }
        })}

        {/* {imgsEmpty.map((im: any) => im.component)} */}

        {Array.from({ length: 9 - nbStatesImages() }).map((_, index) => renderDot(index))}

      </Box>
    </Box>
  );
};

// =======================================================

export default PlayerStates;
