/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Colors } from "../../style";
import ColorManager from "../../services/colorManager";
import StoryReader from "../../services/StoryReader";
import { Box, Fade, Typography } from "@mui/material";

interface Props {
  children: any;
  players: any;
  color: any;
  hlText: string;
  textColor: any;
  shouldResize: boolean;
  marginTop: boolean;
  overlay: boolean;
  storyReader: StoryReader;
  style?: any;
}

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    overflow: "auto",
    //overflowY: 'scroll',
  },
  text: {
    color: Colors.WHITE,
    fontSize: "calc(12px + 0.75vh)",
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    padding: "0px 10px",
  },
  hl: {
    fontFamily: "Gilroy-Bold",
  },
};

const TextScene = (props: Props) => {
  const {
    children,
    players,
    color,
    hlText,
    textColor,
    shouldResize,
    marginTop,
    overlay,
    storyReader,
    style,
  } = props;

  const type = storyReader.getType();

  const playersNames = storyReader.getCurrentNamesToHighlight();

  const newArr = playersNames.concat(hlText);
  const bgColor = ColorManager.getInstance().getColor("primary");

  const [colorFade, setColorFade] = useState(
    ColorManager.getInstance().hexToRgba(bgColor, 0.2)
  );

  const [cFade, setCFade] = useState(
    ColorManager.getInstance().hexToRgba(bgColor, 1)
  );

  const [shouldFade, setShouldFade] = useState(false);

  useEffect(() => {
    if (children) {
      //setShouldFade(false);
      setTimeout(() => {
        setShouldFade(true);
      }, 100)
    }
  }, [children]);

  return (
    <Box
      sx={[
        styles.container,
        //marginTop ? { marginTop: "30px" } : {},
        /* {
          padding: `${children.length > 500 ? "50px" : "25px"} ${
            shouldResize ? "35px" : "0px"
          } 25px ${shouldResize ? "35px" : "0px"}`,
        }, */
        style ? style : {},
        {
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: ColorManager.getInstance().getColor("quinary"),
            borderRadius: "5px",
          },

          "::-webkit-scrollbar-thumb": {
            background: ColorManager.getInstance().getColor("quaternary"),
            borderRadius: "5px",
          },

          "::-webkit-scrollbar-thumb:hover": {
            background: ColorManager.getInstance().getColor("secondary"),
          },
        },
      ]}
    >
      <Fade timeout={600} appear in={shouldFade}>
        <Typography sx={[styles.text, { color: textColor }]}>
          <Highlighter
            highlightStyle={{
              fontFamily: "Gilroy-Bold",
              color: color,
              backgroundColor: "transparent",
            }}
            searchWords={newArr}
            textToHighlight={children}
            autoEscape
          />
        </Typography>
      </Fade>
    </Box>
  );
};

export default React.memo(TextScene);
