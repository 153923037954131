/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import { isFunction } from "../../utils/TypeOfHelper";
import { Players } from "./players";
import { Variables } from "./variables";

export default function handleVariables(
  storyData: any,
  currSceneVars: Array<any>,
  variables: Variables,
  players: Players,
  storyId: number,
  activePlayerId: number,
  newStateCallback: any,
  newSuccessCallback: any,
  newShowGroupCallback: any,
  newShowSecretCallback: any,
  newGameOverCallback: any,
  newVarCallback: any
): void {
  // Not var to check, return.
  if (_.isEmpty(currSceneVars)) {
    return;
  }
  const { globalVars } = storyData;

  for (let i = 0; i < currSceneVars.length; i += 1) {
    const currVar = currSceneVars[i];
    const varName: string = currVar.firstOperand;
    const varValue: string = currVar.thirdOperand;

    // If it a normal var, handle it.
    if (varName.startsWith("var_") && !currVar.tag) {
      variables.handleVariable(currVar);
      if (
        varName.startsWith("var_Success") &&
        varValue === "1" &&
        isFunction(newSuccessCallback)
      ) {
        newSuccessCallback(varName);
      }
      if (
        varName.startsWith("var_Group") &&
        varValue === "1" &&
        isFunction(newShowGroupCallback)
      ) {
        //Si jamais ça marche pas, attention, pas de callback passage 1
        newShowGroupCallback(true);
      }
      if (
        varName.startsWith("var_Group") &&
        varValue === "0" &&
        isFunction(newShowGroupCallback)
      ) {
        //Si jamais ça marche pas, attention, pas de callback passage 1
        newShowGroupCallback(false);
      }
      if (
        varName.startsWith("var_SequenceSecrete") &&
        varValue === "0" &&
        isFunction(newShowSecretCallback)
      ) {
        //Si jamais ça marche pas, attention, pas de callback passage 1
        newShowSecretCallback(false);
      }
      if (
        varName.startsWith("var_SequenceSecrete") &&
        varValue === "1" &&
        isFunction(newShowSecretCallback)
      ) {
        //Si jamais ça marche pas, attention, pas de callback passage 1
        newShowSecretCallback(true);
      }
      if (
        varName.startsWith("var_GameOver") &&
        varValue === "1" &&
        isFunction(newGameOverCallback)
      ) {
        //Si jamais ça marche pas, attention, pas de callback passage 1
        newGameOverCallback(true);
      }
      if (
        varValue != "0" &&
        !varName.startsWith("var_Onglet") &&
        !varName.startsWith("var_Success") &&
        (currVar.secondOperand === "+" || currVar.secondOperand === "=")
      ) {
        if (globalVars) {
          const varToSearch = globalVars.find(
            (entry: any) => entry.name === varName
          );
          if (varToSearch && isFunction(newVarCallback)) {
            newVarCallback(currVar);
          }
        }
      }

      // If it's a player that is not indispo anymore, handle it.
    } else if (currVar.tag && currVar.tag === "all") {
      const tmpPlayers = players.players.filter((p) => p.isAvailable());
      for (let k = 0; k < tmpPlayers.length; k++) {
        tmpPlayers[k].handleState(currVar);
      }
    } else if (currVar.tag && currVar.tag === "forState" && currVar.forState) {
      const forState = currVar.forState;
      const tmpPlayers2 = players.players.filter((p) =>
        p.checkHasStateWithValueAndOperand(
          forState.stateName,
          parseInt(forState.value, 10),
          forState.operand ? forState.operand : false,
        )
      );
      for (let l = 0; l < tmpPlayers2.length; l++) {
        tmpPlayers2[l].handleState(currVar);
      }
    } else if (
      currVar.tag &&
      currVar.tag === "forStateVar" &&
      currVar.forState
    ) {
      const forState = currVar.forState;
      const active = players.getPlayerById(activePlayerId);
      if (
        active.checkHasStateWithValueAndOperand(
          forState.stateName,
          parseInt(forState.value, 10),
          forState.operand ? forState.operand : false,
        )
      ) {
        variables.handleVariable(currVar);
      }
    } else if (varName.startsWith("state_Indispo") && varValue === "0") {
      // Find the players that are indispo...
      const indispo = players.players.filter((player) =>
        player.hasState(varName)
      );
      // ...and remove their indispo
      for (let j = 0; j < indispo.length; j += 1) {
        indispo[j].handleState(currVar);
      }
      // if it is a state (which is not indispo = 0)
    } else {
      // Call callback for pop up state if a state is enabled.
      if (
        varValue != "0" &&
        (currVar.secondOperand === "+" || currVar.secondOperand === "=")
      ) {
        if (globalVars) {
          const varToSearch = globalVars.find(
            (entry: any) => entry.name === varName
          );
          if (varToSearch !== undefined && isFunction(newStateCallback)) {
            newStateCallback(currVar);
          }
        }
      }
      // Update player state as it should.
      players.getPlayerById(activePlayerId).handleState(currVar);
    }
  }
}
