/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";

export function isDateOlderThan(date: any, valueDiff?: number): boolean {
  const createdDate = new Date(date);
  const currentDate = new Date();

  const diff: number = currentDate.getTime() - createdDate.getTime();
  const twentyFourHoursInMilliseconds: number = valueDiff
    ? valueDiff
    : 24 * 60 * 60 * 1000;

  if (diff < twentyFourHoursInMilliseconds) {
    return false;
  } else {
    return true;
  }
}

export const getRemainingTime = (toDate: any) => {
  const date = moment.utc(toDate);
  /* .set("hour", 23)
    .set("minute", 59)
    .set("seconds", 59); */
  const dur = moment.duration(moment.utc(date).diff(moment()));
  const daysRemain = dur.days() + dur.months() * 30 + dur.years() * 365;
  const hoursRemain =
    daysRemain < 1 ? dur.hours() + daysRemain * 24 : dur.hours();
  const minutesRemain = dur.minutes();
  const secondsRemain = dur.seconds();
  return {
    days: daysRemain < 1 ? 0 : daysRemain,
    hours: hoursRemain,
    minutes: minutesRemain,
    seconds: secondsRemain,
  };
};
