import { Box, Typography } from "@mui/material";
import { strings } from "../../../services/translation";
import TicketAnimated from "../../TicketAnimated";
import { Colors } from "../../../style";
import Separator from "../../Separator";
import { styles } from "../style";
import GradientText from "../../GradientText";

export const FTUEModalShop = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <TicketAnimated style={{ width: 30, height: 30 }} />
                <Typography sx={[styles.title, { color: Colors.TICKET }]}>
                    {strings.ftue.shop.title}
                </Typography>
            </Box>
            <Separator style={styles.separator} />
            <Box style={styles.contentContainer}>
                <Typography
                    component={"span"}
                    sx={[styles.itemText, { textAlign: "center" }]}>
                    {strings.ftue.shop.intro1}
                    <Typography
                        component={"span"}
                        sx={[styles.itemText, { color: Colors.TICKET }]}>
                        Tickets
                    </Typography>
                    {strings.ftue.shop.intro2}
                    <GradientText
                        color1={Colors.PASS1}
                        color2={Colors.PASS2}
                        isSpan
                        style={styles.itemText}>
                        {strings.pass.adventurerPass}
                    </GradientText>
                    {strings.ftue.shop.intro3}
                </Typography>
                <Typography sx={[styles.itemText, { textAlign: "center" }]}>
                    {strings.ftue.shop.intro4}
                </Typography>
                <Typography sx={styles.footerText}>
                    {strings.ftue.shop.footer}
                </Typography>
            </Box>
        </Box>
    );
};
