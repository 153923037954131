import { Colors } from "../../style";
import { columnStyles, rowCenterStyles, rowStartStyles, columnCenterStyles, rowWrapStyles, rowStyles } from "../../style/flex";
import { Gilroy } from "../../style/fonts";
import normalize from "../../utils/fontSizer";

export const styles = {
    // Global
    container: {
        ...columnStyles,
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: Colors.PRIMARY,
        overflow: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            display: "none",
        },
        background: `linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181c 72.14%),
        url(${require("../../assets/images/background.jpg")}) 50%/cover`,
    },
    header: {
        width: "90%",
        maxWidth: "1070px",
        alignSelf: "center",
        padding: "20px",
        ...columnStyles,
        margin: "5vh 0 0 0",
    },
    backButton: {
        width: "41px",
        borderRadius: "40px",
        position: "relative",
        top: "20px",
    },
    containerInside: {
        ...columnStyles,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    companionsList: {
        //...columnStyles,
        ...rowWrapStyles,
        width: "90%",
        maxWidth: "1070px",
        alignSelf: "center",
        padding: "20px",
        alignItems: "center",
        justifyContent: 'center',
        gap: "10px",
    },
    companionSingle: {
        ...rowStartStyles,
        minWidth: "300px",
        padding: "10px",
        backgroundColor: Colors.BTN_ACTIVE,
        gap: "12px",
        flexShrink: 0,
        borderRadius: "13px",
        alignSelf: "center",
    },
    companionSingleColumn: {
        ...columnCenterStyles,
        minWidth: "250px",
        padding: "10px",
        backgroundColor: Colors.BTN_ACTIVE,
        gap: "12px",
        flexShrink: 0,
        borderRadius: "13px",
    },
    companionSingleSelected: {
        border: `2px solid ${Colors.HEART}`,
    },
    companionText: {
        ...columnStyles,
        alignItems: 'flex-start',
    },
    companionTextBig: {
        ...columnStyles,
        alignItems: 'center',
    },
    companionName: {
        fontFamily: Gilroy,
        fontSize: "18px",
        fontWeight: 600,
        color: Colors.WHITE,
    },
    companionTitle: {
        fontFamily: Gilroy,
        fontSize: "14px",
        fontWeight: 600,
        color: Colors.ACCENT,
    },
    round: {
        width: '60px',
        height: '60px',
        backgroundColor: Colors.OLD_PRICE,
        borderRadius: "60px",
    },
    bigRound: {
        width: '120px',
        height: '120px',
        backgroundColor: Colors.OLD_PRICE,
        borderRadius: "120px",
    },
    priceBlock: {
        ...rowStartStyles,
        gap: "7px",
    },
    subtitle: {
        fontFamily: Gilroy,
        fontSize: "14px",
        fontWeight: 600,
        color: Colors.OLD_PRICE,
    },

    //Edit companion
    containerEdit: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        //backgroundColor: Colors.PRIMARY,
    },
    topMenu: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "flex-start",
        /* paddingTop: "0",
        paddingBottom: "1.5vh", */
        margin: 0,
    },
    loading: {
        position: "absolute",
        top: "20px",
        right: "5%",
    },
    userName: {
        fontSize: "2.2vh",
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
    },
    editIcon: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    userTitle: {
        fontSize: "2.2vh",
        fontFamily: "Gilroy-Bold",
        color: Colors.ACCENT,
    },
    userId: {
        fontSize: "1.5vh",
        fontFamily: "Gilroy-Bold",
        color: Colors.BTN_INACTIVE,
        maxWidth: "100%",
        marginLeft: 5,
    },
    item: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "center",
        alignContent: "center",
        alignSelf: "center",
        justifySelf: "center",
        width: "80%",

    },
    // Modal
    purchaseSlotModal: {
        width: "300px",
        borderRadius: "20px",
        backgroundColor: Colors.PRIMARY,
        position: "relative",
        padding: "40px 0px 20px",
        ...columnCenterStyles,
        zIndex: 50,
    },
    containerModal: {
        ...columnCenterStyles,
        width: "100%",
    },
    modalView: {
        width: "300px",
        backgroundColor: Colors.PRIMARY,
        borderRadius: "20px",
        paddingTop: "50px",
        paddingBottom: "50px",
        ...columnCenterStyles,
        outline: "none",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    modalSubtitle: {
        color: Colors.SECONDARY_TEXT,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(14),
        width: "250px",
        marginTop: "19px",
        textAlign: "center",
    },
    popupButtons: {
        width: "100%",
        minWidth: "220px",
        ...rowStyles,
        justifyContent: "space-evenly",
        alignItems: "flex-end",
    },
    yesButton: {
        backgroundColor: Colors.ACCENT,
        padding: "11px 15px",
        borderRadius: "10px",
        minHeight: "50px",
        minWidth: "100px",
    },
    yesText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.WHITE,
        textAlign: "center",
    },
    noButton: {
        backgroundColor: Colors.BTN_ACTIVE,
        ...rowCenterStyles,
        padding: "10px 15px",
        borderRadius: "10px",
        minHeight: "50px",
        minWidth: "100px",
    },
    noText: {
        fontSize: normalize(14),
        fontFamily: "Gilroy-Bold",
        color: Colors.OLD_PRICE,
        textAlign: "center",
    },
    ticketsOwned: {
        ...rowStartStyles,
    },
    ticketsAmountOwned: {
        fontSize: normalize(18),
        fontFamily: "Gilroy-Bold",
        color: Colors.TICKET,
        marginLeft: "3px",
    },
    myInputView: {
        width: "100%",
        height: 60,
        backgroundColor: Colors.BTN_ACTIVE,
        borderRadius: 13,
        paddingLeft: "10px",
        flexDirection: "row",
        alignItems: "center",
        color: Colors.WHITE,
        fontFamily: "Gilroy-Bold",
        fontSize: normalize(16),
    },
    separator: {
        width: "300px",
        height: 0,
        color: Colors.BTN_ACTIVE,
        borderColor: Colors.BTN_ACTIVE,
        borderWidth: 1,
        marginTop: 25,
    },
    prev: {
        width: "5%",
        height: "100%",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
        zIndex: 2,
        color: "#fff",
    },
    next: {
        width: "5%",
        height: "100%",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
        zIndex: 2,
        color: "#fff",
    },
    //Modal Pseudo 

}