/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ButtonBase, Fade, Slide, Typography } from "@mui/material";
import { Colors } from "../../style";
import {
    columnBetweenStyles,
    columnCenterStyles,
    rowCenterStyles,
} from "../../style/flex";
import { Player } from "@lottiefiles/react-lottie-player";
import GradientText from "../GradientText";
import { logoPass } from "../../constants/logoPass";
import PassBtn from "../PassBtn";
import { useState, useRef, useEffect } from "react";
import useAuth from "../../services/Auth/useAuth";
import PassAnimated from "../PassAnimated";
import { strings } from "../../services/translation";
import { isNotEmptyString } from "../../utils/StringHelper";
import Separator from "../Separator";
import Loading from "../Loading";
import { getRouteManager } from "../../services/routeManager";
import { getEventManager } from "../../services/EventManager";
import { styles } from "./styles";
import { normalizeSize, normalizeVh } from "../../utils/fontSizer";
import { isStrictlyPositiveNumber } from "../../utils/NumberHelper";
import { Eczar } from "../../style/fonts";
//import { cards, cardsPlus } from "./cards";
import { PassOption } from "./PassOption";
import { PassInfo } from "../../models/passInfo";
import DynamicTag from "../DynamicTag";
import { rewardStoryJson } from "../../constants/rewardStory";
import { rewardTitleJson } from "../../constants/rewardTitle";

const Pass = () => {
    const auth = useAuth();

    /* CAROUSEL */

    const carouselRef = useRef<HTMLDivElement | null>(null);
    const cardWidth = 300;
    const cardSpacing = 16;
    const containerRef = useRef<HTMLDivElement | null>(null);

    /* STATES */

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [currency, setCurrency] = useState<string | null>("EUR");
    const [loading, setLoading] = useState(false);
    const [selectedPass, setSelectedPass] = useState<PassInfo | null>(null);
    const [showContinue, setShowContinue] = useState(false);

    /* PASS / TEST */

    //const passActive = auth && auth.user && auth.user.passActive && auth.user.passEndDate;
    const passActive =
        auth && auth.user && auth.user.pass && auth.user.pass.isActive
            ? true
            : false;

    /* USE EFFECTS */

    useEffect(() => {
        checkCurrency();
    }, []);

    /* FUNCTIONS */

    const checkCurrency = () => {
        const test = localStorage.getItem("currency");
        if (isNotEmptyString(test)) {
            setCurrency(test);
        }
    };

    const initPurchase = async () => {
        try {
            if (auth && auth.user) {
                if (selectedPass) {
                    setLoading(true);
                    const apiPurchase = getRouteManager().purchaseTicketsWeb();
                    const purchase = await getRouteManager().fetchData(
                        apiPurchase,
                        {
                            login_token: auth.user.login_token,
                            code: selectedPass.code,
                            currency: currency,
                            prefix: "http://localhost:3000",
                        },
                    );
                    if (purchase.url && isNotEmptyString(purchase.url)) {
                        const price = purchase.price
                            ? purchase.price
                            : selectedPass.price;
                        const sanitizedPrice = price
                            .replace(",", ".")
                            .replace("$", "")
                            .replace("€", "")
                            .match(/[\d\.\d]+/i);
                        if (sanitizedPrice && sanitizedPrice[0]) {
                            const priceFinal = parseFloat(
                                sanitizedPrice[0] ? sanitizedPrice[0] : price,
                            );
                            getEventManager().logBeginCheckout(
                                priceFinal,
                                currency ? currency : "EUR",
                            );
                        }
                        window.location.href = purchase.url;
                    }
                }
            } else {
                alert(strings.errors.notLoggedIn);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onClickPass = (pass: PassInfo) => {
        if (selectedPass) {
            if (pass.index !== selectedPass?.index) {
                setSelectedPass(pass);
            } else {
                setSelectedPass(null);
            }
        } else {
            setSelectedPass(pass);
        }
    };

    const cards = [
        {
            type: "anim",
            text: strings.pass.allStories,
            anim: rewardStoryJson,
        },
        {
            type: "image",
            text: strings.pass.avatar,
            image: require("../../assets/images/avatarPass.png"),
        },
        /*  {
            type: "anim",
            text: strings.pass.future,
            anim: rewardPackJson,
        }, */
        {
            type: "anim",
            text: strings.pass.title,
            anim: rewardTitleJson,
        },
        {
            type: "image",
            text: strings.pass.saves,
            image: require("../../assets/images/saves.png"),
        },
    ];
    const cardsPlus = [
        {
            type: "anim",
            text: strings.pass.adventurerPass,
            //anim: logoPass,
        },
        {
            type: "image",
            text: strings.pass.allAvatars,
            image: require("../../assets/images/avatarsPass.png"),
        },
        {
            type: "image",
            text: strings.pass.allCosmetics,
            image: require("../../assets/images/avatarPassCosmetic.png"),
        },
        {
            type: "image",
            text: strings.pass.allCompanionSlots,
            image: require("../../assets/images/avatarPassCompanion.png"),
        },
    ];

    const passInfos: PassInfo[] = [
        {
            index: 1,
            cards: cards,
            price: currency === "EUR" ? "19.99€" : "$19.99",
            title: strings.pass.adventurerPass,
            code: "ADVENTURERPASS",
            value: strings.pass.value4,
        },
        {
            index: 2,
            cards: cardsPlus,
            price: currency === "EUR" ? "29.99€" : "$29.99",
            title: strings.pass.adventurerPassPlus,
            code: "ADVENTURERPASSPLUS",
            value: strings.pass.value5,
        },
    ];

    return (
        <Box sx={[styles.mainContainer]} ref={containerRef}>
            <Box sx={styles.imgContainer} />
            <Box sx={styles.contentContainer}>
                <Box sx={styles.container}>
                    <Player
                        src={logoPass}
                        style={styles.lottieLogo}
                        autoplay
                        loop
                    />
                    {auth && !passActive ? (
                        <GradientText
                            color1={Colors.PASS1}
                            color2={Colors.PASS2}
                            style={styles.gradientTextHeader}>
                            {strings.pass.choosePass}
                        </GradientText>
                    ) : null}
                </Box>
                <Box sx={[styles.container]}>
                    <DynamicTag
                        text=""
                        style={styles.tag}
                        icon={() => (
                            <GradientText
                                color1={Colors.PASS1}
                                color2={Colors.PASS2}
                                style={styles.gradientTextSmall}>
                                {strings.pass.lifetimePass}
                            </GradientText>
                        )}
                    />
                    <Box sx={styles.passOptions}>
                        <PassOption
                            pass={passInfos[0]}
                            isSelected={
                                !!(
                                    selectedPass &&
                                    selectedPass.index === passInfos[0].index
                                )
                            }
                            onClickPass={onClickPass}
                        />
                        <PassOption
                            pass={passInfos[1]}
                            isSelected={
                                !!(
                                    selectedPass &&
                                    selectedPass.index === passInfos[1].index
                                )
                            }
                            onClickPass={onClickPass}
                        />
                    </Box>
                </Box>
                <Box sx={[styles.container, { marginTop: normalizeVh(2) }]}>
                    {auth && !passActive ? (
                        <PassBtn
                            callback={() => setShowContinue(true)}
                            disabled={selectedPass === null}
                            style={{ width: "100%" }}>
                            <Typography
                                sx={[
                                    selectedPass === null
                                        ? styles.unlockBtnDisabled
                                        : styles.unlockBtn,
                                ]}>
                                {strings.pass.getItNow}
                            </Typography>
                        </PassBtn>
                    ) : null}
                </Box>
            </Box>
            <Fade in={showContinue}>
                <Box
                    sx={styles.overlay}
                    onClick={() => setShowContinue(false)}
                />
            </Fade>
            <Slide
                in={showContinue}
                container={containerRef.current ? containerRef.current : null}
                direction="up">
                <Box
                    sx={[
                        styles.paymentContainer,
                        { height: loading ? "300px" : "550px" },
                    ]}>
                    {loading || !selectedPass ? (
                        <Box sx={styles.paymentLoading}>
                            <Loading />
                        </Box>
                    ) : (
                        <Box sx={styles.paymentContainerInside}>
                            <Box sx={styles.paymentBox}>
                                <Player
                                    src={logoPass}
                                    style={styles.lottieLogo}
                                    autoplay
                                    loop
                                />
                                <GradientText
                                    color1={Colors.PASS1}
                                    color2={Colors.PASS2}
                                    style={styles.title}>
                                    {strings.purchase.title}
                                </GradientText>
                                <Box sx={[styles.priceContainer]}>
                                    <PassAnimated
                                        style={{ width: 25, height: 25 }}
                                    />
                                    <GradientText
                                        color1={Colors.PASS1}
                                        color2={Colors.PASS2}
                                        style={styles.passAmount}>
                                        {selectedPass.title}
                                    </GradientText>
                                    <Typography
                                        sx={[
                                            styles.passAmount,
                                            {
                                                color: Colors.WHITE,
                                                fontSize: "18px",
                                            },
                                        ]}>
                                        <Typography
                                            component={"span"}
                                            sx={[
                                                styles.subtitle,
                                                {
                                                    color: Colors.WHITE,
                                                    marginRight: "5px",
                                                },
                                            ]}>
                                            {" - "}
                                            {selectedPass.price}
                                        </Typography>
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={[
                                        styles.subtitle,
                                        {
                                            width: "400px",
                                            fontSize: normalizeSize(20),
                                            marginTop: "40px",
                                        },
                                    ]}>
                                    {strings.messages.redirectToPayment}
                                </Typography>
                                <Typography
                                    sx={[
                                        styles.subtitle,
                                        {
                                            color: Colors.FOND_ITEM_POPUP,
                                            width: "400px",
                                            marginTop: "20px",
                                            fontSize: normalizeSize(14),
                                            fontStyle: "italic",
                                        },
                                    ]}>
                                    {strings.messages.changeCurrency}
                                </Typography>
                            </Box>
                            <Box sx={{ ...columnCenterStyles, width: "100%" }}>
                                <Separator />
                                <Box sx={styles.popupButtons}>
                                    <ButtonBase
                                        sx={styles.noButton}
                                        //onMouseDown={(event) => event.stopPropagation()}
                                        onClick={() => {
                                            setShowContinue(false);
                                        }}>
                                        <Typography sx={styles.noText}>
                                            {strings.back}
                                        </Typography>
                                    </ButtonBase>
                                    <PassBtn
                                        callback={() => initPurchase()}
                                        borderRadius={10}
                                        style={{
                                            width: "150px",
                                            height: "50px",
                                            borderRadius: "10px",
                                        }}>
                                        <Typography sx={styles.unlockBtn}>
                                            {strings.continue}
                                        </Typography>
                                    </PassBtn>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Slide>
        </Box>
    );
};

export default Pass;
