/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */

import { isNotEmptyString } from "./StringHelper";
import { isObject, isString } from "./TypeOfHelper";

// =======================================================

export function propertyExists(obj: any, property: any) {
  return (
    obj &&
    isObject(obj) &&
    isNotEmptyString(property) &&
    obj.hasOwnProperty(property) &&
    (isString(obj[property])
      ? isNotEmptyString(obj[property])
      : obj[property] !== undefined && obj[property] !== null)
  );
}

// =======================================================
